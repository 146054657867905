import { useMutation, gql } from "@apollo/client"

const PRINTEDLABEL_MUTATION = gql`
  mutation PrintedLabel($id: ID!, $input: LabelPrintedInput!) {
    label(id: $id) {
      printed(input: $input)
    }
  }
`

const useMutationPrintedLabel = () => useMutation(PRINTEDLABEL_MUTATION)

export { useMutationPrintedLabel }
