import { useMutation, gql } from "@apollo/client"

const ACTIVATE_SELF_MUTATION = gql`
  mutation ActivateSelf($id: ID!, $token: String!, $password: String!, $pin: String!) {
    activateSelf(id: $id, token: $token, password: $password, pin: $pin) {
      firstName
    }
  }
`

const useMutationActivateSelf = () =>
  useMutation(ACTIVATE_SELF_MUTATION, {
    errorPolicy: "all",
  })

export { useMutationActivateSelf }
