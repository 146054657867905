import { useMutation, gql } from "@apollo/client"
import { USER_ACCREDITATION_FIELDS } from "./fragments/userAccreditationFieldsFragment"

const CREATE_USER_ACCREDITATION_MUTATION = gql`
  mutation CreateUserAccreditation($accreditation: ID!, $user: ID!) {
    createUserAccreditation(accreditation: $accreditation, user: $user) {
      ...UserAccreditationFields
    }
  }
  ${USER_ACCREDITATION_FIELDS}
`

const useMutationCreateUserAccreditation = () => useMutation(CREATE_USER_ACCREDITATION_MUTATION)

export { useMutationCreateUserAccreditation }
