import Base from "./Base"
import Test from "./Test"

const config = {
  ...Test,
  clientDevice: true,

  auth: Test.auth ? { ...Test.auth } : { ...Base.auth },
}

export default config
