import React, { useState } from "react"
import { makeStyles } from "@material-ui/styles"
import { OutlinedInput, IconButton, Hidden, Box } from "@material-ui/core"
import { SendOutlined as SendOutlinedIcon } from "@material-ui/icons"
import { t } from "@lingui/macro"
import {
  useMutationCreatePostComment,
  makeCreatePostCommentOptimisticResponse,
  useMutationCreateActionComment,
  makeCreateActionCommentOptimisticResponse,
} from "../../data"
import { Avatar, Uploader, UploadingList, UploadingImagesList } from ".."
import { useAuth } from "../../services"
import { toId, useAnalytics, useFormUtils } from "../../utils"

const useStyles = makeStyles((theme) => ({
  comment: {
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(1),
    paddingBottom: 0,
  },
  avatar: {
    width: 40,
    height: 40,
    marginRight: theme.spacing(2),
  },
  input: {
    display: "flex",
    justifySelf: "stretch",
  },
  spinner: {
    marginTop: -6,
    marginLeft: -6,
  },
}))

const AddComment = ({ post, action, onCommentCreated, placeholder = t`Write a comment...` }) => {
  const classes = useStyles()
  const { principal } = useAuth()
  const analytics = useAnalytics()
  const { firstName, lastName, avatar } = principal
  const [createPostComment] = useMutationCreatePostComment(post)
  const [createActionComment] = useMutationCreateActionComment(action)
  const { isValid } = useFormUtils()

  const [content, setContent] = useState("")
  const [isUploading, setIsUploading] = useState(false)
  const [uploads, setUploads] = useState([])
  const [uploading, setUploading] = useState([])

  const handleCommentSend = async () => {
    if (content.trim()) {
      const variables = {
        content: content.trim(),
        post: post ? toId(post) : null,
        action: action ? toId(action) : null,
        uploads: uploads.map(({ id }) => id),
      }
      const optimisticResponse = post
        ? makeCreatePostCommentOptimisticResponse({
            post,
            content: variables.content,
            uploads,
            principal,
          })
        : makeCreateActionCommentOptimisticResponse({ action, content: variables.content, uploads, principal })
      setContent("")
      setUploads([])

      const comment = await (post
        ? createPostComment({
            variables,
            optimisticResponse,
          })
        : createActionComment({
            variables,
            optimisticResponse,
          }))

      analytics.track(post ? "Commented on Post" : "Commented on Action")

      onCommentCreated && onCommentCreated(comment)
    }
  }
  const handleCommentSendClick = async () => {
    await handleCommentSend()
  }
  const handleCommentKeyPress = async (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      await handleCommentSend(content) // with trimmed for/aft content
    }
  }
  const handleContentChange = (e) => {
    const value = e.target.value
    if (!value.length || value.trim().length > 0) setContent(value)
  }

  const handleBeforeUpload = (items) => {
    setIsUploading(true)
    setUploading(() => items)
  }
  const handleUploadProgress = (items) => {
    setUploading(() => items)
  }
  const handleItemUploaded = (item) => {
    setUploads((state) => [...state, item])
  }
  const handleRemoveUpload = (id) => {
    setUploads([...uploads.filter((upload) => upload.id !== id)])
  }
  const handleNotUploading = () => {
    setIsUploading(false)
  }

  const formValid = () => isValid(content) && !isUploading

  const isFormValid = formValid()

  return (
    <div className={classes.comment}>
      <Box display="flex" flexGrow={1} alignItems="center">
        <Hidden xsDown>
          <Avatar className={classes.avatar} {...{ firstName, lastName, avatar: { key: avatar } }} />
        </Hidden>
        <OutlinedInput
          placeholder={placeholder}
          margin="dense"
          value={content}
          onChange={handleContentChange}
          onKeyPress={handleCommentKeyPress}
          fullWidth
          multiline
        />
        <Uploader
          onBeforeUpload={handleBeforeUpload}
          onProgress={handleUploadProgress}
          onItemUploaded={handleItemUploaded}
          onAfterUpload={handleNotUploading}
          onError={handleNotUploading}
        />
        <IconButton onClick={handleCommentSendClick} disabled={!isFormValid}>
          <SendOutlinedIcon />
        </IconButton>
      </Box>
      <Box mt={uploads?.length ? 1 : 0}>
        <UploadingImagesList
          uploaded={uploads}
          uploading={uploading}
          onRemoveUpload={handleRemoveUpload}
          height={100}
          gridItemProps={{
            xs: 4,
            sm: 3,
            md: 2,
          }}
        />
        <UploadingList uploaded={uploads} uploading={uploading} images={false} onRemoveUpload={handleRemoveUpload} />
      </Box>
    </div>
  )
}

export { AddComment }
