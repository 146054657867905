import React, { Suspense, lazy } from "react"
import { LoadingSpinner } from "../LoadingSpinner"

const InnerTableWindowVirtualizer = lazy(() => import("./InnerTableVirtualizer"))

const TableVirtualizer = ({ items, itemContent, ...props }) => {
  if (!items || items.length === 0) {
    return null
  }

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <InnerTableWindowVirtualizer items={items} itemContent={itemContent} {...props} />
    </Suspense>
  )
}

export { TableVirtualizer }
