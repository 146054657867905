import React from "react"
import ErrorContent from "./ErrorContent"

const ErrorInitConfig = (props) => (
  <ErrorContent>
    <p>{props.message}</p>
  </ErrorContent>
)

export default ErrorInitConfig
