import { useMutation, gql } from "@apollo/client"

const DELETE_USER_ACCREDITATION_MUTATION = gql`
  mutation DeleteUserAccreditation($id: ID!) {
    deleteUserAccreditation(id: $id)
  }
`

const useMutationDeleteUserAccreditation = () => useMutation(DELETE_USER_ACCREDITATION_MUTATION)

export { useMutationDeleteUserAccreditation }
