import { useMutation, gql } from "@apollo/client"

const CHANGE_USERNAME_MUTATION = gql`
  mutation ChangeUsername($input: MeChangeUsernameInput!) {
    me {
      changeUsername(input: $input) {
        id
      }
    }
  }
`

const useMutationChangeUsername = () =>
  useMutation(CHANGE_USERNAME_MUTATION, {
    errorPolicy: "all",
  })

export { useMutationChangeUsername }
