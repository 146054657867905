import React from "react"
import LogoImage from "./operandio.svg"

const Logo = ({ className, size = 64, link = true }) => {
  const image = <img src={LogoImage} width={size} height={size} className={className} alt="Operandio" />

  return link ? <a href="https://operandio.com">{image}</a> : image
}

export { Logo }
