import { Box, Button } from "@material-ui/core"
import { memo, useState } from "react"
import { t } from "@lingui/macro"

const ShowMore = memo(function ShowMore({ text = t`Show More`, children }) {
  const [show, setShow] = useState(false)

  if (!show) {
    return (
      <Box display="flex" justifyContent="center">
        <Button variant="outlined" onClick={() => setShow(true)} size="small">
          {text}
        </Button>
      </Box>
    )
  }

  return <>{children}</>
})

export { ShowMore }
