import { useLocation } from "react-router-dom"
import mixpanel from "mixpanel-browser"
import { useEffect } from "react"
import { useDeviceUtils } from "../../utils"

const RouteChangedMessageProvider = ({ children }) => {
  const { postRouteChangedMessage } = useDeviceUtils()
  const location = useLocation()

  useEffect(() => {
    postRouteChangedMessage(location.pathname)
    mixpanel.track_pageview()
  }, [location, postRouteChangedMessage])

  return children
}

export { RouteChangedMessageProvider }
