import { gql } from "@apollo/client"
import { JOB_STEP_FIELDS } from "./jobStepFieldsFragment"

const JOB_LIST_FIELDS = gql`
  fragment JobListFields on Job {
    id
    type
    title
    process {
      id
      name
    }
    parent {
      job
    }
    availableFrom
    dueAt
    isOverdue @client
    availableUntil {
      type
      at
    }
    scheduleName
    processName
    displayName
    repeat {
      type
      frequency
      weekdays {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
    }
    adhoc
    percentComplete
    hasSubmit
    hasScoring
    status {
      id
      completed
      completedAt
      completedBy {
        id
        fullName
        firstName
        lastName
        avatar {
          key
        }
      }
      failed
      submitted
      submittedAt
      submittedBy {
        id
        fullName
        firstName
        lastName
        avatar {
          key
        }
      }
      steps {
        id
        responseType
        completed @client
        completedAt
        skip
        job {
          id
          percentComplete
        }
      }
      score
      possibleScore
    }
    users {
      id
      fullName
      firstName
      lastName
      avatar {
        key
      }
    }
    groups {
      id
      name
    }
    author {
      id
      fullName
      firstName
      lastName
      avatar {
        key
      }
      groups {
        id
      }
    }
    location {
      id
      name
      timeZone
    }
    createdAt
    updatedAt
  }
`

const JOB_FIELDS = gql`
  fragment JobFields on Job {
    ...JobListFields
    status {
      id
      steps {
        ...JobStepFields
      }
    }
    tags {
      id
      name
    }
    actionSettings {
      users {
        id
        fullName
      }
      groups {
        id
        name
      }
    }
  }
  ${JOB_LIST_FIELDS}
  ${JOB_STEP_FIELDS}
`

export { JOB_FIELDS, JOB_LIST_FIELDS }
