import React from "react"
import { makeStyles } from "@material-ui/styles"
import Spinner from "./spinner.svg"

const useStyles = makeStyles(() => ({
  image: {
    opacity: ({ opacity }) => opacity,
    animationName: ({ delay }) => (delay ? "fadeInOpacityLight" : null),
    animationIterationCount: 1,
    animationTimingFunction: "ease-in",
    animationDuration: "1s",
  },
}))

const LoadingSpinner = ({ size = "36px", className, opacity = 0.3, delay = true }) => {
  const classes = useStyles({ opacity, delay })

  return <img alt="Loading" src={Spinner} height={size} width={size} className={`${classes.image} ${className}`} />
}

export { LoadingSpinner }
