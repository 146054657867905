import React from "react"

import Clock from "react-live-clock"
import { Box, Chip, Typography, makeStyles, useMediaQuery, useTheme } from "@material-ui/core"
import PropTypes from "prop-types"

import { ActionCard } from "../ActionCards"
import { locationTypes } from "../../types"
import { useDateUtils } from "../../utils"
import { Avatar } from "../Avatar"
import { useAuth } from "../../services"

const useStyles = makeStyles((theme) => ({
  root: {
    borderColor: theme.palette.primary.main,
  },
  title: {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: "400",
    color: theme.palette.text.tinted,
    marginBottom: ({ hasDetail }) => (hasDetail ? theme.spacing(0.5) : 0),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "230px",
    },
  },
  currentLocation: {
    color: "white",
    backgroundColor: "#2092FF",
  },
}))

const LocationSwitcherCard = ({ switching, location, onChange }) => {
  const {
    settings: { organisation },
    location: currentLocation,
  } = useAuth()
  const { name, timeZone } = location
  const { hasTimeOffsetFromUser } = useDateUtils()
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const classes = useStyles({ hasDetail: hasTimeOffsetFromUser(timeZone) })
  const isCurrentLocation = location?.id === currentLocation?.id

  const handleChange = () => {
    if (onChange) onChange(location)
  }

  const renderRightChildren = () => {
    if (switching) {
      return <Chip size="small" label="Changing..." classes={{ root: classes.currentLocation }} />
    }

    if (isCurrentLocation) {
      return <Chip size="small" label="Current" classes={{ root: classes.currentLocation }} />
    }

    if (location.inactive) {
      return <Chip size="small" label="Inactive" />
    }

    return null
  }

  return (
    <ActionCard
      className={isCurrentLocation ? classes.root : null}
      p={1}
      cardProps={{ sx: { py: "3px" }, onClick: handleChange }}
      contentMinHeight={40}
      stub={
        <Box display="flex" alignSelf="center" sx={{ marginRight: 8 }}>
          <Avatar
            avatar={location.logo || { key: organisation.logo }}
            fullName={location.name}
            style={{ cursor: "pointer" }}
          />
        </Box>
      }
      title={
        <Typography variant="h2" className={classes.title}>
          {name}
        </Typography>
      }
      detail={hasTimeOffsetFromUser(timeZone) ? <Clock timezone={timeZone} ticking format="h:mma z" /> : null}
      rightChildren={
        <Box display="flex" alignSelf="center" marginLeft={1}>
          {renderRightChildren()}
        </Box>
      }
      titleProps={
        xs
          ? {
              sx: { width: "calc(100% - 120px)" },
            }
          : {}
      }
      titleWrapperProps={
        xs
          ? {
              sx: { width: "100%" },
            }
          : {}
      }
    />
  )
}

LocationSwitcherCard.propTypes = {
  switching: PropTypes.bool,
  location: locationTypes,
  onChange: PropTypes.func,
}

export default LocationSwitcherCard
