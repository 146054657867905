import Config from "react-global-configuration"

const fetchIdentityProvider = async (id) => {
  const { httpBaseUri } = Config.get()
  const response = await fetch(`${httpBaseUri}/identity/${id}`)

  if (response.ok) {
    return { data: await response.json() }
  }

  if (response.status === 400) {
    return {
      error: "Invalid identity provider id",
    }
  }

  if (response.status === 404) {
    return {
      error: "Identity provider does not exist",
    }
  }

  return {
    error: "Error loading identity provider",
  }
}

export { fetchIdentityProvider }
