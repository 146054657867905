import React from "react"
import { Grid, Box, Chip, Paper, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { NavLink, useLocation } from "react-router-dom"
import { AuthorTools, DisplayImage } from ".."
import { useAuth } from "../../services"
import { mapToIds, toId } from "../../utils"

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    flex: 1,
    cursor: "pointer",
    display: "flex",
  },
  paper: {
    "&:hover": theme.palette.hoverCard,
    "&:focus": theme.palette.hoverCard,
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  labelGrid: {
    display: "flex",
  },
  titleBox: {
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    height: "64px",
    maxHeight: "80px",
    [theme.breakpoints.down("sm")]: {
      height: "48px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  title: {
    fontSize: 14,
    fontWeight: "600",
    lineHeight: 1.25,
    display: "-webkit-box",
    lineClamp: 2,
    boxOrient: "vertical",
    overflow: "hidden",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
    },
  },
  imageFrame: {
    position: "relative",
  },
  labelChip: {
    position: "absolute",
    left: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 100,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "80%",
  },
  labelImage: {
    backgroundColor: "#1a1b43",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    flexGrow: 1,
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "140px",
    [theme.breakpoints.down("sm")]: {
      height: "130px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "120px",
    },
  },
  defaultImagePadding: {
    padding: theme.spacing(2),
  },
  defaultImage: {
    borderRadius: 4,
    maxWidth: "100%",
    maxHeight: "100%",
  },
  customImage: {
    width: "100%",
    objectFit: "cover",
    minHeight: "100%",
  },
}))

const requiredPermissions = ["label_update", "label_update_all"]

const LabelsItem = ({ category, item, onEdit, onDelete }) => {
  const classes = useStyles()
  const location = useLocation()
  const {
    hasPermission,
    settings: { locations: userLocations },
  } = useAuth()
  const { id, title } = item

  const LabelAuthorTools = () => {
    const userLocationIds = mapToIds(userLocations)

    if (
      !hasPermission("label_update_all") &&
      (item.locations.length === 0 || !item.locations.every((l) => userLocationIds.includes(toId(l))))
    ) {
      return null
    }

    return (
      <Box ml="auto">
        <AuthorTools
          item={item}
          subject="label"
          admins={{ edit: requiredPermissions, delete: requiredPermissions }}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </Box>
    )
  }

  return (
    <Grid item lg={3} md={3} sm={4} xs={6} className={classes.labelGrid}>
      <Box
        component={NavLink}
        to={{ pathname: `/labels/item/${id}/print`, state: { background: location } }}
        className={classes.root}
      >
        <Paper elevation={3} className={classes.paper}>
          <Box className={classes.imageFrame}>
            {category && <Chip label={category.name} size="small" className={classes.labelChip} />}
            {item.defaultImage ? (
              <Box className={`${classes.labelImage} ${classes.defaultImagePadding}`}>
                <DisplayImage className={classes.defaultImage} fit="inside" upload={item.image} height={200} />
              </Box>
            ) : (
              <Box className={classes.labelImage}>
                <DisplayImage
                  className={classes.customImage}
                  fit="cover"
                  upload={item.image}
                  width={300}
                  height={200}
                />
              </Box>
            )}
          </Box>
          <Box display="flex" flexDirection="row" className={classes.titleBox}>
            <Box display="flex" flexDirection="row" flexGrow={1}>
              <Box display="flex">
                <Typography variant="h4" className={classes.title}>
                  {title}
                </Typography>
              </Box>
            </Box>
            <LabelAuthorTools />
          </Box>
        </Paper>
      </Box>
    </Grid>
  )
}

export { LabelsItem }
