import { useMutation, gql } from "@apollo/client"
import { INTEGRATION_FIELDS } from "./useQueryIntegrations"

const CREATE_INTEGRATION_MUTATION = gql`
  mutation CreateIntegration($integrationProvider: ID!, $code: String!, $reconnectIntegration: ID) {
    createIntegration(
      integrationProvider: $integrationProvider
      code: $code
      reconnectIntegration: $reconnectIntegration
    ) {
      ...IntegrationFields
    }
  }
  ${INTEGRATION_FIELDS}
`

const useMutationCreateIntegration = () => useMutation(CREATE_INTEGRATION_MUTATION)

export { useMutationCreateIntegration }
