import React from "react"
import { makeStyles, useTheme } from "@material-ui/styles"
import { Box, IconButton, Hidden, useMediaQuery } from "@material-ui/core"
import { FilterList as FilterListIcon } from "@material-ui/icons"
import InfiniteScroll from "react-infinite-scroll-component"
import { Trans } from "@lingui/macro"
import { MainHeading, NoItemsMessage, LoadingSpinner } from ".."
import { ModuleHeading } from "../Headings"
import { Action } from "./Action"
import { toId } from "../../utils"

const useStyles = makeStyles((theme) => ({
  postList: {
    marginBottom: theme.spacing(1),
  },
  filterButton: {
    paddingTop: ({ xs }) => (xs ? 6 : "inherit"),
    paddingBottom: ({ xs }) => (xs ? 6 : "inherit"),
  },
  loading: {
    display: "flex",
    justifyContent: "center",
  },
}))

const ActionList = ({
  title,
  loading,
  error,
  actions,
  loadMore,
  onEdit,
  onDelete,
  onCommentCreated,
  onStatusChange,
  onOpenSubNav,
}) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const classes = useStyles({ xs })

  const heading = (
    <>
      {!actions && loading && <Trans>Loading...</Trans>}
      {!actions && error && <Trans>Error</Trans>}
      {actions && title}
    </>
  )

  const items = actions || []

  return (
    <>
      <Box display="flex" alignItems="center" direction="row" mb={xs ? 1 : 3}>
        <Box flexGrow={1}>
          <Hidden xsDown>
            <MainHeading>{heading}</MainHeading>
          </Hidden>
          <Hidden smUp>
            <ModuleHeading noMargin>{heading}</ModuleHeading>
          </Hidden>
        </Box>
        <Box alignSelf="center" display={{ sm: "flex", md: "none" }}>
          <IconButton onClick={onOpenSubNav} className={classes.filterButton}>
            <FilterListIcon />
          </IconButton>
        </Box>
      </Box>

      <div className={classes.postList}>
        {!!items.length && (
          <InfiniteScroll
            dataLength={items.length}
            next={loadMore}
            hasMore
            endMessage={
              !loading && (
                <NoItemsMessage>
                  {items.length || "No"} matching item{items.length > 1 ? "s" : ""}
                </NoItemsMessage>
              )
            }
            style={{ overflow: "visible" }}
          >
            <Box data-cy="Box-actions">
              {items.map((action) => (
                <Action
                  key={toId(action)}
                  action={action}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  onCommentCreated={onCommentCreated}
                  onStatusChange={onStatusChange}
                />
              ))}
            </Box>
          </InfiniteScroll>
        )}
        {loading && (
          <Box display="flex" justifyContent="center">
            <LoadingSpinner size={60} />
          </Box>
        )}
        {!loading && !items.length && (
          <NoItemsMessage>
            <Trans>No matching items</Trans>
          </NoItemsMessage>
        )}
      </div>
    </>
  )
}

export { ActionList }
