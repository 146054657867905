import React, { useState, useEffect } from "react"
import { OutlinedInput, InputAdornment, IconButton } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import SearchIcon from "@material-ui/icons/Search"
import { Clear as ClearIcon } from "@material-ui/icons"
import { t } from "@lingui/macro"
import { useMountEffect } from "../../utils"
import { FlexBox, LoadingSpinner } from ".."

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    fontSize: 16,
    color: theme.palette.text.primary,
    marginBottom: ({ mb }) => (mb ? theme.spacing(mb) : 0),
  },
  notchedOutline: {
    borderColor: "#d2d2d2",
  },
  clearButton: {
    padding: 0,
  },
}))

const baseBoxProps = {
  pl: 1,
  pr: 1,
  mb: 4,
}

const SearchInput = ({
  onChange,
  initialValue,
  placeholder = t`Search`,
  debounce = false,
  loading = false,
  cy = "SearchInput",
  inputProps = {},
  boxProps = {},
  ...rest
}) => {
  const { mb, pl, pr } = { ...baseBoxProps, ...boxProps }
  const { clearButton: clearButtonClass, ...classes } = useStyles({ mb })
  const [searchText, setSearchText] = useState("")
  const [sentText, setSentText] = useState("")

  useEffect(() => {
    if (debounce && sentText !== searchText) {
      const timer = setTimeout(() => {
        setSentText(searchText)
        onChange(searchText)
      }, debounce)
      return () => clearTimeout(timer)
    }
  })

  useMountEffect(() => {
    if (initialValue) {
      setSearchText(initialValue)
    }
  })

  const handleChange = (event) => {
    setSearchText(event.target.value)
    if (onChange && !debounce) {
      onChange(event.target.value, event)
    }
  }

  const handleClear = (event) => {
    setSearchText("")
    if (onChange) {
      onChange("", event)
    }
  }

  const displayLoading = loading && searchText

  return (
    <FlexBox pl={pl} pr={pr} {...boxProps}>
      <OutlinedInput
        placeholder={placeholder}
        value={searchText}
        onChange={handleChange}
        fullWidth
        classes={classes}
        startAdornment={
          <InputAdornment position="start">
            {!displayLoading && <SearchIcon />}
            {displayLoading && <LoadingSpinner size="24px" opacity={1} delay={false} />}
          </InputAdornment>
        }
        endAdornment={
          searchText ? (
            <InputAdornment position="end">
              <IconButton onClick={handleClear} className={clearButtonClass}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ) : null
        }
        inputProps={{
          "data-cy": cy,
          ...inputProps,
        }}
        {...rest}
      />
    </FlexBox>
  )
}

export default SearchInput
