import React, { useEffect, useState } from "react"
import { MissingLocationAccessBlockquote } from ".."
import { toId } from "../../utils"
import { useAuth } from "../../services"

const MissingLocationAccess = ({ edit, onChange, subject }) => {
  const {
    settings: { locations },
  } = useAuth()
  const [show, setShow] = useState(false)

  useEffect(() => {
    const newShow = edit?.locations?.some((item) => !locations.some((ul) => toId(ul) === toId(item)))
    setShow(newShow)
    onChange && onChange(newShow)
  }, [edit, locations, onChange])

  return <MissingLocationAccessBlockquote show={show} subject={subject} />
}

export { MissingLocationAccess }
