import { useQuery, gql } from "@apollo/client"
import { ROLE_ADMINABLE_FIELDS, ROLE_FIELDS } from "./fragments/roleFieldsFragment"

const ROLES_QUERY = gql`
  query AdminableRoles {
    roles {
      ...RoleFields
      ...RoleAdminableFields
    }
  }
  ${ROLE_FIELDS}
  ${ROLE_ADMINABLE_FIELDS}
`

const ROLE_QUERY = gql`
  query AdminableRole($id: ID!) {
    role(id: $id) {
      ...RoleFields
      ...RoleAdminableFields
    }
  }
  ${ROLE_FIELDS}
  ${ROLE_ADMINABLE_FIELDS}
`

const useQueryAdminableRoles = () => useQuery(ROLES_QUERY)

const useQueryAdminableRole = (options) => useQuery(ROLE_QUERY, options)

export { useQueryAdminableRoles, useQueryAdminableRole }
