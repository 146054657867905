import React, { useState } from "react"
import { makeStyles, useTheme } from "@material-ui/styles"
import { List, ListItem, ListItemAvatar, ListItemText, Grid, Box, useMediaQuery } from "@material-ui/core"
import { Avatar, TimeAgo, DownloadList, ImagesViewer } from ".."
import { HubItemSection } from "./HubItemSection"

const useStyles = makeStyles((theme) => ({
  expand: {
    fontSize: 14,
    textDecoration: "underline",
    cursor: "pointer",
  },
  item: {
    margin: 0,
    padding: 0,
  },
  uploadsItem: {
    margin: ({ xs }) => `${theme.spacing(-1)}px 0 ${theme.spacing(2)}px ${xs ? 0 : 40 + theme.spacing(2)}px`,
  },
  text: {
    backgroundColor: "#f3f3f3",
    borderRadius: 4,
    padding: theme.spacing(1),
    overflowWrap: "anywhere",
  },
  userAndAge: {
    color: theme.palette.text.primary,
    fontSize: 16,
    lineHeight: 1.25,
    fontWeight: "600",
  },
  age: {
    color: theme.palette.text.secondary,
    fontSize: 13,
    fontWeight: "normal",
    marginLeft: theme.spacing(1),
  },
  commentText: {
    color: theme.palette.text.primary,
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  },
  avatar: {
    marginTop: 0,
    width: 40,
    height: 40,
  },
}))

const initialCountMax = 3

const CommentList = ({ subject }) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const classes = useStyles({ xs })

  const [expanded, setExpanded] = useState(false)

  if (!subject?.comments?.length) return null

  const hiddenCount = subject.comments.length - initialCountMax

  return (
    <HubItemSection>
      {!expanded && hiddenCount > 0 && (
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className={classes.expand}
          onClick={() => setExpanded(true)}
        >
          <Grid item />
          <Grid item>
            Show {hiddenCount} more comment{hiddenCount > 1 ? "s" : ""}
          </Grid>
        </Grid>
      )}
      <List>
        {(expanded ? subject.comments : subject.comments.slice(hiddenCount < 0 ? 0 : hiddenCount)).map(
          (comment, index) => (
            <Box key={index}>
              <ListItem className={classes.item} alignItems="flex-start">
                <Box flexGrow={1}>
                  <Box display="flex" flexDirection="row" flexGrow={1}>
                    <ListItemAvatar>
                      <Avatar className={classes.avatar} {...comment.author} />
                    </ListItemAvatar>
                    <ListItemText
                      className={classes.text}
                      primary={
                        <span className={classes.userAndAge}>
                          {comment.author.firstName} {comment.author.lastName}
                          <TimeAgo date={comment.createdAt} className={classes.age} />
                        </span>
                      }
                      secondary={<span className={classes.commentText}>{comment.content}</span>}
                    />
                  </Box>
                  {!!comment.uploads?.length && (
                    <Box className={classes.uploadsItem}>
                      <DownloadList uploads={comment.uploads} />
                      <ImagesViewer uploads={comment.uploads} />
                    </Box>
                  )}
                </Box>
              </ListItem>
            </Box>
          )
        )}
      </List>
    </HubItemSection>
  )
}

export { CommentList }
