import React from "react"
import { Box, Grid } from "@material-ui/core"
import { Avatar } from ".."
import { MentionListItem, useMentionListItemStyles } from "../Mentions"

const UserMentionListItem = ({ ...props }) => {
  const classes = useMentionListItemStyles()

  return (
    <MentionListItem>
      <Grid item>
        <Avatar className={classes.avatar} {...props.item} />
      </Grid>
      <Grid item>
        <Box>{props.item.fullName}</Box>
      </Grid>
    </MentionListItem>
  )
}

export default UserMentionListItem
