import Base from "./Base"
import LocalDev from "./LocalDev"

const config = {
  ...LocalDev,
  clientKiosk: true,

  showConfigInTitle: true,
  configTitle: "[LOCALDEV KIOSK]",

  auth: LocalDev.auth ? { ...LocalDev.auth } : { ...Base.auth },
}

// config.auth.idleTimeoutMs = 1000 * 60 * 30
config.auth.idleTimeoutMs = 1000 * 2660 // 60 seconds

export default config
