import React from "react"
import { CompletionChartCard } from "./CompletionChartCard"

const JobsCompletionChartCard = ({ jobs: jobsData, adminOnly, title, detail }) => {
  // calculate stats
  const overall = { total: 0, completed: 0 }
  const tasks = { name: "Tasks", total: 0, completed: 0 }
  const processes = { name: "Processes", total: 0, completed: 0 }
  if (jobsData) {
    if (!jobsData.length) return null

    overall.total = jobsData.length
    jobsData.forEach((job) => {
      const isCompleted = job.status.completed
      if (job.process) {
        processes.total += 1
        if (isCompleted) processes.completed += 1
      } else {
        tasks.total += 1
        if (isCompleted) tasks.completed += 1
      }
      if (isCompleted) overall.completed += 1
    })
  }

  const sets = []
  if (processes.total) sets.push(processes)
  if (tasks.total) sets.push(tasks)

  return (
    <CompletionChartCard
      title={typeof title === "function" ? title({ overall, tasks, processes }) : title}
      detail={typeof detail === "function" ? detail({ overall, tasks, processes }) : detail}
      loading={!jobsData}
      sets={sets}
      adminOnly={adminOnly}
    />
  )
}

export { JobsCompletionChartCard }
