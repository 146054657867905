export const disableBackdropClick = (onClose) => (event, reason) => {
  if (reason === "backdropClick") {
    return false
  }

  if (reason === "escapeKeyDown") {
    return false
  }

  if (typeof onClose === "function") {
    onClose(event)
  }
}
