import * as Sentry from "@sentry/browser"

const offsetLimitLoadMore = (dataPropName, limit, result) => {
  return async () => {
    try {
      await result.fetchMore({
        variables: {
          ...result.variables,
          offset: result.data[dataPropName].list.length,
          limit,
        },
      })
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setExtra("dataPropName", dataPropName)
        scope.setExtra("limit", limit)
        Sentry.captureException(error)
      })
    }
  }
}

export { offsetLimitLoadMore }
