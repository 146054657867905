import Config from "react-global-configuration"
import moment from "moment"

const defaultResponse = { user: { currency: "usd" } }

const fetchLocalisation = async () => {
  const { localisationUrl } = Config.get("services")

  try {
    const response = await fetch(`${localisationUrl}?${moment().format("YYYYMMDDTHHmmss")}`)
    if (response.ok) {
      const json = await response.json()
      console.log("[SERVICES]", "Localisation", json)
      return json
    }
  } catch (e) {
    console.log("[SERVICES]", `Failed to fetch localisation with error ${e.message}`)
    console.log(e)
  }
  return { ...defaultResponse }
}

export { fetchLocalisation }
