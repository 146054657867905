import React, { useMemo, useState } from "react"
import { Box, Button, makeStyles } from "@material-ui/core"
import { toId } from "../../utils"
import { AccreditationOutlinedSelect, PersonAccreditationActionCard } from "../Accreditation"
import { FieldSectionHeading } from "../Headings"
import { RowBox } from "../Boxes"
import {
  useLazyQueryAdminableUser,
  useMutationCreateUserAccreditation,
  useMutationDeleteUserAccreditation,
  useQueryUserAccreditations,
} from "../../data"
import { LoadingSpinner } from "../LoadingSpinner"
import { accreditationAttentionStatuses } from "../../areas/account/Accreditation"

const useStyles = makeStyles((theme) => ({
  requiresAttention: {
    color: theme.palette.error.main,
  },
}))

const PersonAccreditation = ({ user }) => {
  const classes = useStyles()
  const [accreditation, setAccreditation] = useState("")
  const {
    data,
    loading: loadingUserAccreditations,
    refetch,
  } = useQueryUserAccreditations({
    variables: {
      user: toId(user),
    },
  })
  const [loadAdminableUser] = useLazyQueryAdminableUser({ variables: { id: toId(user) } })
  const [createUserAccreditation, { loading: loadingCreateUserAccreditation }] = useMutationCreateUserAccreditation()
  const [deleteUserAccreditation] = useMutationDeleteUserAccreditation()

  const attentionData = useMemo(
    () =>
      data?.userAccreditations
        .filter((item) => accreditationAttentionStatuses.includes(item.status))
        .sort((a, b) => a.accreditation.name.localeCompare(b.accreditation.name)) || [],
    [data]
  )
  const compliantData = useMemo(
    () =>
      data?.userAccreditations
        .filter((item) => !accreditationAttentionStatuses.includes(item.status))
        .sort((a, b) => a.accreditation.name.localeCompare(b.accreditation.name)) || [],
    [data]
  )

  const handleAccreditationChange = (event) => {
    setAccreditation(event.target.value)
  }

  const handleAddAccreditation = async () => {
    await createUserAccreditation({
      variables: {
        accreditation,
        user: toId(user),
      },
    })
    refetch()
    loadAdminableUser()
    setAccreditation("")
  }

  const handleRemoveAccreditation = async (item) => {
    await deleteUserAccreditation({
      variables: {
        id: toId(item),
      },
    })
    refetch()
    loadAdminableUser()
  }

  return (
    <Box mb={5} data-cy="PersonAccreditation">
      <FieldSectionHeading>Assign accreditation type</FieldSectionHeading>
      <RowBox mt={1}>
        <AccreditationOutlinedSelect
          value={accreditation}
          onChange={handleAccreditationChange}
          hasNone={false}
          disabled={loadingCreateUserAccreditation || loadingUserAccreditations}
          filterActiveAccreditations={data?.userAccreditations.map((item) => toId(item.accreditation)) || null}
        />
        <Box ml={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddAccreditation}
            disabled={!accreditation || loadingCreateUserAccreditation}
            data-cy="Button-add-accreditation"
          >
            {!loadingCreateUserAccreditation && <>Assign</>}
            {loadingCreateUserAccreditation && <LoadingSpinner size="24px" delay={false} />}
          </Button>
        </Box>
      </RowBox>

      {Boolean(attentionData.length) && (
        <Box mt={3} data-cy="PersonAccreditation-attention-list">
          <FieldSectionHeading className={classes.requiresAttention}>
            Requires attention ({attentionData.length})
          </FieldSectionHeading>
          {attentionData.map((userAccreditation) => (
            <PersonAccreditationActionCard
              key={toId(userAccreditation)}
              userAccreditation={userAccreditation}
              onDelete={() => handleRemoveAccreditation(userAccreditation)}
            />
          ))}
        </Box>
      )}

      {Boolean(compliantData.length) && (
        <Box mt={3} data-cy="PersonAccreditation-current-list">
          <FieldSectionHeading>Up to date</FieldSectionHeading>
          {compliantData.map((userAccreditation) => (
            <PersonAccreditationActionCard
              key={toId(userAccreditation)}
              userAccreditation={userAccreditation}
              onDelete={() => handleRemoveAccreditation(userAccreditation)}
            />
          ))}
        </Box>
      )}
    </Box>
  )
}

export { PersonAccreditation }
