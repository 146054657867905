import React, { Suspense } from "react"
import { LoadingSpinner } from "../LoadingSpinner"

const InnerListVirtualizer = React.lazy(() => import("./InnerListVirtualizer"))

const ListWindowVirtualizer = ({ items, itemContent, ...props }) => {
  if (!items || items.length === 0) {
    return null
  }

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <InnerListVirtualizer useWindowScroll items={items} itemContent={itemContent} {...props} />
    </Suspense>
  )
}

export { ListWindowVirtualizer }
