import Base from "./Base"
import Local from "./Local"

const config = {
  ...Local,
  clientKiosk: true,

  showConfigInTitle: true,
  configTitle: "[KIOSK LOCAL]",

  auth: Local.auth ? { ...Local.auth } : { ...Base.auth },
}

// config.auth.idleTimeoutMs = 1000 * 60 * 30
config.auth.idleTimeoutMs = 1000 * 2660 // 60 seconds

export default config
