import { useMutation, gql } from "@apollo/client"

const DELETE_TRAINING_MODULE_MUTATION = gql`
  mutation DeleteTrainingModule($id: ID!) {
    deleteTrainingModule(id: $id)
  }
`

const useMutationDeleteTrainingModule = () => useMutation(DELETE_TRAINING_MODULE_MUTATION)

export { useMutationDeleteTrainingModule }
