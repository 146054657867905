import { gql, useMutation } from "@apollo/client"
import { FOOD_ITEM_FIELDS } from "./fragments/foodItemFieldsFragment"
import { toId } from "../../utils"
import { FOOD_ITEMS_COUNT_QUERY } from "./useQueryFoodItems"

const CREATE_FOOD_ITEM_MUTATION = gql`
  mutation CreateFoodItem($input: FoodItemMutationInput!) {
    createFoodItem(input: $input) {
      ...FoodItemFields
    }
  }
  ${FOOD_ITEM_FIELDS}
`

const UPDATE_FOOD_ITEM_MUTATION = gql`
  mutation UpdateFoodItem($id: ID!, $input: FoodItemMutationInput!) {
    foodItem(id: $id) {
      update(input: $input) {
        ...FoodItemFields
      }
    }
  }
  ${FOOD_ITEM_FIELDS}
`
const DELETE_FOOD_ITEM_MUTATION = gql`
  mutation DeleteFoodItem($id: ID!) {
    foodItem(id: $id) {
      delete
    }
  }
`

const useMutationCreateFoodItem = (options) =>
  useMutation(CREATE_FOOD_ITEM_MUTATION, { ...options, refetchQueries: [FOOD_ITEMS_COUNT_QUERY] })

const useMutationUpdateFoodItem = (options) => useMutation(UPDATE_FOOD_ITEM_MUTATION, options)

const useMutationDeleteFoodItem = (options) =>
  useMutation(DELETE_FOOD_ITEM_MUTATION, {
    ...options,
    refetchQueries: [FOOD_ITEMS_COUNT_QUERY],
    update(cache, { errors }, { variables: { id } }) {
      if (errors) {
        return
      }
      const normalizedId = cache.identify({ id: toId(id), __typename: "FoodItem" })
      cache.evict({ id: normalizedId })
      cache.gc()
    },
  })

export { useMutationCreateFoodItem, useMutationUpdateFoodItem, useMutationDeleteFoodItem }
