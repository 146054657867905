import React, { useState } from "react"
import {
  Button,
  TextField,
  Typography,
  Box,
  Grid,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  MenuItem,
  Step,
  StepLabel,
  Stepper,
  OutlinedInput,
  Divider,
  useMediaQuery,
} from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
// import MuiPhoneNumber from "material-ui-phone-number"
import Config from "react-global-configuration"
import Validator from "@impelsys/validatorjs"
import moment from "moment-timezone"
import * as Sentry from "@sentry/browser"
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { Check, ArrowBack } from "@material-ui/icons"
import { useApolloClient } from "@apollo/client"
import { useHistory, useParams } from "react-router-dom"
import { Trans } from "@lingui/macro"
import {
  LoadingSpinner,
  LogoFull,
  Icon,
  ModuleHeading,
  OutlinedSelect,
  LinkButton,
  StripeCardElement,
  FormatDate,
  TextDivider,
  RowBox,
} from "../../components"
import { useFormUtils, useMountEffect } from "../../utils"
import { useMutationCreateOrganisation, ORGANISATION_INDUSTRIES, fetchLocalisation } from "../../data"
import { ErrorLabel } from "../../components/ErrorBoundary"
import { SignupSplash } from "../../components/SignupSplash/SignupSplash"
import { useAuth } from "../../services"

const initialStep = 0

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    backgroundImage: "linear-gradient(to left, #252763, #1a1b43)",
  },
  left: {
    // backgroundImage: "linear-gradient(to left, #252763, #1a1b43)",
    minHeight: "100vh",
    // padding: ({ smDown }) => smDown ? "30px 50px" : "60px 100px",
    color: "#ffffff",
    display: "flex",
    flexGrow: 1,
  },
  heroContent: {
    // transform: "scale(0.8)",
    width: "75%",
  },
  right: {
    backgroundColor: "#ffffff",
    padding: ({ smDown }) => (smDown ? "30px 50px" : "60px 100px"),
  },
  splash: {
    width: "100%",
    height: "100%",
  },
  title: {
    fontSize: ({ lgDown }) => (lgDown ? 28 : 36),
    fontWeight: 700,
    lineHeight: ({ lgDown }) => (lgDown ? "30px" : "50px"),
    paddingLeft: ({ lgDown }) => theme.spacing(lgDown ? 2 : 3),
    marginBottom: ({ lgDown }) => theme.spacing(lgDown ? 2 : 3),
  },
  main: {
    textAlign: "center",
  },
  sub: {
    textAlign: "center",
  },
  check: {
    color: "#18CE99",
  },
  benefits: {
    paddingBottom: ({ lgDown }) => theme.spacing(lgDown ? 0 : 1),
  },
  checkText: {
    fontSize: ({ lgDown }) => (lgDown ? 16 : 20),
    lineHeight: ({ lgDown }) => (lgDown ? "20px" : "36px"),
  },
  highlight: {
    color: theme.palette.primary.main,
  },
  form: {
    margin: "auto",
    maxWidth: "650px",
  },
  // phoneCode: {
  //   backgroundColor: "#000",
  // },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  back: {
    color: theme.palette.text.primary,
    "&:disabled": {
      color: theme.palette.text.disabled,
      cursor: "default",
    },
  },
  external: {
    marginBottom: theme.spacing(2),
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.buttons.light.background,
  },
  externalLogo: {
    width: 18,
    height: 18,
    marginRight: theme.spacing(1),
  },
  stepperWrap: {
    width: 250,
    margin: "0 auto",
  },
  stepper: {
    padding: 0,
    marginBottom: theme.spacing(2),
  },
}))

const useStepIconStyles = makeStyles((theme) => ({
  root: {
    color: "#f3f3f3",
  },
  text: {
    fill: "Transparent",
  },
  completed: {
    color: `${theme.palette.success.main} !important`,
  },
}))

const initialData = {
  firstName: "",
  lastName: "",
  email: "",
  name: "",
  employees: "",
  industry: "",
  role: "",
  phoneMobile: "",
}

const fpr = window.fpr

const Signup = () => {
  const theme = useTheme()
  const { provider } = useParams()
  const history = useHistory()
  const { processSignupResponse } = useAuth(useApolloClient())
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"))
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"))
  const xl = useMediaQuery(theme.breakpoints.only("xl"))
  const lg = useMediaQuery(theme.breakpoints.only("lg"))
  const classes = useStyles({ smDown, lgDown, lg, xl })
  const stepIconClasses = useStepIconStyles()

  const [step, setStep] = useState(initialStep)
  const [data, setData] = useState(initialData)
  const [loading, setLoading] = useState(false)
  const [createOrganisation] = useMutationCreateOrganisation()

  useMountEffect(() => {
    if (provider) {
      const handleProcessResponse = async () => {
        try {
          const result = await processSignupResponse(provider)
          const {
            profile: { email, given_name: firstName, family_name: lastName },
          } = result
          setData({
            email,
            firstName,
            lastName,
          })
          if (email && firstName && lastName) setStep(1)
        } finally {
          history.push("/signup")
        }
      }
      handleProcessResponse()
    }
  })

  useMountEffect(() => {
    const loadLocalisation = async () => {
      const localisation = await fetchLocalisation()
      setData((prev) => ({
        ...prev,
        currency: localisation.user.currency,
      }))
    }
    loadLocalisation()
  })

  const handleNext = async (stepData) => {
    setData({ ...data, ...stepData })
    setLoading(true)
    if (step === 2) {
      const result = await createOrganisation({
        variables: {
          timeZone: moment.tz.guess(),
          ...data,
          ...stepData,
        },
      })
      if (!result.errors) {
        handleReferral(data.email, result)
      }
    }
    setStep(step + 1)
    setLoading(false)
  }

  const handleFinish = async (stepData) => {
    setData({ ...data, ...stepData })
    setLoading(true)
    const result = await createOrganisation({
      variables: {
        timeZone: moment.tz.guess(),
        ...data,
        ...stepData,
      },
    })
    if (result.errors) {
      setLoading(false)
    } else {
      handleReferral(data.email, result)
      setStep(step + 1)
      setLoading(false)
    }
  }

  const handleReferral = (email, result) => {
    try {
      fpr && fpr("referral", { email, uid: result.data.createOrganisation.customer })
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const handleBack = () => {
    setStep(step - 1)
  }

  const CheckListItem = ({ text }) => (
    <ListItem className={classes.benefits}>
      <ListItemIcon>
        <Check className={classes.check} />
      </ListItemIcon>
      <ListItemText>
        <Box component="span" className={classes.checkText}>
          {text}
        </Box>
      </ListItemText>
    </ListItem>
  )

  if (provider)
    return (
      <Box display="flex" justifyContent="center">
        <LoadingSpinner size={60} />
      </Box>
    )

  return (
    <Grid container className={classes.root}>
      {lgUp && (
        <Grid item lg={6} xl={8} className={classes.left}>
          <Box
            className={classes.heroContent}
            display="flex"
            mx="auto"
            my="auto"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box display="flex" justifyContent="center">
              <SignupSplash className={classes.splash} />
            </Box>
            <Box>
              <Typography className={classes.title}>Let's get your free trial started</Typography>
              <List>
                <CheckListItem
                  text={
                    <>
                      14 days of{" "}
                      <Box component="span" className={classes.highlight}>
                        unlimited access.
                      </Box>
                    </>
                  }
                />
                <CheckListItem
                  text={
                    <>
                      No strings attached.{" "}
                      <Box component="span" className={classes.highlight}>
                        Cancel your trial online at anytime.
                      </Box>
                    </>
                  }
                />
                <CheckListItem
                  text={
                    <>
                      <Box component="span" className={classes.highlight}>
                        Industry-leading resources
                      </Box>{" "}
                      to build your business process templates.
                    </>
                  }
                />
                <CheckListItem
                  text={
                    <>
                      <Box component="span" className={classes.highlight}>
                        Direct support
                      </Box>{" "}
                      from our customer success team.
                    </>
                  }
                />
              </List>
            </Box>
          </Box>
        </Grid>
      )}
      <Grid item xs={12} md={12} lg={6} xl={4} className={classes.right}>
        <Box className={classes.form}>
          <Box className={classes.stepperWrap}>
            <Stepper activeStep={step} classes={{ root: classes.stepper }}>
              <Step>
                <StepLabel StepIconProps={{ classes: stepIconClasses }} />
              </Step>
              <Step>
                <StepLabel StepIconProps={{ classes: stepIconClasses }} />
              </Step>
              <Step>
                <StepLabel StepIconProps={{ classes: stepIconClasses }} />
              </Step>
            </Stepper>
          </Box>

          <Box mt={1.5} mb={smDown ? 2 : 4} display="flex" justifyContent="center">
            {step === 0 && <>Your details</>}
            {step === 1 && <>Your company</>}
            {step === 2 && <>Billing information</>}
            {step === 3 && <>Complete!</>}
          </Box>

          <Box mb={smDown ? 2 : 4} display="flex" justifyContent="center">
            <LogoFull width={smDown ? 250 : 300} />
          </Box>
          {step === 0 && <Step0 data={data} onNext={handleNext} loading={loading} history={history} />}
          {step === 1 && <Step1 data={data} onNext={handleNext} onBack={handleBack} loading={loading} />}
          {step === 2 && <Step2 data={data} onNext={handleFinish} onBack={handleBack} loading={loading} />}
          {step === 3 && <StepComplete data={data} loading={loading} />}
        </Box>
      </Grid>
    </Grid>
  )
}

const Step0 = ({ history, data, loading, onNext }) => {
  const classes = useStyles()
  const auth = useAuth(useApolloClient())
  const { isValid, debounceTouched } = useFormUtils()

  const [firstName, setFirstName] = useState(data.firstName)
  const [lastName, setLastName] = useState(data.lastName)
  const [email, setEmail] = useState(data.email)

  const [loadingGoogle, setLoadingGoogle] = useState(false)

  const [emailTouched, setEmailTouched] = useState(false)
  const [emailValid, setEmailValid] = useState(false)

  useMountEffect(() => {
    if (email) handleValidateEmail(email)
  })

  const handleNext = () => {
    onNext({
      firstName,
      lastName,
      email,
      // password
    })
  }

  const handleEmailChange = (e) => {
    const value = e.target.value
    setEmail(value)
    handleValidateEmail(value)
  }

  const handleValidateEmail = (value) => {
    setEmailValid(new Validator({ value }, { value: "required|email" }).passes())
  }

  const handleHasAccount = () => {
    history.push("/")
  }

  const handleLoginWithGoogle = async () => {
    try {
      setLoadingGoogle(true)
      await auth.signupWithGoogle()
    } catch (err) {
      setLoadingGoogle(false)
    }
  }

  const formValid = () => isValid(firstName) && isValid(lastName) && emailValid

  const isFormValid = formValid()

  return (
    <>
      <Box mb={4}>
        <ModuleHeading noMargin className={classes.main} rowBoxProps={{ justifyContent: "center" }}>
          Get started for free
        </ModuleHeading>
        <Typography variant="body1" className={classes.sub}>
          Sign up now and start exploring the platform.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="First name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            disabled={loading}
            data-cy="TextField-user-firstName"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="Last name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            disabled={loading}
            data-cy="TextField-user-lastName"
            required
          />
        </Grid>
      </Grid>

      <Box mt={2} mb={1}>
        <TextField
          variant="outlined"
          fullWidth
          label="Email"
          type="email"
          value={email}
          onChange={handleEmailChange}
          onBlur={() => debounceTouched(setEmailTouched)}
          error={emailTouched && !emailValid}
          autoComplete="email"
          disabled={loading}
          data-cy="TextField-user-email"
          required
        />
      </Box>

      <FormButtons loading={loading} disabled={loading || !isFormValid || !data.currency} onNext={handleNext} />

      <TextDivider>
        <Trans>OR</Trans>
      </TextDivider>

      <Box my={2}>
        <Button
          type="button"
          fullWidth
          variant="contained"
          className={classes.external}
          size="large"
          disabled={loading}
          onClick={handleLoginWithGoogle}
        >
          {!loadingGoogle && <Icon name="google" className={classes.externalLogo} />}
          {loadingGoogle && <LoadingSpinner size="24px" className={classes.externalLogo} />}
          <span>Sign up with Google</span>
        </Button>

        {/* <Button
              fullWidth
              variant="contained"
              className={classes.external}
              size="large"
              disabled={loading}
              onClick={() => alert("Service not currently available")}
            >
              <Icon name="facebook" className={classes.externalLogo} />
              <span>Sign up with Facebook</span>
            </Button>             */}
      </Box>
      <FormButtons
        loading={loading}
        disabled={loading}
        backLabel="I already have an account"
        onBack={handleHasAccount}
      />
    </>
  )
}

const Step1 = ({ data, loading, onNext, onBack }) => {
  const classes = useStyles()
  const { isValid } = useFormUtils()

  const [name, setName] = useState(data.name)
  const [employees, setEmployees] = useState(data.employees)
  const [industry, setIndustry] = useState(data.industry)
  const [role] = useState(data.role)
  // const [achieve, setAchieve] = useState("")
  const [phoneMobile, setPhoneMobile] = useState("")

  const handleNext = () => {
    onNext({
      name,
      employees,
      industry,
      role,
      // achieve,
      phoneMobile,
    })
  }

  const formValid = () => isValid(name) && isValid(employees) && isValid(industry)
  // && isValid(role)
  // && isValid(achieve)
  // && isValid(phoneMobile)

  const isFormValid = formValid()

  return (
    <>
      <Box mb={4}>
        <ModuleHeading noMargin className={classes.main} rowBoxProps={{ justifyContent: "center" }}>
          Almost done
        </ModuleHeading>
        <Typography variant="body1" className={classes.sub}>
          Tell us more about your company so we can tailor the experience for your business.
        </Typography>
      </Box>
      <Box mb={2}>
        <TextField
          variant="outlined"
          fullWidth
          label="What is your business name?"
          value={name}
          onChange={(e) => setName(e.target.value)}
          disabled={loading}
          data-cy="TextField-organisation-name"
          required
        />
      </Box>
      <Box mb={2}>
        <OutlinedSelect
          native={false}
          value={employees}
          label="How many employees do you have?"
          onChange={(e) => setEmployees(e.target.value)}
          disabled={loading}
          data-cy="OutlinedSelect-organisation-employees"
          required
        >
          <MenuItem value="1-50">1-50</MenuItem>
          <MenuItem value="51-200">51-200</MenuItem>
          <MenuItem value="200">200+</MenuItem>
        </OutlinedSelect>
      </Box>
      <Box mb={2}>
        <OutlinedSelect
          native={false}
          label="Select your industry"
          value={industry}
          onChange={(event) => setIndustry(event.target.value)}
          disabled={loading}
          data-cy="OutlinedSelect-organisation-industry"
          required
        >
          {ORGANISATION_INDUSTRIES.map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </OutlinedSelect>
      </Box>
      {/* <Box mb={2}>
        <OutlinedSelect
          native={false}
          value={role}
          label="Your role"
          onChange={e => setRole(e.target.value)}
          disabled={loading}
        >
          <MenuItem value="The business owner">The business owner</MenuItem>
          <MenuItem value="IT manager">IT manager</MenuItem>
          <MenuItem value="Operations manager">Operations manager</MenuItem>
          <MenuItem value="Other">Other</MenuItem>
        </OutlinedSelect>
      </Box> */}
      {/* <Box mb={2}>
        <OutlinedSelect
          native={false}
          value={achieve}
          label="What are you trying to achieve?"
          onChange={e => setAchieve(e.target.value)}
        >
          <MenuItem>1</MenuItem>
          <MenuItem>2</MenuItem>
          <MenuItem>3</MenuItem>
        </OutlinedSelect>
      </Box> */}
      <TextField
        variant="outlined"
        fullWidth
        label="Mobile number"
        value={phoneMobile}
        onChange={(e) => setPhoneMobile(e.target.value)}
        disabled={loading}
        data-cy="TextField-organisation-phoneMobile"
      />

      {/* Disable this 100kb dep for now */}
      {/* <MuiPhoneNumber
        defaultCountry="au"
        variant="outlined"
        enableLongNumbers
        dropdownClass={classes.phoneCode}
        label="Mobile number"
        fullWidth
        value={phoneMobile}
        onChange={setPhoneMobile}
        inputProps={{ "data-cy": "TextField-organisation-phoneMobile" }}
        disabled={loading}
      /> */}
      {/* <Typography
        variant="caption"
      >
        We'll send you a link to download the Operandio app
      </Typography> */}

      <FormButtons loading={loading} disabled={loading || !isFormValid} onNext={handleNext} onBack={onBack} />
    </>
  )
}

const cardElementOptions = {
  style: {
    base: {
      fontSize: "16px",
    },
    invalid: {
      color: "#9e2146",
    },
  },
}

const Step2 = ({ loading: parentLoading, onNext, onBack }) => {
  const classes = useStyles()
  const configLinks = Config.get("links")
  const chargeDate = moment().add(14, "days")
  const stripe = useStripe()
  const elements = useElements()
  const { isValid } = useFormUtils()

  const [name, setName] = useState("")
  const [card, setCard] = useState("")

  const [error, setError] = useState("")
  const [busy, setBusy] = useState(false)

  const [cardError, setCardError] = useState(null)

  const handleNext = async () => {
    if (isFormValid()) {
      setBusy(true)

      const cardElement = elements.getElement(CardElement)

      const result = await stripe.createToken(cardElement, {
        name,
      })

      if (result.error) {
        setError(result.error.message)
      } else {
        onNext({
          cardToken: result.token.id,
        })
      }
      setBusy(false)
    }
  }

  const handleElementChange =
    (setField, setFieldError) =>
    ({ complete: fieldComplete, error = { message: null } }) => {
      setField(fieldComplete)
      setFieldError(error.message)
    }

  const hasError = (message) => message !== null

  const isFormValid = () => isValid(name) && isValid(card)

  const complete = isFormValid()

  const loading = parentLoading || busy

  return (
    <>
      <Box mb={4}>
        <ModuleHeading noMargin className={classes.main} rowBoxProps={{ justifyContent: "center" }}>
          Try us free for 14 days
        </ModuleHeading>
        <Typography variant="body1" className={classes.sub}>
          No commitments. Cancel your trial online at anytime.
        </Typography>
        <ErrorLabel message={error} />
      </Box>
      <Box mb={2}>
        <TextField
          variant="outlined"
          fullWidth
          label="Cardholder name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          data-cy="TextField-card-name"
          disabled={loading}
        />
      </Box>
      <Box mb={2}>
        <OutlinedInput
          fullWidth
          inputComponent={StripeCardElement}
          error={hasError(cardError)}
          inputProps={{
            onChange: handleElementChange(setCard, setCardError),
            options: {
              disabled: loading,
              ...cardElementOptions,
            },
          }}
          data-cy="OutlinedInput-card-details"
          disabled={loading}
        />
        <ErrorLabel message={cardError} />
      </Box>
      {/* <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <OutlinedInput
              fullWidth
              inputComponent={StripeCardExpiry}
              error={hasError(expiryError)}
              inputProps={{
                onChange: handleElementChange(setExpiry, setExpiryError),
                options: {
                  disabled: loading,
                  ...cardElementOptions
                }
              }}
              disabled={loading}
            />
            <ErrorLabel message={expiryError} />
          </Grid>
          <Grid item xs={6}>
            <OutlinedInput
              fullWidth
              inputComponent={StripeCardCvc}
              error={hasError(cvcError)}
              inputProps={{
                onChange: handleElementChange(setCvc, setCvcError),
                options: {
                  disabled: loading,
                  ...cardElementOptions
                }
              }}
              disabled={loading}
            />
            <ErrorLabel message={cvcError} />
          </Grid>
        </Grid>
      </Box> */}
      <Divider />
      <Box mt={2}>
        <Typography variant="body2">
          You will not be charged until your trial ends on <FormatDate value={chargeDate} />. Your subscription fee may
          be subject to applicable taxes. By starting a trial, you agree to our{" "}
          <a href={configLinks.terms} rel="noopener noreferrer" target="_blank">
            Terms of Service
          </a>{" "}
          and{" "}
          <a href={configLinks.privacy} rel="noopener noreferrer" target="_blank">
            Privacy Policy
          </a>
          .
        </Typography>
      </Box>
      <FormButtons
        loading={loading}
        disabled={loading || !complete}
        nextLabel="Start free trial"
        onNext={handleNext}
        onBack={onBack}
      />
    </>
  )
}

const StepComplete = ({ data, loading }) => {
  const classes = useStyles()
  const { emailSupport } = Config.get("links")

  return (
    <>
      {loading && (
        <Box display="flex" justifyContent="center">
          <LoadingSpinner size={60} />
        </Box>
      )}
      {!loading && (
        <Box mb={2}>
          <ModuleHeading noMargin className={classes.main} rowBoxProps={{ justifyContent: "center" }}>
            Welcome aboard, {data.firstName}!
          </ModuleHeading>
          <Typography variant="body1" className={classes.sub}>
            We have sent an email to <span className={classes.highlight}>{data.email}</span>.
            <br />
            <br />
            <strong>Please follow the instructions in your email to complete activating your account.</strong>
            <br />
            <br />
            If you don't receive your email within a few minutes, please check your junk / spam folders.
            <br />
            <br />
            <a
              href={`mailto:${emailSupport}?subject=New%20Signup%20Problem%20-%20${data.firstName}%20${data.lastName}`}
            >
              Contact us
            </a>{" "}
            if further problems receiving your welcome email.
          </Typography>
        </Box>
      )}
    </>
  )
}

const FormButtons = ({ loading, disabled, nextLabel, backLabel, onNext, onBack }) => {
  const classes = useStyles()

  const handleNext = () => {
    if (onNext) onNext()
  }
  const handleBack = () => {
    onBack()
  }

  return (
    <>
      {onNext && !loading && (
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          size="large"
          onClick={handleNext}
          disabled={disabled}
          data-cy="Button-next"
        >
          <span>{nextLabel || "Continue"}</span>
        </Button>
      )}
      {onNext && loading && (
        <Button fullWidth variant="contained" color="primary" className={classes.submit} size="large" disabled>
          <LoadingSpinner size="30px" delay={false} />
        </Button>
      )}
      {onBack && (
        <Box display="flex" justifyContent="center" mt={3}>
          <LinkButton className={classes.back} onClick={handleBack} disabled={loading} data-cy="LinkButton-back">
            <RowBox>
              <ArrowBack />
              <Box ml={0.5}>{backLabel || <>Back</>}</Box>
            </RowBox>
          </LinkButton>
        </Box>
      )}
    </>
  )
}

export { Signup }
