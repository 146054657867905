import React from "react"
import { FieldSectionHeading } from "../Headings"
import { AssetNotes } from "./AssetNotes"

const AssetDetails = ({ asset }) => {
  const hasEditDetails = true
  const hasNotes = true

  return (
    <>
      {hasEditDetails && hasNotes && (
        <>
          <FieldSectionHeading mb={0.5}>Notes</FieldSectionHeading>
          <AssetNotes asset={asset} />
        </>
      )}
    </>
  )
}

export { AssetDetails }
