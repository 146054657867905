import React, { memo } from "react"
import MuiLinearProgress from "@material-ui/core/LinearProgress"
import { makeStyles } from "@material-ui/styles"
import { Box } from "@material-ui/core"
import { RowBox } from "../Boxes"

const useLinearProgressStyles = makeStyles((theme) => ({
  root: {
    height: 10,
    backgroundColor: `rgba(${theme.palette.primary.mainRgb},.15)`,
    borderRadius: 20,
    width: "auto",
  },
  bar: {
    borderRadius: 20,
  },
  barColorPrimary: {
    backgroundColor: ({ complete, overdue }) =>
      theme.palette.progress.bar[!complete && overdue ? "overdue" : "started"],
  },
  barColorSecondary: {
    backgroundColor: theme.palette.success.main,
  },
}))

const useStyles = makeStyles((theme) => ({
  progress: {
    flex: 1,
    marginRight: ({ showValue }) => (showValue ? 10 : 0),
  },
  value: {
    color: ({ complete, overdue }) =>
      complete ? theme.palette.success.main : theme.palette[overdue ? "error" : "primary"].main,
    fontSize: 13,
    fontWeight: "600",
    lineHeight: "16px",
  },
}))

const LinearProgress = memo(function LinearProgress({ value, showValue, overdue, title, className, onClick }) {
  const complete = value === 100
  const classes = useStyles({ complete, overdue, showValue })
  const progressClasses = useLinearProgressStyles({ complete, overdue })

  const handleClick = () => {
    onClick && onClick()
  }

  return (
    <RowBox alignItems="center" title={title} className={className} onClick={handleClick}>
      <Box className={classes.progress}>
        <MuiLinearProgress
          variant="determinate"
          value={value}
          color={!complete ? "primary" : "secondary"}
          classes={progressClasses}
        />
      </Box>
      {showValue && <Box className={classes.value}>{value}%</Box>}
    </RowBox>
  )
})

export { LinearProgress }
