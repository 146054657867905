import { Collapse, makeStyles } from "@material-ui/core"
import React, { useState } from "react"
import { Trans } from "@lingui/macro"
import { DashboardModule } from "."
import { AccreditationDashboardModule } from "../Accreditation"
import { TrainingDashboardModule } from "../Training"

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: "500",
    color: theme.palette.text.primary,
  },
  caption: {
    fontSize: 14,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(2),
    },
  },
  captionWarning: {
    fontWeight: 600,
    color: theme.palette.scheduled.main,
  },
  captionExpired: {
    fontWeight: 600,
    color: theme.palette.error.main,
  },
  overdueIcon: {
    color: "#fff",
    backgroundColor: theme.palette.error.main,
  },
  info: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },
  assignees: {
    textAlign: "right",
  },
  rightChildren: {
    height: 40,
    overflow: "visible",
  },
  progress: {
    width: 140,
  },
}))

const TrainingAccreditationDashboardModule = ({ cy = "TrainingAccreditationDashboardModule" }) => {
  const classes = useStyles()
  const [trainingLoading, setTrainingLoading] = useState(true)
  const [hasTraining, setHasTraining] = useState(false)
  const [accreditationLoading, setAccreditationLoading] = useState(true)
  const [hasAccreditation, setHasAccreditation] = useState(false)

  const handleAccreditationLoaded = (value) => {
    setHasAccreditation(value)
    setAccreditationLoading(false)
  }
  const handleTrainingLoaded = (value) => {
    setHasTraining(value)
    setTrainingLoading(false)
  }

  let title = ""
  if (hasTraining && hasAccreditation) {
    title = <Trans>Your Training & Accreditations</Trans>
  } else if (hasTraining) {
    title = <Trans>Your Training</Trans>
  } else if (hasAccreditation) {
    title = <Trans>Your Accreditations</Trans>
  }

  const hasItems = hasAccreditation || hasTraining
  const loading = trainingLoading || accreditationLoading

  return (
    <Collapse in={!loading && hasItems}>
      <DashboardModule title={{ text: title }} cy={cy}>
        <AccreditationDashboardModule classes={classes} onLoaded={handleAccreditationLoaded} />
        <TrainingDashboardModule classes={classes} onLoaded={handleTrainingLoaded} />
      </DashboardModule>
    </Collapse>
  )
}

export { TrainingAccreditationDashboardModule }
