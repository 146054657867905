const reportTypePolicies = {
  scoped: {
    ReportQueryV2: {
      reportV2: {
        merge(prev, next) {
          return { ...(prev || {}), ...next }
        },
      },
    },
  },
}

export { reportTypePolicies }
