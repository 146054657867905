import { Box } from "@material-ui/core"
import React from "react"
import { LoadingSpinner } from "./LoadingSpinner"

const PageLoadingSpinner = () => (
  <Box display="flex" justifyContent="center">
    <LoadingSpinner size={60} />
  </Box>
)

export default PageLoadingSpinner
