import { PROCESS_REPEAT } from "../enums"

const repeatProcessData = [
  {
    id: PROCESS_REPEAT.NEVER,
    label: "Does not repeat",
  },
  {
    id: PROCESS_REPEAT.DAILY,
    label: "Daily",
    singular: "Day",
    plural: "Days",
  },
  {
    id: PROCESS_REPEAT.WEEKLY,
    label: "Weekly",
    singular: "Week",
    plural: "Weeks",
    data: [
      { label: "Monday", shortLabel: "Mon", id: "monday" },
      { label: "Tuesday", shortLabel: "Tues", id: "tuesday" },
      { label: "Wednesday", shortLabel: "Wed", id: "wednesday" },
      { label: "Thursday", shortLabel: "Thurs", id: "thursday" },
      { label: "Friday", shortLabel: "Fri", id: "friday" },
      { label: "Saturday", shortLabel: "Sat", id: "saturday" },
      { label: "Sunday", shortLabel: "Sun", id: "sunday" },
    ],
  },
  {
    id: PROCESS_REPEAT.FORTNIGHTLY,
    label: "Fortnightly",
    singular: "Fortnight",
    plural: "Fortnights",
  },
  {
    id: PROCESS_REPEAT.MONTHLY,
    label: "Monthly",
    singular: "Month",
    plural: "Months",
    data: [
      { label: "January", id: "january" },
      { label: "February", id: "february" },
      { label: "March", id: "march" },
      { label: "April", id: "april" },
      { label: "May", id: "may" },
      { label: "June", id: "june" },
      { label: "July", id: "july" },
      { label: "August", id: "august" },
      { label: "September", id: "september" },
      { label: "October", id: "october" },
      { label: "November", id: "november" },
      { label: "December", id: "december" },
    ],
  },
  {
    id: PROCESS_REPEAT.YEARLY,
    label: "Yearly",
    singular: "Year",
    plural: "Years",
  },
  {
    id: PROCESS_REPEAT.ADHOC,
    label: "Ad hoc",
  },
  {
    id: PROCESS_REPEAT.UNSCHEDULED,
    label: "Unscheduled",
  },
]

export { repeatProcessData }
