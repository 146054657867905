import { gql } from "@apollo/client"

const PROCESS_SCHEDULE_FIELDS = gql`
  fragment ProcessScheduleFields on ProcessSchedule {
    id
    name
    after {
      type
      date {
        year
        month
        day
        hour
        minute
      }
    }
    due {
      type
      period
      reminders
    }
    until {
      type
      period
    }
    repeat {
      type
      frequency
      weekdays {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
      months {
        january
        february
        march
        april
        may
        june
        july
        august
        september
        october
        november
        december
      }
    }
    users {
      id
      fullName
      firstName
      lastName
      avatar {
        key
      }
    }
    groups {
      id
      name
    }
    actionSettings {
      users {
        id
        fullName
        firstName
        lastName
        avatar {
          key
        }
      }
      groups {
        id
        name
      }
    }
    author {
      id
      fullName
      firstName
      lastName
      avatar {
        key
      }
    }
    locations {
      id
      name
      timeZone
    }
    inactive
    createdAt
    updatedAt
  }
`

export { PROCESS_SCHEDULE_FIELDS }
