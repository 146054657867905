import React, { memo } from "react"
import { makeStyles } from "@material-ui/core"
import moment from "moment"
import { TooltipIcon, TimeAgo, ColumnBox, Caption } from ".."
import { USER_ACCREDITATIONS_STATUS } from "../../data"

const daysAwaitingWarning = 14

const awaitingOverdue = (statusAt) => moment(moment()).diff(statusAt, "days") > daysAwaitingWarning

const useStyles = makeStyles((theme) => ({
  iconRef: {
    display: "flex",
    justifyContent: "center",
  },
  none: {
    fill: theme.palette.text.faint,
    cursor: "pointer",
  },
  expiring: {
    fill: theme.palette.scheduled.main,
    cursor: "pointer",
  },
  expired: {
    fill: theme.palette.error.main,
    cursor: "pointer",
  },
  awaiting: {
    fill: ({ statusAt }) => (awaitingOverdue(statusAt) ? theme.palette.error.main : theme.palette.grey[400]),
    cursor: "pointer",
  },
  compliant: {
    fill: theme.palette.success.main,
    cursor: "pointer",
  },
}))

const PersonAccreditationStatus = memo(function PersonAccreditationStatus({ user, onClick }) {
  const {
    accreditation: { status, statusAt },
  } = user
  const classes = useStyles({ statusAt })

  const handleClick = () => {
    onClick && onClick(user)
  }

  if (status === USER_ACCREDITATIONS_STATUS.WARNING) {
    return (
      <TooltipIcon
        tooltip="Expiring soon"
        name="warning"
        className={classes.expiring}
        refClassName={classes.iconRef}
        onClick={handleClick}
      />
    )
  }
  if (status === USER_ACCREDITATIONS_STATUS.EXPIRED) {
    return (
      <TooltipIcon
        tooltip="Expired"
        name="warning"
        className={classes.expired}
        refClassName={classes.iconRef}
        onClick={handleClick}
      />
    )
  }
  if (status === USER_ACCREDITATIONS_STATUS.AWAITING) {
    return (
      <ColumnBox alignItems="center">
        <TooltipIcon
          tooltip={
            <>
              Awaiting upload <TimeAgo date={statusAt} />
            </>
          }
          name="accreditations-awaiting"
          className={classes.awaiting}
          refClassName={classes.iconRef}
          onClick={handleClick}
        />
        {awaitingOverdue(statusAt) && (
          <Caption mt={0.5} mb={0} tiny>
            <TimeAgo date={statusAt} compact />
          </Caption>
        )}
      </ColumnBox>
    )
  }
  if (status === USER_ACCREDITATIONS_STATUS.COMPLIANT) {
    return (
      <TooltipIcon
        tooltip="Up to date"
        name="accreditations-compliant"
        className={classes.compliant}
        refClassName={classes.iconRef}
        onClick={handleClick}
      />
    )
  }

  return (
    <TooltipIcon
      tooltip="No accreditations"
      name="none"
      className={classes.none}
      refClassName={classes.iconRef}
      onClick={handleClick}
    />
  )
})

export {
  PersonAccreditationStatus,
  useStyles as usePersonAccreditationStatusStyles,
  awaitingOverdue as accreditationAwaitingOverdue,
}
