import React, { useEffect, useState } from "react"
import { Dialog, DialogTitle, DialogContent, Box, Button, Grid, Typography, useMediaQuery } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import { useTheme } from "@material-ui/styles"
import { NavLink, useHistory, useParams } from "react-router-dom"
import moment from "moment"
import { Trans } from "@lingui/macro"
import { DialogTitleCloser } from "../Creators/DialogTitleCloser"
import { InlineTitleCloser } from "../Creators"
import { FieldSectionHeading, Icon, InlineTitle } from ".."
import { TrainingCurriculum } from "./TrainingCurriculum"
import { ColumnBox, RowBox } from "../Boxes"
import { useQueryUserTrainings } from "../../data"
import { useAuth } from "../../services"
import { toId } from "../../utils"
import { IconMessage } from "../Lists"
import TimeAgo from "../TimeAgo"
import { FormatDate } from "../Format"

const closePath = "/knowledge"

const TrainingViewer = ({ isInline }) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const [thisTraining, setTraining] = useState(null)
  const history = useHistory()
  const { id } = useParams()
  const {
    principal: { userID: user },
    hasFeature,
  } = useAuth()
  const linear = !hasFeature("training_nonlinear")
  const { data } = useQueryUserTrainings({ variables: { user } })

  useEffect(() => {
    const close = () => history.push(closePath)

    if (!user) {
      return close()
    }
    if (!data) {
      return
    }

    const getActiveTraining = () => {
      return data?.userTrainings.list.find((t) => !t.status.completedAt)
    }
    const getTraining = (trainingId) => {
      return data?.userTrainings.list.find((t) => toId(t) === trainingId)
    }
    const forwardToTraining = (item) => {
      history.push(`/knowledge/training/${toId(item)}`)
    }

    // no id -> forward to active training
    if (!id) {
      return forwardToTraining(getActiveTraining())
    }

    // id -> get requested training & active training
    const training = getTraining(id)
    const trainingComplete = Boolean(training?.status.completedAt)
    const activeTraining = getActiveTraining()

    // -> no active training -> close
    if (!training && !activeTraining) {
      return close()
    }

    // -> is not complete, has active training, and id does not match active training -> forward to active training. Should only happen if linear training is enabled
    if (!trainingComplete && linear && activeTraining && id !== toId(activeTraining)) {
      return forwardToTraining(activeTraining)
    }

    // -> no active training -> close
    if (!training) {
      return close()
    }

    setTraining(training)
  }, [data, history, id, user, linear])

  const handleClose = () => {
    history.push(closePath)
  }

  if (!thisTraining) {
    return null
  }

  const complete = Boolean(thisTraining.status.completedAt)
  const overdue = thisTraining.dueAt ? moment(thisTraining.dueAt).isBefore(moment()) : false

  // get next if present
  let nextUserTraining = null
  if (complete) {
    nextUserTraining = data?.userTrainings.list.find((userTraining) => !userTraining.status.completedAt)
  }

  const display = (
    <>
      <Box px={isInline ? 2 : 0} py={isInline ? 2 : 0} data-cy="TrainingViewer-display">
        {!complete && (
          <>
            {!overdue && Boolean(thisTraining.dueAt) && (
              <Box mb={3}>
                <Alert icon={<Icon name="due" />} severity="info">
                  <Trans>
                    <AlertTitle>
                      Training due <TimeAgo date={thisTraining.dueAt} />
                    </AlertTitle>
                    Please complete {thisTraining.name} by{" "}
                    <strong>
                      <FormatDate value={thisTraining.dueAt} />
                    </strong>
                    . Contact your manager if you are having any trouble.
                  </Trans>
                </Alert>
              </Box>
            )}
            {overdue && (
              <Box mb={3}>
                <Alert icon={<Icon name="due" />} severity="error">
                  <Trans>
                    <AlertTitle>Training overdue</AlertTitle>
                    Your training is now overdue, please complete it as soon as possible. Please contact your manager if
                    you are having trouble completing this training.
                  </Trans>
                </Alert>
              </Box>
            )}

            <TrainingCurriculum key={toId(thisTraining)} training={thisTraining} showUserScore />
          </>
        )}
        {complete && (
          <Box mb={2}>
            <IconMessage name="training-complete" maxWidth={400} />

            <ColumnBox alignItems="center" mt={1.5}>
              <FieldSectionHeading size="large">
                <Trans>Congratulations!</Trans>
              </FieldSectionHeading>
              <Box mb={1.5}>
                <Typography align="center">
                  <Trans>
                    You've completed the <strong>{thisTraining.name}</strong> course!
                  </Trans>
                </Typography>
              </Box>
              <Typography align="center">
                <Trans>
                  You can always access your training history from the{" "}
                  <NavLink to="/account/training">Settings</NavLink> area of the app.
                </Trans>
              </Typography>
            </ColumnBox>

            <ColumnBox alignItems="center" mt={2}>
              <Grid justifyContent="center" container spacing={xs ? 3 : 1}>
                {nextUserTraining && (
                  <Grid item xs={12} sm="auto">
                    <Button
                      component={NavLink}
                      fullWidth={xs}
                      to={`/knowledge/training/${toId(nextUserTraining)}`}
                      variant="contained"
                      color="primary"
                    >
                      <Trans context="Proceed to next training course">Next course</Trans>
                    </Button>
                  </Grid>
                )}
                {!nextUserTraining && (
                  <Grid item xs={12} sm="auto">
                    <Button variant="contained" color="primary" fullWidth={xs} onClick={handleClose}>
                      <Trans context="OK">Got it</Trans>
                    </Button>
                  </Grid>
                )}
              </Grid>
            </ColumnBox>
          </Box>
        )}
      </Box>
    </>
  )

  if (isInline) {
    return (
      <>
        <InlineTitle>
          <Trans>Your Training</Trans>
          <InlineTitleCloser onClose={handleClose} />
        </InlineTitle>
        {display}
      </>
    )
  }
  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
      data-cy="TrainingViewer-dialog"
    >
      <DialogTitle id="form-dialog-title">
        <RowBox>
          <Icon name="training" />
          <Box ml={1}>{thisTraining.name}</Box>
        </RowBox>
        <DialogTitleCloser onClose={handleClose} />
      </DialogTitle>
      <DialogContent>{display}</DialogContent>
    </Dialog>
  )
}

export { TrainingViewer }
