import { useQuery, gql } from "@apollo/client"

const DEVICE_FIELDS = `
    id
    label
    name
    os
    type
    timer
    managed
    location { id name }
    groups { id name }
    identityProvider { id name }
    activatedAt
    activeAt
`

const DEVICES_QUERY = gql`
  query Devices {
    devices {
      ${DEVICE_FIELDS}
    }
  }
`

const useQueryDevices = (options) =>
  useQuery(DEVICES_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

export { useQueryDevices, DEVICE_FIELDS }
