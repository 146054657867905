import { useMutation, gql } from "@apollo/client"
import moment from "moment"
import { v4 as uuid } from "uuid"
import { makeOptimisticUserFromPrincipal } from "../makeOptimistic"
import serializationKeys from "../serializationKeys"
import { POST_FIELDS } from "./fragments/postFieldsFragment"

const CREATEPOSTCOMMENT_MUTATION = gql`
  mutation CreatePostComment($content: String!, $post: ID!, $uploads: [ID!]) {
    createPostComment(content: $content, post: $post, uploads: $uploads) {
      ...PostFields
    }
  }
  ${POST_FIELDS}
`

const makeCreatePostCommentOptimisticResponse = ({ post, content, uploads, principal }) => ({
  __typename: "Mutaton",
  createPostComment: {
    ...post,
    comments: [
      ...post.comments,
      {
        __typename: "Comment",
        id: uuid(),
        author: makeOptimisticUserFromPrincipal(principal),
        content,
        uploads: uploads.map((upload) => ({ fileSize: 1, ...upload })),
        createdAt: moment().format(),
      },
    ],
  },
})

const useMutationCreatePostComment = (post) =>
  useMutation(CREATEPOSTCOMMENT_MUTATION, {
    context: {
      serializationKey: serializationKeys.post(post),
    },
  })

export { useMutationCreatePostComment, makeCreatePostCommentOptimisticResponse }
