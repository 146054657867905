import { useQuery, gql } from "@apollo/client"
import { PERMISSION_FIELDS, PERMISSION_GROUP_FIELDS } from "./fragments/permissionsFieldsFragment"

const FLAT_PERMISSIONS_QUERY = gql`
  query PermissionsFlat {
    permissions {
      flat {
        ...PermissionFields
      }
    }
  }
  ${PERMISSION_FIELDS}
`

const GROUPED_PERMISSIONS_QUERY = gql`
  query PermissionsGrouped {
    permissions {
      grouped {
        ...PermissionGroupFields
      }
    }
  }
  ${PERMISSION_GROUP_FIELDS}
`

const useQueryFlatPermissions = () => useQuery(FLAT_PERMISSIONS_QUERY)

const useQueryGroupedPermissions = () => useQuery(GROUPED_PERMISSIONS_QUERY)

export { useQueryFlatPermissions, useQueryGroupedPermissions }
