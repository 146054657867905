import { Box, Dialog, DialogContent, DialogTitle } from "@material-ui/core"
import { DialogTitleCloser } from "../Creators"
import { TrainingCurriculum } from "../Training/TrainingCurriculum"
import { LoadingSpinner } from "../LoadingSpinner"

const PersonTrainingViewer = ({ userTraining, loading, onClose }) => {
  const handleClose = () => {
    onClose && onClose()
  }

  if (!userTraining && !loading) {
    return null
  }

  const name = userTraining?.name

  return (
    <Dialog open onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle id="form-dialog-title">
        {name || "Loading..."}
        <DialogTitleCloser onClose={handleClose} />
      </DialogTitle>
      <DialogContent>
        {!loading && userTraining && <TrainingCurriculum training={userTraining} showAnswers showUserScore />}
        {loading && !userTraining && (
          <Box display="flex" justifyContent="center">
            <LoadingSpinner size={60} delay={false} />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}

export { PersonTrainingViewer }
