import { gql } from "@apollo/client"

const IDENTITYPROVIDER_FIELDS = gql`
  fragment IdentityProviderFields on IdentityProvider {
    id
    unique
    name
    client
    authority
    browserPrompt
    devicePrompt
    userDefaultRole
    createdAt
    updatedAt
  }
`

export { IDENTITYPROVIDER_FIELDS }
