import React, { useEffect, useMemo } from "react"
import { Box, CircularProgress, makeStyles, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { useLazyQueryAdminableUser, useLazyQueryAdminableUsers } from "../../data"
import { toId, useDebounceIntercept } from "../../utils"
import { UserPickerListItem } from ".."
import AllUsersPickerListItem from "../UserPickerListItem/AllUsersPickerListItem"

const useStyles = makeStyles(() => ({
  popper: {
    width: 400,
  },
}))

const defaultAllUsersOption = { all: true, fullName: "All people" }

const UserAutocomplete = ({ label = "Select person", inputProps, value, onChange, ...props }) => {
  const classes = useStyles()
  const [loadUser, { data: dataUser, loading: loadingUser }] = useLazyQueryAdminableUser()
  const [loadUsers, { data: dataUsers, loading: loadingUsers }] = useLazyQueryAdminableUsers()
  const handleLoad = (newSearchText) => {
    if (newSearchText) loadUsers({ variables: { searchText: newSearchText, limit: 20 } })
  }
  const [searchText, setSearchText] = useDebounceIntercept("", 300, 0, handleLoad)

  useEffect(() => {
    if (value) {
      loadUser({ variables: { id: toId(value) } })
    }
  }, [loadUser, value])

  const options = useMemo(() => {
    if (dataUsers?.adminableUsers) {
      return [defaultAllUsersOption, ...dataUsers?.adminableUsers]
    }
    return []
  }, [dataUsers?.adminableUsers])

  const loading = loadingUser || loadingUsers

  const handleChange = (event, option) => {
    onChange && onChange(option)
  }

  const handleInputChange = (event, newValue) => {
    setSearchText(newValue)
  }

  const renderInput = (params) => (
    <TextField
      {...params}
      label={label}
      placeholder="Search..."
      variant="outlined"
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {loading ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
      {...inputProps}
    />
  )

  const renderOption = (option) =>
    option?.all ? (
      <>
        <AllUsersPickerListItem component={Box} />
      </>
    ) : (
      <UserPickerListItem item={option} component={Box} />
    )

  const optionValue = dataUser?.adminableUser || defaultAllUsersOption

  return (
    <Autocomplete
      classes={classes}
      loading={loading}
      value={optionValue}
      noOptionsText={searchText ? "No matches..." : "Search for a person..."}
      handleHomeEndKeys
      popupIcon={null}
      onChange={handleChange}
      onInputChange={handleInputChange}
      getOptionSelected={(option) => toId(option) === toId(optionValue, true) || option.all === optionValue.all}
      getOptionLabel={(option) => option.fullName || option}
      options={options}
      renderInput={renderInput}
      renderOption={renderOption}
      disabled={value && !dataUser}
      {...props}
    />
  )
}

export { UserAutocomplete }
