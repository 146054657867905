import React from "react"
import { makeStyles, MenuItem } from "@material-ui/core"
import { toId } from "../../utils"
import { OutlinedSelect } from "./OutlinedSelect"

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 125,
  },
}))

const IntegrationProviderAttributeOutlinedSelect = ({ label = "Attribute", integration, source, ...rest }) => {
  const classes = useStyles()

  if (!integration || !source) {
    return null
  }

  const items = [...integration.integrationProvider.attributes[source]]

  if (!items) {
    return null
  }

  return (
    <OutlinedSelect label={label} native={false} classes={classes} {...rest}>
      {items
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((attribute) => (
          <MenuItem key={toId(attribute)} value={toId(attribute.key)}>
            {attribute.name}
          </MenuItem>
        ))}
    </OutlinedSelect>
  )
}

export { IntegrationProviderAttributeOutlinedSelect }
