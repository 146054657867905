import { makeStyles } from "@material-ui/core"
import React from "react"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"

const useStyles = makeStyles(() => ({
  list: {
    marginLeft: 40,
    marginBottom: 30,
    padding: 0,
    lineHeight: "1em",
  },
  listItem: {
    lineHeight: "24px",
    marginTop: 20,
    marginBottom: 20,
    whiteSpace: "normal",
  },
  paragraph: {},
}))

const allowedElements = ["ul", "ol", "li", "p", "h1", "h2", "h3", "h4", "h5", "h6", "strong", "em", "a"]

const NoMarkdown = ({ children, node, ...props }) => {
  if (node.tagName.match(/h[1-6]/)) {
    return (
      <>
        {Array.from(Array(props.level))
          .map(() => "#")
          .join("")}{" "}
        {children}
      </>
    )
  }

  return children
}

const MarkdownList = ({ depth, children, ...rest }) => {
  const classes = useStyles({ depth })

  return <ul className={classes.list}>{children}</ul>
}

const MarkdownOrderedList = ({ depth, children, start }) => {
  const classes = useStyles({ depth })

  return (
    <ol className={classes.list} start={start}>
      {children}
    </ol>
  )
}

const MarkdownListItem = ({ children }) => {
  const classes = useStyles()

  return <li className={classes.listItem}>{children}</li>
}

const MarkdownParagraph = ({ children }) => {
  const classes = useStyles()

  return <p className={classes.paragraph}>{children}</p>
}

const components = {
  ul: MarkdownList,
  ol: MarkdownOrderedList,
  li: MarkdownListItem,
  h1: NoMarkdown,
  h2: NoMarkdown,
  h3: NoMarkdown,
  h4: NoMarkdown,
  h5: NoMarkdown,
  h6: NoMarkdown,
  p: MarkdownParagraph,
}

const MarkdownOutput = ({ children }) => (
  <ReactMarkdown
    components={components}
    allowedElements={allowedElements}
    remarkPlugins={[remarkGfm]}
    unwrapDisallowed
    linkTarget="_blank"
    transformLinkUri={null}
  >
    {children}
  </ReactMarkdown>
)

export { MarkdownOutput }
