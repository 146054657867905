import { useQuery, useLazyQuery, gql } from "@apollo/client"
import { useMemo } from "react"

const REPORT_TRAINING_LOCATIONS_FIELDS_V2 = gql`
  fragment ReportTrainingLocationsFields on ReportTrainingLocation {
    id
    name
    timeZone
    complete
    inProgress
    overdue
    none
    percentComplete
    percentInProgress
    percentOverdue
    userCount
  }
`

const REPORT_TRAINING_LOCATIONS_QUERY_V2 = gql`
  query ReportLocationsTraining($filter: ReportTrainingLocationsFilterInput) {
    report: reportV2 {
      training {
        locations {
          list(filter: $filter) {
            ...ReportTrainingLocationsFields
          }
          userCount(filter: $filter)
        }
      }
    }
  }
  ${REPORT_TRAINING_LOCATIONS_FIELDS_V2}
`

const useQueryReportTrainingLocationsV2 = (options) =>
  useQuery(REPORT_TRAINING_LOCATIONS_QUERY_V2, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

const useLazyQueryReportTrainingLocationsV2 = (options) =>
  useLazyQuery(REPORT_TRAINING_LOCATIONS_QUERY_V2, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

const useTrainingReportDataV2 = (result) => {
  const staffCount = result?.data?.report?.training?.locations?.userCount ?? 0

  const reportData = useMemo(() => {
    if (!result?.data) {
      return null
    }

    const reduced = result?.data?.report?.training?.locations?.list?.reduce(
      (acc, { percentComplete, percentInProgress, percentOverdue, complete, inProgress, overdue }) => {
        acc.percentComplete += percentComplete
        acc.percentInProgress += percentInProgress
        acc.percentOverdue += percentOverdue
        acc.complete += complete
        acc.inProgress += inProgress
        acc.overdue += overdue

        return acc
      },
      {
        percentComplete: 0,
        percentInProgress: 0,
        percentOverdue: 0,
        complete: 0,
        inProgress: 0,
        overdue: 0,
      },
    )

    const total = reduced.percentComplete + reduced.percentInProgress + reduced.percentOverdue

    return {
      percentComplete: (reduced.percentComplete / total) * 100,
      percentInProgress: (reduced.percentInProgress / total) * 100,
      percentOverdue: (reduced.percentOverdue / total) * 100,
      complete: reduced.complete,
      inProgress: reduced.inProgress,
      overdue: reduced.overdue,
    }
  }, [result.data])

  return { reportData, staffCount }
}

const useQueryReportTrainingLocationsChartDataV2 = (options) => {
  const result = useQueryReportTrainingLocationsV2(options)
  const { reportData, staffCount } = useTrainingReportDataV2(result)

  return { ...result, reportData, staffCount }
}

const useLazyQueryReportTrainingLocationsReportDataV2 = (options) => {
  const [execute, result] = useLazyQueryReportTrainingLocationsV2(options)
  const { reportData, staffCount } = useTrainingReportDataV2(result)

  return [execute, { ...result, reportData, staffCount }]
}

export {
  useQueryReportTrainingLocationsV2,
  useLazyQueryReportTrainingLocationsV2,
  useQueryReportTrainingLocationsChartDataV2,
  useLazyQueryReportTrainingLocationsReportDataV2,
}
