import { useQuery, gql } from "@apollo/client"
import { PREP_LIST_FIELDS } from "./fragments/prepListFieldsFragment"

export const PREP_LIST_QUERY = gql`
  query PrepList($id: ID!) {
    prepLists {
      one(id: $id) {
        ...PrepListFields
      }
    }
  }
  ${PREP_LIST_FIELDS}
`

const useQueryPrepList = (options) => {
  return useQuery(PREP_LIST_QUERY, options)
}

export { useQueryPrepList }
