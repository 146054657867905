import React, { useEffect } from "react"
import { Box, Divider, ListSubheader, makeStyles, MenuItem } from "@material-ui/core"
import { Trans, t } from "@lingui/macro"
import { useQueryRoles } from "../../data"
import { OutlinedSelect } from "./OutlinedSelect"
import { useAuth } from "../../services"
import { Caption } from "../Headings"
import { ColumnBox } from "../Boxes"
import { toId } from "../../utils"

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 125,
  },
}))

const RoleOutlinedSelect = ({
  value,
  customOnly,
  onDataLoaded,
  disabled: disabledInput = false,
  multiple,
  noneLabel = "(None)",
  allLabel = "All",
  filter,
  required,
  ...rest
}) => {
  const { data, loading } = useQueryRoles()
  const {
    principal: { createsRoles },
    settings: { organisation },
    hasPermission,
  } = useAuth()
  const classes = useStyles()

  useEffect(() => {
    if (onDataLoaded && data) onDataLoaded(data)
  }, [data, onDataLoaded])

  const disabled = disabledInput || loading || data?.roles.length === 0

  const hasUserUpdateAll = hasPermission("user_update_all")

  const [roles, customRoles] = data?.roles.reduce(
    (acc, role) => {
      if (filter && !filter(role)) {
        return acc
      }

      if (customOnly && !role.isCustom) {
        return acc
      }

      if (!disabledInput && !createsRoles.includes(role.id) && !hasUserUpdateAll) {
        return acc
      }

      if (role.isCustom) {
        acc[1].push(role)
        return acc
      }

      acc[0].push(role)
      return acc
    },
    [[], []],
  ) || [[], []]

  const allRoles = [...roles, ...customRoles]

  const hasCustomRoles = customRoles.length > 0

  const hasBuiltInRoles = roles.length > 0

  return (
    <OutlinedSelect
      label={t`Permission level`}
      id="permission-level"
      value={allRoles.length > 0 ? value : ""}
      native={false}
      disabled={disabled}
      menuPortal={false}
      data-cy="OutlinedSelect-roles"
      classes={classes}
      selectProps={{
        renderValue: (option) => {
          if (multiple) {
            return option
              .map((item) => {
                const itemRole = allRoles.find((group) => group.id === item)
                if (itemRole) {
                  return itemRole.friendlyName
                }
                if (item === "all") {
                  return allLabel
                }
                return item
              })
              .join(", ")
          }

          const selected = allRoles.find((item) => [option.value, option].includes(toId(item)))
          return selected?.friendlyName || option
        },
      }}
      multiple={multiple}
      {...rest}
    >
      {!required && !multiple && <MenuItem value="">{noneLabel}</MenuItem>}
      {hasCustomRoles && (
        <ListSubheader disableSticky>
          <Trans>{organisation.name} roles</Trans>
        </ListSubheader>
      )}
      {customRoles.map((item, index) => (
        <MenuItem key={item.id} value={item.id} data-cy={`OutlinedSelect-role-${index}`}>
          <ColumnBox>
            <Box>{item.friendlyName}</Box>
            <Caption mb={0}>{item.description}</Caption>
          </ColumnBox>
        </MenuItem>
      ))}
      {hasCustomRoles && hasBuiltInRoles && <Divider />}
      {hasCustomRoles && hasBuiltInRoles && (
        <ListSubheader disableSticky>
          <Trans>Operandio built-in roles</Trans>
        </ListSubheader>
      )}
      {roles.map((item, index) => (
        <MenuItem key={item.id} value={item.id} data-cy={`OutlinedSelect-role-${index}`}>
          <ColumnBox>
            <Box>{item.friendlyName}</Box>
            {hasCustomRoles && <Caption mb={0}>{item.description}</Caption>}
          </ColumnBox>
        </MenuItem>
      ))}
    </OutlinedSelect>
  )
}

export { RoleOutlinedSelect }
