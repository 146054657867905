import React from "react"
import { Chip, makeStyles } from "@material-ui/core"
import Config from "react-global-configuration"
import { preventDefault } from "../../utils"

const useFeaturedStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.featured.main,
    "&:hover": {
      backgroundColor: theme.palette.featured.dark,
    },
    "&:focus": {
      backgroundColor: theme.palette.featured.dark,
    },
  },
}))

const TemplateCategoryChip = ({ category: { id, name }, onClick }) => {
  const featuredClasses = useFeaturedStyles()
  const { featuredCategory: featuredId } = Config.get("templates")

  const handleCategoryClick = (categoryId) => {
    if (onClick) onClick(categoryId)
  }

  return (
    <Chip
      clickable
      label={name}
      size="small"
      classes={id === featuredId ? featuredClasses : null}
      onClick={(event) => preventDefault(event, handleCategoryClick(id))}
    />
  )
}

export { TemplateCategoryChip }
