import { useMutation, gql } from "@apollo/client"
import { IDENTITYPROVIDER_FIELDS } from "./fragments/identityProviderFieldsFragment"

const UPDATE_IDENTITYPROVIDER_MUTATION = gql`
  mutation UpdateIdentityProvider(
    $id: ID!
    $name: String!
    $client: String!
    $authority: String!
    $browserPrompt: String
    $devicePrompt: String
    $userDefaultRole: ID
  ) {
    updateIdentityProvider(
      id: $id
      name: $name
      client: $client
      authority: $authority
      browserPrompt: $browserPrompt
      devicePrompt: $devicePrompt
      userDefaultRole: $userDefaultRole
    ) {
      ...IdentityProviderFields
    }
  }
  ${IDENTITYPROVIDER_FIELDS}
`

const useMutationUpdateIdentityProvider = () => useMutation(UPDATE_IDENTITYPROVIDER_MUTATION)

export { useMutationUpdateIdentityProvider }
