import { Badge, withStyles } from "@material-ui/core"
import React from "react"
import { HeaderMenuItem } from "../Header/HeaderMenuItem"
import { Icon } from "../Icon"

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 2,
    top: 4,
    fontWeight: "600",
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge)

const AnnouncementHelpMenuItem = ({ unread = 1, onClick }) => {
  const handleClick = () => {
    onClick && onClick()
  }

  return (
    <HeaderMenuItem
      text="What's new"
      onClick={handleClick}
      icon={
        <StyledBadge badgeContent={unread} color="primary">
          <Icon name="announcement" />
        </StyledBadge>
      }
    />
  )
}

export { AnnouncementHelpMenuItem }
