import { useMutation, gql } from "@apollo/client"
import { TIMESHEET_FIELDS } from "./useQueryTimesheet"

const START_TIMESHEET_MUTATION = gql`
  mutation StartTimesheet($location: ID, $group: ID, $shift: ID) {
    startTimesheet(location: $location, group: $group, shift: $shift) {
      ${TIMESHEET_FIELDS}
    }
  }
`

const END_TIMESHEET_MUTATION = gql`
  mutation EndTimesheet($comment: String) {
    endTimesheet(comment: $comment) {
      ${TIMESHEET_FIELDS}
    }
  }
`

const PAUSE_TIMESHEET_MUTATION = gql`
  mutation PauseTimesheet {
    pauseTimesheet {
      ${TIMESHEET_FIELDS}
    }
  }
`

const RESUME_TIMESHEET_MUTATION = gql`
  mutation ResumeTimesheet {
    resumeTimesheet {
      ${TIMESHEET_FIELDS}
    }
  }
`

const useMutationStartTimesheet = () => useMutation(START_TIMESHEET_MUTATION)

const useMutationEndTimesheet = () => useMutation(END_TIMESHEET_MUTATION)

const useMutationPauseTimesheet = () => useMutation(PAUSE_TIMESHEET_MUTATION)

const useMutationResumeTimesheet = () => useMutation(RESUME_TIMESHEET_MUTATION)

export { useMutationStartTimesheet, useMutationEndTimesheet, useMutationPauseTimesheet, useMutationResumeTimesheet }
