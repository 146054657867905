import { useMutation, gql } from "@apollo/client"

const ORGANISATION_FIELDS = `
    id
    name
    industry
    logo { key }
    customer
`

const CREATE_ORGANISATION_MUTATION = gql`
  mutation CreateOrganisation(
      $firstName: String!
      $lastName: String!
      $email: String!
      $name: String!
      $timeZone: TimeZone!
      $employees: String!
      $industry: String!
      $role: String
      $phoneMobile: String!
      $cardToken: String!
      $currency: String!
    ) {
    createOrganisation(
      firstName: $firstName
      lastName: $lastName
      email: $email
      name: $name
      timeZone: $timeZone
      employees: $employees
      industry: $industry
      role: $role
      phoneMobile: $phoneMobile
      cardToken: $cardToken
      currency: $currency
    ) {
      ${ORGANISATION_FIELDS}
    } 
  }
`

const useMutationCreateOrganisation = () =>
  useMutation(CREATE_ORGANISATION_MUTATION, {
    errorPolicy: "all",
  })

export { useMutationCreateOrganisation }
