import { useMutation, gql } from "@apollo/client"

import { AREAS_QUERY } from "./useQueryAreas"
import { useAuth } from "../../services"
import { mapToIds, toId } from "../../utils"

const DELETE_AREA_MUTATION = gql`
  mutation DeleteArea($id: ID!) {
    area(id: $id) {
      delete
    }
  }
`

const useMutationDeleteArea = () => {
  const {
    location,
    principal: { groups: usersGroups },
  } = useAuth()

  return useMutation(DELETE_AREA_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: AREAS_QUERY,
      },
      {
        query: AREAS_QUERY,
        variables: { filter: { locations: [toId(location)], groups: mapToIds(usersGroups) } },
      },
    ],
  })
}

export { useMutationDeleteArea }
