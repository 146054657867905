import { useQuery, useLazyQuery, gql } from "@apollo/client"
import { SENSOR_FIELDS } from "./fragments/sensorFieldsFragment"

const SENSORS_QUERY = gql`
  query Sensors($location: ID!) {
    sensors(location: $location) {
      ...SensorFields
    }
  }
  ${SENSOR_FIELDS}
`

const useQuerySensors = (options) => useQuery(SENSORS_QUERY, options)

const useLazyQuerySensors = (options) => useLazyQuery(SENSORS_QUERY, options)

export { useQuerySensors, useLazyQuerySensors, SENSOR_FIELDS }
