import { useQuery, gql } from "@apollo/client"

const LOCATION_FIELDS = gql`
  fragment LocationFields on Location {
    id
    name
    timeZone
    region {
      id
      name
    }
    attributes {
      id
      integration
      key
      value
    }
    inactive
    unbilled
    logo {
      id
      key
    }
  }
`

const LOCATIONS_QUERY = gql`
  ${LOCATION_FIELDS}
  query Locations {
    locations {
      ...LocationFields
    }
  }
`

const useQueryLocations = (options) => useQuery(LOCATIONS_QUERY, options)

export { useQueryLocations, LOCATION_FIELDS }
