import React from "react"
import { Trans } from "@lingui/macro"
import { NoItemsMessage } from ".."

const InsufficientPermissionsMessage = () => (
  <NoItemsMessage>
    <Trans>Insufficient permissions</Trans>
  </NoItemsMessage>
)

export { InsufficientPermissionsMessage }
