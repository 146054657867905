import React from "react"
import { useQueryAdminableRoles } from "../../data"
import { useMutationRoleCreateAttribute, useMutationRoleDeleteAttribute } from "../../data/roles/useMutationRole"
import { IntegrationMappingConfiguration } from "./IntegrationMappingConfiguration"
import { RoleOutlinedSelect } from "../OutlinedSelect/RoleOutlinedSelect"

const IntegrationRoleConfiguration = ({ integration }) => {
  const { data } = useQueryAdminableRoles()
  const [addAttribute, { loading }] = useMutationRoleCreateAttribute()
  const [deleteAttribute] = useMutationRoleDeleteAttribute()

  return (
    <IntegrationMappingConfiguration
      integration={integration}
      data={data?.roles || []}
      source="role"
      label="Permission level"
      plural="permission levels"
      displayPropName="friendlyName"
      addAttribute={addAttribute}
      deleteAttribute={deleteAttribute}
      components={{
        add: {
          Destination: ({ onChange, value }) => (
            <RoleOutlinedSelect
              label="Permission level"
              value={value}
              onChange={(event) => onChange(event.target.value)}
              fullWidth
              required
              disabled={loading}
              customOnly
            />
          ),
        },
      }}
    />
  )
}

export { IntegrationRoleConfiguration }
