import { useMutation, gql } from "@apollo/client"

const RETRIEVE_DOWNLOAD_MUTATION = gql`
  mutation RetrieveDownload($id: ID!) {
    retrieveDownload(id: $id) {
      signedDownloadUrl
      upload {
        id
        fileName
        fileGroup
        contentType
      }
    }
  }
`

const useMutationRetrieveDownload = () => useMutation(RETRIEVE_DOWNLOAD_MUTATION)

export { useMutationRetrieveDownload }
