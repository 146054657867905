import React from "react"
import { ListItem } from "@material-ui/core"
import { useSubNavigationListItemStyles } from "./SubNavigationListItem"

const SubNavigationListItemLink = ({ active, color, children, ...props }) => {
  const classes = useSubNavigationListItemStyles()

  return (
    <ListItem className={classes.navLinkListItem}>
      <a className={classes.navLinkItem} {...props}>
        {children}
      </a>
    </ListItem>
  )
}

export { SubNavigationListItemLink }
