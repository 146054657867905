import { useLazyQuery, useQuery, gql } from "@apollo/client"
import * as Sentry from "@sentry/browser"
import { offsetLimitPagination } from "@apollo/client/utilities"
import { SUPPLIER_FIELDS } from "./fragments/supplierFieldsFragment"
import { TAG_FIELDS } from "../tags/useQueryTags"

const SUPPLIERS_LIMIT = 20

const SUPPLIERS_QUERY = gql`
  query Suppliers($filter: SupplierFilterInput, $offset: Int, $limit: Int) {
    suppliers {
      list(filter: $filter, limit: $limit, offset: $offset) {
        ...SupplierFields
      }
      count(filter: $filter)
    }
  }
  ${SUPPLIER_FIELDS}
`

const SUPPLIERS_COUNT_QUERY = gql`
  query SuppliersCount($filter: SupplierFilterInput) {
    suppliers {
      count(filter: $filter)
    }
  }
`

const SUPPLIER_TAGS_QUERY = gql`
  query SupplierTags {
    suppliers {
      tags {
        ...TagFields
      }
    }
  }
  ${TAG_FIELDS}
`

const useQuerySuppliers = (options) => useQuery(SUPPLIERS_QUERY, options)
const useQuerySuppliersCount = (options) => useQuery(SUPPLIERS_COUNT_QUERY, options)
const useQuerySupplierTags = (options) => useQuery(SUPPLIER_TAGS_QUERY, options)

const loadMore = (result) => async () => {
  try {
    await result.fetchMore({
      variables: {
        ...result.variables,
        offset: result.data.suppliers.list.length,
        limit: SUPPLIERS_LIMIT,
      },
    })
  } catch (error) {
    console.log(error)
    Sentry.captureException(error)
  }
}

const useLazyQuerySuppliers = (options) => {
  const { variables, ...otherOptions } = options || {}
  const [loadItems, result] = useLazyQuery(SUPPLIERS_QUERY, {
    variables: {
      offset: 0,
      limit: SUPPLIERS_LIMIT,
      ...variables,
    },
    fetchPolicy: "cache-and-network",
    ...otherOptions,
  })

  return [
    loadItems,
    {
      ...result,
      loadMore: loadMore(result),
    },
  ]
}

const suppliersTypePolicies = {
  scoped: {
    SuppliersQuery: {
      merge(prev, next) {
        return {
          ...(prev || {}),
          ...next,
        }
      },
      fields: {
        list: offsetLimitPagination(["filter"]),
        count: {
          keyArgs: ["filter"],
        },
      },
    },
    Supplier: {
      fields: {
        contacts: {
          merge(_, next) {
            return next
          },
        },
      },
    },
  },
}

export {
  useQuerySuppliers,
  useQuerySuppliersCount,
  useQuerySupplierTags,
  useLazyQuerySuppliers,
  suppliersTypePolicies,
  SUPPLIERS_QUERY,
  SUPPLIERS_COUNT_QUERY,
}
