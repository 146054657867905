import React, { useState } from "react"
import { Box, FormControlLabel, makeStyles, Radio, RadioGroup } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import { useHistory } from "react-router-dom"
import Config from "react-global-configuration"
import { Trans, t } from "@lingui/macro"
import { CreatorActions } from "../Creators"
import { PROCESS_REPEAT, PROCESS_SET, useMutationRunProcess, useQueryProcesses } from "../../data"
import { LoadingSpinner, NoItemsMessage, SearchInput } from ".."
import { TextDivider } from "../TextDivider"
import { useAuth } from "../../services"
import { toId, useJobUtils } from "../../utils"
import { ColumnBox } from "../Boxes"

const useStyles = makeStyles((theme) => ({
  row: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))

const AdhocCreator = ({ onClose }) => {
  const classes = useStyles()
  const { location } = useAuth()
  const history = useHistory()
  const { getNavigateToLink } = useJobUtils()
  const [searchText, setSearchText] = useState("")
  const { clientKiosk: kiosk, clientDevice: device } = Config.get()

  const { data, loading } = useQueryProcesses({
    variables: { filter: { set: PROCESS_SET.ACTIVE_ADHOC, locations: [toId(location)] }, limit: 200 },
  })
  const [runProcess, { loading: runProcessLoading }] = useMutationRunProcess()

  const [id, setId] = useState(null)
  const [schedule, setSchedule] = useState(null)

  const handleClick = (item, scheduleItem) => {
    setId(toId(item))
    setSchedule(toId(scheduleItem))
  }

  const handleFilter = (text) => {
    setSearchText(text.toLowerCase())
  }

  const handleSubmit = async () => {
    const result = await runProcess({
      variables: {
        id,
        schedule,
        location: toId(location),
      },
    })
    if (result?.data?.process?.run) {
      history.push(getNavigateToLink(result.data.process.run))
    }
  }

  const handleClose = (event, isCancel) => {
    onClose(isCancel)
  }

  const hasFilter = !!searchText || data?.processes.list.length > 8

  const items =
    data?.processes.list
      .filter((item) => !searchText || item.name.toLowerCase().includes(searchText))
      .map((item) => {
        return item.schedules
          .filter((scheduleItem) => scheduleItem.repeat.type === PROCESS_REPEAT.ADHOC)
          .map((scheduleItem) => {
            return (
              <Box key={toId(item)}>
                <TextDivider />
                <FormControlLabel
                  value={item.id}
                  control={<Radio color="primary" />}
                  label={item.name}
                  className={classes.row}
                  onClick={() => handleClick(item, scheduleItem)}
                />
              </Box>
            )
          })
      }) || []

  return (
    <>
      {hasFilter && (
        <SearchInput
          autoFocus={!(kiosk || device)}
          placeholder={t`Search`}
          boxProps={{ pl: 0, pr: 0 }}
          onChange={handleFilter}
        />
      )}

      {loading && (
        <Box display="flex" justifyContent="center">
          <LoadingSpinner size={60} />
        </Box>
      )}
      {!loading && items.length === 0 && (
        <ColumnBox mb={2} justifyContent="center" alignItems="center">
          {!searchText && (
            <Alert severity="info">
              <Trans>
                <AlertTitle>You don't have any ad hoc jobs available at this location</AlertTitle>
                If you think this is a mistake, contact your manager to gain access.
              </Trans>
            </Alert>
          )}
          {searchText && (
            <NoItemsMessage>
              <Trans>No matching items</Trans>
            </NoItemsMessage>
          )}
        </ColumnBox>
      )}
      {!loading && items.length > 0 && (
        <Box mb={1}>
          <RadioGroup>{items}</RadioGroup>
          <TextDivider />
        </Box>
      )}

      <CreatorActions
        id="adhoc-actions"
        onClose={handleClose}
        onSubmit={handleSubmit}
        submitLabel={t`Run job`}
        disableSubmit={!id}
        submitLoading={runProcessLoading}
      />
    </>
  )
}

export default AdhocCreator
