const labelDatetimeFormats = [
  "MMM DD, hh:mm A",
  "ddd MMM DD, hh:mm A",
  "dddd MMMM DD, hh:mm A",
  "MM/DD",
  "MM/DD/YY",
  "DD/MM",
  "DD/MM/YY",
  "DD MMMM YYYY",
  "DD MMM YY",
  "MMMM DD, YYYY",
  "MMM DD, YY",
  "MMMM DD",
  "DD MMMM",
  "dddd, MMMM DD, YYYY",
  "ddd MMM DD YYYY",
  "dddd",
  "ddd",
  "dddd, hh:mm A",
  "hh:mm A",
  "YYYY",
]
export { labelDatetimeFormats }
