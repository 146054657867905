import React from "react"
import { Box, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    fontSize: 20,
    lineHeight: "28px",
    fontWeight: "600",
    color: theme.palette.text.primary,
    marginBottom: 0,
  },
}))

const SectionTitle = ({ children, boxProps = { mb: 2 } }) => {
  const classes = useStyles()
  return (
    <Box {...boxProps}>
      <Typography variant="h2" className={classes.sectionTitle}>
        {children}
      </Typography>
    </Box>
  )
}

export { SectionTitle }
