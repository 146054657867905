import React from "react"
import moment from "moment"
import { InactiveChip } from "./InactiveChip"
import { ActiveChip } from "./ActiveChip"
import { NoneChip } from "./NoneChip"
import { InvitedChip } from "./InvitedChip"
import { InviteExpiredChip } from "./InviteExpiredChip"

const UserStatusChip = ({ user: { status, invite } }) => {
  switch (status) {
    case "invited":
      if (moment().isAfter(moment(invite.expiresAt))) return <InviteExpiredChip />
      return <InvitedChip />
    case "active":
      return <ActiveChip />
    case "inactive":
      return <InactiveChip />
    default:
      return <NoneChip />
  }
}

export { UserStatusChip }
