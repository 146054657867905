import { gql, useQuery } from "@apollo/client"
import { USER_ACCREDITATION_FIELDS } from "./fragments/userAccreditationFieldsFragment"

const USER_ACCREDITATIONS_QUERY = gql`
  query UserAccreditations($user: ID!) {
    userAccreditations(user: $user) {
      ...UserAccreditationFields
    }
  }
  ${USER_ACCREDITATION_FIELDS}
`

const userAccreditationTypePolicies = {
  UserAccreditation: {
    fields: {
      uploads: {
        merge(prev, next) {
          return [...next]
        },
      },
    },
  },
}

const useQueryUserAccreditations = (options) => useQuery(USER_ACCREDITATIONS_QUERY, options)

export { useQueryUserAccreditations, userAccreditationTypePolicies }
