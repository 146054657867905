import { useMutation, gql } from "@apollo/client"

const DELETENOWLEDGE_MUTATION = gql`
  mutation DeleteKnowledge($id: ID!) {
    deleteKnowledge(id: $id)
  }
`

const useMutationDeleteKnowledge = (options) => useMutation(DELETENOWLEDGE_MUTATION, options)

export { useMutationDeleteKnowledge }
