import React, { useMemo } from "react"
import { Box, makeStyles } from "@material-ui/core"
import { Trans } from "@lingui/macro"
import { NoItemsMessage, LoadingSpinner, ManageAccreditationActionCard, FieldSectionHeading } from "../../components"
import { useQueryUserAccreditations, USER_ACCREDITATIONS_STATUS } from "../../data"
import { toId } from "../../utils"
import { useAuth } from "../../services"

const attentionStatuses = [
  USER_ACCREDITATIONS_STATUS.AWAITING,
  USER_ACCREDITATIONS_STATUS.WARNING,
  USER_ACCREDITATIONS_STATUS.EXPIRED,
]

const useStyles = makeStyles((theme) => ({
  requiresAttention: {
    color: theme.palette.error.main,
  },
}))

const Accreditations = () => {
  const {
    principal: { userID: user },
  } = useAuth()
  const { data, loading } = useQueryUserAccreditations({ variables: { user } })
  const classes = useStyles()

  const attentionData = useMemo(
    () =>
      data?.userAccreditations
        .filter((item) => attentionStatuses.includes(item.status))
        .sort((a, b) => a.accreditation.name.localeCompare(b.accreditation.name)) || [],
    [data],
  )
  const compliantData = useMemo(
    () =>
      data?.userAccreditations
        .filter((item) => !attentionStatuses.includes(item.status))
        .sort((a, b) => a.accreditation.name.localeCompare(b.accreditation.name)) || [],
    [data],
  )

  return (
    <Box mb={5} data-cy="Accreditations-lists">
      {Boolean(attentionData.length) && (
        <Box mb={5} data-cy="Accreditation-attention-list">
          <FieldSectionHeading size="large" className={classes.requiresAttention}>
            <Trans>Requires your attention ({attentionData.length})</Trans>
          </FieldSectionHeading>
          {attentionData.map((userAccreditation) => (
            <ManageAccreditationActionCard key={toId(userAccreditation)} userAccreditation={userAccreditation} />
          ))}
        </Box>
      )}

      {Boolean(compliantData.length) && (
        <Box data-cy="Accreditation-current-list">
          <FieldSectionHeading size="large">
            <Trans>Up to date</Trans>
          </FieldSectionHeading>
          {compliantData.map((userAccreditation) => (
            <ManageAccreditationActionCard
              key={toId(userAccreditation)}
              userAccreditation={userAccreditation}
              completed
            />
          ))}
        </Box>
      )}

      {!data && loading && (
        <Box display="flex" justifyContent="center">
          <LoadingSpinner size={60} />
        </Box>
      )}

      {data?.userAccreditations.length === 0 && (
        <NoItemsMessage>
          <Trans>No accreditations found</Trans>
        </NoItemsMessage>
      )}
    </Box>
  )
}

export default Accreditations
export { attentionStatuses as accreditationAttentionStatuses }
