import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Paper, Box, Switch, FormControlLabel } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[200],
  },
  label: {
    fontSize: 14,
    fontWeight: "600",
  },
}))

const SwitchActionCard = ({ label, checked, onChange, color }) => {
  const classes = useStyles()

  return (
    <Paper elevation={3} className={classes.root}>
      <Box pt={1} pb={1} pl={2} pr={2} mb={1}>
        <FormControlLabel
          classes={{
            label: classes.label,
          }}
          control={<Switch {...{ checked, onChange, color: color || "primary" }} />}
          label={label}
        />
      </Box>
    </Paper>
  )
}

export { SwitchActionCard }
