import { useMutation, gql } from "@apollo/client"
import { KNOWLEDGE_FIELDS } from "./useQueryKnowledges"

const COPYKNOWLEDGE_MUTATION = gql`
  mutation CopyKnowledge($id: ID!) {
    knowledge(id: $id) {
      copy {
        ...KnowledgeFields
      }
    }
  }
  ${KNOWLEDGE_FIELDS}
`

const useMutationCopyKnowledge = () => useMutation(COPYKNOWLEDGE_MUTATION)

export { useMutationCopyKnowledge }
