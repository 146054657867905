import { gql } from "@apollo/client"
import * as Sentry from "@sentry/browser"

const queryLogout = async (client) => {
  try {
    const response = await client.query({
      query: gql`
        query Logout {
          logout
        }
      `,
      fetchPolicy: "network-only",
    })
    return response
  } catch (error) {
    console.log("[queryLogout]", error)
    Sentry.captureException(error)
    return error
  }
}

export { queryLogout }
