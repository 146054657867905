import { useMutation, gql } from "@apollo/client"

const PAIR_DEVICE_MUTATION = gql`
  mutation PairDevice($passcode: String!) {
    pairDevice(passcode: $passcode) {
      id
      identityProvider
      token
    }
  }
`

const useMutationPairDevice = () =>
  useMutation(PAIR_DEVICE_MUTATION, {
    errorPolicy: "all",
  })

export { useMutationPairDevice }
