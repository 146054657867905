import React from "react"
import { Box, Typography, Breadcrumbs, useTheme, useMediaQuery } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { NavLink, useHistory } from "react-router-dom"
import { AuthorTools } from "../Author"
import { RowBox } from "../Boxes"

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  title: {
    fontSize: 24,
    fontWeight: "600",
    lineHeight: "30px",
    alignSelf: "center",
  },
  listItem: {
    color: theme.palette.primary.main,
    fontSize: 16,
    marginTop: 4,
    marginBottom: 4,
    fontWeight: "600",
    "&:hover": {
      background: "#ecf4ff",
      borderRadius: "4px",
    },
    "&:focus": {
      background: "#ecf4ff",
      borderRadius: "4px",
    },
  },
  listItemText: {
    fontSize: 16,
    fontWeight: "600 !important",
  },
}))

const LabelsCategory = ({ kiosk, device, category, onEdit, onDelete }) => {
  const theme = useTheme()
  const classes = useStyles()
  const history = useHistory()
  const { name } = category
  const xs = useMediaQuery(theme.breakpoints.down("xs"))

  const handleDelete = (event) => {
    onDelete && onDelete(event)
    history.push("/labels")
  }

  return (
    <>
      <RowBox mb={1}>
        <Typography variant="h3" className={classes.title}>
          {name}
        </Typography>
        <Box ml="auto">
          <AuthorTools
            item={category}
            author={category.author.id}
            admins={{ edit: "label_update", delete: "label_update" }}
            onEdit={onEdit}
            onDelete={category.numberOfLabels ? null : handleDelete}
            subject="category"
          />
        </Box>
      </RowBox>
      {!kiosk && !device && (
        <Box mb={xs ? 2 : 3}>
          <Breadcrumbs>
            <NavLink to="/labels">All labels</NavLink>
            <Box>{name}</Box>
          </Breadcrumbs>
        </Box>
      )}
    </>
  )
}

export { LabelsCategory }
