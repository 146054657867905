import React from "react"
import { alpha, useTheme } from "@material-ui/core/styles"
import { CardCvcElement, CardElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js"

const StripeInput = (props) => {
  const {
    component: Component,
    inputRef,
    "aria-invalid": ariaInvalid,
    "aria-describedby": ariaDescribeBy,
    defaultValue,
    required,
    onKeyDown,
    onKeyUp,
    readOnly,
    autoComplete,
    autoFocus,
    type,
    name,
    rows,
    ...other
  } = props
  const theme = useTheme()
  const [mountNode, setMountNode] = React.useState(null)

  React.useImperativeHandle(
    inputRef,
    () => ({
      focus: () => mountNode.focus(),
    }),
    [mountNode]
  )

  return (
    <Component
      onReady={setMountNode}
      style={{
        base: {
          color: theme.palette.text.primary,
          fontSize: `${theme.typography.fontSize}px`,
          fontFamily: theme.typography.fontFamily,
          "::placeholder": {
            color: alpha(theme.palette.text.primary, 0.42),
          },
        },
        invalid: {
          color: theme.palette.text.primary,
        },
      }}
      {...other}
    />
  )
}

const StripeCardElement = (props) => <StripeInput component={CardElement} {...props} />

const StripeCardNumber = (props) => <StripeInput component={CardNumberElement} {...props} />

const StripeCardExpiry = (props) => <StripeInput component={CardExpiryElement} {...props} />

const StripeCardCvc = (props) => <StripeInput component={CardCvcElement} {...props} />

export { StripeInput, StripeCardElement, StripeCardNumber, StripeCardExpiry, StripeCardCvc }
