import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Chip } from "@material-ui/core"

const useStyles = makeStyles(() => ({}))

const NoneChip = ({ className }) => {
  const classes = useStyles()

  return <Chip label="None" classes={classes} className={className} size="small" />
}

export { NoneChip }
