import { useQuery, useLazyQuery, gql } from "@apollo/client"

const REPORT_JOBS_DETAIL_PDF_QUERY = gql`
  query ReportJobsDetailPdf($input: ReportJobsDetailFilterInput!) {
    report {
      jobs {
        detail {
          pdf(input: $input) {
            message
            fileName
            exportUrl
          }
        }
      }
    }
  }
`

const useQueryReportJobsDetailPdf = (options) =>
  useQuery(REPORT_JOBS_DETAIL_PDF_QUERY, {
    fetchPolicy: "network-only",
    errorPolicy: "all",
    ...options,
  })

const useLazyQueryReportJobsDetailPdf = (options) =>
  useLazyQuery(REPORT_JOBS_DETAIL_PDF_QUERY, {
    fetchPolicy: "network-only",
    errorPolicy: "all",
    ...options,
  })

export { useQueryReportJobsDetailPdf, useLazyQueryReportJobsDetailPdf }
