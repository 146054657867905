import { gql } from "@apollo/client"
import { LOCATION_FIELDS } from "../../locations"
import { toId } from "../../../utils"

const LOGIN_FIELDS = gql`
  fragment LoginFields on LoginResult {
    ticket {
      token
      expiry
      username
      external
      firstName
      lastName
      email
      avatar
      userID
      createdAt
      roles
      groups
      permissions {
        key
        value
      }
      createsRoles
    }
    settings {
      searches {
        at
        text
      }
      preferences {
        hideCompletedSteps
        language
      }
      organisation {
        id
        name
        logo
        disabled
        demonstration
      }
      fl
      location
      locations {
        ...LocationFields
      }
      regions @client
      features {
        key
        value
      }
      deviceGroups
      deviceTimer
    }
  }
  ${LOCATION_FIELDS}
`

const loginResultTypePolicies = {
  root: {},
  scoped: {
    Settings: {
      fields: {
        regions: {
          read(_, { readField, cache }) {
            const locationsRefs = readField("locations")

            if (!locationsRefs) {
              return []
            }

            const locations = locationsRefs
              .map((locationRef) => {
                const location = cache.readFragment({
                  id: locationRef.__ref,
                  fragment: LOCATION_FIELDS,
                })

                return location
              })
              .sort((a, b) => a.name.localeCompare(b.name, "en", { sensitivity: "base" }))

            // get distinct regions and sort by name
            const regions = locations
              .reduce((acc, location) => {
                const { region } = location

                if (!region) {
                  return acc
                }

                if (!acc.some((item) => toId(item) === toId(location.region))) {
                  acc.push({ ...location.region })
                }

                return acc
              }, [])
              .sort((a, b) => a.name.localeCompare(b.name))

            // arrange locations into regions
            regions.forEach((region) => {
              region.locations = locations
                .filter((location) => toId(location.region) === toId(region))
                .map((location) => ({ ...location }))
            })

            // add locations without any region as the first item
            const noRegionLocations = locations.filter((location) => !location.region)
            if (noRegionLocations.length) {
              regions.unshift({
                id: "none",
                name: "",
                locations: noRegionLocations,
              })
            }

            return regions
          },
        },
      },
    },
  },
}

export { LOGIN_FIELDS, loginResultTypePolicies }
