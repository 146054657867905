import React, { useState } from "react"
import { Button, Menu, makeStyles, MenuItem, Box } from "@material-ui/core"
import moment from "moment-timezone"
import Config from "react-global-configuration"
import { useAuth } from "../../services"
import { getDownload } from "../../data"
import { useSnackbar } from ".."
import { toId, useDeviceUtils } from "../../utils"
import { LoadingSpinner } from "../LoadingSpinner"
import { OutlinedSelect } from "../OutlinedSelect"
import { DatePicker } from "../DateTimePickers"

const useStyles = makeStyles(() => ({
  menu: {
    marginTop: 117,
  },
}))

const DesktopSensorsReportsButton = () => {
  const classes = useStyles()
  const { location } = useAuth()
  const { clientKiosk, clientDevice } = Config.get()

  const { canPostMessage, postDownloadMessage } = useDeviceUtils()

  const [loading, setLoading] = useState(false)
  const snackbar = useSnackbar()
  const [showCreateOptions, setShowCreateOptions] = useState(false)
  const [showCreateOptionsEl, setShowCreateOptionsEl] = useState(null)
  const [date, setDate] = useState(moment())

  const handleClick = async () => {
    try {
      setLoading(true)
      setShowCreateOptions(false)
      setShowCreateOptionsEl(null)

      const locationId = toId(location)
      const formattedDate = moment(date).format("YYYY-MM-DD")
      const exportUrl = `/sensors/weekly/${locationId}/${formattedDate}`
      const fileName = `${formattedDate}-Weekly-${locationId}.pdf`

      if ((clientKiosk || clientDevice) && canPostMessage()) {
        postDownloadMessage({
          signedDownloadUrl: exportUrl,
          upload: {
            fileName,
            contentType: "application/pdf",
          },
        })
        setLoading(false)
        return
      }

      await getDownload(exportUrl, {
        contentType: "application/pdf",
        fileName,
        onSuccess: () => setLoading(false),
        onError: (error) => {
          setLoading(false)
          snackbar.showMessage({ message: "No data available for the selected date range" })
        },
      })
    } catch (error) {
      // do nothing
    } finally {
      setLoading(false)
    }
  }

  const handleToggleCreateMenu = ({ currentTarget }) => {
    setShowCreateOptionsEl(!showCreateOptions ? currentTarget : null)
    setShowCreateOptions(!showCreateOptions)
  }

  const handleDateChange = (value) => {
    setDate(value)
  }

  return (
    <Box display="flex" flexDirection="row">
      <Button
        onClick={handleToggleCreateMenu}
        variant="contained"
        color="primary"
        data-cy="Button-reports"
        disabled={loading}
      >
        {!loading && <>Download Report</>}
        {loading && <LoadingSpinner size="24px" delay={false} />}
      </Button>
      <Menu
        anchorEl={showCreateOptionsEl}
        keepMounted
        open={showCreateOptions}
        onClose={handleToggleCreateMenu}
        className={classes.menu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box py={1} px={1.5}>
          <OutlinedSelect native={false} label="Report" value="weekly" disabled={loading}>
            <MenuItem value="weekly">Weekly Temperatures</MenuItem>
          </OutlinedSelect>
          <Box mt={2}>
            <DatePicker label="Date" value={date} onChange={handleDateChange} format="D MMM yyyy" />
          </Box>
          <Box mt={2}>
            <Button variant="contained" fullWidth color="primary" disabled={loading} onClick={() => handleClick()}>
              <Box ml={1}>Download PDF</Box>
            </Button>
          </Box>
        </Box>
      </Menu>
    </Box>
  )
}

export { DesktopSensorsReportsButton }
