import moment from "moment"
import { v4 as uuid } from "uuid"
import { useAuth } from "../services"
import { toId } from "../utils"

const makeOptimisticUserFromPrincipal = ({ userID, firstName, lastName, avatar, groups }, locations) => ({
  id: userID,
  fullName: `${firstName} ${lastName}`,
  firstName,
  lastName,
  status: "active",
  avatar: avatar
    ? {
        __typename: "UploadInfo",
        key: avatar,
      }
    : null,
  activeAt: moment(),
  groups: groups
    ? groups.map((group) => ({ id: toId(group), name: group.name || "Loading...", __typename: "Group" }))
    : [],
  locations: locations || [],
  __typename: "User",
})

const makeOptimisticPlaceholderUser = () => ({
  id: uuid(),
  fullName: "...",
  firstName: "...",
  lastName: "...",
  avatar: null,
  activeAt: moment(),
  __typename: "User",
})

const makeOptimisticPlaceholderGroup = () => ({
  __typename: "Group",
  id: uuid(),
  name: "...",
})

const makeOptimisticPlaceholderLocation = () => ({
  __typename: "Location",
  id: uuid(),
  name: "...",
})

const makeOptimisticPlaceholderKnowledge = () => ({
  id: uuid(),
  title: "...",
  category: makeOptimisticPlaceholderCategory(),
  content: [],
  author: makeOptimisticPlaceholderUser(),
  ...makeOptimisticTimestamps(),
})

const makeOptimisticPlaceholderJob = () => ({
  id: uuid(),
  title: "....",
  ...makeOptimisticTimestamps(),
})

const makeOptimisticPlaceholderCategory = () => ({
  id: uuid(),
  name: "...",
})

const makeOptimisticTimestamps = () => ({
  createdAt: moment().format(),
  updatedAt: moment().format(),
})

const useMakeOptimistic = () => {
  const auth = useAuth()

  return {
    currentUser: () => makeOptimisticUserFromPrincipal(auth.principal, auth.settings.locations),
  }
}

export {
  useMakeOptimistic,
  makeOptimisticUserFromPrincipal,
  makeOptimisticPlaceholderUser,
  makeOptimisticPlaceholderGroup,
  makeOptimisticPlaceholderLocation,
  makeOptimisticPlaceholderJob,
  makeOptimisticPlaceholderKnowledge,
  makeOptimisticPlaceholderCategory,
  makeOptimisticTimestamps,
}
