import { gql, useQuery } from "@apollo/client"

const USER_TRAINING_ASSESSMENT_FIELDS = `
  id
  user
  module { id name }
  status
  trainedAt
  trainedBy { id fullName firstName lastName avatar { key } }
`

const USER_TRAINING_ASSESSMENTS_QUERY = gql`
  query UserTrainingAssessments($user: ID!) {
    userTrainingAssessments(user: $user) {
      ${USER_TRAINING_ASSESSMENT_FIELDS}
    }
  }
`

const useQueryUserTrainingAssessments = (options) => useQuery(USER_TRAINING_ASSESSMENTS_QUERY, options)

export { useQueryUserTrainingAssessments, USER_TRAINING_ASSESSMENT_FIELDS }
