import { useMutation, gql } from "@apollo/client"
import { toId } from "../../utils"

const DELETEPOST_MUTATION = gql`
  mutation DeletePost($id: ID!) {
    deletePost(id: $id)
  }
`

const useMutationDeletePost = () =>
  useMutation(DELETEPOST_MUTATION, {
    update(cache, { errors }, { variables: { id } }) {
      if (errors) {
        return
      }
      const normalizedId = cache.identify({ id: toId(id), __typename: "Post" })
      cache.evict({ id: normalizedId })
      cache.gc()
    },
  })

export { useMutationDeletePost }
