import React, { useState, useEffect } from "react"

import { Chip, TextField, CircularProgress, InputAdornment } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { t } from "@lingui/macro"
import { Search } from "@material-ui/icons"
import { useQueryTags } from "../../data/tags/useQueryTags"
import { toId } from "../../utils"

const TagsField = ({
  label = "",
  placeholder = t`Select relevant tags`,
  inputProps: inputPropsIn,
  onChange,
  showSearchIcon = false,
  ...props
}) => {
  const inputProps = { label, placeholder, ...inputPropsIn }
  const [showHelperText, setShowHelperText] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { loading: queryTagLoading, data: tagData } = useQueryTags()
  const [tagOptions, setTagOptions] = useState([])

  const handleChange = (event, option) => {
    onChange && onChange(event, option)
  }

  useEffect(() => {
    if (tagData?.tags && tagData?.tags.length > 0) {
      const options =
        tagData?.tags?.map((item) => ({
          id: toId(item),
          name: item.name,
        })) ?? []

      setTagOptions(options)
    }
  }, [tagData])

  useEffect(() => {
    setIsLoading(queryTagLoading)
  }, [queryTagLoading])

  return (
    <Autocomplete
      loading={isLoading}
      multiple
      getOptionLabel={(option) => option?.name ?? ""}
      getOptionSelected={(option, value) => toId(option) === toId(value)}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => <Chip label={option.name} {...getTagProps({ index })} />)
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          {...inputProps}
          helperText={showHelperText ? "" : ""}
          InputProps={{
            ...params.InputProps,
            ...(showSearchIcon && {
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }),
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      onChange={handleChange}
      onInputChange={(_, newValue) => {
        setShowHelperText(newValue.length > 0)
      }}
      options={tagOptions}
      getOptionDisabled={(option) => {
        return props?.value?.findIndex((v) => toId(v) === toId(option)) !== -1
      }}
      {...props}
    />
  )
}

export default TagsField
