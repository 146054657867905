import React from "react"
import { Divider, ListSubheader, MenuItem } from "@material-ui/core"
import { PROCESS_SET, PROCESS_TYPE, useQueryProcesses } from "../../data"
import { toId } from "../../utils"
import { OutlinedSelect } from "./OutlinedSelect"

const TemplateOutlinedSelect = ({
  selectLabel = "Select job...",
  allLabel = "Any job template",
  hasAll = false,
  types = [PROCESS_TYPE.PROCESS, PROCESS_TYPE.AUDIT],
  disabled,
  filters = {},
  ...rest
}) => {
  // TODO: limit to 1000 for now, need to implement pagination/virtualization
  const { data: dataActiveItems, loading: loadingActive } = useQueryProcesses({
    variables: {
      filter: {
        ...filters,
        sets: [PROCESS_SET.ACTIVE_RECURRING, PROCESS_SET.ACTIVE_ONEOFF, PROCESS_SET.ACTIVE_ADHOC],
        types,
      },
      limit: 1000,
    },
  })
  const { data: dataInactiveItems, loading: loadingInactive } = useQueryProcesses({
    variables: {
      filter: {
        ...filters,
        sets: [PROCESS_SET.INACTIVE_RECURRING, PROCESS_SET.INACTIVE_ONEOFF, PROCESS_SET.INACTIVE_ADHOC],
        types,
      },
      limit: 1000,
    },
  })

  const loading = (loadingActive || loadingInactive) && !dataActiveItems && !dataInactiveItems

  const removeDuplicates = (arr) => {
    return arr.filter((proc, index) => arr.findIndex((p) => p.id === proc.id) === index)
  }

  const data = dataActiveItems ? removeDuplicates([...dataActiveItems.processes.list]) : []

  const dataInactive = dataInactiveItems ? removeDuplicates([...dataInactiveItems.processes.list]) : []

  return (
    <OutlinedSelect label="Job" native={false} {...rest} disabled={loading || disabled}>
      {!hasAll && <MenuItem value="none">{selectLabel}</MenuItem>}
      {hasAll && <MenuItem value="all">{allLabel}</MenuItem>}
      <Divider />
      {Boolean(data?.length) && <ListSubheader disableSticky>Active</ListSubheader>}
      {data
        ?.sort(({ name: a }, { name: b }) => a.localeCompare(b))
        .map((item) => (
          <MenuItem key={toId(item)} value={toId(item)}>
            {item.name}
          </MenuItem>
        ))}
      {data?.length > 0 && dataInactive?.length > 0 && <Divider />}
      {Boolean(dataInactive?.length) && <ListSubheader disableSticky>Inactive</ListSubheader>}
      {dataInactive
        ?.sort(({ name: a }, { name: b }) => a.localeCompare(b))
        .map((item) => (
          <MenuItem key={toId(item)} value={toId(item)}>
            {item.name}
          </MenuItem>
        ))}
    </OutlinedSelect>
  )
}

export { TemplateOutlinedSelect }
