import React, { useState, useEffect } from "react"
import { Box, makeStyles, Badge } from "@material-ui/core"
import { PhotoCameraOutlined } from "@material-ui/icons"
import { LinkButton, Avatar } from ".."
import { Uploader } from "./Uploader"
import { RowBox } from "../Boxes"

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(1),
  },
  avatar: {
    width: 80,
    height: 80,
    fontSize: 24,
  },
  image: {
    backgroundColor: theme.palette.shaded.main,
    borderRadius: 4,
  },
  badge: {
    borderRadius: 15,
    width: 26,
    height: 26,
  },
}))

const UploaderProfileImage = ({ avatarProps, badgeIcon, subject = "image", onChange }) => {
  const classes = useStyles()
  const [avatar, setAvatar] = useState(null)

  useEffect(() => {
    setAvatar(avatarProps)
  }, [avatarProps])

  const handleAfterUpload = (uploads) => {
    if (uploads && uploads.length && onChange) onChange(uploads[0])
  }

  const handleClear = () => {
    if (onChange) onChange(null)
  }

  return (
    <RowBox mb={3}>
      <Box mr={3} p={3} className={classes.image}>
        <Badge
          badgeContent={badgeIcon || <PhotoCameraOutlined style={{ fontSize: 16 }} />}
          color="primary"
          overlap="circular"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          classes={{ badge: classes.badge }}
        >
          {avatar && <Avatar {...avatar} className={classes.avatar} />}
        </Badge>
      </Box>

      <Box>
        <Box mb={1} display="flex" flexDirection="row">
          <Uploader
            onAfterUpload={handleAfterUpload}
            images
            documents={false}
            multiple={false}
            variant="link"
            linkText={<>Update your {subject}</>}
          />
          <Box mx={0.5}>or</Box>
          <LinkButton onClick={handleClear}>clear your current {subject}</LinkButton>
        </Box>
        <Box>Square (1:1) format in JPEG, PNG or GIF format. Maximum dimensions of 1080x1080 recommended</Box>
      </Box>
    </RowBox>
  )
}

export { UploaderProfileImage }
