import { useMutation, gql } from "@apollo/client"
import { produce } from "immer"
import { NOTIFICATIONS_LIMIT, NOTIFICATIONS_QUERY, NOTIFICATION_FIELDS } from "./useQueryNotifications"

const READ_ALL_NOTIFICATIONS_MUTATION = gql`
  mutation ReadAllNotifications {
    readAllNotifications {
      unread
      unconfirmed
      items {
        ...NotificationFields
      }
    }
  }
  ${NOTIFICATION_FIELDS}
`

const useMutationReadAllNotification = (options) =>
  useMutation(READ_ALL_NOTIFICATIONS_MUTATION, {
    update(cache, { data: { readAllNotifications } }) {
      const notificationsVariables = {
        limit: NOTIFICATIONS_LIMIT,
        offset: 0,
      }

      // get notifications
      const cachedData = cache.readQuery({ query: NOTIFICATIONS_QUERY, variables: notificationsVariables })

      // write notifications
      cache.writeQuery({
        query: NOTIFICATIONS_QUERY,
        variables: notificationsVariables,
        data: produce(cachedData, (data) => {
          // set unread + unconfirmed count
          data.notifications.unread = readAllNotifications.unread
          data.notifications.unconfirmed = readAllNotifications.unconfirmed
        }),
      })
    },
    ...options,
  })

export { useMutationReadAllNotification }
