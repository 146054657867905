import { gql } from "@apollo/client"

const TAG_FIELDS = gql`
  fragment TagFields on Tag {
    id
    url
    name
    description
  }
`

export { TAG_FIELDS }
