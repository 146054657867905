import { gql } from "@apollo/client"

const SEARCH_FIELDS = gql`
  fragment SearchFields on SearchResult {
    id
    title
    scheduleName
    processName
    displayName
    type
    date
    score
    organisation
    createdAt
    updatedAt
  }
`

export { SEARCH_FIELDS }
