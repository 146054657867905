import { useQuery, gql } from "@apollo/client"

const LABEL_CATEGORIES_FIELDS = `
    id
    name
    numberOfLabels
    items {     
      count
    }
    groups { id }
    locations { id }
    author { id firstName lastName avatar { key } }
    createdAt
    updatedAt
`

const LABEL_CATEGORIES_QUERY = gql`
  query LabelCategories($filter: LabelCategoryFilterInput) {
    labelCategories(filter: $filter) {
      ${LABEL_CATEGORIES_FIELDS}
    } 
  }
`

const LABEL_CATEGORY_QUERY = gql`
  query LabelCategories($id: ID!) {
    labelCategory(id: $id) {
      ${LABEL_CATEGORIES_FIELDS}
    }
  }
`

const useQueryLabelCategories = (options) => {
  const result = useQuery(LABEL_CATEGORIES_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

  return {
    ...result,
  }
}

const useQueryLabelCategory = (options) => {
  const result = useQuery(LABEL_CATEGORY_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

  return {
    ...result,
  }
}

export { useQueryLabelCategory, useQueryLabelCategories, LABEL_CATEGORIES_QUERY, LABEL_CATEGORIES_FIELDS }
