import { useQuery, gql } from "@apollo/client"

const POSTCOUNTS_FIELDS = `
  unconfirmed { 
    requested
    authored
  }
  scheduled
`

const POSTCOUNTS_QUERY = gql`query PostCounts {
  postCounts {
    ${POSTCOUNTS_FIELDS}
  } 
}`

const POSTCOUNTS_UPDATED_SUBSCRIPTION = gql`
  subscription postCountsUpdated {
    postCountsUpdated {
        ${POSTCOUNTS_FIELDS}
      }
  }
`

const postCountsUpdatedSubscriptionUpdateQuery = (prev, { subscriptionData }) => {
  if (!subscriptionData.data) return prev
  const { scheduled, unconfirmed } = subscriptionData.data.postCountsUpdated

  return {
    ...prev,
    postCounts: {
      ...prev.postCounts,
      unconfirmed: {
        ...prev.postCounts.unconfirmed,
        ...unconfirmed,
      },
      scheduled,
    },
  }
}

const subscribe = (result) => () =>
  [
    result.subscribeToMore({
      document: POSTCOUNTS_UPDATED_SUBSCRIPTION,
      updateQuery: postCountsUpdatedSubscriptionUpdateQuery,
      onError: (err) => console.log("[useQueryPostCounts][POSTCOUNTS_UPDATED_SUBSCRIPTION]", err),
    }),
  ]

const useQueryPostCounts = () => {
  const result = useQuery(POSTCOUNTS_QUERY)

  return {
    ...result,
    subscribe: subscribe(result),
  }
}

export { useQueryPostCounts }
