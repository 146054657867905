import { useQuery, gql } from "@apollo/client"
import { offsetLimitPagination } from "@apollo/client/utilities"

const REPORT_TRAINING_USER_FIELDS = gql`
  fragment ReportTrainingUserFields on ReportTrainingUser {
    id
    firstName
    lastName
    fullName
    avatar {
      key
    }
    roles {
      id
      friendlyName
    }
    training {
      courses {
        course
        status {
          percentComplete
          completedAt
          score {
            correct
            total
            percent
          }
        }
        dueAt
        createdAt
      }
      percentComplete
      status
      lastActiveAt
      dueAt
    }
  }
`

const reportTrainingUsersQueryTypePolicies = {
  root: {
    report: {
      trainingV2: offsetLimitPagination(["filter"]),
    },
  },
  scoped: {
    ReportTrainingQueryV2: {
      fields: {
        list: offsetLimitPagination(["filter"]),
        count: {
          keyArgs: ["filter"],
        },
        courses: {
          keyArgs: ["filter"],
        },
      },
    },
  },
}

const REPORT_TRAINING_USERS_AND_COUNT_QUERY = gql`
  query ReportTrainingUsersV2($filter: ReportTrainingUsersFilterInput!, $limit: Int, $offset: Int) {
    report {
      trainingV2 {
        list(filter: $filter, limit: $limit, offset: $offset) {
          ...ReportTrainingUserFields
        }
        count(filter: $filter)
        courses(filter: $filter) {
          id
          name
        }
      }
    }
  }
  ${REPORT_TRAINING_USER_FIELDS}
`

const useQueryReportTrainingUsersAndCount = (options) =>
  useQuery(REPORT_TRAINING_USERS_AND_COUNT_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

export { useQueryReportTrainingUsersAndCount, reportTrainingUsersQueryTypePolicies }
