import { useMutation, gql } from "@apollo/client"
import moment from "moment"
import { NOTIFICATION_QUERY } from "."
import { NOTIFICATION_FIELDS } from "./useQueryNotifications"

const CONFIRMNOTIFICATION_MUTATION = gql`
  ${NOTIFICATION_FIELDS}
  mutation ConfirmNotification($id: ID!) {
    confirmNotification(id: $id) {
      unread
      unconfirmed
      notification {
        ...NotificationFields
      }
    }
  }
`

const makeConfirmNotificationOptimisticResponse = (client, { notification: { id } }) => {
  const { notification } = client.readQuery({ query: NOTIFICATION_QUERY, variables: { id } })

  return {
    __typename: "Mutation",
    confirmNotification: {
      __typename: "NotificationChange",
      unread: 0,
      unconfirmed: 0,
      notification: {
        __typename: "Notification",
        ...notification,
        confirmed: moment().format(),
      },
    },
  }
}

const useMutationConfirmNotification = (options) => useMutation(CONFIRMNOTIFICATION_MUTATION, options)

export { useMutationConfirmNotification, makeConfirmNotificationOptimisticResponse }
