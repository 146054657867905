import { v4 as uuid } from "uuid"
import moment from "moment"
import { useMutation, gql } from "@apollo/client"
import serializationKeys from "../serializationKeys"
import { toId } from "../../utils"
import { useMakeOptimistic } from ".."
import { ADMINABLE_USERS_FIELDS } from "./fragments/adminableUserFieldsFragment"

const ADMINABLE_USER_MUTATION_SET_ATTRIBUTE = gql`
  mutation AdminableUserSetAttribute($id: ID!, $input: AttributeInput!) {
    adminableUser(id: $id) {
      setAttribute(input: $input) {
        ...AdminableUsersFields
      }
    }
  }
  ${ADMINABLE_USERS_FIELDS}
`

const ADMINABLE_USER_ADD_NOTE_MUTATION = gql`
  mutation AdminableUserAddNote($user: ID!, $text: String!) {
    adminableUser(id: $user) {
      addNote(text: $text) {
        ...AdminableUsersFields
      }
    }
  }
  ${ADMINABLE_USERS_FIELDS}
`

const ADMINABLE_USER_DELETE_NOTE_MUTATION = gql`
  mutation AdminableUserDeleteNote($user: ID!, $note: ID!) {
    adminableUser(id: $user) {
      note(id: $note) {
        delete
      }
    }
  }
`

export const useMutationAdminableUserSetAttribute = () => useMutation(ADMINABLE_USER_MUTATION_SET_ATTRIBUTE)

const makeAdminableUserAddNoteOptimisticResponse = (variables, author) => {
  const { user, text } = variables

  const result = {
    __typename: "Mutation",
    adminableUser: {
      addNote: {
        ...JSON.parse(JSON.stringify(user)),
      },
    },
  }

  result.adminableUser.addNote.notes.push({
    id: uuid(),
    text,
    author,
    createdAt: moment().format(),
  })

  return result
}

export const useMakeAdminableUserOptimistic = () => {
  const { currentUser } = useMakeOptimistic()

  return {
    makeAdminableUserAddNoteOptimisticResponse: (variables) =>
      makeAdminableUserAddNoteOptimisticResponse(variables, currentUser()),
  }
}

export const useMutationAdminableUserAddNote = (user) =>
  useMutation(ADMINABLE_USER_ADD_NOTE_MUTATION, {
    context: {
      serializationKey: serializationKeys.userNote(user),
    },
  })

export const useMutationAdminableUserDeleteNote = (user) =>
  useMutation(ADMINABLE_USER_DELETE_NOTE_MUTATION, {
    context: {
      serializationKey: serializationKeys.userNote(user),
    },
    update(cache, { errors, ...rest }, { variables: { note } }) {
      if (errors) {
        return
      }
      const normalizedId = cache.identify({ id: toId(note), __typename: "AdminableUserNote" })
      cache.evict({ id: normalizedId })
      cache.gc()
    },
  })
