import React from "react"
import { makeStyles, Typography, Box } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: ({ size }) => (size === "large" ? 20 : 16),
    lineHeight: ({ size }) => (size === "large" ? "36px" : "20px"),
    fontWeight: "600",
    color: theme.palette.text.primary,
  },
}))

const FieldSectionHeading = ({ children, mb = 1.5, size = "normal", className = "", ...rest }) => {
  const classes = useStyles({ size })
  return (
    <Box mb={mb} {...rest}>
      <Typography variant="h3" className={`${classes.root} ${className}`}>
        {children}
      </Typography>
    </Box>
  )
}

export { FieldSectionHeading }
