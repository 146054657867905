import { makeVar } from "@apollo/client"

const makeHubFiltersVar = () => ({
  groups: [],
  locations: [],
  tags: [],
})

const hubFiltersVar = makeVar({
  ...makeHubFiltersVar(),
})

const resetHubFiltersVar = () => {
  hubFiltersVar({ ...makeHubFiltersVar() })
}

export { resetHubFiltersVar, hubFiltersVar }
