import { useMutation, gql } from "@apollo/client"
import { DEVICE_FIELDS } from "./useQueryDevices"

const UPDATE_DEVICE_MUTATION = gql`
  mutation UpdateDevice(
      $id: ID!
      $label: String
      $location: ID
      $groups: [ID!]
      $timer: Int
    ) {
    updateDevice(
      id: $id
      label: $label
      location: $location
      groups: $groups
      timer: $timer
    ) {
      ${DEVICE_FIELDS}
    } 
  }
`

const useMutationUpdateDevice = () => useMutation(UPDATE_DEVICE_MUTATION)

export { useMutationUpdateDevice }
