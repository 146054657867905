import { useMutation, gql } from "@apollo/client"

const CREATE_USER_SCHEDULED_NOTIFICATION_MUTATION = gql`
  mutation CreateUserScheduledNotification($type: Int!, $cron: String, $locations: [ID!]!) {
    createUserScheduledNotification(type: $type, cron: $cron, locations: $locations) {
      id
    }
  }
`

const useMutationCreateUserScheduledNotification = () => useMutation(CREATE_USER_SCHEDULED_NOTIFICATION_MUTATION)

export { useMutationCreateUserScheduledNotification }
