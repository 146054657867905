import { useLazyQuery, gql } from "@apollo/client"

const CHECK_PASSWORD_COMPLEXITY_QUERY = gql`
  query CheckPasswordComplexity($password: String!) {
    checkPasswordComplexity(password: $password) {
      passed
      errors
    }
  }
`

const useLazyQueryCheckPasswordComplexity = (options) =>
  useLazyQuery(CHECK_PASSWORD_COMPLEXITY_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

export { useLazyQueryCheckPasswordComplexity }
