import { processFormats } from "../../data/processes/processFormats"

const FormatSymbol = ({ format }) => {
  const processFormat = processFormats.find((f) => f.name === format?.unit)

  if (!processFormat) {
    return null
  }

  return processFormat.symbol
}

export { FormatSymbol }
