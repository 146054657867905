import React, { useEffect, useState } from "react"
import { Box, useTheme, useMediaQuery } from "@material-ui/core"
import { t } from "@lingui/macro"
import ProcessCreator from "./ProcessCreator"
import AdhocCreator from "./AdhocCreator"
import { CreatorMaster } from "../Creators"
import { useAuth } from "../../services"

const JobCreator = ({ open, onClose, edit }) => {
  const theme = useTheme()
  const isInline = useMediaQuery(theme.breakpoints.down("xs"))
  const [subject, setSubject] = useState(null)
  const { location } = useAuth()

  const isEdit = edit != null

  const handleClose = (event, isCancel) => {
    onClose(isCancel)
  }

  useEffect(() => {
    if (open) {
      if (isEdit) setSubject(t`Edit job`)
      else if (!isEdit && !["adhoc-job"].includes(open)) setSubject(t`Create new...`)
      else if (!isEdit && open === "adhoc-job") setSubject(t`Run ad hoc job at ${location.name}`)
    }
  }, [isEdit, open, location])

  const form = (
    <>
      <Box mt={0}>
        {["process", "audit"].includes(open) && <ProcessCreator onClose={handleClose} edit={edit} />}
        {open === "adhoc-job" && <AdhocCreator onClose={handleClose} />}
      </Box>
    </>
  )

  return (
    <CreatorMaster
      id="jobcreator-dialog"
      open={open}
      title={subject}
      form={form}
      isEdit={isEdit}
      isInline={isInline}
      onClose={handleClose}
    />
  )
}

export { JobCreator }
