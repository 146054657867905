import { useMutation, gql } from "@apollo/client"

const UPDATE_SELF_MUTATION = gql`
  mutation UpdateSelf(
    $firstName: String
    $lastName: String
    $avatar: ID
    $location: ID
    $pin: String
    $simpleNotifications: SimpleNotificationsInput
  ) {
    updateSelf(
      firstName: $firstName
      lastName: $lastName
      avatar: $avatar
      location: $location
      pin: $pin
      simpleNotifications: $simpleNotifications
    ) {
      id
    }
  }
`

const useMutationUpdateSelf = () => useMutation(UPDATE_SELF_MUTATION)

export { useMutationUpdateSelf }
