import React from "react"
import { makeStyles, useTheme } from "@material-ui/styles"
import { Box, IconButton, Hidden, useMediaQuery } from "@material-ui/core"
import { FilterList as FilterListIcon } from "@material-ui/icons"
import { Trans } from "@lingui/macro"
import { MainHeading, NoItemsMessage, LoadingSpinner } from ".."
import Post from "./Post"
import { ModuleHeading } from "../Headings"
import { ListWindowVirtualizer } from "../Lists/ListWindowVirtualizer"

const useStyles = makeStyles((theme) => ({
  postList: {
    marginBottom: theme.spacing(1),
  },
  filterButton: {
    paddingTop: ({ xs }) => (xs ? 6 : "inherit"),
    paddingBottom: ({ xs }) => (xs ? 6 : "inherit"),
  },
  loading: {
    display: "flex",
    justifyContent: "center",
  },
}))

const PostList = ({ title, loading, error, posts, loadMore, onEdit, onDelete, onCommentCreated, onOpenSubNav }) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const classes = useStyles({ xs })

  const heading = (
    <>
      {!posts && loading && <Trans>Loading...</Trans>}
      {!posts && error && <Trans>Error</Trans>}
      {posts && title}
    </>
  )

  const items = posts || []

  return (
    <>
      <Box display="flex" alignItems="center" direction="row" mb={xs ? 1 : 3}>
        <Box flexGrow={1}>
          <Hidden xsDown>
            <MainHeading>{heading}</MainHeading>
          </Hidden>
          <Hidden smUp>
            <ModuleHeading noMargin>{heading}</ModuleHeading>
          </Hidden>
        </Box>
        <Box alignSelf="center" display={{ sm: "flex", md: "none" }}>
          <IconButton onClick={onOpenSubNav} className={classes.filterButton}>
            <FilterListIcon />
          </IconButton>
        </Box>
      </Box>

      <div className={classes.postList}>
        <ListWindowVirtualizer
          items={items}
          itemContent={(_, post) => (
            <Post key={post.id} post={post} onEdit={onEdit} onDelete={onDelete} onCommentCreated={onCommentCreated} />
          )}
          endReached={loadMore}
          components={{
            Footer: () => (
              <Box pt={3} pb={7}>
                {loading && (
                  <Box display="flex" justifyContent="center">
                    <LoadingSpinner size={60} />
                  </Box>
                )}
                {!loading && <> &nbsp;</>}
              </Box>
            ),
          }}
        />
        {loading && !items.length && (
          <Box display="flex" justifyContent="center">
            <LoadingSpinner size={60} />
          </Box>
        )}
        {!loading && !items.length && (
          <NoItemsMessage>
            <Trans>No matching items</Trans>
          </NoItemsMessage>
        )}
      </div>
    </>
  )
}

export { PostList }
