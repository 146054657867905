import React, { useState, useEffect, useRef } from "react"
import {
  Box,
  IconButton,
  Chip,
  MenuItem,
  useTheme,
  ListItemIcon,
  ListItemText,
  Hidden,
  useMediaQuery,
  Button,
  Tooltip,
  Typography,
  Menu,
} from "@material-ui/core"
import { Link, NavLink, useHistory, useLocation } from "react-router-dom"
import { makeStyles } from "@material-ui/styles"
import {
  Error as ErrorIcon,
  Tune as TuneIcon,
  ExitToApp as ExitToAppIcon,
  SwapHoriz,
  LanguageOutlined as LanguageIcon,
} from "@material-ui/icons"
import Config from "react-global-configuration"
import { useApolloClient } from "@apollo/client"
import moment from "moment"
import { Trans, t } from "@lingui/macro"
import {
  Icon,
  SearchPopover,
  Avatar,
  NotificationIndicator,
  LanguageSwitcher,
  LocationSwitcher,
  AnnouncementButton,
} from ".."
import { useAuth } from "../../services"
import { useSnackbar } from "../SnackbarProvider"
import { RequirePermissions } from "../Permissions"
import { IdleTimer } from "./IdleTimer"
import { RowBox } from "../Boxes"
import { HelpMenu } from "./HelpMenu"
import { truncateAtStart, truncateInMiddle, useDateUtils } from "../../utils"

const useStyles = makeStyles((theme) => ({
  tray: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "auto",
    alignItems: "center",
  },
  trayItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  trayOrganisation: {
    fontSize: 13,
    marginRight: 11,
    lineHeight: "100%",
    backgroundColor: theme.palette.shaded.main,
  },
  clickable: {
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.shaded.nav,
    },
    "&:focus": {
      background: theme.palette.shaded.nav,
    },
  },
  organisationLogo: {
    width: 24,
    height: 24,
  },
  trayOrganisationLabel: {
    color: theme.palette.text.primary,
    padding: ({ smDown }) => (smDown ? "0 2px" : "0 12px"),
  },
  trayIconButton: {
    marginRight: 0,
    padding: ({ smDown }) => (smDown ? 5 : theme.spacing(1)),
    "&:hover": {
      background: theme.palette.shaded.nav,
      transition: "all 0.2s ease",
    },
    "&:focus": {
      background: theme.palette.shaded.nav,
      transition: "all 0.2s ease",
    },
  },
  trayAvatarButton: {
    marginRight: theme.spacing(0),
    padding: "4px",
    "&:focus": {
      background: theme.palette.shaded.nav,
      transition: "all 0.2s ease",
    },
  },
  trayAvatar: {
    width: 40,
    height: 40,
  },
  time: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(0.5),
  },
  locationPrimary: {
    lineHeight: "16px",
  },
  menu: {
    minWidth: 307,
  },
  menuItem: {
    "&:hover": {
      background: theme.palette.shaded.nav,
    },
    "&:focus": {
      background: theme.palette.shaded.nav,
    },
  },
  menuItemShaded: {
    backgroundColor: theme.palette.shaded.main,
    "&:hover": {
      background: theme.palette.shaded.nav,
    },
    "&:focus": {
      background: theme.palette.shaded.nav,
    },
    "&.Mui-disabled": {
      opacity: 1,
    },
  },
  menuUser: {
    fontSize: 16,
    lineHeight: "16px",
    fontWeight: "600",
    color: theme.palette.text.primary,
    "&:focus": {
      background: theme.palette.background.default,
    },
    "&.Mui-disabled": {
      opacity: 1,
    },
  },
  menuLight: {
    fontSize: 12,
    lineHeight: "16px",
    fontWeight: "400",
    color: theme.palette.text.secondary,
  },
  menuListItemIcon: {
    minWidth: "auto",
    paddingRight: theme.spacing(2),
  },
  menuItemLanguage: {
    color: theme.palette.action.active,
  },
  kioskTimeLeftDesktop: {
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: "4px 0 0 4px",
    height: 36,
    width: 56,
    fontSize: 16,
    fontWeight: "600",
    color: theme.palette.error.main,
    textAlign: "center",
    lineHeight: "34px",
  },
  kioskLogoutDesktop: {
    borderRadius: "0 4px 4px 0",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  kioskTimeLeftMobile: {
    fontSize: 11,
    fontWeight: "600",
    textAlign: "center",
    height: "100%",
    lineHeight: "100%",
  },
  kioskLogoutTextMobile: {
    lineHeight: "100%",
  },
  kioskLogoutMobile: {
    borderRadius: "4px",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    "&&>span": {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.5),
    },
  },
  chipError: {
    "&.MuiChip-avatar": {
      color: theme.palette.error.main,
    },
  },
}))

const HeaderTray = ({ onKioskLoggingOut }) => {
  const theme = useTheme()
  const history = useHistory()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))
  const classes = useStyles({ smDown })
  const { hasTimeOffsetFromUser } = useDateUtils()
  const { clientKiosk: kiosk } = Config.get()
  const client = useApolloClient()
  const {
    authed,
    principal,
    settings: { organisation, locations, preferences },
    location,
    logout,
    hasFeature,
  } = useAuth(client)
  const { pathname } = useLocation()
  const searchButtonEl = useRef(null)
  const [searchEl, setSearchEl] = useState(null)
  const accountButtonEl = useRef(null)
  const [accountEl, setAccountEl] = useState(null)
  const [showLocationSwitcher, setShowLocationSwitcher] = useState(false)
  const [showLanguageSwitcher, setShowLanguageSwitcher] = useState(false)
  const [global, setGlobal] = useState(false)
  const { showMessage, clearMessage } = useSnackbar()

  useEffect(() => {
    if (!location)
      showMessage({
        color: "secondary",
        message: (
          <>
            There are no locations associated {smDown && <br />}with your user. Contact your {smDown && <br />}system
            administrator.
          </>
        ),
        icon: <ErrorIcon />,
        keepOpen: true,
      })
    return () => clearMessage()
  })

  useEffect(() => {
    setGlobal(
      pathname.startsWith("/knowledge") ||
        pathname.startsWith("/hub") ||
        pathname.startsWith("/people") ||
        pathname.startsWith("/reports") ||
        pathname.startsWith("/assets") ||
        pathname.startsWith("/suppliers") ||
        pathname.startsWith("/templates") ||
        pathname.startsWith("/training") ||
        pathname.startsWith("/account"),
    )
  }, [pathname, setGlobal])

  const isAuthed = authed
  if (!isAuthed) return null

  const { username, firstName, lastName, email, avatar, external } = principal

  const handleSearchClick = (event) => {
    setSearchEl(event.currentTarget)
  }
  const handleSearchClose = () => {
    setSearchEl(null)
  }

  const handleAccountClick = (event) => {
    setAccountEl(event.currentTarget)
  }
  const handleAccountClose = () => {
    setAccountEl(null)
  }

  const handleLocationClick = () => {
    if (xs) {
      history.push({
        pathname: "/locations/change",
        state: { from: pathname },
      })
    } else {
      setShowLocationSwitcher(true)
    }
    handleAccountClose()
  }
  const handleLocationClose = () => {
    setShowLocationSwitcher(false)
  }

  const handleLanguageClick = () => {
    setShowLanguageSwitcher(true)
  }

  const handleLanguageClose = () => {
    setShowLanguageSwitcher(false)
    setAccountEl(null)
  }

  const handleLogout = async () => {
    await logout()
    history.push("/login")
  }

  const handleKioskLogout = () => {
    onKioskLoggingOut && onKioskLoggingOut()
    logout()
  }

  const handleIdleLoggingOut = () => {
    onKioskLoggingOut && onKioskLoggingOut()
  }

  const isDifferentTimezone = location && hasTimeOffsetFromUser(location.timeZone)
  const multiLocation = locations.length > 1

  return (
    <>
      {!kiosk && <LocationSwitcher open={showLocationSwitcher} onClose={handleLocationClose} />}

      <LanguageSwitcher open={showLanguageSwitcher} onClose={handleLanguageClose} />

      <Box className={classes.tray}>
        {!kiosk && (
          <Hidden mdDown>
            {locations && locations.length > 0 && (
              <>
                {/* Display org chip with timezone if required */}
                {!global && location && multiLocation && (
                  <Chip
                    onClick={handleLocationClick}
                    classes={{
                      root: `${classes.trayOrganisation} ${classes.clickable}`,
                      label: classes.trayOrganisationLabel,
                    }}
                    label={
                      <Hidden smDown>
                        <Box display="flex" flexDirection="row" alignItems="center">
                          <Box display="flex" flexDirection="row" alignItems="start" mr={0.5}>
                            {truncateAtStart(location.name, 20)}
                            {isDifferentTimezone && (
                              <small className={classes.time}>{moment.tz(location.timeZone).format("z")}</small>
                            )}
                          </Box>
                          <SwapHoriz fontSize="small" />
                        </Box>
                      </Hidden>
                    }
                    avatar={<Avatar avatar={location.logo || { key: organisation.logo }} fullName={location.name} />}
                  />
                )}

                {/* Display an error chip if there's no locations in this org for the user */}
                {!global && !location && (
                  <>
                    <Chip
                      onClick={handleLocationClick}
                      classes={{
                        root: `${classes.trayOrganisation} ${classes.clickable}`,
                        label: classes.trayOrganisationLabel,
                      }}
                      label={
                        <Hidden smDown>
                          <Box display="flex" flexDirection="row" alignItems="center">
                            <Box display="flex" flexDirection="column" alignItems="start" mr={0.5}>
                              {organisation.name}
                            </Box>
                            <SwapHoriz fontSize="small" />
                          </Box>
                        </Hidden>
                      }
                      avatar={
                        <Tooltip title={t`There are no locations associated with your user.`}>
                          <ErrorIcon className={classes.chipError} color="error" />
                        </Tooltip>
                      }
                    />
                  </>
                )}

                {/* Display a global selector with no click handler or hover for areas of the app that are not location specific (e.g. knowledge base) or if only single location */}
                {(!multiLocation || global) && (
                  <Chip
                    classes={{ root: classes.trayOrganisation, label: classes.trayOrganisationLabel }}
                    label={
                      <Hidden smDown>
                        {multiLocation ? (
                          t`All locations`
                        ) : (
                          <Box display="flex" flexDirection="row" alignItems="start" mr={0.5}>
                            {truncateAtStart(location.name, 20)}
                            {isDifferentTimezone && (
                              <small className={classes.time}>{moment.tz(location.timeZone).format("z")}</small>
                            )}
                          </Box>
                        )}
                      </Hidden>
                    }
                    avatar={
                      organisation.logo && <Avatar avatar={{ key: organisation.logo }} fullName={organisation.name} />
                    }
                  />
                )}
              </>
            )}
          </Hidden>
        )}
        {!kiosk && <AnnouncementButton className={classes.trayItem} buttonClassName={classes.trayIconButton} />}
        <Box className={classes.trayItem}>
          <HelpMenu className={classes.trayIconButton} menuClasses={{ paper: classes.menu }} />
        </Box>
        <RequirePermissions requires={["post_read", "knowledge_read", "jobprocess_read_self", "jobprocess_read_all"]}>
          <Box className={classes.trayItem}>
            <Tooltip title={<Trans>Search</Trans>}>
              <IconButton
                ref={searchButtonEl}
                aria-label={<Trans>Search</Trans>}
                onClick={handleSearchClick}
                className={classes.trayIconButton}
                data-cy="IconButton-search"
              >
                <Icon name="search" />
              </IconButton>
            </Tooltip>

            <SearchPopover open={Boolean(searchEl)} anchorEl={searchEl} onClose={handleSearchClose} />
          </Box>
        </RequirePermissions>
        {hasFeature("calendar") && (
          <Box className={classes.trayItem}>
            <Tooltip title={<Trans>Calendar</Trans>}>
              <IconButton
                component={Link}
                to="/calendar"
                className={classes.trayIconButton}
                data-cy="IconButton-calendar"
              >
                <Icon name="calendar" />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        <Box className={classes.trayItem}>
          <NotificationIndicator buttonClassName={classes.trayIconButton} iconClassName={classes.trayNotifications} />
        </Box>
        <Box>
          <IconButton
            title="Your account"
            onClick={handleAccountClick}
            ref={accountButtonEl}
            className={classes.trayAvatarButton}
            data-cy="HeaderTray-IconButton-account"
          >
            <Avatar className={classes.trayAvatar} firstName={firstName} lastName={lastName} avatar={{ key: avatar }} />
          </IconButton>
          <Menu
            open={Boolean(accountEl)}
            anchorEl={accountEl}
            onClose={handleAccountClose}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            classes={{ paper: classes.menu }}
          >
            <MenuItem disabled divider={!kiosk ? true : undefined} className={classes.menuUser}>
              <Box py={0.5}>
                <Box mb={0.3}>
                  {firstName} {lastName}
                </Box>
                <Box className={classes.menuLight}>{external ? email : username}</Box>
              </Box>
            </MenuItem>

            {!kiosk && (
              <MenuItem
                divider
                disabled={!multiLocation}
                className={`${classes.menuItem} ${classes.menuItemShaded}`}
                onClick={handleLocationClick}
              >
                <Box display="flex" flexGrow={1} flexDirection="column">
                  <Box className={classes.menuLight}>
                    <Trans>Current location</Trans>
                  </Box>
                  <Box display="flex" flexDirection="row" flexGrow={1} alignItems="center">
                    {location && (
                      <>
                        <ListItemIcon className={classes.menuListItemIcon}>
                          <Avatar
                            avatar={location.logo || { key: organisation.logo }}
                            fullName={location.name}
                            className={classes.organisationLogo}
                          />
                        </ListItemIcon>
                        <ListItemText
                          classes={{ primary: classes.locationPrimary, secondary: classes.locationSecondary }}
                          primary={truncateInMiddle(location.name, xs ? 28 : 50)}
                          secondary={isDifferentTimezone && <small>{moment.tz(location.timeZone).format("z")}</small>}
                        />
                        {multiLocation && (
                          <Box display="flex" justifySelf="flex-end" alignItems="center">
                            <SwapHoriz color="action" />
                          </Box>
                        )}
                      </>
                    )}
                    {!location && (
                      <>
                        <ListItemIcon className={classes.menuListItemIcon}>
                          <ErrorIcon color="error" />
                        </ListItemIcon>
                        <ListItemText primary="No locations" secondary="Contact your administrator" />
                      </>
                    )}
                  </Box>
                </Box>
              </MenuItem>
            )}
            {hasFeature("language") && (
              <MenuItem divider className={classes.menuItem} onClick={handleLanguageClick}>
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" flexGrow={1}>
                  <Box display="flex" flexDirection="row" flexGrow={1} alignItems="center">
                    <ListItemIcon className={classes.menuListItemIcon}>
                      <LanguageIcon />
                    </ListItemIcon>
                    <ListItemText primary={<Trans>Language</Trans>} />
                  </Box>
                  <Box>
                    <Typography variant="body1" className={classes.menuItemLanguage}>
                      {preferences.language.toUpperCase()}
                    </Typography>
                  </Box>
                </Box>
              </MenuItem>
            )}
            <MenuItem
              component={NavLink}
              divider={!kiosk ? true : undefined}
              to="/account"
              className={classes.menuItem}
              onClick={() => setAccountEl(null)}
              data-cy="HeaderTray-MenuItem-settings"
            >
              <ListItemIcon className={classes.menuListItemIcon}>
                <TuneIcon />
              </ListItemIcon>
              <ListItemText primary={<Trans>Settings</Trans>} />
            </MenuItem>
            {!kiosk && (
              <MenuItem className={classes.menuItem} onClick={handleLogout} data-cy="MenuItem-logout">
                <ListItemIcon className={classes.menuListItemIcon}>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary={<Trans>Sign out</Trans>} />
              </MenuItem>
            )}
          </Menu>
        </Box>
        {kiosk && (
          <>
            <Hidden smDown>
              <RowBox ml={1}>
                <Box className={classes.kioskTimeLeftDesktop}>
                  <IdleTimer onLoggingOut={handleIdleLoggingOut} />
                </Box>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleKioskLogout}
                  className={classes.kioskLogoutDesktop}
                >
                  Logout
                </Button>
              </RowBox>
            </Hidden>

            <Hidden mdUp>
              <RowBox ml={1}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleKioskLogout}
                  className={classes.kioskLogoutMobile}
                >
                  <Box className={classes.kioskLogoutTextMobile}>Logout</Box>
                  <Box className={classes.kioskTimeLeftMobile}>
                    <IdleTimer onLoggingOut={handleIdleLoggingOut} />
                  </Box>
                </Button>
              </RowBox>
            </Hidden>
          </>
        )}
      </Box>
    </>
  )
}

export default HeaderTray
