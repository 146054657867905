import React from "react"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  overdue: {
    color: ({ overdue }) => (overdue ? theme.palette.error.main : "inherit"),
    fontWeight: ({ strong }) => (strong ? "600" : "normal"),
  },
}))

const Overdue = ({ children, overdue, strong, className = "", ...rest }) => {
  const classes = useStyles({ overdue, strong })

  return (
    <span className={`${className} ${overdue ? classes.overdue : ""}`} {...rest}>
      {children}
    </span>
  )
}

export { Overdue }
