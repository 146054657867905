import React from "react"
import { List } from "@material-ui/core"

const NavigableList = (props) => {
  const { children, data, ...other } = props
  const elements = data.map((val, i) => children(val, i))

  const gotoPrevElement = () => {
    const selected = elements.findIndex((e) => e.props.selected)
    if (selected > 0) {
      const el = elements[selected - 1]
      el.props.onClick(data[selected - 1])
    }
  }
  const gotoNextElement = () => {
    const selected = elements.findIndex((e) => e.props.selected)
    if (selected > -1 && selected < elements.length - 1) {
      const el = elements[selected + 1]
      el.props.onClick(data[selected + 1])
    }
  }

  const handleKey = (e) => {
    if (e.key === "ArrowDown") {
      gotoNextElement()
    }
    if (e.key === "ArrowUp") {
      gotoPrevElement()
    }
  }

  return (
    <List onKeyDown={handleKey} {...other}>
      {elements}
    </List>
  )
}

export { NavigableList }
