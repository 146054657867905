import PropTypes from "prop-types"
import { PROCESS_STEP_SELECTION_SOURCES } from "../data"

export const tagTypes = PropTypes.shape({
  id: PropTypes.string,
  url: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
})

export const viewTypes = PropTypes.shape({
  id: PropTypes.string,
  url: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  tags: PropTypes.arrayOf(tagTypes),
})

export const locationTypes = PropTypes.shape({
  id: PropTypes.string,
  inactive: PropTypes.bool,
  name: PropTypes.string,
  timeZone: PropTypes.string,
})

export const selectionTypes = PropTypes.shape({
  allowMultiple: PropTypes.bool,
  selectFrom: PropTypes.oneOf(Object.values(PROCESS_STEP_SELECTION_SOURCES)),
})

export const jobStepTypes = PropTypes.shape({
  id: PropTypes.string,
  selection: selectionTypes,
})

export const foodItemTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
})

export const prepListTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  lockPrepList: PropTypes.string,
  foodItems: PropTypes.array,
})
