import React from "react"
import { Icon, Box } from "@material-ui/core"
import { Avatar } from ".."

const IconPickerListItem = ({ item, onChange, ...props }) => (
  <Box onClick={(e) => onChange(e, item)} {...props} title={item.name}>
    <Avatar icon={<Icon className="material-icons-outlined">{item.name}</Icon>} />
  </Box>
)

export { IconPickerListItem }
