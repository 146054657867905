import { useMutation, gql } from "@apollo/client"
import { ADMINABLE_USERS_FIELDS } from "./fragments/adminableUserFieldsFragment"

const CREATE_USER_MUTATION = gql`
  mutation CreateUser($input: AdminableUserCreateInput!) {
    createUser(input: $input) {
      ...AdminableUsersFields
    }
  }
  ${ADMINABLE_USERS_FIELDS}
`

const useMutationCreateUser = () => useMutation(CREATE_USER_MUTATION)

export { useMutationCreateUser }
