import React, { memo, useState } from "react"
import { Paper, List, ListItem, ListItemAvatar, ListItemText, Button, Typography, Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import moment from "moment"
import { Trans } from "@lingui/macro"
import { momentToScheduleDate, toId, useJobUtils, useMountEffect } from "../../utils"
import { Avatar, ActionCardInfo, NavLinkItem, TimeAgo, AdornmentIcon, NoItemsMessage } from ".."
import { UserLink } from "../UserLink"
import { ModuleHeading } from "../Headings"
import { useQueryJobsActivity } from "../../data"
import { useAuth } from "../../services"

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    lineHeight: "20px",
    marginBottom: theme.spacing(1),
  },
  item: {
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  content: {
    borderRadius: 4,
    fontSize: 14,
    lineHeight: "18px",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.grey[100],
    marginBottom: theme.spacing(1),
    padding: `5px ${theme.spacing(1)}px`,
  },
  footer: {
    padding: theme.spacing(2),
  },
  more: {
    textTransform: "none",
    fontSize: 14,
    fontWeight: "600",
    lineHeight: "16px",
  },
}))

const pollingInterval = 10000

const Activity = ({
  blockSize = 5,
  heading = <Trans>Recent Activity</Trans>,
  noItemsMessage = <Trans>No recent activity</Trans>,
  hideOnNoItems = true,
}) => {
  const [count, setCount] = useState(blockSize)
  const { location } = useAuth()
  const date = moment()
    .tz(location?.timeZone || moment.tz.guess())
    .startOf("day")
  const { data, startPolling, stopPolling } = useQueryJobsActivity({
    variables: {
      filter: { location: toId(location, true), date: momentToScheduleDate(date) },
    },
    skip: !location,
  })

  useMountEffect(() => {
    startPolling(pollingInterval)
    return () => {
      stopPolling()
    }
  })

  const classes = useStyles()

  const items = data ? [...data.jobs.activity] : []

  const handleViewMoreClick = () => {
    setCount(count + blockSize)
  }

  if (hideOnNoItems && (!items || items.length === 0)) return null

  return (
    <>
      {heading && <ModuleHeading>{heading}</ModuleHeading>}
      <Paper elevation={3}>
        <List disablePadding>
          {items.length === 0 && <NoItemsMessage>{noItemsMessage}</NoItemsMessage>}
          {items
            .sort((a, b) => moment(b.at).diff(moment(a.at), "second"))
            .slice(0, count < items.length ? count : items.length)
            .map((item, index) => (
              <ActivityListItemMemo key={index} {...item} classes={classes} />
            ))}
        </List>
        {items.length > count && (
          <Grid container justifyContent="center" alignItems="center" className={classes.footer}>
            <Button className={classes.more} color="primary" onClick={handleViewMoreClick}>
              <Trans>View more</Trans>
            </Button>
          </Grid>
        )}
      </Paper>
    </>
  )
}

const ActivityListItem = ({ author, jobId: id, availableUntil, displayName, stepName, action, at, classes }) => {
  const { getNavigateToLink } = useJobUtils()
  const { location } = useAuth()

  return (
    <ListItem divider alignItems="flex-start" className={classes.item}>
      <ListItemAvatar>
        <Avatar {...author} />
      </ListItemAvatar>
      <ListItemText>
        <Typography variant="h2" className={classes.title}>
          <>
            <UserLink user={author} /> {action} {stepName} in{" "}
            <NavLinkItem to={getNavigateToLink({ id, location, availableUntil })} text={displayName} display="inline" />
          </>
        </Typography>
        <ActionCardInfo>
          <AdornmentIcon name="process" />
          <TimeAgo date={at} />
        </ActionCardInfo>
      </ListItemText>
    </ListItem>
  )
}

const ActivityListItemMemo = memo(ActivityListItem)

export { Activity }
