import { useMutation, gql } from "@apollo/client"

const ADMINABLE_USER_CANCEL_USERNAME_CHANGE_MUTATION = gql`
  mutation AdminableUserCancelUsernameChange($id: ID!) {
    adminableUser(id: $id) {
      cancelUsernameChange
    }
  }
`

export const useMutationAdminableUserCancelUsernameChange = () =>
  useMutation(ADMINABLE_USER_CANCEL_USERNAME_CHANGE_MUTATION, {
    errorPolicy: "all",
  })
