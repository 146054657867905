const SHIFT_FIELDS = `
  id
  startsAt
  endsAt
  location { id name }
  group { id name }
  otherLocationName
  otherGroupName
`

// const USER_SHIFTS_QUERY = gql`
//   query UserShifts($location: ID!) {
//     userShifts(location: $location) {
//       ${SHIFT_FIELDS}
//     }
//   }
// `

// const useQueryUserShifts = (options) => useQuery(USER_SHIFTS_QUERY, options)

// const useLazyQueryUserShifts = (options) => useLazyQuery(USER_SHIFTS_QUERY, options)

export { SHIFT_FIELDS }
