import React, { useState, useEffect } from "react"
import { Box, ListItemIcon, MenuItem, makeStyles, useMediaQuery, useTheme } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import { Trans, t } from "@lingui/macro"
import { CreatorActions, CreatorMaster } from "../Creators"
import { Icon, useSnackbar } from ".."
import { useAuth } from "../../services"
import { OutlinedSelect } from "../OutlinedSelect"
import { useMutationUpdatePreferences, makeUpdatePreferencesOptimisticResponse } from "../../data"
import { dynamicActivate, locales } from "../../i18n"
import { useLocalStorage } from "../../utils"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  flag: {
    marginRight: theme.spacing(1),
    width: "24px",
    height: "24px",
  },
}))

const initialState = {
  userLanguage: "en",
}

const LanguageSwitcher = ({ open, onClose, isInline }) => {
  const [userLanguage, setUserLanguage] = useState(initialState.userLanguage)
  const [updatePreferences] = useMutationUpdatePreferences()
  const snackbar = useSnackbar()
  const classes = useStyles()
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))

  const [, setStoredLang] = useLocalStorage("lang")
  const [loading, setLoading] = useState(false)

  const {
    settings: { preferences },
  } = useAuth()

  useEffect(() => {
    setUserLanguage(preferences.language)
  }, [preferences.language])

  const handleCancel = () => {
    setUserLanguage(preferences.language)
    dynamicActivate(preferences.language)
    handleClose()
  }

  const handleClose = () => {
    onClose && onClose()
  }

  // Updates 2 things - local state and i18n locale. Set localstorage value only once submitted
  const handleLanguageChange = (lang) => {
    setUserLanguage(lang)
    dynamicActivate(lang)
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      setStoredLang(userLanguage)
      const variables = {
        input: {
          language: userLanguage,
        },
      }
      await updatePreferences({
        variables,
        optimisticResponse: makeUpdatePreferencesOptimisticResponse({
          variables,
          preferences,
        }),
      })
      setLoading(false)
      handleClose()
    } catch (error) {
      setLoading(false)
      setStoredLang(preferences.language)
      snackbar.showMessage({ message: error.message })
    }
  }

  const form = (
    <Box mb={xs ? 2 : 0}>
      <Box mb={2}>
        <Alert severity="info">
          <AlertTitle>
            <Trans>Changing languages</Trans>
          </AlertTitle>
          <Trans>This will not affect the language of any content you have created.</Trans>
        </Alert>
      </Box>
      <Box mt={2} mb={2}>
        <OutlinedSelect
          label={t`Language`}
          native={false}
          value={userLanguage}
          onChange={(e) => handleLanguageChange(e.target.value)}
        >
          {locales.map(({ localeFull, locale, name, icon }) => (
            <MenuItem key={localeFull} value={locale}>
              <Box className={classes.root}>
                <ListItemIcon>
                  <Icon name={icon} className={classes.flag} />
                </ListItemIcon>
                {name}
              </Box>
            </MenuItem>
          ))}
        </OutlinedSelect>
      </Box>
      <CreatorActions
        id="LanguageCreator-CreatorActions"
        subject={t`language`}
        submitLabel={<Trans>Set language</Trans>}
        submitLoading={loading}
        onClose={handleCancel}
        onSubmit={handleSubmit}
      />
    </Box>
  )

  return (
    <CreatorMaster
      open={open}
      subject={t`language`}
      form={form}
      title={<Trans>Set language</Trans>}
      isInline={isInline}
      onClose={handleCancel}
    />
  )
}

export { LanguageSwitcher }
