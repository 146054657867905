import React from "react"
import { useTheme } from "@material-ui/core"
import MentionHighlighter from "./MentionHighlighter"
import { getMentionChildren } from "./MentionTextInput"

const MentionOutput = ({ content }) => {
  const theme = useTheme()

  if (!content) return null

  return (
    <MentionHighlighter value={content}>{getMentionChildren({ color: theme.palette.primary.main })}</MentionHighlighter>
  )
}

export { MentionOutput }
