import { gql } from "@apollo/client"
import { DEVICE_FIELDS } from "./useQueryDevices"

const REGISTERING_DEVICE_QUERY = gql`
  query RegisteringDevice($passcode: String!) {
    registeringDevice(passcode: $passcode) {
      ${DEVICE_FIELDS}
    }
  }
`

const queryRegisteringDevice = async (client, passcode) => {
  const response = await client.query({
    query: REGISTERING_DEVICE_QUERY,
    variables: { passcode },
    errorPolicy: "ignore",
    fetchPolicy: "network-only",
  })
  return response
}

export { queryRegisteringDevice }
