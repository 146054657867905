import { toId } from "../utils"

export default {
  job: (job) => `job-${toId(job, true) || "unknown"}`,
  jobStep: (job, jobStep) => `job-step-${toId(job, true) || "unknown"}-${toId(jobStep, true) || "unknown"}`,
  post: (post) => `post-${toId(post, true) || "unknown"}`,
  action: (action) => `action-${toId(action, true) || "unknown"}`,
  userNote: (user) => `user-note-${toId(user, true) || "unknown"}`,
  userTraining: (userTraining) => `user-training-${toId(userTraining, true) || "unknown"}`,
}
