import { useMutation, gql } from "@apollo/client"
import { toId } from "../../utils"

const DELETE_PREP_LIST_MUTATION = gql`
  mutation DeletePrepList($id: ID!) {
    prepList(id: $id) {
      delete
    }
  }
`

const useMutationDeletePrepList = () => {
  return useMutation(DELETE_PREP_LIST_MUTATION, {
    update(cache, { errors }, { variables: { id } }) {
      if (errors) {
        return
      }
      const normalizedId = cache.identify({ id: toId(id), __typename: "PrepList" })
      cache.evict({ id: normalizedId })
      cache.gc()
    },
    refetchQueries: ["PrepListsCount"],
  })
}

export { useMutationDeletePrepList }
