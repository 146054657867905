import React from "react"
import { Badge, makeStyles } from "@material-ui/core"
import { Cancel, CheckCircle } from "@material-ui/icons"

const useStyles = makeStyles((theme) => ({
  selectedIcon: {
    color: theme.palette.success.main,
    backgroundColor: theme.palette.background.default,
    borderRadius: "100%",
  },
  warningIcon: {
    color: theme.palette.error.main,
    backgroundColor: theme.palette.background.default,
    borderRadius: "100%",
  },
}))

const SelectionBadge = ({ children, status = "none" }) => {
  const classes = useStyles()

  switch (status) {
    case "selected":
      return (
        <Badge badgeContent={<CheckCircle size="small" className={classes.selectedIcon} />} overlap="circular">
          {children}
        </Badge>
      )
    case "warning":
      return (
        <Badge badgeContent={<Cancel size="small" className={classes.warningIcon} />} overlap="circular">
          {children}
        </Badge>
      )
    default:
      return children
  }
}

export { SelectionBadge }
