import Base from "./Base"

export default {
  httpBaseUri: "https://local.operandiodev.com:5022",
  httpServiceBaseUri: "https://local.operandiodev.com:5022/graphql",
  wsServiceBaseUri: "wss://local.operandiodev.com:5022/graphql",

  showConfigInTitle: true,
  configTitle: "[LOCALTEST]",

  theme: {
    logoContainer: "images-dev",
  },

  uploader: {
    ...Base.uploader,

    imageViewerBaseUri: "https://dydc66wtc7q9n.cloudfront.net",
    // imageViewerBucketName: "operandio-content-dev",
    imageViewerBucketName: "operandio-content-ap-southeast-2",
  },

  errors: {
    ...Base.errors,

    showDetail: false,
    showGraphQLErrorNotification: true,
  },
}
