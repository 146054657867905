import { useQuery, useLazyQuery, gql } from "@apollo/client"

const REPORT_LOCATION_TRAINING_FIELDS = `
  id
  name
  timeZone
  users {
    id
    firstName
    lastName
    fullName
    avatar { key }
    training {
      percentComplete
      status
      lastActiveAt
      dueAt
    }
  }
`

const REPORT_LOCATION_TRAINING_QUERY = gql`
  query ReportLocationTraining($location: ID!) {
    reportLocationTraining(location: $location)
    {
      ${REPORT_LOCATION_TRAINING_FIELDS}
    } 
  }
`

const useQueryReportLocationTraining = (options) =>
  useQuery(REPORT_LOCATION_TRAINING_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

const useLazyQueryReportLocationTraining = (options) =>
  useLazyQuery(REPORT_LOCATION_TRAINING_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

export { useQueryReportLocationTraining, useLazyQueryReportLocationTraining }
