import React from "react"
import { Box, FormControlLabel, Hidden, Switch, makeStyles } from "@material-ui/core"
import { RowBox } from "../Boxes"
import { HintTooltip } from "../Tooltip"

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 15,
  },
  hint: {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
}))

const ConditionSwitch = ({ label, hint, ...rest }) => {
  const classes = useStyles()

  return (
    <RowBox mt={1} px={1}>
      <FormControlLabel
        control={<Switch color="primary" size="small" {...rest} />}
        label={
          <Box ml={0.5} className={classes.label}>
            {label}
          </Box>
        }
      />
      <Hidden xsDown>
        {!!hint && (
          <Box ml="auto" className={classes.hint}>
            {hint}
          </Box>
        )}
      </Hidden>
      <Hidden smUp>
        <HintTooltip hint={hint} />
      </Hidden>
    </RowBox>
  )
}

export { ConditionSwitch }
