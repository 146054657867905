import { useLazyQuery, useQuery, gql } from "@apollo/client"
import * as Sentry from "@sentry/browser"
import { FOOD_ITEM_FIELDS } from "./fragments/foodItemFieldsFragment"

const FOOD_ITEMS_LIMIT = 10

const FOOD_ITEMS_QUERY = gql`
  query FoodItems($filter: FoodItemFilterInput, $offset: Int, $limit: Int) {
    foodItems {
      list(filter: $filter, limit: $limit, offset: $offset) {
        ...FoodItemFields
      }
      count(filter: $filter)
    }
  }
  ${FOOD_ITEM_FIELDS}
`

const FOOD_ITEMS_COUNT_QUERY = gql`
  query FoodItemsCount($filter: FoodItemFilterInput) {
    foodItems {
      count(filter: $filter)
    }
  }
`

const foodItemsPolicies = {
  scoped: {
    FoodItemsQuery: {
      merge(prev, next) {
        return {
          ...(prev || {}),
          ...next,
        }
      },
    },
  },
}

const useQueryFoodItems = (options) => useQuery(FOOD_ITEMS_QUERY, options)
const useQueryFoodItemsCount = (options) => useQuery(FOOD_ITEMS_COUNT_QUERY, options)
const loadMoreForScoped = (result) => async () => {
  try {
    await result.fetchMore({
      variables: {
        ...result.variables,
        offset: result.data.foodItems.list.length,
        limit: FOOD_ITEMS_LIMIT,
      },
    })
  } catch (error) {
    Sentry.captureException(error)
  }
}

const useLazyQueryFoodItems = (options) => {
  const { variables, ...otherOptions } = options || {}
  const [loadItems, result] = useLazyQuery(FOOD_ITEMS_QUERY, {
    variables: {
      offset: 0,
      limit: FOOD_ITEMS_LIMIT,
      ...variables,
    },
    fetchPolicy: "cache-and-network",
    ...otherOptions,
  })

  return [
    loadItems,
    {
      ...result,
      loadMore: loadMoreForScoped(result),
    },
  ]
}

export { useQueryFoodItems, useQueryFoodItemsCount, useLazyQueryFoodItems, FOOD_ITEMS_COUNT_QUERY, foodItemsPolicies }
