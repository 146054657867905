import React from "react"

const Assignees = ({ users: usersInitial, groups: groupsInitial, limit, className }) => {
  const users = usersInitial || []
  const groups = groupsInitial || []
  const count = users.length + groups.length

  return (
    <span className={className}>
      {count === 0 && "All staff"}
      {count > 0 &&
        [...groups, ...users]
          .slice(0, limit || count)
          .map((item) => (item.__typename === "Group" ? item.name : item.fullName))
          .join(count === 2 ? " & " : ", ")}
      {limit < count ? ` + ${count - limit}` : ""}
    </span>
  )
}

export { Assignees }
