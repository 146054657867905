import { useQuery, gql, useLazyQuery } from "@apollo/client"
import { JOB_FIELDS } from "./fragments/jobFieldsFragment"

const CALENDAR_ENTRY_FIELDS = `
    id: unique
    itemId: id    
    title
    startDate: start
    endDate: end
    allDay
    inFuture
    type
`

const CALENDAR_QUERY = gql`
  query Calendar($location: ID!, $start: ScheduleDateInput!, $end: ScheduleDateInput!, $groups: [ID!], $schedules: [ScheduleFilter!]) {
    calendar(location: $location, start: $start, end: $end, groups: $groups, schedules: $schedules) {
      ${CALENDAR_ENTRY_FIELDS}
      item {
        ... on Job {
          ...JobFields
        }
      }
    }
  }
  ${JOB_FIELDS}  
`

const useQueryCalendar = (options) => useQuery(CALENDAR_QUERY, options)

const useLazyQueryCalendar = (options) =>
  useLazyQuery(CALENDAR_QUERY, {
    ...options,
    fetchPolicy: "cache-and-network",
  })

export { useQueryCalendar, useLazyQueryCalendar }
