import { gql } from "@apollo/client"
import { PROCESS_SCHEDULE_FIELDS } from "./processScheduleFieldsFragment"
import { RESPONSE_SET_FIELDS } from "./responseSetFragment"
import { TAG_FIELDS } from "../../tags/useQueryTags"

const PROCESS_FIELDS = gql`
  fragment ProcessFields on Process {
    id
    type
    name
    tags {
      ...TagFields
    }
    description
    hasScoring
    steps {
      ... on CommonProcessStep {
        id
        name
        description
        responseType
        responseMandatory
        uploads {
          id
          key
          fileName
          fileGroup
          fileSize
        }
        hasScoring
        hasExclusions
        exclusions {
          id
        }
        format {
          unit
          decimals
        }
        logic {
          range
          lowerValue
          upperValue
          outcomes {
            type
          }
        }
        selection {
          selectFrom
          allowMultiple
          customItems
        }
      }
      ... on NumberProcessStep {
        id
        name
        description
        responseType
        responseMandatory
        uploads {
          id
          key
          fileName
          fileGroup
          fileSize
        }
        hasScoring
        hasExclusions
        exclusions {
          id
        }
        format {
          unit
          decimals
        }
        logic {
          range
          lowerValue
          upperValue
          outcomes {
            type
          }
        }
        canEnterManually
        canUseDevice
      }
      ... on MultipleChoiceProcessStep {
        id
        name
        description
        responseType
        responseMandatory
        responseSet {
          ...ResponseSetFields
        }
        scoring {
          response
          score
          noScore
          failure
        }
        canFail
        uploads {
          id
          key
          fileName
          fileGroup
          fileSize
        }
        hasScoring
        hasExclusions
        exclusions {
          id
        }
      }
      ... on ProcessProcessStep {
        id
        name
        description
        responseType
        responseMandatory
        process {
          id
          name
          stepCount
          createdAt
          updatedAt
        }
        canFail
        hasScoring
        hasExclusions
        exclusions {
          id
        }
      }
    }
    stepCount
    schedules {
      ...ProcessScheduleFields
    }
    sets
    categories {
      id
      name
    }
    users {
      id
      fullName
      firstName
      lastName
      avatar {
        key
      }
    }
    groups {
      id
      name
    }
    author {
      id
      fullName
      firstName
      lastName
      avatar {
        key
      }
    }
    inactive
    locations {
      id
      name
    }
    createdAt
    updatedAt
  }
  ${TAG_FIELDS}
  ${RESPONSE_SET_FIELDS}
  ${PROCESS_SCHEDULE_FIELDS}
`

export { PROCESS_FIELDS }
