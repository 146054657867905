import { Button } from "@material-ui/core"
import React from "react"
import { NavLink } from "react-router-dom"
import { Trans } from "@lingui/macro"
import { useQueryUserTrainings } from "../../data"
import { useAuth } from "../../services"
import { toId } from "../../utils"
import { RowBox } from "../Boxes"
import { Icon } from "../Icon"

const DesktopTrainingButton = () => {
  const {
    principal: { userID: user },
    hasFeature,
  } = useAuth()
  const linear = !hasFeature("training_nonlinear")
  const { data } = useQueryUserTrainings({ variables: { user } })

  const uncompletedUserTraining = data?.userTrainings.list.find((userTraining) => !userTraining.status.completedAt)
  if (!uncompletedUserTraining) {
    return null
  }

  const to = linear ? `/knowledge/training/${toId(uncompletedUserTraining)}` : "/knowledge/training"

  return (
    <Button component={NavLink} variant="contained" to={to}>
      <RowBox>
        <Icon name="training" style={{ marginRight: 8 }} />
        <Trans>Your Training</Trans>
      </RowBox>
    </Button>
  )
}

export { DesktopTrainingButton }
