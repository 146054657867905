import React from "react"
import { Backdrop, makeStyles, Popover } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  popoverRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}))

const CenteredPopover = ({ open, backdrop = true, children, ...rest }) => {
  const classes = useStyles()

  return (
    <Backdrop open={backdrop && open} className={classes.backdrop}>
      <Popover
        open={open}
        anchorReference="none"
        classes={{
          root: classes.popoverRoot,
        }}
        {...rest}
      >
        {children}
      </Popover>
    </Backdrop>
  )
}

export default CenteredPopover
