import { Button, Tooltip } from "@material-ui/core"
import React from "react"
import { Trans } from "@lingui/macro"
import { ActionCard } from "."
import { ColumnBox, RowBox } from "../Boxes"
import { Icon } from "../Icon"

const IntegrationProviderActionCard = ({ integrationProvider, onEnable, detail, ...rest }) => {
  const { id, name, shortDescription } = integrationProvider

  const handleEnable = () => {
    if (onEnable) onEnable(integrationProvider)
  }

  return (
    <ActionCard
      key={id}
      title={name}
      detail={shortDescription}
      cursor={null}
      stub={
        <ColumnBox mr={1} justifyContent="flex-start">
          <Icon name="integration" />
        </ColumnBox>
      }
      rightChildren={
        <RowBox alignItems="center">
          <Tooltip title="Enable this integration in your account">
            <Button variant="contained" color="primary" onClick={handleEnable}>
              <Trans>View</Trans>
            </Button>
          </Tooltip>
        </RowBox>
      }
      {...rest}
    />
  )
}

export { IntegrationProviderActionCard }
