import React from "react"
import { Button, makeStyles } from "@material-ui/core"
import { ColumnBox, PaperBox, RowBox } from "../Boxes"

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "left",
    height: "100%",
    backgroundColor: ({ active, disabled }) =>
      active ? theme.palette.primary[disabled ? "disabled" : "main"] : "inherit",
    lineHeight: "inherit",
    "&&:hover": {
      backgroundColor: ({ active }) => (active ? theme.palette.primary.main : theme.palette.shaded.main),
    },
    "& .MuiButton-root": {
      height: "100%",
    },
    "& .MuiButton-label": {
      height: "100%",
    },
  },
  label: {
    display: "flex",
    alignSelf: "flex-start",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    justifySelf: "flex-start",
  },
  content: {
    width: "100%",
    height: "100%",
  },
  title: {
    fontSize: 16,
    fontWeight: "normal",
    alignSelf: "flex-start",
    alignItems: "flex-start",
    marginBottom: theme.spacing(0.5),
    color: ({ active }) => theme.palette.text[active ? "white" : "primary"],
  },
  description: {
    fontSize: 13,
    lineHeight: "16px",
    fontWeight: "normal",
    alignSelf: "flex-start",
    alignItems: "flex-start",
    textAlign: "left",
    color: ({ active }) => theme.palette.text[active ? "white" : "secondary"],
  },
}))

const TileButton = ({ title, description, active, onClick, cy = "TileButton", disabled = false, ...rest }) => {
  const classes = useStyles({ active, disabled })

  return (
    <PaperBox
      component={Button}
      className={classes.root}
      color="primary"
      onClick={onClick}
      data-cy={cy}
      fullWidth
      disabled={disabled}
      {...rest}
    >
      <ColumnBox
        className={classes.content}
        px={1}
        pt={1.25}
        pb={1.75}
        justifySelf="flex-start"
        alignItems="flex-start"
      >
        <RowBox className={classes.title}>{title}</RowBox>
        <RowBox className={classes.description}>{description}</RowBox>
      </ColumnBox>
    </PaperBox>
  )
}

export { TileButton }
