import { Box, Button, makeStyles, TextField, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import { useMemo, useState } from "react"
import moment from "moment-timezone"
import sortArray from "sort-array"
import pluralize from "pluralize"
import { NavLink } from "react-router-dom"
import { Trans, t } from "@lingui/macro"
import {
  Caption,
  CreatorActions,
  CreatorMaster,
  RowBox,
  ColumnBox,
  PaperBox,
  ModuleHeading,
  FormatDateTimeCompact,
  FormatTime,
  FieldSectionHeading,
  LinearProgress,
  LoadingSpinner,
  IntervalRenderer,
} from ".."
import { jobSort } from "../JobList"
import { JOBS_SET, useMutationEndTimesheet, useQueryJobs, useQueryTimesheet } from "../../data"
import { useAuth } from "../../services"
import { getNavigateToLink, momentToScheduleDate, toId } from "../../utils"

const useStyles = makeStyles((theme) => ({
  detail: {
    backgroundColor: theme.palette.background.shaded,
    borderRadius: "8px",
    padding: "20px 24px",
  },
  detailName: {
    fontSize: 26,
    lineHeight: "36px",
    marginBottom: theme.spacing(2),
  },
  job: {
    color: theme.palette.text.primary,
    fontSize: 12,
  },
  jobTitle: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 500,
  },
  jobProgressBar: {
    width: 150,
  },
}))

const sortOption = jobSort.options.find((option) => option.id === "jobStatus")

const defaultJobPageSize = 4

const EndShiftDialog = ({ open, onEnded, onClose }) => {
  const classes = useStyles()
  const { location } = useAuth()
  const theme = useTheme()
  const closeForJob = useMediaQuery(theme.breakpoints.down("md"))
  const { data: jobsData, loading: jobsLoading } = useQueryJobs({
    variables: {
      filter: {
        location: toId(location),
        date: momentToScheduleDate(moment.tz(moment(), location.timeZone).startOf("day")),
        set: JOBS_SET.SELF,
      },
    },
  })
  const { data: timesheetData, loading: timesheetLoading } = useQueryTimesheet({
    variables: { location: toId(location) },
    pollInterval: 1000 * 20,
  })
  const [end, { loading: endLoading }] = useMutationEndTimesheet()
  const [comment, setComment] = useState("")
  const [jobTotal, setJobTotal] = useState(0)
  const [jobLimit, setJobLimit] = useState(defaultJobPageSize)

  const uncompletedJobs = useMemo(() => {
    if (!jobsData) {
      return []
    }

    const result = sortArray(
      jobsData.jobs.list.filter((job) => !job.status?.completed),
      { ...jobSort.common, ...sortOption },
    ).map((job) => ({
      ...job,
      overdue: job.dueAt && moment(job.dueAt).isBefore(moment()),
      uncompletedSteps: job.status?.steps?.filter((step) => !step.completedAt).length || 0,
    }))

    if (result.length !== jobTotal) {
      setJobTotal(result.length)
    }

    return result.slice(0, jobLimit)
  }, [jobsData, jobTotal, jobLimit])

  const calculateShiftDuration = () => {
    if (!timesheetData) {
      return null
    }
    const hours = moment().diff(moment(timesheetData.timesheet.startedAt), "minutes") / 60
    return {
      hours: Math.floor(hours),
      minutes: Math.floor((hours % 1) * 60),
    }
  }

  const handleMoreJobs = () => {
    setJobLimit(Math.min(jobLimit + defaultJobPageSize, jobsData?.jobs.count || 0))
  }

  const handleJobClick = (event) => {
    if (closeForJob) {
      onClose && onClose(event)
    }
  }

  const handleSubmit = async () => {
    await end({ variables: { comment } })
    onEnded && onEnded()
  }
  const handleCommentChange = (event) => {
    setComment(event.target.value)
  }
  const handleClose = (event) => {
    onClose && onClose(event)
  }

  const hasMoreJobs = jobTotal > jobLimit

  const timesheet = timesheetData?.timesheet
  const shift = timesheetData?.shift

  const form = (
    <>
      <Box className={classes.detail} mb={2}>
        <IntervalRenderer delay={1000}>
          {() => {
            const shiftDuration = calculateShiftDuration()
            if (!shiftDuration) {
              return null
            }

            return (
              <>
                <ModuleHeading id="process-title" className={classes.detailName} noMargin>
                  {pluralize("hr", shiftDuration.hours, true)}, {pluralize("minute", shiftDuration.minutes, true)}
                </ModuleHeading>
                <Box>
                  {location.name} | {timesheet.group?.name || shift?.otherGroupName || "Unknown area"}
                </Box>
                <FormatDateTimeCompact value={moment(timesheet.startedAt)} /> - <FormatTime value={moment()} />
              </>
            )
          }}
        </IntervalRenderer>

        {timesheetLoading && (
          <Box display="flex" justifyContent="center">
            <LoadingSpinner size={60} />
          </Box>
        )}
      </Box>

      {!jobsLoading && Boolean(uncompletedJobs.length) && (
        <Box mb={2}>
          <FieldSectionHeading mb={0.25}>
            <Trans>There's {jobTotal} uncompleted jobs</Trans>
          </FieldSectionHeading>
          <Caption>
            <Trans>Please review and update status for today's uncompleted jobs before ending your shift.</Trans>
          </Caption>
          {uncompletedJobs.map((job, index) => (
            <Box key={toId(job)} mt={index > 0 ? 1 : 0}>
              <PaperBox
                component={NavLink}
                to={getNavigateToLink(job)}
                completed={false}
                overdue={job.overdue}
                onClick={handleJobClick}
              >
                <RowBox p={2} alignItems="center">
                  <ColumnBox>
                    <Typography variant="h2" className={classes.jobTitle}>
                      {job.displayName}
                    </Typography>
                    <Caption mb={0}>
                      {pluralize("step", job.uncompletedSteps, true)}{" "}
                      {pluralize("remain", job.uncompletedSteps > 1 ? 1 : 2)} uncompleted
                    </Caption>
                  </ColumnBox>
                  <Box ml="auto" className={classes.jobProgressBar}>
                    <LinearProgress value={job.percentComplete} showValue overdue={job.overdue} />
                  </Box>
                </RowBox>
              </PaperBox>
            </Box>
          ))}
          {hasMoreJobs && (
            <Box display="flex" justifyContent="center" mt={2}>
              <Button variant="outlined" onClick={() => handleMoreJobs()}>
                <Trans>Show More ({jobTotal - jobLimit})</Trans>
              </Button>
            </Box>
          )}
        </Box>
      )}
      {jobsLoading && (
        <Box display="flex" justifyContent="center">
          <LoadingSpinner size={60} />
        </Box>
      )}

      <Box mb={2}>
        <FieldSectionHeading>
          <Trans>Any shift comments?</Trans>
        </FieldSectionHeading>
        <TextField
          variant="outlined"
          value={comment}
          onChange={handleCommentChange}
          fullWidth
          size="small"
          multiline
          minRows={4}
        />
      </Box>

      <CreatorActions
        id="EndShiftDialog-CreatorActions"
        submitLabel={t`End shift`}
        submitLoading={endLoading}
        onClose={handleClose}
        onSubmit={handleSubmit}
        disableSubmit={endLoading}
      />
    </>
  )

  return <CreatorMaster id="EndShiftDialog" open={open} title={t`Shift summary`} onClose={handleClose} form={form} />
}

export { EndShiftDialog }
