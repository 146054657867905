import Base from "./Base"
import Live from "./Live"

const config = {
  ...Live,
  clientDevice: true,
  clientLinkBaseUri: "https://app.operandio.com",

  auth: Live.auth ? { ...Live.auth } : { ...Base.auth },
}

export default config
