import React, { useState } from "react"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@impelsys/material-ui-pickers"
import { useTheme } from "@material-ui/styles"
import { useMediaQuery } from "@material-ui/core"
import MomentUtils from "@date-io/moment"
import moment from "moment"
import { useDateUtils } from "../../utils"

const DatePicker = ({ label, value, onChange, clearLabel = "Today", clearable = false, smartStripTime, ...rest }) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const { hasTime } = useDateUtils()
  const [open, setOpen] = useState(false)

  const handleChange = (date) => {
    if (clearable && !date) {
      onChange(null)
      return
    }
    onChange(smartStripTime && !hasTime(value) && date ? date.startOf("day") : date || moment().startOf("day"))
  }
  const handleClick = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        label={label}
        value={value}
        open={open}
        inputVariant="outlined"
        minutesStep={5}
        InputAdornmentProps={{ position: "end", style: { marginRight: -16 } }}
        format={xs ? "DD/MMM/Y" : "Do MMMM Y"}
        onChange={handleChange}
        onClick={handleClick}
        onClose={handleClose}
        clearable={clearable}
        clearLabel={clearLabel}
        showTodayButton={false}
        fullWidth
        okLabel=""
        autoOk
        {...rest}
      />
    </MuiPickersUtilsProvider>
  )
}

export { DatePicker }
