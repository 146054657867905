import React, { Suspense } from "react"
import { LoadingSpinner } from "../LoadingSpinner"

const InnerQRButton = React.lazy(() => import("./InnerQRButton"))

const QRButton = ({ ...props }) => {
  return (
    <Suspense fallback={<LoadingSpinner size={16} />}>
      <InnerQRButton {...props} />
    </Suspense>
  )
}

export { QRButton }
