import { useMutation, gql } from "@apollo/client"

const DELETE_TRAINING_COURSE_MUTATION = gql`
  mutation DeleteTrainingCourse($id: ID!) {
    deleteTrainingCourse(id: $id)
  }
`

const useMutationDeleteTrainingCourse = () => useMutation(DELETE_TRAINING_COURSE_MUTATION)

export { useMutationDeleteTrainingCourse }
