import React, { useState } from "react"
import { makeStyles, IconButton } from "@material-ui/core"
import { Close as CloseIcon } from "@material-ui/icons"
import { LoadingSpinner } from "../LoadingSpinner"

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}))

const DialogTitleCloser = ({ onClose, cy = "DialogTitleCloser" }) => {
  const classes = useStyles()
  const [closing, setClosing] = useState(false)

  const handleClose = (event) => {
    setClosing(true)
    onClose && onClose(event)
  }

  return (
    <IconButton
      aria-label="close"
      className={classes.root}
      onClick={handleClose}
      disabled={closing}
      data-cy={`${cy}-button`}
    >
      {!closing && <CloseIcon />}
      {closing && <LoadingSpinner size={24} delay={false} />}
    </IconButton>
  )
}

export { DialogTitleCloser }
