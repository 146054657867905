import React from "react"
import { Box, useTheme } from "@material-ui/core"

const ErrorLabel = ({ message, ...rest }) => {
  const theme = useTheme()

  if (!message) return null

  return (
    <Box style={{ color: theme.palette.error.main }} {...rest}>
      {message}
    </Box>
  )
}

export { ErrorLabel }
