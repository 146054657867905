import React from "react"
import LogoImageFull from "./operandio-full.svg"

const LogoFull = ({ className, width = 220 }) => (
  <a href="https://operandio.com">
    <img src={LogoImageFull} width={width} height={width / 5} className={className} alt="Operandio" />
  </a>
)

export { LogoFull }
