import { makeStyles } from "@material-ui/core"
import React from "react"

const useStyles = makeStyles(() => ({
  noWrap: {
    whiteSpace: "nowrap",
  },
}))

const NoWrap = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.noWrap}>{children}</div>
}

export { NoWrap }
