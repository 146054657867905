import { useMutation, gql } from "@apollo/client"
import { GROUP_FIELDS } from "./fragments/groupFieldsFragment"

const UPDATE_GROUP_MUTATION = gql`
  mutation UpdateGroup($id: ID!, $input: GroupUpdateMutationInput!) {
    group(id: $id) {
      update(input: $input) {
        ...GroupFields
      }
    }
  }
  ${GROUP_FIELDS}
`

const makeUpdateGroupOptimisticResponse = ({ group, name }) => ({
  __typename: "Mutation",
  group: {
    update: {
      ...group,
      name,
    },
  },
})

const useMutationUpdateGroup = () => useMutation(UPDATE_GROUP_MUTATION)

export { useMutationUpdateGroup, makeUpdateGroupOptimisticResponse }
