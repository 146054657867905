import Axios from "axios"
import Config from "react-global-configuration"
import FileDownload from "js-file-download"
import { authStateVar } from "./authentication"

const getDownload = async (path, { contentType, fileName, onSuccess, onError }) => {
  const { httpBaseUri } = Config.get()
  const {
    principal: { token },
  } = authStateVar()

  try {
    const response = await Axios({
      url: `${httpBaseUri}${path}`,
      method: "GET",
      responseType: "blob",
      headers: {
        "Content-Type": contentType,
        Authorization: `Bearer ${token}`,
      },
    })

    const fallbackFileName = response.headers["content-disposition"]?.split("filename=")[1] || "UnknownFileDownload"

    FileDownload(response.data, fileName || fallbackFileName)

    onSuccess && onSuccess()
  } catch (error) {
    onError && onError(error)
  }
}

export { getDownload }
