import { useMutation, gql } from "@apollo/client"
import { ACCREDITATION_FIELDS } from "./useQueryAccreditations"

const CREATE_ACCREDITATION_MUTATION = gql`
  mutation CreateAccreditation($name: String!, $description: String, $icon: String!, $locations: [ID!]!, $groups: [ID!]!, $expires: Boolean, $expiringWarningMonths: Int) {
    createAccreditation(name: $name, description: $description, icon: $icon, locations: $locations, groups: $groups, expires: $expires, expiringWarningMonths: $expiringWarningMonths) {
      ${ACCREDITATION_FIELDS}
    } 
  }
`

const useMutationCreateAccreditation = () => useMutation(CREATE_ACCREDITATION_MUTATION)

export { useMutationCreateAccreditation }
