import React, { useEffect, useState } from "react"
import { Card, CardContent, useMediaQuery } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/styles"
import PostHeader, { PostHeaderSkeleton } from "./PostHeader"
import PostContent, { PostContentSkeleton } from "./PostContent"
import PostConfirm from "./PostConfirm"
import PostCommentList from "./PostCommentList"
import PostComment from "./PostComment"
import { usePostUtils } from "../../utils"
import { useAuth } from "../../services"

const useStyles = makeStyles((theme) => ({
  post: {
    marginBottom: theme.spacing(3),
    padding: 0,
  },
  postXs: {
    borderRadius: 0,
  },
  postScheduled: {
    border: `1px solid ${theme.palette.scheduled.main}`,
  },
  content: {
    paddingTop: 0,
    "&:last-child": {
      paddingBottom: theme.spacing(1),
    },
  },
  postContentScheduled: {
    opacity: 0.3,
  },
  section: {
    borderBottom: "1px solid #EFEFEF",
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    "&:last-child": {
      borderBottom: "none",
    },
  },
  sectionHeader: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  sectionScheduled: {
    borderColor: theme.palette.scheduled.main,
  },
  sectionHighlight: {
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: "#fafcff",
    "&:last-child": {
      paddingBottom: theme.spacing(1),
      marginBottom: theme.spacing(-1),
    },
  },
  title: {
    fontSize: 20,
    fontWeight: "600",
  },
}))

const Post = ({ post, onEdit, onDelete, onCommentCreated }) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const classes = useStyles({ xs })
  const { hasPermission } = useAuth()
  const { isScheduledInFuture, isCurrentUserAuthorOf, hasCurrentUserReceivedPostNotification } = usePostUtils()

  const [userNotNotified, setUserNotNotified] = useState(true)
  const [isAuthor] = useState(isCurrentUserAuthorOf(post))

  useEffect(() => {
    setUserNotNotified(!hasCurrentUserReceivedPostNotification(post))
  }, [hasCurrentUserReceivedPostNotification, post])

  const isScheduled = isScheduledInFuture(post)

  const showConfirm = !userNotNotified || isAuthor || hasPermission(["post_update_all", "post_update_locations"])

  return (
    <Card className={`${classes.post} ${isScheduled && classes.postScheduled}`} elevation={3}>
      <CardContent className={classes.content}>
        <div className={`${classes.section} ${classes.sectionHeader} ${isScheduled && classes.sectionScheduled}`}>
          <PostHeader {...{ post, onEdit, onDelete }} />
        </div>
        <div className={`${classes.section} ${isScheduled && classes.postContentScheduled}`}>
          <PostContent post={post} />
        </div>
        {post.hasConfirm && showConfirm && (
          <div
            className={`${classes.section} ${classes.sectionHighlight} ${isScheduled && classes.postContentScheduled}`}
          >
            <PostConfirm post={post} />
          </div>
        )}
        {!isScheduled && post.hasComments && <PostCommentList post={post} />}
        {!isScheduled && post.hasComments && (
          <div className={classes.section}>
            <PostComment post={post} onCommentCreated={onCommentCreated} />
          </div>
        )}
      </CardContent>
    </Card>
  )
}

const PostSkeleton = () => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const classes = useStyles({ xs })

  return (
    <Card className={classes.post} elevation={3}>
      <CardContent className={classes.content}>
        <div className={`${classes.section} ${classes.sectionHeader}`}>
          <PostHeaderSkeleton />
        </div>
        <div className={`${classes.section}`}>
          <PostContentSkeleton />
        </div>
      </CardContent>
    </Card>
  )
}

export default Post
export { PostSkeleton }
