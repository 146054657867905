import { useMutation, gql } from "@apollo/client"
import { LABEL_TEMPLATE_FIELDS } from "./useQueryLabelTemplates"

const UPDATELABEL_TEMPLATE_MUTATION = gql`
  mutation UpdateLabelTemplate($id: ID!, $input: LabelTemplateInput!) {
    labelTemplate(id: $id) {
      update(input: $input) {
        ${LABEL_TEMPLATE_FIELDS}
      }
    }
  }
`

const useMutationUpdateLabelTemplate = () => useMutation(UPDATELABEL_TEMPLATE_MUTATION)

export { useMutationUpdateLabelTemplate }
