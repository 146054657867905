import React from "react"
import { Avatar as MuiAvatar, Icon } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
}))

const KnowledgeCategoryIcon = ({ name, icon }) => {
  const classes = useStyles()

  return (
    <MuiAvatar aria-label={name} className={classes.avatar}>
      <Icon>{icon}</Icon>
    </MuiAvatar>
  )
}

export { KnowledgeCategoryIcon }
