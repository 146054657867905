import React, { useEffect, useState } from "react"
import { Divider, ListSubheader, MenuItem } from "@material-ui/core"
import { OutlinedSelect } from "../OutlinedSelect"
import { IntegrationLocationConfiguration } from "./IntegrationLocationConfiguration"
import { IntegrationLocationGroupConfiguration } from "./IntegrationLocationGroupConfiguration"
import { IntegrationEventsConfiguration } from "./IntegrationEventsConfiguration"
import { IntegrationUserConfiguration } from "./IntegrationUserConfiguration"
import { IntegrationAttributesConfiguration } from "./IntegrationAttributesConfiguration"
import { IntegrationGroupConfiguration } from "./IntegrationGroupConfiguration"
import { IntegrationRoleConfiguration } from "./IntegrationRoleConfiguration"

const IntegrationConfiguration = ({ integration }) => {
  const [area, setArea] = useState("integration")
  const [hasRole, setHasRole] = useState(false)
  const [hasLocation, setHasLocation] = useState(false)
  const [hasLocationGroup, setHasLocationGroup] = useState(false)
  const [hasGroup, setHasGroup] = useState(false)
  const [hasUser, setHasUser] = useState(false)

  const {
    integrationProvider: { attributes },
  } = integration

  useEffect(() => {
    setHasRole(Boolean(attributes?.role.length))
    setHasLocation(Boolean(attributes?.location.length))
    setHasLocationGroup(Boolean(attributes?.locationGroup.length))
    setHasGroup(Boolean(attributes?.group.length))
    setHasUser(Boolean(attributes?.user.length))
  }, [attributes])

  const handleChange = (event) => {
    if (!event.target.value) {
      return
    }
    setArea(event.target.value)
  }

  const hasSelect = hasLocation || hasUser

  return (
    <>
      {hasSelect && (
        <OutlinedSelect label="Configure" value={area} native={false} onChange={handleChange}>
          <MenuItem value="integration">General settings</MenuItem>
          <MenuItem value="events">Events</MenuItem>
          {hasRole && <MenuItem value="role">Permission levels</MenuItem>}
          {hasLocation && <Divider />}
          {hasLocation && <ListSubheader disableSticky>Location mappings</ListSubheader>}
          {hasLocation && <MenuItem value="location">All locations</MenuItem>}
          {hasLocation && <MenuItem value="location-unmapped">Unmapped locations</MenuItem>}
          {(hasLocationGroup || hasGroup) && <Divider />}
          {(hasLocationGroup || hasGroup) && <ListSubheader disableSticky>Area of Work mappings</ListSubheader>}
          {hasGroup && <MenuItem value="group">Areas of work (global mappings)</MenuItem>}
          {hasLocationGroup && <MenuItem value="locationgroup">Areas of work (location-based mappings)</MenuItem>}
          {hasUser && <Divider />}
          {hasUser && <ListSubheader disableSticky>User mappings</ListSubheader>}
          {hasUser && <MenuItem value="user">All users</MenuItem>}
          {hasUser && <MenuItem value="user-unmapped">Unmapped users</MenuItem>}
        </OutlinedSelect>
      )}

      {area === "integration" && <IntegrationAttributesConfiguration integration={integration} />}
      {area === "events" && <IntegrationEventsConfiguration integration={integration} hasTitle={!hasSelect} />}
      {area === "location" && hasLocation && <IntegrationLocationConfiguration area={area} integration={integration} />}
      {area === "location-unmapped" && hasLocation && (
        <IntegrationLocationConfiguration area={area} integration={integration} />
      )}
      {area === "locationgroup" && hasLocationGroup && (
        <IntegrationLocationGroupConfiguration area={area} integration={integration} />
      )}
      {area === "group" && hasGroup && <IntegrationGroupConfiguration area={area} integration={integration} />}
      {area === "role" && hasGroup && <IntegrationRoleConfiguration area={area} integration={integration} />}
      {area === "user" && hasUser && <IntegrationUserConfiguration area={area} integration={integration} />}
      {area === "user-unmapped" && hasUser && <IntegrationUserConfiguration area={area} integration={integration} />}
    </>
  )
}

export { IntegrationConfiguration }
