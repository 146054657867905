import React, { useState } from "react"
import { useTheme, Box, Tooltip, makeStyles, Typography } from "@material-ui/core"
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons"
import moment from "moment-timezone"
import pluralize from "pluralize"
import { getStepFormattedUnit, toId, useJobUtils } from "../../../utils"
import {
  PROCESS_STEP_RESPONSE_TYPE,
  PROCESS_STEP_RESPONSE_TYPES_USING_SELECTIONS,
  RESPONSE_SET_DISPLAY_AS,
} from "../../../data"
import { FlexBox, FormatTime, Icon, ImagesViewer } from "../.."
import { NestedProcessStatusChip } from "../../Chips/NestedProcessStatusChip"
import { titleCase } from "../../../utils/string"
import { UserTooltip } from "./Tooltips"

const useStyles = makeStyles((theme) => ({
  valueResponse: {
    whiteSpace: "nowrap",
    maxWidth: 120,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  templatedValueResponse: {
    maxWidth: 120,
    overflow: "hidden",
    textTransform: "uppercase",
  },
  photosResponse: {
    width: 120,
  },
  signatureResponse: {
    width: 120,
  },
  signature: {
    width: 120,
  },
  valueEmpty: {
    fontStyle: "italic",
    fontWeight: "normal",
    color: theme.palette.text.faint,
  },
  notes: {
    color: theme.palette.text.secondary,
    fontSize: 11,
    fontStyle: "italic",
  },
}))

const StepTableCellContent = ({ jobStep, boxProps = {} }) => {
  const classes = useStyles()
  const theme = useTheme()
  const [viewing, setViewing] = useState(false)
  const { getStepStatus, isStepValueResponseType, isStepValueNonResponseType, getSelectionResponseText } = useJobUtils()

  const handleOpen = () => {
    setViewing(true)
  }

  const handleClose = () => {
    setViewing(false)
  }

  const {
    id,
    step: { responseType: stepResponseType, response, responseUploads, notes, format },
  } = jobStep
  const responseType = stepResponseType // || processStepResponseType
  const status = getStepStatus(jobStep)
  const userStatus = ["complete", "overdue", "skipped"].includes(status)
  const valueResponseType = isStepValueResponseType(responseType)
  const nonResponseType = isStepValueNonResponseType(responseType)
  const overdue = status === "overdue"

  const displayValueStatus = ["complete", "overdue"].includes(status)

  const renderMultipleChoiceResponse = ({ step }) => {
    const responseSet = step?.responseSet
    const isIcon = responseSet?.displayAs === RESPONSE_SET_DISPLAY_AS.ICON
    if (isIcon) {
      const responseDetail = responseSet?.responses?.find((item) => {
        return Number(item.value) === Number(response)
      })
      const responseText = responseDetail?.text
      const iconName = responseText?.replace("_", "-")

      return (
        <Box>
          <Icon name={iconName} />
          <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
            {titleCase(responseText).replace("_", " ")}
          </Typography>
        </Box>
      )
    }

    return (
      <Box
        className={
          responseType === PROCESS_STEP_RESPONSE_TYPE.MULTIPLE_CHOICE
            ? classes.templatedValueResponse
            : classes.valueResponse
        }
      >
        {response}
        {responseType === PROCESS_STEP_RESPONSE_TYPE.NUMBER_RATING_5 && <>/5</>}
        {responseType === PROCESS_STEP_RESPONSE_TYPE.NUMBER_RATING_10 && <>/10</>}
      </Box>
    )
  }

  return (
    <Box key={id} align="center" style={{ cursor: "grab", width: 150, minWidth: 150 }} {...boxProps}>
      {userStatus && (
        <UserTooltip jobStep={jobStep} status={status} anchor={viewing}>
          <>
            {displayValueStatus && responseType === PROCESS_STEP_RESPONSE_TYPE.CHECKBOX && (
              <Box>
                <CheckBox style={{ fill: overdue ? theme.palette.error.light : theme.palette.success.main }} />
              </Box>
            )}
            {status === "skipped" && !nonResponseType && (
              <Box>
                <Icon name="skip" style={{ fill: theme.palette.grey[400] }} />
              </Box>
            )}
            {displayValueStatus && responseType === PROCESS_STEP_RESPONSE_TYPE.PHOTOS && (
              <FlexBox className={classes.photosResponse} justifyContent="center">
                <ImagesViewer
                  uploads={responseUploads}
                  onOpen={handleOpen}
                  onClose={handleClose}
                  max={3}
                  width={50}
                  height={50}
                />
              </FlexBox>
            )}
            {displayValueStatus && responseType === PROCESS_STEP_RESPONSE_TYPE.FILES && (
              <Box>{pluralize("file", responseUploads.length, true)}</Box>
            )}
            {displayValueStatus && responseType === PROCESS_STEP_RESPONSE_TYPE.SIGNATURE && (
              <FlexBox className={classes.signatureResponse} justifyContent="center">
                <img src={response} alt="signature" className={classes.signature} />
              </FlexBox>
            )}
            {displayValueStatus && responseType === PROCESS_STEP_RESPONSE_TYPE.DATE_TIME && (
              <Box className={classes.valueResponse}>
                {moment(response).format("D MMM YYYY")}
                <br />
                <FormatTime value={moment(response)} />
              </Box>
            )}

            {displayValueStatus && responseType === PROCESS_STEP_RESPONSE_TYPE.NUMBER && (
              <Box className={classes.valueResponse}>
                {format?.unit ? getStepFormattedUnit(response, format) : response}
              </Box>
            )}

            {displayValueStatus &&
              valueResponseType &&
              ![PROCESS_STEP_RESPONSE_TYPE.DATE_TIME, PROCESS_STEP_RESPONSE_TYPE.NUMBER].includes(responseType) && (
                <>{renderMultipleChoiceResponse({ step: jobStep?.step, response, responseType })}</>
              )}

            {displayValueStatus && PROCESS_STEP_RESPONSE_TYPES_USING_SELECTIONS.includes(responseType) && (
              <Box>{getSelectionResponseText(jobStep?.step)}</Box>
            )}
          </>
        </UserTooltip>
      )}
      {!userStatus && (
        <Box title="Uncompleted">
          {!valueResponseType &&
            !nonResponseType &&
            ![
              PROCESS_STEP_RESPONSE_TYPE.PHOTOS,
              PROCESS_STEP_RESPONSE_TYPE.FILES,
              PROCESS_STEP_RESPONSE_TYPE.PROCESS,
              PROCESS_STEP_RESPONSE_TYPE.SIGNATURE,
            ].includes(responseType) && <CheckBoxOutlineBlank style={{ fill: theme.palette.grey[400] }} />}
          {(valueResponseType ||
            [
              PROCESS_STEP_RESPONSE_TYPE.PHOTOS,
              PROCESS_STEP_RESPONSE_TYPE.FILES,
              PROCESS_STEP_RESPONSE_TYPE.SIGNATURE,
            ].includes(responseType)) && <Box className={classes.valueEmpty}>&lt;empty&gt;</Box>}
          {responseType === PROCESS_STEP_RESPONSE_TYPE.PROCESS && jobStep?.step?.job && (
            <NestedProcessStatusChip job={jobStep.step.job} />
          )}
        </Box>
      )}
      {notes && (
        <Box className={classes.notes}>
          {notes.map((note) => (
            <Tooltip key={toId(note)} title={`${note.author?.fullName}`} placement="right">
              <Box>&ldquo;{note.text}&rdquo;</Box>
            </Tooltip>
          ))}
        </Box>
      )}
    </Box>
  )
}

export { StepTableCellContent }
