import { useQuery, useLazyQuery, gql } from "@apollo/client"

const REPORT_GROUP_OVERVIEW_FIELDS = gql`
  fragment ReportGroupOverviewFields on ReportGroupOverview {
    id
    name
    jobs {
      completed
      overdue
      uncompleted
      skipped
      total
    }
  }
`

const REPORTGROUPSOVERVIEW_QUERY = gql`
  query ReportGroupsOverview($locations: [ID!]!, $groups: [ID!], $gt: ScheduleDateInput!, $lt: ScheduleDateInput) {
    reportGroupsOverview(locations: $locations, groups: $groups, gt: $gt, lt: $lt) {
      ...ReportGroupOverviewFields
    }
  }
  ${REPORT_GROUP_OVERVIEW_FIELDS}
`

const useQueryReportGroupsOverview = (options) =>
  useQuery(REPORTGROUPSOVERVIEW_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

const useLazyQueryReportGroupsOverview = (options) =>
  useLazyQuery(REPORTGROUPSOVERVIEW_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

export { useQueryReportGroupsOverview, useLazyQueryReportGroupsOverview }
