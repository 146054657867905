import React, { useEffect, useState } from "react"
import { Button, Grid, Dialog, DialogContent, Box, DialogTitle, useTheme, useMediaQuery } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { Trans } from "@lingui/macro"
import { useProbes } from "../../utils/useProbes"
import { PROCESS_STEP_FORMAT_UNIT } from "../../data/enums"
import { getStepFormattedUnit, useMountEffect } from "../../utils"

const { TEMPERATURE_CELSIUS } = PROCESS_STEP_FORMAT_UNIT

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minWidth: 326,
  },
  readout: {
    color: ({ display: { colour } }) => colour || theme.palette.primary.main,
    textAlign: "center",
    padding: ({ xs }) => (xs ? theme.spacing(2) : theme.spacing(4, 3, 4, 3)),
    backgroundColor: ({ display: { backgroundColor } }) => backgroundColor || theme.palette.primary.shaded.background,
    borderRadius: 8,
    border: ({ display: { colour } }) => `2px solid ${colour || theme.palette.primary.main}`,
  },
  value: {
    fontSize: 56,
    fontWeight: 700,
    lineHeight: "100%",
  },
  message: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "25px",
  },
  jumboButtons: {
    width: "100%",
    height: "56px",
    display: "flex",
    flexShrink: 0,
  },
}))

const ReadProbeDialog = ({ onRecord, onCancel, format, instructions, onDetermineDisplay }) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))

  const [display, setDisplay] = useState({
    message: "Ready",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.shaded,
  })
  const classes = useStyles({ display, xs })
  const { startReadingValue, stopReadingValue, value, handleConversionCallback } = useProbes({ onRecord, format })
  const [connecting, setConnecting] = useState(false)

  useMountEffect(() => {
    setConnecting(true)
    startReadingValue()
  })

  useEffect(() => {
    if (value && onDetermineDisplay) {
      const convertedValue = handleConversionCallback(value.value)
      setDisplay({ ...onDetermineDisplay(convertedValue) })
    }
  }, [onDetermineDisplay, value, handleConversionCallback])

  useEffect(() => {
    if (value && connecting) {
      setConnecting(false)
    }
  }, [connecting, value])

  const handleRecord = (event) => {
    stopReadingValue()
    const convertedValue = handleConversionCallback(value.value)
    onRecord && onRecord(event, value, convertedValue)
  }

  const handleCancel = () => {
    stopReadingValue()
    onCancel && onCancel()
  }

  return (
    <Dialog open classes={{ paper: classes.dialogPaper }}>
      <DialogTitle>Record temperature</DialogTitle>
      <DialogContent>
        <Box className={classes.readout} mb={2}>
          <Box className={classes.value}>
            {value
              ? getStepFormattedUnit(handleConversionCallback(value.value), {
                  unit: format || TEMPERATURE_CELSIUS,
                })
              : "--"}
          </Box>
          <Box className={classes.message}>{connecting ? "Wait..." : display.message}</Box>

          {value && format !== null && format !== TEMPERATURE_CELSIUS && (
            <Box mt={1}>Converted from {getStepFormattedUnit(value.value, { unit: TEMPERATURE_CELSIUS })}</Box>
          )}
        </Box>

        {!!instructions && <Box mb={2}>{instructions}</Box>}
        <Box mb={2}>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleRecord}
                className={classes.jumboButtons}
                disabled={connecting || !value}
              >
                Record temperature
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" size="large" onClick={handleCancel} className={classes.jumboButtons}>
                <Trans>Cancel</Trans>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export { ReadProbeDialog }
