import { makeVar } from "@apollo/client"
import { toId, useMountAwareReactiveVar } from "../../utils"
import { PROCESS_STEP_RESPONSE_TYPE } from "../enums"

const jobSectionCollapseStateVar = makeVar({})

const toggleSectionCollapsed = (job, sectionStep) => {
  const key = getKey(job, sectionStep)

  const currentState = jobSectionCollapseStateVar()

  jobSectionCollapseStateVar({
    ...currentState,
    [key]: !currentState[key],
  })
}

const getKey = (job, sectionStep) => {
  return `${toId(job)}_${toId(sectionStep)}`
}

const useJobCollapsed = (job) => {
  const state = useMountAwareReactiveVar(jobSectionCollapseStateVar)

  const isStepCollapsed = (step, parent) => {
    const key = getKey(parent ?? job, step)
    return state[key]
  }

  return [
    state,
    {
      isStepCollapsed,
    },
  ]
}

const useJobSectionCollapsed = (job, sectionStep) => {
  const collapseKey = getKey(job, sectionStep)
  const state = useMountAwareReactiveVar(jobSectionCollapseStateVar)

  if (!job || !sectionStep) {
    return [
      false,
      () => {
        throw new Error("Job and sectionStep must be defined to toggle section collapse")
      },
    ]
  }

  return [state[collapseKey], () => toggleSectionCollapsed(job, sectionStep)]
}

const useJobSectionCollapseAll = (job) => {
  const state = useMountAwareReactiveVar(jobSectionCollapseStateVar)

  if (!job) {
    return [
      false,
      () => {
        throw new Error("Job must be defined to toggle all collapse/expand of all sections")
      },
    ]
  }

  const steps = job.status.steps
    .filter((step) => step.responseType === PROCESS_STEP_RESPONSE_TYPE.SECTION)
    .map((step) => step.step)
  const collapseKeys = steps.map((step) => getKey(job, step))

  const toggleAllSectionsCollapsed = (collapse) => {
    steps.forEach((step) => {
      const key = getKey(job, step)
      const shouldToggle = collapse ? !state[key] : state[key]
      if (shouldToggle) {
        toggleSectionCollapsed(job, step)
      }
    })
  }

  const allCollapsed = collapseKeys.every((key) => state[key])

  return [
    allCollapsed,
    () => toggleAllSectionsCollapsed(true), // Call to collapse
    () => toggleAllSectionsCollapsed(false), // Call to expand
  ]
}

export {
  jobSectionCollapseStateVar,
  toggleSectionCollapsed,
  getKey,
  useJobCollapsed,
  useJobSectionCollapsed,
  useJobSectionCollapseAll,
}
