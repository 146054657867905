import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Chip, Box, CircularProgress } from "@material-ui/core"
import { Trans } from "@lingui/macro"
import { Icon } from ".."
import { PROCESS_TYPE, PROCESS_STEP_RESPONSE_TYPE } from "../../data"

const useStyles = makeStyles((theme) => ({
  label: {
    paddingLeft: ({ todo }) => (todo ? theme.spacing(1.5) : theme.spacing(0.5)),
    paddingRight: ({ todo }) => (todo ? theme.spacing(1.5) : theme.spacing(1.5)),
  },
  statusIconFrame: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 24,
    height: 24,
  },
  statusIcon: {
    width: 18,
    height: 18,
  },
  inProgressRoot: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
  },
  awaitingSubmitRoot: {
    color: "white",
    backgroundColor: theme.palette.scheduled.main,
  },
  submittedRoot: {
    color: "white",
    backgroundColor: theme.palette.success.main,
  },
  overdueRoot: {
    color: "white",
    backgroundColor: theme.palette.error.main,
  },
  labelFrame: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  progressFrame: {
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  circularProgressTop: {
    marginRight: theme.spacing(1),
    color: "#FFFFFF",
    position: "absolute",
  },
  circularProgressBottom: {
    marginRight: theme.spacing(1),
    color: "#FFFFFF",
    opacity: 0.4,
  },
  circularProgressCompleted: {
    marginRight: theme.spacing(1),
    color: "#FFFFFF",
    opacity: 1,
  },
  circularProgressPercent: {
    fontSize: 11,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    width: 24,
    height: 24,
  },
}))

const StatusProgress = ({ job }) => {
  const classes = useStyles()

  return (
    <Box className={classes.labelFrame}>
      <Box className={classes.progressFrame}>
        <CircularProgress
          size={24}
          variant="determinate"
          value={job.percentComplete}
          className={classes.circularProgressTop}
        />
        <CircularProgress size={24} variant="determinate" value={100} className={classes.circularProgressBottom} />
        <Box className={classes.circularProgressPercent}>{job.percentComplete}</Box>
      </Box>
      <Trans>In progress</Trans>
    </Box>
  )
}

const StatusAwaitingSubmission = () => {
  const classes = useStyles()

  return (
    <Box className={classes.labelFrame}>
      <Box className={classes.progressFrame}>
        <CircularProgress size={24} variant="determinate" value={100} className={classes.circularProgressCompleted} />
        <Box className={classes.statusIconFrame}>
          <Icon className={classes.statusIcon} name="up" />
        </Box>
      </Box>
      <Trans>Awaiting submission</Trans>
    </Box>
  )
}

const StatusSubmitted = ({ job }) => {
  const classes = useStyles()

  return (
    <Box className={classes.labelFrame}>
      <Box className={classes.progressFrame}>
        <CircularProgress size={24} variant="determinate" value={100} className={classes.circularProgressCompleted} />
        <Box className={classes.statusIconFrame}>
          <Icon className={classes.statusIcon} name="checkbox" />
        </Box>
      </Box>
      {job.type === PROCESS_TYPE.AUDIT ? <Trans>Submitted</Trans> : <Trans>Completed</Trans>}
    </Box>
  )
}

const StatusOverdue = ({ job }) => {
  const classes = useStyles()

  return (
    <Box className={classes.labelFrame}>
      <Box className={classes.progressFrame}>
        {job.percentComplete !== 0 && (
          <>
            <CircularProgress
              size={24}
              variant="determinate"
              value={job.percentComplete}
              className={classes.circularProgressTop}
            />
            <CircularProgress size={24} variant="determinate" value={100} className={classes.circularProgressBottom} />
            <Box className={classes.circularProgressPercent}>{job.percentComplete}</Box>
          </>
        )}
        {job.percentComplete === 0 && (
          <>
            <CircularProgress
              size={24}
              variant="determinate"
              value={100}
              className={classes.circularProgressCompleted}
            />
            <Box className={classes.statusIconFrame}>
              <Icon className={classes.statusIcon} name="overdue" />
            </Box>
          </>
        )}
      </Box>
      {job.percentComplete === 0 ? <Trans>Overdue</Trans> : <Trans>In progress</Trans>}
    </Box>
  )
}

const AuditStatusChip = ({ job, overdue, ...rest }) => {
  const hasCompletions = () => {
    return job.status.steps.some((step) => step.completedAt !== null)
  }

  const hasNestedCompletions = () => {
    return job.status.steps
      .filter((step) => step.responseType === PROCESS_STEP_RESPONSE_TYPE.PROCESS)
      .some((step) => step.job?.percentComplete !== 0)
  }

  const todo = !hasCompletions() && !hasNestedCompletions() && !overdue
  const classes = useStyles({ todo })

  let root = null
  let label = null

  if (job.status.submitted) {
    root = classes.submittedRoot
    label = <StatusSubmitted job={job} />
  } else if (job.status.completed && job.type === PROCESS_TYPE.AUDIT) {
    root = classes.awaitingSubmitRoot
    label = <StatusAwaitingSubmission />
  } else if (job.status.completed && job.type === PROCESS_TYPE.PROCESS) {
    root = classes.submittedRoot
    label = <StatusSubmitted job={job} />
  } else if (overdue) {
    root = classes.overdueRoot
    label = <StatusOverdue job={job} />
  } else if (hasCompletions() || hasNestedCompletions()) {
    root = classes.inProgressRoot
    label = <StatusProgress job={job} />
  } else {
    label = <Trans context="Yet to be started">To do</Trans>
  }

  return (
    <>
      <Chip size="medium" classes={{ root, label: classes.label }} label={label} {...rest} />
    </>
  )
}

export { AuditStatusChip }
