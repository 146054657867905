import React from "react"
import { SortableHandle } from "react-sortable-hoc"
import { Icon } from "../Icon"

const DragHandleActive = SortableHandle(({ icon = "drag", className }) => <Icon name={icon} className={className} />)

const DragHandle = ({ icon = "drag", expanded, className }) =>
  expanded ? <Icon name={icon} className={className} /> : <DragHandleActive icon={icon} className={className} />

export { DragHandleActive, DragHandle }
