import React from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
  makeStyles,
} from "@material-ui/core"
import { Trans } from "@lingui/macro"
import { LinkButton } from ".."

const useStyles = makeStyles((theme) => ({
  paper: {
    width: theme.dimensions.dialogs.alerts.width,
    top: "inherit",
  },
  title: {
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  description: {
    color: theme.palette.text.primary,
  },
  content: {
    padding: theme.spacing(1, 2, 0),
  },
}))

const Alert = ({
  open,
  onClose,
  title,
  description,
  hideOK,
  hideCancel,
  okLabel,
  cancelLabel,
  dialogProps = {},
  okButtonProps = {},
}) => {
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.paper }}
      {...dialogProps}
    >
      <Box pb={1}>
        <DialogTitle id="alert-dialog-title" classes={{ root: classes.title }}>
          {title}
        </DialogTitle>
        <DialogContent classes={{ root: classes.content }}>
          <DialogContentText id="alert-dialog-description" className={classes.description}>
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {hideCancel || (
            <Box mr={2}>
              <LinkButton onClick={() => onClose(false)}>{cancelLabel || <Trans>Cancel</Trans>}</LinkButton>
            </Box>
          )}
          {hideOK || (
            <Box mr={1}>
              <Button onClick={() => onClose(true)} variant="contained" color="primary" autoFocus {...okButtonProps}>
                {okLabel || <Trans>OK</Trans>}
              </Button>
            </Box>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export { Alert }
