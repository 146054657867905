import React, { useState } from "react"
import { makeStyles, Tooltip, Box, IconButton } from "@material-ui/core"
import { Icon } from "."
import { preventDefault } from "../../utils"

const useStyles = makeStyles((theme) => ({
  root: {
    width: 24,
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.grey[200],
    padding: 3,
    borderRadius: 4,
  },
  overdue: {
    color: "#fff",
    backgroundColor: theme.palette.error.main,
  },
}))

const AdornmentIcon = ({ name, tooltip, overdue, iconProps = {}, className = "" }) => {
  const classes = useStyles()
  const [open, setTooltip] = useState(false)

  const icon = (
    <Icon name={name} className={`${classes.root} ${overdue ? classes.overdue : ""} ${className}`} {...iconProps} />
  )

  if (tooltip) {
    return (
      <Box onClick={preventDefault}>
        <Tooltip disableFocusListener open={open} onClose={() => setTooltip(false)} title={tooltip}>
          <IconButton tabIndex="-1" style={{ margin: 0, padding: 0 }} onClick={() => setTooltip(true)}>
            {icon}
          </IconButton>
        </Tooltip>
      </Box>
    )
  }

  return icon
}

export { AdornmentIcon }
