import React from "react"
import { Typography, Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { RowBox } from "../Boxes"

const useStyles = makeStyles((theme) => ({
  infoSection: {
    minHeight: ({ compact }) => (compact ? 32 : 48),
    gap: theme.spacing(1),
  },
  mainSection: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "flex-start",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      gap: 0,
      flexDirection: "column",
      alignItems: "flex-start",
      textAlign: "left",
    },
  },
  infoTitle: {
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: "600",
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
  },
}))

const InfoTitle = ({ children }) => {
  const classes = useStyles()

  return (
    <Typography variant="h3" className={classes.infoTitle}>
      {children}
    </Typography>
  )
}

const InfoSection = ({ title, value, children, rightChildren, py = 1, px = 2, compact }) => {
  const classes = useStyles({ compact })
  return (
    <RowBox py={py} px={px} className={classes.infoSection} flexGrow={1} align="right">
      <RowBox flexGrow={1} className={classes.mainSection}>
        {title && <InfoTitle>{title}</InfoTitle>}
        {children}
        {value && <Box>{value}</Box>}
      </RowBox>
      {rightChildren && (
        <Box ml="auto" display="flex" alignItems="center">
          {rightChildren}
        </Box>
      )}
    </RowBox>
  )
}

export { InfoTitle, InfoSection }
