import { Security } from "@material-ui/icons"
import React from "react"
import { useSnackbar } from ".."
import { useJobUtils } from "../../utils"
import { ProcessActionCard } from "../ActionCards"

const Job = ({ job, click, onView, onEdit, date, compact, showCheckbox, cy }) => {
  const { canNavigateToJob } = useJobUtils()
  const snackbar = useSnackbar()

  const navigable = canNavigateToJob(job)

  const handleCantNavigate = () => {
    snackbar.showMessage({
      icon: <Security />,
      message: "Job is in a different location, unable to view",
    })
  }

  return (
    <ProcessActionCard
      job={job}
      {...{ click, onView, onEdit, date, compact, navigable, showCheckbox, onCantNavigate: handleCantNavigate, cy }}
    />
  )
}

export { Job }
