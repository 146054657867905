import { BARCODE_TYPE } from "../enums"

const labelBarcodeFormats = [
  { display: "Code39", format: BARCODE_TYPE.CODE39, default: "123456" },
  { display: "Code128", format: BARCODE_TYPE.CODE128, default: "123456" },
  { display: "EAN8", format: BARCODE_TYPE.EAN8, default: "12345670" },
  { display: "EAN13", format: BARCODE_TYPE.EAN13, default: "1234567890128" },
  { display: "UPCA", format: BARCODE_TYPE.UPCA, default: "123456789012" },
  { display: "UPCE", format: BARCODE_TYPE.UPCE, default: "1234567" },
  { display: "ITF", format: BARCODE_TYPE.ITF, default: "1234567890123" },
]
export { labelBarcodeFormats }
