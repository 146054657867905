import React from "react"
import { Redirect } from "react-router-dom"
import { useApolloClient } from "@apollo/client"
import { useAuth } from "../../services/useAuth"
import { useMountEffect } from "../../utils"

const Logout = () => {
  const client = useApolloClient()
  const { logout } = useAuth(client)

  useMountEffect(() => {
    logout()
  })

  console.log("[Logout]", "Signing out")

  return <Redirect to="/login" />
}

export default Logout
