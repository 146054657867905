import React from "react"
import {
  Box,
  Tabs,
  Tab,
  Divider,
  Hidden,
  Drawer,
  RadioGroup,
  Radio,
  FormControlLabel,
  Grid,
  useTheme,
  useMediaQuery,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { Trans, t } from "@lingui/macro"
import { ModuleHeading, Switch } from ".."
import { useAuth } from "../../services"
import { jobFiltersStateVar, useJobFiltersStore } from "../../data"
import { AssignedToPickers } from "../AssignedToPickers/AssignedToPickers"

const useStyles = makeStyles((theme) => ({
  title: {
    padding: 0,
    margin: "0 0 4px 0",
  },
  filter: {
    width: ({ fullWidth }) => (fullWidth ? "100%" : 322),
    backgroundColor: theme.palette.shaded.filter,
    borderRadius: "8px",
  },
  filterButton: {
    paddingTop: ({ xs }) => (xs ? 6 : "inherit"),
    paddingBottom: ({ xs }) => (xs ? 6 : "inherit"),
  },
  drawer: {
    maxWidth: "80vw",
  },
  tabsIndicator: {
    width: ({ fullWidth }) => (fullWidth ? "auto" : "145px !important"),
  },
  tabRoot: {
    minWidth: ({ fullWidth }) => (fullWidth ? "none" : 145),
    maxWidth: ({ fullWidth }) => (fullWidth ? "none" : 145),
  },
  assignedTo: {
    borderBottom: "none",
  },
  radioTabs: {
    backgroundColor: theme.palette.shaded.filter,
  },
}))

const handleSet = (prop, value) => {
  jobFiltersStateVar({
    ...jobFiltersStateVar(),
    [prop]: value,
  })
}

const Filters = ({ classes }) => {
  const { jobsFiltersStore } = useJobFiltersStore()
  const { tab, hideCompleted, hideFuture, hideHidden, isFuture } = jobsFiltersStore

  return (
    <Box mb={3}>
      {tab === "others" && (
        <Box pt={3} px={3}>
          <AssignedToPickers classes={classes} />
        </Box>
      )}
      <Box px={3} pb={3} pt={2}>
        <Grid container direction="row">
          <Grid xs={12} sm={5} md={12} item>
            <Switch
              label={<Trans>Show completed</Trans>}
              checked={!hideCompleted}
              onChange={() => handleSet("hideCompleted", !hideCompleted)}
              disabled={isFuture}
            />
          </Grid>

          <Grid xs={12} sm={5} md={12} item>
            <Switch
              label={<Trans>Show not yet available</Trans>}
              checked={!hideFuture}
              onChange={() => handleSet("hideFuture", !hideFuture)}
              disabled={isFuture}
            />
          </Grid>

          <Grid xs={12} sm={5} md={12} item>
            <Switch
              label={<Trans>Show hidden</Trans>}
              checked={!hideHidden}
              onChange={() => handleSet("hideHidden", !hideHidden)}
              disabled={isFuture}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

const JobsFilters = ({ open, onClose, fullWidth }) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const { jobsFiltersStore } = useJobFiltersStore()
  const {
    settings: { deviceGroups },
  } = useAuth()
  const { tab } = jobsFiltersStore

  const classes = useStyles({ fullWidth, xs })

  const handleToggleFilterDrawer = () => {
    onClose && onClose()
  }

  const hasDeviceGroups = deviceGroups?.length

  return (
    <>
      <Hidden mdDown>
        <Box mr={5} className={classes.filter}>
          <Box px={3} pt={2} pb={0}>
            <ModuleHeading className={classes.title}>
              <Trans>Filter jobs</Trans>
            </ModuleHeading>
            {!hasDeviceGroups && (
              <Tabs
                variant="standard"
                value={tab}
                indicatorColor="primary"
                textColor="primary"
                onChange={(e, newTab) => handleSet("tab", newTab)}
                aria-label="Job filtering tabs"
                classes={{ indicator: classes.tabsIndicator }}
              >
                <Tab label={<Trans>Assigned to me</Trans>} value="me" classes={{ root: classes.tabRoot }} />
                <Tab label={<Trans>Assigned to others</Trans>} value="others" classes={{ root: classes.tabRoot }} />
              </Tabs>
            )}
          </Box>
          {!hasDeviceGroups && <Divider />}
          <Filters classes={classes} />
        </Box>
      </Hidden>

      <Hidden lgUp>
        <Drawer open={open} onClose={handleToggleFilterDrawer}>
          <Box className={classes.drawer}>
            {!hasDeviceGroups && (
              <RadioGroup
                value={tab}
                onChange={(e) => handleSet("tab", e.target.value)}
                row
                className={classes.radioTabs}
              >
                <Box display="flex" flexDirection="column" p={3}>
                  <FormControlLabel label={t`Assigned to me`} value="me" control={<Radio color="primary" />} />
                  <FormControlLabel label={t`Assigned to others`} value="others" control={<Radio color="primary" />} />
                </Box>
              </RadioGroup>
            )}
            {!hasDeviceGroups && <Divider />}
            <Filters classes={classes} />
          </Box>
        </Drawer>
      </Hidden>
    </>
  )
}

export { JobsFilters }
