import { ListItem, ListItemAvatar, ListItemIcon, ListItemText, makeStyles } from "@material-ui/core"
import { NavLink } from "react-router-dom"
import { ErrorBoundary } from "../ErrorBoundary"
import { useNotificationUtils } from "../../utils"
import { NOTIFICATION_TYPE } from "../../data"
import NotificationFrom from "./NotificationFrom"
import NotificationIcon from "./NotificationIcon"
import NotificationMessage from "./NotificationMessage"
import TimeAgo from "../TimeAgo"
import { Icon } from "../Icon"

const useStyles = makeStyles((theme) => ({
  item: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    borderBottom: "1px solid #e9e9e9",
    "&:last-child": {
      borderBottom: "none",
    },
    "&:hover": {
      background: theme.palette.shaded.nav,
      outline: "none",
    },
    "&:focus": {
      background: theme.palette.shaded.nav,
      outline: "none",
    },
  },
  itemAvatar: {
    minWidth: 0,
    marginRight: theme.spacing(1),
  },
  itemIcon: {
    minWidth: 0,
    marginRight: theme.spacing(1),
  },
  itemMessage: {
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: "16px",
    width: 215,
  },
  itemLinkIcon: {
    minWidth: 0,
    marginRight: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  age: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontWeight: "normal",
  },
  avatar: {
    width: 40,
    height: 40,
  },
}))

const NotificationListItem = ({ notification, onClick }) => {
  const classes = useStyles()
  const { getNavigateToLink } = useNotificationUtils()

  const handleClick = () => {
    onClick && onClick(notification)
  }

  const hasLink = notification.type !== NOTIFICATION_TYPE.GENERAL

  return (
    <ErrorBoundary>
      <ListItem
        className={classes.item}
        alignItems="center"
        component={hasLink ? NavLink : "div"}
        to={{ pathname: getNavigateToLink(notification) }}
        onClick={() => handleClick(notification)}
      >
        <ListItemAvatar className={classes.itemAvatar}>
          <NotificationFrom notification={notification} className={classes.avatar} />
        </ListItemAvatar>
        <ListItemIcon className={classes.itemIcon}>
          <NotificationIcon {...notification} />
        </ListItemIcon>
        <ListItemText
          className={classes.text}
          primary={<NotificationMessage notification={notification} className={classes.itemMessage} />}
          secondary={<TimeAgo date={notification.createdAt} className={classes.age} />}
        />
        {hasLink && (
          <ListItemIcon className={classes.itemLinkIcon}>
            <Icon name="right" />
          </ListItemIcon>
        )}
      </ListItem>
    </ErrorBoundary>
  )
}

export { NotificationListItem }
