import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { makeStyles, useTheme } from "@material-ui/styles"
import ReactMarkdown from "react-markdown"
import moment from "moment"
import { ToggleButton, ToggleButtonGroup, Alert } from "@material-ui/lab"
import {
  FormatAlignCenterOutlined,
  FormatAlignLeftOutlined,
  FormatAlignRightOutlined,
  WrapTextOutlined,
  DeleteOutline,
} from "@material-ui/icons"
import {
  Button,
  TextField,
  Paper,
  Slider,
  DialogTitle,
  Box,
  MenuItem,
  ListItemIcon,
  Divider,
  Grid,
  Popper,
  Typography,
  useMediaQuery,
  ClickAwayListener,
} from "@material-ui/core"
import { RowBox } from "../Boxes"
import { DialogTitleCloser } from "../Creators/DialogTitleCloser"
import { OutlinedSelect, Icon, RemoveIconButton } from ".."
import { useAuth } from "../../services"

import { LABEL_TEMPLATE_FIELD_TYPE } from "../../data"
import { labelFieldTypes } from "../../data/labels/labelFieldTypes"
import { labelDatetimeFormats } from "../../data/labels/labelDatetimeFormats"
import { labelUserFormats } from "../../data/labels/labelUserFormats"
import { labelBarcodeFormats } from "../../data/labels/labelBarcodeFormats"

const useStyles = makeStyles((theme) => ({
  frame: {
    display: "flex",
    justifyContent: "space-between",
  },
  custom: {
    fillColor: theme.palette.primary.main,
  },
  popper: {
    zIndex: 1300,
    maxWidth: "350px",
  },
  fieldSelectBox: {
    gap: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  fieldSelect: {
    zIndex: 99999,
    "& .MuiSelect-select": {
      display: "flex",
    },
    "& .MuiListItemIcon-root": {
      minWidth: theme.spacing(4),
    },
  },
  fieldDescription: {
    "& p": {
      margin: 0,
    },
  },
  learnMore: {
    display: "flex",
    gap: theme.spacing(0.5),
    alignItems: "center",
    paddingTop: theme.spacing(1),
  },
  externalIcon: {
    fontSize: "16px",
  },
  popperTitle: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}))

const LabelsPopper = ({ count, onUpdateField, onRemoveField, onClickAway, anchorEl, activeEl, open }) => {
  const classes = useStyles()
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const { id } = useParams()
  const { hasFeature } = useAuth()
  const hasBarcodes = hasFeature("labels_barcodes")

  // Required fields
  const [fieldType, setFieldType] = useState(activeEl.content.type)
  const [fontHeight, setFontHeight] = useState(activeEl.content.fontHeight)
  const [textWrap, setWrap] = useState(activeEl.content.textWrap)
  const [alignment, setAlignment] = useState(activeEl.content.align)
  const [upperCase, setCase] = useState(activeEl.content.upperCase)
  const [currentTime] = useState(moment())

  // Optional state fields
  const [dateFormat, setDateFormat] = useState(
    activeEl.content.dateFormat ? activeEl.content.dateFormat : "MMM DD, hh:mm A",
  )

  const [userFormat, setUserFormat] = useState(
    activeEl.content.userFormat ? activeEl.content.userFormat : labelUserFormats[0].format,
  )

  const [dynamicName, setDynamicName] = useState(activeEl.content.dynamicName ? activeEl.content.dynamicName : "")
  const [dynamicText, setDynamicText] = useState(activeEl.content.dynamicText ? activeEl.content.dynamicText : "")
  const [staticText, setStaticText] = useState(activeEl.content.staticText ? activeEl.content.staticText : "")
  const [barcodeFormat, setBarcodeFormat] = useState(
    activeEl.content.barcodeFormat ? activeEl.content.barcodeFormat : labelBarcodeFormats[0].format,
  )

  // Fields within existing templates that are in use should be locked to their type. Users can still add new fields to in-use templates, but can't edit existing ones.
  const isNew = id === "new"
  const isExisting = Object.prototype.hasOwnProperty.call(activeEl, "id")
  const isUnlocked = count === 0 || isNew || count === null || !isExisting
  const isLocked = count > 0 && !isNew && isExisting

  const handleWrap = (event, value) => {
    if (value !== null) {
      setWrap(value)
      activeEl.content.textWrap = value
      onUpdateField(activeEl)
    }
  }

  const handleAlignment = (event, value) => {
    if (value !== null) {
      setAlignment(value)
      activeEl.content.align = value
      onUpdateField(activeEl)
    }
  }

  const handleCase = (event, value) => {
    if (value !== null) {
      setCase(value)
      activeEl.content.upperCase = value
      onUpdateField(activeEl)
    }
  }

  const handleFontHeight = (event, value) => {
    if (value !== null) {
      setFontHeight(value)
      activeEl.content.fontHeight = value
      onUpdateField(activeEl)
    }
  }

  // To avoid having unneeded fields in the field when the type changes, clear out the ones that aren't needed
  const handleFieldType = (set, name, newValue) => {
    activeEl.content = {
      type: newValue,
      fontHeight,
      align: alignment,
      upperCase,
      textWrap,
    }

    // Add extra fields based on type as needed
    switch (newValue) {
      case LABEL_TEMPLATE_FIELD_TYPE.USER:
        activeEl.content.userFormat = userFormat
        break
      case LABEL_TEMPLATE_FIELD_TYPE.CURRENT_DATETIME:
      case LABEL_TEMPLATE_FIELD_TYPE.PREP_DATETIME:
      case LABEL_TEMPLATE_FIELD_TYPE.READY_DATETIME:
      case LABEL_TEMPLATE_FIELD_TYPE.DISCARD_DATETIME:
        activeEl.content.dateFormat = dateFormat
        break
      case LABEL_TEMPLATE_FIELD_TYPE.DYNAMIC_TEXT_ONCREATE:
      case LABEL_TEMPLATE_FIELD_TYPE.DYNAMIC_TEXT_ONPRINT:
        activeEl.content.dynamicName = dynamicName
        activeEl.content.dynamicText = dynamicText
        setDynamicName("")
        break
      case LABEL_TEMPLATE_FIELD_TYPE.STATIC_TEXT:
        activeEl.content.staticText = staticText
        break
      case LABEL_TEMPLATE_FIELD_TYPE.BARCODE:
        activeEl.content.barcodeFormat = barcodeFormat
        if (dynamicName === "") {
          activeEl.content.dynamicName = "Barcode"
          setDynamicName("Barcode")
        }
        break
      default:
        break
    }
    handleChange(set, name, newValue)
  }

  const handleChange = (set, name, newValue) => {
    if (newValue !== null) {
      set(newValue)
      activeEl.content[name] = newValue
      onUpdateField(activeEl)
    }
  }

  const handleRemove = () => {
    onRemoveField(activeEl)
  }

  const fieldDescription = () => {
    const matchedField = labelFieldTypes.find((item) => item.value === fieldType)
    return matchedField.description
  }

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement={xs ? "bottom" : "right"}
      className={classes.popper}
      modifiers={{
        flip: { enabled: true },
        preventOverflow: { enabled: false, boundariesElement: "scrollParent" },
        hide: { enabled: false },
      }}
    >
      <ClickAwayListener mouseEvent="onMouseUp" onClickAway={onClickAway}>
        <Paper>
          <DialogTitle className={classes.popperTitle}>
            Edit field
            <DialogTitleCloser onClose={onClickAway} />
          </DialogTitle>
          <Box p={2}>
            <Box display="flex" flexDirection="column" mt={2} mb={2} alignItems="center">
              <Grid container spacing={2} display="flex">
                <Grid item xs={12} sm={12} className={classes.fieldSelectBox}>
                  <OutlinedSelect
                    value={fieldType}
                    className={classes.fieldSelect}
                    fullWidth
                    native={false}
                    id="fieldtype"
                    label="Field Type"
                    name="fieldtype"
                    required
                    autoFocus
                    disabled={isLocked}
                    onChange={(event) => handleFieldType(setFieldType, "type", event.target.value)}
                  >
                    {labelFieldTypes &&
                      labelFieldTypes.map(({ name, value, icon }) => {
                        // Temporary for barcode feature flag
                        if (value === "barcode" && !hasBarcodes) {
                          return null
                        }

                        return (
                          <MenuItem key={value} value={value}>
                            <ListItemIcon>
                              <Icon name={icon} />
                            </ListItemIcon>
                            <RowBox>
                              <Box>{name}</Box>
                            </RowBox>
                          </MenuItem>
                        )
                      })}
                  </OutlinedSelect>

                  <Box>
                    <RemoveIconButton onClick={handleRemove} subject="field">
                      <DeleteOutline />
                    </RemoveIconButton>
                  </Box>
                </Grid>

                {fieldType !== "" && (
                  <Grid item xs={12} sm={12}>
                    {isUnlocked && (
                      <Alert icon={false} severity="info">
                        <>
                          <ReactMarkdown className={classes.fieldDescription}>{fieldDescription()}</ReactMarkdown>
                          {/* Add help link when required in here */}
                        </>
                      </Alert>
                    )}

                    {isLocked && (
                      <Alert icon={false} severity="warning">
                        <ReactMarkdown className={classes.fieldDescription}>
                          This field type cannot be changed as the template is in use.
                        </ReactMarkdown>
                      </Alert>
                    )}
                  </Grid>
                )}

                <Grid item xs={12} sm={12}>
                  <Divider orientation="horizontal" />
                </Grid>

                {[LABEL_TEMPLATE_FIELD_TYPE.USER].includes(fieldType) && (
                  <Grid item xs={12} sm={12}>
                    <OutlinedSelect
                      value={userFormat}
                      className={classes.select}
                      fullWidth
                      native={false}
                      id="format"
                      label="Format"
                      name="format"
                      required
                      onChange={(event) => handleChange(setUserFormat, "userFormat", event.target.value)}
                    >
                      {labelUserFormats.map((value, index) => (
                        <MenuItem key={index} value={value.format}>
                          <RowBox>
                            <Box>{value.display}</Box>
                          </RowBox>
                        </MenuItem>
                      ))}
                    </OutlinedSelect>
                  </Grid>
                )}

                {/* For datetime fields, moment.js format is also required */}
                {[
                  LABEL_TEMPLATE_FIELD_TYPE.CURRENT_DATETIME,
                  LABEL_TEMPLATE_FIELD_TYPE.PREP_DATETIME,
                  LABEL_TEMPLATE_FIELD_TYPE.READY_DATETIME,
                  LABEL_TEMPLATE_FIELD_TYPE.DISCARD_DATETIME,
                ].includes(fieldType) && (
                  <Grid item xs={12} sm={12}>
                    <OutlinedSelect
                      value={dateFormat}
                      className={classes.select}
                      fullWidth
                      native={false}
                      id="format"
                      label="Format"
                      name="format"
                      required
                      onChange={(event) => handleChange(setDateFormat, "dateFormat", event.target.value)}
                    >
                      {labelDatetimeFormats &&
                        labelDatetimeFormats.map((value, index) => (
                          <MenuItem key={index} value={value}>
                            <RowBox>
                              <Box>{currentTime.format(value)}</Box>
                            </RowBox>
                          </MenuItem>
                        ))}
                    </OutlinedSelect>
                  </Grid>
                )}

                {/* For dynamic text fields */}
                {[
                  LABEL_TEMPLATE_FIELD_TYPE.DYNAMIC_TEXT_ONCREATE,
                  LABEL_TEMPLATE_FIELD_TYPE.DYNAMIC_TEXT_ONPRINT,
                ].includes(fieldType) && (
                  <>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="name"
                        label="Field name"
                        name="name"
                        value={dynamicName}
                        onChange={(event) => handleChange(setDynamicName, "dynamicName", event.target.value)}
                        required
                        data-cy="TextField-dynamic-name"
                      />
                    </Grid>

                    {[
                      LABEL_TEMPLATE_FIELD_TYPE.DYNAMIC_TEXT_ONCREATE,
                      LABEL_TEMPLATE_FIELD_TYPE.DYNAMIC_TEXT_ONPRINT,
                    ].includes(fieldType) && (
                      <Grid item xs={12} sm={12}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          id="text"
                          label={
                            fieldType === LABEL_TEMPLATE_FIELD_TYPE.DYNAMIC_TEXT_ONCREATE
                              ? "Default text"
                              : "Placeholder"
                          }
                          name="text"
                          value={dynamicText}
                          onChange={(event) => handleChange(setDynamicText, "dynamicText", event.target.value)}
                          data-cy="TextField-dynamic-text"
                        />
                      </Grid>
                    )}
                  </>
                )}

                {/* For static text fields */}
                {fieldType === LABEL_TEMPLATE_FIELD_TYPE.STATIC_TEXT && (
                  <Grid item xs={12} sm={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="text"
                      label="Text"
                      name="text"
                      value={staticText}
                      onChange={(event) => handleChange(setStaticText, "staticText", event.target.value)}
                      required
                      data-cy="TextField-static-text"
                    />
                  </Grid>
                )}

                {/* For barcode / qrcode fields */}
                {[LABEL_TEMPLATE_FIELD_TYPE.BARCODE].includes(fieldType) && hasBarcodes && (
                  <>
                    <Grid item xs={12} sm={12}>
                      <OutlinedSelect
                        value={barcodeFormat}
                        className={classes.select}
                        fullWidth
                        native={false}
                        id="type"
                        label="Type"
                        name="type"
                        required
                        onChange={(event) => {
                          handleChange(setBarcodeFormat, "barcodeFormat", event.target.value)
                        }}
                      >
                        {labelBarcodeFormats.map((value, index) => (
                          <MenuItem key={index} value={value.format}>
                            <RowBox>
                              <Box>{value.display}</Box>
                            </RowBox>
                          </MenuItem>
                        ))}
                      </OutlinedSelect>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="name"
                        label="Field name"
                        name="name"
                        value={dynamicName}
                        onChange={(event) => handleChange(setDynamicName, "dynamicName", event.target.value)}
                        required
                        data-cy="TextField-dynamic-name"
                      />
                    </Grid>
                  </>
                )}

                {![LABEL_TEMPLATE_FIELD_TYPE.BARCODE].includes(fieldType) && (
                  <>
                    <Grid item xs={12} sm={12}>
                      <Typography fontSize={14} id="Font height slider" gutterBottom>
                        Text size
                      </Typography>
                      <Slider
                        className={classes.slider}
                        onChange={handleFontHeight}
                        value={fontHeight}
                        aria-labelledby="discrete-slider"
                        valueLabelDisplay="auto"
                        step={0.5}
                        marks
                        min={1}
                        max={activeEl.height}
                        aria-label="Font height slider"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Box className={classes.frame}>
                        {/* Wrap */}
                        <ToggleButtonGroup
                          size="small"
                          value={textWrap}
                          exclusive
                          onChange={handleWrap}
                          aria-label="Text wrapping"
                        >
                          <ToggleButton value={false} aria-label="Normal">
                            <Icon className={classes.custom} name="nowrap" />
                          </ToggleButton>
                          <ToggleButton value aria-label="Wrap text">
                            <WrapTextOutlined />
                          </ToggleButton>
                        </ToggleButtonGroup>

                        {/* Alignment */}
                        <Divider flexItem orientation="vertical" />
                        <ToggleButtonGroup
                          size="small"
                          value={alignment}
                          onChange={handleAlignment}
                          exclusive
                          aria-label="Text alignment"
                        >
                          <ToggleButton value="Start" aria-label="Bold">
                            <FormatAlignLeftOutlined />
                          </ToggleButton>
                          <ToggleButton value="Center" aria-label="Italic">
                            <FormatAlignCenterOutlined />
                          </ToggleButton>
                          <ToggleButton value="End" aria-label="Underlined">
                            <FormatAlignRightOutlined />
                          </ToggleButton>
                        </ToggleButtonGroup>

                        {/* Uppercase */}
                        <Divider flexItem orientation="vertical" />
                        <ToggleButtonGroup
                          size="small"
                          exclusive
                          value={upperCase}
                          onChange={handleCase}
                          aria-label="Text case"
                        >
                          <ToggleButton value={false} aria-label="Normal">
                            <Icon className={classes.custom} name="sentencecase" />
                          </ToggleButton>
                          <ToggleButton value aria-label="Uppercase">
                            <Icon className={classes.custom} name="uppercase" />
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Box>
                    </Grid>
                  </>
                )}

                <Grid item xs={12} sm={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={onClickAway}
                    data-cy="Button-close-popper"
                  >
                    Done
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>
      </ClickAwayListener>
    </Popper>
  )
}

export { LabelsPopper }
