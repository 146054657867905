import { useQuery, gql } from "@apollo/client"

const VERIFY_INVITE_QUERY = gql`
  query VerifyInvite($id: ID!, $token: String!) {
    verifyInvite(id: $id, token: $token) {
      firstName
    }
  }
`

const useQueryVerifyInvite = (options) =>
  useQuery(VERIFY_INVITE_QUERY, {
    errorPolicy: "none",
    ...options,
  })

export { useQueryVerifyInvite }
