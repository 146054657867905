import { useQueryAsset } from "../assets"
import { PROCESS_STEP_SELECTION_SOURCES } from "../enums"
import { useQueryFoodItem } from "../food-items"
import { useQuerySupplier } from "../suppliers"

const { SUPPLIERS, ASSETS, FOOD_ITEMS } = PROCESS_STEP_SELECTION_SOURCES

const useQuerySelectionSourceItem = (source, id) => {
  const {
    data: supplierData,
    loading: supplierLoading,
    error: supplierError,
  } = useQuerySupplier({
    skip: !(id && source === SUPPLIERS),
    variables: { id },
    errorPolicy: "all",
  })
  const {
    data: assetData,
    loading: assetLoading,
    error: assetError,
  } = useQueryAsset({
    skip: !(id && source === ASSETS),
    variables: { id },
    errorPolicy: "all",
  })
  const {
    data: foodItemData,
    loading: foodItemLoading,
    error: foodItemError,
  } = useQueryFoodItem({
    skip: !(id && source === FOOD_ITEMS),
    variables: { id },
    errorPolicy: "all",
  })

  const data =
    supplierData || assetData || foodItemData
      ? {
          item: supplierData?.supplier || assetData?.asset || foodItemData?.foodItem,
        }
      : null

  return {
    data,
    loading: supplierLoading || assetLoading || foodItemLoading,
    error: supplierError || assetError || foodItemError,
    name: data?.name,
  }
}

export { useQuerySelectionSourceItem }
