import React, { memo } from "react"
import { makeStyles } from "@material-ui/core"
import moment from "moment-timezone"
import { useDateUtils } from "../../utils"
import { useAuth } from "../../services"

const useStyles = makeStyles((theme) => ({
  overdue: {
    color: theme.palette.error.main,
  },
}))

const FormatTime = ({ value, showOverdue = false, overdueSuffix = "" }) => {
  const {
    location: { timeZone },
  } = useAuth()
  const { formatTime } = useDateUtils()
  const classes = useStyles()
  const isOverdue = showOverdue && value && moment(value).tz(timeZone).isSameOrBefore(moment())

  try {
    return (
      <span className={isOverdue ? classes.overdue : null}>
        {value && formatTime(value, timeZone)}
        {isOverdue && overdueSuffix && <> {overdueSuffix}</>}
      </span>
    )
  } catch (ex) {
    return <>Data invalid.</>
  }
}

const FormatTimeMemo = memo(FormatTime)

export { FormatTimeMemo as FormatTime }
