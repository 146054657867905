import html2canvas from "html2canvas"
import { createSafeFilename } from "../../utils"

const generateDefault = async (title) => {
  const target = document.querySelector("#print")
  const rect = target.getBoundingClientRect()
  const canvas = await html2canvas(document.querySelector("#print"), {
    width: rect.width,
    height: rect.height,
    backgroundColor: "#FFFFFF",
    scale: 1,
    logging: false,
  })

  const fileData = await new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        resolve(blob)
      },
      "image/png",
      1,
    )
  })

  const fileName = createSafeFilename(`${title}_default`, "png")
  const fileSize = fileData.size

  return { fileData, fileSize, fileName }
}

export { generateDefault }
