import React from "react"
import { Box, makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  frame: {
    backgroundColor: theme.palette.background.shaded,
    padding: theme.spacing(2, 2),
    borderRadius: 8,
    margin: theme.spacing(1, 2),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1, 1),
      backgroundColor: theme.palette.background.shaded,
      "& div": {
        display: "flex",
        justifyContent: "center",
      },
    },
  },
}))

const StepContentFrame = ({ children }) => {
  const classes = useStyles()

  return <Box className={classes.frame}>{children}</Box>
}

export { StepContentFrame }
