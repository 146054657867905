import React from "react"
import { Box, Hidden, useMediaQuery, useTheme } from "@material-ui/core"
import { Trans } from "@lingui/macro"
import { ActionCard, ActionCardInfo } from "."
import { AuthorTools } from "../Author"
import { ColumnBox, RowBox } from "../Boxes"
import { FormatDateTimeCompact } from "../Format"
import { Icon, AdornmentIcon } from "../Icon"

const ActionCardFooterInfo = ({ title, value, icon, mr }) => (
  <RowBox mr={mr}>
    <Hidden mdUp>
      <ActionCardInfo>
        <AdornmentIcon
          name={icon}
          tooltip={
            <>
              {title}: {value}
            </>
          }
          iconProps={{ throwForUnmapped: false }}
        />
      </ActionCardInfo>
    </Hidden>
    <Hidden smDown>
      <AdornmentIcon name={icon} tooltip={title} iconProps={{ throwForUnmapped: false }} />
      <Box ml={1} mr={0}>
        {value}
      </Box>
    </Hidden>
  </RowBox>
)

const DeviceActionCard = ({ device, onEdit, onDelete, detail, ...rest }) => {
  const theme = useTheme()
  const small = useMediaQuery(theme.breakpoints.down("sm"))
  const { id, label, name, location, type, groups, identityProvider, os, managed, activatedAt, activeAt } = device

  const handleEdit = () => {
    if (onEdit) onEdit(device)
  }
  const handleDelete = () => {
    if (onDelete) onDelete(device)
  }

  return (
    <ActionCard
      key={id}
      title={label}
      stub={
        <ColumnBox mr={1} alignSelf="flex-start">
          <Icon name={`${os}-${type}`} throwForUnmapped={false} />
        </ColumnBox>
      }
      detail={
        <Box ml={-0.5}>
          <RowBox mt={2} title={<Trans>Location</Trans>}>
            <Icon name="location" />
            <Box ml={1} title={<Trans>Location</Trans>}>
              {location.name}
            </Box>
          </RowBox>
          {!!identityProvider && (
            <RowBox mt={2} title={<Trans>External Identity Provider</Trans>}>
              <Icon name="identity-provider" />
              <Box ml={1} title={<Trans>External Identity Provider</Trans>}>
                {identityProvider.name}
              </Box>
            </RowBox>
          )}
          {!!groups?.length && (
            <RowBox mt={2} title={<Trans>Areas of Work</Trans>}>
              <Icon name="group" />
              <Box ml={1} title={<Trans>Areas of Work</Trans>}>
                {groups && groups.map((group) => group.name).join(", ")}
              </Box>
            </RowBox>
          )}
          {managed && (
            <RowBox mt={2}>
              <Icon name="device-managed" />
              <Box ml={1}>
                <Trans>MDM managed device</Trans>
              </Box>
            </RowBox>
          )}
        </Box>
      }
      cursor={null}
      rightChildren={
        <AuthorTools
          item={device}
          admins={{
            edit: ["devices_update_all", "devices_update_locations"],
            delete: "devices_delete",
          }}
          onEdit={handleEdit}
          subject="device"
          onDelete={handleDelete}
        />
      }
      footer={
        <>
          <ActionCardFooterInfo
            title={<Trans>Device</Trans>}
            value={name}
            icon={os}
            throwForUnmapped={false}
            mr="auto"
          />

          <ActionCardFooterInfo
            title={<Trans>Date registered</Trans>}
            value={<FormatDateTimeCompact value={activatedAt} />}
            icon="device-registered"
            mr={small ? 1 : 3}
          />
          <ActionCardFooterInfo
            title={<Trans>Last activity</Trans>}
            value={<FormatDateTimeCompact value={activeAt} />}
            icon="last-activity"
            mr={small ? -1 : 0}
          />
        </>
      }
      {...rest}
    />
  )
}

export { DeviceActionCard }
