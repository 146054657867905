import { useMutation, gql } from "@apollo/client"
import { ADMINABLE_USERS_FIELDS } from "./fragments/adminableUserFieldsFragment"

const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($id: ID!, $input: AdminableUserUpdateInput!) {
    adminableUser(id: $id) {
      update(input: $input) {
        ...AdminableUsersFields
      }
    }
  }
  ${ADMINABLE_USERS_FIELDS}
`

const useMutationUpdateUser = () => useMutation(UPDATE_USER_MUTATION)

export { useMutationUpdateUser }
