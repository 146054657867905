import { useLazyQuery, gql, useQuery } from "@apollo/client"
import {
  ACTION_COMMENT_CREATED_SUBSCRIPTION,
  ACTION_UPDATED_SUBSCRIPTION,
  ACTION_DELETED_SUBSCRIPTION,
  ACTION_CREATED_SUBSCRIPTION,
  actionCreatedSubscriptionUpdateQuery,
  actionDeletedSubscriptionUpdateQuery,
  actionCommentCreatedSubscriptionUpdateQuery,
} from "./useQueryActions"
import { ACTION_FIELDS } from "./fragments/actionFieldsFragment"

const ACTION_QUERY = gql`
  query Action($id: ID!) {
    action(id: $id) {
      ...ActionFields
    }
  }
  ${ACTION_FIELDS}
`

const subscribe = (result) => () => [
  result.subscribeToMore({
    document: ACTION_CREATED_SUBSCRIPTION,
    updateQuery: actionCreatedSubscriptionUpdateQuery,
    onError: (err) => console.log("[useQueryActions][ACTION_CREATED_SUBSCRIPTION]", err),
  }),
  result.subscribeToMore({
    document: ACTION_UPDATED_SUBSCRIPTION,
    onError: (err) => console.log("[useLazyQueryAction][ACTION_UPDATED_SUBSCRIPTION]", err),
  }),
  result.subscribeToMore({
    document: ACTION_DELETED_SUBSCRIPTION,
    updateQuery: actionDeletedSubscriptionUpdateQuery,
    onError: (err) => console.log("[useLazyQueryAction][ACTION_DELETED_SUBSCRIPTION]", err),
  }),
  result.subscribeToMore({
    document: ACTION_COMMENT_CREATED_SUBSCRIPTION,
    updateQuery: actionCommentCreatedSubscriptionUpdateQuery,
    onError: (err) => console.log("[useLazyQueryAction][COMMENT_CREATED_SUBSCRIPTION]", err),
  }),
]

const useQueryAction = (options) => {
  const result = useQuery(ACTION_QUERY, {
    ...options,
  })

  return {
    ...result,
    subscribe: subscribe(result),
  }
}

const useLazyQueryAction = () => {
  const [loadAction, result] = useLazyQuery(ACTION_QUERY, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  })

  return [
    loadAction,
    {
      ...result,
      subscribe: subscribe(result),
    },
  ]
}

export { useQueryAction, useLazyQueryAction, ACTION_QUERY }
