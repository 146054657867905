import Config from "react-global-configuration"
import { toId } from "./data"

const useActionUtils = () => {
  const config = Config.get()

  const getNavigateToLink = (action) => `/hub/action/${toId(action)}`
  const getFullNavigateToLink = (action) =>
    `${config.clientLinkBaseUri || config.clientBaseUri}${getNavigateToLink(action)}`

  return {
    getNavigateToLink,
    getFullNavigateToLink,
  }
}

export { useActionUtils }
