import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Fab } from "@material-ui/core"

const useStyles = makeStyles(() => ({
  buttonFixed: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 80,
    left: "auto",
    position: "fixed",
    zIndex: 999,
  },
}))

const FloatingActionButton = ({ children, ...rest }) => {
  const classes = useStyles()

  return (
    <Fab {...rest} className={classes.buttonFixed}>
      {children}
    </Fab>
  )
}

export default FloatingActionButton
