import { useMutation, gql } from "@apollo/client"
import { TRAINING_MODULE_FIELDS } from "./useQueryTrainingModules"

const CREATE_TRAINING_MODULE_MUTATION = gql`
  mutation CreateTrainingModule(
    $name: String!
    $description: String
    $knowledges: [ID!]!
    $users: [ID!]
    $groups: [ID!]    
  ) {
    createTrainingModule(
      name: $name
      description: $description
      knowledges: $knowledges
      users: $users
      groups: $groups      
    ) {
      ${TRAINING_MODULE_FIELDS}
    }
  }
`

const useMutationCreateTrainingModule = () => useMutation(CREATE_TRAINING_MODULE_MUTATION)

export { useMutationCreateTrainingModule }
