import React from "react"
import { Box, IconButton } from "@material-ui/core"
import { CancelOutlined as CancelOutlinedIcon } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"
import { PaperBox, LinearProgress } from ".."
import { FileIcon } from "../Icon"
import { RowBox } from "../Boxes"

const useStyles = makeStyles(() => ({
  fileName: {
    maxWidth: 220,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  progress: {
    width: 100,
  },
}))

const UploadingList = ({ uploaded, uploading, onRemoveUpload, documents = true, images = true, mb }) => {
  const classes = useStyles()

  const handleRemoveUpload = (id) => {
    if (onRemoveUpload) onRemoveUpload(id)
  }

  const uploads = []
  if (uploaded && uploaded.length > 0) uploads.push(...uploaded)
  if (uploading && uploading.length > 0) uploads.push(...uploading.filter((u) => u.loaded < u.total))

  return (
    <>
      {uploads && uploads.length > 0 && (
        <Box display="flex" flexGrow={1} alignItems="center" flexWrap="wrap" mb={mb}>
          {uploads
            .filter(
              ({ fileGroup }) =>
                (documents && images) || (documents && fileGroup === "document") || (images && fileGroup === "image")
            )
            .sort((a, b) => a.order - b.order)
            .map(({ id, fileName, loaded, total }) => {
              const item = typeof loaded === "undefined" || loaded === total
              return (
                <Box key={id || fileName} mr={1} mt={1}>
                  <PaperBox pl={1}>
                    <RowBox>
                      <Box mr={0.5} display="flex">
                        <FileIcon fileName={fileName} />
                      </Box>
                      {!item && (
                        <Box className={classes.progress}>
                          <LinearProgress value={Math.round((loaded / total) * 100)} />
                        </Box>
                      )}
                      {item && (
                        <Box mr={1} className={classes.fileName}>
                          {fileName}
                        </Box>
                      )}
                      {onRemoveUpload && (
                        <Box>
                          <IconButton edge="start" onClick={() => handleRemoveUpload(id)} disabled={!item}>
                            <CancelOutlinedIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      )}
                    </RowBox>
                  </PaperBox>
                </Box>
              )
            })}
        </Box>
      )}
    </>
  )
}

export { UploadingList }
