import { useMutation, gql } from "@apollo/client"

const ORGANISATION_FIELDS = `
    id
    name
    industry
    logo { key }
`

const UPDATE_ORGANISATION_MUTATION = gql`
  mutation UpdateOrganisation($id: ID!, $name: String!, $industry: String, $logo: ID) {
    updateOrganisation(id: $id, name: $name, industry: $industry, logo: $logo) {
      ${ORGANISATION_FIELDS}
    } 
  }
`

const useMutationUpdateOrganisation = () => useMutation(UPDATE_ORGANISATION_MUTATION)

export { useMutationUpdateOrganisation }
