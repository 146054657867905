import { useQuery, useLazyQuery, gql } from "@apollo/client"

const REPORTLOCATIONSOVERVIEW_FIELDS = `
  id
  name
  jobs {
    completed
    overdue
    uncompleted
    skipped
    total
  }
`

const REPORTLOCATIONSOVERVIEW_QUERY = gql`
  query ReportLocationsOverview($locations: [ID!]!, $groups: [ID!], $gt: ScheduleDateInput!, $lt: ScheduleDateInput) {
    reportLocationsOverview(locations: $locations, groups: $groups, gt: $gt, lt: $lt)
    {
      ${REPORTLOCATIONSOVERVIEW_FIELDS}
    } 
  }
`

const useQueryReportLocationsOverview = (options) =>
  useQuery(REPORTLOCATIONSOVERVIEW_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

const useLazyQueryReportLocationsOverview = (options) =>
  useLazyQuery(REPORTLOCATIONSOVERVIEW_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

export { useQueryReportLocationsOverview, useLazyQueryReportLocationsOverview }
