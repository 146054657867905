import { useQuery, gql } from "@apollo/client"

const ACCREDITATION_FIELDS = `
  id
  name
  description
  icon
  locations { id name }
  groups { id name }
  expires
  expiringWarningMonths
`

const ACCREDITATIONS_QUERY = gql`
  query Accreditations {
    accreditations {
      ${ACCREDITATION_FIELDS}
    }
  }
`

const useQueryAccreditations = () => useQuery(ACCREDITATIONS_QUERY)

export { useQueryAccreditations, ACCREDITATION_FIELDS }
