import React from "react"
import moment from "moment"
import { PROCESS_REPEAT, PROCESS_SCHEDULE_DUE, PROCESS_SCHEDULE_UNTIL, PROCESS_TYPE } from "../../../data"
import { useDateUtils, useProcessUtils } from "../../../utils"
import { FormatDateCompact, FormatDateTimeCompact } from "../../Format"

const typename = "__typename"

const ProcessScheduleStatement = ({ schedule, subject }) => {
  const { hasTime, scheduleDateToMoment, formatScheduleTime, formatTime } = useDateUtils()
  const { getRepeatProcessTypeData } = useProcessUtils()

  const { until, after: afterInput, due, repeat } = schedule
  const { type, frequency, weekdays, months } = repeat || {}

  if (!repeat) {
    return null
  }

  const typeData = getRepeatProcessTypeData(type)
  const selectedWeekdays = Object.keys(weekdays).filter((weekday) => weekday !== typename && weekdays[weekday])
  const selectedMonths = Object.keys(months).filter((month) => month !== typename && months[month])

  const after = scheduleDateToMoment(afterInput.date)

  // determine until if enabled
  const { type: untilType, period: untilPeriod } = until
  const pinned = untilType !== PROCESS_SCHEDULE_UNTIL.NONE

  const renderActiveForWithAdhoc = () => {
    return (
      <>
        active for{" "}
        <strong>
          {untilPeriod} {untilType}
        </strong>
      </>
    )
  }

  const getPinnedStatement = () => {
    let pinnedStatement
    switch (untilType) {
      case PROCESS_SCHEDULE_UNTIL.NEXT_INSTANCE:
        pinnedStatement = (
          <>
            active until <strong>the next scheduled instance</strong>
          </>
        )
        break
      case PROCESS_SCHEDULE_UNTIL.HOURS: {
        if (type === PROCESS_REPEAT.ADHOC) {
          pinnedStatement = renderActiveForWithAdhoc()
        } else {
          const pinnedUntil = moment(after).add(untilPeriod, "hours")
          pinnedStatement = (
            <>
              active until <FormatDateCompact value={pinnedUntil} /> at <strong>{formatTime(pinnedUntil)}</strong>
            </>
          )
        }
        break
      }
      case PROCESS_SCHEDULE_UNTIL.DAYS: {
        if (type === PROCESS_REPEAT.ADHOC) {
          pinnedStatement = renderActiveForWithAdhoc()
        } else {
          const pinnedUntil = moment(after).add(untilPeriod * 24, "hours")
          pinnedStatement = (
            <>
              active until <FormatDateCompact value={pinnedUntil} /> at <strong>{formatTime(pinnedUntil)}</strong>
            </>
          )
        }
        break
      }
      default:
        pinnedStatement = "active until complete"
    }
    return pinnedStatement
  }

  // determine due if enabled
  const { type: dueType, period: duePeriod } = due

  const renderDueAfterWithAdhoc = () => {
    return (
      <>
        , due after{" "}
        <strong>
          {duePeriod} {dueType},{" "}
        </strong>
      </>
    )
  }

  const getDueStatement = () => {
    let dueStatement
    switch (dueType) {
      case PROCESS_SCHEDULE_DUE.HOURS:
      case PROCESS_SCHEDULE_DUE.MINUTES: {
        if (type === PROCESS_REPEAT.ADHOC) {
          dueStatement = renderDueAfterWithAdhoc()
        } else {
          const dueAt = moment(after).add(duePeriod, dueType)
          dueStatement = (
            <>
              , due{" "}
              <strong>
                <FormatDateTimeCompact value={dueAt} />
              </strong>
            </>
          )
        }
        break
      }
      case PROCESS_SCHEDULE_DUE.DAYS: {
        if (type === PROCESS_REPEAT.ADHOC) {
          dueStatement = renderDueAfterWithAdhoc()
        } else {
          const dueAt = moment(after).add(duePeriod * 24, "hours")
          dueStatement = (
            <>
              , due{" "}
              <strong>
                <FormatDateTimeCompact value={dueAt} />
              </strong>
            </>
          )
        }
        break
      }
      default:
    }

    return dueStatement
  }

  return (
    <>
      {subject === PROCESS_TYPE.AUDIT ? "This audit" : "This process"}
      {after && (
        <>
          {" "}
          will start on{" "}
          <strong>
            <FormatDateCompact value={after} />
          </strong>
        </>
      )}
      {hasTime(after) && (
        <>
          {" "}
          at <strong>{formatScheduleTime(afterInput.date)}</strong>
        </>
      )}
      {getDueStatement()}
      {pinned && ![PROCESS_REPEAT.NEVER, PROCESS_REPEAT.ADHOC].includes(type) && (
        <>
          , will remain <strong>{getPinnedStatement()}</strong>,
        </>
      )}
      {type === PROCESS_REPEAT.NEVER && (
        <>
          {pinned && (
            <>
              , will remain <strong>{getPinnedStatement()}</strong>,
            </>
          )}{" "}
          and <strong>{typeData.label.toLowerCase()}</strong>
        </>
      )}
      {type === PROCESS_REPEAT.ADHOC && (
        <>
          {" "}
          is run on an <strong>ad hoc</strong> basis
          {pinned && (
            <>
              {" "}
              and will remain <strong>{getPinnedStatement()}</strong>
            </>
          )}
        </>
      )}
      {![PROCESS_REPEAT.NEVER, PROCESS_REPEAT.ADHOC, PROCESS_REPEAT.UNSCHEDULED].includes(type) && (
        <>
          {" "}
          and will repeat{" "}
          {frequency > 1 && (
            <strong>
              every {frequency} {typeData.plural.toLowerCase()}
            </strong>
          )}
          {frequency === 1 && (
            <>
              on a <strong>{typeData.label.toLowerCase()}</strong> basis
            </>
          )}
          {selectedWeekdays.length > 0 && (
            <>
              {" "}
              on{" "}
              {selectedWeekdays.map((weekday, index) => (
                <span key={weekday}>
                  {index ? ", " : ""}
                  <strong>
                    {weekday.substring(0, 1).toUpperCase()}
                    {weekday.substring(1)}
                  </strong>
                </span>
              ))}
            </>
          )}
          {selectedMonths.length > 0 && (
            <>
              {" "}
              in{" "}
              {selectedMonths.map((month, index) => (
                <span key={month}>
                  {index ? ", " : ""}
                  <strong>
                    {month.substring(0, 1).toUpperCase()}
                    {month.substring(1)}
                  </strong>
                </span>
              ))}
            </>
          )}
        </>
      )}
      {type === PROCESS_REPEAT.UNSCHEDULED && (
        <>
          {" "}
          is <strong>unscheduled</strong>
        </>
      )}
      .
    </>
  )
}

export { ProcessScheduleStatement }
