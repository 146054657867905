import React, { useEffect, useState } from "react"

const IntervalRenderer = ({ delay, children }) => {
  const [value, setValue] = useState(false)

  useEffect(() => {
    if (delay) {
      const intervalId = setInterval(() => {
        setValue(!value)
      }, delay)
      return () => {
        clearInterval(intervalId)
      }
    }
  }, [delay, value])

  if (children) return <>{children()}</>
  return null
}

export { IntervalRenderer }
