import React from "react"
import { Paper, Grid, Typography, Divider, Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { RowBox } from "../Boxes"
import { AdminOnlyLabel } from "../Headings"

const useStyles = makeStyles((theme) => ({
  item: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  itemWithFooter: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(1)}px`,
    marginBottom: theme.spacing(1),
  },
  chart: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontSize: 16,
    lineHeight: "20px",
    marginBottom: theme.spacing(1),
    wordBreak: "break-word",
  },
  detail: {
    fontSize: 14,
    lineHeight: "20px",
    color: theme.palette.text.primary,
    marginBottom: 10,
  },
  text: {
    flex: 1,
  },
  adminOnly: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
  },
  footer: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px 0`,
    fontSize: 14,
    fontWeight: "600",
    lineHeight: "16px",
    height: 42,
    color: theme.palette.grey[400],
  },
}))

const ChartCard = ({ chart, title, detail, adminOnly, footerPrompt, className = "", ...rest }) => {
  const classes = useStyles()

  return (
    <Paper elevation={3} className={`${adminOnly ? classes.itemWithFooter : classes.item} ${className}`} {...rest}>
      <Grid container alignItems="center">
        <Grid item className={classes.chart}>
          {chart}
        </Grid>
        <Grid item className={classes.text}>
          {title && (
            <Grid item>
              <Typography variant="h2" className={classes.title}>
                {title}
              </Typography>
            </Grid>
          )}
          {detail && <Grid className={classes.detail}>{detail}</Grid>}
        </Grid>
      </Grid>
      {adminOnly && (
        <div className={classes.adminOnly}>
          <Divider />
          <RowBox className={classes.footer}>
            {footerPrompt && <Box>{footerPrompt}</Box>}
            <Box ml="auto">
              <AdminOnlyLabel />
            </Box>
          </RowBox>
        </div>
      )}
    </Paper>
  )
}

export { ChartCard }
