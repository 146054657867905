import { useQuery, gql, useLazyQuery } from "@apollo/client"
import { SHIFT_FIELDS } from "./useQueryUserShifts"

const TIMESHEET_FIELDS = `
  id
  startedAt
  endedAt
  status
  secondsOnBreak
  group { id name }
`

const TIMESHEET_QUERY = gql`
  query Timesheet($location: ID!) {
    timesheet {
      ${TIMESHEET_FIELDS}
    }
    shift: userShiftNow(location: $location) {
      ${SHIFT_FIELDS}
    }
    nextShift: userShiftNext {
      ${SHIFT_FIELDS}
    }
  }
`

const useQueryTimesheet = (options) => useQuery(TIMESHEET_QUERY, options)

const useLazyQueryTimesheet = (options) => useLazyQuery(TIMESHEET_QUERY, options)

export { useQueryTimesheet, useLazyQueryTimesheet, TIMESHEET_FIELDS }
