import { useMutation, gql } from "@apollo/client"

const DELETE_LOCATION_MUTATION = gql`
  mutation DeleteLocation($id: ID!) {
    location(id: $id) {
      delete
    }
  }
`
const useMutationDeleteLocation = () => useMutation(DELETE_LOCATION_MUTATION)

export { useMutationDeleteLocation }
