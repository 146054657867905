import React, { useState, useRef, useEffect, useCallback } from "react"
import PickerDialog from "../PickerDialog"

import { AssignerDisplay } from "./AssignerDisplay"

const Assigner = ({
  type = "multiple",
  icon,
  title,
  description,
  placeholder,
  pickerConfig,
  openPickerAfterInit,
  onInitComplete,
  topBorder,
  bottomBorder,
  disabled,
  forceNoValue,
  tooltip,
  cy,
}) => {
  const { mapPickedToText, onPickedChanged, onComplete, ...restPickerConfig } = pickerConfig

  const [showPicker, setShowPicker] = useState(openPickerAfterInit || false)
  const [value, setValue] = useState("")
  const [picked, setPicked] = useState([])
  const [subPicked, setSubPicked] = useState([])
  const initRef = useRef(false)

  const handleExternalAddPicked = useCallback(
    (newValue) => {
      setPicked((state) => [...state, newValue])
    },
    [setPicked]
  )

  useEffect(() => {
    if (!initRef.current) {
      initRef.current = true
      pickerConfig.initialPicked && setPicked(pickerConfig.initialPicked)
      pickerConfig.initialSubPicked && setSubPicked(pickerConfig.initialSubPicked)
      if (onInitComplete) onInitComplete(handleExternalAddPicked)
    }
  }, [pickerConfig.initialPicked, pickerConfig.initialSubPicked, onInitComplete, handleExternalAddPicked])

  useEffect(() => {
    if (!value && picked && (picked.length > 0 || type === "single")) {
      setValue(mapPickedToText({ picked, subPicked }))
    }
  }, [value, picked, mapPickedToText, subPicked, type])

  const handlePickedChanged = (data) => {
    if (pickerConfig.pickBehaviour !== "complete") {
      setPicked(data.picked)
      setSubPicked(data.subPicked)
      setValue(mapPickedToText(data))
    }
    if (onPickedChanged) onPickedChanged(data)
  }

  const handleComplete = (data) => {
    if (pickerConfig.pickBehaviour === "complete") {
      setPicked(data.picked)
      setSubPicked(data.subPicked)
      setValue(mapPickedToText(data))
    }
    if (onComplete) onComplete(data)
  }

  return (
    <>
      <AssignerDisplay
        icon={icon}
        title={title}
        value={forceNoValue ? null : value}
        placeholder={placeholder}
        onClick={() => setShowPicker(true)}
        topBorder={topBorder}
        bottomBorder={bottomBorder}
        disabled={disabled}
        tooltip={tooltip}
        cy={cy}
      />
      <PickerDialog
        open={showPicker}
        onClose={() => setShowPicker(false)}
        title={title}
        description={description}
        initialPicked={pickerConfig.initialPicked}
        initialSubPicked={pickerConfig.initialSubPicked}
        disableInitialPicked={pickerConfig.disableInitialPicked}
        data={pickerConfig.data}
        config={{
          mapPickedToText,
          onPickedChanged: handlePickedChanged,
          onComplete: handleComplete,
          ...restPickerConfig,
        }}
        cy={cy ? `${cy}-dialog` : null}
      />
    </>
  )
}

export { Assigner }
