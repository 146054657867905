import React from "react"
import { LocationSwitcher } from "../../components"

const Locations = ({ location }) => {
  const from = location?.state?.from || "/"

  return <LocationSwitcher open isInline from={from} />
}

export default Locations
