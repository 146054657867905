import { useQuery, useLazyQuery, gql } from "@apollo/client"

const REPORTUSERSJOBSOVERVIEW_FIELDS = `
  id
  firstName
  lastName
  jobs {
    process
    name
    stepsCompleted
    stepsOverdue
    stepsSkipped    
    stepsUncompleted    
  }
`

const REPORTUSERSJOBSOVERVIEW_QUERY = gql`
  query ReportUsersJobsOverview($users: [ID!]!, $locations: [ID!]!, $groups: [ID!], $gt: ScheduleDateInput!, $lt: ScheduleDateInput) {
    reportUsersJobsOverview(users: $users, locations: $locations, groups: $groups, gt: $gt, lt: $lt)
    {
      ${REPORTUSERSJOBSOVERVIEW_FIELDS}
    } 
  }
`

const useQueryReportUsersJobsOverview = (options) =>
  useQuery(REPORTUSERSJOBSOVERVIEW_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

const useLazyQueryReportUsersJobsOverview = (options) =>
  useLazyQuery(REPORTUSERSJOBSOVERVIEW_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

export { useQueryReportUsersJobsOverview, useLazyQueryReportUsersJobsOverview }
