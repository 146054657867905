import React, { useRef, useState } from "react"
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from "@impelsys/material-ui-pickers"
import { AccessTime, Alarm } from "@material-ui/icons"
import { useTheme } from "@material-ui/styles"
import { Grid, useMediaQuery } from "@material-ui/core"
import MomentUtils from "@date-io/moment"
import moment from "moment-timezone"
import { t } from "@lingui/macro"
import { useDateUtils } from "../../utils"

const DateTimeInputs = ({
  dateLabel = "Date",
  timeLabel = "Time",
  dueLabel = "Due",
  value,
  dueValue,
  onChange,
  onDueChange,
  emptyTimeLabel = "Nothing selected",
  emptyDueLabel = "Nothing selected",
  hasDue = false,
  dateRequired = false,
  cy = "DateTimeInputs",
}) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const { hasTime } = useDateUtils()

  const dateRef = useRef()
  const timeRef = useRef()
  const dueRef = useRef()

  const [openDate, setOpenDate] = useState(false)
  const [openTime, setOpenTime] = useState(false)
  const [openDue, setOpenDue] = useState(false)

  const momentValue = moment(value)

  const isAllDay = value && momentValue.hours() + momentValue.minutes() === 0

  const handleDateChange = (date) => {
    if (hasTime(value)) {
      onChange(
        (date ? moment(date) : moment())
          .startOf("day")
          .add(moment(value).hours(), "hours")
          .add(moment(value).minutes(), "minutes"),
      )
    } else {
      onChange(date ? date.startOf("day") : moment().startOf("day"))
    }
    setTimeout(() => dateRef.current.blur())
  }
  const handleTimeChange = (date) => {
    onChange(
      !date && value
        ? moment(value).startOf("day")
        : (value ? moment(value) : moment())
            .startOf("day")
            .add(moment(date).hours(), "hours")
            .add(moment(date).minutes(), "minutes"),
    )
    setTimeout(() => timeRef.current.blur())
  }
  const handleDueChange = (date) => {
    const dateMoment = moment(date)
    const minutes = date && moment(date).diff(moment(date).startOf("day"), "minute")
    onDueChange &&
      onDueChange(
        date && moment(dateMoment.format("HH:mm"), "HH:mm").isBefore(moment(momentValue.format("HH:mm"), "HH:mm"))
          ? momentValue
          : date
            ? moment(value).startOf("day").add(minutes, "minutes")
            : null,
      )
    setTimeout(() => dueRef.current.blur())
  }

  const handleDateClick = () => {
    setOpenDate(true)
  }
  const handleDateClose = () => {
    setOpenDate(false)
  }
  const handleTimeClick = () => {
    setOpenTime(true)
  }
  const handleTimeClose = () => {
    setOpenTime(false)
  }
  const handleDueClick = () => {
    setOpenDue(true)
  }
  const handleDueClose = () => {
    setOpenDue(false)
  }

  const smCols = hasDue ? 4 : 6

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container spacing={xs ? 2 : 1}>
        <Grid item xs={12} sm={smCols}>
          <KeyboardDatePicker
            label={dateLabel}
            value={value}
            open={openDate}
            inputVariant="outlined"
            minutesStep={5}
            InputAdornmentProps={{ position: "end", style: { marginRight: -16 } }}
            format={xs ? "DD/MMM/Y" : "Do MMMM Y"}
            onChange={handleDateChange}
            onClick={handleDateClick}
            onClose={handleDateClose}
            clearable
            clearLabel={t`Today`}
            showTodayButton={false}
            fullWidth
            okLabel=""
            autoOk
            inputRef={dateRef}
            data-cy={`${cy}-date`}
            required={dateRequired}
          />
        </Grid>
        <Grid item xs={12} sm={smCols}>
          <KeyboardTimePicker
            label={timeLabel}
            value={isAllDay ? null : value}
            open={openTime}
            inputVariant="outlined"
            minutesStep={5}
            InputAdornmentProps={{ position: "end", style: { marginRight: -16 } }}
            onChange={handleTimeChange}
            onClick={handleTimeClick}
            onClose={handleTimeClose}
            clearable
            clearLabel={t`Start of day`}
            keyboardIcon={<AccessTime />}
            fullWidth
            emptyLabel={value && emptyTimeLabel}
            inputRef={timeRef}
            data-cy={`${cy}-time`}
          />
        </Grid>
        {hasDue && (
          <Grid item xs={12} sm={smCols}>
            <KeyboardTimePicker
              label={dueLabel}
              value={dueValue}
              open={openDue}
              inputVariant="outlined"
              minutesStep={5}
              InputAdornmentProps={{ position: "end", style: { marginRight: -16 } }}
              onChange={handleDueChange}
              onClick={handleDueClick}
              onClose={handleDueClose}
              clearable
              clearLabel={t`None`}
              keyboardIcon={<Alarm />}
              fullWidth
              emptyLabel={emptyDueLabel}
              inputRef={dueRef}
              minDate={value}
              disabled={!value}
              data-cy={`${cy}-due`}
            />
          </Grid>
        )}
      </Grid>
    </MuiPickersUtilsProvider>
  )
}

export { DateTimeInputs }
