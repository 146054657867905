import { useQuery, gql, useLazyQuery } from "@apollo/client"

const TRAINING_MODULE_FIELDS = `
  id
  name
  knowledges {
    id
    title
    category { id name }
  }
  users { id fullName }
  groups { id name }
`

const TRAINING_COURSES_QUERY = gql`
  query TrainingModules($trainingCourses: [ID!]) {
    trainingModules(trainingCourses: $trainingCourses) {
      ${TRAINING_MODULE_FIELDS}
    }
  }
`

const useQueryTrainingModules = (options) => useQuery(TRAINING_COURSES_QUERY, options)

const useLazyQueryTrainingModules = (options) => useLazyQuery(TRAINING_COURSES_QUERY, options)

export { useQueryTrainingModules, useLazyQueryTrainingModules, TRAINING_MODULE_FIELDS }
