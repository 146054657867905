import { makeStyles } from "@material-ui/core"
import React from "react"
import { ColumnBox } from ".."

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 30,
    lineHeight: "30px",
    fontWeight: "600",
    color: ({ color }) => color,
    opacity: ({ opacity }) => opacity,
  },
  small: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    fontWeight: 400,
  },
}))

const NumberDisplay = ({
  value,
  total,
  color = "black",
  opacity = 1,
  alignItems = "center",
  justifyContent = "center",
  subject,
  ...rest
}) => {
  const classes = useStyles({ color, opacity })

  return (
    <ColumnBox alignItems={alignItems} justifyContent={justifyContent} className={classes.root} {...rest}>
      {!!total && (
        <>
          {Number(parseFloat((value / total) * 100).toFixed(1))}%
          <small className={classes.small}>
            {value ? value.toLocaleString() : "No"} {subject}
          </small>
        </>
      )}
      {!total && (
        <>
          {value || 0}
          <small className={classes.small}>{subject}</small>
        </>
      )}
    </ColumnBox>
  )
}

export { NumberDisplay }
