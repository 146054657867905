import React, { useState, useEffect } from "react"
import Config from "react-global-configuration"
import { Grid, TextField, Box, Divider, MenuItem, makeStyles, Typography, Button } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import { GetApp, TabletMacOutlined } from "@material-ui/icons"
import { useApolloClient } from "@apollo/client"
import plist from "plist"
import FileDownload from "js-file-download"
import { t } from "@lingui/macro"
import {
  DEVICE_TYPE,
  queryRegisteringDevice,
  useMutationCreateDevice,
  useMutationPairDevice,
  useMutationRegisterDevice,
  useMutationUpdateDevice,
} from "../../data"
import { mapToIds, toId, useFormUtils, useReportUtils } from "../../utils"
import { CreatorActions, CreatorMaster } from "../Creators"
import { useAuth } from "../../services"
import { useSnackbar } from "../SnackbarProvider"
import { OutlinedSelect } from "../OutlinedSelect"
import { Icon } from "../Icon"
import { InfoSection } from "../Viewers"
import {
  ExpansionPanel,
  GroupOutlinedSelect,
  IdentityProviderOutlinedSelect,
  ColumnBox,
  PaperBox,
  RowBox,
  TileButton,
  ReadOnlyOutlinedInput,
} from ".."

const useStyles = makeStyles((theme) => ({
  passcodeTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(1),
  },
  passcode: {
    textAlign: "center",
    fontSize: 24,
    fontWeight: 700,
    width: 250,
  },
  list: {
    paddingInlineStart: theme.spacing(2),
  },
}))

const DeviceInfo = ({ name, os }) => (
  <PaperBox mb={4}>
    <InfoSection
      title="Device"
      value={
        <RowBox>
          <Box mr={1}>
            <Icon name={os} />
          </Box>
          <Box>{name}</Box>
        </RowBox>
      }
    />
  </PaperBox>
)

const ManagedSwitcher = ({ managed, pair, onChange }) => {
  const { hasFeature } = useAuth()

  const handleChange = (value) => {
    onChange && onChange(value)
  }

  const hasFeatureIdentityProviders = hasFeature("identity_providers")

  return (
    <>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ColumnBox flexGrow={1}>
              <TileButton
                title="Standard device"
                description={`Register an iOS or Android tablet for multiple user access by user PIN${
                  hasFeatureIdentityProviders ? " or Single Sign-on Identity Provider" : ""
                }`}
                active={!managed}
                onClick={() => handleChange(false)}
                style={{ width: "100%" }}
                disabled={!!pair}
                data-cy="TileButton-unmanaged"
              />
            </ColumnBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <TileButton
              title="Enterprise managed device"
              description="Register an MDM managed device for access by email/password or Single Sign-on Identity Provider, with Shared iPad support"
              active={managed}
              onClick={() => handleChange(true)}
              style={{ width: "100%" }}
              data-cy="TileButton-managed"
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>
    </>
  )
}

const StandardDeviceStepInfo = ({ hasFeatureManaged }) => {
  const classes = useStyles()

  return (
    <Box mb={3}>
      <Alert severity="info">
        <AlertTitle>{hasFeatureManaged ? "To register a standard device" : "To register a device"}</AlertTitle>
        <>
          <ol className={classes.list}>
            <li>
              Open the Operandio app on the device and tap <strong>Register as kiosk device</strong> for tablets, or on
              mobile <strong>More options</strong> then <strong>Register as shared device</strong>
            </li>
            <li>Enter the device registration code shown on the screen</li>
            <li>
              Enter additional details as requested, then click <strong>Register device</strong> to complete the
              registration
            </li>
            <li>The device will confirm the registration, and enable kiosk / shared mode</li>
          </ol>
        </>
      </Alert>
    </Box>
  )
}

const EnterpriseDeviceStepInfo = () => {
  const classes = useStyles()

  return (
    <Box mb={3}>
      <Alert severity="info">
        <AlertTitle>Enterprise device configuration</AlertTitle>
        <>
          <p>A device profile has been successfully created. Follow these steps to complete the configuration.</p>
          <p>
            The device token is <strong>ONLY DISPLAYED ONCE</strong> here and cannot be retrieved after you close this
            window.
          </p>
          <ol className={classes.list}>
            <li>Download the details shown below to a secure location.</li>
            <li>
              In your MDM server management console, enter the downloaded details against the target device for the
              Operandio app (com.operandio.app) and push them to the device.
            </li>
            <li>Relaunch Operandio on the target device to pick up the registration</li>
            <li>
              We recommend you expunge any copies of the details stored outside of your MDM server once registration is
              successful
            </li>
          </ol>
        </>
      </Alert>
    </Box>
  )
}

const GroupsSelection = ({ value, onChange, ...rest }) => {
  const handleChange = (target) => {
    onChange && onChange(target)
  }

  return (
    <ColumnBox width="100%" mb={3}>
      <GroupOutlinedSelect value={value} onChange={handleChange} multiple />
      <Box mt={0.25} mx={1.75}>
        <small>Filter Jobs for this device for only the Areas of Work specified where the user also has access.</small>
      </Box>
    </ColumnBox>
  )
}

const IdentityProviderSelection = ({ value, onChange, ...rest }) => {
  const handleChange = (target) => {
    onChange && onChange(target)
  }

  return (
    <>
      <IdentityProviderOutlinedSelect value={value} onChange={handleChange} {...rest} />
      <Box mt={0.25} mx={1.75}>
        <small>
          Configure single sign-on for the device. Users accessing the app will be directed through their external sign
          in as required. This can only be set when registering a device and cannot be updated afterwards without
          re-registration.
        </small>
      </Box>
    </>
  )
}

const PairInfo = ({ id, identityProvider, token }) => {
  const handleDownloadPlist = () => {
    FileDownload(
      plist.build({
        device_id: id,
        device_identityprovider_id: identityProvider || "",
        device_token: token,
      }),
      `${id}.plist`,
      "application/x-plist",
    )
  }

  const handleDownloadJson = () => {
    FileDownload(
      JSON.stringify(
        {
          device_id: id,
          device_identityprovider_id: identityProvider,
          device_token: token,
        },
        null,
        2,
      ),
      `${id}.json`,
      "application/json",
    )
  }

  return (
    <Box mb={1}>
      <Box mb={2}>
        <ReadOnlyOutlinedInput label="device_id" labelWidth={75} value={id} hasCopy fullWidth />
      </Box>
      {identityProvider && (
        <Box mb={2}>
          <ReadOnlyOutlinedInput
            label="device_identityprovider_id"
            labelWidth={200}
            value={identityProvider}
            hasCopy
            fullWidth
          />
        </Box>
      )}
      <Box mb={2}>
        <ReadOnlyOutlinedInput label="device_token" labelWidth={100} value={token} hasCopy fullWidth />
      </Box>

      <RowBox pb={1} px={1} justifyContent="flex-end">
        <Box mr={1}>
          <Button variant="contained" onClick={handleDownloadPlist}>
            Download as .plist&nbsp;
            <GetApp style={{ fontSize: 18 }} />
          </Button>
        </Box>
        <Button variant="contained" onClick={handleDownloadJson}>
          Download as .json&nbsp;
          <GetApp style={{ fontSize: 18 }} />
        </Button>
      </RowBox>
    </Box>
  )
}

const DeviceCreator = ({ open, onClose, edit, isInline }) => {
  const {
    device: {
      register: { passcodeLength },
    },
  } = Config.get("auth")
  const classes = useStyles()
  const { isValid } = useFormUtils()
  const { filterChange } = useReportUtils()
  const client = useApolloClient()
  const snackbar = useSnackbar()
  const {
    refresh,
    settings: { locations },
    hasFeature,
  } = useAuth(client)
  const [createDevice, { loading: createDeviceLoading }] = useMutationCreateDevice()
  const [updateDevice, { loading: updateDeviceLoading }] = useMutationUpdateDevice()
  const [registerDevice, { loading: registerDeviceLoading }] = useMutationRegisterDevice()
  const [pairDevice, { loading: pairDeviceLoading }] = useMutationPairDevice()

  const [managed, setManaged] = useState(false)
  const [os, setOs] = useState("")
  const [label, setLabel] = useState("")
  const [type, setType] = useState("")
  const [location, setLocation] = useState("")
  const [groups, setGroups] = useState(["all"])
  const [identityProvider, setIdentityProvider] = useState("none")
  const [timer, setTimer] = useState("")
  const [passcode, setPasscode] = useState("")
  const [passcodeDevice, setPasscodeDevice] = useState(null)

  const [pairingLoading, setPairingLoading] = useState(false)
  const [pair, setPair] = useState(null)

  const [expand, setExpand] = useState(false)

  const isEdit = edit != null

  useEffect(() => {
    if (edit) {
      setOs(edit.os)
      setLabel(edit.label)
      setType(edit.type)
      setLocation(toId(edit.location))
      setGroups(edit.groups?.length ? mapToIds(edit.groups) : ["all"])
      setIdentityProvider(edit.identityProvider ? toId(edit.identityProvider) : "none")
      setTimer(edit.timer || "")

      if (edit.groups?.length || edit.identityProvider) setExpand(true)
    }
  }, [edit])

  const handleClose = () => {
    handleResetState()
    if (onClose) onClose(true)
  }

  const handleLabelChange = (event) => {
    setLabel(event.target.value)
  }

  const handleLocationChange = (event) => {
    setLocation(event.target.value)
  }

  const handlePasscodeChange = async (event) => {
    setPasscodeDevice(null)
    const value = event.target.value.toUpperCase()
    setPasscode(value)
    if (value.length === passcodeLength) {
      const result = await queryRegisteringDevice(client, value)
      if (result && result.data) {
        const device = result.data.registeringDevice
        setPasscodeDevice(device)
        setLabel(device.label)
        setType(device.type)
      } else snackbar.showMessage({ message: "No matching device, check code", icon: <TabletMacOutlined /> })
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (formValid()) {
      try {
        const groupsValue = groups.includes("all") ? null : groups
        const timerValue = timer && identityProvider === "none" ? Number.parseInt(timer) : null
        if (isEdit) {
          await updateDevice({
            variables: { id: toId(edit), label, location, groups: groupsValue, timer: timerValue },
          })
        } else {
          await createDevice({
            variables: {
              label,
              location,
              groups: groupsValue,
              identityProvider: identityProvider === "none" ? null : identityProvider,
              passcode,
              timer: timerValue,
            },
          })
        }
        refresh(client)
        handleClose(event)
        snackbar.showMessage({ message: "Device updated", icon: <TabletMacOutlined /> })
      } catch (error) {
        snackbar.showMessage({ message: error.message })
      }
    }
  }

  const handleManagedContinue = async () => {
    if (formValid()) {
      setPairingLoading(true)
      const groupsValue = groups.includes("all") ? null : groups

      // register device
      const registerResult = await registerDevice({
        variables: {
          name: label,
          os,
          type,
          installationId: "",
        },
      })

      const { passcode: managedPasscode } = registerResult.data.registerDevice

      // create device
      await createDevice({
        variables: {
          label,
          location,
          groups: groupsValue,
          identityProvider: identityProvider === "none" ? null : identityProvider,
          passcode: managedPasscode,
          managed: true,
        },
      })

      // pair device
      const pairResult = await pairDevice({
        variables: {
          passcode: managedPasscode,
        },
      })

      setPair({
        ...pairResult.data.pairDevice,
      })

      setPairingLoading(false)
    }
  }

  const handleOsChanged = (event) => {
    setOs(event.target.value)
  }

  const handleTypeChanged = (event) => {
    setType(event.target.value)
  }

  const handleGroupsChanged = (event) => {
    setGroups([...filterChange(groups, event)])
  }

  const handleIdentityProviderChanged = (event) => {
    setIdentityProvider(event.target.value)
  }

  const handleTimerChange = (event) => {
    setTimer(event.target.value)
  }

  const handleResetState = () => {
    setManaged(false)
    setOs("")
    setType(null)
    setPasscode("")
    setPasscodeDevice(null)
    setLabel("")
    setLocation("")
    setGroups(["all"])
    setIdentityProvider("none")
    setTimer("")
    setPair(null)
  }

  const formValid = () =>
    managed ? isValid(os, type, label, location) : isValid(label, location) && (edit || isValid(passcode))

  const isFormValid = formValid()

  const loading =
    createDeviceLoading || updateDeviceLoading || registerDeviceLoading || pairDeviceLoading || pairingLoading

  const hasFeatureManaged = hasFeature("device_managed")
  const hasFeatureIdentityProviders = hasFeature("identity_providers")
  const hasFeatureGroups = hasFeature("device_groups")
  const hasUnmanagedAdvanced = !managed && (hasFeatureIdentityProviders || hasFeatureGroups)
  const hasIdentityProvider = identityProvider !== "none"

  const form = (
    <>
      {!edit && hasFeatureManaged && <ManagedSwitcher managed={managed} pair={pair} onChange={setManaged} />}

      {!edit && !managed && (
        <>
          <StandardDeviceStepInfo hasFeatureManaged={hasFeatureManaged} />
          <Box mb={3}>
            <Typography className={classes.passcodeTitle}>
              Enter the {passcodeLength}-character registration code displayed on the device
            </Typography>
            <RowBox justifyContent="center">
              <TextField
                variant="outlined"
                name="name"
                value={passcode}
                onChange={handlePasscodeChange}
                inputProps={{ className: classes.passcode, maxLength: passcodeLength }}
                required
                disabled={passcodeDevice !== null}
              />
            </RowBox>
          </Box>
          {passcodeDevice && (
            <Box mb={3}>
              <Divider />
            </Box>
          )}
        </>
      )}

      {(edit || passcodeDevice || (managed && !pair)) && (
        <>
          {passcodeDevice && <DeviceInfo {...passcodeDevice} />}

          {managed && (
            <>
              <Box mb={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <OutlinedSelect
                      label="Operating system"
                      required
                      native={false}
                      value={os}
                      onChange={handleOsChanged}
                      data-cy="OutlinedSelect-os"
                    >
                      <MenuItem value="ios" data-cy="MenuItem-os-ios">
                        Apple iOS
                      </MenuItem>
                    </OutlinedSelect>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <OutlinedSelect
                      label="Device type"
                      required
                      native={false}
                      value={type}
                      onChange={handleTypeChanged}
                      data-cy="OutlinedSelect-type"
                    >
                      <MenuItem value={DEVICE_TYPE.PHONE} data-cy={`MenuItem-type-${DEVICE_TYPE.PHONE}`}>
                        Phone
                      </MenuItem>
                      <MenuItem value={DEVICE_TYPE.TABLET} data-cy={`MenuItem-type-${DEVICE_TYPE.TABLET}`}>
                        Tablet
                      </MenuItem>
                    </OutlinedSelect>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}

          <Box mb={3}>
            <TextField
              variant="outlined"
              fullWidth
              label="Label"
              name="label"
              value={label}
              onChange={handleLabelChange}
              required
              data-cy="TextField-label"
            />
          </Box>
          <Box mb={hasUnmanagedAdvanced && (!managed || !hasFeatureIdentityProviders) ? 1 : 3}>
            <OutlinedSelect
              label="Location"
              native={false}
              value={location}
              onChange={handleLocationChange}
              required
              data-cy="OutlinedSelect-location"
            >
              {locations.map((value, index) => (
                <MenuItem key={toId(value)} value={toId(value)} data-cy={`MenuItem-location-${index}`}>
                  {value.name}
                </MenuItem>
              ))}
            </OutlinedSelect>
          </Box>

          {managed && hasFeatureGroups && (
            <Box mb={3}>
              <GroupsSelection value={groups} onChange={handleGroupsChanged} multiple />
            </Box>
          )}

          {managed && hasFeatureIdentityProviders && (
            <Box mb={3}>
              <IdentityProviderSelection
                value={identityProvider}
                onChange={handleIdentityProviderChanged}
                disabled={!!edit}
              />
            </Box>
          )}

          {hasUnmanagedAdvanced && (
            <Box mb={3}>
              <ExpansionPanel title="Advanced options" expanded={expand} onChange={() => setExpand(!expand)}>
                <ColumnBox width="100%">
                  {hasFeatureGroups && type === DEVICE_TYPE.TABLET && (
                    <GroupsSelection value={groups} onChange={handleGroupsChanged} multiple />
                  )}

                  {hasFeatureIdentityProviders && (
                    <IdentityProviderSelection
                      value={identityProvider}
                      onChange={handleIdentityProviderChanged}
                      disabled={!!edit}
                    />
                  )}

                  {!hasIdentityProvider && (
                    <RowBox mt={3}>
                      <TextField
                        label="Sign-out timer"
                        type="number"
                        variant="outlined"
                        value={timer}
                        onChange={handleTimerChange}
                        inputProps={{ "data-cy": "TextField-", min: 20, max: 300 }}
                        style={{ width: 150 }}
                      />
                      <Box ml={1}>seconds</Box>
                    </RowBox>
                  )}
                </ColumnBox>
              </ExpansionPanel>
            </Box>
          )}
        </>
      )}

      {pair && (
        <>
          <EnterpriseDeviceStepInfo />
          <Box mb={3}>
            <PairInfo {...pair} />
          </Box>
        </>
      )}

      <Box mb={1}>
        <Divider />
      </Box>

      {!managed && (
        <CreatorActions
          submitLabel={edit ? t`Save device` : t`Register device`}
          onClose={handleClose}
          onSubmit={handleSubmit}
          disableSubmit={!isFormValid}
          submitLoading={loading}
        />
      )}

      {managed && !pair && (
        <CreatorActions
          id="DeviceCreator-CreatorActions"
          submitLabel={edit ? t`Save device` : t`Continue`}
          onClose={handleClose}
          onSubmit={handleManagedContinue}
          disableSubmit={!isFormValid}
          submitLoading={loading}
        />
      )}

      {managed && pair && (
        <CreatorActions
          id="DeviceCreator-CreatorActions"
          submitLabel={t`I confirm I have saved these details, close window`}
          onSubmit={handleClose}
        />
      )}
    </>
  )

  return (
    <CreatorMaster
      open={open}
      title={edit ? t`Edit device` : t`Register device`}
      form={form}
      isEdit={isEdit}
      isInline={isInline}
      onClose={handleClose}
    />
  )
}

export { DeviceCreator }
