import { useState } from "react"
import { useUserLocalStorage } from "."

export const useDraft = (key, initialValue) => {
  const [draft, set, remove] = useUserLocalStorage(`${key}_draft`, initialValue)
  const [unchanged, setUnchanged] = useState(true)

  const setDraft = (value) => {
    setUnchanged(false)
    set(value)
  }

  const removeDraft = () => {
    setUnchanged(false)
    remove()
  }

  return [draft, setDraft, removeDraft, unchanged]
}
