import { InputBase } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { useEffect, useState } from "react"

const FONT_WIDTH = 12

const useStyles = makeStyles((theme) => ({
  input: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "14px",
    padding: theme.spacing(0.5, 1),
    minWidth: FONT_WIDTH * 2,
    color: theme.palette.tertiary.main,
    border: `2px solid ${theme.palette.tertiary.main}`,
    borderRadius: 4,
    "&:hover": {
      background: `rgba(${theme.palette.tertiary.mainRgb},.10)`,
      borderRadius: 4,
    },
    "&:focus": {
      background: `rgba(${theme.palette.tertiary.mainRgb},.10)`,
      borderRadius: 4,
    },
  },
}))

const InlineOutlinedInput = ({ value, ...props }) => {
  const classes = useStyles()

  const [width, setWidth] = useState(String(value).length * FONT_WIDTH)

  useEffect(() => {
    const valueLength = String(value).length
    setWidth((valueLength + 1) * FONT_WIDTH)
  }, [value])

  return (
    <InputBase
      classes={classes}
      value={value}
      {...props}
      inputProps={{
        style: { width: `${width}px` },
      }}
    />
  )
}

export { InlineOutlinedInput }
