import React, { useEffect, useRef, useState } from "react"
import {
  Box,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import { Trans, t } from "@lingui/macro"
import { CreatorActions, DialogTitleCloser, TextDivider, DateTimeInputs, TileButton, Checkbox } from "../.."
import {
  PROCESS_REPEAT,
  PROCESS_SCHEDULE_UNTIL,
  PROCESS_SCHEDULE_DUE,
  processScheduleInitialState as none,
  PROCESS_SCHEDULE_AFTER,
} from "../../../data"
import { disableBackdropClick, useDateUtils, useFormUtils, useMountEffect, useProcessUtils } from "../../../utils"
import { OutlinedSelect } from "../../OutlinedSelect"
import { FlexBox, RowBox, ColumnBox } from "../../Boxes"
import { StaticButton } from "../../Buttons"
import { ProcessScheduleStatement } from "./ProcessScheduleStatement"

const typename = "__typename"

const initialMaxPeriods = {
  maxUntilPeriod: 999,
  maxDuePeriod: 999,
}

const useStyles = makeStyles((theme) => ({
  untilPeriod: {
    width: 100,
    textAlign: "center",
  },
  alert: {
    backgroundColor: "#FFF4E5",
    "& .MuiAlert-icon": {
      color: theme.palette.warning.amber,
    },
  },
}))

const ProcessDueAfter = ({ type, period, onChange, maxPeriod }) => {
  const classes = useStyles()
  const periodRef = useRef(null)

  const handleTypeChange = (event) => {
    onChange &&
      onChange({
        type: event.target.value,
        period,
      })
  }

  const handlePeriodChange = (event) => {
    onChange &&
      onChange({
        type,
        period: event.target.value,
      })
  }

  return (
    <RowBox flexGrow={1}>
      <FlexBox flexGrow={1}>
        <OutlinedSelect label={t`Due`} value={type} native={false} onChange={handleTypeChange} fullWidth>
          <MenuItem value={PROCESS_SCHEDULE_DUE.NONE}>At no set time</MenuItem>
          <MenuItem value={PROCESS_SCHEDULE_DUE.HOURS}>After several hours</MenuItem>
          <MenuItem value={PROCESS_SCHEDULE_DUE.DAYS}>After several days</MenuItem>
        </OutlinedSelect>
      </FlexBox>
      {type !== PROCESS_SCHEDULE_DUE.NONE && (
        <RowBox ml={1}>
          <TextField
            type="number"
            label={
              <>
                {type === PROCESS_SCHEDULE_DUE.MINUTES && "Minutes"}
                {type === PROCESS_SCHEDULE_DUE.HOURS && "Hours"}
                {type === PROCESS_SCHEDULE_DUE.DAYS && "Days"}
              </>
            }
            variant="outlined"
            value={period}
            onChange={handlePeriodChange}
            required
            inputRef={periodRef}
            InputProps={{ inputProps: { "data-cy": "TextField-pinned-for", min: 0 } }}
            className={classes.untilPeriod}
            error={period !== "" && (period <= 0 || period > maxPeriod)}
          />
        </RowBox>
      )}
    </RowBox>
  )
}

const ProcessScheduleDialog = ({ value, subject, open, onClose, onChange }) => {
  const classes = useStyles()
  const { isValid } = useFormUtils()
  const { scheduleDateToMoment, momentToScheduleDate } = useDateUtils()
  const { canScheduleOnlyPinPeriodTypes, canScheduleHaveNextInstanceUntilType, isPeriodProcessUntilType } =
    useProcessUtils()
  const pinnedMinutesRef = useRef(null)
  const [{ maxUntilPeriod, maxDuePeriod }, setMaxPeriods] = useState({ ...initialMaxPeriods })

  const [name, setName] = useState("")
  const afterType = PROCESS_SCHEDULE_AFTER.DATE
  const [afterDate, setAfterDate] = useState(null)
  const [dueType, setDueType] = useState(PROCESS_SCHEDULE_DUE.NONE)
  const [duePeriod, setDuePeriod] = useState("")
  const [dueReminders, setDueReminders] = useState(false)
  const [untilType, setUntilType] = useState(PROCESS_SCHEDULE_UNTIL.NONE)
  const [untilPeriod, setUntilPeriod] = useState("")

  const [type, setType] = useState(PROCESS_REPEAT.DAILY)
  const [frequency, setFrequency] = useState(1)
  const [weekdays, setWeekdays] = useState({ ...none.weekdays })
  const [months, setMonths] = useState(["all"])

  const canOnlyPinPeriodTypes = canScheduleOnlyPinPeriodTypes(type)
  const hasUntilType = untilType !== PROCESS_SCHEDULE_UNTIL.NONE
  const hasDueType = dueType !== PROCESS_SCHEDULE_DUE.NONE
  const hasUntilTypeNextInstance = canScheduleHaveNextInstanceUntilType(type)
  const hasUntilPeriod = isPeriodProcessUntilType(untilType)

  useMountEffect(() => {
    setName(value.name)
    setAfterDate(scheduleDateToMoment(value.after.date))
    setUntilType(value.until.type)
    setUntilPeriod(value.until.period)
    setDueType(value.due.type)
    setDueReminders(value.due.reminders)
    setDuePeriod(value.due.period || "")
    if (value.repeat) {
      let selectedMonths = Object.keys(value.repeat.months).filter(
        (month) => month !== typename && value.repeat.months[month],
      )
      if (!selectedMonths.length) selectedMonths = ["all"]

      setType(value.repeat.type)
      setFrequency(value.repeat.frequency)
      setWeekdays((prev) => {
        Object.keys(prev).forEach((weekday) => (prev[weekday] = value.repeat.weekdays[weekday]))
        return { ...prev }
      })
      setMonths(selectedMonths)
    }
  })

  useEffect(() => {
    if ([PROCESS_REPEAT.ADHOC, PROCESS_REPEAT.NEVER, PROCESS_REPEAT.UNSCHEDULED].includes(type)) {
      setMaxPeriods({
        ...initialMaxPeriods,
      })
      return
    }
    if (type === PROCESS_REPEAT.DAILY) {
      setMaxPeriods({
        maxUntilPeriod: untilType === PROCESS_SCHEDULE_UNTIL.HOURS ? 24 * frequency : 1 * frequency,
        maxDuePeriod: dueType === PROCESS_SCHEDULE_UNTIL.HOURS ? 24 * frequency : 1 * frequency,
      })
    }
    if (type === PROCESS_REPEAT.WEEKLY) {
      setMaxPeriods({
        maxUntilPeriod: untilType === PROCESS_SCHEDULE_UNTIL.HOURS ? 24 * 7 * frequency : 7 * frequency,
        maxDuePeriod: dueType === PROCESS_SCHEDULE_UNTIL.HOURS ? 24 * 7 * frequency : 7 * frequency,
      })
    }
    if (type === PROCESS_REPEAT.FORTNIGHTLY) {
      setMaxPeriods({
        maxUntilPeriod: untilType === PROCESS_SCHEDULE_UNTIL.HOURS ? 24 * 14 * frequency : 14 * frequency,
        maxDuePeriod: dueType === PROCESS_SCHEDULE_UNTIL.HOURS ? 24 * 14 * frequency : 14 * frequency,
      })
    }
    if (type === PROCESS_REPEAT.MONTHLY) {
      setMaxPeriods({
        maxUntilPeriod: untilType === PROCESS_SCHEDULE_UNTIL.HOURS ? 24 * 31 * frequency : 31 * frequency,
        maxDuePeriod: dueType === PROCESS_SCHEDULE_UNTIL.HOURS ? 24 * 31 * frequency : 31 * frequency,
      })
    }
    if (type === PROCESS_REPEAT.YEARLY) {
      setMaxPeriods({
        maxUntilPeriod: untilType === PROCESS_SCHEDULE_UNTIL.HOURS ? 24 * 366 * frequency : 366 * frequency,
        maxDuePeriod: dueType === PROCESS_SCHEDULE_UNTIL.HOURS ? 24 * 366 * frequency : 366 * frequency,
      })
    }
  }, [type, frequency, untilType, dueType])

  const clearDates = () => {
    setAfterDate(null)
  }

  const clearWeekdays = () => {
    setWeekdays({ ...none.weekdays })
  }
  const clearMonths = () => {
    setMonths(["all"])
  }
  const clearRestrictedUntilTypes = (newType) => {
    if (
      (!canScheduleHaveNextInstanceUntilType(newType) && untilType === PROCESS_SCHEDULE_UNTIL.NEXT_INSTANCE) ||
      (canScheduleOnlyPinPeriodTypes(newType) && !isPeriodProcessUntilType(untilType))
    ) {
      setUntilType(PROCESS_SCHEDULE_UNTIL.NONE)
    }
  }

  const compileData = () => {
    const data = {
      name,
      after: {
        type: afterType,
        date: momentToScheduleDate(afterDate),
      },
      due: {
        type: dueType,
        period: duePeriod ? Number(duePeriod) : null,
        reminders: type !== PROCESS_REPEAT.UNSCHEDULED ? dueReminders : false,
      },
      until: {
        type: untilType,
        period: untilPeriod ? Number(untilPeriod) : null,
      },
      repeat: {
        type,
        frequency,
        weekdays,
        months: { ...none.months },
      },
    }

    months.filter((month) => month !== "all").forEach((month) => (data.repeat.months[month] = true))

    return data
  }

  const handleChange = () => {
    onChange && onChange(compileData())
  }

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  const handleAfterChanged = (date) => setAfterDate(date)

  const handleTypeChange = (newType) => {
    setType(newType)
    if (newType === PROCESS_REPEAT.ADHOC) {
      clearDates()
    }
    setFrequency(1)
    setUntilPeriod("")
    clearWeekdays()
    if (newType === PROCESS_REPEAT.UNSCHEDULED) {
      clearDates()
      setFrequency(0)
      setUntilType(PROCESS_SCHEDULE_UNTIL.NONE)
    }
    if (
      [PROCESS_REPEAT.NEVER, PROCESS_REPEAT.YEARLY, PROCESS_REPEAT.ADHOC, PROCESS_REPEAT.UNSCHEDULED].includes(newType)
    ) {
      clearMonths()
    }
    clearRestrictedUntilTypes(newType)
  }

  const handleFrequencyChange = (event) => {
    setFrequency(event.target.value)
  }

  const handleMonthsChange = ({ target: { value: targetValues } }) => {
    if (months.includes("all") && targetValues.length > 1) {
      setMonths(targetValues.filter((targetValue) => targetValue !== "all"))
    } else if ((!months.includes("all") && targetValues.includes("all")) || !targetValues.length) {
      setMonths(["all"])
    } else {
      setMonths(targetValues)
    }
  }

  const handleWeekdayClick = (weekday) => {
    if (type === PROCESS_REPEAT.WEEKLY) {
      const newWeekdays = { ...weekdays, [weekday]: !weekdays[weekday] }
      if (!Object.keys(newWeekdays).some((key) => !newWeekdays[key])) {
        setType(PROCESS_REPEAT.DAILY)
        setWeekdays({ ...none.weekdays })
        clearRestrictedUntilTypes(PROCESS_REPEAT.DAILY)
        return
      }
    }

    setWeekdays((prev) => {
      prev[weekday] = !prev[weekday]
      return { ...prev }
    })
  }

  const handleUntilTypeChange = (event) => {
    if (!event) {
      const newDefaultUntilType = canOnlyPinPeriodTypes
        ? PROCESS_SCHEDULE_UNTIL.HOURS
        : PROCESS_SCHEDULE_UNTIL.COMPLETED
      const newHasUntilType = !hasUntilType
      setUntilType(hasUntilType ? PROCESS_SCHEDULE_UNTIL.NONE : newDefaultUntilType)
      if (newHasUntilType && isPeriodProcessUntilType(newDefaultUntilType)) {
        setTimeout(() => pinnedMinutesRef.current.focus())
      }
      if (!newHasUntilType && untilPeriod) {
        setUntilPeriod("")
      }
      return
    }
    const newValue = event.target.value
    setUntilType(newValue)

    if (isPeriodProcessUntilType(newValue)) {
      setTimeout(() => pinnedMinutesRef.current.focus())
    }
  }

  const handleUntilPeriodChange = (event) => {
    setUntilPeriod(event.target.value)
  }

  const handleDueChange = (newValue) => {
    setDueType(newValue.type)
    setDuePeriod(newValue.period)
  }

  const handleDueRemindersChange = () => {
    setDueReminders(!dueReminders)
  }

  const isUntilCompleted = () => {
    if (
      [PROCESS_SCHEDULE_UNTIL.NONE, PROCESS_SCHEDULE_UNTIL.NEXT_INSTANCE, PROCESS_SCHEDULE_UNTIL.COMPLETED].includes(
        untilType,
      )
    ) {
      return true
    }
    return !!untilPeriod
  }

  const isDueCompleted = () => {
    if (dueType === PROCESS_SCHEDULE_DUE.NONE) {
      return true
    }
    return !!duePeriod
  }

  const formCompleted = () => {
    if (!name) {
      return false
    }
    if ([PROCESS_REPEAT.ADHOC, PROCESS_REPEAT.UNSCHEDULED].includes(type)) {
      return true
    }
    if (type === PROCESS_REPEAT.NEVER) {
      return isUntilCompleted() && isValid(afterDate)
    }
    return isValid(afterDate) && isUntilCompleted() && isDueCompleted()
  }

  const formValid = () => {
    if (!isValid(name)) {
      return false
    }
    if (hasUntilType && isPeriodProcessUntilType(untilType)) {
      if (!untilPeriod) {
        return false
      }
      if (untilPeriod <= 0) {
        return false
      }
      if (untilPeriod > maxUntilPeriod) {
        return false
      }
    }
    if (hasDueType) {
      if (!duePeriod) {
        return false
      }
      if (duePeriod <= 0) {
        return false
      }
      if (duePeriod > maxDuePeriod) {
        return false
      }
    }
    if (type === PROCESS_REPEAT.NEVER) {
      return isValid(afterDate)
    }
    if ([PROCESS_REPEAT.ADHOC, PROCESS_REPEAT.UNSCHEDULED].includes(type)) {
      return true
    }
    if (type === PROCESS_REPEAT.WEEKLY) {
      // If repeat every 1 week then at least one weekday need to be selected
      if (frequency === 1 && !Object.values(weekdays).some((val) => val === true)) {
        return false
      }
    }
    return isValid(type, afterDate)
  }

  const isFormValid = formValid()

  const isFormCompleted = formCompleted()

  return (
    <Dialog
      open={open}
      onClose={disableBackdropClick(onClose)}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
      top={16}
      style={{ position: "fixed", top: 16 }}
    >
      <DialogTitle id="form-dialog-title">
        <Trans>Set schedule</Trans>
        <DialogTitleCloser onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <TextField
            label={t`Name`}
            variant="outlined"
            value={name}
            onChange={handleNameChange}
            required
            fullWidth
            inputProps={{ "data-cy": "TextField-name" }}
          />
        </Box>

        <TextDivider />

        <Box my={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <TileButton
                title={<Trans>Recurring</Trans>}
                description={t`To be completed on a regular basis (e.g. daily, weekly)`}
                active={![PROCESS_REPEAT.NEVER, PROCESS_REPEAT.ADHOC, PROCESS_REPEAT.UNSCHEDULED].includes(type)}
                onClick={() => handleTypeChange(PROCESS_REPEAT.DAILY)}
                style={{ width: "100%" }}
                data-cy="TileButton-recurring"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TileButton
                title={<Trans>Ad hoc</Trans>}
                description={t`To be completed only as needed by staff`}
                active={type === PROCESS_REPEAT.ADHOC}
                onClick={() => handleTypeChange(PROCESS_REPEAT.ADHOC)}
                style={{ width: "100%" }}
                data-cy="TileButton-adhoc"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TileButton
                title={<Trans>One off</Trans>}
                description={t`To be completed only once on the chosen date`}
                active={type === PROCESS_REPEAT.NEVER}
                onClick={() => handleTypeChange(PROCESS_REPEAT.NEVER)}
                style={{ width: "100%" }}
                data-cy="TileButton-oneoff"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TileButton
                title={<Trans>Unscheduled</Trans>}
                description={t`This ${subject} will have no fixed schedule`}
                active={type === PROCESS_REPEAT.UNSCHEDULED}
                onClick={() => handleTypeChange(PROCESS_REPEAT.UNSCHEDULED)}
                style={{ width: "100%" }}
                data-cy="TileButton-unscheduled"
              />
            </Grid>
          </Grid>
        </Box>

        {![PROCESS_REPEAT.NEVER, PROCESS_REPEAT.ADHOC, PROCESS_REPEAT.UNSCHEDULED].includes(type) && (
          <>
            <TextDivider />

            <Box my={2}>
              <Grid container spacing={1} direction="row" alignItems="center">
                <Grid item xs={12} sm={6}>
                  <Trans>Repeat this {subject} every:</Trans>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <RowBox>
                    <Box mr={1}>
                      {[
                        PROCESS_REPEAT.DAILY,
                        PROCESS_REPEAT.WEEKLY,
                        // PROCESS_REPEAT.MONTHLY,
                        PROCESS_REPEAT.YEARLY,
                      ].includes(type) && (
                        <OutlinedSelect value={frequency} native={false} onChange={handleFrequencyChange}>
                          {type === PROCESS_REPEAT.DAILY &&
                            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((item) => (
                              <MenuItem key={item} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                          {type === PROCESS_REPEAT.WEEKLY &&
                            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
                              <MenuItem key={item} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                          {type === PROCESS_REPEAT.MONTHLY &&
                            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map(
                              (item) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              ),
                            )}
                          {type === PROCESS_REPEAT.YEARLY &&
                            [1, 2, 3].map((item) => (
                              <MenuItem key={item} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                        </OutlinedSelect>
                      )}
                    </Box>
                    <OutlinedSelect
                      value={type}
                      native={false}
                      onChange={(event) => handleTypeChange(event.target.value)}
                    >
                      <MenuItem value={PROCESS_REPEAT.DAILY}>Day(s)</MenuItem>
                      <MenuItem value={PROCESS_REPEAT.WEEKLY}>Week(s)</MenuItem>
                      <MenuItem value={PROCESS_REPEAT.FORTNIGHTLY}>Fortnight</MenuItem>
                      <MenuItem value={PROCESS_REPEAT.MONTHLY}>Month</MenuItem>
                      <MenuItem value={PROCESS_REPEAT.YEARLY}>Year(s)</MenuItem>
                    </OutlinedSelect>
                  </RowBox>
                </Grid>
              </Grid>
            </Box>

            {type === PROCESS_REPEAT.WEEKLY && frequency === 1 && (
              <Box mb={3}>
                <RowBox mb={1}>On these days: *</RowBox>
                <Grid container spacing={2}>
                  {Object.keys(none.weekdays).map((weekday) => (
                    <Grid key={weekday} item xs>
                      <StaticButton
                        text={`${weekday.substring(0, 1).toUpperCase()}${weekday.substring(1, 3)}`}
                        active={weekdays[weekday] === true}
                        onClick={() => handleWeekdayClick(weekday)}
                        style={{ width: "100%" }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}

            {type !== PROCESS_REPEAT.YEARLY && (
              <Box>
                <OutlinedSelect
                  label={t`Repeat in these months`}
                  placeholder={t`All months`}
                  value={months}
                  native={false}
                  multiple
                  onChange={handleMonthsChange}
                >
                  <MenuItem value="all">All</MenuItem>
                  {Object.keys(none.months).map((month) => (
                    <MenuItem key={month} value={month}>{`${month.substring(0, 1).toUpperCase()}${month.substring(
                      1,
                    )}`}</MenuItem>
                  ))}
                </OutlinedSelect>
              </Box>
            )}
          </>
        )}

        {![PROCESS_REPEAT.ADHOC, PROCESS_REPEAT.UNSCHEDULED].includes(type) && (
          <Box mt={2}>
            <TextDivider />

            <Box my={2}>
              <DateTimeInputs
                value={afterDate}
                onChange={handleAfterChanged}
                dateLabel={t`Start date`}
                dateRequired
                timeLabel={t`Available from`}
                clearLabel={t`Start of day`}
                emptyTimeLabel={t`Start of day`}
              />
            </Box>
          </Box>
        )}

        {type !== PROCESS_REPEAT.UNSCHEDULED && (
          <Box my={2}>
            <TextDivider />

            <Grid container spacing={1} direction="row" alignItems="center">
              <Grid item xs={12} sm={6}>
                <RowBox mt={2} flexGrow={1}>
                  <FlexBox flexGrow={1}>
                    <OutlinedSelect
                      label={t`Active`}
                      value={untilType}
                      native={false}
                      onChange={handleUntilTypeChange}
                      fullWidth
                    >
                      <MenuItem value={PROCESS_SCHEDULE_UNTIL.NONE}>Until the end of the day</MenuItem>
                      {!canOnlyPinPeriodTypes && (
                        <MenuItem value={PROCESS_SCHEDULE_UNTIL.COMPLETED}>Until it's completed</MenuItem>
                      )}
                      <MenuItem value={PROCESS_SCHEDULE_UNTIL.HOURS}>For several hours</MenuItem>
                      <MenuItem value={PROCESS_SCHEDULE_UNTIL.DAYS}>For several days</MenuItem>
                      {hasUntilTypeNextInstance && (
                        <MenuItem value={PROCESS_SCHEDULE_UNTIL.NEXT_INSTANCE}>
                          Until the next scheduled occurrence
                        </MenuItem>
                      )}
                    </OutlinedSelect>
                  </FlexBox>
                  {hasUntilPeriod && (
                    <RowBox ml={1}>
                      <TextField
                        type="number"
                        label={
                          <>
                            {untilType === PROCESS_SCHEDULE_UNTIL.MINUTES && "Minutes"}
                            {untilType === PROCESS_SCHEDULE_UNTIL.HOURS && "Hours"}
                            {untilType === PROCESS_SCHEDULE_UNTIL.DAYS && "Days"}
                          </>
                        }
                        variant="outlined"
                        value={untilPeriod}
                        onChange={handleUntilPeriodChange}
                        maxLength={3}
                        required
                        inputRef={pinnedMinutesRef}
                        InputProps={{
                          inputProps: { "data-cy": "TextField-pinned-for", min: 0, max: 999, maxLength: 3 },
                        }}
                        className={classes.untilPeriod}
                        error={untilPeriod !== "" && (untilPeriod <= 0 || untilPeriod > maxUntilPeriod)}
                      />
                    </RowBox>
                  )}
                </RowBox>
              </Grid>

              <Grid item xs={12} sm={6}>
                <RowBox mt={2} flexGrow={1}>
                  <ProcessDueAfter
                    type={dueType}
                    period={duePeriod}
                    onChange={handleDueChange}
                    maxPeriod={maxDuePeriod}
                  />
                </RowBox>
              </Grid>
              {dueType !== "none" && duePeriod && (
                <Grid item xs={12}>
                  <ColumnBox pt={1} pb={2}>
                    <Checkbox
                      type="label"
                      color="primary"
                      label={t`Send reminder notifications for this schedule`}
                      checked={dueReminders}
                      onChange={handleDueRemindersChange}
                    />
                    <Typography variant="caption">
                      <Trans>Sends a reminder notification to all assigned users prior to the job coming due.</Trans>
                    </Typography>
                  </ColumnBox>

                  <Collapse in={dueReminders}>
                    <Box>
                      <Alert severity="warning" className={classes.alert}>
                        <AlertTitle>
                          <Trans>Heads up</Trans>
                        </AlertTitle>
                        <Trans>
                          This will send notifications to all users in the assigned Areas of Work with reminder
                          notifications enabled. To prevent overwhelming your team with notifications, it's not
                          recommended to use this for jobs that repeat daily.
                        </Trans>
                      </Alert>
                    </Box>
                  </Collapse>
                </Grid>
              )}
            </Grid>
          </Box>
        )}

        <Box mt={2} mb={1}>
          <TextDivider />
        </Box>

        <Collapse
          in={isFormValid && ![PROCESS_REPEAT.NEVER, PROCESS_REPEAT.ADHOC, PROCESS_REPEAT.UNSCHEDULED].includes(type)}
        >
          <Box my={1}>
            <Alert icon={false} severity="info">
              <ProcessScheduleStatement schedule={compileData()} subject={subject} />
            </Alert>
          </Box>
        </Collapse>

        {type === PROCESS_REPEAT.ADHOC && (
          <Box my={1}>
            <Alert severity="info">
              <AlertTitle>
                <Trans>About ad hoc schedules</Trans>
              </AlertTitle>
              <Trans>
                This {subject} has no fixed date or time, and will only run when required by staff. Only the staff you
                have assigned to this {subject} will be able to run it.
              </Trans>
            </Alert>
          </Box>
        )}

        {type === PROCESS_REPEAT.UNSCHEDULED && (
          <Box my={1}>
            <Alert severity="info">
              <AlertTitle>
                <Trans>About unscheduled processes and audits</Trans>
              </AlertTitle>
              <Trans>
                If you have nested processes or audits that aren't used elsewhere in your business, you can set them to
                unscheduled. They will only run at the same time as their parent audit or process, and they won't show
                up in your Active Jobs list.
              </Trans>
            </Alert>
          </Box>
        )}

        <Collapse in={!isFormValid && isFormCompleted}>
          <Box my={1}>
            <Alert severity="error">
              <Trans>
                A valid schedule can't be created from the options selected. You'll need to update these before you can
                set this schedule.
              </Trans>
            </Alert>
          </Box>
        </Collapse>

        <CreatorActions
          id="ProcessScheduleAssigner-CreatorActions"
          subject={t`Process`}
          cancelLabel={t`Back`}
          submitLabel={t`Set schedule`}
          onClose={onClose}
          onSubmit={handleChange}
          disableSubmit={!isFormValid}
        />
      </DialogContent>
    </Dialog>
  )
}

export default ProcessScheduleDialog
