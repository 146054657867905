import { gql } from "@apollo/client"

const ACTION_FIELDS = gql`
  fragment ActionFields on Action {
    id
    title
    content
    users {
      id
      fullName
    }
    groups {
      id
      name
    }
    uploads {
      id
      key
      fileName
      fileGroup
      fileSize
    }
    location {
      id
      name
    }
    job {
      id
      title
      availableFrom
      displayName
      process {
        id
        name
      }
      location {
        id
        name
        timeZone
      }
    }
    jobStep {
      id
      step
      name
      description
    }
    priority
    status
    statusAt
    statusBy {
      id
      fullName
      firstName
      lastName
      avatar {
        key
      }
    }
    note
    isOverdue @client
    dueAt
    author {
      id
      firstName
      lastName
      fullName
      avatar {
        key
      }
    }
    comments {
      id
      content
      uploads {
        id
        key
        fileName
        fileGroup
        fileSize
      }
      author {
        id
        firstName
        lastName
        avatar {
          key
        }
      }
      createdAt
    }
    tags {
      id
      name
    }
    createdAt
    updatedAt
  }
`

export { ACTION_FIELDS }
