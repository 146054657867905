import React from "react"
import { makeStyles } from "@material-ui/styles"
import Config from "react-global-configuration"
import { Box, Hidden } from "@material-ui/core"
import { NavLink } from "react-router-dom"
import pluralize from "pluralize"
import { Trans, t, Plural } from "@lingui/macro"
import { ActionCard } from "./ActionCard"
import { useProcessUtils, toId, useDateUtils, useStringUtils, getResponseStepsCount } from "../../utils"
import { AdornmentIcon } from ".."
import { AuthorTools } from "../Author"
import { TemplateCategoryChip } from "../Chips"
import { RowBox } from "../Boxes"
import { FormatDateCompact, FormatScheduleTime } from "../Format"
import { PROCESS_REPEAT, PROCESS_SCHEDULE_UNTIL } from "../../data"
import { ActionCardInfo } from "./ActionCardInfo"
import { Tooltip } from "../Tooltip"

const useStyles = makeStyles((theme) => ({
  info: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },
  rightChildren: {
    height: 40,
    overflow: "visible",
  },
  locations: {
    fontWeight: "600",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  location: {
    fontWeight: "500",
  },
}))

const ProcessTemplateActionCard = ({
  process,
  set,
  category,
  onEdit,
  onCopy,
  onDisable,
  onDelete,
  onCategoryClick,
  hideActions = false,
}) => {
  const { name, steps, inactive, locations } = process
  const classes = useStyles()
  const { truncateInMiddle } = useStringUtils()
  const { getNavigateToTemplateLink } = useProcessUtils()
  const { featuredCategory: featuredId } = Config.get("templates")

  const handleEdit = () => {
    if (onEdit) onEdit(process)
  }
  const handleCopy = () => {
    if (onCopy) onCopy(process)
  }
  const handleDisable = () => {
    if (onDisable) onDisable(process)
  }
  const handleDelete = () => {
    if (onDelete) onDelete(process)
  }
  const handleCategoryClick = (id) => {
    if (onCategoryClick) onCategoryClick(id)
  }

  const hasCategories = process.categories?.length > 0

  const statusPrefix = inactive ? "Inactive for" : `Active ${locations?.length > 1 ? "in" : "for"}`

  const setMismatched = (inactive && set.startsWith("active")) || (!inactive && set.startsWith("inactive"))

  const responseStepsCount = getResponseStepsCount(steps)

  const suffix = <Plural value={responseStepsCount} one="step" other="steps" />

  return (
    <ActionCard
      title={name}
      cardProps={{ component: NavLink, to: { pathname: getNavigateToTemplateLink(process, set, category, "view") } }}
      isScheduled={setMismatched}
      rightChildren={
        set !== "library" &&
        !hideActions && (
          <Box
            mr="auto"
            display="flex"
            flexDirection="row"
            alignSelf="center"
            alignItems="center"
            className={classes.rightChildren}
          >
            <AuthorTools
              item={process}
              author={toId(process.author)}
              subject="process"
              admins={{ edit: "process_update_all", delete: "process_delete_all" }}
              disableLabel={inactive ? t`Make active` : t`Make inactive`}
              onEdit={handleEdit}
              onCopy={handleCopy}
              onDisable={handleDisable}
              onDelete={inactive && onDelete && handleDelete}
            />
          </Box>
        )
      }
      footer={
        <>
          <RowBox>
            <Hidden smUp>
              <Box mr={1}>
                <AdornmentIcon name="steps" tooltip={`${responseStepsCount} ${suffix}`} />
              </Box>
            </Hidden>
            <Hidden xsDown>
              <AdornmentIcon name="steps" tooltip={t`Steps`} />
              <Box ml={1} mr={5}>
                {responseStepsCount} {suffix}
              </Box>
            </Hidden>

            <ScheduleInformation process={process} />
          </RowBox>
          <RowBox ml="auto">
            {!hasCategories && (
              <>
                {Boolean(locations.length) && (
                  <Box display="flex" alignItems="center" flexDirection="row">
                    <Box mr={0.5}>{statusPrefix}</Box>
                    {locations.length === 1 && (
                      <Box className={classes.location}>{truncateInMiddle(locations[0].name, 25)}</Box>
                    )}
                    {locations.length > 1 && (
                      <Tooltip title={locations.map((location) => location.name).join(", ")}>
                        <Box className={classes.locations}>
                          {locations.length} location{locations.length > 1 ? "s" : ""}
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                )}
                {!locations.length && <Box>{statusPrefix} 0 locations</Box>}
              </>
            )}
            {hasCategories &&
              [...process.categories]
                .sort((a, b) => {
                  if (a.id === featuredId) return -1
                  if (b.id === featuredId) return 1
                  return a.name.localeCompare(b.name)
                })
                .map((item) => (
                  <Box key={item.id} ml={0.5}>
                    <TemplateCategoryChip category={item} onClick={handleCategoryClick} />
                  </Box>
                ))}
          </RowBox>
        </>
      }
    />
  )
}

const ScheduleInformation = ({ process }) => {
  const { formatTime, hasScheduleDate, scheduleDateToMoment } = useDateUtils()
  const { getRepeatDisplayText, getRepeatDisplayShortText, getProcessUntilTypeDescription } = useProcessUtils()

  if (process.categories?.length > 0) {
    return null
  }

  if (process.schedules.length > 1) {
    const enabledCount = process.schedules.filter((item) => !item.inactive).length
    const statement = `${enabledCount || "No"} ${pluralize("schedules", enabledCount)} enabled`

    return (
      <>
        <Hidden smUp>
          <ActionCardInfo>
            <AdornmentIcon name="schedule" tooltip={statement} />
          </ActionCardInfo>
        </Hidden>
        <Hidden xsDown>
          <AdornmentIcon name="schedule" tooltip={statement} />
          <Box ml={1} mr={5} title={statement}>
            {statement}
          </Box>
        </Hidden>
      </>
    )
  }

  const schedule = process.schedules[0]

  const untilTypeDescription = getProcessUntilTypeDescription(schedule)

  return (
    <>
      {schedule.repeat?.type !== PROCESS_REPEAT.NEVER && (
        <>
          <Hidden smUp>
            <ActionCardInfo>
              <AdornmentIcon name="repeat" tooltip={<>Repeats: {getRepeatDisplayText(schedule.repeat)}</>} />
            </ActionCardInfo>
          </Hidden>
          <Hidden xsDown>
            <AdornmentIcon name="repeat" tooltip={t`Repeats: ${getRepeatDisplayText(schedule.repeat)}`} />
            <Box ml={1} mr={5} title={getRepeatDisplayText(schedule.repeat)}>
              {getRepeatDisplayShortText(schedule.repeat)}
            </Box>
          </Hidden>
        </>
      )}
      {schedule.repeat?.type === PROCESS_REPEAT.NEVER && hasScheduleDate(schedule.after) && (
        <>
          <Hidden smUp>
            <ActionCardInfo>
              <AdornmentIcon
                name="date"
                tooltip={
                  <Trans>
                    Repeats: <FormatDateCompact value={schedule.after} />
                  </Trans>
                }
              />
            </ActionCardInfo>
          </Hidden>
          <Hidden xsDown>
            <AdornmentIcon name="date" tooltip={t`Repeats`} />
            <Box ml={1} mr={5}>
              <FormatDateCompact value={schedule.after} />
            </Box>
          </Hidden>
        </>
      )}

      {hasScheduleDate(schedule.due) && (
        <>
          <Hidden smUp>
            <AdornmentIcon name="due" tooltip={<>Due: {formatTime(scheduleDateToMoment(schedule.due))}</>} />
          </Hidden>
          <Hidden xsDown>
            <AdornmentIcon name="due" tooltip={t`Due`} />
            <Box ml={1} mr={5}>
              <FormatScheduleTime value={schedule.due} />
            </Box>
          </Hidden>
        </>
      )}

      {schedule.until?.type !== PROCESS_SCHEDULE_UNTIL.NONE && (
        <>
          <Hidden smUp>
            <AdornmentIcon name="pinned" tooltip={untilTypeDescription} />
          </Hidden>
          <Hidden xsDown>
            <AdornmentIcon name="pinned" tooltip={t`Pinning`} />
            <RowBox ml={1} mr={5} justifySelf="center">
              {untilTypeDescription}
            </RowBox>
          </Hidden>
        </>
      )}
    </>
  )
}

export { ProcessTemplateActionCard }
