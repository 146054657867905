import { useQuery, useLazyQuery, gql } from "@apollo/client"
import { offsetLimitPagination } from "@apollo/client/utilities"
import moment from "moment"
import { toId } from "../../utils"
import { ACTION_FIELDS } from "./fragments/actionFieldsFragment"
import { offsetLimitLoadMore } from "../shared/offsetLimitLoadMore"
import { ACTION_STATUS } from "../enums"

const ACTIONS_LIMIT = 5

const ACTIONS_QUERY = gql`
  query Actions($filter: ActionsFilterInput, $offset: Int) {
    actions: actionsV2 {
      list(filter: $filter, limit: ${ACTIONS_LIMIT}, offset: $offset) {
        ...ActionFields
      } 
      count(filter: $filter)
    }
  }
  ${ACTION_FIELDS}
`

const actionsTypePolicies = {
  scoped: {
    ActionsQuery: {
      merge(prev, next) {
        return {
          ...(prev || {}),
          ...next,
        }
      },
      fields: {
        list: offsetLimitPagination(["filter"]),
        count: {
          keyArgs: ["filter"],
        },
      },
    },
    Action: {
      fields: {
        isOverdue: {
          read(_, { readField }) {
            const status = readField("status")
            if ([ACTION_STATUS.RESOLVED, ACTION_STATUS.CANCELLED].includes(status)) {
              return false
            }

            const dueAt = readField("dueAt")
            if (dueAt) {
              return moment(dueAt).isBefore(moment())
            }

            return false
          },
        },
      },
    },
  },
}

const ACTION_CREATED_SUBSCRIPTION = gql`
  subscription actionCreated {
    actionCreated {
      ...ActionFields
    }
  }
  ${ACTION_FIELDS}
`
const ACTION_UPDATED_SUBSCRIPTION = gql`
  subscription actionUpdated {
    actionUpdated {
      ...ActionFields
    }
  }
  ${ACTION_FIELDS}
`

const ACTION_DELETED_SUBSCRIPTION = gql`
  subscription actionDeleted {
    actionDeleted
  }
`

const ACTION_COMMENT_CREATED_SUBSCRIPTION = gql`
  subscription actionCommentCreated {
    actionCommentCreated {
      id
      content
      uploads {
        id
        key
        fileName
        fileGroup
        fileSize
      }
      author {
        id
        firstName
        lastName
        avatar {
          key
        }
      }
      action
      createdAt
    }
  }
`

const actionCreatedSubscriptionUpdateQuery = (prev, { subscriptionData }) => {
  if (!subscriptionData.data) return prev
  const { action: newItem } = subscriptionData.data.actionCreated
  const next = JSON.parse(JSON.stringify(prev))
  next.actions.list = [newItem, ...next.actions.list]
  return next
}

const actionDeletedSubscriptionUpdateQuery = (prev, { subscriptionData }) => {
  if (!subscriptionData.data) return prev
  const next = JSON.parse(JSON.stringify(prev))
  next.actions.list = [
    ...prev.actions.list.filter((action) => toId(action) !== toId(subscriptionData.data.actionDeleted)),
  ]
  return next
}

const actionCommentCreatedSubscriptionUpdateQuery = (prev, { subscriptionData }) => {
  if (!subscriptionData.data) return prev
  const newComment = subscriptionData.data.actionCommentCreated
  const next = JSON.parse(JSON.stringify(prev))
  let action
  if (next.actions?.list) {
    action = next.actions.list?.find((a) => toId(a) === toId(newComment.action))
  } else if (next.action && toId(next.action) === toId(newComment.action)) {
    action = next.action
  }
  if (action && !action.comments.find((c) => toId(c) === toId(newComment))) {
    action.comments.push(newComment)
  }
  return next
}

const subscribe = (result) => () => [
  result.subscribeToMore({
    document: ACTION_CREATED_SUBSCRIPTION,
    updateQuery: actionCreatedSubscriptionUpdateQuery,
    onError: (err) => console.log("[useQueryActions][ACTION_CREATED_SUBSCRIPTION]", err),
  }),
  result.subscribeToMore({
    document: ACTION_UPDATED_SUBSCRIPTION,
    onError: (err) => console.log("[useQueryActions][ACTION_UPDATED_SUBSCRIPTION]", err),
  }),
  result.subscribeToMore({
    document: ACTION_DELETED_SUBSCRIPTION,
    updateQuery: actionDeletedSubscriptionUpdateQuery,
    onError: (err) => console.log("[useQueryActions][ACTION_DELETED_SUBSCRIPTION]", err),
  }),
  result.subscribeToMore({
    document: ACTION_COMMENT_CREATED_SUBSCRIPTION,
    updateQuery: actionCommentCreatedSubscriptionUpdateQuery,
    onError: (err) => console.log("[useQueryActions][ACTION_COMMENT_CREATED_SUBSCRIPTION]", err),
  }),
]

const useQueryActions = (options = {}) => {
  const { variables, ...otherOptions } = options
  const result = useQuery(ACTIONS_QUERY, {
    variables: {
      ...variables,
      offset: 0,
      limit: ACTIONS_LIMIT,
    },
    notifyOnNetworkStatusChange: true,
    ...otherOptions,
  })

  return {
    ...result,
    loadMore: offsetLimitLoadMore("actions", ACTIONS_LIMIT, result),
    subscribe: subscribe(result),
  }
}

const useLazyQueryActions = () => {
  const [loadActions, result] = useLazyQuery(ACTIONS_QUERY, {
    variables: {
      offset: 0,
      limit: ACTIONS_LIMIT,
    },
    notifyOnNetworkStatusChange: true,
  })

  return [
    loadActions,
    {
      ...result,
      loadMore: offsetLimitLoadMore("actions", ACTIONS_LIMIT, result),
      subscribe: subscribe(result),
    },
  ]
}

export {
  useQueryActions,
  useLazyQueryActions,
  ACTIONS_QUERY,
  ACTION_CREATED_SUBSCRIPTION,
  ACTION_UPDATED_SUBSCRIPTION,
  ACTION_DELETED_SUBSCRIPTION,
  ACTION_COMMENT_CREATED_SUBSCRIPTION,
  actionsTypePolicies,
  actionCreatedSubscriptionUpdateQuery,
  actionDeletedSubscriptionUpdateQuery,
  actionCommentCreatedSubscriptionUpdateQuery,
}
