import { useMutation, gql } from "@apollo/client"

import { PREP_LIST_FIELDS } from "./fragments/prepListFieldsFragment"

const CREATE_PREP_LIST_MUTATION = gql`
  mutation CreatePrepList($input: PrepListMutationInput!) {
    createPrepList(input: $input) {
      ...PrepListFields
    }
  }
  ${PREP_LIST_FIELDS}
`

const useMutationCreatePrepList = () => {
  return useMutation(CREATE_PREP_LIST_MUTATION, {
    refetchQueries: ["PrepListsCount"],
  })
}

export { useMutationCreatePrepList }
