import React, { useState } from "react"

import { UploaderDropZone } from "../.."
import { UploadingImagesList } from "../../Uploader"

const CreatorContentImage = ({ content, onUpdateContent }) => {
  const [uploading, setUploading] = useState([])
  const [isUploading, setIsUploading] = useState(false)

  const handleBeforeUpload = (items) => {
    setIsUploading(true)
    setUploading(() => items)
  }
  const handleUploadProgress = (items) => {
    setUploading(() => items)
  }
  const handleRemoveUpload = (id) => {
    onUpdateContent("uploads", [...content.uploads.filter((upload) => upload.id !== id)])
  }
  const handleAfterUpload = (items) => {
    onUpdateContent("uploads", [...content.uploads, ...items.map((item) => item.upload)])
  }
  const handleNotUploading = () => {
    setIsUploading(false)
  }

  return (
    <>
      <UploadingImagesList
        uploaded={content.uploads}
        uploading={uploading}
        onRemoveUpload={handleRemoveUpload}
        mb={2}
      />
      <UploaderDropZone
        onBeforeUpload={handleBeforeUpload}
        onProgress={handleUploadProgress}
        onAfterUpload={handleAfterUpload}
        onError={handleNotUploading}
        documents={false}
        disabled={isUploading}
      />
    </>
  )
}

export { CreatorContentImage }
