import React from "react"
import { Box, makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
  },
  border: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    width: "100%",
  },
  content: {
    padding: theme.spacing(0, 1, 0, 1),
    color: theme.palette.text.primary,
  },
}))

const TextDivider = ({ children, ...rest }) => {
  const classes = useStyles()

  return (
    <Box className={classes.container} {...rest}>
      <div className={classes.border} />
      {children && <span className={classes.content}>{children}</span>}
      <div className={classes.border} />
    </Box>
  )
}

export { TextDivider }
