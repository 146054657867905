import { useMutation, gql } from "@apollo/client"
import { PROCESS_FIELDS } from "../processes/fragments/processFieldsFragment"

const USELIBRARYTEMPLATE_MUTATION = gql`
  mutation UseLibraryTemplate($id: ID!) {
    useLibraryTemplate(id: $id) {
      ... on Process {
        __typename
        ...ProcessFields
      }
    }
  }
  ${PROCESS_FIELDS}
`

const useMutationUseLibraryTemplate = () => useMutation(USELIBRARYTEMPLATE_MUTATION)

export { useMutationUseLibraryTemplate }
