import { Box, makeStyles } from "@material-ui/core"
import { FiberManualRecord } from "@material-ui/icons"
import React from "react"
import clsx from "clsx"
import { ErrorBoundary, RowBox } from ".."

const useStyles = makeStyles((theme) => ({
  chartLegendLabel: {
    fontSize: 11,
    whiteSpace: "nowrap",
  },
  chartLegendDot: {
    fontSize: 15,
    fill: ({ color, fillColor }) => fillColor || theme.palette[color]?.main || theme.palette.chart.background,
  },
}))

const HorizontalLegend = ({ children, ...props }) => (
  <ErrorBoundary>
    <RowBox mt={props?.mt || 1} {...props} justifyContent="flex-start">
      {children}
    </RowBox>
  </ErrorBoundary>
)

const HorizontalLegendItem = ({ text, className, fillColor, color }) => {
  const classes = useStyles({ color, fillColor })

  return (
    <RowBox py={0.5} px={1}>
      <FiberManualRecord className={clsx(classes.chartLegendDot, className)} />
      <Box ml={0.5} className={classes.chartLegendLabel}>
        {text}
      </Box>
    </RowBox>
  )
}

export { HorizontalLegend, HorizontalLegendItem }
