import React, { forwardRef } from "react"
import { makeStyles } from "@material-ui/styles"
import { Box } from "@material-ui/core"
import { ErrorBoundary } from "../ErrorBoundary"
import OfflineModeBanner from "../Offline/OfflineModeBanner"

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 20,
    fontSize: 20,
    fontWeight: "600 !important",
    lineHeight: "28px",
    paddingRight: theme.spacing(4),
  },
}))

const InlineTitle = forwardRef(function InlineTitle({ children, boxProps }, ref) {
  const classes = useStyles()

  return (
    <div ref={ref}>
      <ErrorBoundary>
        <OfflineModeBanner />
        <Box className={classes.root} display="flex" alignItems="center" mx={2} {...boxProps}>
          {children}
        </Box>
      </ErrorBoundary>
    </div>
  )
})

export { InlineTitle }
