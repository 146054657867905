import { gql } from "@apollo/client"

const ATTRIBUTE_FIELDS = gql`
  fragment AttributeFields on Attribute {
    id
    location
    integration
    key
    value
  }
`

export { ATTRIBUTE_FIELDS }
