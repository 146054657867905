import React, { Suspense, forwardRef } from "react"
import { LoadingSpinner } from "../LoadingSpinner"

const InnerGroupedListVirtualizer = React.lazy(() => import("./InnerGroupedListVirtualizer"))

const GroupedListVirtualizer = forwardRef(function GroupedListVirtualizer({ items, itemContent, ...props }, ref) {
  if (!items || items.length === 0) {
    return null
  }

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <InnerGroupedListVirtualizer ref={ref} items={items} itemContent={itemContent} {...props} />
    </Suspense>
  )
})

export { GroupedListVirtualizer }
