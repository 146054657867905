import React, { useCallback, useMemo } from "react"
import { Box, Divider, IconButton, ListSubheader, makeStyles, MenuItem, Tooltip } from "@material-ui/core"
import { Trans, t } from "@lingui/macro"
import { useAuth } from "../../services"
import { mapToIds, preventDefault, toId } from "../../utils"
import { OutlinedSelect } from "./OutlinedSelect"
import { RowBox } from "../Boxes"
import { Icon } from "../Icon"
import { useQueryLocations } from "../../data"

const useStyles = makeStyles((theme) => ({
  subheader: {
    backgroundColor: "#ffffff",
    borderTop: `1px solid ${theme.palette.cards.divide.darker}`,
    borderBottom: `1px solid ${theme.palette.cards.divide.darker}`,
  },
  subheaderButton: {
    color: theme.palette.text.secondary,
  },
}))

const LocationOutlinedSelect = ({
  multiple = false,
  hasAll = true,
  mode = "user",
  allLabel = "All locations",
  allValue = "all",
  singleLabel = t`Select location...`,
  onChange,
  onRegionChange,
  value,
  cy = "LocationOutlinedSelect-locations",
  ...rest
}) => {
  const classes = useStyles({ multiple })
  const {
    settings: { locations: userLocations },
    hasFeature,
  } = useAuth()
  const { data: allLocationsData } = useQueryLocations()

  const locations = useMemo(() => {
    if (mode === "admin") {
      return allLocationsData?.locations || []
    }
    return userLocations
  }, [allLocationsData?.locations, mode, userLocations])

  const handleChange = (event) => {
    onChange && onChange(event)
  }

  const handleRegionSelect = useCallback(
    (region) => {
      if (!value || !onRegionChange) {
        return
      }
      const newLocations = mapToIds(
        locations.filter((location) => location.region && toId(location.region) === toId(region)),
      )
      if (!value.includes("all")) {
        newLocations.push(...value.filter((item) => !newLocations.includes(item)))
      }

      onRegionChange && onRegionChange(newLocations)
    },
    [locations, onRegionChange, value],
  )

  const handleRegionClear = useCallback(
    (region) => {
      if (!value || !onRegionChange) {
        return
      }
      const regionLocations = mapToIds(
        locations.filter((location) => location.region && toId(location.region) === toId(region)),
      )
      onRegionChange(value.filter((item) => !regionLocations.includes(item)))
    },
    [locations, onRegionChange, value],
  )

  const items = useMemo(() => {
    if (!hasFeature("regions")) {
      return locations.map((location) => (
        <MenuItem key={toId(location)} value={toId(location)}>
          {location.name}
        </MenuItem>
      ))
    }

    const result = []

    let index = -1
    const nextIndex = () => (index += 1)

    const regions = locations.reduce((acc, location) => {
      if (location.region) {
        acc[location.region.id] = location.region
      }
      return acc
    }, {})

    result.push(
      ...locations
        .filter((location) => !location.region)
        .map((location) => (
          <MenuItem key={toId(location)} value={toId(location)} data-cy={`${cy}-${nextIndex()}`}>
            {location.name}
          </MenuItem>
        )),
    )

    Object.values(regions)
      .sort((a, b) => a.name.localeCompare(b.name))
      .forEach((region) => {
        result.push(
          <ListSubheader key={toId(region)} className={classes.subheader} data-cy={`${cy}-region`}>
            <RowBox alignItems="center" onClick={preventDefault}>
              <Icon name="region" fontSize="small" />
              <Box ml={0.5} mr={2}>
                {region.name}
              </Box>
              {multiple && onRegionChange && (
                <Box ml="auto">
                  <Tooltip title={<Trans>All</Trans>}>
                    <IconButton
                      onClick={() => handleRegionSelect(region)}
                      size="small"
                      className={classes.subheaderButton}
                    >
                      <Icon name="tick" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={<Trans>None</Trans>}>
                    <IconButton
                      onClick={() => handleRegionClear(region)}
                      size="small"
                      className={classes.subheaderButton}
                    >
                      <Icon name="cancel" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </RowBox>
          </ListSubheader>,
        )

        result.push(
          ...locations
            .filter((location) => location.region?.id === region.id)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((location) => (
              <MenuItem key={toId(location)} value={toId(location)} data-cy={`${cy}-${nextIndex()}`}>
                {location.name}
              </MenuItem>
            )),
        )
      })

    return result
  }, [
    classes.subheader,
    classes.subheaderButton,
    cy,
    handleRegionClear,
    handleRegionSelect,
    hasFeature,
    locations,
    multiple,
    onRegionChange,
  ])

  const hasNonRegionLocations = locations.some((location) => !location.region)

  return (
    <OutlinedSelect
      label={multiple ? t`Locations` : t`Location`}
      native={false}
      multiple={multiple}
      onChange={handleChange}
      value={value}
      data-cy={cy}
      {...rest}
    >
      {!multiple && hasAll && <MenuItem value={allValue}>{singleLabel}</MenuItem>}
      {multiple && hasAll && <MenuItem value={allValue}>{allLabel}</MenuItem>}
      {multiple && hasAll && hasNonRegionLocations && <Divider />}
      {items}
    </OutlinedSelect>
  )
}

export { LocationOutlinedSelect }
