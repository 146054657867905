import { useMutation, gql } from "@apollo/client"
import { v4 as uuid } from "uuid"
import { makeOptimisticPlaceholderUser, makeOptimisticTimestamps } from "../makeOptimistic"
import { USER_TRAINING_ASSESSMENT_FIELDS } from "./useQueryUserTrainingAssessments"

const CREATE_OR_UPDATE_USER_TRAINING_ASSESSMENT_MUTATION = gql`
  mutation CreateOrUpdateUserTrainingAssessment(
    $module: ID!
    $user: ID!
    $status: UserTrainingAssessmentStatus
    $comments: String
    $trainedAt: Date
    $trainedBy: ID
  ) {
    createOrUpdateUserTrainingAssessment(
      module: $module
      user: $user
      status: $status
      comments: $comments
      trainedAt: $trainedAt
      trainedBy: $trainedBy
    ) {
      ${USER_TRAINING_ASSESSMENT_FIELDS}
    }
  }
`

const makeCreateOrUpdateUserTrainingAssessmentOptimisticResponse = ({
  userTrainingAssessment: { module, user, status, comments },
}) => ({
  __typename: "Mutation",
  createOrUpdateUserTrainingAssessment: {
    __typename: "UserTrainingAssessment",
    id: uuid(),
    user,
    module,
    status,
    trainedAt: new Date(),
    trainedBy: makeOptimisticPlaceholderUser(),
    comments,
    ...makeOptimisticTimestamps(),
  },
})

const useMutationCreateOrUpdateUserTrainingAssessment = () =>
  useMutation(CREATE_OR_UPDATE_USER_TRAINING_ASSESSMENT_MUTATION)

export { useMutationCreateOrUpdateUserTrainingAssessment, makeCreateOrUpdateUserTrainingAssessmentOptimisticResponse }
