import React, { forwardRef } from "react"
import { Link, makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  link: {
    "&[disabled]": {
      color: theme.palette.text.disabled,
      cursor: "not-allowed",
      "&:hover": {
        textDecoration: "none",
      },
    },
  },
}))

const LinkButton = forwardRef(function LinkButton({ children, className, ...rest }, ref) {
  const classes = useStyles()

  return (
    <Link
      ref={ref}
      component="button"
      className={`OpLinkButton-root${className ? ` ${className}` : ""} ${classes.link}`}
      underline="none"
      {...rest}
    >
      {children}
    </Link>
  )
})

export { LinkButton }
