import React from "react"
import { AddPhotoAlternateOutlined, AlternateEmailOutlined } from "@material-ui/icons"
import { IconButton } from "@material-ui/core"
import { Uploader } from "../Uploader"

const CreatorAtAndUploaders = ({
  onAtClick,
  onBeforeUpload,
  onProgress,
  onItemUploaded,
  onAfterUpload,
  onError,
  disableUploaders,
  disableAt,
}) => (
  <>
    {onAtClick && (
      <IconButton onClick={onAtClick} disabled={disableAt}>
        <AlternateEmailOutlined />
      </IconButton>
    )}
    <Uploader
      onBeforeUpload={onBeforeUpload}
      onProgress={onProgress}
      onItemUploaded={onItemUploaded}
      onAfterUpload={onAfterUpload}
      onError={onError}
      images={false}
      disabled={disableUploaders}
    />
    <Uploader
      onBeforeUpload={onBeforeUpload}
      onProgress={onProgress}
      onItemUploaded={onItemUploaded}
      onAfterUpload={onAfterUpload}
      onError={onError}
      icon={<AddPhotoAlternateOutlined />}
      documents={false}
      disabled={disableUploaders}
    />
  </>
)

export { CreatorAtAndUploaders }
