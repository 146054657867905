export const triggerUserHelpBeacon = (principal, location, settings) => {
  window.Beacon("open")
  window.Beacon("navigate", "/ask/")
  window.Beacon("prefill", {
    subject: `Support request - ${settings.organisation.name}${location && location.name ? ` (${location.name})` : ""}`,
  })
  window.Beacon("identify", {
    name: `${principal.firstName} ${principal.lastName}`,
    email: principal.email,
    company: settings.organisation.name,
    location: location && location.name ? location.name : "unknown location",
  })
}
