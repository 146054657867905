import { Button, Menu, MenuItem, Tooltip, useTheme } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import PopupState, { anchorRef, bindTrigger, bindMenu } from "material-ui-popup-state"
import { useState } from "react"
import { Trans, t } from "@lingui/macro"
import { Icon } from "../Icon"
import downloadUserExport from "../../data/users/downloadUserExport"
import { compileAdminableUsersFilters } from "../../areas/people"
import { useSnackbar } from "../SnackbarProvider"
import { LoadingSpinner } from "../LoadingSpinner"
import { FormatBytes } from "../Format"

const useStyles = makeStyles(() => ({
  button: {
    height: 36.5,
  },
  menu: {
    marginTop: 5,
  },
}))

const DownloadButton = ({ itemCount = 0, allCount = 0, disabled = false, filters, checked }) => {
  const classes = useStyles()
  const theme = useTheme()
  const snackbar = useSnackbar()
  const [loading, setLoading] = useState(false)

  const handleDownload = async (popupState, type) => {
    setLoading(true)
    if (popupState) {
      popupState.close()
    }

    const busySnackbarConfig = {
      message: t`Preparing your download`,
      icon: <Icon name="document" />,
      chipProps: { style: { backgroundColor: theme.palette.scheduled.main } },
      autoHideDuration: 30000,
    }

    snackbar.showMessage(busySnackbarConfig)

    try {
      const ids = Object.keys(checked).filter((key) => checked[key])
      await downloadUserExport(
        { ...compileAdminableUsersFilters("", filters), ids, type },
        {
          onProgress: ({ loaded }) => {
            snackbar.showMessage({
              ...busySnackbarConfig,
              message: (
                <>
                  <Trans>
                    Downloading (<FormatBytes bytes={loaded} backets decimalPlaces={loaded > 1000000 ? 1 : 0} />)
                  </Trans>
                </>
              ),
            })
          },
        },
      )

      snackbar.showMessage({ message: t`Download ready`, icon: <Icon name="download" /> })
    } catch {
      snackbar.showMessage({ message: t`Download failed`, icon: <Icon name="download" />, color: "secondary" })
    }
    if (popupState) popupState.close()
    setLoading(false)
  }

  return (
    <PopupState variant="popover" popupId="download-people">
      {(popupState) => (
        <>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={disabled || loading}
            ref={anchorRef(popupState)}
            {...bindTrigger(popupState)}
          >
            {!loading && <Icon name="download" />}
            {loading && <LoadingSpinner size="24px" delay={false} />}
          </Button>
          <Menu
            {...bindMenu(popupState)}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            className={classes.menu}
          >
            <Tooltip title={!itemCount && t`Select some users first`} disableHoverListener={!!itemCount}>
              <span>
                <MenuItem
                  onClick={() => handleDownload(popupState, "selected")}
                  data-cy="MenuItem-download-selected-user"
                  disabled={!itemCount}
                >
                  {t`Download CSV (${itemCount} selected)`}
                </MenuItem>
              </span>
            </Tooltip>
            <MenuItem
              onClick={() => {
                handleDownload(popupState, "all")
              }}
              data-cy="MenuItem-download-all-user"
            >
              {t`Download CSV (All ${allCount})`}
            </MenuItem>
          </Menu>
        </>
      )}
    </PopupState>
  )
}

export default DownloadButton
