import React from "react"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    fontWeight: "600",
    lineHeight: "20px",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}))

const DrawerHeading = ({ children, className }) => {
  const classes = useStyles()

  return (
    <Typography variant="h2" className={`${classes.title} ${className}`}>
      {children}
    </Typography>
  )
}

export { DrawerHeading }
