import React, { useState } from "react"
import Config from "react-global-configuration"
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core"
import { Security } from "@material-ui/icons"
import { useParams, NavLink } from "react-router-dom"
import { Trans, t } from "@lingui/macro"
import { LogoFull, LoadingSpinner, PaperBox, PinInput } from "../../components"
import { useQueryVerifyInvite, useMutationActivateSelf } from "../../data"
import { useFormUtils } from "../../utils"
import { PublicContainer } from "./PublicContainer"
import { PasswordInput } from "../../components/PasswordInput/PasswordInput"

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 300,
    padding: 0,
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    marginBottom: theme.spacing(5),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    textAlign: "center",
  },
  pin: {
    display: "flex",
    justifySelf: "center",
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  errorText: {
    fontSize: 8,
    color: theme.palette.error.main,
  },
  errorAvatar: {
    backgroundColor: theme.palette.error.light,
  },
}))

const Invite = () => {
  const classes = useStyles()
  const { pinLength } = Config.get("auth")
  const { isValid } = useFormUtils()
  const { id, token } = useParams()
  const [activateSelf, { loading: activateSelfLoading }] = useMutationActivateSelf()
  const { loading, data, error } = useQueryVerifyInvite({ variables: { id, token } })

  const [activated, setActivated] = useState(false)
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [pin, setPin] = useState("")

  const handlePasswordChange = (value) => {
    setPassword(value)
  }

  const handleSubmit = async () => {
    if (formValid()) {
      await activateSelf({
        variables: {
          id,
          token,
          password,
          pin,
        },
      })
      setActivated(true)
    }
  }

  const formValid = () =>
    isValid(password, confirmPassword, pin) && password === confirmPassword && pin.length === pinLength

  const isFormValid = formValid()

  return (
    <PublicContainer>
      <PaperBox p={5}>
        <Container component="main" className={classes.root}>
          <div className={classes.wrapper}>
            <Box mb={2}>
              <LogoFull width="197" />
            </Box>
            {loading && (
              <>
                <LoadingSpinner size={60} />
              </>
            )}
            {data && (
              <>
                <Typography paragraph align="center">
                  <Trans>
                    <strong>Welcome {data.verifyInvite.firstName}</strong>!
                  </Trans>
                </Typography>

                {!activated && (
                  <>
                    <Typography align="center">
                      <Trans>To activate your account, please choose and confirm a password.</Trans>
                    </Typography>
                    <Typography align="center" variant="caption" />

                    <Box mb={3} fullWidth>
                      <PasswordInput
                        required
                        fullWidth
                        label={t`Password`}
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                        disabled={loading || activateSelfLoading}
                      />

                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label={t`Confirm password`}
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        disabled={loading || activateSelfLoading}
                      />

                      <Collapse in={isFormValid !== true} timeout={1000}>
                        <List dense disablePadding>
                          {password && confirmPassword && password !== confirmPassword && (
                            <ListItem className={classes.error} disableGutters>
                              <ListItemAvatar>
                                <Avatar variant="rounded" className={classes.errorAvatar}>
                                  <Security />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText className={classes.errorText}>
                                <Trans>Password and confirm password do not match</Trans>
                              </ListItemText>
                            </ListItem>
                          )}
                        </List>
                      </Collapse>
                    </Box>

                    <Typography align="center">
                      <Trans>
                        Now choose a unique 4-digit PIN so you can log into <Trans>Operandio</Trans> tablet apps.
                      </Trans>
                    </Typography>

                    <Box mt={2} mb={3}>
                      <PinInput
                        alignItems="center"
                        length={pinLength}
                        onChange={setPin}
                        className={classes.pin}
                        password
                        margin="normal"
                        allowShow
                        showLoading={false}
                        disabled={loading || activateSelfLoading}
                      />
                    </Box>

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={handleSubmit}
                      size="large"
                      disabled={loading || activateSelfLoading || !isFormValid}
                    >
                      {activateSelfLoading && <LoadingSpinner size="30px" delay={false} />}
                      {!activateSelfLoading && <Trans>Activate my account</Trans>}
                    </Button>
                  </>
                )}
                {activated && (
                  <>
                    <Typography paragraph align="center">
                      <Trans>Your account has been activated and you can now sign in.</Trans>
                    </Typography>
                    <Button variant="contained" component={NavLink} to="/">
                      <Trans>Proceed to sign in</Trans>
                    </Button>
                  </>
                )}
              </>
            )}
            {error && (
              <>
                <Typography paragraph align="center">
                  <strong>{error.graphQLErrors && error.graphQLErrors.map((err) => err.message).join(",")}</strong>
                </Typography>
                <Typography align="center">
                  <Trans>
                    Please try again. If the problem persists contact your system administrator and request they send a
                    new invite.
                  </Trans>
                </Typography>
              </>
            )}
          </div>
        </Container>
      </PaperBox>
    </PublicContainer>
  )
}

export { Invite }
