import React, { useState } from "react"
import { Button, Collapse, makeStyles } from "@material-ui/core"
import { ExpandMoreOutlined } from "@material-ui/icons"

const useStyles = makeStyles((theme) => ({
  button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: "600",
    padding: theme.spacing(1, 2),
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    transform: ({ expand }) => (expand ? "rotate(180deg)" : null),
    transformOrigin: "center",
    transition: "0.2s all",
  },
}))

const InfoCollapse = ({ children, subject, defaultExpand = false }) => {
  const [expand, setExpand] = useState(defaultExpand)
  const classes = useStyles({ expand })

  if (!children) {
    return null
  }

  return (
    <>
      <Collapse in={expand}>{children}</Collapse>
      <Button className={classes.button} onClick={() => setExpand(!expand)}>
        {!expand ? <>Show more {subject}</> : <>Show less {subject}</>}
        <ExpandMoreOutlined className={classes.icon} />
      </Button>
    </>
  )
}

export { InfoCollapse }
