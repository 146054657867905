import React from "react"
import { FormControlLabel, Switch as MuiSwitch } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

const useSwitchStyles = makeStyles(() => ({
  root: {},
  track: {
    backgroundColor: "#C5D8E1 !important",
  },
  disabled: {
    opacity: 0.3,
  },
}))

const useLabelStyles = makeStyles(() => ({
  label: {
    fontSize: 14,
    fontWeight: "600",
    lineHeight: "16px",
  },
}))

const Switch = ({ label, color, ...rest }) => {
  const switchClasses = useSwitchStyles()
  const labelClasses = useLabelStyles()

  return (
    <FormControlLabel
      control={<MuiSwitch color={color || "primary"} classes={switchClasses} {...rest} />}
      label={label}
      classes={labelClasses}
    />
  )
}

export { Switch }
