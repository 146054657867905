import React from "react"
import moment from "moment"

export default ({ value, compactTodayTimeZone }) => {
  const date = moment(value)
  let format = date.year() === moment().year() ? "MMM D, h:mmA" : "D MMM YY, h:mmA"
  if (compactTodayTimeZone && moment.tz(date, compactTodayTimeZone).isSame(moment.tz(compactTodayTimeZone), "day")) {
    format = "h:mmA"
  }
  try {
    return <>{value && date.format(format)}</>
  } catch (ex) {
    return <>Data invalid.</>
  }
}
