import React from "react"

const AssignedLocations = ({ locations, limit, className }) => {
  const count = locations.length

  return (
    <span className={className}>
      {count > 0 &&
        locations
          .slice(0, limit || count)
          .map((location) => location.name)
          .join(count === 2 ? " & " : ", ")}
      {limit < count ? ` + ${count - limit}` : ""}
    </span>
  )
}

export { AssignedLocations }
