const labelFieldTypes = [
  {
    value: "name",
    name: "Name",
    icon: "labels-item",
    description: "This field will be filled with the **label name** when the label is printed.",
  },
  {
    value: "user",
    name: "User",
    icon: "account-circle-alt",
    description: "This field will be filled with the **currently signed-in user** when the label is printed.",
  },
  {
    value: "location",
    name: "Location",
    icon: "location",
    description: "This field will be filled with the **location** where the label is printed.",
  },
  {
    value: "current_datetime",
    name: "Current Date & Time",
    icon: "clock",
    description: "This field will be filled with the **current date and time** when the label is printed.",
  },
  {
    value: "prep_datetime",
    name: "Preparation Date & Time",
    icon: "clock",
    description:
      "This field will be filled with the item's **preparation date and time**. This will default to the current date and time at printing.",
  },
  {
    value: "ready_datetime",
    name: "Ready Date & Time",
    icon: "clock",
    description:
      "This field will be calculated from the **ready** value when the label is printed. You can edit this value when creating the label.",
  },
  {
    value: "discard_datetime",
    name: "Discard Date & Time",
    icon: "clock",
    description:
      "This field will be calculated from the **discard** value when the label is printed. You can edit this value when creating the label.",
  },
  {
    value: "dynamic_text_oncreate",
    name: "Dynamic Text (On Label)",
    icon: "dynamic",
    description:
      "This field contains text that can be set **per label** for any labels using this template. You can add or edit this text when **creating or editing** your label.",
  },
  {
    value: "dynamic_text_onprint",
    name: "Dynamic Text (On Print)",
    icon: "dynamic",
    description:
      "This field contains text that can be set when **printing** a label. If no text is entered, this field won't show on the label.",
  },
  {
    value: "static_text",
    name: "Static Text",
    icon: "text",
    description: "This text will be **the same on all labels** using this template.",
  },
  {
    value: "barcode",
    name: "Barcode",
    icon: "barcode",
    description:
      "This field will display a barcode in the selected format. You can edit the barcode value when **creating** the label.",
  },
]

export { labelFieldTypes }
