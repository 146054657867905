import React from "react"
import {
  TextFields,
  InsertPhoto,
  InfoOutlined,
  Warning,
  Error,
  AttachFile,
  PlayCircleFilledOutlined,
  QuestionAnswerOutlined,
} from "@material-ui/icons"
import { KNOWLEDGE_CONTENT_NOTE_TYPE, KNOWLEDGE_CONTENT_TYPENAMES } from "../../data"

const KnowledgeContentTypeIcon = ({ type, subType, ...rest }) => {
  switch (type) {
    case KNOWLEDGE_CONTENT_TYPENAMES.TEXT:
      return <TextFields {...rest} />
    case KNOWLEDGE_CONTENT_TYPENAMES.IMAGE:
      return <InsertPhoto {...rest} />
    case KNOWLEDGE_CONTENT_TYPENAMES.NOTE: {
      switch (subType) {
        case KNOWLEDGE_CONTENT_NOTE_TYPE.IMPORTANT:
          return <Warning {...rest} />
        case KNOWLEDGE_CONTENT_NOTE_TYPE.DANGER:
          return <Error {...rest} />
        default:
          return <InfoOutlined {...rest} />
      }
    }
    case KNOWLEDGE_CONTENT_TYPENAMES.FILES:
      return <AttachFile {...rest} />
    case KNOWLEDGE_CONTENT_TYPENAMES.VIDEO:
      return <PlayCircleFilledOutlined {...rest} />
    case KNOWLEDGE_CONTENT_TYPENAMES.MULTICHOICE:
      return <QuestionAnswerOutlined {...rest} />
    default:
      return null
  }
}

export { KnowledgeContentTypeIcon }
