export default {
  httpBaseUri: "http://127.0.0.1:5021",
  httpServiceBaseUri: "http://127.0.0.1:5021/graphql",
  wsServiceBaseUri: "ws://127.0.0.1:5021/graphql",

  showConfigInTitle: true,
  configTitle: "[LOCAL #2]",

  theme: {
    logoContainer: "images-dev",
  },

  errors: {
    showDetail: false,
  },
}
