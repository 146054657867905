import React, { useState } from "react"
import { Box, MenuItem, Input, Divider, Tooltip } from "@material-ui/core"
import { useQueryGroups, GROUP_VISIBILITY } from "../../data"
import { RowBox, OutlinedSelect, GroupCreator, Icon } from ".."

const GroupOutlinedSelectWithCreator = ({
  edit,
  onClose,
  isInline,
  cy = "OutlinedSelect-groups",
  multiple,
  ...rest
}) => {
  const { data: groupsData, refetch: refetchGroups } = useQueryGroups()
  const [openCreateGroup, setOpenCreateGroup] = useState(false)

  const handleClose = async (updated) => {
    setOpenCreateGroup(false)
    if (updated) await refetchGroups()
  }

  return (
    <>
      <GroupCreator open={openCreateGroup} onClose={handleClose} />

      <OutlinedSelect
        native={false}
        input={<Input />}
        menuPortal={false}
        data-cy={cy}
        multiple={multiple}
        selectProps={{
          renderValue: (option) => {
            if (multiple) {
              return option
                .map((item) => {
                  return groupsData?.groups.find((group) => group.id === item)?.name || item
                })
                .join(", ")
            }
            const selected = groupsData?.groups.find((item) => item.id === option.value)
            return selected?.name || option
          },
        }}
        {...rest}
      >
        <MenuItem value="new" onClick={() => setOpenCreateGroup(true)} data-cy={`${cy}-new`}>
          <RowBox>
            <Icon name="add" />
            <Box ml={1}>New area of work...</Box>
          </RowBox>
        </MenuItem>
        {groupsData?.groups.length > 0 && <Divider />}
        {groupsData?.groups.map((item, index) => (
          <MenuItem key={item.id} value={item.id} data-cy={`${cy}-${index}`}>
            <RowBox>
              {item.name}
              {item.visibility === GROUP_VISIBILITY.PRIVATE && (
                <Tooltip title="Private">
                  <RowBox ml={0.5}>
                    <Icon name="group-visibility-private" fontSize="small" />
                  </RowBox>
                </Tooltip>
              )}
              {item.hideFromKiosk && (
                <Tooltip title="Hidden from kiosk login screen">
                  <RowBox ml={0.5}>
                    <Icon name="no-devices" fontSize="small" />
                  </RowBox>
                </Tooltip>
              )}
            </RowBox>
          </MenuItem>
        ))}
      </OutlinedSelect>
    </>
  )
}

export { GroupOutlinedSelectWithCreator }
