import { POST_QUERY } from "."

const queryPost = async (id, client) => {
  try {
    const response = await client.query({
      query: POST_QUERY,
      variables: { id },
    })
    return response
  } catch (err) {
    return err
  }
}

export { queryPost }
