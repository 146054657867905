import { useMutation, gql } from "@apollo/client"

import { AREA_FIELDS } from "./fragments/areaFieldsFragment"
import { AREAS_QUERY } from "./useQueryAreas"
import { useAuth } from "../../services"
import { mapToIds, toId } from "../../utils"

const UPDATE_AREA_MUTATION = gql`
  mutation UpdateArea($id: ID!, $input: AreaMutationInput!) {
    area(id: $id) {
      update(input: $input) {
        ...AreaFields
      }
    }
  }
  ${AREA_FIELDS}
`

const useMutationUpdateArea = () => {
  const {
    location,
    principal: { groups: usersGroups },
  } = useAuth()

  return useMutation(UPDATE_AREA_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: AREAS_QUERY },
      { query: AREAS_QUERY, variables: { filter: { locations: [toId(location)], groups: mapToIds(usersGroups) } } },
    ],
  })
}

export { useMutationUpdateArea }
