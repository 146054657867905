import React from "react"
import { makeStyles } from "@material-ui/styles"
import { NavLink } from "react-router-dom"
import { ActionCard } from "./ActionCard"
import { UnconfirmedChip } from ".."
import NotificationMessage from "../NotificationList/NotificationMessage"
import { useNotificationUtils } from "../../utils"
import { RowBox } from "../Boxes"

const useStyles = makeStyles((theme) => ({
  info: {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  chip: {
    marginRight: theme.spacing(1),
  },
  message: {
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: "18px",
    "&>strong": {
      color: theme.palette.primary.main,
    },
    marginBottom: 8,
  },
}))

const UnconfirmedActionCard = ({ notification }) => {
  const classes = useStyles()
  const { getNavigateToLink } = useNotificationUtils()

  const post = notification.post
  const confirmed = post && post.confirmed.length
  const info = post ? `${confirmed}/${confirmed + post.unconfirmed.length} staff have confirmed` : null

  return (
    <ActionCard
      title={notification.post && notification.post.title}
      detail={<NotificationMessage notification={notification} className={classes.message} showMessage={false} />}
      cardProps={{ component: NavLink, to: { pathname: getNavigateToLink(notification) } }}
    >
      <RowBox>
        <UnconfirmedChip className={classes.chip} /> <span className={classes.info}>{info}</span>
      </RowBox>
    </ActionCard>
  )
}

export { UnconfirmedActionCard }
