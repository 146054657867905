import React, { useMemo } from "react"
import { Box } from "@material-ui/core"
import { Caption, FieldSectionHeading } from "../Headings"
import { useMutationIntegrationDisableEvent, useMutationIntegrationEnableEvent } from "../../data"
import { CheckboxList } from "../Checkbox/CheckboxList"
import { formatEventLabel } from "../../utils/events"
import { NoItemsMessage } from "../Lists"

const IntegrationEventsConfiguration = ({ integration, hasTitle }) => {
  const [enableEvent] = useMutationIntegrationEnableEvent()
  const [disableEvent] = useMutationIntegrationDisableEvent()

  const {
    id,
    integrationProvider: { events: allEvents },
    events,
  } = integration

  const handleEventChange = async (option, checked) => {
    const variables = {
      id,
      event: option.value,
    }
    checked ? enableEvent({ variables }) : disableEvent({ variables })
  }

  const options = useMemo(
    () => allEvents.map((event) => ({ value: event, label: formatEventLabel(event), checked: events.includes(event) })),
    [allEvents, events]
  )

  return (
    <Box m={hasTitle ? 0 : 1}>
      {hasTitle && <FieldSectionHeading mb={0}>Enabled events</FieldSectionHeading>}
      {options.length > 0 && (
        <>
          <Caption>
            Select which Operandio events are enabled for this integration. Changes take effect immediately.
          </Caption>
          <CheckboxList options={options} onChange={handleEventChange} />
        </>
      )}
      {options.length === 0 && <NoItemsMessage>No event settings for this integration.</NoItemsMessage>}
    </Box>
  )
}

export { IntegrationEventsConfiguration }
