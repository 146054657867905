import React from "react"
import { Box, Hidden, makeStyles, Typography } from "@material-ui/core"
import { NavLink, useHistory } from "react-router-dom"
import Config from "react-global-configuration"
import { Icon } from "../Icon"

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    marginTop: -60,
    height: "100vh",
    textAlign: "center",
  },
  inner: {
    width: 340,
  },
  icon: {
    fontSize: 50,
    color: theme.palette.grey[400],
    marginBottom: theme.spacing(2),
  },
  heading: {
    marginBottom: theme.spacing(2),
    fontSize: 20,
    fontWeight: "600",
  },
  body: {
    fontSize: 13,
    marginBottom: theme.spacing(2),
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: 13,
    fontWeight: 600,
  },
}))

const DisplayLimiter = ({ children }) => {
  const classes = useStyles()
  const history = useHistory()

  const { clientKiosk: kiosk, clientDevice: device } = Config.get()
  const isDevice = kiosk || device

  return (
    <>
      <Hidden mdUp>
        <Box className={classes.wrapper}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            alignSelf="center"
            justifySelf="center"
            className={classes.inner}
          >
            <>
              <Icon name={isDevice ? "display-rotation" : "display-size"} className={classes.icon} />
              <Typography variant="h1" className={classes.heading}>
                Your screen size is too small
              </Typography>
              <Typography variant="body2" className={classes.body}>
                {isDevice
                  ? "Try rotating your device or viewing this page on a larger screen to make any changes."
                  : "Please view this page on a larger screen to make any changes."}
              </Typography>
              <NavLink to="#" onClick={history.goBack} className={classes.link}>
                Go back
              </NavLink>
            </>
          </Box>
        </Box>
      </Hidden>
      <Hidden smDown>{children}</Hidden>
    </>
  )
}

export { DisplayLimiter }
