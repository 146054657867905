import { useMutation, gql } from "@apollo/client"

import { ADMINABLE_USERS_FIELDS } from "./fragments/adminableUserFieldsFragment"

const ADMINABLE_USER_CHANGE_USERNAME_MUTATION = gql`
  mutation AdminableUserChangeUsername($id: ID!, $input: UsernameChangeInput!) {
    adminableUser(id: $id) {
      changeUsername(input: $input) {
        ...AdminableUsersFields
      }
    }
  }
  ${ADMINABLE_USERS_FIELDS}
`

export const useMutationAdminableUserChangeUsername = () =>
  useMutation(ADMINABLE_USER_CHANGE_USERNAME_MUTATION, {
    errorPolicy: "all",
  })
