import React from "react"
import { Box, ListItem, makeStyles, Divider } from "@material-ui/core"
import { ChevronRight, ChevronLeft } from "@material-ui/icons"
import moment from "moment"
import { Avatar, Icon } from ".."
import { useMentionListItemStyles } from "./MentionListItem"
import { preventDefault } from "../../utils"
import { FormatDate } from "../Format"
import { NavigableList } from "../NavigableList/NavigableList"
import { convertProcessJobToMention } from "./MentionTextInput"

const useSubStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    minWidth: 320,
  },
  list: {},
  item: {
    padding: "5px 10px",
    borderRadius: 4,
    fontSize: 13,
    "&focused": {
      backgroundColor: "#cee4e5",
    },
    "&:hover": {
      backgroundColor: "#cee4e5",
    },
  },
  title: {
    fontWeight: "bold",
    fontSize: 14,
  },
  subTitle: {
    color: theme.palette.text.secondary,
    fontSize: 12,
  },
}))

const MentionProcessListItem = ({
  showSubMenu,
  text,
  subMenuFocusIndex,
  data,
  secondaryText,
  toggleSubMenu,
  addManually,
  stateMutator,
}) => {
  const classes = useMentionListItemStyles()
  const subClasses = useSubStyles()

  const handleClick = (event) =>
    preventDefault(event, () => {
      if (toggleSubMenu) toggleSubMenu(stateMutator)
    })
  const handleKeyDown = (event) =>
    preventDefault(event, () => {
      // console.log(event.key)
      // if (event.key === "Enter" && !event.shiftKey) {
      // }
    })

  const handleTitleClick = () => {
    if (toggleSubMenu) toggleSubMenu(stateMutator)
  }

  const addJob = (job) => addManually(convertProcessJobToMention(job))

  const handleSubItemClick = (job) => {
    addJob(job)
  }

  const renderDayHelper = (date, daysDelta, label) =>
    moment(date).isSame(moment().add(daysDelta, "day"), "day") && <Box ml={0.5}>({label})</Box>

  const JobListItem = ({ job, ...rest }) => (
    <ListItem className={subClasses.item} onClick={() => handleSubItemClick(job)} {...rest}>
      <FormatDate value={job.date} />
      {renderDayHelper(job.date, -1, "Yesterday")}
      {renderDayHelper(job.date, 0, "Today")}
      {renderDayHelper(job.date, 1, "Tomorrow")}
      <Box mx={0.5}>-</Box>
      {job.location.name}
    </ListItem>
  )

  if (showSubMenu) {
    return (
      <Box className={subClasses.root} onClick={preventDefault}>
        <Box display="flex" flexDirection="row" py={1.5} alignItems="center" onClick={handleTitleClick}>
          <Box display="flex" alignItems="center" px={1.5}>
            <ChevronLeft />
          </Box>
          <Box display="flex" flexGrow={1} flexDirection="column">
            <Box className={subClasses.title}>{text}</Box>
            <Box className={subClasses.subTitle}>Select job date &amp; location</Box>
          </Box>
        </Box>
        <Divider />
        <Box px={1.5}>
          <NavigableList className={subClasses.list} data={data.jobs}>
            {(job, index) => <JobListItem key={job.id} job={job} selected={index === subMenuFocusIndex} />}
          </NavigableList>
        </Box>
      </Box>
    )
  }

  return (
    <Box display="flex" alignItems="center" className={classes.root} onClick={handleClick} onKeyDown={handleKeyDown}>
      <Box>
        <Avatar className={classes.avatar} icon={<Icon name="process" className={classes.icon} />} />
      </Box>
      <Box mx={1} display="flex" flexGrow={1}>
        <Box>
          <Box className={classes.text}>{text}</Box>
          {secondaryText && <Box className={classes.secondaryText}>{secondaryText}</Box>}
        </Box>
      </Box>
      <Box display="flex" alignSelf="center">
        <ChevronRight />
      </Box>
    </Box>
  )
}

export { MentionProcessListItem }
