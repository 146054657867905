import React from "react"
import { Divider, FormHelperText, MenuItem } from "@material-ui/core"
import { OutlinedSelect } from "."
import { OPENID_PROMPT } from "../../data"

const IdentityProviderPromptOutlinedSelect = ({ value, onChange, ...rest }) => {
  const handleChange = (event) => {
    onChange && onChange(event)
  }

  return (
    <>
      <OutlinedSelect
        label="Device prompt"
        native={false}
        defaultValue=""
        value={value}
        onChange={handleChange}
        {...rest}
      >
        <MenuItem value={OPENID_PROMPT.DEFAULT}>(Provider default)</MenuItem>
        <Divider />
        <MenuItem value={OPENID_PROMPT.NONE}>None</MenuItem>
        <MenuItem value={OPENID_PROMPT.LOGIN}>Login</MenuItem>
        <MenuItem value={OPENID_PROMPT.CONSENT}>Consent</MenuItem>
        <MenuItem value={OPENID_PROMPT.SELECT_ACCOUNT}>Select account</MenuItem>
      </OutlinedSelect>
      {value && (
        <FormHelperText>
          {value === OPENID_PROMPT.DEFAULT && <>Use the identity providers default behaviour.</>}
          {value === OPENID_PROMPT.NONE && (
            <>The Authorization Server MUST NOT display any authentication or consent user interface pages.</>
          )}
          {value === OPENID_PROMPT.LOGIN && (
            <>The Authorization Server SHOULD prompt the End-User for reauthentication.</>
          )}
          {value === OPENID_PROMPT.CONSENT && (
            <>
              The Authorization Server SHOULD prompt the End-User for consent before returning information to the
              Client.
            </>
          )}
          {value === OPENID_PROMPT.SELECT_ACCOUNT && (
            <>The Authorization Server SHOULD prompt the End-User to select a user account.</>
          )}
        </FormHelperText>
      )}
    </>
  )
}

export { IdentityProviderPromptOutlinedSelect }
