import { gql, useSubscription } from "@apollo/client"
import { PREP_LIST_FIELDS } from "./fragments/prepListFieldsFragment"

const PREPLIST_CREATED_SUBSCRIPTION = gql`
  subscription PrepListCreated($filter: PrepListCreatedSubscriptionFilterInput!) {
    prepListCreated(filter: $filter) {
      ...PrepListFields
    }
  }
  ${PREP_LIST_FIELDS}
`

const PREPLIST_UPDATED_SUBSCRIPTION = gql`
  subscription PrepListUpdated($filter: PrepListUpdatedSubscriptionFilterInput!) {
    prepListUpdated(filter: $filter) {
      ...PrepListFields
    }
  }
  ${PREP_LIST_FIELDS}
`

const useSubscriptionPrepListCreated = (options) => {
  return useSubscription(PREPLIST_CREATED_SUBSCRIPTION, options)
}

const useSubscriptionPrepListUpdated = (options) => {
  return useSubscription(PREPLIST_UPDATED_SUBSCRIPTION, options)
}

export { useSubscriptionPrepListCreated, useSubscriptionPrepListUpdated, PREPLIST_CREATED_SUBSCRIPTION }
