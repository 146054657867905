import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Box, Button } from "@material-ui/core"
import moment from "moment-timezone"
import { withRouter } from "react-router-dom"
import { Trans, Plural } from "@lingui/macro"
import { Icon } from ".."
import { jobFiltersStateVar, useJobFiltersStore } from "../../data"

const useStyles = makeStyles((theme) => ({
  count: {
    color: theme.palette.text.primary,
    fontSize: 16,
    fontWeight: "600",
    lineHeight: "20px",
    opacity: 0.5,
  },
  link: {
    fontSize: 14,
    lineHeight: "18px",
    fontWeight: "600",
  },
}))

const MoreJobsCount = withRouter(({ history, jobs, boxProps = {} }) => {
  const classes = useStyles()
  const { jobsFiltersStore } = useJobFiltersStore()

  const handleViewAllJobs = () => {
    jobFiltersStateVar({
      ...jobsFiltersStore,
      hideFuture: false,
    })
    history.push("/jobs")
  }

  if (!jobs) return null

  const futureJobsCount = jobs.list.filter((job) => !moment().isSameOrAfter(job.availableFrom)).length

  if (!futureJobsCount) return null

  return (
    <>
      <Box display="flex" alignItems="center" flexDirection="column" className={classes.root} {...boxProps}>
        <Box display="flex" className={classes.count} mb={2} alignItems="center">
          <Icon name="jobs-dark" />
          <Box ml={1.5}>
            <Plural
              value={futureJobsCount}
              one="There's # more job planned today"
              other="There's # more jobs planned today"
            />
          </Box>
        </Box>
        <Button className={classes.link} color="primary" onClick={handleViewAllJobs}>
          <Trans>View all jobs</Trans>
        </Button>
      </Box>
    </>
  )
})

export { MoreJobsCount }
