import React from "react"
import { Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Config from "react-global-configuration"
import { LoadingSpinner } from "../../components"
import { useMountEffect } from "../../utils"

const useStyles = makeStyles(() => ({
  spinner: {
    height: "100vh",
  },
}))

const ReauthDevice = () => {
  const classes = useStyles()
  const deviceReauthLink = Config.get("links.device.reauth")

  useMountEffect(() => {
    setTimeout(() => {
      window.location = deviceReauthLink
    })
  })

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      mt={-10}
      alignItems="center"
      className={classes.spinner}
    >
      <Box display="flex" justifySelf="flex-start" alignSelf="center" mt={3}>
        <LoadingSpinner size={85} />
      </Box>
    </Box>
  )
}

export { ReauthDevice }
