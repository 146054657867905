import React from "react"
import { ChartCard } from "./ChartCard"
import { CircularProgress } from "../CircularProgress"

const CompletionChartCard = ({ title, detail, sets, loading, ...rest }) => {
  let total = 0
  let completed = 0

  sets.map((set) => {
    total += set.total
    completed += set.completed
    return true
  })

  const percentComplete = Math.round((completed / total) * 100)

  return (
    <ChartCard
      title={title}
      detail={detail}
      chart={<CircularProgress value={loading ? 0 : percentComplete} showValue={!loading} size={120} />}
      {...rest}
    />
  )
}

export { CompletionChartCard }
