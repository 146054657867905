import { useQuery, gql, useLazyQuery } from "@apollo/client"

const TIMESHEET_AREA_FIELDS = `
  id
  name
`

const TIMESHEET_AREAS_QUERY = gql`
  query TimesheetAreas {
    timesheetAreas {
      ${TIMESHEET_AREA_FIELDS}
    }
  }
`

const useQueryTimesheetAreas = () => useQuery(TIMESHEET_AREAS_QUERY)

const useLazyQueryTimesheetAreas = () => useLazyQuery(TIMESHEET_AREAS_QUERY)

export { useQueryTimesheetAreas, useLazyQueryTimesheetAreas }
