import { ACTION_QUERY } from "./useQueryAction"

const queryAction = async (id, client) => {
  try {
    const response = await client.query({
      query: ACTION_QUERY,
      variables: { id },
      fetchPolicy: "network-only",
    })
    return response
  } catch (err) {
    return err
  }
}

export { queryAction }
