import React from "react"

/**
 * React hook that merges the new state with current state.
 * @example 
 * const [tags, setTags] = useMergeState({
    value: [],
    touched: false,
   });
 * setTags(tag => ({ value: value }));
 */
const useMergeState = (initialState = {}) => {
  const [value, setValue] = React.useState(initialState)

  const mergeState = (state) => {
    let newState = state || {}
    if (typeof newState === "function") newState = newState(value)
    setValue({ ...value, ...newState })
  }

  return [value, mergeState]
}

export default useMergeState
