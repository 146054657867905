import Config from "react-global-configuration"
import moment from "moment"
import { WebVersion, AndroidVersion, IosVersion } from "../services"

const fetchClientVersions = async () => {
  const { clientBaseUri } = Config.get()
  try {
    const response = await fetch(`${clientBaseUri}/version.json?${moment().format("YYYYMMDDTHHmmss")}`)

    let webVersion = WebVersion
    let androidVersion = AndroidVersion
    let iosVersion = IosVersion

    if (response.ok) {
      const json = await response.json()
      webVersion = json.webVersion
      androidVersion = json.androidVersion
      iosVersion = json.iosVersion
    }
    return {
      webVersion,
      androidVersion,
      iosVersion,
      at: new Date(),
    }
  } catch (e) {
    return {
      webVersion: WebVersion,
      androidVersion: AndroidVersion,
      iosVersion: IosVersion,
      at: new Date(),
    }
  }
}

export { fetchClientVersions }
