import { useLazyQuery, gql } from "@apollo/client"

const CHECK_PIN_COMPLEXITY_QUERY = gql`
  query CheckPinComplexity($pin: String!) {
    checkPinComplexity(pin: $pin) {
      passed
      errors
    }
  }
`

const useLazyQueryCheckPinComplexity = (options) =>
  useLazyQuery(CHECK_PIN_COMPLEXITY_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

export { useLazyQueryCheckPinComplexity }
