import { makeVar } from "@apollo/client"

const principal = {
  isAuth: false,
  token: "",
  expiry: null,
  username: "",
  userID: "",
  external: false,
  createdAt: null,
  firstName: "",
  lastName: "",
  email: "",
  avatar: "",
  roles: [],
  groups: [],
  permissions: {},
}

const makePrincipal = (credentials, loginData, silent) => {
  if (!credentials) {
    return {
      ...principal,
    }
  }

  const { permissions, __typename, ...rest } = loginData

  const result = {
    ...rest,
    isAuth: true,
    username: credentials.username,
    permissions: Object.fromEntries(permissions.map(({ key, value }) => [key, value === "true"])),
  }

  if (!silent) {
    console.log("[AUTH]", "[GP]", {
      username: credentials.username,
      userID: result.userID,
      createdAt: result.createdAt,
      external: result.external,
      firstName: result.firstName,
      lastName: result.lastName,
      email: result.email,
      avatar: result.avatar,
      roles: result.roles,
      groups: result.groups,
      permissions: result.permissions,
      createsRoles: result.createsRoles,
    })
  }

  return result
}

const makeAuthStateVar = (patch) => ({
  authed: false,
  principal: makePrincipal(),
  settings: {},
  location: null,
  reason: null,
  refreshInterval: null,
  firstLogin: false,
  ...patch,
})

const authStateVar = makeVar({
  ...makeAuthStateVar(),
})

const authedVar = makeVar(false)

export { makeAuthStateVar, authStateVar, authedVar, makePrincipal }
