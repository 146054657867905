import { Box, Container, makeStyles, Toolbar, Tooltip } from "@material-ui/core"
import Config from "react-global-configuration"
import { useAuth } from "../../services"
import { RowBox } from "../Boxes"
import { Icon } from "../Icon"
import { LoadingSpinner } from "../LoadingSpinner"
import { useOffline } from "./useOffline"

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.white,
    backgroundColor: ({ reconnecting }) => (reconnecting ? theme.palette.scheduled.main : theme.palette.error.main),
    fontWeight: 600,
    height: ({ offline, reconnecting }) => (offline || reconnecting ? theme.dimensions.offlineBanner.height : 0),
    minHeight: ({ offline, reconnecting }) => (offline || reconnecting ? theme.dimensions.offlineBanner.height : 0),
    position: "sticky",
    top: ({ punchClock }) => {
      let top = theme.dimensions.header.height
      if (punchClock) {
        top += theme.dimensions.punchClock.height
      }
      return top
    },
    zIndex: 999,
    transition: "height 0.6s ease, background-color 0.2s ease",
  },
  preloadIcon: {
    height: 0,
    width: 0,
    position: "absolute",
    opacity: 0,
  },
  message: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  hint: {
    textDecoration: "underline",
    cursor: "pointer",
    display: "inline-block",
  },
}))

const OfflineModeBanner = () => {
  const { clientKiosk: kiosk } = Config.get()
  const { hasFeature } = useAuth()
  const hasTimesheets = hasFeature("timesheets")
  const { offline, reconnecting } = useOffline()
  const classes = useStyles({ offline, reconnecting, punchClock: kiosk && hasTimesheets })

  const show = offline || reconnecting

  return (
    <Toolbar className={classes.root} disableGutters>
      {show && (
        <Container>
          <RowBox className={classes.message}>
            {!reconnecting && (
              <>
                <Icon name="offline" />

                <Box ml={1}>
                  No network, you are in{" "}
                  <Tooltip
                    title="Changes you make may be lost if your network connection isn't restored! Attempts will be made to sync up your changes once you restore network connectivity."
                    enterTouchDelay={0}
                    leaveTouchDelay={6000}
                  >
                    <Box className={classes.hint}>offline mode</Box>
                  </Tooltip>
                </Box>
              </>
            )}
            {reconnecting && (
              <>
                <LoadingSpinner size="24px" delay={false} />
                <Box ml={1}>Reconnecting...</Box>
              </>
            )}
          </RowBox>
        </Container>
      )}
      {!show && (
        <Box className={classes.preloadIcon}>
          <Icon name="offline" />
          <LoadingSpinner size="24px" delay={false} />
        </Box>
      )}
    </Toolbar>
  )
}

export default OfflineModeBanner
