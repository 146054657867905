import React from "react"
import moment from "moment"

export default ({ value, shortMonthName = true }) => {
  try {
    const monthFormat = shortMonthName ? "MMM" : "MMMM"
    const date = moment(value)
    const format = date.year() === moment().year() ? `${monthFormat} D` : `D ${monthFormat} YY`

    return (
      <span className="format-date" title={moment(date).local().format()}>
        {value && date.format(format)}
      </span>
    )
  } catch (ex) {
    return <span>Data invalid.</span>
  }
}
