import React, { useEffect, useMemo, useState, Suspense } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  useTheme,
  useMediaQuery,
  makeStyles,
  Divider,
  Button,
  Hidden,
  Paper,
} from "@material-ui/core"
import Config from "react-global-configuration"
import { useHistory, useParams } from "react-router-dom"
import pluralize from "pluralize"
import useMeasure from "react-use-measure"
import { Trans } from "@lingui/macro"
import { DialogTitleCloser } from "../Creators/DialogTitleCloser"
import { InlineTitleCloser } from "../Creators"
import { InlineTitle, LoadingSpinner, NoItemsMessage } from ".."
import { TemplateCategoryChip } from "../Chips"
import { ModuleHeading } from "../Headings"
import { InfoSection } from "../Viewers"
import { FlexBox } from "../Boxes"
import { FormatDate } from "../Format"
import { Icon } from "../Icon"
import { PROCESS_STEP_RESPONSE_TYPE, useLazyQueryJobPreview, useMutationUseLibraryTemplate } from "../../data"
import { useAuth } from "../../services"
import { toId, useProcessUtils, getResponseStepsCount } from "../../utils"
import { Author, AuthorTools } from "../Author"
import { TruncateNames } from "../DataDisplay/TruncateNames"
import { useJobCollapsed } from "../../data/jobs/jobSectionCollapseStateVar"

const ProcessSteps = React.lazy(() => import("./ProcessSteps"))

const useStyles = makeStyles((theme) => ({
  detail: {
    borderRadius: "8px",
  },
  detailInner: {
    padding: theme.spacing(2),
    borderBottom: "1px solid #EFEFEF",
    color: theme.palette.text.primary,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  detailDescription: {
    fontSize: 16,
    lineHeight: "20px",
  },
  detailSteps: {
    display: "flex",
    flexDirection: "row",
    fontSize: 16,
  },
  infoLight: {
    color: theme.palette.text.secondary,
  },
  dialogContent: {
    padding: theme.spacing(0, 0),
  },
}))

const TemplateViewer = ({ process, parentJob, onClose, onUsed, onDisable, onCategoryClick }) => {
  const history = useHistory()
  const { set, category } = useParams()
  const classes = useStyles()
  const theme = useTheme()
  const isInline = useMediaQuery(theme.breakpoints.down("xs"))
  const [inlineTitleRef, { height: inlineTitleHeight }] = useMeasure()
  const [dialogTitleRef, { height: dialogTitleHeight }] = useMeasure()
  const [stepsHeight, setStepsHeight] = useState("100vh")
  const [functionsLoading, setFunctionsLoading] = useState(false)
  const [load, { data, loading: processLoading }] = useLazyQueryJobPreview()
  const [copyTemplate, { loading: useLibraryTemplateLoading }] = useMutationUseLibraryTemplate()
  const [, { isStepCollapsed }] = useJobCollapsed(process)
  const { getNavigateToTemplateLink } = useProcessUtils()
  const {
    settings: { locations: userLocations },
    hasPermission,
  } = useAuth()
  const { featuredCategory: featuredId } = Config.get("templates")
  const inactive = data?.jobs.preview.inactive

  const template = data?.jobs.preview || null

  useEffect(() => {
    if (process) {
      load({
        variables: { process: toId(process), parentProcess: parentJob ? toId(parentJob.process, true) : undefined },
      })
    }
  }, [process, load, parentJob])

  useEffect(() => {
    const offset = isInline
      ? inlineTitleHeight + theme.dimensions.footer.mobileBottomNav.height + theme.dimensions.header.height
      : dialogTitleHeight + theme.dimensions.dialogs.margin * 2

    setStepsHeight(`calc(100vh - ${offset}px)`)
  }, [
    dialogTitleHeight,
    inlineTitleHeight,
    isInline,
    theme.dimensions.dialogs.margin,
    theme.dimensions.footer.mobileBottomNav.height,
    theme.dimensions.header.height,
  ])

  const steps = useMemo(
    () =>
      template?.status.steps.filter((step) => {
        if (step.responseType === PROCESS_STEP_RESPONSE_TYPE.SECTION) {
          return true
        }

        return !isStepCollapsed(step.section, template)
      }),
    [isStepCollapsed, template],
  )

  const handleOnClose = () => {
    onClose(true)
  }

  const handleCategoryClick = (categoryId) => {
    onCategoryClick && onCategoryClick(categoryId)
  }

  const handleEditTemplate = () => {
    history.push(getNavigateToTemplateLink(process, set || "library", category, "edit"))
  }

  const handleUseTemplate = async () => {
    let item

    if (libraryTemplate()) {
      // copy from template library
      item = await copyTemplate({ variables: { id: toId(process) } })
      item = item.data?.useLibraryTemplate || null

      if (onUsed && item) onUsed(item)
      return
    }

    // copy own process -> forward to copy screen
    history.push(getNavigateToTemplateLink(process, set || "library", category, "copy"))
  }

  const handleDisable = async () => {
    if (onDisable) {
      setFunctionsLoading(true)
      await onDisable(template, true)
      setFunctionsLoading(false)
    }
  }

  const libraryTemplate = () => template?.categories?.length > 0

  const isLibraryTemplate = libraryTemplate()

  const loading = processLoading || useLibraryTemplateLoading || functionsLoading

  const locations =
    template?.locations?.map((templateLocation) => {
      const item = userLocations.find((ul) => toId(ul) === toId(templateLocation))
      if (item) return item
      return { id: toId(templateLocation), name: "(Hidden)", noAccess: true }
    }) || null

  // O-i18n translate Hidden?? ^

  let display = null

  if (!process) {
    return null
  }

  if (template) {
    const header = (
      <Paper elevation={3}>
        <Box className={classes.detail} mt={1}>
          <Box className={classes.detailInner}>
            <ModuleHeading noMargin>{template.process.name}</ModuleHeading>
            {template.description && <Box className={classes.detailDescription}>{template.description}</Box>}
            {template.categories?.length > 0 && (
              <Box display="flex" flexDirection="row">
                {[...template.categories]
                  .sort((a, b) => {
                    if (a.id === featuredId) return -1
                    if (b.id === featuredId) return 1
                    return a.name.localeCompare(b.name)
                  })
                  .map((item) => (
                    <Box key={item.id} mr={0.5}>
                      <TemplateCategoryChip category={item} onClick={handleCategoryClick} />
                    </Box>
                  ))}
              </Box>
            )}
          </Box>
        </Box>

        <InfoSection
          variant="info"
          compact
          rightChildren={
            <>
              <Box ml="auto" display="flex" flexDirection="row">
                {isLibraryTemplate && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUseTemplate}
                    disabled={loading}
                    title={<Trans>Make a copy</Trans>}
                    data-cy="Button-copy"
                  >
                    {!loading && (
                      <>
                        <Hidden xsDown>
                          <Trans>Make a copy</Trans>
                        </Hidden>
                        <Hidden smUp>
                          <Icon name="copy" />
                        </Hidden>
                      </>
                    )}
                    {loading && <LoadingSpinner size="24px" delay={false} />}
                  </Button>
                )}
                {!isLibraryTemplate && (
                  <>
                    <Box alignSelf="center">
                      <AuthorTools
                        item={template}
                        subject="process"
                        admins={{ edit: "process_update_all", disable: "process_update_all" }}
                        disableLabel={inactive ? <Trans>Make active</Trans> : <Trans>Make inactive</Trans>}
                        onCopy={handleUseTemplate}
                        onDisable={handleDisable}
                        p={0.5}
                      />
                    </Box>
                    {hasPermission("process_update_all") && (
                      <Box ml={1.5}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleEditTemplate}
                          disabled={loading}
                          title={<Trans>Edit</Trans>}
                        >
                          <Trans>Edit</Trans>
                        </Button>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </>
          }
        >
          <Box py={isLibraryTemplate ? 1 : 0} display="flex" flexDirection="row" flexGrow={1} alignItems="center">
            {isLibraryTemplate && <Icon name="operandio" size={32} />}
            {isLibraryTemplate && (
              <Box className={classes.infoLight} ml={1.5}>
                <Trans>Created by Operandio</Trans>
              </Box>
            )}
            {!isLibraryTemplate && (
              <Author author={template.author} prefix={<Trans>Created by</Trans>} className={classes.infoLight} />
            )}
          </Box>
        </InfoSection>
        <Divider light />

        <InfoSection title={<Trans>Date added</Trans>} value={<FormatDate value={template.updatedAt} />} compact />

        <Divider light />
        <InfoSection
          title={<Trans>Steps</Trans>}
          value={pluralize("step", getResponseStepsCount(template.status.steps), true)}
          compact
        />
        {/* O-i18n check pluralization of steps above */}
        {locations && <Divider />}
        {locations && (
          <InfoSection
            title={<Trans>Locations</Trans>}
            value={<TruncateNames names={locations.map((item) => item.name)} max={5} />}
          />
        )}
      </Paper>
    )

    display = (
      <Suspense fallback={<LoadingSpinner size={60} />}>
        <ProcessSteps
          header={header}
          job={template}
          steps={steps}
          isPreview
          scrollableTarget="TemplateViewer-content"
          isInline={isInline}
          height={stepsHeight}
        />
      </Suspense>
    )
  } else if (!loading) {
    display = (
      <NoItemsMessage>
        <Trans>Template not found</Trans>
      </NoItemsMessage>
    )
  } else {
    display = (
      <FlexBox justifyContent="center" mb={2}>
        <LoadingSpinner size={60} />
      </FlexBox>
    )
  }

  if (isInline) {
    return (
      <>
        <InlineTitle ref={inlineTitleRef}>
          <Trans>Template preview</Trans>
          <InlineTitleCloser onClose={handleOnClose} />
        </InlineTitle>
        {display}
      </>
    )
  }

  return (
    <Dialog open={!!process} onClose={handleOnClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
      <DialogTitle id="form-dialog-title" ref={dialogTitleRef}>
        <Trans>Template preview</Trans>
        <DialogTitleCloser onClose={handleOnClose} />
      </DialogTitle>
      <DialogContent id="TemplateViewer-content" classes={{ root: classes.dialogContent }}>
        {display}
      </DialogContent>
    </Dialog>
  )
}

export { TemplateViewer }
