import React from "react"
import { Box, Chip } from "@material-ui/core"
import { CancelOutlined } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"
import moment from "moment-timezone"

import { PROCESS_TYPE, USER_ACCREDITATIONS_STATUS, USER_TRAINING_STATUS } from "../../data"

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
    fontWeight: 500,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      padding: "4px",
    },
  },
  labelSmall: {
    display: "block",
    whiteSpace: "normal",
  },
}))

const FilterChip = ({ label, onDelete }) => {
  const classes = useStyles()

  return <Chip label={label} onDelete={onDelete} deleteIcon={<CancelOutlined />} size="small" classes={classes} />
}

const FiltersChips = ({ data, filterItems, filters, hasSchedules, onDelete }) => {
  const handleFilterDelete = (filter) => {
    onDelete(filter)
  }

  const renderLabelWithValue = (type, value, field) => {
    if (Array.isArray(value)) {
      return (
        data?.[`${type}Data`]?.[type]
          ?.filter((item) => {
            return value.findIndex((v) => v === item.id) !== -1
          })
          .map((item) => item?.[field] ?? "")
          ?.join(", ") ?? "..."
      )
    }

    return data?.[`${type}Data`]?.[type]?.find((item) => item.id === value)?.[field] ?? "..."
  }

  const renderAssigneeLabel = (value) => {
    let firstItem
    if (value?.groups && value?.groups.length > 0) {
      const [firstGroup] = value?.groups
      firstItem = data?.groupsData?.groups?.find((item) => item.id === firstGroup)?.name
    }

    if (value?.users && value?.users.length > 0 && !firstItem) {
      const [firstUser] = value?.users
      firstItem = data?.usersData?.users?.find((item) => item.id === firstUser)?.fullName
    }

    const numberOfItems = (value?.groups?.length ?? 0) + (value?.users?.length ?? 0)
    const extraText = numberOfItems > 1 ? ` + ${numberOfItems - 1}` : ""

    return `${firstItem || "..."}${extraText}`
  }

  const getSelectLabelByValue = (options = [], filterValue) => {
    return options.find((option) => option?.value === filterValue)?.label
  }

  const renderSelectLabel = (filter) => {
    const options = filterItems?.find((filterItem) => filterItem.name === filter?.name)?.options ?? []

    if (Array.isArray(filter?.value)) {
      const [firstValue] = filter?.value
      const extraText = filter?.value?.length > 1 ? ` + ${filter?.value?.length - 1}` : ""

      return `${getSelectLabelByValue(options, firstValue)}${extraText}`
    }

    return getSelectLabelByValue(options, filter?.value)
  }

  const renderAutocompleteLabel = (filter) => {
    return filter?.value?.[filter?.label] ?? ""
  }

  const renderDateLabel = (value) => {
    return moment(value).format("MMM, D YYYY")
  }

  const renderDateRangeLabel = (value) => {
    if (value === 7) {
      return "Last 7 days"
    }

    if (value !== 1 && Number.isInteger(value)) {
      return `${value} days`
    }

    if (value === 1) {
      return moment().startOf("day").format("MMM D YYYY")
    }

    const dateArray = value?.split("-to-")

    if (dateArray?.[0] === dateArray?.[1]) {
      return moment(dateArray?.[0]).format("MMM D YYYY")
    }

    const startDate = moment(dateArray?.[0]).format("MMM D YYYY")
    const endDate = moment(dateArray?.[1]).format("MMM D YYYY")

    return `${startDate} - ${endDate}`
  }

  return (
    <Box display="flex" flexWrap="wrap">
      {filters
        ?.filter((filter) => filter.type !== "schedules" || hasSchedules)
        ?.map((filter) => {
          const { type, value } = filter
          const key = `${type}-${value}`
          switch (type) {
            case "types": {
              let label
              switch (value) {
                case PROCESS_TYPE.PROCESS:
                  label = "Process"
                  break
                case PROCESS_TYPE.AUDIT:
                  label = "Audit"
                  break
                default:
                  label = "Unknown type"
              }

              return <FilterChip key={key} label={label} onDelete={() => handleFilterDelete(filter)} />
            }

            case "roles":
              return (
                <FilterChip
                  key={key}
                  label={data?.rolesData?.roles.find((item) => item.id === value)?.friendlyName || "..."}
                  onDelete={() => handleFilterDelete(filter)}
                />
              )
            case "groups":
            case "locations":
            case "trainingCourses":
            case "tags":
            case "processes":
              return (
                <FilterChip
                  key={key}
                  label={renderLabelWithValue(type, value, "name")}
                  onDelete={() => handleFilterDelete(filter)}
                />
              )
            case "users":
              return (
                <FilterChip
                  key={key}
                  label={renderLabelWithValue(type, value, "fullName")}
                  onDelete={() => handleFilterDelete(filter)}
                />
              )
            case "schedules":
            case "status":
              return (
                <FilterChip
                  key={key}
                  label={`${value.charAt(0).toUpperCase()}${value.slice(1)}`}
                  onDelete={() => handleFilterDelete(filter)}
                />
              )
            case "trainingStatus": {
              let label
              switch (value) {
                case USER_TRAINING_STATUS.IN_PROGRESS:
                  label = "Training in progress"
                  break
                case USER_TRAINING_STATUS.COMPLETE:
                  label = "Training complete"
                  break
                case USER_TRAINING_STATUS.OVERDUE:
                  label = "Training overdue"
                  break
                default:
                  label = "No training assigned"
              }

              return <FilterChip key={key} label={label} onDelete={() => handleFilterDelete(filter)} />
            }
            case "accreditationStatus": {
              let label
              switch (value) {
                case USER_ACCREDITATIONS_STATUS.AWAITING:
                  label = "Awaiting accreditations"
                  break
                case USER_ACCREDITATIONS_STATUS.WARNING:
                  label = "Accreditations expiring"
                  break
                case USER_ACCREDITATIONS_STATUS.EXPIRED:
                  label = "Accreditations expired"
                  break
                case USER_ACCREDITATIONS_STATUS.COMPLIANT:
                  label = "Accreditations current"
                  break
                default:
                  label = "No accreditations assigned"
              }

              return <FilterChip key={key} label={label} onDelete={() => handleFilterDelete(filter)} />
            }
            // Generic types
            case "autocomplete":
              return (
                <FilterChip
                  key={key}
                  label={renderAutocompleteLabel(filter)}
                  onDelete={() => handleFilterDelete(filter)}
                />
              )
            case "select":
              return (
                <FilterChip key={key} label={renderSelectLabel(filter)} onDelete={() => handleFilterDelete(filter)} />
              )
            case "date":
              return <FilterChip key={key} label={renderDateLabel(value)} onDelete={() => handleFilterDelete(filter)} />
            case "dateRange":
              return (
                <FilterChip key={key} label={renderDateRangeLabel(value)} onDelete={() => handleFilterDelete(filter)} />
              )
            case "assignedTo":
              return (
                <FilterChip key={key} label={renderAssigneeLabel(value)} onDelete={() => handleFilterDelete(filter)} />
              )
            default:
              throw new Error("Unmapped filter type")
          }
        })}
    </Box>
  )
}

export { FiltersChips }
