import { useLazyQuery, useQuery, gql } from "@apollo/client"
import { ADMINABLE_USERS_FIELDS } from "./fragments/adminableUserFieldsFragment"

const ADMINABLE_USER_QUERY = gql`
  query AdminableUser($id: ID!) {
    adminableUser(id: $id) {
      ...AdminableUsersFields
    }
  }
  ${ADMINABLE_USERS_FIELDS}
`

const useQueryAdminableUser = (options) =>
  useQuery(ADMINABLE_USER_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

const useLazyQueryAdminableUser = (options) =>
  useLazyQuery(ADMINABLE_USER_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

export { useQueryAdminableUser, useLazyQueryAdminableUser }
