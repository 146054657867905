import { useQuery, gql } from "@apollo/client"

const ORGANISATION_QUERY = gql`
  query Organisation($id: ID!) {
    organisation(id: $id) {
      id
      name
      industry
      logo {
        key
      }
      billing {
        type
      }
    }
  }
`

const useQueryOrganisation = (options) =>
  useQuery(ORGANISATION_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

export { useQueryOrganisation }
