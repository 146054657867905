import React from "react"
import MuiCheckbox from "@material-ui/core/Checkbox"
import { useTheme } from "@material-ui/core/styles"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import { FormControlLabel } from "@material-ui/core"
import { ColumnBox } from "../Boxes"
import { Caption } from "../Headings"

const Checkbox = ({ type, label, description, formControlLabelProps, color = "default", ...props }) => {
  const { palette } = useTheme()

  switch (type) {
    case "confirm":
    case "complete":
      return (
        <MuiCheckbox
          color="default"
          checkedIcon={<CheckBoxIcon style={{ color: palette.success.main }} />}
          {...props}
        />
      )
    case "skipped":
      return (
        <MuiCheckbox
          color="default"
          indeterminate
          checkedIcon={<CheckBoxIcon style={{ color: palette.grey[400] }} />}
          {...props}
        />
      )
    case "label":
      return (
        <FormControlLabel
          control={<MuiCheckbox color={color} {...props} />}
          label={
            description ? (
              <ColumnBox>
                {label}
                <Caption mb={0}>{description}</Caption>
              </ColumnBox>
            ) : (
              label
            )
          }
          {...formControlLabelProps}
        />
      )
    default:
      return <MuiCheckbox color={color} {...props} />
  }
}

export default Checkbox
