import { gql } from "@apollo/client"

import { TAG_FIELDS } from "../../tags/useQueryTags"

const AREA_FIELDS = gql`
  fragment AreaFields on Area {
    id
    url
    name
    icon
    groups {
      id
    }
    locations {
      id
    }
    organisation
    tags {
      ...TagFields
    }
  }
  ${TAG_FIELDS}
`

export { AREA_FIELDS }
