import React from "react"
import { MenuItem, makeStyles } from "@material-ui/core"
import { ACTION_STATUS, ACTION_STATUS_LABELS } from "../../data"
import { OutlinedSelect } from "./OutlinedSelect"
import { IconMenuItemContent } from "./IconMenuItemContent"

const useStyles = makeStyles((theme) => {
  const styles = {
    root: {
      minWidth: 125,
    },
  }

  Object.values(ACTION_STATUS).forEach((value) => {
    styles[`icon_${value}`] = {
      color: theme.palette.status[value] || "inherit",
    }
  })

  return styles
})

const ActionStatusOutlinedSelect = ({ onDataLoaded, disabled, ...rest }) => {
  const classes = useStyles()

  return (
    <OutlinedSelect
      label="Status"
      native={false}
      classes={{ root: classes.root }}
      disabled={disabled}
      selectProps={{
        renderValue: (selected) => {
          if (Array.isArray(selected)) {
            return selected.map((value) => ACTION_STATUS_LABELS[value]).join(", ")
          }

          return ACTION_STATUS_LABELS[selected]
        },
      }}
      {...rest}
    >
      {Object.values(ACTION_STATUS).map((value) => (
        <MenuItem key={value} value={value}>
          <IconMenuItemContent
            label={ACTION_STATUS_LABELS[value]}
            value={value}
            icon="action-status"
            iconProps={{ className: classes[`icon_${value}`] }}
          />
        </MenuItem>
      ))}
    </OutlinedSelect>
  )
}

export { ActionStatusOutlinedSelect }
