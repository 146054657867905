import { useMutation, gql } from "@apollo/client"
import { CATEGORY_FIELDS } from "./useQueryCategories"

const CREATECATEGORY_MUTATION = gql`
  mutation CreateCategory($name: String!, $description: String, $icon: String!, $groups: [ID!], $locations: [ID!]) {
    createCategory(name: $name, description: $description, icon: $icon, groups: $groups, locations: $locations) {
      ...CategoryFields
    }
  }
  ${CATEGORY_FIELDS}
`

const useMutationCreateCategory = () => useMutation(CREATECATEGORY_MUTATION)

export { useMutationCreateCategory }
