import { Icon } from "./Icon"

const FileIcon = ({ fileName, ...rest }) => {
  const getIconFromFileName = (fname) => {
    const ext = fname.split(".").pop()

    const supportedExtensions = {
      doc: "upload-doc",
      docx: "upload-doc",
      pdf: "upload-pdf",
      odt: "upload-odt",
      ods: "upload-ods",
      pages: "upload-pages",
      xls: "upload-xls",
      xlsx: "upload-xls",
      xlt: "upload-xls",
      xltx: "upload-xls",
      ots: "upload-xls",
      zip: "upload-zip",
      xz: "upload-zip",
      gz: "upload-zip",
    }

    if (supportedExtensions[ext]) {
      return supportedExtensions[ext]
    }

    // Image file
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "svg"]
    if (imageExtensions.includes(ext)) {
      return "image"
    }

    // Document file
    return "document"
  }

  return <Icon name={getIconFromFileName(fileName)} {...rest} />
}

export { FileIcon }
