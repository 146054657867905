import { i18n } from "@lingui/core"
import { I18nProvider } from "@lingui/react"
import { defaultLocale, dynamicActivate } from "../../i18n"
import { useMountEffect } from "../../utils"

const InternationalisationBooter = ({ children }) => {
  useMountEffect(() => {
    // init lingui.js for translations. If there is a localstorage preference set, it takes precendence.
    const lang = JSON.parse(window.localStorage.getItem("op__lang"))
    if (lang && lang !== "en") {
      dynamicActivate(lang)
    } else {
      dynamicActivate(defaultLocale)
    }
  })

  return <I18nProvider i18n={i18n}>{children}</I18nProvider>
}

export default InternationalisationBooter
