import { gql } from "@apollo/client"

const USER_ACCREDITATION_FIELDS = gql`
  fragment UserAccreditationFields on UserAccreditation {
    id
    accreditation {
      id
      name
      description
      icon
      expires
    }
    notes
    uploads {
      id
      key
      fileName
      fileGroup
      fileSize
    }
    warningAt
    expiresAt
    status
    statusAt
    createdAt
  }
`

export { USER_ACCREDITATION_FIELDS }
