import React from "react"
import { Box, Divider, IconButton, Tooltip, makeStyles } from "@material-ui/core"
import { FlexBox, RowBox } from "../Boxes"
import { PROCESS_STEP_LOGIC_MULTIPLE_VALUE_RANGES, PROCESS_STEP_LOGIC_OUTCOME_LABELS } from "../../data"
import { Icon } from "../Icon"
import { FormatSymbol } from "../Format/FormatSymbol"
import { formatEnumKeyForDisplay } from "../../utils"
import { CommonChip } from "../Chips/CommonChip"

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 13,
    color: theme.palette.tertiary.main,
    backgroundColor: theme.palette.tertiary.background,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  addOutcome: {
    padding: 2,
  },
  addIcon: {
    width: 18,
    height: 18,
  },
  rangeChip: {
    fontWeight: 400,
    color: theme.palette.text.white,
    backgroundColor: theme.palette.tertiary.main,
  },
  editButton: {
    padding: 2,
    "&:hover": {
      background: `rgba(${theme.palette.tertiary.mainRgb},.10)`,
    },
    "&:focus": {
      background: `rgba(${theme.palette.tertiary.mainRgb},.10)`,
    },
  },
  editIcon: {
    width: 18,
    height: 18,
    color: theme.palette.tertiary.main,
  },
}))

const ProcessStepCreatorLogicSummary = ({ logic, format, onEdit }) => {
  const classes = useStyles()

  const handleEdit = () => {
    onEdit && onEdit()
  }

  if (!logic?.length > 0) {
    return null
  }

  return (
    <Box className={classes.root}>
      {logic.map((rule, index) => (
        <React.Fragment key={index}>
          <Divider />
          <RowBox px={2} py={0.5}>
            <Box mr={0.5}>If answer is</Box>
            <CommonChip
              label={
                <>
                  {formatEnumKeyForDisplay(rule.range)} {rule.lowerValue}
                  {PROCESS_STEP_LOGIC_MULTIPLE_VALUE_RANGES.includes(rule.range) && <> and {rule.upperValue}</>}
                </>
              }
              size="small"
              className={classes.rangeChip}
            />
            <Box ml={0.5}>
              <FormatSymbol format={format} />, then
            </Box>
            {rule.outcomes.map((outcome, outcomeIndex) => (
              <FlexBox ml={0.5} key={outcomeIndex}>
                {PROCESS_STEP_LOGIC_OUTCOME_LABELS[outcome.type].toLowerCase()}
              </FlexBox>
            ))}

            <Box ml="auto">
              <Tooltip title="Edit">
                <IconButton className={classes.editButton} onClick={() => handleEdit(index)} color="primary">
                  <Icon name="edit" className={classes.editIcon} />
                </IconButton>
              </Tooltip>
            </Box>
          </RowBox>
        </React.Fragment>
      ))}
    </Box>
  )
}

export { ProcessStepCreatorLogicSummary }
