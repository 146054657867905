import React from "react"
import { Box, makeStyles } from "@material-ui/core"
import { ColumnBox } from "../Boxes"
import { Icon } from "../Icon"

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: ({ maxWidth }) => maxWidth,
    textAlign: "center",
    margin: "0 auto",
    opacity: 1,
    animationName: "fadeInOpacity",
    animationIterationCount: 1,
    animationTimingFunction: "ease-in",
    animationDuration: "0.35s",
  },
  text: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.secondary,
    opacity: 0.7,
  },
}))

const IconMessage = ({ name, text, maxWidth = 200, iconProps = {} }) => {
  const classes = useStyles({ maxWidth })

  return (
    <ColumnBox className={classes.root} justifyContent="center" alignItems="center" py={1}>
      <Icon name={name} {...iconProps} />
      {Boolean(text) && (
        <Box className={classes.text} mt={3}>
          {text}
        </Box>
      )}
    </ColumnBox>
  )
}

export { IconMessage }
