import React, { useState, useEffect } from "react"
import { Button, Tooltip } from "@material-ui/core"
import { fetchIconMetadata } from "../../data"
import PickerDialog from "../PickerDialog"
import { IconPickerListItem } from "./IconPickerListItem"
import { LoadingSpinner } from "../LoadingSpinner"

const IconPicker = ({ value, onChange, selectText, buttonText, buttonProps, children }) => {
  const [icons, setIcons] = useState(null)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const result = await fetchIconMetadata()
        setIcons([...result])
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
    if (!icons) fetchData()
  }, [icons, setIcons])

  if (loading) return <LoadingSpinner />

  const pickerConfig = {
    closeText: "Close",
    loading,
    initialPicked: value,
    type: "single",
    data: icons,
    limit: 10,
    layout: "grid",
    labelPropName: (d) => d.name,
    search: {
      placeholder: "Start typing keywords",
      required: true,
    },
    // eslint-disable-next-line react/display-name
    getListItem: (item, index, checked, onListItemChange) => (
      <IconPickerListItem key={index} color="primary" {...{ item, checked, onChange: onListItemChange }} />
    ),
    onSearch: (searchTextLower, data) => {
      if (data && searchTextLower.trim().length >= 3) {
        return data.filter((icon) => icon.keywords.indexOf(searchTextLower) > -1)
      }
      return []
    },
    onPickedChanged: (data) => {
      if (data.picked) {
        onChange(data.picked.name)
        setOpen(false)
      }
    },
  }

  return (
    <>
      <Tooltip title={selectText || "Icon"}>
        <Button onClick={() => setOpen(true)} {...buttonProps}>
          {children || buttonText || "Select icon"}
        </Button>
      </Tooltip>
      <PickerDialog
        open={open}
        onClose={() => setOpen(false)}
        title="Select icon"
        initialPicked={value}
        config={pickerConfig}
      />
    </>
  )

  // if (!loading && !icons)
  //   return (<div>Error! Unable to retrieve icons</div>)
}

export default IconPicker
