import { Button } from "@material-ui/core"
import React, { useState } from "react"
import { Caption, FieldSectionHeading, Icon, LoadingSpinner, useSnackbar } from ".."
import { useMutationIntegrationRunOperation } from "../../data"
import { toId } from "../../utils"
import { ColumnBox } from "../Boxes"

const IntegrationOperation = ({ integration, operation: { name, description, method } }) => {
  const [runOperation, { loading }] = useMutationIntegrationRunOperation()
  const [run, setRun] = useState(false)
  const snackbar = useSnackbar()

  const handleClick = async () => {
    if (!run) {
      setRun(true)
      return
    }
    const response = await runOperation({ variables: { id: toId(integration), method } })
    setRun(false)
    if (response?.data?.integration.runOperation) {
      snackbar.showMessage({
        icon: <Icon name="time" />,
        message: `Operation started, see Events tab for result`,
      })
    }
  }

  return (
    <ColumnBox mb={3}>
      <FieldSectionHeading mb={0}>{name}</FieldSectionHeading>
      <Caption mb={1}>{description}</Caption>

      <Button variant="contained" onClick={handleClick} disabled={loading}>
        {!loading && !run && <>Run this operation</>}
        {!loading && run && <>Click again to run this operation!</>}
        {loading && <LoadingSpinner size="24px" delay={false} />}
      </Button>
    </ColumnBox>
  )
}

const IntegrationOperations = ({ integration }) => {
  const { integrationProvider } = integration
  if (!integrationProvider?.operations?.length) {
    return null
  }

  return integrationProvider.operations.map((operation) => (
    <IntegrationOperation key={operation.method} integration={integration} operation={operation} />
  ))
}

export { IntegrationOperations }
