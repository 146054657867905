import React from "react"
import { IconButton, Tooltip } from "@material-ui/core"

const TooltipIconButton = ({ tooltip, children, ...rest }) => {
  return (
    <Tooltip title={tooltip}>
      <IconButton {...rest}>{children}</IconButton>
    </Tooltip>
  )
}

export { TooltipIconButton }
