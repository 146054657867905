import React from "react"
import { makeStyles } from "@material-ui/styles"
import { SpeedDial, SpeedDialIcon } from "@material-ui/lab"
import { Backdrop } from "@material-ui/core"

const useStyles = makeStyles(() => ({
  root: {
    position: "fixed",
    zIndex: 999,
  },
  speedDial: {
    position: "fixed",
    bottom: 80,
    "&.MuiSpeedDial-directionUp": {
      bottom: 80,
      right: 20,
    },
  },
}))

const FloatingSpeedDial = ({ open, children, title = "Create menu", direction = "up", cy, ...rest }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Backdrop open={open} />
      <SpeedDial
        open={open}
        ariaLabel={title}
        direction={direction}
        icon={<SpeedDialIcon />}
        className={classes.speedDial}
        data-cy={`${cy}-speeddial`}
        {...rest}
      >
        {children}
      </SpeedDial>
    </div>
  )
}

export { FloatingSpeedDial }
