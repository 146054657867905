import { useMutation, gql } from "@apollo/client"

const DELETE_GROUP_MUTATION = gql`
  mutation DeleteGroup($id: ID!) {
    group(id: $id) {
      delete
    }
  }
`

const useMutationDeleteGroup = () => useMutation(DELETE_GROUP_MUTATION)

export { useMutationDeleteGroup }
