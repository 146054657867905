import { useQuery, gql } from "@apollo/client"
import { TAG_FIELDS } from "../tags/useQueryTags"

const CATEGORY_FIELDS = gql`
  fragment CategoryFields on Category {
    id
    name
    description
    icon
    tags {
      ...TagFields
    }
    groups {
      id
    }
    locations {
      id
    }
    items {
      count
      lastUpdatedAt
      lastUpdatedAuthor {
        firstName
        lastName
        avatar {
          key
        }
      }
    }
    author {
      id
      firstName
      lastName
      avatar {
        key
      }
    }
    createdAt
    updatedAt
  }
  ${TAG_FIELDS}
`

const CATEGORIES_QUERY = gql`
  query Categories($filter: CategoryFilterInput) {
    categories(filter: $filter) {
      ...CategoryFields
    }
  }
  ${CATEGORY_FIELDS}
`

const CATEGORIES_CREATED_SUBSCRIPTION = gql`
  subscription CategoryCreated {
    categoryCreated {
      ...CategoryFields
    }
  }
  ${CATEGORY_FIELDS}
`
const CATEGORIES_UPDATED_SUBSCRIPTION = gql`
  subscription CategoryUpdated {
    categoryUpdated {
      ...CategoryFields
    }
  }
  ${CATEGORY_FIELDS}
`

const subscribe = (result) => () =>
  [
    result.subscribeToMore({
      document: CATEGORIES_CREATED_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        const newItem = subscriptionData.data.categoryCreated

        // skip if already exists
        if (prev.categories.some((p) => p.id === newItem.id)) return prev

        return { ...prev, categories: [newItem, ...prev.categories] }
      },
      onError: (err) => console.log("[useQueryCategories][CATEGORIES_CREATED_SUBSCRIPTION]", err),
    }),
    result.subscribeToMore({
      document: CATEGORIES_UPDATED_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        const updatedItem = subscriptionData.data.categoryUpdated
        let category = prev.categories.find((p) => p.id === updatedItem.id)
        if (category) {
          category = { ...updatedItem }
        }
        return prev
      },
      onError: (err) => console.log("[useQueryCategories][CATEGORIES_UPDATED_SUBSCRIPTION]", err),
    }),
  ]

const useQueryCategories = (options) => {
  const result = useQuery(CATEGORIES_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

  return {
    ...result,
    subscribe: subscribe(result),
  }
}

export { useQueryCategories, CATEGORIES_QUERY, CATEGORY_FIELDS }
