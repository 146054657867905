import React, { useMemo } from "react"

import PropTypes from "prop-types"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core"
import { NavLink } from "react-router-dom"

import { PROCESS_STEP_SELECTION_SOURCES } from "../../data"
import {
  DialogTitleCloser,
  FlexBox,
  Icon,
  SupplierViewer,
  LoadingSpinner,
  RowBox,
  AssetViewer,
  FoodItemViewer,
} from ".."
import Forbidden from "../../areas/knowledge/Forbidden"
import { titleCase } from "../../utils/string"
import { useQuerySelectionSourceItem } from "../../data/jobs/useQuerySelectionStepSource"

const { SUPPLIERS, ASSETS, FOOD_ITEMS } = PROCESS_STEP_SELECTION_SOURCES

const SelectionPeekDialog = ({ id, source, open, onClose }) => {
  const { data, loading, error, name } = useQuerySelectionSourceItem(source, id)

  const forbidden = useMemo(() => {
    if (!error) {
      return false
    }
    if (error.graphQLErrors?.length === 0) {
      return false
    }

    return error.graphQLErrors[0].extensions?.code === "PERMISSION_DENIED"
  }, [error])

  const handleClose = () => {
    onClose && onClose()
  }

  const getActionTextBySource = () => {
    return titleCase(source)
  }

  const getNavigateLink = () => {
    switch (source) {
      case SUPPLIERS:
        return `/suppliers/${id}`
      case ASSETS:
        return `/assets/${id}`
      case FOOD_ITEMS:
        return `/food-items/${id}`
      default:
        throw new Error("Source does not support")
    }
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
      <DialogTitle id="customized-dialog-title">
        <RowBox mr={2}>
          <FlexBox flexGrow={1}>{name}</FlexBox>
          <DialogTitleCloser onClose={handleClose} />
        </RowBox>
      </DialogTitle>
      <DialogContent>
        {loading && !data && (
          <Box display="flex" justifyContent="center">
            <LoadingSpinner size={60} />
          </Box>
        )}
        {!forbidden && data && (
          <>
            {source === SUPPLIERS && <SupplierViewer variant="peek" supplier={data.item} />}
            {source === ASSETS && <AssetViewer variant="peek" asset={data.item} />}
            {source === FOOD_ITEMS && <FoodItemViewer variant="peek" asset={data.item} />}
          </>
        )}
        {forbidden && <Forbidden />}
      </DialogContent>
      <DialogActions>
        {!forbidden && (
          <Button component={NavLink} to={getNavigateLink(id)}>
            <Box mr={0.5}>View in {getActionTextBySource()}</Box>
            <Icon name="forward" />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

SelectionPeekDialog.propTypes = {
  id: PropTypes.string.isRequired,
  source: PropTypes.oneOf([SUPPLIERS, ASSETS, FOOD_ITEMS]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

export default SelectionPeekDialog
