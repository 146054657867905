import { useMutation, gql } from "@apollo/client"
import { produce } from "immer"
import { NOTIFICATIONS_LIMIT, NOTIFICATIONS_QUERY, NOTIFICATION_FIELDS } from "./useQueryNotifications"

const READNOTIFICATIONS_MUTATION = gql`
  ${NOTIFICATION_FIELDS}
  mutation ReadNotifications($ids: [ID!]!) {
    readNotifications(ids: $ids) {
      unread
      unconfirmed
      items {
        ...NotificationFields
      }
    }
  }
`

const useMutationReadNotification = (options) =>
  useMutation(READNOTIFICATIONS_MUTATION, {
    update(cache, { data: { readNotifications } }) {
      const notificationsVariables = {
        limit: NOTIFICATIONS_LIMIT,
        offset: 0,
      }

      // get notifications
      const cachedData = cache.readQuery({ query: NOTIFICATIONS_QUERY, variables: notificationsVariables })

      // write notifications
      cache.writeQuery({
        query: NOTIFICATIONS_QUERY,
        variables: notificationsVariables,
        data: produce(cachedData, (data) => {
          // set unread + unconfirmed count
          data.notifications.unread = readNotifications.unread
          data.notifications.unconfirmed = readNotifications.unconfirmed
        }),
      })
    },
    ...options,
  })

export { useMutationReadNotification }
