import { useQuery, useLazyQuery, gql } from "@apollo/client"
import * as Sentry from "@sentry/browser"
import { JOB_FIELDS, JOB_LIST_FIELDS } from "./fragments/jobFieldsFragment"
import { makeSubscribeToMore } from "../shared/subscribeToMore"

const JOBS_QUERY = gql`
  query Jobs($filter: JobsQueryFilterInput!) {
    jobs: jobsV2 {
      list(filter: $filter) {
        ...JobListFields
      }
      count(filter: $filter)
    }
  }
  ${JOB_LIST_FIELDS}
`

const JOB_PREVIEW_QUERY = gql`
  query JobPreview($process: ID!, $parentProcess: ID, $date: ScheduleDateInput, $location: ID) {
    jobs: jobsV2 {
      preview(process: $process, parentProcess: $parentProcess, date: $date, location: $location) {
        ...JobFields
        categories {
          id
          name
        }
      }
    }
  }
  ${JOB_FIELDS}
`

const MENTIONABLE_JOBS_QUERY = gql`
  query MentionableJobs($locations: [ID!]!) {
    mentionableJobs(locations: $locations) {
      processes {
        id
        name
        jobs {
          id
          name
          process
          location {
            id
            name
            timeZone
          }
          locationDay
          date
        }
      }
      tasks {
        id
        name
        location {
          id
          name
          timeZone
        }
        locationDay
        date
      }
    }
  }
`

const ACTIVE_PROCESS_JOBS_QUERY = gql`
  query ActiveProcessJobs($process: ID!) {
    activeProcessJobs(process: $process) {
      id
    }
  }
`

const JOB_CREATED_SUBSCRIPTION = gql`
  subscription JobListCreated($filter: JobCreatedSubscriptionFilterInput) {
    jobCreated(filter: $filter) {
      ...JobListFields
    }
  }
  ${JOB_LIST_FIELDS}
`
const JOB_UPDATED_SUBSCRIPTION = gql`
  subscription JobListUpdated($filter: JobUpdatedSubscriptionFilterInput) {
    jobUpdated(filter: $filter) {
      ...JobListFields
    }
  }
  ${JOB_LIST_FIELDS}
`

const loadMore = (result) => async () => {
  try {
    await result.fetchMore({
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return {
          ...prev,
          jobs: {
            count: prev.jobs.count,
            list: [...(prev.jobs.list || []), ...fetchMoreResult.jobs.list],
            __typename: prev.jobs.__typename,
          },
        }
      },
    })
  } catch (error) {
    Sentry.captureException(error)
  }
}

const subscribe =
  (result, options = {}) =>
  () => {
    if (!options?.variables?.filter?.location) {
      const message = "No location provided for subscription, not subscribing to job updates"
      console.log("[useQueryJobs]", message)
      Sentry.captureMessage(message)
      return []
    }

    return [
      result.subscribeToMore(
        makeSubscribeToMore({
          document: JOB_CREATED_SUBSCRIPTION,
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) return prev
            const newItem = subscriptionData.data.jobCreated
            return {
              ...prev,
              jobs: {
                count: prev.jobs.count + 1,
                list: [newItem, ...(prev.jobs.list || [])],
                __typename: prev.jobs.__typename,
              },
            }
          },
          ...options,
        }),
      ),
      result.subscribeToMore(
        makeSubscribeToMore({
          document: JOB_UPDATED_SUBSCRIPTION,
          ...options,
        }),
      ),
    ]
  }

const useQueryJobs = (options) => {
  const result = useQuery(JOBS_QUERY, {
    ...options,
  })

  return {
    ...result,
    loadMore: loadMore(result),
    subscribe: subscribe(result, {
      variables: {
        filter: {
          location: options?.variables?.filter?.location ?? undefined,
        },
      },
    }),
  }
}

const useLazyQueryJobs = (options) => {
  const [loadJobs, result] = useLazyQuery(JOBS_QUERY, {
    ...options,
  })

  return [
    loadJobs,
    {
      ...result,
      loadMore: loadMore(result),
      subscribe: subscribe(result, {
        variables: {
          filter: {
            location: result?.variables?.filter?.location ?? undefined,
          },
        },
      }),
    },
  ]
}

const JOBS_ACTIVITY_QUERY = gql`
  query JobsActivity($filter: JobsQueryActivityFilterInput!) {
    jobs: jobsV2 {
      activity(filter: $filter) {
        jobId
        displayName
        stepName
        at
        action
        author {
          id
          status
          fullName
          firstName
          lastName
          avatar {
            key
          }
          groups {
            id
            name
          }
          locations {
            id
            name
          }
          activeAt
        }
      }
    }
  }
`

const useQueryJobsActivity = (options) => useQuery(JOBS_ACTIVITY_QUERY, options)

const useQueryJobPreview = (options) => useQuery(JOB_PREVIEW_QUERY, options)

const useLazyQueryJobPreview = (options = {}) => useLazyQuery(JOB_PREVIEW_QUERY, options)

const useQueryMentionableJobs = (options) =>
  useQuery(MENTIONABLE_JOBS_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

const useLazyQueryActiveProcessJobs = (options) =>
  useLazyQuery(ACTIVE_PROCESS_JOBS_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

const jobsTypePolicies = {
  root: {},
  scoped: {
    JobsQuery: {
      merge(prev = {}, next) {
        return {
          ...prev,
          ...next,
        }
      },
    },
    Job: {
      fields: {
        isOverdue: {
          read(_, { readField, cache }) {
            const dueAt = readField("dueAt")
            if (!dueAt) {
              return false
            }

            const hasSubmit = readField("hasSubmit")

            const statusRef = readField("status")
            const status = cache.readFragment({
              id: statusRef.__ref,
              fragment: gql`
                fragment JobStatusFields on JobStatus {
                  completedAt
                  submittedAt
                }
              `,
            })

            const now = new Date()

            const { submittedAt, completedAt } = status

            if (hasSubmit && !submittedAt && new Date(dueAt) < now) {
              return true
            }

            if (hasSubmit && new Date(submittedAt) > new Date(dueAt)) {
              return true
            }

            if (!completedAt && new Date(dueAt) < now) {
              return true
            }

            if (completedAt && new Date(completedAt) > new Date(dueAt)) {
              return true
            }

            return false
          },
        },
      },
    },
    JobProcessStep: {
      fields: {
        completed: {
          read(_, { readField }) {
            return readField("completedAt") !== null
          },
        },
        notes: {
          merge(prev, next) {
            return [...next]
          },
        },
      },
    },
    JobProcessStepJob: {
      fields: {
        completed: {
          read(_, { readField }) {
            return readField("totalSteps") === readField("completedSteps")
          },
        },
      },
    },
  },
}

export {
  useQueryJobsActivity,
  useQueryJobPreview,
  useLazyQueryJobPreview,
  useQueryJobs,
  useLazyQueryJobs,
  useQueryMentionableJobs,
  useLazyQueryActiveProcessJobs,
  JOBS_QUERY,
  jobsTypePolicies,
}
