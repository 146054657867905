import { makeStyles } from "@material-ui/core"
import React, { useState } from "react"
import { useMountEffect } from "../../utils"

const useStyles = makeStyles(() => ({
  root: {
    position: "sticky",
    top: ({ top }) => top,
    opacity: ({ show }) => (show ? 1 : 0),
    transition: "opacity 0.2s",
  },
}))

const ShowAtScrollTop = ({ from = 0, top = 0, children }) => {
  const [show, setShow] = useState(false)
  const classes = useStyles({ show, top })

  useMountEffect(() => {
    window.addEventListener("scroll", scrollListener)
    return () => window.removeEventListener("scroll", scrollListener)
  })

  const scrollListener = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop
    // ?? window.scrollY || window.pageYOffset || document.body.scrollTop + (document.documentElement && document.documentElement.scrollTop || 0)
    if (winScroll > from) {
      !show && setShow(true)
    } else {
      setShow(false)
    }
  }

  return <div className={classes.root}>{children}</div>
}

export { ShowAtScrollTop }
