import React, { memo } from "react"
import { Box } from "@material-ui/core"

const ColumnBox = ({ alignItems = "flex-start", children, ...rest }) => (
  <Box display="flex" flexDirection="column" alignItems={alignItems} {...rest}>
    {children}
  </Box>
)

const ColumnBoxMemo = memo(ColumnBox)

export { ColumnBoxMemo as ColumnBox }
