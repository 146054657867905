import { useMutation, gql } from "@apollo/client"
import { TRAINING_COURSE_FIELDS } from "./useQueryTrainingCourses"

const CREATE_TRAINING_COURSE_MUTATION = gql`
  mutation CreateTrainingCourse(
    $name: String!
    $description: String
    $dueDays: Int
    $modules: [ID!]!
    $showUserScore: Boolean!
  ) {
    createTrainingCourse(
      name: $name
      description: $description
      dueDays: $dueDays
      modules: $modules
      showUserScore: $showUserScore
    ) {
      ${TRAINING_COURSE_FIELDS}
    }
  }
`

const useMutationCreateTrainingCourse = () => useMutation(CREATE_TRAINING_COURSE_MUTATION)

export { useMutationCreateTrainingCourse }
