import { useLazyQuery, gql, useQuery } from "@apollo/client"

const SEARCH_LIMIT = 20

const SEARCH_FIELDS = `
    id
    title
    type
    date
    score
    createdAt
    updatedAt
`

const SEARCH_ALL_QUERY = gql`query SearchAll($searchText: String!, $location: ID!) {
  searchAll(limit: ${SEARCH_LIMIT}, searchText: $searchText, location: $location) {
    keywords
    results {
      total
      items {
        ${SEARCH_FIELDS}
      }
    }
  } 
}`

const useQuerySearchAll = (options) => useQuery(SEARCH_ALL_QUERY, options)

const useLazyQuerySearchAll = () => {
  const [load, result] = useLazyQuery(SEARCH_ALL_QUERY, {
    variables: {
      limit: SEARCH_LIMIT,
    },
    fetchPolicy: "network-only",
  })

  return [load, result]
}

export { useQuerySearchAll, useLazyQuerySearchAll }
