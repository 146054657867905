import { List, ListItem, makeStyles } from "@material-ui/core"
import React from "react"
import { SortableContainer, SortableElement } from "react-sortable-hoc"

const useStyles = makeStyles(() => ({
  sortableListHelper: {
    zIndex: 99999,
  },
}))

const SortableListItem = SortableElement(({ itemComponent, ...rest }) => (
  <ListItem component={itemComponent} {...rest} />
))

const SortableContainerList = SortableContainer(({ itemComponent, items, itemProps, ...rest }) => (
  <List component="div" {...rest}>
    {items.map((item, index) => (
      <SortableListItem
        component={itemComponent}
        key={index}
        index={index}
        itemIndex={index}
        item={item}
        {...itemProps}
      />
    ))}
  </List>
))

const SortableList = (props) => {
  const classes = useStyles()

  return <SortableContainerList helperClass={classes.sortableListHelper} {...props} />
}

export { SortableList }
