import { gql } from "@apollo/client"

const COMPACT_RESPONSE_SELECTION_OPTION_FIELDS = gql`
  fragment CompactResponseSelectionFields on CompactResponseSelection {
    id
    name
  }
`

export { COMPACT_RESPONSE_SELECTION_OPTION_FIELDS }
