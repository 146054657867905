import { useMutation, gql } from "@apollo/client"
import { USER_TRAINING_FIELDS } from "."
import serializationKeys from "../serializationKeys"

const USER_TRAINING_MODULE_KNOWLEDGE_ANSWER_MUTATION = gql`
  mutation UserTrainingModuleKnowledgeAnswer(
    $userTraining: ID!
    $module: ID!
    $moduleKnowledge: ID!
    $question: ID!
    $answer: ID!
  ) {
    userTraining(id: $userTraining) {
      module(id: $module) {
        knowledge(id: $moduleKnowledge) {
          answer(question: $question, answer: $answer) {
            ...UserTrainingFields
          }
        }
      }
    }
  }
  ${USER_TRAINING_FIELDS}
`

const USER_TRAINING_MODULE_KNOWLEDGE_COMPLETE_MUTATION = gql`
  mutation UserTrainingModuleKnowledgeComplete($userTraining: ID!, $module: ID!, $moduleKnowledge: ID!) {
    userTraining(id: $userTraining) {
      module(id: $module) {
        knowledge(id: $moduleKnowledge) {
          complete {
            ...UserTrainingFields
          }
        }
      }
    }
  }
  ${USER_TRAINING_FIELDS}
`

const useMutationUserTrainingModuleKnowledgeAnswer = (userTraining) =>
  useMutation(USER_TRAINING_MODULE_KNOWLEDGE_ANSWER_MUTATION, {
    context: {
      serializationKey: serializationKeys.userTraining(userTraining),
    },
  })

const useMutationCompleteUserTrainingModuleKnowledge = (userTraining) =>
  useMutation(USER_TRAINING_MODULE_KNOWLEDGE_COMPLETE_MUTATION, {
    context: {
      serializationKey: serializationKeys.userTraining(userTraining),
    },
  })

export { useMutationUserTrainingModuleKnowledgeAnswer, useMutationCompleteUserTrainingModuleKnowledge }
