import React, { useState } from "react"

import PropTypes from "prop-types"

import { t } from "@lingui/macro"
import { CreatorMaster } from "../../../components"
import EmailChangeForm from "./EmailChangeForm"
import SuccessfulEmailChangeDialog from "./SuccessfulEmailChangeDialog"
import { useMutationChangeUsername } from "../../../data/users/useMutationChangeUsername"
import { useMutationAdminableUserChangeUsername } from "../../../data/users/useMutationAdminableUserChangeUsername"

const EmailChangeDialog = ({
  userId,
  open = false,
  onClose,
  onSuccess,
  changeByAdmin = true,
  isInline = false,
  user = {},
}) => {
  const [changeUsername, { loading }] = useMutationChangeUsername()
  const [changeUserNameByAdmin, { loading: loadingChangeUserNameByAdmin }] = useMutationAdminableUserChangeUsername()
  const [openSuccessfulDialog, setOpenSuccessfulDialog] = useState(false)

  const handleSubmit = async (value) => {
    let resp
    if (changeByAdmin) {
      resp = await changeUserNameByAdmin({
        variables: {
          id: userId,
          input: {
            newUsername: value?.newUsername,
          },
        },
      })
    } else {
      resp = await changeUsername({
        variables: {
          input: {
            ...value,
          },
        },
      })
    }

    if (!resp?.errors) {
      onClose()
      onSuccess()
      setOpenSuccessfulDialog(true)
    }
  }

  return (
    <>
      <SuccessfulEmailChangeDialog
        open={openSuccessfulDialog}
        onClose={() => setOpenSuccessfulDialog(false)}
        user={user}
      />
      <CreatorMaster
        form={
          <EmailChangeForm
            loading={loading || loadingChangeUserNameByAdmin}
            onClose={onClose}
            onSubmit={handleSubmit}
            changeByAdmin={changeByAdmin}
          />
        }
        loading={false}
        open={open}
        onClose={onClose}
        title={t`Change email address`}
        isInline={isInline}
      />
    </>
  )
}

EmailChangeDialog.propTypes = {
  userId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  changeByAdmin: PropTypes.bool,
  isInline: PropTypes.bool,
  user: PropTypes.object,
}

export default EmailChangeDialog
