import { useQuery, useLazyQuery, gql } from "@apollo/client"
import { JOB_FIELDS } from "./fragments/jobFieldsFragment"
import { makeSubscribeToMore } from "../shared/subscribeToMore"

const JOB_QUERY = gql`
  query Job($id: ID!) {
    jobs: jobsV2 {
      one(id: $id) {
        ...JobFields
      }
    }
  }
  ${JOB_FIELDS}
`

const JOB_UPDATED_SUBSCRIPTION = gql`
  subscription JobUpdated($filter: JobUpdatedSubscriptionFilterInput) {
    jobUpdated(filter: $filter) {
      ...JobFields
    }
  }
  ${JOB_FIELDS}
`

const subscribe =
  (result, options = {}) =>
  () =>
    [
      result.subscribeToMore(
        makeSubscribeToMore({
          document: JOB_UPDATED_SUBSCRIPTION,
          ...options,
        })
      ),
    ]

const useLazyQueryJob = (options = {}) => useLazyQuery(JOB_QUERY, options)

const useQueryJob = (options) => {
  const result = useQuery(JOB_QUERY, {
    ...options,
  })

  return {
    ...result,
    subscribe: subscribe(result, {
      variables: { filter: { id: options.variables.id } },
    }),
  }
}

const queryJob = async (id, client, options = {}) => {
  try {
    const response = await client.query({
      query: JOB_QUERY,
      variables: { id },
      ...options,
    })
    return response
  } catch (err) {
    return err
  }
}

export { useQueryJob, useLazyQueryJob, queryJob }
