import React, { useState, useEffect, useRef } from "react"
import { TextField, Box, Avatar, Icon, makeStyles } from "@material-ui/core"
import { Trans, t } from "@lingui/macro"
import { useMutationCreateAccreditation, useMutationUpdateAccreditation } from "../../data"
import { mapToIds, multipleSelectChange, toId, useFormUtils } from "../../utils"
import { CreatorActions, CreatorMaster } from "../Creators"
import IconPicker from "../IconPicker/IconPicker"
import { ColumnBox, RowBox } from "../Boxes"
import Checkbox from "../Checkbox"
import { GroupOutlinedSelect, LocationOutlinedSelect } from "../OutlinedSelect"
import { Caption, FieldSectionHeading } from "../Headings"

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
}))

const initialState = {
  title: "",
  icon: "badge",
  description: "",
  locations: [],
  groups: [],
  expires: true,
  expiringWarningMonths: "3",
}

const AccreditationCreator = ({ open, onClose, edit, cy = "AccreditationCreator" }) => {
  const classes = useStyles()
  const { isValid } = useFormUtils()
  const expiringWarningMonthsRef = useRef(null)
  const [createAccreditation, { loading: createAccreditationLoading }] = useMutationCreateAccreditation()
  const [updateAccreditation, { loading: updateAccreditationLoading }] = useMutationUpdateAccreditation()

  const [name, setName] = useState(initialState.name)
  const [icon, setIcon] = useState(initialState.icon)
  const [description, setDescription] = useState(initialState.description)
  const [locations, setLocations] = useState(initialState.locations)
  const [groups, setGroups] = useState(initialState.groups)
  const [expires, setExpires] = useState(initialState.expires)
  const [expiringWarningMonths, setExpiringWarningMonths] = useState(initialState.expiringWarningMonths)

  const isEdit = edit != null

  useEffect(() => {
    if (edit) {
      setName(edit.name)
      setDescription(edit.description)
      setIcon(edit.icon)
      setLocations(mapToIds(edit.locations))
      setGroups(mapToIds(edit.groups))
      setExpires(edit.expires)
      setExpiringWarningMonths(edit.expiringWarningMonths)
    }
  }, [edit])

  const handleCancel = () => {
    handleClose(false)
  }

  const handleClose = (updated) => {
    handleResetState()
    if (onClose) onClose(updated)
  }

  const handleLocationsChanged = (event) => {
    setLocations([...multipleSelectChange(locations, event)])
  }

  const handleRegionChange = (regionLocations) => {
    setLocations([...mapToIds(regionLocations)])
  }

  const handleGroupsChanged = (event) => {
    setGroups([...multipleSelectChange(groups, event)])
  }

  const handleExpiresChange = () => {
    if (expires) {
      setExpiringWarningMonths("")
    }
    setExpires(!expires)
  }

  const handlExpiringWarningMonthsChange = (event) => {
    setExpiringWarningMonths(event.target.value)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (formValid()) {
      const variables = {
        name,
        description,
        icon,
        locations: locations.includes("all") ? [] : mapToIds(locations),
        groups: groups.includes("all") ? [] : mapToIds(groups),
        expires,
        expiringWarningMonths: Number(expiringWarningMonths),
      }
      if (isEdit) {
        updateAccreditation({ variables: { id: toId(edit), ...variables } })
      } else {
        await createAccreditation({ variables })
      }
      handleClose(true)
    }
  }

  const handleResetState = () => {
    setName(initialState.name)
    setDescription(initialState.description)
    setIcon(initialState.icon)
    setExpires(initialState.expires)
    setExpiringWarningMonths(initialState.expiringWarningMonths)
    setLocations(initialState.locations)
    setGroups(initialState.groups)
  }

  const formValid = () => isValid(name)

  const isFormValid = formValid()

  const loading = createAccreditationLoading || updateAccreditationLoading

  const form = (
    <>
      <RowBox mb={2} alignItems="center">
        <Box flexGrow={1}>
          <TextField
            variant="outlined"
            fullWidth
            id="name"
            label={t`Name`}
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            inputProps={{ "data-cy": `${cy}-TextField-name` }}
          />
        </Box>
        <Box>
          <IconPicker
            value={icon}
            onChange={setIcon}
            selectText={icon ? <Trans>Change icon</Trans> : <Trans>Select icon</Trans>}
          >
            <Avatar className={classes.avatar}>
              <Icon className="material-icons-outlined">{icon || "fact_check"}</Icon>
            </Avatar>
          </IconPicker>
        </Box>
      </RowBox>
      <Box mb={2}>
        <TextField
          variant="outlined"
          fullWidth
          id="description"
          label={t`Description`}
          name="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          multiline
          minRows={4}
          inputProps={{ "data-cy": `${cy}-TextField-description` }}
        />
      </Box>

      <FieldSectionHeading mb={0.5}>
        <Trans>Expiration &amp; Warning</Trans>
      </FieldSectionHeading>
      <ColumnBox mb={2}>
        <RowBox>
          <Checkbox
            type="label"
            color="primary"
            label={
              <RowBox>
                <Trans>
                  <Box>Requires an expiry date, warn</Box>
                  <RowBox ml={1}>
                    <TextField
                      placeholder="None"
                      type="number"
                      variant="outlined"
                      value={expiringWarningMonths}
                      onChange={handlExpiringWarningMonthsChange}
                      required
                      inputRef={expiringWarningMonthsRef}
                      inputProps={{ "data-cy": "TextField-expiring-warning-months", min: 1, max: 36 }}
                      disabled={!expires}
                      style={{ width: 100 }}
                      size="small"
                    />
                    <Box ml={1}>months prior to expiry</Box>
                  </RowBox>
                </Trans>
              </RowBox>
            }
            checked={expires}
            onChange={() => handleExpiresChange()}
          />
        </RowBox>
        {expires && (
          <Caption>
            {expiringWarningMonths ? (
              <Trans>
                Staff will be required to provide an expiry date and will receive a warning {expiringWarningMonths}{" "}
                months prior to the accreditation expiring.
              </Trans>
            ) : (
              <Trans>
                Staff will be required to provide an expiry date and will not receive a warning prior to the
                accreditation expiring.
              </Trans>
            )}
          </Caption>
        )}
      </ColumnBox>

      <FieldSectionHeading mb={0.5}>
        <Trans>Automated assignment</Trans>
      </FieldSectionHeading>
      <Caption mb={1.5}>
        <Trans>
          New and existing members of locations and/or areas of work selected will be requested to provide this
          accreditation.
        </Trans>
      </Caption>
      <Box mb={2}>
        <LocationOutlinedSelect
          allLabel={t`No automated assignment by location`}
          value={locations}
          onChange={handleLocationsChanged}
          onRegionChange={handleRegionChange}
          multiple
        />
      </Box>
      <Box mb={2}>
        <GroupOutlinedSelect
          allLabel={t`No automated assignment by area`}
          value={groups}
          onChange={handleGroupsChanged}
          multiple
        />
      </Box>

      <CreatorActions
        id="AccreditationCreator-CreatorActions"
        subject={t`Accreditation type`}
        submitLoading={loading}
        onClose={handleCancel}
        onSubmit={handleSubmit}
        disableSubmit={!isFormValid}
      />
    </>
  )

  return (
    <CreatorMaster open={open} subject={t`Accreditation type`} form={form} isEdit={isEdit} onClose={handleCancel} />
  )
}

export { AccreditationCreator }
