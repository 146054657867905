import { Box, makeStyles } from "@material-ui/core"
import React from "react"
import { USER_ACCREDITATIONS_STATUS } from "../../data"
import { toId } from "../../utils"
import { ActionCard } from "../ActionCards"
import { ColumnBox, RowBox } from "../Boxes"
import { DeleteConfirmIconButton } from "../Buttons"
import { ImagesViewer } from "../ImagesViewer"
import TimeAgo from "../TimeAgo"
import { DownloadList } from "../Uploader"
import { PersonAccreditationItemStatus } from "./PersonAccreditationItemStatus"
import { accreditationAwaitingOverdue } from "./PersonAccreditationStatus"

const useStyles = makeStyles((theme) => ({
  card: {
    border: ({ status }) => {
      if (status === USER_ACCREDITATIONS_STATUS.EXPIRED) {
        return `2px solid ${theme.palette.error.main}`
      }
      if (status === USER_ACCREDITATIONS_STATUS.WARNING) {
        return `2px solid ${theme.palette.scheduled.main}`
      }

      return null
    },
  },
  title: {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: "500",
    color: theme.palette.text.primary,
  },
  caption: {
    fontSize: 12,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
    fontWeight: "normal",
  },
  awaitingRequestedCaption: {
    fontWeight: ({ statusAt }) => (accreditationAwaitingOverdue(statusAt) ? 600 : "inherit"),
    color: ({ statusAt }) => (accreditationAwaitingOverdue(statusAt) ? theme.palette.error.main : "inherit"),
  },
}))

const PersonAccreditationActionCard = ({ userAccreditation, onDelete }) => {
  const {
    accreditation: { name },
    expiresAt,
    uploads,
    status,
    statusAt,
    createdAt,
  } = userAccreditation

  const classes = useStyles({
    status,
    statusAt,
  })

  const completed = status === USER_ACCREDITATIONS_STATUS.COMPLIANT

  const handleDelete = () => {
    onDelete && onDelete()
  }

  return (
    <>
      <ActionCard
        key={toId(userAccreditation)}
        overdue={status === USER_ACCREDITATIONS_STATUS.EXPIRED}
        className={classes.card}
        cursor="inherit"
        completed={completed}
        title={
          <RowBox className={classes.title} alignItems="flex-start">
            <PersonAccreditationItemStatus userAccreditation={userAccreditation} />

            <ColumnBox ml={1}>
              <Box>{name}</Box>

              <RowBox alignItems="center">
                <Box className={classes.caption}>
                  {status === USER_ACCREDITATIONS_STATUS.AWAITING && (
                    <>
                      Awaiting upload by user,{" "}
                      <span className={classes.awaitingRequestedCaption}>
                        requested <TimeAgo date={createdAt} />
                      </span>
                    </>
                  )}
                  {status === USER_ACCREDITATIONS_STATUS.WARNING && (
                    <>
                      Expires <TimeAgo date={expiresAt} />
                    </>
                  )}
                  {status === USER_ACCREDITATIONS_STATUS.EXPIRED && (
                    <>
                      Expired <TimeAgo date={expiresAt} />
                    </>
                  )}
                  {status === USER_ACCREDITATIONS_STATUS.COMPLIANT && (
                    <>
                      Provided by user
                      {Boolean(expiresAt) && (
                        <>
                          , expires <TimeAgo date={expiresAt} />
                        </>
                      )}
                    </>
                  )}
                </Box>
              </RowBox>

              {Boolean(uploads.length) && (
                <>
                  <ImagesViewer uploads={uploads} />
                  <DownloadList uploads={uploads} />
                </>
              )}
            </ColumnBox>
          </RowBox>
        }
        rightChildren={
          <RowBox alignItems="flex-start">
            <DeleteConfirmIconButton onDelete={handleDelete} />
          </RowBox>
        }
      />
    </>
  )
}

export { PersonAccreditationActionCard }
