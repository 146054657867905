import { useMutation, gql } from "@apollo/client"
import { GROUP_FIELDS } from "./fragments/groupFieldsFragment"
import { toId } from "../../utils"

const GROUP_CREATE_ATTRIBUTE_MUTATION = gql`
  mutation GroupCreateAttribute($id: ID!, $input: AttributeCreateMutationInput!) {
    group(id: $id) {
      createAttribute(input: $input) {
        ...GroupFields
      }
    }
  }
  ${GROUP_FIELDS}
`

const GROUP_UPDATE_ATTRIBUTE_MUTATION = gql`
  mutation GroupUpdateAttribute($id: ID!, $attribute: ID!, $input: AttributeUpdateMutationInput!) {
    group(id: $id) {
      attribute(id: $attribute) {
        update(input: $input) {
          ...GroupFields
        }
      }
    }
  }
  ${GROUP_FIELDS}
`

const GROUP_DELETE_ATTRIBUTE_MUTATION = gql`
  mutation GroupDeleteAttribute($id: ID!, $attribute: ID!) {
    group(id: $id) {
      attribute(id: $attribute) {
        delete
      }
    }
  }
`

const useMutationGroupCreateAttribute = () => useMutation(GROUP_CREATE_ATTRIBUTE_MUTATION)

const useMutationGroupUpdateAttribute = () => useMutation(GROUP_UPDATE_ATTRIBUTE_MUTATION)

const useMutationGroupDeleteAttribute = () =>
  useMutation(GROUP_DELETE_ATTRIBUTE_MUTATION, {
    update(cache, { errors, ...rest }, { variables: { attribute } }) {
      if (errors) {
        return
      }
      const normalizedId = cache.identify({ id: toId(attribute), __typename: "Attribute" })
      cache.evict({ id: normalizedId })
      cache.gc()
    },
  })

export { useMutationGroupCreateAttribute, useMutationGroupUpdateAttribute, useMutationGroupDeleteAttribute }
