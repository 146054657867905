import { gql } from "@apollo/client"

const ROLE_FIELDS = gql`
  fragment RoleFields on Role {
    id
    name
    friendlyName
    description
    scope
    isCustom
  }
`

const ROLE_ADMINABLE_FIELDS = gql`
  fragment RoleAdminableFields on Role {
    attributes {
      id
      integration
      key
      value
    }
    createsRoles {
      id
      friendlyName
    }
    inheritsRoles {
      id
      friendlyName
    }
    mentionsRoles {
      id
      friendlyName
    }
    permissions {
      id
      key
      value
    }
    effectivePermissions {
      id
      key
      value
    }
  }
`

export { ROLE_FIELDS, ROLE_ADMINABLE_FIELDS }
