import { gql } from "@apollo/client"

const mutationResetPassword = async ({ username, passcode, password }, client) => {
  try {
    const response = await client.mutate({
      variables: { username, passcode, password },
      mutation: gql`
        mutation ResetPassword($username: String!, $passcode: String!, $password: String!) {
          resetPassword(username: $username, passcode: $passcode, password: $password)
        }
      `,
    })
    return response
  } catch (err) {
    return err
  }
}

export { mutationResetPassword }
