import Config from "react-global-configuration"

const fetchIconMetadata = async () => {
  const { httpBaseUri } = Config.get()
  const response = await fetch(`${httpBaseUri}/icons`)
  if (response.ok) {
    const text = await response.text()
    const icons = JSON.parse(text.substr(4)).icons.map(({ name, tags }) => ({
      name,
      keywords: `${name} ${tags.join(" ")}`,
    }))

    return icons
  }
  return []
}

export { fetchIconMetadata }
