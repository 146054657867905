import { makeVar } from "@apollo/client"
import { toId, useMountAwareReactiveVar } from "../../utils"

const jobStateVar = makeVar({})

const merge = (job, nextState) => {
  const key = getKey(job)

  const currentState = jobStateVar()

  const jobState = currentState[key] || {}

  jobStateVar({
    ...currentState,
    [key]: {
      ...jobState,
      ...nextState,
    },
  })
}

const getKey = (job) => {
  return `${toId(job)}`
}

const useJobState = (job) => {
  const key = getKey(job)
  const state = useMountAwareReactiveVar(jobStateVar)

  if (!job) {
    return [
      false,
      () => {
        throw new Error("Job must be defined")
      },
    ]
  }

  return [state[key], { merge: (next) => merge(job, next), clear: () => merge(job, { index: undefined }) }]
}

export { jobStateVar, getKey, useJobState }
