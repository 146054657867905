import { useQuery, gql } from "@apollo/client"

const SUBSCRIPTION_QUERY = gql`
  query Subscription($pending: Boolean) {
    subscription(pending: $pending) {
      customer {
        created_at
      }
      subscription {
        activated_at
        billing_period
        billing_period_unit
        created_at
        currency_code
        current_term_end
        current_term_start
        due_invoices_count
        has_scheduled_changes
        mrr
        next_billing_at
        plan_amount
        plan_free_quantity
        plan_id
        plan_quantity
        plan_unit_price
        started_at
        status
        trial_start
        trial_end
        updated_at
      }
      plan {
        name
        free_quantity
        trial_period
        trial_period_unit
      }
    }
  }
`

const useQuerySubscription = (options) =>
  useQuery(SUBSCRIPTION_QUERY, {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
    ...options,
  })

export { useQuerySubscription }
