import React from "react"
import { makeStyles, Typography, Box } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: ({ tiny }) => (tiny ? 10 : 12),
    lineHeight: ({ tiny }) => (tiny ? "13px" : "16px"),
    color: ({ light }) => (light ? theme.palette.text.secondary : theme.palette.text.primary),
  },
}))

const Caption = ({ children, mb = 1.5, className = "", tiny = false, light = true, ...rest }) => {
  const classes = useStyles({ tiny, light })

  return (
    <Box mb={mb} {...rest}>
      <Typography variant="h4" className={`${classes.root} ${className}`}>
        {children}
      </Typography>
    </Box>
  )
}

export { Caption }
