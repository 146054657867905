import { useMutation, gql } from "@apollo/client"
import { LOCATION_FIELDS } from "./useQueryLocations"

const UPDATE_LOCATION_MUTATION = gql`
  mutation UpdateLocation($id: ID!, $input: LocationUpdateMutationInput!) {
    location(id: $id) {
      update(input: $input) {
        ...LocationFields
      }
    }
  }
  ${LOCATION_FIELDS}
`

const useMutationUpdateLocation = () => useMutation(UPDATE_LOCATION_MUTATION)

export { useMutationUpdateLocation }
