import React, { useState } from "react"
import { Box, Divider, Tab, Tabs } from "@material-ui/core"
import { Trans } from "@lingui/macro"
import { SupplierNotes } from "./SupplierNotes"
import { toId } from "../../utils"
import { SupplierContactPanel } from "./SupplierContactPanel"
import { NoItemsMessage } from "../Lists"

const formatItemCount = (count) => {
  if (count === 0) {
    return ""
  }

  return ` (${count})`
}

const SupplierDetails = ({ supplier }) => {
  const [tab, setTab] = useState("contacts")

  const handleTabClick = (newTab) => {
    setTab(newTab)
  }

  return (
    <>
      <Tabs
        variant="standard"
        indicatorColor="primary"
        textColor="primary"
        value={tab}
        aria-label="Supplier details tabs"
      >
        <Tab label={<Trans>Contacts</Trans>} value="contacts" onClick={() => handleTabClick("contacts")} />
        <Tab
          label={`Notes${formatItemCount(supplier.notes.length)}`}
          value="notes"
          onClick={() => handleTabClick("notes")}
        />
      </Tabs>
      <Divider />

      <Box pt={3}>
        {tab === "contacts" && (
          <>
            {supplier.contacts?.map((contact) => (
              <SupplierContactPanel key={toId(contact)} contact={contact} mb={1} />
            ))}
            {supplier.contacts?.length === 0 && (
              <NoItemsMessage>
                <Trans>No contacts added</Trans>
              </NoItemsMessage>
            )}
          </>
        )}

        {tab === "notes" && <SupplierNotes supplier={supplier} />}
      </Box>
    </>
  )
}

export { SupplierDetails }
