import React, { useMemo } from "react"
import { Box, Divider, List } from "@material-ui/core"
import { Caption } from "../Headings"
import { toId } from "../../utils"
import { IntegrationMappingAddAttributeForm } from "./IntegrationMappingAddAttributeForm"
import { IntegrationMappingAttributeListItem } from "./IntegrationMappingAttributeListItem"

const IntegrationMappingConfiguration = ({
  integration,
  data,
  source,
  label,
  plural,
  displayPropName,
  addAttribute,
  deleteAttribute,
  components: {
    add: { Destination: AddDestination },
  },
}) => {
  const {
    integrationProvider: { name, attributes },
  } = integration

  const attributesSource = attributes[source]

  const handleAdd = ({ key, value, destination }) => {
    addAttribute({
      variables: {
        id: toId(destination),
        input: {
          integration: toId(integration),
          key,
          value,
        },
      },
    })
  }

  const handleDelete = async (deleteItem, deleteItemAttribute) => {
    await deleteAttribute({
      variables: {
        id: toId(deleteItem),
        attribute: toId(deleteItemAttribute),
      },
    })
  }

  const items = useMemo(
    () =>
      data?.reduce((acc, item) => {
        const itemAttributes = item.attributes.filter(
          (ia) =>
            ia.integration &&
            toId(ia.integration) === toId(integration) &&
            attributesSource.some((itemAttribute) => itemAttribute.key === ia.key)
        )
        if (itemAttributes.length > 0) {
          acc.push(
            ...itemAttributes.map((itemAttribute) => ({
              item,
              itemAttribute,
              attribute: attributesSource.find((asi) => asi.key === itemAttribute.key),
            }))
          )
        }
        return acc
      }, []) || [],
    [attributesSource, data, integration]
  )

  return (
    <Box mt={2}>
      <Divider />

      <Box my={2}>
        <IntegrationMappingAddAttributeForm
          source={source}
          integration={integration}
          components={{
            Destination: AddDestination,
          }}
          onAdd={handleAdd}
        />
      </Box>

      {items.length > 0 && (
        <>
          <Divider />

          <Box m={1}>
            <Caption>
              The following {name} attributes are mapped to your Operandio {plural}.
            </Caption>

            <List disablePadding>
              {items.map(({ item, itemAttribute, attribute }, index) => (
                <React.Fragment key={toId(itemAttribute)}>
                  {index > 0 && <Divider />}
                  <IntegrationMappingAttributeListItem
                    label={label}
                    source={source}
                    displayPropName={displayPropName}
                    integration={integration}
                    item={item}
                    itemAttribute={itemAttribute}
                    attribute={attribute}
                    onDelete={() => handleDelete(item, itemAttribute)}
                  />
                </React.Fragment>
              ))}
            </List>
          </Box>
        </>
      )}
    </Box>
  )
}

export { IntegrationMappingConfiguration }
