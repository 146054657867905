import { useQuery, gql } from "@apollo/client"

const PROCESS_SET_COUNTS_QUERY = gql`
  query ProcessSetCounts {
    processes {
      activeRecurring: count(filter: { set: "active-recurring" })
      activeOneoff: count(filter: { set: "active-oneoff" })
      activeAdhoc: count(filter: { set: "active-adhoc" })
      activeUnscheduled: count(filter: { set: "active-unscheduled" })
      inactiveRecurring: count(filter: { set: "inactive-recurring" })
      inactiveOneoff: count(filter: { set: "inactive-oneoff" })
      inactiveAdhoc: count(filter: { set: "inactive-adhoc" })
      inactiveUnscheduled: count(filter: { set: "inactive-unscheduled" })
    }
  }
`

const useQueryProcessSetCounts = (options) =>
  useQuery(PROCESS_SET_COUNTS_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

export { useQueryProcessSetCounts }
