import { Skeleton } from "@material-ui/lab"
import pluralize from "pluralize"
import { RowBox } from "../Boxes"

const TablePageIndicator = ({ subject = "items", total, loading, offset, limit, hideWhenNone }) => {
  const isLastPage = total <= offset + limit
  const rangeTo = isLastPage ? total : offset + limit

  if (!loading && hideWhenNone && !total) {
    return null
  }

  return (
    <RowBox>
      {loading && <Skeleton variant="text" width={120} />}
      {!loading && !!total && (
        <>
          {offset + 1} - {rangeTo} of {total.toLocaleString()} {pluralize(subject, total)}
        </>
      )}
      {!loading && !total && <>No matching {subject}</>}
    </RowBox>
  )
}

export { TablePageIndicator }
