import { useLazyQueryTrainingModules, useQueryUserTrainings } from "../data"
import { useAuth } from "../services"
import { toId } from "./data"
import { useMountEffect } from "./useMountEffect"

const useTraining = () => {
  const {
    principal: { userID },
    hasPermission,
    permissionGroups: { adminTraining },
  } = useAuth()
  const { data, loading } = useQueryUserTrainings({
    variables: {
      user: userID,
    },
  })
  const [loadModules, { data: modulesData }] = useLazyQueryTrainingModules()

  useMountEffect(() => {
    if (hasPermission(adminTraining)) {
      loadModules()
    }
  })

  const getActiveTrainingForKnowledge = (knowledge) => {
    // find training with active module
    const userTraining = data?.userTrainings.list.find((item) => !item.status.completedAt && item.status.activeModule)
    if (userTraining) {
      // get the active module
      const activeModule = userTraining.status.modules.find(
        (item) => toId(item) === toId(userTraining.status.activeModule),
      )
      if (activeModule) {
        // try and find active or completed knowledge in active module
        const activeModuleKnowledge = activeModule.knowledges.find(
          (item) =>
            toId(item.knowledge) === toId(knowledge) &&
            (toId(userTraining.status.activeKnowledge, true) === toId(item.knowledge) || item.completedAt),
        )

        if (activeModuleKnowledge) {
          return {
            userTraining,
            module: activeModule,
            moduleKnowledge: activeModuleKnowledge,
            knowledgeActive:
              !!activeModuleKnowledge && userTraining.status.activeKnowledge === toId(activeModuleKnowledge.knowledge),
            knowledgeCompleted: activeModuleKnowledge?.completedAt,
          }
        }
      }

      // try and find in any completed module
      const module = userTraining.status.modules.find(
        (item) => item.completedAt && item.knowledges.some((k) => toId(k.knowledge) === toId(knowledge)),
      )
      const moduleKnowledge = module?.knowledges.find((item) => toId(knowledge) === toId(item.knowledge)) || null
      if (module) {
        return {
          userTraining,
          module,
          moduleKnowledge,
          knowledgeActive: false,
          knowledgeCompleted: moduleKnowledge?.completedAt,
        }
      }
    }
    return null
  }

  const getTrainingForKnowledge = (knowledge) => {
    const userTrainings = data?.userTrainings.list.filter((item) => !item.status.completedAt)

    if (userTrainings) {
      const userTraining = userTrainings.find((_userTraining) => {
        return _userTraining.status.modules.find((item) =>
          item.knowledges.some((k) => toId(k.knowledge) === toId(knowledge)),
        )
      })

      if (userTraining) {
        const module = userTraining.status.modules.find((item) =>
          item.knowledges.some((k) => toId(k.knowledge) === toId(knowledge)),
        )

        const moduleKnowledge = module?.knowledges.find((item) => toId(knowledge) === toId(item.knowledge)) || null
        if (module) {
          return {
            userTraining,
            module,
            moduleKnowledge,
            knowledgeActive: true,
            knowledgeCompleted: moduleKnowledge?.completedAt,
          }
        }
      }
    }
    return null
  }

  const getActiveModuleIndex = ({ status }) =>
    status.activeModule ? status.modules.findIndex((module) => toId(module) === toId(status.activeModule)) : -1

  const getActiveModule = (userTraining) => {
    const activeModuleIndex = getActiveModuleIndex(userTraining)
    return activeModuleIndex > -1 ? userTraining.status.modules[activeModuleIndex] : null
  }

  // const modulesHaveCompletedKnowledge = (modules, knowledge) =>
  //   modules.some((module) =>
  //     module.knowledges.some((item) => item.completedAt && toId(item.knowledge) === toId(knowledge))
  //   )

  return {
    getActiveTrainingForKnowledge,
    getTrainingForKnowledge,
    getActiveModuleIndex,
    getActiveModule,

    modules: modulesData?.trainingModules || [],
    loading,
  }
}

export { useTraining }
