import { useLazyQuery, useQuery, gql } from "@apollo/client"
import { TAG_FIELDS } from "../tags/useQueryTags"

const KNOWLEDGE_FIELDS = gql`
  fragment KnowledgeFields on KnowledgeV2 {
    id
    title
    category {
      id
      name
    }
    tags {
      ...TagFields
    }
    groups {
      id
    }
    locations {
      id
    }
    content {
      ... on KnowledgeContentText {
        id
        heading
        text
      }
      ... on KnowledgeContentImage {
        id
        heading
        uploads {
          id
          key
          fileGroup
          fileSize
          fileName
        }
      }
      ... on KnowledgeContentFiles {
        id
        heading
        uploads {
          id
          key
          fileGroup
          fileSize
          fileName
        }
      }
      ... on KnowledgeContentNote {
        id
        heading
        type
        text
      }
      ... on KnowledgeContentVideo {
        id
        heading
        url
      }
      ... on KnowledgeContentMultichoice {
        id
        heading
        question
        answers {
          id
          text
          isCorrect
        }
      }
    }
    author {
      id
      firstName
      lastName
      avatar {
        key
      }
    }
    createdAt
    updatedAt
  }
  ${TAG_FIELDS}
`

const KNOWLEDGES_QUERY = gql`
  query Knowledges($filter: KnowledgeFilterInput) {
    knowledges(filter: $filter) {
      ...KnowledgeFields
    }
  }
  ${KNOWLEDGE_FIELDS}
`

const KNOWLEDGE_QUERY = gql`
  query Knowledge($id: ID!) {
    knowledge: knowledgeV2(id: $id) {
      ...KnowledgeFields
    }
  }
  ${KNOWLEDGE_FIELDS}
`

const KNOWLEDGE_CREATED_SUBSCRIPTION = gql`
  subscription KnowledgeCreated {
    knowledgeCreated {
      ...KnowledgeFields
    }
  }
  ${KNOWLEDGE_FIELDS}
`
const KNOWLEDGE_UPDATED_SUBSCRIPTION = gql`
  subscription KnowledgeUpdated {
    knowledgeUpdated {
      ...KnowledgeFields
    }
  }
  ${KNOWLEDGE_FIELDS}
`

const subscribe = (result) => () =>
  [
    result.subscribeToMore({
      document: KNOWLEDGE_CREATED_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        const newItem = subscriptionData.data.knowledgeCreated

        // skip if already exists
        if (prev.knowledges.some((p) => p.id === newItem.id)) return prev

        return { ...prev, knowledges: [newItem, ...prev.knowledges] }
      },
      onError: (err) => console.log("[useQueryKnowledges][KNOWLEDGE_CREATED_SUBSCRIPTION(1)]", err),
    }),
    result.subscribeToMore({
      document: KNOWLEDGE_UPDATED_SUBSCRIPTION,
      onError: (err) => console.log("[useQueryKnowledges][KNOWLEDGE_UPDATED_SUBSCRIPTION]", err),
    }),
  ]

const subscribeSingle = (result) => () =>
  [
    result.subscribeToMore({
      document: KNOWLEDGE_UPDATED_SUBSCRIPTION,
      onError: (err) => console.log("[useQueryKnowledges][KNOWLEDGE_UPDATED_SUBSCRIPTION(2)]", err),
    }),
  ]

const useQueryKnowledges = (options) => {
  const result = useQuery(KNOWLEDGES_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

  return {
    ...result,
    subscribe: subscribe(result),
  }
}

const useQueryKnowledge = (options) => {
  const result = useQuery(KNOWLEDGE_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

  return {
    ...result,
    subscribe: subscribeSingle(result),
  }
}

const useLazyQueryKnowledge = () => useLazyQuery(KNOWLEDGE_QUERY)

export { useQueryKnowledges, useQueryKnowledge, useLazyQueryKnowledge, KNOWLEDGE_FIELDS, KNOWLEDGES_QUERY }
