import { useMutation, gql } from "@apollo/client"
import { ADMINABLE_USERS_FIELDS } from "./fragments/adminableUserFieldsFragment"

const UPDATE_USERS_MUTATION = gql`
  mutation UpdateUsers($ids: [ID!]!, $input: AdminableUsersUpdateInput!) {
    adminableUsers(ids: $ids) {
      update(input: $input) {
        ...AdminableUsersFields
      }
    }
  }
  ${ADMINABLE_USERS_FIELDS}
`

const useMutationUpdateUsers = () => useMutation(UPDATE_USERS_MUTATION)

export { useMutationUpdateUsers }
