import { useMutation, gql } from "@apollo/client"
import { GROUP_FIELDS } from "./fragments/groupFieldsFragment"

const CREATE_GROUP_MUTATION = gql`
  mutation CreateGroup($input: GroupCreateMutationInput!) {
    createGroup(input: $input) {
      ...GroupFields
    }
  }
  ${GROUP_FIELDS}
`

const useMutationCreateGroup = () => useMutation(CREATE_GROUP_MUTATION)

export { useMutationCreateGroup }
