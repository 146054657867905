import React, { useState } from "react"
import { Box, Button, Chip, makeStyles, Typography } from "@material-ui/core"
import moment from "moment"
import { ColumnBox, Overdue, PaperBox, RowBox } from "../Boxes"
import {
  useLazyQueryAdminableUser,
  useMutationCreateUserTraining,
  useMutationDeleteUserTraining,
  useQueryUserTrainings,
} from "../../data"
import { toId, useTraining } from "../../utils"
import { TrainingOutlinedSelect } from "../Training"
import { LinearProgress } from "../LinearProgress"
import { FieldSectionHeading } from "../Headings"
import TimeAgo from "../TimeAgo"
import { LoadingSpinner } from "../LoadingSpinner"
import { FormatDateCompact } from "../Format"
import { DeleteConfirmIconButton } from "../Buttons"
import { Icon } from "../Icon"
import { PersonTrainingViewer } from "./PersonTrainingViewer"
import { TrainingScoreChip } from "../Chips/TrainingScoreChip"
import { ErrorBoundary } from "../ErrorBoundary"

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: "500",
  },
  caption: {
    fontSize: 12,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
    textAlign: "right",
    whiteSpace: "nowrap",
  },
  item: {
    cursor: "pointer",
  },
  progress: {
    width: 100,
  },
  completedChip: {
    color: "white",
    backgroundColor: theme.palette.success.main,
    marginRight: theme.spacing(1),
  },
  remove: {
    cursor: "pointer",
    marginLeft: theme.spacing(1),
  },
}))

const PersonTraining = ({ user }) => {
  const classes = useStyles()
  const [trainingCourse, setTrainingCourse] = useState("")
  const [view, setView] = useState(null)
  const { getActiveModuleIndex } = useTraining()
  const {
    data,
    loading: loadingUserTrainings,
    refetch,
  } = useQueryUserTrainings({
    variables: {
      user: toId(user),
      filter: {
        withCompleted: true,
      },
    },
  })
  const [loadAdminableUser] = useLazyQueryAdminableUser({ variables: { id: toId(user) } })
  const [createUserTraining, { loading: loadingCreateUserTraining }] = useMutationCreateUserTraining()
  const [deleteUserTraining] = useMutationDeleteUserTraining()

  const handleTrainingChange = (event) => {
    setTrainingCourse(event.target.value)
  }

  const handleAddTraining = async () => {
    await createUserTraining({
      variables: {
        course: trainingCourse,
        user: toId(user),
      },
    })
    refetch()
    loadAdminableUser()
    setTrainingCourse("")
  }

  const handleRemoveTraining = async (item) => {
    await deleteUserTraining({
      variables: {
        id: toId(item),
      },
    })
    refetch()
    loadAdminableUser()
  }

  return (
    <ErrorBoundary>
      <PersonTrainingViewer userTraining={view} onClose={() => setView(null)} />

      <Box mb={5} data-cy="PersonTraining">
        <FieldSectionHeading>Assign training</FieldSectionHeading>
        <RowBox mt={1}>
          <TrainingOutlinedSelect
            value={trainingCourse}
            onChange={handleTrainingChange}
            hasNone={false}
            disabled={loadingCreateUserTraining}
            filterActiveUserTrainings={data?.userTrainings?.list || null}
          />
          <Box ml={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddTraining}
              disabled={!trainingCourse || loadingCreateUserTraining}
              data-cy="Button-add-training"
            >
              {!loadingCreateUserTraining && <>Assign</>}
              {loadingCreateUserTraining && <LoadingSpinner size="24px" delay={false} />}
            </Button>
          </Box>
        </RowBox>
        {Boolean(data?.userTrainings?.list.length) && (
          <Box mt={3} data-cy="PersonTraining-list">
            <FieldSectionHeading>Training courses</FieldSectionHeading>
            {[...data.userTrainings.list]
              .sort((a, b) => (moment(a.createdAt).isAfter(moment(b.createdAt)) ? -1 : 1))
              .map((userTraining, index) => {
                const { id, name, dueAt, status, createdAt } = userTraining
                const completed = Boolean(status?.completedAt)
                const overdue = !completed && dueAt ? moment(dueAt).isBefore(moment()) : false
                const activeModuleIndex = getActiveModuleIndex(userTraining)
                const activeModule = activeModuleIndex > -1 ? status.modules[activeModuleIndex] : null

                return (
                  <Box key={id} mt={index > 0 ? 1 : 0} onClick={() => setView(userTraining)} className={classes.item}>
                    <PaperBox completed={completed} overdue={overdue}>
                      <RowBox p={2} alignItems="center">
                        {overdue && (
                          <Box mr={1}>
                            <Overdue overdue>
                              <Icon name="warning" />
                            </Overdue>
                          </Box>
                        )}
                        <ColumnBox>
                          <Typography variant="h2" className={classes.title}>
                            {name}
                          </Typography>
                          <Box className={classes.caption}>
                            Assigned <TimeAgo date={createdAt} />
                            {Boolean(dueAt) && (
                              <>
                                ,{" "}
                                <Overdue overdue={overdue} strong>
                                  due <FormatDateCompact value={dueAt} />
                                </Overdue>
                              </>
                            )}
                          </Box>
                        </ColumnBox>
                        {!completed && (
                          <RowBox ml="auto" alignContent="center">
                            <Box className={classes.caption} mr={2}>
                              {activeModuleIndex + 1} of {status.modules.length}:{" "}
                              {activeModule ? activeModule.name : "Complete"}
                            </Box>
                            <Box className={classes.progress} mr={2}>
                              <LinearProgress value={status.percentComplete} overdue={overdue} />
                            </Box>
                            <DeleteConfirmIconButton onDelete={() => handleRemoveTraining(userTraining)} />
                          </RowBox>
                        )}
                        {completed && (
                          <RowBox ml="auto">
                            {status.score.total > 0 && (
                              <Box mx={1}>
                                <TrainingScoreChip tooltip="Course score" label={`${status.score.percent}%`} />
                              </Box>
                            )}
                            <Box className={classes.caption} pr={1}>
                              <TimeAgo date={status.completedAt} />
                            </Box>
                            <Chip label="Complete" size="small" className={classes.completedChip} />
                          </RowBox>
                        )}
                      </RowBox>
                    </PaperBox>
                  </Box>
                )
              })}
          </Box>
        )}
        {!data?.userTrainings && loadingUserTrainings && (
          <Box display="flex" justifyContent="center" mt={3}>
            <LoadingSpinner size={60} />
          </Box>
        )}
      </Box>
    </ErrorBoundary>
  )
}

export { PersonTraining }
