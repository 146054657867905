import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Chip } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    color: ({ color }) => (color ? theme.palette.text.white : theme.palette.text.primary),
    backgroundColor: ({ color }) => (color ? theme.palette[color].main : undefined),
  },
}))

const CommonChip = ({ color, ...rest }) => {
  const classes = useStyles({
    color,
  })

  return <Chip classes={classes} {...rest} />
}

export { CommonChip }
