import React, { forwardRef, memo } from "react"
import { Box } from "@material-ui/core"

const RowBox = forwardRef(function RowBox({ alignItems = "center", children, ...rest }, ref) {
  return (
    <Box ref={ref} display="flex" flexDirection="row" alignItems={alignItems} {...rest}>
      {children}
    </Box>
  )
})

const RowBoxMemo = memo(RowBox)

export { RowBoxMemo as RowBox }
