import { gql } from "@apollo/client"
import { TAG_FIELDS } from "../../tags/useQueryTags"

const SUPPLIER_FIELDS = gql`
  fragment SupplierFields on Supplier {
    id
    name
    supplies
    accountNumber
    website
    locations {
      id
      name
    }
    groups {
      id
      name
    }
    tags {
      ...TagFields
    }
    notes {
      id
      text
      author {
        id
        fullName
        firstName
        lastName
        avatar {
          key
        }
      }
      uploads {
        id
        key
        fileName
        fileGroup
        fileSize
      }
      createdAt
    }
    contacts {
      id
      name
      email
      emailSecondary
      phone
      phoneEmergency
      fax
      notes
      address {
        name
        line1
        line2
        city
        postcode
        country
        notes
      }
    }
  }
  ${TAG_FIELDS}
`

export { SUPPLIER_FIELDS }
