import React, { useState } from "react"
import { Button, Hidden, Menu, makeStyles, MenuItem, ListItemIcon, Box } from "@material-ui/core"
import { SpeedDialAction } from "@material-ui/lab"
import { Trans } from "@lingui/macro"
import { FloatingSpeedDial, Icon, RequirePermissions } from ".."
import { useAuth } from "../../services"

const useStyles = makeStyles((theme) => ({
  menu: {
    marginTop: 50,
  },
  menuListItemIcon: {
    minWidth: "auto",
    paddingRight: theme.spacing(2),
  },
}))

const useSpeedDialActionStyles = makeStyles(() => ({
  staticTooltipLabel: {
    whiteSpace: "nowrap",
  },
}))

const requiresPermissions = ["label_update", "label_update_all", "label_template_update"]

const DesktopCreateLabelsButton = ({ onCreateClick }) => {
  const classes = useStyles()
  const { hasFeature } = useAuth()
  const [showCreateOptions, setShowCreateOptions] = useState(false)
  const [showCreateOptionsEl, setShowCreateOptionsEl] = useState(null)

  const handleCreateClick = (type) => {
    setShowCreateOptions(false)
    setShowCreateOptionsEl(null)
    onCreateClick && onCreateClick(type)
  }

  const handleToggleCreateMenu = ({ currentTarget }) => {
    setShowCreateOptionsEl(!showCreateOptions ? currentTarget : null)
    setShowCreateOptions(!showCreateOptions)
  }

  const hasLabels = hasFeature("labels")

  return (
    <RequirePermissions operator="or" requires={requiresPermissions}>
      {hasLabels && (
        <Box display="flex" flexDirection="row">
          <Button onClick={handleToggleCreateMenu} variant="contained" color="primary" data-cy="Button-create-label">
            <Trans>Create</Trans>
          </Button>
          <Menu
            anchorEl={showCreateOptionsEl}
            keepMounted
            open={showCreateOptions}
            onClose={handleToggleCreateMenu}
            className={classes.menu}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={() => handleCreateClick("label")} data-cy="MenuItem-create-label">
              <ListItemIcon className={classes.menuListItemIcon}>
                <Icon name="labels-item" />
              </ListItemIcon>
              <Trans>Create a new label</Trans>
            </MenuItem>
            <RequirePermissions requires={["label_template_update"]}>
              <Hidden smDown>
                <MenuItem onClick={() => handleCreateClick("template")} data-cy="MenuItem-create-template">
                  <ListItemIcon className={classes.menuListItemIcon}>
                    <Icon name="labels-template" />
                  </ListItemIcon>
                  <Trans>Create a new label template</Trans>
                </MenuItem>
              </Hidden>
            </RequirePermissions>
          </Menu>
        </Box>
      )}
    </RequirePermissions>
  )
}

const MobileCreateLabelsButton = ({ creatorOpen, onCreateClick }) => {
  const classes = useSpeedDialActionStyles()
  const { hasFeature } = useAuth()
  const [openSpeedDial, setOpenSpeedDial] = useState(false)

  const handleCreateClick = (type) => {
    handleToggleSpeedDial()
    onCreateClick && onCreateClick(type)
  }

  const handleToggleSpeedDial = () => {
    setOpenSpeedDial(!openSpeedDial)
  }

  const hasLabels = hasFeature("labels")

  return (
    <RequirePermissions operator="or" requires={requiresPermissions}>
      {hasLabels && !creatorOpen && (
        <FloatingSpeedDial onClose={handleToggleSpeedDial} onOpen={handleToggleSpeedDial} open={openSpeedDial}>
          <RequirePermissions requires={["label_template_update"]}>
            <Hidden smDown>
              <SpeedDialAction
                tooltipTitle={<Trans>Create a new label template</Trans>}
                icon={<Icon name="labels-template" />}
                tooltipOpen
                open={openSpeedDial}
                onClick={() => handleCreateClick("template")}
                classes={classes}
              />
            </Hidden>
          </RequirePermissions>
          <SpeedDialAction
            tooltipTitle={<Trans>Create a new label</Trans>}
            icon={<Icon name="labels-item" />}
            tooltipOpen
            onClick={() => handleCreateClick("label")}
            classes={classes}
          />
        </FloatingSpeedDial>
      )}
    </RequirePermissions>
  )
}

export { DesktopCreateLabelsButton, MobileCreateLabelsButton }
