import { useMutation, gql } from "@apollo/client"
import { KNOWLEDGE_FIELDS } from "./useQueryKnowledges"

const UPDATEKNOWLEDGE_MUTATION = gql`
  mutation UpdateKnowledge($id: ID!, $input: KnowledgeInput!) {
    knowledge(id: $id) {
      update(input: $input) {
        ...KnowledgeFields
      }
    }
  }
  ${KNOWLEDGE_FIELDS}
`

const useMutationUpdateKnowledge = () => useMutation(UPDATEKNOWLEDGE_MUTATION)

export { useMutationUpdateKnowledge }
