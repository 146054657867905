import React, { useState } from "react"
import { Tooltip as MuiTooltip } from "@material-ui/core"
import { preventDefault } from "../../utils"

const Tooltip = ({ title, children, ...rest }) => {
  const [open, setTooltip] = useState(false)

  return (
    <MuiTooltip
      disableFocusListener
      open={open}
      onOpen={(event) => preventDefault(event, () => setTooltip(true))}
      onClose={() => setTooltip(false)}
      title={title}
      {...rest}
    >
      {children}
    </MuiTooltip>
  )
}

export { Tooltip }
