import { useQuery, useLazyQuery, gql } from "@apollo/client"

const REPORTJOBSOVERVIEW_FIELDS = `
  process
  name
  steps {
    completed
    overdue
    uncompleted
    skipped    
  }
`

const REPORTJOBSOVERVIEW_QUERY = gql`
  query ReportJobsOverview($locations: [ID!]!, $groups: [ID!], $gt: ScheduleDateInput!, $lt: ScheduleDateInput) {
    reportJobsOverview(locations: $locations, groups: $groups, gt: $gt, lt: $lt)
    {
      ${REPORTJOBSOVERVIEW_FIELDS}
    } 
  }
`

const useQueryReportJobsOverview = (options) =>
  useQuery(REPORTJOBSOVERVIEW_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

const useLazyQueryReportJobsOverview = (options) =>
  useLazyQuery(REPORTJOBSOVERVIEW_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

export { useQueryReportJobsOverview, useLazyQueryReportJobsOverview }
