import { gql, useLazyQuery, useQuery } from "@apollo/client"
import { ACTION_FIELDS } from "../actions/fragments/actionFieldsFragment"

const JOB_STEP_ACTIONS_QUERY = gql`
  query JobStep($job: ID!, $step: ID!) {
    job(id: $job) {
      step(id: $step) {
        actions {
          ...ActionFields
        }
      }
    }
  }
  ${ACTION_FIELDS}
`

const useQueryJobStepActions = (options) => useQuery(JOB_STEP_ACTIONS_QUERY, options)

const useLazyQueryJobStepActions = (options) => useLazyQuery(JOB_STEP_ACTIONS_QUERY, options)

export { useQueryJobStepActions, useLazyQueryJobStepActions }
