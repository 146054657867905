import React from "react"
import { ListItemAvatar, ListItemText } from "@material-ui/core"
import { Avatar, CheckboxPickerListItem } from ".."
import { TruncateNames } from "../DataDisplay/TruncateNames"
import { useAuth } from "../../services"
import { toId } from "../../utils"

const UserPickerListItem = ({ showLocations, ...props }) => {
  const {
    settings: { locations: userLocations },
  } = useAuth()

  return (
    <CheckboxPickerListItem {...props}>
      <ListItemAvatar>
        <Avatar {...props.item} />
      </ListItemAvatar>
      <ListItemText
        primary={props.item.fullName}
        secondary={
          showLocations && props.item.locations ? (
            <TruncateNames
              names={props.item.locations
                .filter((location) => userLocations.map((item) => toId(item)).includes(toId(location)))
                .map((location) => location.name)
                .sort((a, b) => a.localeCompare(b))}
              max={3}
            />
          ) : null
        }
      />
    </CheckboxPickerListItem>
  )
}

export default UserPickerListItem
