import Base from "./Base"

const config = {
  ...Base,
  httpBaseUri: "https://local.operandiodev.com:5020",
  httpServiceBaseUri: "https://local.operandiodev.com:5020/graphql",
  wsServiceBaseUri: "wss://local.operandiodev.com:5020/graphql",

  showConfigInConsole: true,
  showConfigInTitle: true,
  configTitle: "[LOCAL]",

  dataClient: {
    ...Base.dataClient,
    connectToDevTools: true,
  },

  theme: {
    ...Base.theme,

    logoContainer: "images-dev",
  },

  uploader: {
    ...Base.uploader,

    imageViewerBaseUri: "https://d2y50qvg2q46in.cloudfront.net",
    imageViewerBucketName: "dev-operandio-content-ap-southeast-2",
  },

  services: {
    ...Base.services,
    export: {
      ...Base.services.export,
      jobUrl: "https://lxknk3pfv9.execute-api.ap-southeast-2.amazonaws.com/prod/job/", // DEV account
      auditUrl: "https://lxknk3pfv9.execute-api.ap-southeast-2.amazonaws.com/prod/auditscores/", // DEV account
    },
  },

  errors: {
    ...Base.errors,

    showDetail: false,
    showGraphQLErrorNotification: true,
  },
}

config.dataClient.throwIfNoOperationName = true

export default config
