import { gql } from "@apollo/client"
import { COMPACT_RESPONSE_SELECTION_OPTION_FIELDS } from "../../processes/fragments/compactResponseSelectionFields"
import { RESPONSE_SET_FIELDS } from "../../processes/fragments/responseSetFragment"

const REPORT_JOB_DETAIL_STEPS_FIELDS = gql`
  fragment ReportJobDetailStepsFields on ReportJobInstanceStep {
    id
    step
    name
    responseType
    response
    responseUploads {
      id
      key
      fileName
      fileGroup
      fileSize
    }
    skip
    completedAt
    completedBy
    completedByFullName
    format {
      unit
      decimals
    }
    notes {
      id
      text
      createdAt
      author {
        id
        fullName
        firstName
        lastName
        avatar {
          key
        }
      }
    }
    selection {
      selectFrom
      allowMultiple
      customItems
    }
    responseCustomSelections
    responseSelections {
      ...CompactResponseSelectionFields
    }
    responseSet {
      ...ResponseSetFields
    }
  }
  ${COMPACT_RESPONSE_SELECTION_OPTION_FIELDS}
  ${RESPONSE_SET_FIELDS}
`

export { REPORT_JOB_DETAIL_STEPS_FIELDS }
