const useKnowledgeUtils = () => {
  const getNavigateToCategoryForID = (id) => `/knowledge/category/${id}`

  const getNavigateToArticleLinkForID = (id) => `/knowledge/article/${id}`

  return {
    getNavigateToCategoryForID,
    getNavigateToArticleLinkForID,
  }
}

export { useKnowledgeUtils }
