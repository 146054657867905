import { useMutation, gql } from "@apollo/client"
import { LABEL_FIELDS } from "./useQueryLabels"

const CREATELABEL_MUTATION = gql`
  mutation CreateLabel(
  $input: LabelInput!, 
  ) {
    createLabel(
    input: $input
    ) {
      ${LABEL_FIELDS}
    } 
  }
`

const useMutationCreateLabel = () => useMutation(CREATELABEL_MUTATION)

export { useMutationCreateLabel }
