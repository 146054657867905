import { makeStyles } from "@material-ui/styles"
import React, { memo } from "react"

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "1px solid #EFEFEF",
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    "&:last-child": {
      borderBottom: "none",
    },
  },
}))

const PostSection = ({ className, children }) => {
  const classes = useStyles()

  return <div className={`${classes.root} ${className}`}>{children}</div>
}

const PostSectionMemo = memo(PostSection)

export { PostSectionMemo as PostSection }
