import React from "react"
import { makeStyles, IconButton } from "@material-ui/core"
import { Close as CloseIcon } from "@material-ui/icons"

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    right: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

const InlineTitleCloser = ({ onClose, cy = "DialogTitleCloser" }) => {
  const classes = useStyles()
  return (
    <IconButton aria-label="close" className={classes.root} onClick={onClose} data-cy={`${cy}-button`}>
      <CloseIcon />
    </IconButton>
  )
}

export { InlineTitleCloser }
