import { gql } from "@apollo/client"
import { LOGIN_FIELDS } from "./fragments/loginFieldsFragment"

const queryLoginWithUsernameAndPassword = async (credentials, client) => {
  const { username, password } = credentials
  try {
    const response = await client.query({
      query: gql`
        query LoginWithUsernameAndPassword($username: String!, $password: String!) {
          login(username: $username, password: $password) {
            ...LoginFields
          }
        }
        ${LOGIN_FIELDS}
      `,
      fetchPolicy: "network-only",
      variables: {
        username,
        password,
      },
    })
    return response
  } catch (err) {
    return err
  }
}

const queryLoginWithExternal = async ({ identityProvider, idToken }, client) => {
  try {
    const response = await client.query({
      query: gql`
        query LoginWithExternal($identityProvider: ID!, $idToken: String!) {
          loginWithExternal(identityProvider: $identityProvider, idToken: $idToken) {
            ...LoginFields
          }
        }
        ${LOGIN_FIELDS}
      `,
      fetchPolicy: "network-only",
      variables: {
        identityProvider,
        idToken,
      },
    })
    return response
  } catch (err) {
    return err
  }
}

const queryLoginWithGoogle = async ({ idToken, client: clientType }, client) => {
  try {
    const response = await client.query({
      query: gql`
        query LoginWithGoogle($idToken: String!, $client: GoogleLoginClient!) {
          loginWithGoogle(idToken: $idToken, client: $client) {
            ...LoginFields
          }
        }
        ${LOGIN_FIELDS}
      `,
      fetchPolicy: "network-only",
      variables: {
        idToken,
        client: clientType,
      },
    })
    return response
  } catch (err) {
    return err
  }
}

const queryLoginWithMicrosoft = async ({ idToken }, client) => {
  try {
    const response = await client.query({
      query: gql`
        query LoginWithMicrosoft($idToken: String!) {
          loginWithMicrosoft(idToken: $idToken) {
            ...LoginFields
          }
        }
        ${LOGIN_FIELDS}
      `,
      fetchPolicy: "network-only",
      variables: {
        idToken,
      },
    })
    return response
  } catch (err) {
    return err
  }
}

export { queryLoginWithUsernameAndPassword, queryLoginWithExternal, queryLoginWithGoogle, queryLoginWithMicrosoft }
