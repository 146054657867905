import React from "react"
import { MuiPickersUtilsProvider, DateTimePicker } from "@impelsys/material-ui-pickers"
import MomentUtils from "@date-io/moment"

const DialogDateTimePicker = ({ open, value, onChange, onClose, ...rest }) => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <DateTimePicker
      open={open}
      variant="dialog"
      value={value}
      onChange={onChange}
      onClose={onClose}
      TextFieldComponent={() => null}
      showTodayButton
      {...rest}
    />
  </MuiPickersUtilsProvider>
)

export { DialogDateTimePicker }
