import React, { useState } from "react"

import { FormControl, Divider, Collapse, Box, IconButton, makeStyles } from "@material-ui/core"
import PropTypes from "prop-types"
import { Done as DoneIcon, Edit as EditIcon } from "@material-ui/icons"

import { RowBox } from "../Boxes"
import { preventDefault, toId } from "../../utils"
import { jobStepTypes } from "../../types"
import SelectionField from "../TextField/SelectionField"
import { Icon } from "../Icon"
import { PROCESS_STEP_SELECTION_SOURCES } from "../../data"
import SelectionPeekDialog from "./SelectionPeekDialog"

const useStyles = makeStyles((theme) => ({
  selection: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  links: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: theme.spacing(1),
  },
  link: {
    cursor: "pointer",
    display: "inline-block",
    color: theme.palette.primary.main,
  },
  icon: {
    display: "inline-block",
    verticalAlign: "middle",
    width: 16,
    height: 16,
    marginRight: 2,
    marginTop: -3,
  },
}))

const SelectionStep = ({
  step,
  expand,
  collapseTimeout = "auto",
  classes,
  onChange,
  onComplete,
  value,
  completed = false,
  isPreview = false,
  location,
}) => {
  const selectionClasses = useStyles()
  const { selection } = step
  const isError = selection?.allowMultiple && value.length === 0
  const [open, setOpen] = useState(false)
  const [selectedItemId, setSelectedItemId] = useState({})

  const handleAutocompleteChange = (event, option) => {
    if (onChange) onChange(event, option)
  }

  const handleCompletedChange = (event) => {
    if (onComplete) onComplete(event)
  }

  const handleClickLink = (item) => {
    setOpen(true)
    setSelectedItemId(item?.id)
  }

  const handleCloseSelectionPeekDialog = () => {
    setOpen(false)
    setSelectedItemId()
  }

  const getIcon = () => {
    switch (selection?.selectFrom) {
      case PROCESS_STEP_SELECTION_SOURCES.ASSETS:
        return <Icon name="asset" className={selectionClasses.icon} />
      case PROCESS_STEP_SELECTION_SOURCES.SUPPLIERS:
        return <Icon name="supplier" className={selectionClasses.icon} />
      default:
        return null
    }
  }

  const renderLinks = () => {
    if (
      !value ||
      (selection?.allowMultiple && value.length === 0) ||
      ![PROCESS_STEP_SELECTION_SOURCES.SUPPLIERS, PROCESS_STEP_SELECTION_SOURCES.ASSETS].includes(selection?.selectFrom)
    )
      return null

    const links = selection?.allowMultiple ? value : [value]

    return (
      <RowBox className={selectionClasses.links}>
        {links.map((link) => (
          <Box key={toId(link)}>
            <Box className={selectionClasses.link} onClick={() => handleClickLink(link)} rel="noreferrer">
              {getIcon(selection.selectFrom)}
              <span>{link.name}</span>
            </Box>
          </Box>
        ))}
      </RowBox>
    )
  }

  return (
    <Collapse in={expand} timeout={collapseTimeout}>
      <Divider className={classes.divider} />

      <Box className={selectionClasses.selection}>
        <RowBox alignItems="start">
          <FormControl variant="outlined" fullWidth error={isError}>
            <SelectionField
              selection={selection}
              value={value}
              onChange={handleAutocompleteChange}
              disabled={selection?.allowMultiple && completed}
              isPreview={isPreview}
              location={location}
            />
          </FormControl>
          {selection?.allowMultiple && (
            <Box ml={1} pt={0.5}>
              {!completed && (
                <Box onClick={preventDefault}>
                  <IconButton onClick={handleCompletedChange} disabled={isError} className={classes.incompletedButton}>
                    <DoneIcon />
                  </IconButton>
                </Box>
              )}
              {completed && (
                <IconButton onClick={handleCompletedChange} className={classes.completedButton}>
                  <EditIcon />
                </IconButton>
              )}
            </Box>
          )}
        </RowBox>
        {completed && renderLinks()}
      </Box>

      {open && selectedItemId && (
        <SelectionPeekDialog
          id={selectedItemId}
          source={selection?.selectFrom}
          open={open}
          onClose={handleCloseSelectionPeekDialog}
        />
      )}
    </Collapse>
  )
}

SelectionStep.propTypes = {
  step: jobStepTypes,
  expand: PropTypes.bool,
  collapseTimeout: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  onComplete: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  completed: PropTypes.bool,
  isPreview: PropTypes.bool,
}

export default SelectionStep
