import React from "react"
import { Divider, makeStyles, MenuItem } from "@material-ui/core"
import { t } from "@lingui/macro"
import { NON_RECURRING_SCHEDULE_FILTERS, SCHEDULE_FILTERS } from "../../data"
import { OutlinedSelect } from "./OutlinedSelect"

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 125,
  },
}))

const ScheduleOutlinedSelect = ({
  onDataLoaded,
  allLabel = t`Any schedule`,
  hasAll = true,
  hasNonRecurring = true,
  disabled,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <OutlinedSelect label={t`Cadence`} native={false} value="all" classes={classes} disabled={disabled} {...rest}>
      {hasAll && <MenuItem value="all">{allLabel}</MenuItem>}
      {hasAll && <Divider />}
      {(hasNonRecurring ? SCHEDULE_FILTERS : NON_RECURRING_SCHEDULE_FILTERS).map(({ key, label }) => (
        <MenuItem key={key} value={key}>
          {label}
        </MenuItem>
      ))}
    </OutlinedSelect>
  )
}

export { ScheduleOutlinedSelect }
