import React, { useEffect, useState } from "react"
import { useIdleTimer } from "react-idle-timer"
import * as Sentry from "@sentry/browser"
import Config from "react-global-configuration"
import { useApolloClient } from "@apollo/client"
import { useAuth } from "../../services"
import { useMountEffect } from "../../utils"
import { DEVICE_POSTMESSAGE_TYPE } from "../../data"

const IdleTimer = ({ onLoggingOut }) => {
  const client = useApolloClient()
  const {
    logout,
    settings: { deviceTimer },
  } = useAuth(client)
  const { idleTimeoutMs } = Config.get("auth")
  const [ticker, setTicker] = useState(false)

  useEffect(() => {
    const run = setInterval(() => {
      setTicker(!ticker)
    }, 1000)
    return () => clearInterval(run)
  })

  const handleIdle = () => {
    console.log("[AUTH]", "User is idle, sign out...")
    onLoggingOut && onLoggingOut()
    logout("idle")
  }

  const { getRemainingTime, reset } = useIdleTimer({
    timeout: deviceTimer ? deviceTimer * 1000 : idleTimeoutMs,
    onIdle: handleIdle,
    debounce: 500,
  })

  const remaining = getRemainingTime()

  useMountEffect(() => {
    function handleDevicePing(event) {
      const message = event.detail
      try {
        if (!message?.type) {
          return
        }

        // reset idle timer on ping from device
        if (message.type === DEVICE_POSTMESSAGE_TYPE.PING) {
          reset()
        }
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    // register listener
    window.addEventListener("deviceMessage", handleDevicePing)

    // remove listener
    return () => window.removeEventListener("deviceMessage", handleDevicePing)
  })

  const minutes = Math.floor(remaining / (1000 * 60)) % 60
  const seconds = Math.floor(remaining / 1000) % 60

  return (
    <>
      {minutes}:{`${seconds < 10 ? "0" : ""}${seconds}`}
    </>
  )
}

const IdleTimerAuthWrapper = ({ onLoggingOut }) => {
  const {
    authed,
    settings: { deviceTimer },
  } = useAuth()
  const {
    auth: { idleTimeoutMs },
    clientKiosk,
  } = Config.get()

  if (!authed || !clientKiosk || (clientKiosk && !idleTimeoutMs && !deviceTimer)) return null

  console.log("[AUTH]", "Idle timer is active...")

  return <IdleTimer onLoggingOut={onLoggingOut} />
}

export { IdleTimerAuthWrapper as IdleTimer }
