import { gql } from "@apollo/client"
import { LOGIN_FIELDS } from "./fragments/loginFieldsFragment"

const queryRefreshToken = async (client) => {
  try {
    const response = await client.query({
      query: gql`
        query Refresh {
          refresh {
            ...LoginFields
          }
        }
        ${LOGIN_FIELDS}
      `,
      fetchPolicy: "network-only",
    })
    return response
  } catch (err) {
    return err
  }
}

export { queryRefreshToken }
