import Base from "./Base"
import Dev from "./Dev"

const config = {
  ...Dev,
  clientKiosk: true,
  clientLinkBaseUri: "https://dev-app.operandiodev.com",

  showConfigInTitle: true,
  configTitle: "[KIOSK DEV]",

  auth: Dev.auth ? { ...Dev.auth } : { ...Base.auth },
}

// config.auth.idleTimeoutMs = 1000 * 60 * 30
config.auth.idleTimeoutMs = 1000 * 60 // 60 seconds

export default config
