import React from "react"
import Config from "react-global-configuration"

const HelpLink = ({ children, article, ...rest }) => {
  const { help } = Config.get("links")

  return (
    <a href={`${help}/article/${article}`} rel="noopener noreferrer" target="_blank" {...rest}>
      {children}
    </a>
  )
}

export { HelpLink }
