import React, { useState } from "react"
import { Button, makeStyles } from "@material-ui/core"
import { SpeedDialAction } from "@material-ui/lab"
import { FloatingSpeedDial, Icon, RequirePermissions } from ".."

const requires = "food_item_create"

const useSpeedDialActionStyles = makeStyles(() => ({
  staticTooltipLabel: {
    whiteSpace: "nowrap",
  },
}))

const DesktopCreateFoodItemsButton = ({ onCreate }) => {
  const handleCreate = () => {
    onCreate && onCreate()
  }

  return (
    <RequirePermissions operator="or" requires={requires} features="food">
      <Button onClick={handleCreate} variant="contained" color="primary" data-cy="Button-create-asset">
        Create
      </Button>
    </RequirePermissions>
  )
}

const MobileCreateFoodItemsButton = ({ creatorOpen, onCreate }) => {
  const classes = useSpeedDialActionStyles()
  const [openSpeedDial, setOpenSpeedDial] = useState(false)

  const handleCreate = () => {
    onCreate && onCreate()
  }

  const handleToggleSpeedDial = () => {
    setOpenSpeedDial(!openSpeedDial)
  }

  return (
    <RequirePermissions operator="or" requires={requires} features="food">
      {!creatorOpen && (
        <FloatingSpeedDial onClose={handleToggleSpeedDial} onOpen={handleToggleSpeedDial} open={openSpeedDial}>
          <SpeedDialAction
            tooltipTitle="Create a new food items"
            icon={<Icon name="assets-dark" />}
            tooltipOpen
            onClick={handleCreate}
            classes={classes}
          />
        </FloatingSpeedDial>
      )}
    </RequirePermissions>
  )
}

export { DesktopCreateFoodItemsButton, MobileCreateFoodItemsButton }
