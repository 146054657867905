import React, { useRef, useEffect, useState } from "react"
import { Box, makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  shine: {
    position: "relative",
    overflow: "hidden",
    "&:after": {
      content: "''",
      top: 0,
      transform: "translateX(100%)",
      width: "100%",
      height: 500,
      position: "absolute",
      animation: "slide 500ms",
      background: `linear-gradient(to right, rgba(${theme.palette.success.mainRgb},0) 0%,rgba(${theme.palette.success.mainRgb},0.8) 50%,rgba(${theme.palette.success.mainRgb},0) 99%,rgba(${theme.palette.success.mainRgb},0) 100%)`,
    },
  },
}))

const Shine = ({ go, children, fullHeight = false }) => {
  const classes = useStyles()
  const firstLoadRef = useRef(true)
  const [doEffect, setDoEffect] = useState(false)

  useEffect(() => {
    if (firstLoadRef.current) firstLoadRef.current = false
    else setDoEffect(go)
  }, [firstLoadRef, setDoEffect, go])

  return (
    <Box className={doEffect ? classes.shine : null} style={{ height: fullHeight ? "100%" : "auto" }}>
      {children}
    </Box>
  )
}

export { Shine }
