import { makeVar } from "@apollo/client"
import { useEffect } from "react"
import { useAuth } from "../../services"
import { useMountAwareReactiveVar } from "../../utils"

const makeDashboardJobListFilters = ({ userID, hideCompleted = false }) => ({
  tab: "me",
  toHasEveryone: false,
  toUsers: [userID],
  toGroups: [],
  byHasEveryone: false,
  byUsers: [],
  byGroups: [],
  hideCompleted,
  hideFuture: true,
  hideHidden: true,
  sort: "jobStatus",
})

const makeJobFiltersStateVar = ({ hideCompleted = false } = {}) => ({
  tab: "me",
  filterText: "",
  toVariant: "select",
  toOpenPickerInit: true,
  toHasEveryone: true,
  toUsers: [],
  toGroups: [],
  byVariant: "select",
  byOpenPickerInit: true,
  byHasEveryone: true,
  byUsers: [],
  byGroups: [],
  hideCompleted,
  hideFuture: true,
  hideHidden: true,
  isFuture: false,
  sort: "jobStatus",
})

const jobFiltersStateVar = makeVar(null)

const resetJobFiltersStateVar = () => {
  jobFiltersStateVar(null)
}

const useJobFiltersStore = () => {
  const { authed, hasFeature } = useAuth()
  const jobsFiltersStore = useMountAwareReactiveVar(jobFiltersStateVar)

  useEffect(() => {
    if (authed && !jobFiltersStateVar()) {
      jobFiltersStateVar({
        ...makeJobFiltersStateVar({
          hideCompleted: hasFeature("job_hide_completed"),
        }),
      })
    }
  }, [authed, hasFeature, jobsFiltersStore])

  return {
    jobsFiltersStore,
  }
}

export {
  useJobFiltersStore,
  makeJobFiltersStateVar,
  makeDashboardJobListFilters,
  resetJobFiltersStateVar,
  jobFiltersStateVar,
}
