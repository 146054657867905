import React from "react"
import { Checkbox, Box } from "@material-ui/core"
import { PickerListItem } from "."

const CheckboxPickerListItem = ({ item, checked, onChange, children, disabled, cy, inputProps, ...props }) => (
  <PickerListItem data-cy={cy} {...props}>
    {onChange && (
      <Checkbox
        color="primary"
        checked={checked}
        onChange={(e) => onChange(e, item)}
        disabled={!!disabled}
        inputProps={inputProps}
      />
    )}
    <Box display="flex" alignItems="center" style={{ opacity: disabled ? "0.38" : "1" }}>
      {children}
    </Box>
  </PickerListItem>
)

export { CheckboxPickerListItem }
