import React, { useState } from "react"
import { TextField, makeStyles } from "@material-ui/core"
import { LoadingSpinner } from "../LoadingSpinner"
import { Icon } from "../Icon"

const useStyles = makeStyles((theme) => ({
  lockIcon: {
    marginRight: theme.spacing(1),
  },
  viewIcon: {
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
}))

const ProtectedTextField = ({ loading, ...props }) => {
  const classes = useStyles()
  const [type, setType] = useState("password")

  const handleToggleView = () => {
    setType(type === "password" ? "text" : "password")
  }

  return (
    <TextField
      variant="outlined"
      type={type}
      InputProps={{
        startAdornment: <Icon name="protect" className={classes.lockIcon} />,
        endAdornment: (
          <>
            {loading ? <LoadingSpinner size={24} /> : null}
            <Icon
              name={type === "password" ? "view" : "view-off"}
              className={classes.viewIcon}
              onClick={handleToggleView}
            />
          </>
        ),
      }}
      {...props}
    />
  )
}

export { ProtectedTextField }
