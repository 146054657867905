import { Select } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "14px",
    padding: theme.spacing(0.5, 1),
    color: theme.palette.tertiary.main,
    border: `2px solid ${theme.palette.tertiary.main}`,
    borderRadius: 4,
    "&:hover": {
      background: `rgba(${theme.palette.tertiary.mainRgb},.10)`,
      borderRadius: 4,
    },
    "&:focus": {
      background: `rgba(${theme.palette.tertiary.mainRgb},.10)`,
      borderRadius: 4,
    },
  },
  select: {
    paddingRight: `${theme.spacing(1)}px !important`,
  },
}))

const InlineOutlinedSelect = ({ children, ...props }) => {
  const classes = useStyles()

  return (
    <Select variant="standard" classes={classes} disableUnderline IconComponent={() => null} {...props}>
      {children}
    </Select>
  )
}

export { InlineOutlinedSelect }
