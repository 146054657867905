import React from "react"
import { Route, Redirect } from "react-router-dom"
import { useAuth } from "../../services"

const PrivateRoute = ({ component: Component, validator, location, requires, operator = "or", feature, ...rest }) => {
  const { authed, reason, hasFeature, hasPermission } = useAuth()

  // use href redirect -> react-router redirect conflict
  // with webpack-dev-server > express > path-to-regexp
  // doesn't work

  const qs = []

  // capture requested url, ensure local
  if (!authed && !location.pathname.startsWith("/login")) {
    if (reason) qs.push(`reason=${reason || "unauth"}`)
    if (location.pathname !== "/login" && location.pathname.startsWith("/")) qs.push(`to=${location.pathname}`)
  }

  if (authed && validator && validator.isValid) {
    if (!validator.isValid(rest.computedMatch)) {
      if (!validator.invalidRedirectTo)
        throw new Error("Route failed validation but no value for 'invalidRedirectTo' was supplied.")
      return <Redirect to={validator.invalidRedirectTo} />
    }
  }

  // check feature
  if (feature && !hasFeature(feature)) {
    console.log("[PrivateRoute]", "requires and !hasFeature", "Redirecting to /")
    return <Redirect to="/" />
  }

  // check permissions
  if (requires && !hasPermission(requires, operator)) {
    console.log("[PrivateRoute]", "requires and !hasPermission", "Redirecting to /")
    return <Redirect to="/" />
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        authed ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: `/login${qs.length > 0 ? "?" : ""}${qs.join("&")}`, state: { from: props.location } }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
