import { useMutation, gql } from "@apollo/client"
import { ACCREDITATION_FIELDS } from "./useQueryAccreditations"

const UPDATE_ACCREDITATION_MUTATION = gql`
  mutation UpdateAccreditation($id: ID!, $name: String!, $description: String, $icon: String!, $locations: [ID!]!, $groups: [ID!]!, $expires: Boolean, $expiringWarningMonths: Int) {
    updateAccreditation(id: $id, name: $name, description: $description, icon: $icon, locations: $locations, groups: $groups, expires: $expires, expiringWarningMonths: $expiringWarningMonths) {
      ${ACCREDITATION_FIELDS}
    } 
  }
`

const makeUpdateAccreditationOptimisticResponse = ({ accreditation, name }) => ({
  __typename: "Mutaton",
  updateAccreditation: {
    ...accreditation,
    name,
  },
})

const useMutationUpdateAccreditation = () => useMutation(UPDATE_ACCREDITATION_MUTATION)

export { useMutationUpdateAccreditation, makeUpdateAccreditationOptimisticResponse }
