import React, { useState } from "react"

import { TextField, Box } from "@material-ui/core"
import PropTypes from "prop-types"
import { t } from "@lingui/macro"
import { CreatorActions } from "../../../components"
import { useFormUtils } from "../../../utils"
import useMergeState from "../../../utils/useMergeState"

const initialValue = {
  newUsername: "",
  password: "",
}

const EmailChangeForm = ({ loading = false, onClose, onSubmit, changeByAdmin = false }) => {
  const [form, setForm] = useState(initialValue)
  const [touchedFields, setTouchedFields] = useMergeState({})
  const { isValid, validate } = useFormUtils()
  const isEmailValid = validate.requiredEmail(form?.newUsername)
  const isFormValid = isEmailValid && (changeByAdmin || isValid(form?.password))

  const handleChange = (field, value) => {
    setForm((prevForm) => ({ ...prevForm, [field]: value }))
  }

  const handleTouchedFields = (field, touched) => {
    setTouchedFields(() => ({
      [field]: touched,
    }))
  }

  const reset = () => {
    setForm(initialValue)
    setTouchedFields([])
  }

  const handleClose = () => {
    reset()
    if (onClose) onClose()
  }

  const handleSubmit = () => {
    if (isFormValid && onSubmit)
      onSubmit({
        ...form,
        ...(!changeByAdmin && { password: form.password }),
      })
  }

  return (
    <>
      <Box>
        <Box mb={2}>
          <TextField
            variant="outlined"
            fullWidth
            name="newUsername"
            type="email"
            label="New email address"
            value={form?.newUsername}
            onChange={(e) => handleChange("newUsername", e.target.value)}
            required
            disabled={loading}
            onBlur={() => handleTouchedFields("newUsername", true)}
            error={touchedFields?.newUsername && !isEmailValid}
          />
        </Box>
        <Box mb={2}>
          {!changeByAdmin && (
            <TextField
              variant="outlined"
              fullWidth
              name="password"
              value={form?.password}
              onChange={(e) => handleChange("password", e.target.value)}
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              disabled={loading}
            />
          )}
        </Box>
      </Box>
      <CreatorActions
        submitLabel={t`Continue`}
        onClose={handleClose}
        onSubmit={handleSubmit}
        disableSubmit={loading || !isFormValid}
      />
    </>
  )
}

EmailChangeForm.propTypes = {
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  changeByAdmin: PropTypes.bool,
}

export default EmailChangeForm
