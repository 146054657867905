import React from "react"
import { Grid, Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { Skeleton } from "@material-ui/lab"
import { Trans } from "@lingui/macro"
import { TimeAgo, Avatar, Assignees, AuthorTools, AdornmentIcon } from ".."
import { usePostUtils, mapToIds, toId } from "../../utils"
import { CopyLinkButton } from "../Buttons"
import { useAuth } from "../../services"

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(1.75),
    paddingBottom: theme.spacing(1.75),
  },
  avatar: {
    width: 40,
    height: 40,
  },
  avatarName: {
    fontSize: 16,
    fontWeight: "600",
    lineHeight: "20px",
  },
  scheduleAndRecipients: {
    fontSize: 14,
    lineHeight: "16px",
    color: theme.palette.scheduled.main,
  },
  ageAndRecipients: {
    fontSize: 14,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
  },
}))

const PostHeader = ({ post, onEdit, onDelete }) => {
  const classes = useStyles()
  const { isScheduledInFuture, getScheduleFormattedDate, getFullNavigateToLink } = usePostUtils()
  const { isCurrentUser, hasPermission, settings } = useAuth()

  const handleEdit = (event) => {
    onEdit && onEdit(event)
  }
  const handleDelete = (event) => {
    onDelete && onDelete(event)
  }

  const isScheduled = isScheduledInFuture(post)

  const userLocationIds = mapToIds(settings.locations)
  const postLocationIds = mapToIds(post.locations)

  const canEdit =
    (isCurrentUser(toId(post.author)) && hasPermission("post_update_author")) ||
    hasPermission("post_update_all") ||
    (hasPermission("post_update_locations") && postLocationIds.every((locId) => userLocationIds.includes(locId)))

  const canDelete =
    (isCurrentUser(toId(post.author)) && hasPermission("post_delete_author")) ||
    hasPermission("post_delete_all") ||
    (hasPermission("post_delete_locations") && postLocationIds.every((locId) => userLocationIds.includes(locId)))

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={1}
      className={classes.root}
    >
      <Box mr={2} display={{ xs: "none", sm: "flex" }}>
        <AdornmentIcon name="post" />
      </Box>
      <Box mr={1} display="flex">
        <Avatar className={classes.avatar} {...post.author} />
      </Box>
      <Grid item xs>
        <div className={classes.avatarName}>
          {post.author.firstName} {post.author.lastName}
        </div>
        <div className={isScheduled ? classes.scheduleAndRecipients : classes.ageAndRecipients}>
          {isScheduled ? (
            <>
              Post scheduled for <strong>{getScheduleFormattedDate(post.visibleFrom)}</strong>
            </>
          ) : (
            <TimeAgo date={post.visibleFrom} />
          )}
          <>
            <span> to </span>
            <Assignees {...{ users: post.users, groups: post.groups, limit: 4 }} />
          </>
          {isScheduled && <> (only you can see this)</>}
        </div>
      </Grid>
      <Box ml="auto" display="flex" alignItems="center" flexDirection="row">
        <CopyLinkButton link={getFullNavigateToLink(post)} title={<Trans>Copy link to clipboard</Trans>} />
        <AuthorTools
          item={post}
          author={post.author.id}
          admins={{ delete: canDelete, edit: canEdit }}
          onEdit={handleEdit}
          subject="post"
          onDelete={handleDelete}
        />
      </Box>
    </Grid>
  )
}

const PostHeaderSkeleton = () => {
  const classes = useStyles()

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={1}
      className={classes.root}
    >
      <Box mr={2} display={{ xs: "none", sm: "flex" }}>
        <AdornmentIcon name="post" />
      </Box>
      <Box mr={1} display="flex">
        <Skeleton className={classes.avatar} variant="circle" />
      </Box>
      <Grid item xs>
        <div className={classes.avatarName}>
          <Skeleton height={20} width={100} style={{ marginBottom: 2 }} />
        </div>
        <div className={classes.ageAndRecipients}>
          <Skeleton height={15} width="50%" />
        </div>
      </Grid>
      <Box ml="auto" display="flex" flexDirection="row" />
    </Grid>
  )
}

export default PostHeader
export { PostHeaderSkeleton }
