import { useQuery, gql, useLazyQuery } from "@apollo/client"
import { ASSET_FIELDS } from "./fragments/assetFieldsFragment"

const ASSET_QUERY = gql`
  query Asset($id: ID!) {
    asset(id: $id) {
      ...AssetFields
    }
  }
  ${ASSET_FIELDS}
`

const useQueryAsset = (options) => useQuery(ASSET_QUERY, options)

const useLazyQueryAsset = (options) => useLazyQuery(ASSET_QUERY, options)

export { useQueryAsset, useLazyQueryAsset }
