import Base from "./Base"

export default {
  httpBaseUri: "https://dev-api.operandiodev.com",
  httpServiceBaseUri: "https://dev-api.operandiodev.com/graphql",
  wsServiceBaseUri: "wss://dev-api.operandiodev.com/graphql",

  showConfigInTitle: true,
  configTitle: "[LOCALDEV]",

  theme: {
    ...Base.theme,

    logoContainer: "images-dev",
  },

  uploader: {
    ...Base.uploader,

    imageViewerBaseUri: "https://dydc66wtc7q9n.cloudfront.net",
    // imageViewerBucketName: "operandio-content-dev",
    imageViewerBucketName: "operandio-content-ap-southeast-2",
  },

  errors: {
    ...Base.errors,

    showDetail: false,
    showGraphQLErrorNotification: true,
  },
}
