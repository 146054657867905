import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core"
import { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { Trans } from "@lingui/macro"
import { useQueryKnowledge } from "../../data"
import { DialogTitleCloser, FlexBox, Icon, KnowledgeArticle, LoadingSpinner, RowBox } from ".."
import Forbidden from "../../areas/knowledge/Forbidden"
import { useKnowledgeUtils } from "../../utils"

const KnowledgePeekDialog = ({ id, open, onClose }) => {
  const { data, loading, error } = useQueryKnowledge({ variables: { id }, errorPolicy: "all" })
  const { getNavigateToArticleLinkForID } = useKnowledgeUtils()

  const forbidden = useMemo(() => {
    if (!error?.graphQLErrors?.length) {
      return false
    }
    return error.graphQLErrors[0].extensions?.code === "FORBIDDEN"
  }, [error])

  const handleClose = () => {
    onClose && onClose()
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
      <DialogTitle id="customized-dialog-title">
        <RowBox mr={2}>
          <FlexBox flexGrow={1}>{data?.knowledge.title}</FlexBox>
          <DialogTitleCloser onClose={handleClose} />
        </RowBox>
      </DialogTitle>
      <DialogContent>
        {loading && !data && (
          <Box display="flex" justifyContent="center">
            <LoadingSpinner size={60} />
          </Box>
        )}
        {data && <KnowledgeArticle variant="peek" knowledge={data.knowledge} />}
        {forbidden && <Forbidden />}
      </DialogContent>
      <DialogActions>
        {!forbidden && (
          <Button component={NavLink} to={getNavigateToArticleLinkForID(id)}>
            <Box mr={0.5}>
              <Trans>View in Knowledge Base</Trans>
            </Box>
            <Icon name="forward" />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default KnowledgePeekDialog
