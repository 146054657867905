import * as Sentry from "@sentry/browser"
import { toId } from "../../utils"
import { authedVar, authStateVar, makeAuthStateVar, makePrincipal } from "./authStateVar"

const getAuthToken = () => {
  const state = authStateVar()
  const authed = authedVar()
  return authed ? state.principal.token : ""
}

const resetAuth = (reason) => {
  Sentry.setUser({
    id: null,
  })
  authedVar(false)
  authStateVar({
    ...makeAuthStateVar({ reason: typeof reason !== "undefined" && reason }),
  })
}

const useAuthState = () => {
  const initLocation = (silent) => {
    const state = authStateVar()
    const { location, locations } = state.settings
    if (location && locations) {
      const newLocation = locations.find((item) => toId(item) === toId(location)) || locations[0]
      if (!state.location) {
        state.location = { ...newLocation }
      } else {
        Object.assign(state.location, { ...newLocation })
      }
    } else if (locations?.length) {
      state.location = { ...locations[0] }
    }
    authStateVar(silent ? state : { ...state })
  }

  const setAuthed = (authed) => {
    authedVar(authed)
  }

  const setPrincipal = (credentials, { ticket, settings }, silent = true) => {
    Sentry.setUser({
      id: ticket?.userID || null,
    })
    const state = authStateVar()
    Object.assign(state.principal, { ...makePrincipal(credentials, ticket, silent) })
    Object.assign(state.settings, {
      ...settings,
      features: Object.fromEntries(settings.features.map(({ key, value }) => [key, value === "true"])),
    })
    state.firstLogin = settings.fl
    authStateVar(silent ? state : { ...state })
    initLocation(silent)
    !silent && console.log("[AUTH]", "[SETTINGS]", settings)
  }

  const updatePrincipal = (data) => {
    const state = authStateVar()
    Object.assign(state.principal, data)
    authStateVar(state)
  }

  const updatePreferences = (data) => {
    const state = authStateVar()
    authStateVar({
      ...state,
      settings: {
        ...state.settings,
        preferences: { ...state.settings.preferences, ...data },
      },
    })
  }

  const setLocation = (location) => {
    authStateVar({
      ...authStateVar(),
      location: { ...location },
    })
  }

  const clearFirstLogin = () => {
    const state = authStateVar()
    Object.assign(state.firstLogin, false)
    authStateVar(state)
  }

  return {
    initLocation,
    setAuthed,
    setPrincipal,
    updatePrincipal,
    updatePreferences,
    setLocation,
    clearFirstLogin,
    reset: resetAuth,
    getAuthToken,
  }
}

export { useAuthState, getAuthToken, resetAuth }
