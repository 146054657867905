import { useMutation, gql } from "@apollo/client"
import { PREP_LIST_FIELDS } from "./fragments/prepListFieldsFragment"

const PREPLIST_SET_SALESFORECAST_AND_STOCK_MUTATION = gql`
  mutation UpdateSalesForecastPrepList($id: ID!, $input: PrepListSetSalesForecastAndStockMutationInput!) {
    prepList(id: $id) {
      setSalesForecastAndStock(input: $input) {
        ...PrepListFields
      }
    }
  }
  ${PREP_LIST_FIELDS}
`

const useMutationPrepListSetSalesForecastAndStock = (options) => {
  return useMutation(PREPLIST_SET_SALESFORECAST_AND_STOCK_MUTATION, options)
}

export { useMutationPrepListSetSalesForecastAndStock }
