import { useQuery, useLazyQuery, gql } from "@apollo/client"
import * as Sentry from "@sentry/browser"
import { PROCESS_FIELDS } from "../processes/fragments/processFieldsFragment"

const LIBRARY_TEMPLATES_LIMIT = 10

const LIBRARY_TEMPLATES_QUERY = gql`
  query LibraryTemplates($filter: LibraryTemplateFilterInput!, $limit: Int = ${LIBRARY_TEMPLATES_LIMIT}, $offset: Int = 0) {
    libraryTemplates: libraryTemplatesV2 {
      list(filter: $filter, limit: $limit, offset: $offset) {
        ...ProcessFields
      }
      count(filter: $filter)
    } 
  }
  ${PROCESS_FIELDS}  
`

const loadMore = (result) => async () => {
  try {
    await result.fetchMore({
      variables: {
        ...result.variables,
        offset: result.data.libraryTemplates.list.length,
        limit: LIBRARY_TEMPLATES_LIMIT,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult

        return {
          libraryTemplates: {
            ...previousResult.libraryTemplates,
            list: [...previousResult.libraryTemplates.list, ...fetchMoreResult.libraryTemplates.list],
          },
        }
      },
      notifyOnNetworkStatusChange: true,
    })
  } catch (error) {
    Sentry.captureException(error)
  }
}

const useQueryLibraryTemplates = (options) => {
  const result = useQuery(LIBRARY_TEMPLATES_QUERY, { fetchPolicy: "cache-and-network", ...options })
  return {
    ...result,
    loadMore: loadMore(result),
  }
}

const useLazyQueryLibraryTemplates = (options) => {
  const { variables, ...otherOptions } = options || {}
  const [loadTemplates, result] = useLazyQuery(LIBRARY_TEMPLATES_QUERY, {
    variables: {
      offset: 0,
      limit: LIBRARY_TEMPLATES_LIMIT,
      ...variables,
    },
    fetchPolicy: "cache-and-network",
    ...otherOptions,
  })

  return [
    loadTemplates,
    {
      ...result,
      loadMore: loadMore(result),
    },
  ]
}

export { useQueryLibraryTemplates, useLazyQueryLibraryTemplates }
