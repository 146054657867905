import React from "react"
import { Grid, Box, Container } from "@material-ui/core"

const ErrorContent = (props) => (
  <Container>
    <Box className="error-content" mt={3}>
      <Grid container direction="row">
        <Grid item sm={1} />
        <Grid item sm={10}>
          <h1 className="h3">{/* <Icon name="exclamation-triangle" />  */}A problem occurred</h1>
          {props.children}
        </Grid>
        <Grid item sm={1} />
      </Grid>
    </Box>
  </Container>
)

export default ErrorContent
