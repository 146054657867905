import { gql, useSubscription } from "@apollo/client"
import { PREP_LIST_ITEM_FIELDS } from "./fragments/prepListFieldsFragment"

const PREPLISTITEM_UPDATED_SUBSCRIPTION = gql`
  subscription PrepListItemUpdated($filter: PrepListItemUpdatedSubscriptionFilterInput) {
    prepListItemUpdated(filter: $filter) {
      ...PrepListItemFields
    }
  }
  ${PREP_LIST_ITEM_FIELDS}
`

const useSubscriptionPrepListItem = (options) => {
  return useSubscription(PREPLISTITEM_UPDATED_SUBSCRIPTION, options)
}

export { useSubscriptionPrepListItem }
