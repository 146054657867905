import { useMutation, gql } from "@apollo/client"

const DELETELABEL_MUTATION = gql`
  mutation DeleteLabel($id: ID!) {
    label(id: $id) {
      delete
    }
  }
`

const useMutationDeleteLabel = () => useMutation(DELETELABEL_MUTATION)

export { useMutationDeleteLabel }
