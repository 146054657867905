import moment from "moment-timezone"
import Config from "react-global-configuration"
import { useAuth } from "../services"

const usePostUtils = () => {
  const { isCurrentUser, location } = useAuth()
  const config = Config.get()

  const getCurrentUserUnconfirmedNotification = (post) => post.unconfirmed.find((n) => isCurrentUser(n.user.id))
  const getCurrentUserConfirmedNotification = (post) => post.confirmed.find((n) => isCurrentUser(n.user.id))

  const getScheduleFormattedDate = (visibleFrom) =>
    moment(visibleFrom).tz(location.timeZone).format("D MMM YYYY [at] h:mm A z")

  const getNavigateToLink = (post) => `/hub/post/${post.id}`
  const getFullNavigateToLink = (post) =>
    `${config.clientLinkBaseUri || config.clientBaseUri}${getNavigateToLink(post)}`

  const isCurrentUserAuthorOf = (post) => isCurrentUser(post.author.id)

  const hasCurrentUserConfirmedPost = (post) => typeof getCurrentUserConfirmedNotification(post) !== "undefined"
  const hasCurrentUserReceivedPostNotification = (post) =>
    !!getCurrentUserConfirmedNotification(post) || !!getCurrentUserUnconfirmedNotification(post)

  const isDateInFuture = (date) => date && moment().isBefore(date)
  const isScheduledInFuture = (post) => post && post.visibleFrom && isDateInFuture(post.visibleFrom)

  return {
    getCurrentUserUnconfirmedNotification,
    getCurrentUserConfirmedNotification,
    getScheduleFormattedDate,
    getNavigateToLink,
    getFullNavigateToLink,

    isCurrentUserAuthorOf,
    isScheduledInFuture,
    isDateInFuture,

    hasCurrentUserConfirmedPost,
    hasCurrentUserReceivedPostNotification,
  }
}

export { usePostUtils }
