import { useEffect, useRef, useState } from "react"
import { useMountEffect } from "./useMountEffect"

// taken from apollo client useReactiveVar and updated to respect
// component mounted state for e.g. hard route changes
export function useMountAwareReactiveVar(rv) {
  const value = rv()
  const mounted = useRef(false)

  // We don't actually care what useState thinks the value of the variable
  // is, so we take only the update function from the returned array.
  const setValue = useState(value)[1]

  useMountEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  })

  // We subscribe to variable updates on initial mount and when the value has
  // changed. This avoids a subtle bug in React.StrictMode where multiple
  // listeners are added, leading to inconsistent updates.
  useEffect(() => {
    const probablySameValue = rv()
    if (value !== probablySameValue) {
      // If the value of rv has already changed, we don't need to listen for the
      // next change, because we can report this change immediately.
      setValue(probablySameValue)
    } else {
      return rv.onNextChange((newValue) => {
        if (!mounted.current) {
          return
        }
        setValue(newValue)
      })
    }
  }, [rv, setValue, value])

  return value
}
