import React, { useRef, useEffect, useState } from "react"
import { Box, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
  glow: {
    animation: "successGlow 300ms",
  },
}))

const SuccessGlow = ({ glow, children }) => {
  const classes = useStyles()
  const firstLoadRef = useRef(true)
  const [doGlow, setDoGlow] = useState(false)

  useEffect(() => {
    if (firstLoadRef.current) firstLoadRef.current = false
    else setDoGlow(glow)
  }, [firstLoadRef, setDoGlow, glow])

  return <Box className={doGlow ? classes.glow : null}>{children}</Box>
}

export { SuccessGlow }
