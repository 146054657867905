import { gql, useQuery } from "@apollo/client"
import { RESPONSE_SET_FIELDS } from "./fragments/responseSetFragment"

const RESPONSE_SETS_QUERY = gql`
  query ResponseSets {
    responseSets {
      list {
        ...ResponseSetFields
      }
    }
  }
  ${RESPONSE_SET_FIELDS}
`

const useQueryResponseSets = (options) => useQuery(RESPONSE_SETS_QUERY, options)

export { useQueryResponseSets }
