import React, { useState } from "react"
import { Button, makeStyles } from "@material-ui/core"
import { Icon, RowBox } from ".."
import { useProbes } from "../../utils/useProbes"
import { ProbeSelector } from "./ProbeSelector"

const useStyles = makeStyles(() => ({
  link: {
    fontSize: 14,
    lineHeight: "18px",
    fontWeight: "600",
    textAlign: "left",
  },
}))

const SelectedProbeButton = () => {
  const classes = useStyles()
  const { probe } = useProbes()
  const [openSelector, setOpenSelector] = useState(false)

  const handleOpen = () => {
    setOpenSelector(true)
  }

  const handleClose = () => {
    setOpenSelector(false)
  }

  const probeSelector = <>{openSelector && <ProbeSelector open onClose={handleClose} />}</>

  if (!probe) {
    return (
      <>
        {probeSelector}
        <RowBox>
          <Icon name="probe" color="inherit" fontSize="large" />

          <Button className={classes.link} color="primary" onClick={handleOpen}>
            Please select a probe
          </Button>
        </RowBox>
      </>
    )
  }

  return (
    <>
      {probeSelector}
      <RowBox>
        <Icon name="probe" color="inherit" fontSize="large" />

        <Button className={classes.link} color="primary" onClick={handleOpen}>
          {probe.name}
        </Button>
      </RowBox>
    </>
  )
}

export { SelectedProbeButton }
