import React, { Suspense, useState } from "react"
import { Box, Button, Divider, Tab, Tabs, Tooltip } from "@material-ui/core"
import { CreatorMaster } from "../Creators"
import { ColumnBox, RowBox } from "../Boxes"
import { Caption, FieldSectionHeading } from "../Headings"
import { IntegrationProviderInfo } from "./IntegrationProviderInfo"
import { useAuth } from "../../services"
import { IntegrationConfiguration } from "./IntegrationConfiguration"
import PageLoadingSpinner from "../LoadingSpinner/PageLoadingSpinner"
import TimeAgo from "../TimeAgo"
import { LoadingSpinner } from "../LoadingSpinner"
import { IntegrationOperations } from "./IntegrationOperations"
import { useIntegrationProviderConnector } from "../../utils/useIntegrationProviderConnector"
import { CommonChip } from "../Chips/CommonChip"

const IntegrationEvents = React.lazy(() => import("./IntegrationEvents"))

const IntegrationCreator = ({ open, onClose, onPause, onResume, onDisable, integration }) => {
  const { hasPermission } = useAuth()
  const { startConnectionRequest } = useIntegrationProviderConnector()
  const [reconnect, setReconnect] = useState(false)
  const [reconnecting, setReconnecting] = useState(false)
  const [pause, setPause] = useState(false)
  const [pausing, setPausing] = useState(false)
  const [resume, setResume] = useState(false)
  const [resuming, setResuming] = useState(false)
  const [disabling, setDisabling] = useState(false)
  const [disable, setDisable] = useState(false)
  const [tab, setTab] = useState("overview")

  const handleClose = () => {
    if (onClose) onClose(true)
  }

  const handleReconnect = () => {
    if (!reconnecting) {
      setReconnecting(true)
      return
    }
    setReconnect(true)
    startConnectionRequest(integration.integrationProvider, integration)
  }

  const handlePause = () => {
    if (!pausing) {
      setPausing(true)
      return
    }
    setPause(true)
    onPause && onPause(integration)
  }

  const handleResume = () => {
    if (!resuming) {
      setResuming(true)
      return
    }
    setResume(true)
    onResume && onResume(integration)
  }

  const handleDisable = () => {
    if (!disabling) {
      setDisabling(true)
      return
    }
    setDisable(true)
    onDisable && onDisable(integration)
  }

  if (!integration) {
    return null
  }

  const {
    title,
    enabled,
    paused,
    pausedAt,
    integrationProvider: { name, canDisable, operations, connect },
  } = integration

  const hasOperations = Boolean(operations?.length)
  const hasDisable = canDisable && hasPermission("integration_update")
  const showAdvanced = hasOperations || hasDisable

  const form = (
    <Box mb={1}>
      <Tabs
        variant="standard"
        indicatorColor="primary"
        textColor="primary"
        value={tab}
        onChange={(_, newTab) => setTab(newTab)}
        aria-label="Integration details tabs"
        data-cy="Tabs"
      >
        <Tab label="Overview" value="overview" data-cy="Tab-overview" />
        <Tab label="Configuration" value="configuration" data-cy="Tab-configuration" />
        <Tab label="Events" value="events" data-cy="Tab-events" />
        {showAdvanced && <Tab label="Advanced" value="advanced" data-cy="Tab-advanced" />}
      </Tabs>

      <Divider />

      <Box pt={2} py={2}>
        {tab === "overview" && <IntegrationProviderInfo integrationProvider={integration.integrationProvider} />}
        {tab === "configuration" && <IntegrationConfiguration integration={integration} />}
        {tab === "events" && (
          <Suspense fallback={<PageLoadingSpinner />}>
            <IntegrationEvents integration={integration} />
          </Suspense>
        )}
        {tab === "advanced" && (
          <>
            {hasOperations && (
              <Box mb={hasDisable ? 2 : 0}>
                <FieldSectionHeading size="large">Operations</FieldSectionHeading>

                <IntegrationOperations integration={integration} />
                {hasDisable && <Divider />}
              </Box>
            )}
            {hasDisable && (
              <>
                <FieldSectionHeading size="large">Danger zone</FieldSectionHeading>

                {connect && (
                  <ColumnBox mb={3}>
                    <FieldSectionHeading mb={0}>Reconnect this integration</FieldSectionHeading>
                    <Caption mb={1}>
                      Reconnect this integration with the connected service. This will redirect you to {name} to
                      authorise Operandio to interact with the service. Useful if something has happened to the
                      authorisation. Ensure you authoise the same {name} account as before to avoid configuration
                      problems.
                    </Caption>

                    <Button variant="contained" onClick={handleReconnect} disabled={reconnect}>
                      {!reconnect && !reconnecting && <>Reconnect {name} integration</>}
                      {!reconnect && reconnecting && <>Click again to reconnect this integration!</>}
                      {reconnect && <LoadingSpinner size="24px" delay={false} />}
                    </Button>
                  </ColumnBox>
                )}

                {enabled && !paused && (
                  <ColumnBox mb={3}>
                    <FieldSectionHeading mb={0}>Pause this integration</FieldSectionHeading>
                    <Caption mb={1}>
                      Pausing will temporarily stop events being processed for this integration. You can resume at
                      anytime, to continue processing events normally.
                    </Caption>

                    <Button variant="contained" onClick={handlePause}>
                      {!pause && !pausing && <>Pause {name} integration</>}
                      {!pause && pausing && <>Click again to pause this integration!</>}
                      {pause && <LoadingSpinner size="24px" delay={false} />}
                    </Button>
                  </ColumnBox>
                )}

                {paused && (
                  <ColumnBox mb={3}>
                    <FieldSectionHeading mb={0}>Unpause this integration</FieldSectionHeading>
                    <Caption mb={1}>
                      {name} was paused <TimeAgo date={pausedAt} />. Unpausing will resume event processing for this
                      integration.
                    </Caption>

                    <Button variant="contained" onClick={handleResume}>
                      {!resume && !resuming && <>Unpause {name} integration</>}
                      {!resume && resuming && <>Click again to unpause this integration!</>}
                      {resume && <LoadingSpinner size="24px" delay={false} />}
                    </Button>
                  </ColumnBox>
                )}

                <ColumnBox>
                  <FieldSectionHeading mb={0}>Remove this integration</FieldSectionHeading>
                  <Caption mb={1}>
                    Removing {name} will cause your integration to stop working. This action cannot be reversed without
                    reconnecting the integration, and will impact event &amp; data synchronisation between {name} and
                    Operandio.
                  </Caption>

                  <Button variant="contained" color="secondary" onClick={handleDisable}>
                    {!disable && !disabling && <>Remove {name} integration</>}
                    {!disable && disabling && <>Click again to remove this integration!</>}
                    {disable && <LoadingSpinner size="24px" delay={false} />}
                  </Button>
                </ColumnBox>
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  )

  return (
    <CreatorMaster
      open={open}
      title={
        <RowBox>
          <Box>{name}</Box>
          {title && (
            <Box ml={1}>
              <Tooltip title={`${title.name} - ${title.description}`}>
                <div>
                  <CommonChip label={title.value} size="small" />
                </div>
              </Tooltip>
            </Box>
          )}
        </RowBox>
      }
      form={form}
      onClose={handleClose}
    />
  )
}

export { IntegrationCreator }
