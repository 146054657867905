import React from "react"
import { Box, Divider, Button, Grid } from "@material-ui/core"
import { ProcessCollapse } from "./ProcessCollapse"

const YesNoNaStep = ({ expand, value, buttonClass, onClick, disabled, classes, collapseTimeout = "auto" }) => {
  const handleClick = (...args) => {
    onClick && onClick(...args)
  }

  return (
    <ProcessCollapse in={expand} timeout={collapseTimeout}>
      <Divider className={classes.divider} />
      <Box py={1} px={2}>
        <Grid container spacing={1}>
          <YesNoNaButton answer="Yes" value={value} onClick={handleClick} className={buttonClass} disabled={disabled} />
          <YesNoNaButton answer="No" value={value} onClick={handleClick} className={buttonClass} disabled={disabled} />
          <YesNoNaButton answer="N/A" value={value} onClick={handleClick} className={buttonClass} disabled={disabled} />
        </Grid>
      </Box>
    </ProcessCollapse>
  )
}

const YesNoNaButton = ({ answer, value, onClick, className, disabled: disabledInput }) => {
  const selected = !!value && answer.toLowerCase() === value.toLowerCase()
  const disabled = !!value && !selected

  const handleClick = (event) => {
    onClick && onClick(event, answer.toLowerCase())
  }

  return (
    <Grid item xs={4}>
      <Button
        color={selected ? "primary" : "default"}
        variant={disabled ? "text" : "contained"}
        fullWidth
        onClick={handleClick}
        className={className}
        disabled={disabled || disabledInput}
      >
        {answer}
      </Button>
    </Grid>
  )
}

export { YesNoNaStep }
