import { useMutation, gql } from "@apollo/client"
import { USER_PREFERENCES_FIELDS } from "./fragments/userPreferencesFieldsFragment"
import { useAuthState } from "../authentication"

const UPDATE_PREFERENCES_MUTATION = gql`
  mutation MeUpdatePreferences($input: MeUpdatePreferencesInput!) {
    me {
      updatePreferences(input: $input) {
        ...UserPreferencesFields
      }
    }
  }
  ${USER_PREFERENCES_FIELDS}
`

const makeUpdatePreferencesOptimisticResponse = ({ variables, preferences }) => {
  return {
    __typename: "Mutation",
    me: {
      __typename: "User",
      updatePreferences: {
        __typename: "UserPreferences",
        ...preferences,
        ...variables,
      },
    },
  }
}

const useMutationUpdatePreferences = () => {
  const { updatePreferences } = useAuthState()
  return useMutation(UPDATE_PREFERENCES_MUTATION, {
    update: (_, { data }) => {
      updatePreferences(data.me.updatePreferences)
    },
  })
}

export { useMutationUpdatePreferences, makeUpdatePreferencesOptimisticResponse }
