import Base from "./Base"
import Dev from "./Dev"

const config = {
  ...Dev,
  clientDevice: true,
  clientLinkBaseUri: "https://dev-app.operandiodev.com",

  auth: Dev.auth ? { ...Dev.auth } : { ...Base.auth },
}

export default config
