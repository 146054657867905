import { useQuery, useLazyQuery, gql } from "@apollo/client"
import * as Sentry from "@sentry/browser"
import { offsetLimitPagination } from "@apollo/client/utilities"
import { PROCESS_FIELDS } from "./fragments/processFieldsFragment"

const PROCESSES_LIMIT = 10

const processScheduleInitialState = {
  weekdays: {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  },
  months: {
    january: false,
    february: false,
    march: false,
    april: false,
    may: false,
    june: false,
    july: false,
    august: false,
    september: false,
    october: false,
    november: false,
    december: false,
  },
}

const PROCESSES_QUERY = gql`
  query Processes($filter: ProcessFilterInput!, $limit: Int = ${PROCESSES_LIMIT}, $offset: Int) {
    processes {
      list(filter: $filter, limit: $limit, offset: $offset) {
        ...ProcessFields
      }
      count(filter: $filter)
    } 
  }
  ${PROCESS_FIELDS}  
`

const PROCESS_QUERY = gql`
  query Process($id: ID!) {
    process(id: $id) {
      ...ProcessFields
    }
  }
  ${PROCESS_FIELDS}
`

const loadMore = (result) => async () => {
  try {
    await result.fetchMore({
      variables: {
        ...result.variables,
        offset: result.data.processes.list.length,
        limit: PROCESSES_LIMIT,
      },
      notifyOnNetworkStatusChange: true,
    })
  } catch (error) {
    Sentry.captureException(error)
  }
}

const useQueryProcesses = (options) => {
  const result = useQuery(PROCESSES_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

  return {
    ...result,
    loadMore: loadMore(result),
  }
}

const useLazyQueryProcesses = () => {
  const [loadItems, result] = useLazyQuery(PROCESSES_QUERY, {
    variables: {
      offset: 0,
      limit: PROCESSES_LIMIT,
    },
    fetchPolicy: "cache-and-network",
  })

  return [
    loadItems,
    {
      ...result,
      loadMore: loadMore(result),
    },
  ]
}

const useQueryProcess = (options) =>
  useQuery(PROCESS_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

const useLazyQueryProcess = (options) =>
  useLazyQuery(PROCESS_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

const queryProcess = async (id, client) => {
  try {
    const response = await client.query({
      query: PROCESS_QUERY,
      variables: { id },
    })
    return response
  } catch (err) {
    return err
  }
}

const processesQueryTypePolicies = {
  ProcessesQuery: {
    merge(prev, next) {
      return {
        ...(prev || {}),
        ...next,
      }
    },
    fields: {
      list: offsetLimitPagination(["filter"]),
      count: {
        keyArgs: ["filter"],
      },
    },
  },
}

const processTypePolicies = {
  Process: {
    fields: {
      repeat: {
        merge(_, next) {
          return { ...next }
        },
      },
      schedules: {
        merge(_, next) {
          return [...next]
        },
      },
    },
  },
}

export {
  useQueryProcesses,
  useLazyQueryProcesses,
  useQueryProcess,
  useLazyQueryProcess,
  queryProcess,
  processScheduleInitialState,
  processesQueryTypePolicies,
  processTypePolicies,
}
