import React from "react"
import { makeStyles, Box, Typography } from "@material-ui/core"
import { Caption } from "../Headings"

const useStyles = makeStyles((theme) => ({
  panel: {
    width: ({ layout }) => (layout === "landscape" ? "100%" : 322),
    backgroundColor: theme.palette.shaded.filter,
    borderRadius: "8px",
    textAlign: ({ layout }) => (layout === "portrait" ? "center" : "left"),
  },
  nameTitle: {
    color: theme.palette.text.primary,
    fontSize: 24,
    fontWeight: "500",
    lineHeight: "30px",
  },
  locations: {
    color: theme.palette.text.light,
    fontSize: 14,
    lineHeight: "16px",
  },
  description: {
    marginTop: 4,
    color: theme.palette.text.light,
    fontSize: 14,
    lineHeight: "16px",
  },
}))

const FoodItemPanel = ({
  layout = "portrait",
  foodItem: { name, locations, description },
  className = "",
  mr = 5,
  ...rest
}) => {
  const classes = useStyles({ layout })
  const isPortrait = layout === "portrait"
  const isLandscape = layout === "landscape"

  return (
    <Box mr={mr} className={`${classes.panel} ${className}`} {...rest}>
      <Box display="flex" flexDirection={isPortrait ? "column" : "row"} alignItems="center" py={3} px={3}>
        <Box mt={isPortrait ? 2 : 0} ml={isLandscape ? 2 : 0} flexGrow={1}>
          <Box
            mb={isPortrait ? 0.5 : 0}
            display="flex"
            flexGrow={1}
            justifyContent={isPortrait ? "center" : "flex-start"}
          >
            <Typography component="h1" className={classes.nameTitle}>
              {name}
            </Typography>
          </Box>
          <Box className={classes.locations}>
            {locations && locations.length > 0 && locations.map((location) => location.name).join(", ")}
          </Box>
          <Box className={classes.description}>
            <Caption>{description}</Caption>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export { FoodItemPanel }
