import { eventsIntegrationQueryTypePolicies } from "./useQueryIntegrationEvents"

const integrationQueryTypePolicies = {
  IntegrationQuery: {
    merge(prev, next) {
      return {
        ...prev,
        ...next,
      }
    },
    fields: {
      ...eventsIntegrationQueryTypePolicies.IntegrationQuery.fields,
    },
  },
}

export { integrationQueryTypePolicies }
