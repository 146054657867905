import React from "react"
import { Chip, makeStyles } from "@material-ui/core"
import { TruncateNames } from "../DataDisplay/TruncateNames"

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: "600",
    marginRight: theme.spacing(0.5),
  },
}))

const SupplierTags = ({ tags, className, max = 1 }) => {
  const classes = useStyles()

  if (!tags || tags.length === 0) {
    return null
  }

  return (
    <TruncateNames
      names={tags.map((tag) => (
        <Chip key={tag.id} size="small" label={tag.name} classes={classes} className={className} />
      ))}
      max={max}
      separator={null}
    />
  )
}

export { SupplierTags }
