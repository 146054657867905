const preventDefault = (event, callback) => {
  if (!event) throw Error("Event must be supplied.")

  event.preventDefault()
  event.stopPropagation()
  event.nativeEvent.stopImmediatePropagation()

  if (callback) return callback(event)
}

export { preventDefault }
