import Base from "./Base"
import LocalDev from "./LocalDev"

const config = {
  ...LocalDev,
  clientDevice: true,

  showConfigInTitle: true,
  configTitle: "[LOCALDEV DEVICE]",

  auth: LocalDev.auth ? { ...LocalDev.auth } : { ...Base.auth },
}

export default config
