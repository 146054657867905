import React, { useState, useEffect } from "react"
import { TextField, Box, Divider, FormHelperText } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { OPENID_PROMPT, useMutationCreateIdentityProvider, useMutationUpdateIdentityProvider } from "../../data"
import { toId, useFormUtils } from "../../utils"
import { CreatorActions, CreatorMaster } from "../Creators"
import { useSnackbar } from "../SnackbarProvider"
import { Caption, ColumnBox, ExpansionPanel, FieldSectionHeading, IdentityProviderPromptOutlinedSelect } from ".."
import { RoleOutlinedSelect } from "../OutlinedSelect/RoleOutlinedSelect"

const IdentityProviderCreator = ({ open, onClose, edit, isInline }) => {
  const snackbar = useSnackbar()
  const [createIdentityProvider, { loading: createLoading }] = useMutationCreateIdentityProvider()
  const [updateIdentityProvider, { loading: updateLoading }] = useMutationUpdateIdentityProvider()
  const { isValid } = useFormUtils()

  const [name, setName] = useState("")
  const [client, setClient] = useState("")
  const [authority, setAuthority] = useState("")
  const [browserPrompt, setBrowserPrompt] = useState(OPENID_PROMPT.DEFAULT)
  const [devicePrompt, setDevicePrompt] = useState(OPENID_PROMPT.DEFAULT)
  const [userDefaultRole, setUserDefaultRole] = useState("")

  const isEdit = edit != null

  useEffect(() => {
    if (edit) {
      setName(edit.name)
      setClient(edit.client)
      setAuthority(edit.authority)
      setBrowserPrompt(edit.browserPrompt)
      setDevicePrompt(edit.devicePrompt)
      setUserDefaultRole(edit.userDefaultRole)
    }
  }, [edit])

  const handleClose = () => {
    handleResetState()
    if (onClose) onClose(true)
  }

  const handleChange = (event, set) => {
    set(event.target.value)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (formValid()) {
      try {
        const variables = {
          name,
          client,
          authority,
          browserPrompt,
          devicePrompt,
          userDefaultRole: userDefaultRole || null,
        }

        if (isEdit) {
          await updateIdentityProvider({ variables: { id: toId(edit), ...variables } })
        } else {
          await createIdentityProvider({ variables })
        }
        handleClose(event)
      } catch (error) {
        snackbar.showMessage({ message: error.message })
      }
    }
  }

  const handleResetState = () => {
    setName("")
    setClient("")
    setAuthority("")
    setBrowserPrompt(OPENID_PROMPT.DEFAULT)
    setDevicePrompt(OPENID_PROMPT.DEFAULT)
  }

  const formValid = () => isValid(name) && isValid(client) && isValid(authority)

  const isFormValid = formValid()

  const loading = createLoading || updateLoading

  const form = (
    <>
      <Alert severity="info" data-cy="IdentityProviderCreator-Alert">
        Provide access to Operandio browser and mobile apps, from an external identity provider, using OpenID Connect /
        OAuth2 + PKCE.
      </Alert>

      <Box my={3}>
        <Box flexGrow={1} mb={2}>
          <TextField
            variant="outlined"
            fullWidth
            id="name"
            label="Name"
            name="name"
            value={name}
            onChange={(event) => handleChange(event, setName)}
            required
            data-cy="TextField-name"
          />
        </Box>
      </Box>
      <Box mb={3}>
        <Box flexGrow={1} mb={2}>
          <TextField
            variant="outlined"
            fullWidth
            id="client"
            label="Client ID"
            name="client"
            value={client}
            onChange={(event) => handleChange(event, setClient)}
            required
            data-cy="TextField-client"
          />
        </Box>
      </Box>
      <Box mb={3}>
        <Box flexGrow={1} mb={2}>
          <TextField
            variant="outlined"
            fullWidth
            id="authority"
            label="Authority"
            name="authority"
            value={authority}
            onChange={(event) => handleChange(event, setAuthority)}
            required
            data-cy="TextField-authority"
          />
        </Box>
      </Box>
      <Box mb={3}>
        <ExpansionPanel title="Advanced options">
          <ColumnBox width="100%">
            <ColumnBox width="100%" mb={3}>
              <IdentityProviderPromptOutlinedSelect
                label="Browser prompt"
                value={browserPrompt}
                onChange={(event) => handleChange(event, setBrowserPrompt)}
              />
            </ColumnBox>
            <ColumnBox width="100%" mb={3}>
              <IdentityProviderPromptOutlinedSelect
                label="Device prompt"
                value={devicePrompt}
                onChange={(event) => handleChange(event, setDevicePrompt)}
              />
            </ColumnBox>
            <FieldSectionHeading mb={0.5}>User provisioning</FieldSectionHeading>
            <Caption mb={3}>
              Users are provisioned using a just-in-time approach by default. Users are created as they are
              authenticated by the identity provider, if there is no pre-provisioned user profile found. To
              pre-provision user profiles, add them through the People area prior to their first login selecting the{" "}
              <strong>Single sign-on user</strong> option.
            </Caption>
            <RoleOutlinedSelect
              label="User default permission level"
              value={userDefaultRole}
              onChange={(event) => handleChange(event, setUserDefaultRole)}
              noneLabel="(Use system default)"
            />
            <FormHelperText>
              Default permission level assigned to just-in-time provisioned users, authenticated by this identity
              provider.
            </FormHelperText>
          </ColumnBox>
        </ExpansionPanel>
      </Box>

      <Box mb={1}>
        <Divider />
      </Box>

      <CreatorActions
        id="IdentityProviderCreator-CreatorActions"
        subject="Identity Provider"
        submitLoading={loading}
        onClose={handleClose}
        onSubmit={handleSubmit}
        disableSubmit={!isFormValid || loading}
      />
    </>
  )

  return (
    <CreatorMaster
      open={open}
      subject="Identity Provider"
      form={form}
      isEdit={isEdit}
      isInline={isInline}
      onClose={handleClose}
    />
  )
}

export { IdentityProviderCreator }
