import { Box, IconButton, makeStyles, Tooltip } from "@material-ui/core"
import React from "react"
import { Icon, LoadingSpinner } from ".."

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.text.primary,
  },
}))

const ReportToolsIconButton = ({ title = "Download data", size = "small", icon, loading, disabled, ...props }) => {
  const classes = useStyles()

  return (
    <Tooltip title={title}>
      <Box>
        <IconButton size={size} className={classes.button} disabled={disabled || loading} {...props}>
          {!loading && <Icon name={icon} />}
          {loading && <LoadingSpinner size={20} delay={false} />}
        </IconButton>
      </Box>
    </Tooltip>
  )
}

export default ReportToolsIconButton
