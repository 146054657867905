const labelSizes = [
  // Metric labels
  {
    id: "6473da56c0b304206dd57717",
    title: "40x15mm",
    width: 40,
    height: 15,
    padding: 1,
    format: "metric",
  },
  {
    id: "6473da5c60ee55e7e5bc3b61",
    title: "40x28mm",
    width: 40,
    height: 28,
    padding: 1,
    format: "metric",
  },
  {
    id: "6473da6132007b3f89f3d706",
    title: "40x40mm",
    width: 40,
    height: 40,
    padding: 1,
    format: "metric",
  },
  {
    id: "6473da650f3e6839659b9045",
    title: "50x25mm",
    width: 50,
    height: 25,
    padding: 1,
    format: "metric",
  },
  // Imperial labels
  {
    id: "6473da6b7dc1e70f56bbd280",
    title: `1"x1"`,
    width: 25.4,
    height: 25.4,
    padding: 1,
    format: "imperial",
  },
  {
    id: "6473da6f4a4a3766c1a0399b",
    title: `2"x1"`,
    width: 50.8,
    height: 25.4,
    padding: 1,
    format: "imperial",
  },
  {
    id: "6473da736afa4ee546321279",
    title: `2.2"x1"`,
    width: 55.88,
    height: 25.4,
    padding: 1,
    format: "imperial",
  },
  {
    id: "6473da777235837bc9f1e322",
    title: `2.2"x2"`,
    width: 55.88,
    height: 50.8,
    padding: 1,
    format: "imperial",
  },
  {
    id: "6473da7b2541ff95ea382e6f",
    title: `2"x1.25"`,
    width: 55.88,
    height: 31.75,
    padding: 1,
    format: "imperial",
  },
]

export { labelSizes }
