import { useQuery, gql, useLazyQuery } from "@apollo/client"
import { FOOD_ITEM_FIELDS } from "./fragments/foodItemFieldsFragment"

const FOOD_ITEM_QUERY = gql`
  query FoodItem($id: ID!) {
    foodItem(id: $id) {
      ...FoodItemFields
    }
  }
  ${FOOD_ITEM_FIELDS}
`

const useQueryFoodItem = (options) => useQuery(FOOD_ITEM_QUERY, options)

const useLazyQueryFoodItem = (options) => useLazyQuery(FOOD_ITEM_QUERY, options)

export { useQueryFoodItem, useLazyQueryFoodItem }
