import React, { memo } from "react"
import { TooltipIcon, TimeAgo } from ".."
import { usePersonAccreditationStatusStyles } from "./PersonAccreditationStatus"
import { USER_ACCREDITATIONS_STATUS, USER_ACCREDITATIONS_STATUS_LABELS } from "../../data"

const PersonAccreditationItemStatus = memo(function PersonAccreditationItemStatus({ userAccreditation, onClick }) {
  const { status, statusAt, expiresAt } = userAccreditation
  const classes = usePersonAccreditationStatusStyles({ statusAt })

  const handleClick = () => {
    onClick && onClick(userAccreditation)
  }

  const label = USER_ACCREDITATIONS_STATUS_LABELS[status]

  if (status === USER_ACCREDITATIONS_STATUS.WARNING) {
    return (
      <TooltipIcon
        tooltip={
          <>
            {label} <TimeAgo date={expiresAt} />
          </>
        }
        name="warning"
        className={classes.expiring}
        refClassName={classes.iconRef}
        onClick={handleClick}
      />
    )
  }
  if (status === USER_ACCREDITATIONS_STATUS.EXPIRED) {
    return (
      <TooltipIcon
        tooltip={
          <>
            {label} <TimeAgo date={expiresAt} />
          </>
        }
        name="warning"
        className={classes.expired}
        refClassName={classes.iconRef}
        onClick={handleClick}
      />
    )
  }
  if (status === USER_ACCREDITATIONS_STATUS.AWAITING) {
    return (
      <TooltipIcon
        tooltip={
          <>
            {label} <TimeAgo date={statusAt} />
          </>
        }
        name="accreditations-awaiting"
        className={classes.awaiting}
        refClassName={classes.iconRef}
        onClick={handleClick}
      />
    )
  }

  return (
    <TooltipIcon
      tooltip={label}
      name="accreditations-compliant"
      className={classes.compliant}
      refClassName={classes.iconRef}
      onClick={handleClick}
    />
  )
})

export { PersonAccreditationItemStatus }
