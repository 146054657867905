import { useLazyQuery, gql } from "@apollo/client"
import { toId } from "../../utils"
import { COMMENT_CREATED_SUBSCRIPTION, POST_UPDATED_SUBSCRIPTION } from "./useQueryPosts"
import { POST_FIELDS } from "./fragments/postFieldsFragment"

const POST_QUERY = gql`
  query Post($id: ID!) {
    post: postV2(id: $id) {
      ...PostFields
    }
  }
  ${POST_FIELDS}
`

const postUpdatedSubscriptionUpdateQuery = (prev, { subscriptionData }) => {
  if (!subscriptionData.data) return prev
  const updatedItem = subscriptionData.data.postUpdated
  const next = JSON.parse(JSON.stringify(prev))
  let post = next.post
  if (post) {
    post = { ...updatedItem }
  }
  return next
}

// const postDeletedSubscriptionUpdateQuery = (prev, { subscriptionData }) => {
//   if (!subscriptionData.data) return prev
//   const next = JSON.parse(JSON.stringify(prev))
//   console.log(next)
//   // next.post = [...prev.post.filter((post) => toId(post) !== toId(subscriptionData.data.postDeleted))]
//   return next
// }

const commentCreatedSubscriptionUpdateQuery = (prev, { subscriptionData }) => {
  if (!subscriptionData.data) return prev
  const newComment = subscriptionData.data.commentCreated
  const next = JSON.parse(JSON.stringify(prev))
  const post = next.post
  if (
    next.post &&
    toId(next.post) === toId(newComment.post) &&
    !post.comments.find((c) => toId(c) === toId(newComment))
  ) {
    post.comments.push(newComment)
  }
  return next
}

const subscribe = (result) => () =>
  [
    result.subscribeToMore({
      document: POST_UPDATED_SUBSCRIPTION,
      updateQuery: postUpdatedSubscriptionUpdateQuery,
      onError: (err) => console.log("[useLazyQueryPost][POST_UPDATED_SUBSCRIPTION]", err),
    }),
    // result.subscribeToMore({
    //   document: POST_DELETED_SUBSCRIPTION,
    //   // updateQuery: postDeletedSubscriptionUpdateQuery,
    //   onError: (err) => console.log("[useLazyQueryPost][POST_DELETED_SUBSCRIPTION]", err),
    // }),
    result.subscribeToMore({
      document: COMMENT_CREATED_SUBSCRIPTION,
      updateQuery: commentCreatedSubscriptionUpdateQuery,
      onError: (err) => console.log("[useLazyQueryPost][COMMENT_CREATED_SUBSCRIPTION]", err),
    }),
  ]

const useLazyQueryPost = () => {
  const [loadPost, result] = useLazyQuery(POST_QUERY, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  })

  return [
    loadPost,
    {
      ...result,
      subscribe: subscribe(result),
    },
  ]
}

export { useLazyQueryPost, POST_QUERY }
