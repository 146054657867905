import moment from "moment-timezone"

const usePrepUtils = () => {
  const getNavigateDateLink = (date, timeZone) => `/prep/list/${moment.tz(moment(date), timeZone).format("YYYY-MM-DD")}`

  const getPrepListNavigateDateLink = (locationDay, id) => `/prep/list/${locationDay}/${id}`

  return {
    getNavigateDateLink,
    getPrepListNavigateDateLink,
  }
}

export { usePrepUtils }
