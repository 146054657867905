import { NOTIFICATION_TYPE } from "../data"
import { getNavigateToLink as getNavigateToJobLink } from "./useJobUtils"

const useNotificationUtils = () => {
  const getNavigateToLink = (notification) => {
    switch (notification.type) {
      case NOTIFICATION_TYPE.POST:
        return `/hub/post/${notification?.post?.id || "unknown"}`
      case NOTIFICATION_TYPE.ACTION:
        return `/hub/action/${notification?.action?.id || "unknown"}`
      case NOTIFICATION_TYPE.TRAINING:
        return `/knowledge/training/${notification?.userTraining?.id || "unknown"}`
      case NOTIFICATION_TYPE.ACCREDITATION:
        return "/account/accreditation"
      case NOTIFICATION_TYPE.JOB:
        return getNavigateToJobLink(notification.job)
      default:
    }
  }

  return {
    getNavigateToLink,
  }
}

export { useNotificationUtils }
