import React, { memo } from "react"
import { useDateUtils } from "../../utils"

const FormatScheduleDate = ({ value }) => {
  const { formatScheduleDate } = useDateUtils()

  try {
    return <span>{formatScheduleDate(value)}</span>
  } catch (ex) {
    return <>Data invalid.</>
  }
}

const FormatScheduleDateMemo = memo(FormatScheduleDate)

export { FormatScheduleDateMemo as FormatScheduleDate }
