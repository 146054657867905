import React from "react"
import clsx from "clsx"
import { Paper, Box, makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      boxShadow: theme.palette.hoverCard.boxShadow,
    },
    "&:focus": {
      boxShadow: theme.palette.hoverCard.boxShadow,
    },
  },
  selected: {
    border: `2px solid ${theme.palette.grey[400]}`,
  },
  completed: {
    backgroundColor: theme.palette.cards.completed.backgroundColor,
    border: `2px solid ${theme.palette.cards.completed.borderColor}`,
  },
  overdue: {
    border: `2px solid ${theme.palette.error.main}`,
  },
  skipped: {
    backgroundColor: theme.palette.grey[100],
    border: `2px solid ${theme.palette.grey[400]}`,
  },
}))

const PaperBox = ({
  children,
  elevation = 3,
  fullWidth = false,
  fullHeight = false,
  selected,
  completed,
  skipped,
  overdue,
  paperProps,
  boxProps,
  className = "",
  ...props
}) => {
  const classes = useStyles()

  const displayClasses = [className, classes.root]
  if (selected) displayClasses.push(classes.selected)
  if (completed) displayClasses.push(classes.completed)
  if (overdue) displayClasses.push(classes.overdue)
  if (skipped) displayClasses.push(classes.skipped)

  return (
    <Paper
      elevation={elevation}
      style={{ width: fullWidth ? "100%" : null, height: fullHeight ? "100%" : null }}
      className={clsx(displayClasses)}
      {...paperProps}
    >
      <Box {...props} {...boxProps}>
        {children}
      </Box>
    </Paper>
  )
}

export { PaperBox }
