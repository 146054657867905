import { gql, useQuery } from "@apollo/client"

const USER_TRAINING_FIELDS = gql`
  fragment UserTrainingFields on UserTraining {
    id
    course
    name
    dueAt
    showUserScore
    status {
      modules {
        id
        name
        knowledges {
          id
          knowledge {
            id
            title
          }
          answers {
            knowledgeContent
            answer
            questionText
            answerText
            isCorrect
          }
          completedAt
        }
        percentComplete
        completedAt
        status
        score {
          total
          correct
          incorrect
          percent
        }
      }
      activeKnowledge
      activeModule
      percentComplete
      completedAt
      score {
        total
        correct
        incorrect
        percent
      }
    }
    author {
      firstName
      lastName
    }
    createdAt
  }
`

const userTrainingsQueryTypePolicies = {
  root: {
    userTrainingsV2: {
      keyArgs: ["user"],
      merge(_, next) {
        return { ...next }
      },
    },
  },
  scoped: {
    UserTrainingsQuery: {
      fields: {
        list: {
          merge(_, next) {
            return [...next]
          },
          keyArgs: ["filter"],
        },
      },
    },
  },
}

const USER_TRAININGS_QUERY = gql`
  query UserTrainings($user: ID!, $filter: UserTrainingsFilterInput) {
    userTrainings: userTrainingsV2(user: $user) {
      list(filter: $filter) {
        ...UserTrainingFields
      }
    }
  }
  ${USER_TRAINING_FIELDS}
`

const useQueryUserTrainings = (options) => useQuery(USER_TRAININGS_QUERY, options)

export { useQueryUserTrainings, USER_TRAINING_FIELDS, userTrainingsQueryTypePolicies }
