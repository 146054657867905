import { useMutation, gql } from "@apollo/client"
import { USER_TRAINING_FIELDS } from "."

const CREATE_USER_TRAINING_MUTATION = gql`
  mutation CreateUserTraining($course: ID!, $user: ID!) {
    createUserTraining(course: $course, user: $user) {
      ...UserTrainingFields
    }
  }
  ${USER_TRAINING_FIELDS}
`

const useMutationCreateUserTraining = () => useMutation(CREATE_USER_TRAINING_MUTATION)

export { useMutationCreateUserTraining }
