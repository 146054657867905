import { useQuery, gql, useLazyQuery } from "@apollo/client"

const INTEGRATION_PROVIDER_ATTRIBUTE_TYPE_FIELDS = gql`
  fragment IntegrationProviderAttributeTypeFields on IntegrationProviderAttributeType {
    id
    name
    description
    key
    component
    dataSource
    protect
  }
`

const INTEGRATION_PROVIDER_FIELDS = gql`
  fragment IntegrationProviderFields on IntegrationProvider {
    id
    name
    link
    shortDescription
    description
    canEnable
    canDisable
    connect {
      authoriseEndpoint
      client
      responseType
      scope
    }
    attributes {
      integration {
        ...IntegrationProviderAttributeTypeFields
      }
      location {
        ...IntegrationProviderAttributeTypeFields
      }
      locationGroup {
        ...IntegrationProviderAttributeTypeFields
      }
      group {
        ...IntegrationProviderAttributeTypeFields
      }
      role {
        ...IntegrationProviderAttributeTypeFields
      }
      user {
        ...IntegrationProviderAttributeTypeFields
      }
    }
    operations {
      name
      description
      method
    }
    events
    remoteEvents {
      remoteName
    }
  }
  ${INTEGRATION_PROVIDER_ATTRIBUTE_TYPE_FIELDS}
`

const INTEGRATION_PROVIDERS_QUERY = gql`
  query IntegrationProviders {
    integrationProviders {
      ...IntegrationProviderFields
    }
  }
  ${INTEGRATION_PROVIDER_FIELDS}
`

const INTEGRATION_PROVIDER_QUERY = gql`
  query IntegrationProvider($id: ID!) {
    integrationProvider(id: $id) {
      ...IntegrationProviderFields
    }
  }
  ${INTEGRATION_PROVIDER_FIELDS}
`

const useQueryIntegrationProviders = (options) => useQuery(INTEGRATION_PROVIDERS_QUERY, options)

const useQueryIntegrationProvider = () => useQuery(INTEGRATION_PROVIDER_QUERY)

const useLazyQueryIntegrationProvider = () => useLazyQuery(INTEGRATION_PROVIDER_QUERY)

export {
  useQueryIntegrationProviders,
  useQueryIntegrationProvider,
  useLazyQueryIntegrationProvider,
  INTEGRATION_PROVIDER_FIELDS,
}
