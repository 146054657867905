import React from "react"
import MuiCircularProgress from "@material-ui/core/CircularProgress"
import { makeStyles } from "@material-ui/styles"
import { Box, Grid } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: ({ size }) => size,
    height: ({ size }) => size,
  },
  chart: {
    position: "absolute",
  },
  value: {
    color: ({ complete }) => (complete === 0 ? theme.palette.text.faint : theme.palette.success.main),
    position: "absolute",
    width: "100%",
    textAlign: "center",
    fontSize: ({ size }) => Math.max(Math.floor(size * 0.2), 16),
    fontWeight: 800,
    lineHeight: "16px",
  },
  innerCircle: {
    marginLeft: ({ size }) => Math.floor(size * 0.075),
    position: "absolute",
    width: ({ size }) => Math.floor(size * 0.85),
    height: ({ size }) => Math.floor(size * 0.85),
    borderRadius: ({ size }) => Math.floor(size * 0.85),
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
  },
}))

const useCircularProgressStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: ({ backing }) => (backing ? theme.palette.chart.background : "transparent"),
    borderRadius: ({ size }) => size,
  },
}))

const useCircularProgressCompleteStyles = makeStyles((theme) => ({
  root: {
    borderRadius: ({ size }) => size,
  },
  colorPrimary: {
    color: theme.palette.success.main,
  },
}))

const MultiSeriesCircularProgress = ({ series, complete = 0, size = 120, ...rest }) => {
  const classes = useStyles({ size, complete })
  const circularProgressBackingClasses = useCircularProgressStyles({ size, backing: true })
  const circularProgressMainClasses = useCircularProgressStyles({ size })
  const circularProgressCompleteStyles = useCircularProgressCompleteStyles({ size })

  return (
    <Grid container direction="row" alignItems="center">
      <Box className={classes.wrapper}>
        <Box className={classes.chart}>
          <MuiCircularProgress
            classes={circularProgressBackingClasses}
            variant="determinate"
            value={0}
            size={size}
            {...rest}
          />
        </Box>
        {series
          .filter((item) => item.value)
          .map(({ value, cumulative, color }, index) => (
            <Box key={index} className={classes.chart}>
              <MuiCircularProgress
                classes={color === "complete" ? circularProgressCompleteStyles : circularProgressMainClasses}
                variant="determinate"
                value={cumulative + value}
                color={["primary", "secondary"].includes(color) ? color : "primary"}
                size={size}
                {...rest}
              />
            </Box>
          ))}
      </Box>
      <div className={classes.innerCircle}>
        <Box className={classes.value}>{complete}%</Box>
      </div>
    </Grid>
  )
}

export { MultiSeriesCircularProgress }
