import React from "react"

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  makeStyles,
} from "@material-ui/core"
import PropTypes from "prop-types"

const useStyles = makeStyles(() => ({
  centerTitle: {
    textAlign: "center",
  },
  actions: {
    display: "flex",
    justifyContent: "center",
    fontSize: 14,
    marginBottom: 8,
  },
}))

const SuccessfulEmailChangeDialog = ({ open = false, onClose, user = {} }) => {
  const classes = useStyles()

  const isYourself = user && Object.entries(user).length === 0

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className={classes.centerTitle}>
        {isYourself ? "Please verify your email" : "Verification email sent"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {isYourself
            ? "Check your new email address to complete your email change."
            : `${
                user?.fullName ?? "This user"
              } will need to check this email address to complete their email address change.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={onClose} variant="contained" autoFocus color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SuccessfulEmailChangeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  user: PropTypes.object,
}

export default SuccessfulEmailChangeDialog
