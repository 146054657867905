import { useMutation, gql } from "@apollo/client"
import { LABEL_TEMPLATE_FIELDS } from "./useQueryLabelTemplates"

const CREATELABEL_TEMPLATE_MUTATION = gql`
  mutation CreateLabelTemplate(
  $input: LabelTemplateInput!, 
  ) {
    createLabelTemplate(
    input: $input
    ) {
      ${LABEL_TEMPLATE_FIELDS}
    } 
  }
`

const useMutationCreateLabelTemplate = () => useMutation(CREATELABEL_TEMPLATE_MUTATION)

export { useMutationCreateLabelTemplate }
