import { gql } from "@apollo/client"

const mutationRequestPasscode = async ({ username }, client) => {
  try {
    const response = await client.mutate({
      variables: { username },
      mutation: gql`
        mutation ResetPasscode($username: String!) {
          resetPasscode(username: $username)
        }
      `,
    })
    return response
  } catch (err) {
    return err
  }
}

export { mutationRequestPasscode }
