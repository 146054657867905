import { gql } from "@apollo/client"

const RESPONSE_SET_FIELDS = gql`
  fragment ResponseSetFields on ResponseSet {
    id
    name
    icon
    responses {
      id
      text
      value
      score
      noScore
      failure
    }
    displayAs
    createdAt
    updatedAt
  }
`

export { RESPONSE_SET_FIELDS }
