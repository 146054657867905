import React, { useState } from "react"
import { Typography, useTheme, useMediaQuery, Box, TextField } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { useAuth } from "../../services"
import { FlexBox, RowBox } from "../Boxes"
import { AdminOnlyLabel } from "./AdminOnlyLabel"

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginBottom: ({ noMargin, xs }) => (noMargin ? 0 : xs ? theme.spacing(1) : theme.spacing(3)),
  },
  title: {
    fontSize: ({ xs }) => (xs ? 20 : 24),
    fontWeight: "600",
    lineHeight: ({ xs }) => (xs ? "28px" : "30px"),
    "&&:hover": {
      backgroundColor: ({ canUpdate }) => (canUpdate ? theme.palette.inlineEditable.hover.background : null),
      cursor: "text",
    },
  },
  titleWarning: {
    color: theme.palette.error.main,
  },
  adminOnly: {},
}))

const ModuleHeading = ({
  id,
  children,
  value,
  unconfirmed,
  className,
  noMargin,
  editable,
  editLabel,
  onEdited,
  adminOnly,
  rowBoxProps = {},
}) => {
  const theme = useTheme()
  const { hasPermission } = useAuth()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const canUpdate = editable && hasPermission(editable)
  const classes = useStyles({ noMargin, xs, canUpdate })

  const [editValue, setEditValue] = useState(false)
  const [newValue, setNewValue] = useState("")

  const handleEdit = () => {
    if (!canUpdate) return
    setNewValue(value)
    setEditValue(true)
  }

  const handleValueChange = (event) => {
    setNewValue(event.target.value)
  }

  const handleEdited = () => {
    onEdited && onEdited(newValue)
    setEditValue(false)
  }

  const handleEditKeyDown = (e) => {
    if (e.key === "Enter") {
      handleEdited()
    }
  }

  return (
    <>
      {!editValue && (
        <RowBox className={classes.wrapper} {...rowBoxProps}>
          <Box>
            <Typography
              id={id}
              variant="h2"
              className={`${classes.title} ${unconfirmed ? classes.titleWarning : ""} ${className}`}
              onClick={handleEdit}
            >
              {children || value}
            </Typography>
          </Box>
          {adminOnly && (
            <FlexBox ml="auto" className={classes.adminOnly} alignSelf="flex-end">
              <AdminOnlyLabel />
            </FlexBox>
          )}
        </RowBox>
      )}
      {editValue && (
        <RowBox mb={1} flexGrow={1}>
          <TextField
            id={id}
            label={editLabel}
            variant="outlined"
            value={newValue}
            onChange={handleValueChange}
            fullWidth
            required
            autoFocus
            onBlur={handleEdited}
            onKeyDown={handleEditKeyDown}
          />
        </RowBox>
      )}
    </>
  )
}

export default ModuleHeading
