import React from "react"
import { Box, makeStyles, useTheme } from "@material-ui/core"
import { Chart, PieSeries, Tooltip } from "@devexpress/dx-react-chart-material-ui"
import { EventTracker, HoverState, Palette } from "@devexpress/dx-react-chart"
import { RowBox, ColumnBox } from ".."

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    textAlign: "center",
    minWidth: 100,
    cursor: ({ clickable }) => (clickable ? "pointer" : "default"),
  },
  text: {
    maxWidth: 100,
    fontSize: 12,
  },
  value: {
    color: ({ complete, total }) =>
      !total ? theme.palette.chart.background : complete ? theme.palette.success.main : theme.palette.primary.main,
    position: "absolute",
    textAlign: "center",
    fontSize: ({ size }) => Math.max(Math.floor(size * 0.2), 16),
    fontWeight: ({ total }) => (!total ? 600 : 800),
    marginTop: 40,
    // lineHeight: "100px",
    zIndex: 1,
  },
}))

const CircularStepCompletionChart = ({ text, data, mr = 2, mb = 1, ...props }) => {
  const { completed, overdue, skipped, uncompleted, total } = data
  const classes = useStyles({ clickable: !!props.onClick, complete: uncompleted === 0, total })
  const theme = useTheme()

  const colours = [
    theme.palette.success.main,
    theme.palette.error.light,
    theme.palette.primary.main,
    theme.palette.chart.background,
  ]

  const chartData = [
    { argument: "On-time", value: completed },
    { argument: "Late", value: overdue },
    { argument: "Skipped", value: skipped },
    { argument: "Uncompleted", value: total ? uncompleted : 1 },
  ]

  const overall = total ? Math.floor(((total - uncompleted) / total) * 100) : 0

  const TooltipContent = ({ targetItem: { point } }) => {
    const { argument, value } = chartData[point]
    const percentage = Math.floor((value / total) * 100)

    return (
      <Box>
        <Box>
          <strong>{argument}</strong>
        </Box>
        <Box>
          {percentage}% ({value} steps)
        </Box>
      </Box>
    )
  }

  return (
    <>
      <ColumnBox alignItems="center" justifyContent="flex-start" mr={mr} mb={mb} className={classes.root} {...props}>
        <ColumnBox>
          <Chart width="100" height="100" data={chartData}>
            <Palette scheme={colours} />
            <PieSeries valueField="value" argumentField="argument" innerRadius={0.75} />
            <EventTracker />
            {total > 0 && <Tooltip contentComponent={TooltipContent} />}
            <HoverState />
          </Chart>
        </ColumnBox>
        <Box className={classes.value}>
          {!!total && <>{overall}%</>}
          {!total && <>None</>}
        </Box>
        <RowBox alignItems="center" justifyContent="center">
          <Box className={classes.text}>{text}</Box>
        </RowBox>
      </ColumnBox>
    </>
  )
}
export { CircularStepCompletionChart }
