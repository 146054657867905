import React from "react"
import { ListItemAvatar, ListItemText } from "@material-ui/core"
import { Avatar, CheckboxPickerListItem, Icon } from ".."
import { GROUP_VISIBILITY } from "../../data"

const GroupPickerListItem = ({ iconName, ...props }) => {
  let finalIconName = iconName
  if (!finalIconName && props.item.visibility === GROUP_VISIBILITY.PRIVATE) {
    finalIconName = "group-visibility-private"
  }
  if (!finalIconName) {
    finalIconName = "group"
  }

  return (
    <CheckboxPickerListItem {...props}>
      <ListItemAvatar>
        <Avatar icon={<Icon name={finalIconName} />} />
      </ListItemAvatar>
      <ListItemText>{props.item.name}</ListItemText>
    </CheckboxPickerListItem>
  )
}

export default GroupPickerListItem
