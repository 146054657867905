import { mapToIds, multipleSelectChange } from "."

const useReportUtils = () => {
  const getNavigateToLink = (area, days, locations, groups) =>
    `/reports/${area}/${days}/${mapToIds(locations).join("-")}/${mapToIds(groups).join("-")}`

  const getNavigateToOverviewLink = (days, locations, groups, tags) =>
    `/reports/overview/${days}/${mapToIds(locations).join("-")}/${mapToIds(groups).join("-")}/${mapToIds(tags).join(
      "-",
    )}`

  const getNavigateToJobsLink = (days, locations, groups, process, tags) =>
    `${getNavigateToLink("jobs", days, locations, groups)}/${process}/${mapToIds(tags).join("-")}`

  const getNavigateToActionsLink = (days, locations, groups, process, tags) =>
    `${getNavigateToLink("actions", days, locations, groups)}/${process}/${mapToIds(tags).join("-")}`

  const getNavigateToAuditsLink = (days, locations, groups, process, tags) =>
    `${getNavigateToLink("audits", days, locations, groups)}/${process}/${mapToIds(tags).join("-")}`

  const getNavigateToPeopleLink = (days, locations, groups, user) =>
    `${getNavigateToLink("people", days, locations, groups)}${user ? `/${user}` : ""}`

  const getNavigateToTrainingLink = (days, locations, groups) => getNavigateToLink("training", days, locations, groups)

  return {
    getNavigateToLink,
    getNavigateToOverviewLink,
    getNavigateToJobsLink,
    getNavigateToActionsLink,
    getNavigateToAuditsLink,
    getNavigateToPeopleLink,
    getNavigateToTrainingLink,

    filterChange: multipleSelectChange,
  }
}

export { useReportUtils }
