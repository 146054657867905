import React from "react"
import ReportToolsIconButton from "./ReportToolsIconButton"

const FullscreenIconButton = ({ fullscreen, ...props }) => (
  <ReportToolsIconButton
    title={fullscreen ? "Exit fullscreen" : "Fullscreen"}
    icon={fullscreen ? "fullscreen-exit" : "fullscreen"}
    {...props}
  />
)

export { FullscreenIconButton }
