import { useQuery, gql } from "@apollo/client"
import { TAG_FIELDS } from "./fragments/tagFieldsFragment"

const TAGS_QUERY = gql`
  query Tags {
    tags {
      ...TagFields
    }
  }
  ${TAG_FIELDS}
`

const useQueryTags = (options) => useQuery(TAGS_QUERY, options)

export { useQueryTags, TAGS_QUERY, TAG_FIELDS }
