import { useMutation, gql } from "@apollo/client"

const DELETELABEL_CATEGORY_MUTATION = gql`
  mutation DeleteLabelCategory($id: ID!) {
    labelCategory(id: $id) {
      delete
    }
  }
`

const useMutationDeleteLabelCategory = () => useMutation(DELETELABEL_CATEGORY_MUTATION)

export { useMutationDeleteLabelCategory }
