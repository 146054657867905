import React, { useMemo } from "react"
import { Box, makeStyles, Typography } from "@material-ui/core"
import { Trans } from "@lingui/macro"
import { ColumnBox, PaperBox, RowBox } from "../Boxes"
import { useQueryUserTrainingAssessments, USER_TRAINING_ASSESSMENT_STATUS } from "../../data"
import { toId } from "../../utils"
import { FieldSectionHeading } from "../Headings"
import { UserTrainingAssessmentStatusChip } from "../Chips"
import { CompletedByAvatar } from "../Avatar"
import { NoItemsMessage } from "../Lists"
import { LoadingSpinner } from "../LoadingSpinner"

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: "500",
  },
  caption: {
    fontSize: 12,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
    textAlign: "right",
    whiteSpace: "nowrap",
  },
  progress: {
    width: 100,
  },
  completedChip: {
    fontWeight: "600",
    color: "white",
    backgroundColor: theme.palette.success.main,
    marginRight: theme.spacing(1),
  },
  remove: {
    cursor: "pointer",
    marginLeft: theme.spacing(1),
  },
}))

const PersonTrainingAssessment = ({ user }) => {
  const classes = useStyles()
  const { data, loading } = useQueryUserTrainingAssessments({ variables: { user: toId(user) } })

  const userTrainingAssessments = useMemo(
    () =>
      data?.userTrainingAssessments
        .filter((item) => item.status !== USER_TRAINING_ASSESSMENT_STATUS.NONE)
        .sort((a, b) => a.module.name.localeCompare(b.module.name)) || [],
    [data],
  )

  const hasItems = Boolean(data?.userTrainingAssessments.length)

  return (
    <Box mb={5}>
      {hasItems && (
        <>
          <FieldSectionHeading>Training modules</FieldSectionHeading>
          {userTrainingAssessments.map((assessment, index) => (
            <Box key={toId(assessment)} mt={index > 0 ? 1 : 0}>
              <PaperBox completed={assessment.status === USER_TRAINING_ASSESSMENT_STATUS.COMPETENT}>
                <RowBox p={2} alignItems="center">
                  <ColumnBox mr={2}>
                    <Typography variant="h2" className={classes.title}>
                      {assessment.module.name}
                    </Typography>
                  </ColumnBox>
                  <Box ml="auto">
                    <RowBox>
                      {(assessment.trainedAt || assessment.trainedBy) && (
                        <CompletedByAvatar at={assessment.trainedAt} by={assessment.trainedBy} time={false} mr={1} />
                      )}

                      <UserTrainingAssessmentStatusChip
                        user={user}
                        module={assessment.module}
                        assessment={assessment}
                        size="small"
                      />
                    </RowBox>
                  </Box>
                </RowBox>
              </PaperBox>
            </Box>
          ))}
        </>
      )}
      {loading && !data?.userTrainingAssessments && (
        <Box display="flex" justifyContent="center" mt={3}>
          <LoadingSpinner size={60} />
        </Box>
      )}
      {!loading && !hasItems && (
        <NoItemsMessage>
          <Trans>No training assessments</Trans>
        </NoItemsMessage>
      )}
    </Box>
  )
}

export { PersonTrainingAssessment }
