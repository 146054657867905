import React, { useState, useEffect } from "react"
import Config from "react-global-configuration"
import { CircularProgress, Grid, IconButton, Box, Collapse } from "@material-ui/core"
import { CancelOutlined as CancelOutlinedIcon } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"
import { DisplayImage, ColumnBox, ImagesViewerModal } from ".."
import { preventDefault, useDeviceUtils } from "../../utils"

const useStyles = makeStyles((theme) => ({
  backing: {
    position: "relative",
    backgroundColor: theme.palette.images.backing.background,
    borderRadius: 4,
    height: ({ height }) => height,
    cursor: "pointer",
  },
  thumbnail: {
    borderRadius: 4,
    objectFit: "cover",
    height: "100%",
    width: "100%",
  },
  progress: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
  },
  remove: {
    position: "absolute",
    right: 10,
    top: 10,
    backgroundColor: "#ffffff",
    padding: 2,
    zIndex: 999,
    "&:hover": {
      backgroundColor: "#000000",
      color: "#ffffff",
    },
  },
}))

const UploadingImagesList = ({
  uploaded,
  uploading,
  onRemoveUpload,
  height = 150,
  gridItemProps = { xs: 6, sm: 4 },
  collapseTimeout = "auto",
  mb,
  ...rest
}) => {
  const classes = useStyles({ height })
  const { clientKiosk, clientDevice } = Config.get()
  const { canPostMessage, postImagesMessage } = useDeviceUtils()
  const [open, setOpen] = useState(null)
  const [uploads, setUploads] = useState([])

  useEffect(() => {
    const items = []
    if (uploaded && uploaded.length > 0) items.push(...uploaded)
    if (uploading && uploading.length > 0) items.push(...uploading.filter((u) => u.loaded < u.total))
    setUploads(items)
  }, [uploaded, uploading])

  const handleRemoveUpload = (event, id) => {
    preventDefault(event, () => {
      onRemoveUpload && onRemoveUpload(id, event)
    })
  }

  const handleImageClick = (index) =>
    (clientKiosk || clientDevice) && canPostMessage() ? handleImageClickDevice(index) : handleImageClickDefault(index)

  const handleImageClickDefault = (index) => {
    setOpen(index)
  }

  const handleImageClickDevice = (index) => {
    postImagesMessage(uploads, index)
  }

  const handleClose = () => {
    setOpen(null)
  }

  return (
    <>
      {open != null && <ImagesViewerModal uploads={uploads} startIndex={open} onClose={handleClose} />}

      <Collapse in={Boolean(uploads?.length)} timeout={collapseTimeout}>
        <Box display="flex" flexGrow={1} alignItems="center" flexWrap="wrap" mb={mb} {...rest}>
          <Grid container spacing={1}>
            {uploads
              .filter(({ fileGroup }) => fileGroup === "image")
              .sort((a, b) => a.order < b.order)
              .map((upload, index) => {
                const { id, fileName, loaded, total, key } = upload
                const item = typeof loaded === "undefined" || loaded === total
                return (
                  <Grid item key={id || fileName} {...gridItemProps}>
                    {key && (
                      <ColumnBox className={classes.backing} onClick={() => handleImageClick(index)}>
                        {!!onRemoveUpload && (
                          <IconButton
                            onClick={(event) => handleRemoveUpload(event, id)}
                            disabled={!item}
                            className={classes.remove}
                          >
                            <CancelOutlinedIcon />
                          </IconButton>
                        )}
                        {!item && (
                          <ColumnBox className={classes.progress} alignItems="center" justifyContent="center">
                            <CircularProgress value={Math.round((loaded / total) * 100)} size={32} />
                          </ColumnBox>
                        )}
                        {item && (
                          <DisplayImage
                            upload={upload}
                            width={height * 2 * 1.3}
                            height={height * 2}
                            alt={fileName}
                            className={classes.thumbnail}
                          />
                        )}
                      </ColumnBox>
                    )}
                  </Grid>
                )
              })}
          </Grid>
        </Box>
      </Collapse>
    </>
  )
}

export { UploadingImagesList }
