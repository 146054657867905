import React, { useState } from "react"
import { Container, Button, TextField, makeStyles, Box, Typography } from "@material-ui/core"
import { useApolloClient } from "@apollo/client"
import Config from "react-global-configuration"
import { Trans } from "@lingui/macro"
import { LogoFull, LoadingSpinner, NavLinkItem, PaperBox, PinInput } from "../../components"
import { useAuth } from "../../services"
import { PublicContainer } from "./PublicContainer"
import { PasswordInput } from "../../components/PasswordInput/PasswordInput"
import { useFormUtils } from "../../utils"

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 300,
    padding: 0,
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    marginBottom: theme.spacing(5),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    textAlign: "center",
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
}))

const Password = () => {
  const classes = useStyles()
  const client = useApolloClient()
  const { requestPasscode, verifyPasscode, resetPassword } = useAuth(client)
  const { isValid } = useFormUtils()
  const {
    auth: {
      password: { reset: config },
    },
  } = Config.get()

  const [step, setStep] = useState(0)
  const [username, setUsername] = useState("")
  const [passcode, setPasscode] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const handlePasswordChange = (value) => {
    setPassword(value)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)
    setError("")
    try {
      switch (step) {
        case 0: {
          // email
          const passcodeResult = await requestPasscode({ username })
          if (passcodeResult.data.resetPasscode === 2) setStep(4)
          else if (passcodeResult.data.resetPasscode) setStep(1)
          else setError("Error requesting password reset, please try again")
          break
        }
        case 1: {
          // pin
          const verifyResult = await verifyPasscode({ username, passcode })
          if (verifyResult.data.verifyPasscode) setStep(2)
          else setError("Invalid passcode, check and try again")
          break
        }
        case 2: {
          // password
          const passwordResult = await resetPassword({ username, passcode, password })
          if (passwordResult.data.resetPassword) setStep(3)
          else setError("Error requesting password reset, please try again")
          break
        }
        case 3: // conf
          break
        default:
          setStep(0)
      }
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const formValid = () => {
    switch (step) {
      case 0:
        return isValid(username)
      case 1:
        return isValid(passcode)
      case 2:
        return isValid(password)
      default:
        return false
    }
  }

  const isFormValid = formValid()

  return (
    <PublicContainer withCancel>
      <PaperBox p={5} m={2}>
        <Container component="main" className={classes.root}>
          <div className={classes.wrapper}>
            <Box mb={2}>
              <LogoFull width="197" />
            </Box>
            {error && (
              <Typography component="p">
                <br />
                <strong style={{ color: "Red" }}>{error}</strong>
                <br />
              </Typography>
            )}
            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              {step === 0 && (
                <>
                  <Box>
                    <p>
                      <Trans>Enter your email address and we'll send you a code to reset your password.</Trans>
                    </p>
                  </Box>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label={<Trans>Enter your email</Trans>}
                    name="username"
                    type="email"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    autoComplete="email"
                    disabled={loading}
                    data-cy="TextField-username"
                  />
                </>
              )}
              {step === 1 && (
                <>
                  <Box>
                    <p>
                      <Trans>
                        A reset code has been sent to your email address. Please enter the code to continue.
                      </Trans>
                    </p>
                  </Box>

                  <PinInput
                    length={config.passcodeLength}
                    alignItems="center"
                    onChange={setPasscode}
                    autoFocus
                    disableComplexityCheck
                    cy="PinInput-passcode"
                  />
                </>
              )}
              {step === 2 && (
                <>
                  <Box>
                    <p>
                      <Trans>Please enter your new password below.</Trans>
                    </p>
                  </Box>

                  <PasswordInput
                    required
                    fullWidth
                    label={<Trans>New password</Trans>}
                    name="password"
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    disabled={loading}
                    cy="PasswordInput-password"
                  />
                </>
              )}

              {step === 3 && (
                <>
                  <Box>
                    <p>
                      <Trans>Password reset successfully.</Trans>
                    </p>
                  </Box>
                  <Box display="flex" justifyContent="center" mt={2}>
                    <NavLinkItem to="/" text="Back to login" disabled={loading} />
                  </Box>
                </>
              )}

              {step === 4 && (
                <>
                  <Box>
                    <p>
                      <Trans>
                        An email has been sent to your email address. Please follow the instructions in the email to
                        continue.
                      </Trans>
                    </p>
                  </Box>
                </>
              )}

              {step <= 2 && (
                <>
                  {!loading && (
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      size="large"
                      disabled={loading || !isFormValid}
                      data-cy="Button-continue"
                    >
                      <span>
                        {step <= 1 && <Trans>Continue</Trans>}
                        {step === 2 && <Trans>Reset password</Trans>}
                      </span>
                    </Button>
                  )}
                  {loading && (
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      size="large"
                      disabled
                    >
                      <LoadingSpinner size="30px" delay={false} />
                    </Button>
                  )}
                </>
              )}
            </form>
          </div>
        </Container>
      </PaperBox>
    </PublicContainer>
  )
}

export default Password
