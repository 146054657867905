import { useMutation, gql } from "@apollo/client"
import { LABEL_FIELDS } from "./useQueryLabels"

const UPDATELABEL_MUTATION = gql`
  mutation UpdateLabel($id: ID!, $input: LabelInput!) {
    label(id: $id) {
      update(input: $input) {
        ${LABEL_FIELDS}
      }
    }
  }
`

const useMutationUpdateLabel = () => useMutation(UPDATELABEL_MUTATION)

export { useMutationUpdateLabel }
