import React from "react"
import { Box, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { MentionOutput, DownloadList } from ".."
import { ImagesViewer } from "../ImagesViewer/ImagesViewer"

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 20,
    fontWeight: "600",
    paddingTop: theme.spacing(2),
    overflowWrap: "anywhere",
  },
  content: {
    fontSize: 16,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    overflowWrap: "anywhere",
  },
  mentionItem: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mentionItemTitle: {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: "600",
    marginBottom: theme.spacing(1),
  },
  mentionItemDetail: {
    fontSize: 14,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
    marginBottom: 10,
  },
}))

const ActionContent = ({ action: { title, content, uploads } }) => {
  const classes = useStyles()

  return (
    <Box ml={0} mr={0}>
      {title && (
        <Typography variant="h1" className={classes.title}>
          {title}
        </Typography>
      )}
      <div className={classes.content}>
        <MentionOutput content={content} />
        <ImagesViewer uploads={uploads} />
        <DownloadList uploads={uploads} variant="cards" mt={2} />
      </div>
    </Box>
  )
}

export { ActionContent }
