export const isString = (str) => str !== null && typeof str.valueOf() === "string"

export const titleCase = (str) => str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase())

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1)

export const snakeToTitle = (s) =>
  s
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
    .replace(/[-_]+(.)/g, (_, c) => ` ${c.toUpperCase()}`) // First char after each -/_
