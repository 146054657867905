import { useMutation, gql } from "@apollo/client"
import { POST_FIELDS } from "./fragments/postFieldsFragment"

const UPDATEPOST_MUTATION = gql`
  mutation UpdatePostV2(
    $id: ID!
    $title: String!
    $content: String!
    $job: ID
    $knowledge: ID
    $hasComments: Boolean!
    $hasConfirm: Boolean!
    $visibleFrom: Date
    $locations: [ID!]!
    $users: [ID!]
    $groups: [ID!]
    $uploads: [ID!]
    $resendNotifications: Boolean!
  ) {
    updatePostV2(
      id: $id
      title: $title
      content: $content
      job: $job
      knowledge: $knowledge
      hasComments: $hasComments
      hasConfirm: $hasConfirm
      visibleFrom: $visibleFrom
      locations: $locations
      users: $users
      groups: $groups
      uploads: $uploads
      resendNotifications: $resendNotifications
    ) {
      ...PostFields
    }
  }
  ${POST_FIELDS}
`

const useMutationUpdatePost = () => useMutation(UPDATEPOST_MUTATION)

export default useMutationUpdatePost
