import { useMutation, gql } from "@apollo/client"

const ADMINABLE_USER_RESEND_USERNAME_CHANGE_MUTATION = gql`
  mutation AdminableUserResendUsernameChange($id: ID!) {
    adminableUser(id: $id) {
      resendUsernameChange
    }
  }
`

export const useMutationAdminableUserResendUsernameChange = () =>
  useMutation(ADMINABLE_USER_RESEND_USERNAME_CHANGE_MUTATION, {
    errorPolicy: "all",
  })
