import Base from "./Base"
import Test from "./Test"

const config = {
  ...Test,
  clientKiosk: true,

  showConfigInTitle: true,
  configTitle: "[KIOSK DEV]",

  auth: Test.auth ? { ...Test.auth } : { ...Base.auth },
}

// config.auth.idleTimeoutMs = 1000 * 60 * 30
config.auth.idleTimeoutMs = 1000 * 60 // 60 seconds

export default config
