import { gql } from "@apollo/client"

const ADMINABLE_GROUPS_FIELDS = gql`
  fragment AdminableGroupFields on Group {
    id
    visibility
    name
    attributes {
      id
      location
      integration
      key
      value
    }
    hideFromKiosk
  }
`

export { ADMINABLE_GROUPS_FIELDS }
