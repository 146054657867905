import React from "react"
import { Box, Divider, Button, makeStyles } from "@material-ui/core"
import { FlexBox } from "../Boxes"
import { ProcessCollapse } from "./ProcessCollapse"

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gap: theme.spacing(1),
    gridTemplateColumns: ({ count }) => `repeat(${count}, 1fr)`,
  },
}))

const NumberRatingStep = ({
  expand,
  count = 5,
  value,
  buttonClass,
  onClick,
  disabled,
  classes,
  collapseTimeout = "auto",
}) => {
  const gridClasses = useStyles({ count })

  const handleClick = (...args) => {
    onClick && onClick(...args)
  }

  return (
    <ProcessCollapse in={expand} timeout={collapseTimeout}>
      <Divider className={classes.divider} />
      <Box py={1} px={2}>
        <Box className={gridClasses.container}>
          {Array.from({ length: count }, (_, index) => {
            const answer = index + 1
            return (
              <RatingButton
                key={answer}
                answer={`${answer}`}
                value={value}
                onClick={handleClick}
                className={buttonClass}
                disabled={disabled}
              />
            )
          })}
        </Box>
      </Box>
    </ProcessCollapse>
  )
}

const RatingButton = ({ answer, value, onClick, className, disabled: disabledInput }) => {
  const selected = !!value && answer.toLowerCase() === value.toLowerCase()
  const disabled = !!value && !selected

  const handleClick = (event) => {
    onClick && onClick(event, answer.toLowerCase())
  }

  return (
    <FlexBox>
      <Button
        color={selected ? "primary" : "default"}
        variant={disabled ? "text" : "contained"}
        fullWidth
        onClick={handleClick}
        className={className}
        disabled={disabled || disabledInput}
      >
        {answer}
      </Button>
    </FlexBox>
  )
}

export { NumberRatingStep }
