import { useMutation, gql } from "@apollo/client"
import { LABEL_CATEGORIES_FIELDS } from "./useQueryLabelCategories"

const UPDATELABEL_CATEGORY_MUTATION = gql`
  mutation UpdateLabelCategory($id: ID!, $input: LabelCategoryInput!) {
    labelCategory(id: $id) {
      update(input: $input) {
        ${LABEL_CATEGORIES_FIELDS}
      }
    }
  }
`

const useMutationUpdateLabelCategory = () => useMutation(UPDATELABEL_CATEGORY_MUTATION)

export { useMutationUpdateLabelCategory }
