import React from "react"
import {
  Avatar as MuiAvatar,
  Divider,
  Box,
  Paper,
  Typography,
  Icon,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Breadcrumbs,
  useTheme,
  useMediaQuery,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { NavLink, useHistory } from "react-router-dom"
import { Trans, Plural } from "@lingui/macro"
import { AdornmentIcon, Avatar } from ".."
import TimeAgo from "../TimeAgo"
import { AuthorTools } from "../Author"
import { FlexBox, RowBox } from "../Boxes"

const useStyles = makeStyles((theme) => ({
  paper: {
    height: "100%",
    "&:hover": theme.palette.hoverCard,
    "&:focus": theme.palette.hoverCard,
  },
  root: {
    color: theme.palette.text.primary,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    marginLeft: theme.spacing(2),
    fontSize: 24,
    fontWeight: "600",
    lineHeight: "30px",
    alignSelf: "center",
  },
  description: {
    fontSize: 16,
    lineHeight: "24px",
    height: "100%",
  },
  footer: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    minHeight: 56,
  },
  lastUpdated: {
    color: theme.palette.text.secondary,
  },
  listItem: {
    padding: theme.spacing(0.25, 2, 0.25, 2),
    color: theme.palette.primary.main,
    fontSize: 16,
    marginTop: 4,
    marginBottom: 4,
    fontWeight: "600",
    "&:hover": {
      background: "#ecf4ff",
      borderRadius: "4px",
    },
    "&:focus": {
      background: "#ecf4ff",
      borderRadius: "4px",
    },
  },
  listItemAvatar: {
    minWidth: theme.spacing(4),
    paddingTop: 2,
  },
  listItemText: {
    fontSize: 16,
    fontWeight: "600 !important",
  },
}))

const showTitleOverLength = 21

const KnowledgeCategory = ({ category, variant, onEdit, onDelete }) => {
  const theme = useTheme()
  const classes = useStyles()
  const history = useHistory()
  const {
    id,
    name,
    icon,
    description,
    groups,
    locations,
    items: { count, lastUpdatedAt, lastUpdatedAuthor },
  } = category
  const xs = useMediaQuery(theme.breakpoints.down("xs"))

  const handleDelete = (event) => {
    onDelete && onDelete(event)
    history.push("/knowledge")
  }

  if (variant === "submenu") {
    const text = `${name} ${(Boolean(count) && `(${count || 0})`) || ""}`

    return (
      <ListItem component={NavLink} to={`/knowledge/category/${id}`} classes={{ root: classes.listItem }}>
        <FlexBox alignItems="flex-start">
          <ListItemAvatar classes={{ root: classes.listItemAvatar }}>
            <Icon>{icon}</Icon>
          </ListItemAvatar>
          <ListItemText title={text?.length > showTitleOverLength ? text : ""}>
            <Typography className={classes.listItemText}>{text}</Typography>
          </ListItemText>
        </FlexBox>
      </ListItem>
    )
  }
  if (variant === "title") {
    return (
      <>
        <RowBox mb={3}>
          <MuiAvatar aria-label={name} className={classes.avatar}>
            <Icon>{icon}</Icon>
          </MuiAvatar>
          <Typography variant="h3" className={classes.title}>
            {name}
          </Typography>
          <Box ml="auto">
            <AuthorTools
              item={category}
              author={category.author.id}
              admins={{ edit: "category_update_all", delete: "category_update_all" }}
              onEdit={onEdit}
              onDelete={category.items.count ? null : handleDelete}
              subject="category"
            />
          </Box>
        </RowBox>
        <Box mb={xs ? 2 : 4}>
          <Breadcrumbs>
            <NavLink to="/knowledge">
              <Trans>Knowledge Base</Trans>
            </NavLink>
            <Box>{name}</Box>
          </Breadcrumbs>
        </Box>
      </>
    )
  }

  const hasGroupsOrLocations = Boolean(groups?.length) || Boolean(locations?.length)

  return (
    <Paper elevation={3} className={classes.paper}>
      <Box component={NavLink} to={`/knowledge/category/${id}`} className={classes.root}>
        <Box p={2} display="flex" flexDirection="row" flexGrow={1}>
          <Box>
            <RowBox alignItems="flex-start">
              <MuiAvatar aria-label={name} className={classes.avatar}>
                <Icon>{icon}</Icon>
              </MuiAvatar>
              <Typography variant="h4" className={classes.title}>
                {name}
              </Typography>
            </RowBox>
            <Box mt={2} className={classes.description}>
              {description}
            </Box>
          </Box>
          <Box ml="auto">
            <AuthorTools
              item={category}
              author={category.author.id}
              admins={{ edit: "category_update_all", delete: "category_update_all" }}
              onEdit={onEdit}
              onDelete={onDelete}
              subject="category"
            />
          </Box>
        </Box>
        <Divider />
        <RowBox className={classes.footer}>
          <RowBox flexGrow={1}>
            {hasGroupsOrLocations && (
              <RowBox mr={1}>
                <AdornmentIcon
                  name="restricted"
                  tooltip={<Trans>Restricted to locations and/or areas of work</Trans>}
                />
              </RowBox>
            )}
            <Box>
              <Plural value={count} _0="No articles" one="# article" other="# articles" />
            </Box>
          </RowBox>
          {lastUpdatedAt && (
            <RowBox className={classes.lastUpdated}>
              <Box mr={1}>
                <Trans>Last updated:</Trans> {lastUpdatedAt ? <TimeAgo date={lastUpdatedAt} lowercase /> : "never"}
              </Box>
              <Avatar {...lastUpdatedAuthor} />
            </RowBox>
          )}
        </RowBox>
      </Box>
    </Paper>
  )
}

export { KnowledgeCategory }
