import React from "react"
import moment from "moment"
import { Hidden } from "@material-ui/core"

const defaultFormats = {
  withYear: "Do MMMM YYYY",
  withoutYear: "dddd Do MMMM",
}

const alternateFormats = {
  withYear: "dddd, Do MMMM YYYY",
  withoutYear: "dddd, Do MMMM",
}

export default ({ value, formats = "default" }) => {
  try {
    const finalFormats = formats === "alternate" ? alternateFormats : defaultFormats

    const momentValue = value && moment(value)
    const year = moment().year()

    return (
      <span title={momentValue && moment(momentValue).local().format()}>
        <Hidden xsDown>
          {momentValue &&
            momentValue.format(year !== momentValue.year() ? finalFormats.withYear : finalFormats.withoutYear)}
        </Hidden>
        <Hidden smUp>
          {momentValue && momentValue.format(`ddd Do MMM${year !== momentValue.year() ? " YYYY" : ""}`)}
        </Hidden>
      </span>
    )
  } catch (ex) {
    return <span>Data invalid.</span>
  }
}
