import React, { useEffect, useMemo } from "react"
import { Divider, makeStyles, MenuItem } from "@material-ui/core"
import { useQueryTrainingModules } from "../../data"
import { toId } from "../../utils"
import { OutlinedSelect } from "./OutlinedSelect"

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 125,
  },
}))

const TrainingModulesOutlinedSelect = ({
  onDataLoaded,
  allLabel = "All training modules",
  hasAll = true,
  disabled: disabledInput = false,
  ...rest
}) => {
  const { data, loading } = useQueryTrainingModules()
  const classes = useStyles()

  useEffect(() => {
    if (onDataLoaded && data) onDataLoaded(data)
  }, [data, onDataLoaded])

  const items = useMemo(() => data?.trainingModules || [], [data])

  const disabled = disabledInput || loading || !data?.trainingModules.length

  return (
    <OutlinedSelect native={false} value="all" classes={classes} disabled={disabled || loading} {...rest}>
      {hasAll && <MenuItem value="all">{allLabel}</MenuItem>}
      {hasAll && <Divider />}
      {items.map((item) => (
        <MenuItem key={toId(item)} value={toId(item)}>
          {item.name}
        </MenuItem>
      ))}
    </OutlinedSelect>
  )
}

export { TrainingModulesOutlinedSelect }
