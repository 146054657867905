import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Box, Divider } from "@material-ui/core"
import { RowBox } from "../Boxes"

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    borderRadius: 8,
    backgroundColor: ({ color }) => theme.palette.cards[color].backgroundColor,
    border: ({ color }) => (color === "completed" ? `2px solid ${theme.palette.cards.completed.borderColor}` : null),
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  body: {
    // paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: ({ hasAction }) => (hasAction ? 0 : theme.spacing(2)),
  },
  divider: {
    backgroundColor: ({ color }) => theme.palette.cards[color].divider,
  },
  action: {
    padding: theme.spacing(2),
    fontWeight: 600,
  },
}))

const ActionBlockquote = ({ icon, title, body, action, pb = 1, completed, cy = "Blockquote" }) => {
  const classes = useStyles({ color: completed ? "completed" : "primary", hasAction: !!action })

  return (
    <blockquote className={classes.root} data-cy={cy}>
      {title && (
        <RowBox className={classes.title} mb={pb}>
          {icon && <>{icon}</>}
          <Box ml={icon ? 1 : 0}>{title}</Box>
        </RowBox>
      )}
      {body && <div className={classes.body}>{body}</div>}
      {action && (
        <>
          <Divider className={classes.divider} />
          <div className={classes.action}>{action}</div>
        </>
      )}
    </blockquote>
  )
}

export { ActionBlockquote }
