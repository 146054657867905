import React from "react"
import { Alert, AlertTitle } from "@material-ui/lab"
import { Trans } from "@lingui/macro"
import { Icon } from ".."

const TrainingArticleHeader = ({ userTraining, module, moduleKnowledge }) => {
  if (!userTraining || !module || !moduleKnowledge) {
    return null
  }

  const { name } = userTraining
  const completed = Boolean(moduleKnowledge.completedAt)

  return (
    <>
      <Alert icon={<Icon name="training" />} severity={completed ? "success" : "info"}>
        <AlertTitle>{`${name} - ${module.name}`}</AlertTitle>
        <>
          {!completed && (
            <span className="notranslate">
              <Trans>
                Please review the following content as part of your training. Once you understand all the content
                contained, use the confirmation button at the end of this page to progress with your training.
              </Trans>
            </span>
          )}
          {completed && (
            <span className="notranslate">
              <Trans>
                You have already indicated you have read and understood this training content. You can return and refer
                to this content at any time through the Knowledge Base.
              </Trans>
            </span>
          )}
        </>
      </Alert>
    </>
  )
}

export { TrainingArticleHeader }
