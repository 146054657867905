import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Box, Button, MenuItem, Popover, Grid } from "@material-ui/core"
import { FilterList as FilterListIcon } from "@material-ui/icons"
import PopupState, { anchorRef, bindTrigger, bindMenu } from "material-ui-popup-state"
import { Trans } from "@lingui/macro"
import { OutlinedSelect } from ".."
import { useQueryGroups, useQueryLocations, useQueryTrainingCourses, USER_ACCREDITATIONS_STATUS } from "../../data"
import { useAuth } from "../../services"

const useFiltersPopoverStyles = makeStyles((theme) => ({
  root: {
    width: 512,
    marginTop: theme.spacing(1),
  },
  paper: {
    width: "100%",
    maxWidth: "calc(100vw - 32px)",
  },
}))

const TrainingFiltersButton = ({
  options: { hasCourses, hasTrainingStatus, hasAccreditationStatus },
  text = <Trans>Filters</Trans>,
  filters,
  onFilterChange,
}) => {
  const filtersPopoverClasses = useFiltersPopoverStyles()
  const { data: groupsData } = useQueryGroups()
  const { data: locationsData } = useQueryLocations()
  const { data: coursesData } = useQueryTrainingCourses()
  const { hasFeature } = useAuth()

  const handleFilterChange = (type, event) => {
    onFilterChange && onFilterChange(type, event)
  }

  const hasTrainingFeature = hasFeature("training")
  const hasAccreditationFeature = hasFeature("accreditation")

  return (
    <PopupState variant="popover" popupId="bulk-actions">
      {(popupState) => (
        <>
          <Button variant="contained" color="primary" {...bindTrigger(popupState)} ref={anchorRef(popupState)}>
            <FilterListIcon />
            {Boolean(text) && <Box ml={1}>{text}</Box>}
          </Button>
          <Popover
            {...bindMenu(popupState)}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            classes={filtersPopoverClasses}
          >
            <Box p={2}>
              <Grid container direction="row" spacing={2}>
                {hasCourses && (
                  <Grid item xs={12}>
                    <OutlinedSelect
                      label="Courses"
                      native={false}
                      value={filters
                        .filter((filter) => filter.type === "trainingCourses")
                        .map((filter) => filter.value)}
                      onChange={(event) => handleFilterChange("trainingCourses", event)}
                      multiple
                    >
                      {coursesData?.trainingCourses.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </OutlinedSelect>
                  </Grid>
                )}
                <Grid item xs={6}>
                  <OutlinedSelect
                    label="Areas of work"
                    native={false}
                    value={filters.filter((filter) => filter.type === "groups").map((filter) => filter.value)}
                    onChange={(event) => handleFilterChange("groups", event)}
                    multiple
                  >
                    {groupsData &&
                      groupsData.groups.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </OutlinedSelect>
                </Grid>
                <Grid item xs={6}>
                  <OutlinedSelect
                    label="Locations"
                    native={false}
                    value={filters.filter((filter) => filter.type === "locations").map((filter) => filter.value)}
                    onChange={(event) => handleFilterChange("locations", event)}
                    multiple
                  >
                    {locationsData &&
                      locationsData.locations.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </OutlinedSelect>
                </Grid>
                {hasTrainingFeature && hasTrainingStatus && (
                  <Grid item xs={6}>
                    <OutlinedSelect
                      label="Training status"
                      native={false}
                      value={filters.filter((filter) => filter.type === "trainingStatus").map((filter) => filter.value)}
                      onChange={(event) => handleFilterChange("trainingStatus", event)}
                      multiple
                    >
                      <MenuItem value="none">No training assigned</MenuItem>
                      <MenuItem value="in_progress">Training in progress</MenuItem>
                      <MenuItem value="overdue">Training overdue</MenuItem>
                      <MenuItem value="complete">Training complete</MenuItem>
                    </OutlinedSelect>
                  </Grid>
                )}
                {hasAccreditationFeature && hasAccreditationStatus && (
                  <Grid item xs={6}>
                    <OutlinedSelect
                      label="Accreditations status"
                      native={false}
                      value={filters
                        .filter((filter) => filter.type === "accreditationStatus")
                        .map((filter) => filter.value)}
                      onChange={(event) => handleFilterChange("accreditationStatus", event)}
                      multiple
                    >
                      <MenuItem value={USER_ACCREDITATIONS_STATUS.NONE}>No accreditations assigned</MenuItem>
                      <MenuItem value={USER_ACCREDITATIONS_STATUS.AWAITING}>Awaiting user upload</MenuItem>
                      <MenuItem value={USER_ACCREDITATIONS_STATUS.WARNING}>Expiring soon</MenuItem>
                      <MenuItem value={USER_ACCREDITATIONS_STATUS.EXPIRED}>Expired</MenuItem>
                      <MenuItem value={USER_ACCREDITATIONS_STATUS.COMPLIANT}>Up to date</MenuItem>
                    </OutlinedSelect>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Popover>
        </>
      )}
    </PopupState>
  )
}

export { TrainingFiltersButton }
