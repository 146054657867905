import { gql } from "@apollo/client"
import { TAG_FIELDS } from "../../tags/useQueryTags"

const ASSET_FIELDS = gql`
  fragment AssetFields on Asset {
    id
    name
    locations {
      id
      name
    }
    groups {
      id
      name
    }
    tags {
      ...TagFields
    }
    notes {
      id
      text
      author {
        id
        fullName
        firstName
        lastName
        avatar {
          key
        }
      }
      uploads {
        id
        key
        fileName
        fileGroup
        fileSize
      }
      createdAt
    }
  }
  ${TAG_FIELDS}
`

export { ASSET_FIELDS }
