import { useQuery, useLazyQuery, gql } from "@apollo/client"
import { ADMINABLE_GROUPS_FIELDS } from "./fragments/adminableGroupFieldsFragment"

const ADMINABLE_GROUP_QUERY = gql`
  query AdminableGroup($id: ID!) {
    group(id: $id) {
      ...AdminableGroupFields
    }
  }
  ${ADMINABLE_GROUPS_FIELDS}
`

const useQueryAdminableGroup = (options) => useQuery(ADMINABLE_GROUP_QUERY, options)

const useLazyQueryAdminableGroup = (options) => useLazyQuery(ADMINABLE_GROUP_QUERY, options)

export { useQueryAdminableGroup, useLazyQueryAdminableGroup }
