import { useQuery, useLazyQuery, gql } from "@apollo/client"
import { REPORT_JOB_DETAIL_STEPS_FIELDS } from "./fragments/reportJobDetailStepsFieldsFragment"

const REPORTJOBDETAIL_FIELDS = gql`
  fragment ReportJobDetailFields on ReportJobDetail {
    process {
      name
      steps {
        ... on CommonProcessStep {
          id
          name
          responseType
        }
        ... on NumberProcessStep {
          id
          name
          responseType
        }
        ... on MultipleChoiceProcessStep {
          id
          name
          responseType
        }
        ... on ProcessProcessStep {
          id
          name
          responseType
          process {
            id
            name
            steps {
              ... on CommonProcessStep {
                id
                name
                responseType
              }
              ... on NumberProcessStep {
                id
                name
                responseType
              }
              ... on MultipleChoiceProcessStep {
                id
                name
                responseType
              }
            }
          }
        }
      }
    }
    jobs {
      id
      title
      availableFrom
      dueAt
      scheduleName
      repeat {
        type
      }
      steps {
        ...ReportJobDetailStepsFields
        job {
          id
          process
          title
          totalSteps
          completedSteps
          steps {
            ...ReportJobDetailStepsFields
          }
        }
      }
    }
  }
  ${REPORT_JOB_DETAIL_STEPS_FIELDS}
`

const REPORTJOBDETAIL_QUERY = gql`
  query ReportJobDetail($process: ID!, $location: ID!, $gt: ScheduleDateInput!, $lt: ScheduleDateInput) {
    reportJobDetail(process: $process, location: $location, gt: $gt, lt: $lt) {
      ...ReportJobDetailFields
    }
  }
  ${REPORTJOBDETAIL_FIELDS}
`

const useQueryReportJobDetail = (options) =>
  useQuery(REPORTJOBDETAIL_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

const useLazyQueryReportJobDetail = (options) =>
  useLazyQuery(REPORTJOBDETAIL_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

export { useQueryReportJobDetail, useLazyQueryReportJobDetail }
