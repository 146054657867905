import React from "react"
import Config from "react-global-configuration"
import { encode } from "js-base64"
import * as Sentry from "@sentry/browser"

// https://sharp.pixelplumbing.com/api-resize
// fit: cover|contain|fill|inside|outside

const getImageSource = (
  baseUri,
  bucket,
  { key },
  resizeFit,
  resizeWidth = null,
  resizeHeight = null,
  enlarge = true,
  edits = {}
) => {
  const rq = {
    key,
    bucket,
    edits: {
      resize: {
        fit: resizeFit,
        width: resizeWidth,
        height: resizeHeight,
        withoutEnlargement: !enlarge,
      },
      ...edits,
    },
  }

  try {
    return `${baseUri}/${btoa(JSON.stringify(rq))}`
  } catch (error) {
    Sentry.captureException(error)
  }

  try {
    return `${baseUri}/${encode(JSON.stringify(rq))}`
  } catch (error) {
    Sentry.captureException(error)
    return ""
  }
}

const getImageSourceWithEdits = ({ baseUri, bucket, key, edits = {} }) => {
  const rq = {
    key,
    bucket,
    edits,
  }

  try {
    return `${baseUri}/${btoa(JSON.stringify(rq))}`
  } catch (error) {
    Sentry.captureException(error)
  }

  try {
    return `${baseUri}/${encode(JSON.stringify(rq))}`
  } catch (error) {
    Sentry.captureException(error)
    return ""
  }
}

const DisplayImage = ({ upload, fit = "cover", onLoad, width, height, enlarge = true, alt, ...rest }) => {
  const { imageViewerBaseUri, imageViewerBucketName } = Config.get("uploader")

  const handleLoad = (event) => {
    onLoad && onLoad(event)
  }

  const src = getImageSource(imageViewerBaseUri, imageViewerBucketName, upload, fit, width, height, enlarge)

  return <img src={src} alt={alt} onLoad={handleLoad} {...rest} />
}

export { DisplayImage, getImageSource, getImageSourceWithEdits }
