import React, { useEffect, useRef, useState } from "react"
import { FormControl, InputLabel, Select } from "@material-ui/core"

const OutlinedSelect = ({
  id,
  value,
  onChange,
  multiple = false,
  menuPortal = true,
  label,
  children,
  selectProps,
  selectInputProps,
  native = true,
  fullWidth = true,
  ...rest
}) => {
  const priorityLabel = useRef(null)
  const [priorityLabelWidth, setPriorityLabelWidth] = useState(0)
  useEffect(() => {
    setPriorityLabelWidth(priorityLabel.current.offsetWidth)
  }, [])

  return (
    <FormControl variant="outlined" fullWidth={fullWidth} {...rest} id={id ? `${id}-wrapper` : null}>
      <InputLabel ref={priorityLabel} htmlFor={id}>
        {label}
      </InputLabel>
      <Select
        id={id ? `${id}-select` : null}
        required
        native={native}
        value={value}
        onChange={onChange}
        labelWidth={priorityLabelWidth}
        inputProps={{
          name: id,
          id,
          ...selectInputProps,
        }}
        MenuProps={{
          disablePortal: !menuPortal,
        }}
        multiple={multiple}
        {...selectProps}
      >
        {children}
      </Select>
    </FormControl>
  )
}

export { OutlinedSelect }
