import { Box, Chip, Hidden } from "@material-ui/core"
import moment from "moment"
import React, { useEffect, useMemo } from "react"
import { NavLink } from "react-router-dom"
import { ActionCard } from ".."
import { useQueryUserTrainings } from "../../data"
import { useAuth } from "../../services"
import { toId, useTraining } from "../../utils"
import { ColumnBox, RowBox } from "../Boxes"
import { Icon } from "../Icon"
import { LinearProgress } from "../LinearProgress"
import { ShowMoreResults } from "../ShowMoreResults/ShowMoreResults"

const TrainingDashboardModule = ({ classes, cy = "TrainingDashboardModule", onLoaded }) => {
  const { getActiveModuleIndex } = useTraining()
  const {
    principal: { userID: user },
    hasFeature,
  } = useAuth()
  const linear = !hasFeature("training_nonlinear")
  const { data } = useQueryUserTrainings({
    variables: {
      user,
    },
  })

  const uncompletedItems = useMemo(
    () => data?.userTrainings.list.filter((userTraining) => !userTraining.status.completedAt),
    [data]
  )

  useEffect(() => {
    if (uncompletedItems) {
      onLoaded && onLoaded(Boolean(uncompletedItems.length))
    }
  }, [onLoaded, uncompletedItems])

  if (!uncompletedItems?.length) {
    return null
  }

  const renderedItems = uncompletedItems.map((userTraining, index) => {
    const { id, name, dueAt, status } = userTraining
    const overdue = dueAt ? moment(dueAt).isBefore(moment()) : false
    const activeModuleIndex = getActiveModuleIndex(userTraining)
    const activeModule = activeModuleIndex > -1 ? status.modules[activeModuleIndex] : null

    return (
      <ActionCard
        cy={cy}
        key={id}
        cardProps={{
          component: NavLink,
          to: {
            pathname: linear ? `/knowledge/training/${toId(uncompletedItems[0])}` : `/knowledge/training/${toId(id)}`,
            "data-cy": `${cy}-actioncard`,
          },
        }}
        overdue={overdue}
        isScheduled={index > 0 && linear}
        title={
          <RowBox className={classes.title} alignItems="flex-start">
            <Icon name="training" />
            <ColumnBox ml={1}>
              <Box mb={0.5}>{name}</Box>
              <Box className={classes.caption}>
                {activeModuleIndex + 1} of {status.modules.length}: {activeModule ? activeModule.name : "Complete"}
              </Box>
            </ColumnBox>
          </RowBox>
        }
        rightChildren={
          <Box
            mr="auto"
            display="flex"
            flexDirection="row"
            alignSelf="center"
            alignItems="center"
            justifyItems="center"
            className={classes.rightChildren}
          >
            <Hidden xsDown>
              {index === 0 && linear && (
                <Box className={classes.progress} mr={1}>
                  <LinearProgress value={status.percentComplete} showValue overdue={overdue} />
                </Box>
              )}

              {!linear && (
                <Box className={classes.progress} mr={1}>
                  <LinearProgress value={status.percentComplete} showValue overdue={overdue} />
                </Box>
              )}

              {index > 0 && linear && <Chip label="Coming up" size="small" />}
            </Hidden>
          </Box>
        }
      >
        <Hidden smUp>
          {index === 0 && linear && <LinearProgress value={status.percentComplete} showValue overdue={overdue} />}

          {!linear && <LinearProgress value={status.percentComplete} showValue overdue={overdue} />}

          {index > 0 && linear && <Chip label="Coming up" size="small" />}
        </Hidden>
      </ActionCard>
    )
  })

  return <ShowMoreResults items={renderedItems} subject="course" />
}

export { TrainingDashboardModule }
