import { useMutation, gql } from "@apollo/client"

import { PREP_LIST_FIELDS } from "./fragments/prepListFieldsFragment"

const UPDATE_PREP_LIST_MUTATION = gql`
  mutation UpdatePrepList($id: ID!, $input: PrepListMutationInput!) {
    prepList(id: $id) {
      update(input: $input) {
        ...PrepListFields
      }
    }
  }
  ${PREP_LIST_FIELDS}
`

const useMutationUpdatePrepList = () => {
  return useMutation(UPDATE_PREP_LIST_MUTATION)
}

export { useMutationUpdatePrepList }
