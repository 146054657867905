import { useMutation, gql } from "@apollo/client"
import { PREP_LIST_FIELDS } from "./fragments/prepListFieldsFragment"

const SYNC_PREP_LIST_ITEMS_MUTATION = gql`
  mutation SyncPrepListItems($id: ID!, $input: [PrepListItemMutationInput!]) {
    prepList(id: $id) {
      syncPrepListItems(input: $input) {
        ...PrepListFields
      }
    }
  }
  ${PREP_LIST_FIELDS}
`

const useMutationSyncPrepListItems = () => {
  return useMutation(SYNC_PREP_LIST_ITEMS_MUTATION, {
    fetchPolicy: "no-cache",
  })
}

export { useMutationSyncPrepListItems }
