import { useQuery, gql } from "@apollo/client"

const ACTIONCOUNTS_FIELDS = gql`
  fragment ActionCountsFields on ActionCounts {
    unresolved
  }
`

const ACTIONCOUNTS_QUERY = gql`
  query ActionCounts {
    actionCounts {
      ...ActionCountsFields
    }
  }
  ${ACTIONCOUNTS_FIELDS}
`

const ACTIONCOUNTS_UPDATED_SUBSCRIPTION = gql`
  subscription actionCountsUpdated {
    actionCountsUpdated {
      ...ActionCountsFields
    }
  }
  ${ACTIONCOUNTS_FIELDS}
`

const actionCountsUpdatedSubscriptionUpdateQuery = (prev, { subscriptionData }) => {
  if (!subscriptionData.data) return prev
  const { unresolved } = subscriptionData.data.actionCountsUpdated

  return {
    ...prev,
    actionCounts: {
      ...prev.actionCounts,
      unresolved,
    },
  }
}

const subscribe = (result) => () =>
  [
    result.subscribeToMore({
      document: ACTIONCOUNTS_UPDATED_SUBSCRIPTION,
      updateQuery: actionCountsUpdatedSubscriptionUpdateQuery,
      onError: (err) => console.log("[useQueryActionCounts][ACTIONCOUNTS_UPDATED_SUBSCRIPTION]", err),
    }),
  ]

const useQueryActionCounts = () => {
  const result = useQuery(ACTIONCOUNTS_QUERY)

  return {
    ...result,
    subscribe: subscribe(result),
  }
}

export { useQueryActionCounts }
