import React, { forwardRef } from "react"
import { Tooltip } from "@material-ui/core"
import { Icon } from "."

const IconComponent = forwardRef(function IconComponent({ refClassName = "", ...props }, ref) {
  return (
    <div className={refClassName} ref={ref}>
      <Icon {...props} />
    </div>
  )
})

const TooltipIcon = ({ tooltip, ...rest }) => (
  <Tooltip title={tooltip}>
    <IconComponent {...rest} />
  </Tooltip>
)

export { TooltipIcon }
