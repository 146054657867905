import Base from "./Base"

export default {
  httpBaseUri: "https://api.operandio.com",
  httpServiceBaseUri: "https://api.operandio.com/graphql",
  wsServiceBaseUri: "wss://api.operandio.com/graphql",

  clientKiosk: false,

  showConfigInTitle: false,
  configTitle: "[LIVE]",

  theme: {
    ...Base.theme,

    logoContainer: "images-dev",
  },

  uploader: {
    ...Base.uploader,

    imageViewerBaseUri: "https://dydc66wtc7q9n.cloudfront.net",
    imageViewerBucketName: "operandio-content-ap-southeast-2",
  },

  stripe: {
    publishableKey:
      "pk_live_51GxNIsCmvyAu99J00nBfNgcypgin24GX4q97w3Dxa0Whoq5OsOtZSsGzpZaVbTwh1PGZ71WcrKGma5YJQ2BktMtn00bimwwkVm",
  },

  analytics: {
    ...Base.analytics,

    mixpanel: {
      enabled: true,
      token: "a0740ae1cb6ae5ddf2eaf445b5ceaeae",
      settings: { debug: false, track_pageview: true, persistence: "localStorage" },
    },
  },

  errors: {
    ...Base.errors,

    showDetail: false,
    showGraphQLErrorNotification: true,
  },
}
