import { useQuery, gql } from "@apollo/client"

const TRAINING_COURSES_COUNT_QUERY = gql`
  query TrainingCoursesCount {
    trainingCoursesCount
  }
`

const useQueryTrainingCoursesCount = () => useQuery(TRAINING_COURSES_COUNT_QUERY)

export { useQueryTrainingCoursesCount }
