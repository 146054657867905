import { useQuery, gql } from "@apollo/client"

const ADMINABLE_USER_COUNTS_FIELDS = `
  active
  invited
  inactive
`

const ADMINABLE_USER_COUNTS_QUERY = gql`query AdminableUserCounts {
  adminableUserCounts {
    ${ADMINABLE_USER_COUNTS_FIELDS}
  } 
}`

const useQueryAdminableUserCounts = () => useQuery(ADMINABLE_USER_COUNTS_QUERY)

export { useQueryAdminableUserCounts }
