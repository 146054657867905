import React from "react"
import { Box, Chip, makeStyles } from "@material-ui/core"
import Clock from "react-live-clock"
import moment from "moment"
import { ActionCard } from "."
import { AuthorTools } from "../Author"
import { RowBox } from "../Boxes"
import { Avatar, AdornmentIcon } from ".."

const useStyles = makeStyles((theme) => ({
  footer: {
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
    justifyContent: "space-between",
  },
  region: {
    marginRight: "auto",
    marginLeft: "10px",

    [theme.breakpoints.down("xs")]: {
      marginTop: "5px",
      marginLeft: "0px",
    },
  },
  clock: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "5px",
    },
  },
}))

const LocationActionCard = ({ location, organisation, variant = "normal", onEdit, onDelete, ...rest }) => {
  const { id, name, timeZone, region, inactive, logo } = location

  const classes = useStyles()

  const handleEdit = () => {
    if (onEdit) onEdit(location)
  }
  const handleDelete = () => {
    if (onDelete) onDelete(location)
  }

  // admins={{ edit: "location_update", delete: "location_delete" }}

  return (
    <ActionCard
      key={id}
      title={name}
      cursor={null}
      stub={
        logo && (
          <RowBox ml={-1} mr={1}>
            <Avatar avatar={logo} fullName={name} />
          </RowBox>
        )
      }
      detail={
        variant === "compact" && (
          <>
            {timeZone.replace("_", " ")} {moment.tz(timeZone).format("Z")}
          </>
        )
      }
      rightChildren={
        <RowBox>
          {inactive && <Chip label="Inactive" />}

          <AuthorTools
            item={location}
            admins={{ edit: "location_update" }}
            onEdit={handleEdit}
            subject="location"
            onDelete={handleDelete}
          />
        </RowBox>
      }
      footer={
        variant === "normal" && (
          <Box className={classes.footer}>
            <RowBox>
              <AdornmentIcon name="timezone" tooltip="Time zone" />
              <Box ml={1}>{timeZone.replace("_", " ")}</Box>
            </RowBox>
            {region && (
              <RowBox className={classes.region}>
                <AdornmentIcon name="region" tooltip="Region" />
                <Box ml={1}>{region.name}</Box>
              </RowBox>
            )}

            <RowBox className={classes.clock}>
              <AdornmentIcon name="clock" tooltip="Clock" />
              <Box ml={1}>
                <Clock timezone={timeZone} ticking format="Do MMM YYYY h:mma z" />
              </Box>
            </RowBox>
          </Box>
        )
      }
      {...rest}
    />
  )
}

export { LocationActionCard }
