import { gql } from "@apollo/client"

const PERMISSION_FIELDS = gql`
  fragment PermissionFields on Permission {
    id
    key
    description
  }
`

const PERMISSION_GROUP_FIELDS = gql`
  fragment PermissionGroupFields on PermissionGroup {
    id
    key
    permissions {
      ...PermissionFields
    }
  }
  ${PERMISSION_FIELDS}
`

export { PERMISSION_FIELDS, PERMISSION_GROUP_FIELDS }
