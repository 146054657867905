import { gql, useLazyQuery, useQuery } from "@apollo/client"
import { USER_TRAINING_FIELDS } from "./useQueryUserTrainings"

const USER_TRAINING_BY_COURSE_QUERY = gql`
  query UserTrainingByCourse($user: ID!, $course: ID!) {
    userTrainingByCourse(user: $user, course: $course) {
      ...UserTrainingFields
    }
  }
  ${USER_TRAINING_FIELDS}
`

const useQueryUserTrainingsCourse = (options) => useQuery(USER_TRAINING_BY_COURSE_QUERY, options)

const useLazyQueryUserTrainingsCourse = (options) => useLazyQuery(USER_TRAINING_BY_COURSE_QUERY, options)

export { useQueryUserTrainingsCourse, useLazyQueryUserTrainingsCourse }
