import { useEffect, useState } from "react"

function makeSubscribeToMore({ document, ...rest }) {
  return {
    document,
    onError: (error) => {
      console.log("[subscribeToMore]", document, error)
    },
    ...rest,
  }
}

const useSubscribeEffect = (result) => {
  const { subscribe, called } = result
  const [unsubscribes, setUnsubscribes] = useState(null)

  useEffect(() => {
    if (!called || unsubscribes) {
      return
    }
    setUnsubscribes(subscribe())
    return () => {
      if (unsubscribes) {
        try {
          unsubscribes.map((unsub) => unsub())
        } catch (err) {
          // Ignore errors
        }
      }
    }
  }, [called, subscribe, unsubscribes])

  return unsubscribes
}

export { makeSubscribeToMore, useSubscribeEffect }
