import React from "react"
import { Box, Collapse } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"

const HasActiveJobsChangedBlockquote = ({ show = false, subject = "process", count, mb = 3 }) => (
  <Collapse in={show}>
    <Box mb={mb}>
      <Alert severity="warning">
        <AlertTitle>{`Active job${count > 1 ? "s" : ""}`}</AlertTitle>
        {count > 1
          ? `This ${subject} has ${count} active jobs running today. Changes made will only take effect from the next scheduled instance. If you need to, you can edit today's active jobs from the Active Jobs area.`
          : `This ${subject} has an active job running today. Changes made will only take effect from the next scheduled instance. If you need to, you can edit today's active job from the Active Jobs area.`}
      </Alert>
    </Box>
  </Collapse>
)

export { HasActiveJobsChangedBlockquote }
