import { useQuery, gql } from "@apollo/client"

const TIMESHEET_GROUP_FIELDS = `
  id
  name
`

const TIMESHEET_GROUPS_QUERY = gql`
  query TimesheetGroups($location: ID!) {
    timesheetGroups(location: $location) {
      ${TIMESHEET_GROUP_FIELDS}
    }
  }
`

const useQueryTimesheetGroups = (options) => useQuery(TIMESHEET_GROUPS_QUERY, options)

export { useQueryTimesheetGroups }
