import { useMutation, gql } from "@apollo/client"
import { ADMINABLE_USERS_FIELDS } from "./fragments/adminableUserFieldsFragment"

const CREATE_EXTERNAL_USER_MUTATION = gql`
  mutation CreateExternalUser($input: AdminableExternalUserCreateInput!) {
    createExternalUser(input: $input) {
      ...AdminableUsersFields
    }
  }
  ${ADMINABLE_USERS_FIELDS}
`

const useMutationCreateExternalUser = () => useMutation(CREATE_EXTERNAL_USER_MUTATION)

export { useMutationCreateExternalUser }
