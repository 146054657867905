import React from "react"
import { Box, makeStyles, Typography } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 24,
    fontWeight: "600",
    lineHeight: "30px",
    marginBottom: 11,
    color: theme.palette.text.primary,
  },
  content: {
    fontSize: 16,
    lineHeight: "24px",
    marginBottom: 40,
  },
}))

const Forbidden = ({ subject }) => {
  const classes = useStyles()

  return (
    <Box mb={3}>
      <Typography variant="h3" className={classes.title}>
        Permission Denied
      </Typography>
      Access to this {subject || "resource"} has been restricted. Please contact your administrator for more
      information.
    </Box>
  )
}

export default Forbidden
