import { i18n } from "@lingui/core"

// Make sure to update lingui.config.js with locales you want to support as well
export const locales = [
  { localeFull: "en-US", locale: "en", name: "English (US)", icon: "locale-en-us" },
  { localeFull: "es-ES", locale: "es", name: "Español (Spanish)", icon: "locale-es-es" },
  { localeFull: "ja-JP", locale: "ja", name: "日本語 (Japanese)", icon: "locale-ja-jp" },
  { localeFull: "ko-KR", locale: "ko", name: "한국어 (Korean)", icon: "locale-ko-kr" },
]

export const defaultLocale = "en"

export async function dynamicActivate(locale) {
  let load = locale

  // Check that the locale exists in the locales array, if not fallback to EN
  if (!locales.map((item) => item.locale).includes(load)) {
    load = defaultLocale
  }

  // Need this value parsed so it can be added to the file path to retrieve translations
  if (typeof locale === "string" && locale.startsWith('"') && locale.endsWith('"')) {
    load = JSON.parse(locale)
  }

  const { messages } = await import(`./locales/${load}/messages`)
  i18n.load(load, messages)
  i18n.activate(load)
}
