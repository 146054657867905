import { Box, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
  root: {
    height: ({ height }) => height,
  },
  delay: {
    animationName: "fadeInOpacityLight",
    animationIterationCount: 1,
    animationTimingFunction: "ease-in",
    animationDuration: "1s",
  },
}))

const CenteredWrapper = ({ children, delay = false, height = "50vh" }) => {
  const classes = useStyles({ height })

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" className={classes.root}>
      <Box display="flex" justifySelf="flex-start" alignSelf="center" className={delay ? classes.delay : undefined}>
        {children}
      </Box>
    </Box>
  )
}

export { CenteredWrapper }
