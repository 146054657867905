import { useMutation, gql } from "@apollo/client"
import { DEVICE_FIELDS } from "./useQueryDevices"

const CREATE_DEVICE_MUTATION = gql`
  mutation CreateDevice(
      $label: String!
      $location: ID!
      $groups: [ID!]
      $identityProvider: ID      
      $passcode: String!
      $managed: Boolean
      $timer: Int
    ) {
    createDevice(
      label: $label
      location: $location
      groups: $groups      
      identityProvider: $identityProvider
      passcode: $passcode
      managed: $managed
      timer: $timer
    ) {
      ${DEVICE_FIELDS}
    } 
  }
`

const useMutationCreateDevice = () =>
  useMutation(CREATE_DEVICE_MUTATION, {
    errorPolicy: "all",
  })

export { useMutationCreateDevice }
