import React from "react"
import MuiCircularProgress from "@material-ui/core/CircularProgress"
import { makeStyles } from "@material-ui/styles"
import { Grid, Box } from "@material-ui/core"

const useCircularProgressStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.chart.background,
    borderRadius: ({ size }) => size,
  },
  colorSecondary: {
    color: theme.palette.success.main,
  },
}))

const useStyles = makeStyles((theme) => ({
  progress: {
    flex: 1,
    marginRight: ({ marginRight }) => marginRight,
  },
  value: {
    color: ({ complete }) => (complete ? theme.palette.success.main : theme.palette.primary.main),
    position: "absolute",
    width: "100%",
    textAlign: "center",
    fontSize: ({ size }) => Math.max(Math.floor(size * 0.2), 16),
    fontWeight: 800,
    lineHeight: "16px",
  },
  innerCircle: {
    marginLeft: ({ size }) => Math.floor(size * 0.075),
    position: "absolute",
    width: ({ size }) => Math.floor(size * 0.85),
    height: ({ size }) => Math.floor(size * 0.85),
    borderRadius: ({ size }) => Math.floor(size * 0.85),
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
  },
}))

const CircularProgress = ({ value, showValue, size = 120, marginRight = 10, suffix = "%", ...rest }) => {
  const circularProgressClasses = useCircularProgressStyles({ size })
  const classes = useStyles({ complete: value === 100, marginRight, size })

  return (
    <Grid container direction="row" alignItems="center">
      <MuiCircularProgress
        classes={circularProgressClasses}
        variant="determinate"
        value={value}
        color={value < 100 ? "primary" : "secondary"}
        size={size}
        {...rest}
      />
      <div className={classes.innerCircle}>
        {showValue && (
          <Box color={value < 100 ? "primary" : "secondary"} className={classes.value}>
            {value}
            {suffix}
          </Box>
        )}
      </div>
    </Grid>
  )
}

export { CircularProgress }
