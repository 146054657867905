import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Box } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  info: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    display: "flex",
    alignItems: "center",
    "&>*": {
      marginRight: theme.spacing(1),
    },
  },
}))

const ActionCardInfo = ({ children, ...rest }) => {
  const classes = useStyles()

  return (
    <Box m={0} p={0} className={classes.info} {...rest}>
      {children}
    </Box>
  )
}

export { ActionCardInfo }
