import { useEffect, useState } from "react"
import Config from "react-global-configuration"
import { useAuth } from "../services"
import { useSnackbar } from "../components"
import { DEMO_PROFILE } from "../data"

const useDemonstration = () => {
  const { clientKiosk: kiosk, clientDevice: device } = Config.get()
  const { authed, settings, hasFeature } = useAuth()
  const [profile, setProfile] = useState(null)
  const snackbar = useSnackbar()

  useEffect(() => {
    if (authed && settings?.organisation?.demonstration && (kiosk || device)) {
      const demonstration = settings.organisation.demonstration

      switch (demonstration) {
        case DEMO_PROFILE.LABELS:
          if (!hasFeature("labels")) {
            snackbar.showMessage({
              message: `Demonstration mode ${demonstration} requires labels feature`,
            })
            setProfile(null)
            return
          }

          break
        default:
          snackbar.showMessage({
            message: `Demonstration mode ${demonstration} is unknown`,
          })
          setProfile(null)
          return
      }

      setProfile(demonstration)
    } else {
      setProfile(null)
    }
  }, [authed, device, hasFeature, kiosk, settings, snackbar])

  const isDemo = (profileName) => {
    return profile === profileName
  }

  return {
    profile,
    isDemo,
  }
}

export { useDemonstration }
