import { useMutation, gql } from "@apollo/client"
import serializationKeys from "../serializationKeys"
import { JOB_FIELDS } from "./fragments/jobFieldsFragment"

const UPDATE_JOB_MUTATION = gql`
  mutation UpdateJob($id: ID!, $users: [ID!], $groups: [ID!]) {
    updateJob(id: $id, users: $users, groups: $groups) {
      ...JobFields
    }
  }
  ${JOB_FIELDS}
`

const useMutationUpdateJob = (job) =>
  useMutation(UPDATE_JOB_MUTATION, {
    context: {
      serializationKey: serializationKeys.job(job),
    },
  })

export { useMutationUpdateJob }
