import { gql, useMutation } from "@apollo/client"
import { SUPPLIER_FIELDS } from "./fragments/supplierFieldsFragment"
import { toId } from "../../utils"
import { SUPPLIERS_COUNT_QUERY, SUPPLIERS_QUERY } from "./useQuerySuppliers"

const CREATE_SUPPLIER_MUTATION = gql`
  mutation CreateSupplier($input: SupplierMutationInput!) {
    createSupplier(input: $input) {
      ...SupplierFields
    }
  }
  ${SUPPLIER_FIELDS}
`

const UPDATE_SUPPLIER_MUTATION = gql`
  mutation UpdateSupplier($id: ID!, $input: SupplierMutationInput!) {
    supplier(id: $id) {
      update(input: $input) {
        ...SupplierFields
      }
    }
  }
  ${SUPPLIER_FIELDS}
`

const SUPPLIER_ADD_NOTE_MUTATION = gql`
  mutation SupplierMutationAddNote($id: ID!, $input: SupplierAddNoteInput!) {
    supplier(id: $id) {
      addNote(input: $input) {
        ...SupplierFields
      }
    }
  }
  ${SUPPLIER_FIELDS}
`

const SUPPLIER_DELETE_NOTE_MUTATION = gql`
  mutation SupplierDeleteNote($id: ID!, $note: ID!) {
    supplier(id: $id) {
      note(id: $note) {
        delete
      }
    }
  }
`

const DELETE_SUPPLIER_MUTATION = gql`
  mutation DeleteSupplier($id: ID!) {
    supplier(id: $id) {
      delete
    }
  }
`

const useMutationCreateSupplier = (options) =>
  useMutation(CREATE_SUPPLIER_MUTATION, { ...options, refetchQueries: [SUPPLIERS_QUERY, SUPPLIERS_COUNT_QUERY] })
const useMutationUpdateSupplier = (options) => useMutation(UPDATE_SUPPLIER_MUTATION, options)
const useMutationDeleteSupplier = (options) =>
  useMutation(DELETE_SUPPLIER_MUTATION, {
    ...options,
    refetchQueries: [SUPPLIERS_COUNT_QUERY],
    update(cache, { errors }, { variables: { id } }) {
      if (errors) {
        return
      }
      const normalizedId = cache.identify({ id: toId(id), __typename: "Supplier" })
      cache.evict({ id: normalizedId })
      cache.gc()
    },
  })
const useMutationSupplierAddNote = (options) => useMutation(SUPPLIER_ADD_NOTE_MUTATION, options)
const useMutationSupplierDeleteNote = (options) => useMutation(SUPPLIER_DELETE_NOTE_MUTATION, options)

export {
  useMutationCreateSupplier,
  useMutationUpdateSupplier,
  useMutationDeleteSupplier,
  useMutationSupplierAddNote,
  useMutationSupplierDeleteNote,
}
