import React, { useState } from "react"
import { Box, Button, makeStyles } from "@material-ui/core"
import pluralize from "pluralize"

const useStyles = makeStyles(() => ({
  link: {
    fontSize: 14,
    lineHeight: "18px",
    fontWeight: "600",
  },
}))

export const ShowMoreResults = ({ items, minShown = 3, subject = "item" }) => {
  const [showMoreNotifications, setShowMoreNotifications] = useState(false)
  const classes = useStyles()
  const canShowMoreItems = items.length > minShown
  const remainingCount = items.length - minShown

  const shownItems = (() => {
    if (canShowMoreItems === false || showMoreNotifications === true) {
      return items
    }

    return items.slice(0, minShown)
  })()

  if (items.length === 0) {
    return null
  }

  return (
    <>
      <Box>{shownItems}</Box>
      {canShowMoreItems === true && (
        <Box display="flex" alignItems="center" flexDirection="column" mt={2}>
          <Button
            className={classes.link}
            color="primary"
            onClick={() => setShowMoreNotifications(!showMoreNotifications)}
          >
            {showMoreNotifications === true
              ? `Show less ${pluralize(subject, 2)}`
              : `Show ${remainingCount} more ${pluralize(subject, remainingCount)}`}
          </Button>
        </Box>
      )}
    </>
  )
}
