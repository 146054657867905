import React from "react"
import { Box, makeStyles, useTheme } from "@material-ui/core"
import { Chart, PieSeries } from "@devexpress/dx-react-chart-material-ui"
import { Palette } from "@devexpress/dx-react-chart"
import { RowBox, ColumnBox } from ".."

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    minWidth: 100,
    cursor: ({ clickable }) => (clickable ? "pointer" : "default"),
  },
  text: {
    maxWidth: 100,
    fontSize: 12,
  },
  value: {
    color: ({ complete }) => (complete ? theme.palette.success.main : theme.palette.primary.main),
    position: "absolute",
    width: 100,
    textAlign: "center",
    fontSize: ({ size }) => Math.max(Math.floor(size * 0.2), 16),
    fontWeight: 800,
    lineHeight: "100px",
    zIndex: 999,
  },
}))

const CircularPercentageChart = ({ text, value, ...props }) => {
  const classes = useStyles({ clickable: !!props.onClick, complete: value === 100 })
  const theme = useTheme()

  const colours = [
    value === 100 ? theme.palette.success.main : theme.palette.primary.main,
    theme.palette.chart.background,
  ]

  return (
    <>
      <ColumnBox alignItems="center" justifyContent="flex-start" mr={2} className={classes.root} mb={1} {...props}>
        <ColumnBox>
          <Chart
            width="100"
            height="100"
            data={[
              { argument: "Completed", value },
              { argument: "Uncompleted", value: 100 - value },
            ]}
          >
            <Palette scheme={colours} />
            <PieSeries valueField="value" argumentField="argument" innerRadius={0.8} />
          </Chart>
        </ColumnBox>
        <Box className={classes.value}>{value}%</Box>
        <RowBox alignItems="center" justifyContent="center">
          <Box className={classes.text}>{text}</Box>
        </RowBox>
      </ColumnBox>
    </>
  )
}
export { CircularPercentageChart }
