import { Divider, Icon, MenuItem } from "@material-ui/core"
import React, { useMemo } from "react"
import { OutlinedSelect } from "./OutlinedSelect"
import { useQueryCategories } from "../../data"
import { IconMenuItemContent } from "./IconMenuItemContent"

const CategoryOutlinedSelect = ({ value, handleChange, canCreateCategory = false }) => {
  const { data: categoryData } = useQueryCategories()

  const categories = useMemo(() => categoryData?.categories || [], [categoryData])

  return (
    <OutlinedSelect
      label="Category"
      value={value}
      native={false}
      onChange={handleChange}
      required
      data-cy="OutlinedSelect-category"
    >
      {canCreateCategory && (
        <MenuItem value="new" data-cy="MenuItem-category-new">
          <IconMenuItemContent label="New category..." value="new" icon="add" />
        </MenuItem>
      )}
      {canCreateCategory && <Divider />}
      {categories.map(({ id, name, icon }) => (
        <MenuItem key={id} value={id}>
          <IconMenuItemContent
            label={name}
            value={id}
            icon={<Icon className="material-icons-outlined">{icon}</Icon>}
            iconProps={{ className: "material-icons-outlined" }}
          />
        </MenuItem>
      ))}
    </OutlinedSelect>
  )
}

export { CategoryOutlinedSelect }
