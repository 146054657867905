import { useQuery, gql } from "@apollo/client"
import { ADMINABLE_GROUPS_FIELDS } from "./fragments/adminableGroupFieldsFragment"

const ADMINABLE_GROUPS_QUERY = gql`
  query AdminableGroups {
    groups {
      ...AdminableGroupFields
    }
  }
  ${ADMINABLE_GROUPS_FIELDS}
`

const useQueryAdminableGroups = () => useQuery(ADMINABLE_GROUPS_QUERY)

export { useQueryAdminableGroups }
