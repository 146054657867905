import { useMutation, gql } from "@apollo/client"

const ADD_USER_SEARCH_MUTATION = gql`
  mutation AddUserSearch($searchText: String!) {
    addUserSearch(searchText: $searchText)
  }
`

const useMutationAddUserSearch = () => useMutation(ADD_USER_SEARCH_MUTATION)

export { useMutationAddUserSearch }
