import React, { Suspense, useMemo } from "react"
import { toId, useJobUtils } from "../../utils"
import PageLoadingSpinner from "../LoadingSpinner/PageLoadingSpinner"
import { PROCESS_STEP_RESPONSE_TYPE } from "../../data"
import { AuditSubmittedScreen } from "./AuditSubmittedScreen"
import { useJobCollapsed } from "../../data/jobs/jobSectionCollapseStateVar"
import { ProcessCollapse } from "./ProcessCollapse"
import { ProcessHeader } from "./ProcessHeader"

const ProcessSteps = React.lazy(() => import("./ProcessSteps"))

const ProcessViewer = ({
  job,
  isInline,
  isParentSubmitted,
  allowMessage,
  onDelete,
  onClose,
  onCloseMessage,
  deleteLoading,
  scrollableTarget,
  stepsHeight,
}) => {
  const { isJobAfterToday } = useJobUtils()
  const [, { isStepCollapsed }] = useJobCollapsed(job)
  const {
    status: { steps: allSteps, submitted },
    availableFrom,
  } = job
  const scheduled = isJobAfterToday(availableFrom)

  const steps = useMemo(
    () =>
      allSteps.filter((step) => {
        if (step.responseType === PROCESS_STEP_RESPONSE_TYPE.SECTION) {
          return true
        }

        return !isStepCollapsed(step.section)
      }),
    [allSteps, isStepCollapsed]
  )

  const handleClose = () => {
    onClose && onClose()
  }

  const handleCloseMessage = () => {
    onCloseMessage && onCloseMessage()
  }

  const handleDelete = () => {
    onDelete && onDelete()
  }

  const isSubmitted = submitted || isParentSubmitted

  const showSubmitted = submitted && allowMessage

  return (
    <>
      <AuditSubmittedScreen
        show={showSubmitted}
        job={job}
        onClose={handleClose}
        onCloseMessage={handleCloseMessage}
        boxProps={{ px: isInline ? 2 : 0 }}
      />
      <ProcessCollapse in={!showSubmitted}>
        <Suspense fallback={<PageLoadingSpinner />}>
          <ProcessSteps
            key={toId(job)}
            header={
              <ProcessHeader
                job={job}
                isInline={isInline}
                isParentSubmitted={isParentSubmitted}
                onDelete={handleDelete}
                deleteLoading={deleteLoading}
              />
            }
            job={job}
            steps={steps}
            isInline={isInline}
            isSubmitted={isSubmitted}
            isScheduled={scheduled}
            scrollableTarget={scrollableTarget}
            height={stepsHeight}
          />
        </Suspense>
      </ProcessCollapse>
    </>
  )
}

export { ProcessViewer }
