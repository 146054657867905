import React, { useEffect, useState } from "react"
import Config from "react-global-configuration"
import { Box, Button, Divider, Grid, IconButton, makeStyles, useTheme } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import { Trans } from "@lingui/macro"
import { Icon, MentionOutput, OutlinedInputStep } from ".."
import { preventDefault } from "../../utils"
import { useProbes } from "../../utils/useProbes"
import { SelectedProbeButton } from "../Probes/SelectedProbeButton"
import { ReadProbeDialog } from "../Probes/ReadProbeDialog"
import { checkLogic } from "../../services/jobStepLogic"
import { HelpLink } from "../HelpBanner/HelpLink"
import { ProcessCollapse } from "./ProcessCollapse"
import { PROCESS_STEP_FORMAT_UNIT } from "../../data/enums"
import { StepContentFrame } from "./StepContentFrame"

const useStyles = makeStyles((theme) => ({
  desktopMessage: {
    backgroundColor: theme.palette.background.shaded,
    borderRadius: 8,
    margin: theme.spacing(1, 2),
  },
  bluetoothButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  startRecordingButton: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}))

const NumberStepWithProbes = ({
  step,
  isSubmitted,
  classes: parentClasses,
  collapseTimeout,
  onCompletedChange,
  onResponseChange,
  onCompletedWithResponse,
  onLogicResult,
  inputRef,
  complete,
  expand,
  value,
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const { clientKiosk: kiosk, clientDevice: device } = Config.get()
  const { probe } = useProbes()
  const [open, setOpen] = useState(false)
  const { description, canEnterManually, canUseDevice } = step
  const isDeviceOrKiosk = kiosk || device
  const canUseDeviceOnly = canUseDevice && !canEnterManually
  const [showDevice, setShowDevice] = useState(canUseDeviceOnly)

  useEffect(() => {
    if (complete && canUseDeviceOnly && showDevice) {
      setShowDevice(false)
    } else if (!complete && canUseDeviceOnly && !showDevice) {
      setShowDevice(true)
    }
  }, [canUseDeviceOnly, complete, showDevice])

  const handleCompletedChange = async (event, skipping) => {
    onCompletedChange && onCompletedChange(event, skipping)
  }

  const handleResponseChange = (newValue) => {
    onResponseChange && onResponseChange(newValue)
  }

  const handleLogicResult = (logicResult) => {
    onLogicResult && onLogicResult(logicResult)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleRecord = (event, reading, formattedReading) => {
    // TODO store "reading" (the true probe value) to the step as well
    const logicResult = checkLogic(step, formattedReading)

    const stringValue = formattedReading.toString()

    // complete the step if no requirements
    if (logicResult.hasRequirements || !onCompletedWithResponse) {
      handleResponseChange(stringValue)
    } else {
      onCompletedWithResponse && onCompletedWithResponse(stringValue)
    }

    setOpen(false)
    setShowDevice(false)
  }

  const handleEnterManually = () => {
    setShowDevice(false)
  }

  const handleShowDevice = () => {
    if (!isDeviceOrKiosk) {
      return
    }

    setShowDevice(true)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const handleFormat = () => {
    const hasUnit = Boolean(step.format?.unit)

    if (
      hasUnit &&
      [PROCESS_STEP_FORMAT_UNIT.TEMPERATURE_CELSIUS, PROCESS_STEP_FORMAT_UNIT.TEMPERATURE_FAHRENHEIT].includes(
        step.format.unit,
      )
    ) {
      return step.format.unit
    }
    return null
  }

  const TakeReadingButton = () => (
    <Button
      variant="contained"
      color="primary"
      className={classes.startRecordingButton}
      onClick={handleOpen}
      disabled={!probe}
    >
      <Trans context="Capture a value">Take reading</Trans>
    </Button>
  )

  const EnterManuallyButton = () => (
    <Button variant="contained" className={classes.startRecordingButton} onClick={handleEnterManually}>
      <Trans>Enter manually</Trans>
    </Button>
  )

  return (
    <ProcessCollapse in={expand} timeout={collapseTimeout}>
      {open && (
        <ReadProbeDialog
          onRecord={handleRecord}
          onCancel={handleCancel}
          instructions={description ? <MentionOutput content={description} /> : null}
          format={handleFormat()}
          onDetermineDisplay={(formattedReading) => {
            const logicResult = checkLogic(step, formattedReading)
            if (logicResult.hasRequirements) {
              return {
                colour: theme.palette.error.main,
                backgroundColor: theme.palette.error.background,
                message: <Trans>Out of range</Trans>,
              }
            }

            return {
              colour: theme.palette.primary.main,
              backgroundColor: theme.palette.background.shaded,
              message: <Trans context="A measurement shown on a screen">Reading</Trans>,
            }
          }}
        />
      )}

      {showDevice && !isDeviceOrKiosk && (
        <>
          <Divider className={parentClasses.divider} />
          <Box className={classes.desktopMessage}>
            <Alert icon={<Icon name="probe" />} severity="info">
              <AlertTitle>
                <Trans>Taking this measurement</Trans>
              </AlertTitle>
              <Trans>
                To take this measurement, please use a Bluetooth-enabled mobile or kiosk device, with a{" "}
                <HelpLink article="65-supported-bluetooth-measurement-instruments">
                  supported measurement instrument
                </HelpLink>
                .
              </Trans>
            </Alert>
          </Box>
        </>
      )}

      {showDevice && isDeviceOrKiosk && (
        <>
          <Divider className={parentClasses.divider} />
          <StepContentFrame>
            <Grid container direction="row" justifyContent="space-between" spacing={2}>
              <Grid item xs={12} sm="auto">
                <SelectedProbeButton />
              </Grid>
              {!canEnterManually && (
                <Grid item xs={12} sm="auto">
                  <TakeReadingButton />
                </Grid>
              )}
              {canEnterManually && (
                <Grid item xs={12} sm="auto">
                  <Grid container spacing={1}>
                    <Grid item sm="auto" xs={6}>
                      <EnterManuallyButton />
                    </Grid>
                    <Grid item sm="auto" xs={6}>
                      <TakeReadingButton />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </StepContentFrame>
        </>
      )}

      {!showDevice && (
        <OutlinedInputStep
          expand
          inputRef={inputRef}
          complete={complete}
          step={step}
          onResponseChange={handleResponseChange}
          onLogicResult={handleLogicResult}
          onCompletedChange={handleCompletedChange}
          value={value}
          collapseTimeout={collapseTimeout}
          disabled={isSubmitted}
          readOnly={!canEnterManually}
          classes={{
            divider: parentClasses.divider,
            completedText: parentClasses.completedText,
            incompletedButton: parentClasses.incompletedButton,
            completedButton: parentClasses.completedButton,
            adornments: parentClasses.adornments,
          }}
          hideEditAndSaveButtons={!canEnterManually && (!complete || !isDeviceOrKiosk)}
          buttons={
            canUseDevice &&
            isDeviceOrKiosk && (
              <Box onClick={preventDefault}>
                <IconButton onClick={handleShowDevice} className={classes.bluetoothButton} disabled={complete}>
                  <Icon name="bluetooth" />
                </IconButton>
              </Box>
            )
          }
        />
      )}
    </ProcessCollapse>
  )
}

export { NumberStepWithProbes }
