import Config from "react-global-configuration"
import BaseConfig from "./Base"
import LocalConfig from "./Local"
import LocalTest from "./LocalTest"
import LocalKioskConfig from "./LocalKiosk"
import LocalDeviceConfig from "./LocalDevice"
import LocalSecondConfig from "./LocalSecond"
import LocalBudgieConfig from "./LocalBudgie"
import LocalDevConfig from "./LocalDev"
import LocalDevKioskConfig from "./LocalDevKiosk"
import LocalDevDeviceConfig from "./LocalDevDevice"
import LocalLiveConfig from "./LocalLive"
import DevConfig from "./Dev"
import DevKioskConfig from "./DevKiosk"
import DevDeviceConfig from "./DevDevice"
import TestConfig from "./Test"
import TestKioskConfig from "./TestKiosk"
import TestDeviceConfig from "./TestDevice"
import LiveConfig from "./Live"
import LiveDeviceConfig from "./LiveDevice"
import LiveKioskConfig from "./LiveKiosk"

const initConfig = () =>
  new Promise((resolve, reject) => {
    const { location } = window

    let config = null
    let clientBaseUri = location.hostname // client app base

    console.log(`[CONFIG] Host is: ${location.hostname}`)

    switch (clientBaseUri) {
      case "localhost":
      case "local.operandiodev.com":
      case "127.0.0.1":
        clientBaseUri += `:${location.port}` // append port
        switch (location.port) {
          case "3000":
          case "8443":
            config = LocalConfig
            break
          case "8444":
            config = LocalKioskConfig
            break
          case "8445":
            config = LocalDeviceConfig
            break
          case "8450":
            config = LocalTest
            break
          case "8446":
            config = LocalDevConfig
            break
          case "8447":
            config = LocalDevKioskConfig
            break
          case "8448":
            config = LocalDevDeviceConfig
            break
          case "3002":
            console.log(
              "[CONFIG] Multi-instance load balanced environment simulation, ensure second server instance running",
            )
            config = LocalSecondConfig
            break
          case "3003":
            config = LocalLiveConfig
            break
          default:
            reject(new Error("Unable to locate suitable environment configuration."))
        }
        break
      case "budgie":
      case "10.0.2.2":
      case "192.168.100.100":
        clientBaseUri += `:${location.port}` // append port
        switch (location.port) {
          case "3000":
            config = LocalBudgieConfig
            break
          default:
            reject(new Error("Unable to locate suitable environment configuration."))
        }
        break
      case "dev-app.operandiodev.com":
        config = DevConfig
        break
      case "dev-kiosk.operandiodev.com":
        config = DevKioskConfig
        break
      case "dev-device.operandiodev.com":
        config = DevDeviceConfig
        break
      case "test-app.operandiodev.com":
        config = TestConfig
        break
      case "test-kiosk.operandiodev.com":
        config = TestKioskConfig
        break
      case "test-device.operandiodev.com":
        config = TestDeviceConfig
        break
      case "app.operandio.com":
        config = LiveConfig
        break
      case "device.operandio.com":
        config = LiveDeviceConfig
        break
      case "kiosk.operandio.com":
        config = LiveKioskConfig
        break
      default:
        reject(new Error("Unable to locate suitable environment configuration."))
    }

    config.clientBaseUri = `${location.protocol}//${clientBaseUri}`

    Config.set(Object.assign(BaseConfig, config))

    // document title?
    if (config.showConfigInTitle) {
      document.title = `${config.configTitle} ${document.title}`
    }

    const getConfig = Config.get()

    console.log(`[CONFIG] Using (${location.hostname}:${location.port})`)

    if (config.showConfigInConsole) {
      console.log(`[CONFIG]`, getConfig)
    }

    // resolve promise
    resolve(getConfig)
  })

export default initConfig
