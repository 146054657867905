import React from "react"
import { Grid, Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { Avatar, Icon } from ".."

const useMentionListItemStyles = makeStyles((theme) => ({
  root: {
    fontSize: 13,
  },
  avatar: {
    width: 24,
    height: 24,
    fontSize: 12,
  },
  icon: {
    width: 16,
    height: 16,
    fontSize: 16,
  },
  text: {
    fontSize: 13,
    lineHeight: "15px",
  },
  secondaryText: {
    fontSize: 11,
    color: theme.palette.text.secondary,
  },
}))

const MentionListItem = ({ text, secondaryText, iconName, customIconName, children }) => {
  const classes = useMentionListItemStyles()

  return (
    <Grid container alignItems="center" className={classes.root} spacing={1}>
      {children || (
        <>
          <Grid item>
            <Avatar
              className={classes.avatar}
              icon={<Icon name={iconName} customName={customIconName} className={classes.icon} />}
            />
          </Grid>
          <Grid item>
            <Box>
              <Box className={classes.text}>{text}</Box>
              {secondaryText && <Box className={classes.secondaryText}>{secondaryText}</Box>}
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export { MentionListItem, useMentionListItemStyles }
