import { useQuery, gql } from "@apollo/client"
import { IDENTITYPROVIDER_FIELDS } from "./fragments/identityProviderFieldsFragment"

const IDENTITYPROVIDER_QUERY = gql`
  query IdentityProviders {
    identityProviders {
      ...IdentityProviderFields
    }
  }
  ${IDENTITYPROVIDER_FIELDS}
`

const useQueryIdentityProviders = () => useQuery(IDENTITYPROVIDER_QUERY)

export { useQueryIdentityProviders }
