import { gql } from "@apollo/client"

const queryVerifyPasscode = async ({ username, passcode }, client) => {
  try {
    const response = await client.query({
      query: gql`
        query VerifyPasscode {
          verifyPasscode(username:"${username}", passcode:"${passcode}")
        }
      `,
      fetchPolicy: "network-only",
    })
    return response
  } catch (err) {
    return err
  }
}

export { queryVerifyPasscode }
