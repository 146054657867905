import React from "react"
import moment from "moment-timezone"
import { useAuth } from "../../services"

export default ({ value, timeZone }) => {
  const { location } = useAuth()

  try {
    return (
      <>
        {value &&
          moment(value)
            .tz(timeZone || location.timeZone)
            .format("Do MMM YYYY hh:mma z")}
      </>
    )
  } catch (ex) {
    return <>Data invalid.</>
  }
}
