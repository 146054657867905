import React from "react"
import { Box, Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { Trans } from "@lingui/macro"
import { FiltersChips } from ".."
import { useQueryGroups, useQueryLocations, useQueryRoles } from "../../data"
import { FiltersButton } from "./FiltersButton"
import { BulkActionsButton } from "./BulkActionsButton"
import { RowBox } from "../Boxes"
import DownloadButton from "./DownloadButton"

const useStyles = makeStyles((theme) => ({
  filter: {
    backgroundColor: theme.palette.shaded.filter,
  },
  tableHead: {
    position: "sticky",
    top: theme.dimensions.header.height,
    backgroundColor: "white",
    zIndex: 1000,
  },
  tableCellCenter: {
    textAlign: "center",
  },
  clearAllLink: {
    whiteSpace: "nowrap",
  },
  infiniteScrollWrapper: {
    "&&>div": {
      display: "contents",
    },
  },
  infiniteScroll: {
    display: "contents",
  },
  button: {
    height: 36.5,
  },
}))

const FiltersPanel = ({
  filters,
  filtersOptions = {
    hasRoles: true,
    hasStatus: true,
    hasGroups: true,
    hasLocations: true,
    hasTrainingStatus: true,
    hasAccreditationStatus: true,
  },
  onChange,
  onClear,
  onDelete,
  any,
  bulkAction,
  bulkActionParams,
  itemCount,
  allCount,
  onBulkActionApply,
  onBulkActionChange,
  onBulkActionParamsChange,
  onDownload,
  checked,
}) => {
  const classes = useStyles()
  const { data: groupsData } = useQueryGroups()
  const { data: locationsData } = useQueryLocations()
  const { data: rolesData } = useQueryRoles()

  const data = {
    groupsData,
    locationsData,
    rolesData,
  }

  const hasBulkActions = Boolean(onBulkActionApply && onBulkActionChange && onBulkActionParamsChange)
  const hasDownload = Boolean(onDownload)

  return (
    <Box display="flex" flexDirection="row">
      <Box px={3} mb={3} mr={3} display="flex" flexGrow={1} className={classes.filter}>
        <Box py={3} pr={2}>
          <FiltersButton filters={filters} onFilterChange={onChange} options={filtersOptions} />
        </Box>
        <Box display="flex" alignItems="center">
          <FiltersChips data={data} filters={filters} onDelete={onDelete} />
        </Box>
        {filters.length > 0 && (
          <Box ml="auto" pl={2} display="flex" alignItems="center">
            <Button className={classes.clearAllLink} color="primary" onClick={onClear}>
              <Trans>Clear all</Trans>
            </Button>
          </Box>
        )}
      </Box>
      {(hasBulkActions || hasDownload) && (
        <Box p={3} mb={3} display="flex" flexShrink={0} className={classes.filter}>
          <RowBox>
            {hasBulkActions && (
              <BulkActionsButton
                enabled={any}
                action={bulkAction}
                params={bulkActionParams}
                itemCount={itemCount}
                onApply={onBulkActionApply}
                onActionChange={onBulkActionChange}
                onParamsChange={onBulkActionParamsChange}
              />
            )}
            {hasDownload && (
              <Box ml={hasBulkActions ? 1 : 0}>
                <DownloadButton itemCount={itemCount} allCount={allCount} filters={filters} checked={checked} />
              </Box>
            )}
          </RowBox>
        </Box>
      )}
    </Box>
  )
}

export { FiltersPanel }
