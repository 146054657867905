import { useState } from "react"
import * as Sentry from "@sentry/browser"
import { useAuth } from "../services"

export const localStoragePrefix = "op_"

export const getLocalStorageItem = (key) => window.localStorage.getItem(`${localStoragePrefix}_${key}`)

const debounceMs = 500

let timeout = null

export const useLocalStorage = (key, initialValue, debounce = true) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key for user
      const item = getLocalStorageItem(key)
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      Sentry.captureException(error)
      return initialValue
    }
  })
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    return new Promise((resolve, reject) => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore = value instanceof Function ? value(storedValue) : value
        // Save state
        setStoredValue(valueToStore)
        if (timeout) clearTimeout(timeout)
        timeout = setTimeout(
          () => {
            try {
              // Save to local storage
              window.localStorage.setItem(`${localStoragePrefix}_${key}`, JSON.stringify(valueToStore))
              resolve()
            } catch (error) {
              Sentry.captureException(error)
              reject(error)
            }
          },
          debounce ? debounceMs : 0
        )
      } catch (error) {
        // A more advanced implementation would handle the error case
        Sentry.captureException(error)
        reject(error)
      }
    })
  }
  const removeValue = () => {
    return new Promise((resolve, reject) => {
      try {
        window.localStorage.removeItem(`${localStoragePrefix}_${key}`)
        setStoredValue(null)
        resolve()
      } catch (error) {
        Sentry.captureException(error)
        reject(error)
      }
    })
  }
  const checkAvailable = () => {
    return new Promise((resolve) => {
      const test = "op__test"
      try {
        window.localStorage.setItem(test, test)
        window.localStorage.removeItem(test)
        resolve(true)
      } catch (error) {
        console.log("[useLocalStorage]", "Local storage is unavailable")
        resolve(false)
      }
    })
  }

  return [storedValue, setValue, removeValue, { checkAvailable }]
}

export const useUserLocalStorage = (key, initialValue, debounce = true) => {
  const {
    principal: { userID },
  } = useAuth()

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key for user
      const item = window.localStorage.getItem(`${localStoragePrefix}${userID}_${key}`)
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      Sentry.captureException(error)
      return initialValue
    }
  })
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(
        () => {
          // Save to local storage
          window.localStorage.setItem(`${localStoragePrefix}${userID}_${key}`, JSON.stringify(valueToStore))
        },
        debounce ? debounceMs : 0
      )
    } catch (error) {
      // A more advanced implementation would handle the error case
      Sentry.captureException(error)
    }
  }
  const removeValue = () => {
    try {
      window.localStorage.removeItem(`${localStoragePrefix}${userID}_${key}`)
    } catch (error) {
      console.log("useUserLocalStorage", error)
      Sentry.captureException(error)
    }
  }
  return [storedValue, setValue, removeValue]
}
