import React, { memo, useEffect, useState } from "react"
import { Box, Tooltip, makeStyles, Divider, Typography, Button } from "@material-ui/core"
import moment from "moment-timezone"
import { useHistory, useParams } from "react-router-dom"
import { formatJobTitle, getStepFormattedUnit, toId, useJobUtils } from "../../../utils"
import { useLazyQueryAdminableUser } from "../../../data"
import { FormatDateFormal, FormatHumanTime, Icon, PaperBox, TimestampUserPanel } from "../.."

const useTooltipStyles = makeStyles((theme) => ({
  tooltipButton: {
    display: "inline-block",
    alignItems: "center",
    textDecoration: "none",
    backgroundColor: "transparent",
    border: "none",
    fontWeight: "normal",
    fontFamily: theme.typography.fontFamily,
    fontSize: "inherit",
    lineHeight: "inherit",
    cursor: "pointer",
    outline: "none",
    margin: 0,
    padding: 0,
  },
  valueButton: {
    color: theme.palette.text.secondary,
  },
  tooltipContent: {
    color: theme.palette.text.secondary,
    maxWidth: 550,
    fontSize: 14,
    fontWeight: "500",
    lineHeight: "20px",
    cursor: "pointer",
  },
  tooltipResponseValue: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    lineHeight: "14px",
    fontWeight: "normal",
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
  },
  jobTitle: {
    color: theme.palette.text.primary,
    fontSize: 16,
    fontWeight: "500",
    lineHeight: "20px",
  },
  tooltip: {
    backgroundColor: "Transparent",
    maxWidth: "none",
  },
  anchor: {
    left: "8px !important",
    top: "0px !important",
    transform: "none !important",
  },
}))

const UserTooltip = ({ jobStep, children, anchor }) => {
  const classes = useTooltipStyles()
  const { days, locations, groups } = useParams()
  const history = useHistory()
  const { isStepValueResponseType } = useJobUtils()
  const [loadUser, { data }] = useLazyQueryAdminableUser()
  const [open, setOpen] = useState(false)
  const {
    step: { responseType, response, skip, completedAt, completedBy, format },
  } = jobStep

  useEffect(() => {
    if (open) {
      loadUser({ variables: { id: completedBy } })
    }
  }, [completedBy, loadUser, open])

  const handleOpen = () => {
    if (!open) setOpen(true)
  }

  const handleUserClick = () => {
    history.push(`/reports/people/${days || 7}/${locations || "all"}/${groups || "all"}/${toId(data.adminableUser)}`)
  }

  return (
    <Tooltip
      title={
        <PaperBox className={classes.tooltipContent} onClick={handleUserClick}>
          {isStepValueResponseType(responseType) && !skip && (
            <>
              <Box p={2} className={classes.tooltipResponseValue}>
                {format?.unit ? getStepFormattedUnit(response, format) : response}
              </Box>
              <Divider />
            </>
          )}
          {data && (
            <TimestampUserPanel
              user={data.adminableUser}
              timestamp={completedAt}
              hoverText={
                <Box mt={1}>
                  <Button variant="outlined" size="small">
                    Reports&nbsp;
                    <Icon name="forward" fontSize="small" />
                  </Button>
                </Box>
              }
            />
          )}
        </PaperBox>
      }
      classes={{ tooltip: classes.tooltip, popper: anchor ? classes.anchor : null }}
      enterDelay={1000}
      enterTouchDelay={1000}
      leaveTouchDelay={2000}
      onOpen={handleOpen}
      interactive
      PopperProps={{
        popperOptions: {
          positionFixed: true,
        },
      }}
    >
      <button type="button" className={`${classes.tooltipButton} ${classes.valueButton}`}>
        {children}
      </button>
    </Tooltip>
  )
}

const JobTooltip = memo(function JobTooltip({ job, location, children }) {
  const classes = useTooltipStyles()
  const [open, setOpen] = useState(false)
  const { availableFrom, dueAt } = job

  const handleOpen = () => {
    if (!open) setOpen(true)
  }

  return (
    <Tooltip
      title={
        <PaperBox className={classes.tooltipContent}>
          <Box p={2}>
            <Typography component="h1" className={classes.jobTitle}>
              {formatJobTitle(job)}
            </Typography>
            <Box>
              <FormatDateFormal value={moment(availableFrom).tz(location.timeZone)} />
            </Box>
            {dueAt && (
              <Box mt={1}>
                Due at <FormatHumanTime value={moment(dueAt).tz(location.timeZone)} />
              </Box>
            )}
          </Box>
        </PaperBox>
      }
      classes={{ tooltip: classes.tooltip }}
      enterTouchDelay={100}
      leaveTouchDelay={2000}
      onOpen={handleOpen}
    >
      <button type="button" className={classes.tooltipButton}>
        {children}
      </button>
    </Tooltip>
  )
})

export { UserTooltip, JobTooltip }
