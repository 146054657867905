import React from "react"
import { EVENT_NAME, NOTIFICATION_TYPE, useQuerySettings } from "../../data"
import { useAuth } from "../../services"
import { toId } from "../../utils"

const NotificationMessage = ({ notification, className, showMessage = true, truncateAt = 75 }) => {
  const settings = useQuerySettings()
  const { isCurrentUser } = useAuth()
  const { type, from } = notification

  const fn = settings.lang.notification[`message${type}`]
  let message = fn ? fn(notification, showMessage) : "unknown notification type"

  if (message?.length > truncateAt) {
    message = `${message.substring(0, truncateAt - 3).trim()}...`
  }

  const self = isCurrentUser(toId(from, true))

  const fromName = self ? "You" : from ? `${from.firstName} ${from.lastName}`.trim() : null

  // If the notification is created from a trigger, the message will not be displayed.
  const showMessageOnly =
    notification.type === NOTIFICATION_TYPE.ACTION && notification.eventName === EVENT_NAME.ACTION_OVERDUE

  return (
    <div className={className}>
      {!showMessageOnly && from && <strong>{fromName}</strong>} {message}
    </div>
  )
}

export default NotificationMessage
