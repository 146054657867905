import { useQuery, gql, useLazyQuery } from "@apollo/client"
import { INTEGRATION_PROVIDER_FIELDS } from "./useQueryIntegrationProviders"

const INTEGRATION_FIELDS = gql`
  fragment IntegrationFields on Integration {
    id
    integrationProvider {
      ...IntegrationProviderFields
    }
    title {
      name
      description
      value
    }
    variables {
      name
      description
      key
    }
    attributes {
      id
      key
      value
    }
    enabled
    enabledAt
    paused
    pausedAt
    events
    createdAt
  }
  ${INTEGRATION_PROVIDER_FIELDS}
`

const INTEGRATION_QUERY = gql`
  query Integrations {
    integrations {
      ...IntegrationFields
    }
  }
  ${INTEGRATION_FIELDS}
`

const useQueryIntegrations = (options) => useQuery(INTEGRATION_QUERY, options)

const useLazyQueryIntegrations = () => useLazyQuery(INTEGRATION_QUERY)

export { useQueryIntegrations, useLazyQueryIntegrations, INTEGRATION_FIELDS }
