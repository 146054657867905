import React, { memo } from "react"
import { useDateUtils } from "../../utils"

const FormatScheduleTime = ({ value }) => {
  const { formatScheduleTime } = useDateUtils()

  try {
    return <span>{formatScheduleTime(value)}</span>
  } catch (ex) {
    return <>Data invalid.</>
  }
}

const FormatScheduleTimeMemo = memo(FormatScheduleTime)

export { FormatScheduleTimeMemo as FormatScheduleTime }
