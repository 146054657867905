import React from "react"
import { Box, Divider, Hidden, useMediaQuery, useTheme } from "@material-ui/core"
import { ActionCard, ActionCardInfo } from "."
import { AuthorTools } from "../Author"
import { ColumnBox, RowBox } from "../Boxes"
import { Icon } from "../Icon"
import { FormatDateTimeCompact, ReadOnlyOutlinedInput, AdornmentIcon } from ".."
import { useAuth } from "../../services"

const ActionCardFooterInfo = ({ title, value, icon, mr }) => (
  <RowBox mr={mr}>
    <Hidden mdUp>
      <ActionCardInfo>
        <AdornmentIcon
          name={icon}
          tooltip={
            <>
              {title}: {value}
            </>
          }
        />
      </ActionCardInfo>
    </Hidden>
    <Hidden smDown>
      <AdornmentIcon name={icon} tooltip={title} />
      <Box ml={1} mr={0}>
        {value}
      </Box>
    </Hidden>
  </RowBox>
)

const IdentityProviderActionCard = ({ identityProvider, onEdit, onDelete, detail, ...rest }) => {
  const theme = useTheme()
  const small = useMediaQuery(theme.breakpoints.down("sm"))
  const {
    getIdentityProviderBrowserLoginLink,
    getIdentityProviderBrowserLogoutLink,
    getIdentityProviderDeviceLoginLink,
    getIdentityProviderBrowserRedirectLink,
    getIdentityProviderDeviceRedirectLink,
  } = useAuth()
  const { id, name, client, authority, createdAt } = identityProvider

  const handleEdit = () => {
    if (onEdit) onEdit(identityProvider)
  }
  const handleDelete = () => {
    if (onDelete) onDelete(identityProvider)
  }

  const browserLoginUrl = getIdentityProviderBrowserLoginLink(identityProvider)
  const browserLogoutUrl = getIdentityProviderBrowserLogoutLink(identityProvider)
  const browserRedirectUri = getIdentityProviderBrowserRedirectLink(identityProvider)
  const deviceLoginUrl = getIdentityProviderDeviceLoginLink(identityProvider)
  const deviceRedirectUri = getIdentityProviderDeviceRedirectLink(identityProvider)

  return (
    <ActionCard
      key={id}
      title={name}
      detail={
        <Box style={{ minWidth: 700 }}>
          {!small && <Box mb={3}>{authority}</Box>}
          <Box mb={2} mt={small ? 3 : 0}>
            <ReadOnlyOutlinedInput
              label="Browser login link"
              hasCopy
              hasQRCode
              value={browserLoginUrl}
              labelWidth={135}
              fullWidth
            />
          </Box>
          <Box mb={2}>
            <ReadOnlyOutlinedInput
              label="Device login link"
              hasCopy
              hasQRCode
              value={deviceLoginUrl}
              labelWidth={160}
              fullWidth
            />
          </Box>
          <Divider />
          <Box my={2}>
            <ReadOnlyOutlinedInput
              label="Browser logout link"
              hasCopy
              value={browserLogoutUrl}
              labelWidth={150}
              fullWidth
            />
          </Box>

          <Box mb={2}>
            <ReadOnlyOutlinedInput
              label="Browser redirect URI"
              hasCopy
              value={browserRedirectUri}
              labelWidth={160}
              fullWidth
            />
          </Box>
          <Box mb={1}>
            <ReadOnlyOutlinedInput
              label="Device redirect URI"
              hasCopy
              value={deviceRedirectUri}
              labelWidth={150}
              fullWidth
            />
          </Box>
        </Box>
      }
      cursor={null}
      stub={
        <ColumnBox mr={1} justifyContent="flex-start">
          <Icon name="identity-provider" />
        </ColumnBox>
      }
      rightChildren={
        <RowBox alignItems="flex-start">
          <AuthorTools
            item={identityProvider}
            admins={{
              edit: "identity_provider_update",
            }}
            onEdit={handleEdit}
            subject="identity provider"
            onDelete={handleDelete}
          />
        </RowBox>
      }
      footer={
        <>
          <ActionCardFooterInfo title="Client (application) ID" value={client} icon="setting" mr="auto" />
          <ActionCardFooterInfo
            title="Date registered"
            value={<FormatDateTimeCompact value={createdAt} />}
            icon="date"
            mr={small ? -1 : 0}
          />
        </>
      }
      {...rest}
    />
  )
}

export { IdentityProviderActionCard }
