import { useMutation, gql } from "@apollo/client"
import { v4 as uuid } from "uuid"
import { produce } from "immer"
import { toId } from "../../utils"
import { postMatchesSet } from "./useQueryPosts"
import {
  makeOptimisticPlaceholderGroup,
  makeOptimisticPlaceholderKnowledge,
  makeOptimisticPlaceholderLocation,
  makeOptimisticPlaceholderUser,
  makeOptimisticTimestamps,
  makeOptimisticUserFromPrincipal,
} from "../makeOptimistic"
import { POSTS_QUERY } from "."
import { POST_FIELDS } from "./fragments/postFieldsFragment"
import { hubFiltersVar } from "../hubFiltersVar"

const CREATEPOST_MUTATION = gql`
  mutation CreatePostV2(
    $title: String!
    $content: String!
    $job: ID
    $knowledge: ID
    $hasComments: Boolean!
    $hasConfirm: Boolean!
    $visibleFrom: Date
    $locations: [ID!]!
    $users: [ID!]
    $groups: [ID!]
    $uploads: [ID!]
  ) {
    createPostV2(
      title: $title
      content: $content
      job: $job
      knowledge: $knowledge
      hasComments: $hasComments
      hasConfirm: $hasConfirm
      visibleFrom: $visibleFrom
      locations: $locations
      users: $users
      groups: $groups
      uploads: $uploads
    ) {
      ...PostFields
    }
  }
  ${POST_FIELDS}
`

const makeCreatePostOptimisticResponse = ({ post: { locations, users, groups, ...post }, principal }) => ({
  __typename: "Mutation",
  createPostV2: {
    __typename: "Post",
    ...post,
    id: uuid(),
    author: makeOptimisticUserFromPrincipal(principal),
    locations: locations.map(() => makeOptimisticPlaceholderLocation()),
    users: users.map(() => makeOptimisticPlaceholderUser()),
    groups: groups.map(() => makeOptimisticPlaceholderGroup()),
    knowledge: post.knowledge ? makeOptimisticPlaceholderKnowledge() : null,
    job: null,
    hasSentConfirms: false,
    comments: [],
    confirmed: [],
    unconfirmed: [],
    ...makeOptimisticTimestamps(),
  },
})

const useMutationCreatePost = ({ set }) =>
  useMutation(CREATEPOST_MUTATION, {
    update: (store, { data: { createPostV2: post } }) => {
      if (set === "all") {
        return unshiftPost(store, set, readPosts(store, "all"), post)
      }
      if (postMatchesSet(post, "important")) {
        return unshiftPost(store, set, readPosts(store, "important"), post)
      }
      if (postMatchesSet(post, "jobs")) {
        return unshiftPost(store, set, readPosts(store, "jobs"), post)
      }
      if (postMatchesSet(post, "knowledge")) {
        return unshiftPost(store, set, readPosts(store, "knowledge"), post)
      }
    },
  })

const readPosts = (store, set) =>
  store.readQuery({ query: POSTS_QUERY, variables: { filter: { set, ...hubFiltersVar() }, offset: 0 } })

const unshiftPost = (store, set, setData, post) => {
  if (!setData) {
    return
  }
  store.writeQuery({
    query: POSTS_QUERY,
    variables: { filter: { set, ...hubFiltersVar() }, offset: 0 },
    data: produce(setData, (data) => {
      const cachePost = data.posts.list.find((item) => toId(item) === toId(post))
      if (cachePost) {
        Object.assign(cachePost, post)
        return
      }
      data.posts.list.unshift(post)
    }),
  })
}

export { useMutationCreatePost, makeCreatePostOptimisticResponse }
