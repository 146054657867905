import { Box, Hidden, IconButton, TableCell, TableRow } from "@material-ui/core"
import { EditOutlined } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"
import React, { useRef, useState } from "react"
import { NavLink } from "react-router-dom"
import { useAuth } from "../../services"
import { toId } from "../../utils"
import { TruncateNames } from "../DataDisplay/TruncateNames"
import { AuthorTools, ErrorBoundary, Icon, LoadingSpinner, NoItemsMessage, RowBox } from ".."
import { useMutationDeleteFoodItem } from "../../data/food-items/useMutationFoodItem"
import { TableVirtualizer } from "../Lists/TableVirtualizer"

const useStyles = makeStyles((theme) => ({
  name: {
    fontSize: 16,
    color: theme.palette.text.tinted,
    lineHeight: "20px",
  },
  suppliesInline: {
    fontSize: 14,
    color: theme.palette.text.light,
    lineHeight: "16px",
  },
  roles: {
    color: theme.palette.text.light,
    whiteSpace: "nowrap",
  },
  tableHead: {
    position: "sticky",
    top: theme.dimensions.header.height,
    backgroundColor: "white",
    zIndex: 1000,
  },
  tableCellLeft: {
    whiteSpace: "nowrap",
  },
  tableCellCenter: {
    textAlign: "center",
  },
  infiniteScrollWrapper: {
    "&&>div": {
      display: "contents",
    },
  },
  infiniteScroll: {
    display: "contents",
  },
  supportInfo: {
    fontSize: 20,
    cursor: "pointer",
  },
  supportInfoRef: {
    display: "flex",
    alignSelf: "center",
  },
}))

const FoodItemTable = ({ items, loading, loaded, onLoadMore, hasDelete = false, isInline = false }) => {
  const { hasPermission } = useAuth()
  const classes = useStyles()

  const tableContainerRef = useRef(null)

  const [deleteLoading, setDeleteLoading] = useState(false)
  const [deleteFoodItem] = useMutationDeleteFoodItem()

  const handleDelete = async (foodItem) => {
    setDeleteLoading(toId(foodItem))
    await deleteFoodItem({ variables: { id: toId(foodItem) } })
    setDeleteLoading(false)
  }

  const handleLoadMore = () => {
    onLoadMore && onLoadMore()
  }

  const hasEditPermission = hasPermission("food_item_update")

  return (
    <>
      {loading && !loaded && (
        <Box display="flex" justifyContent="center">
          <LoadingSpinner size={60} />
        </Box>
      )}
      {loaded && (
        <div ref={tableContainerRef} style={{ overflow: "visible" }}>
          <TableVirtualizer
            useWindowScroll
            items={items}
            endReached={handleLoadMore}
            fixedHeaderContent={() => (
              <TableRow>
                <TableCell className={classes.tableCellLeft}>Name</TableCell>
                <TableCell>Locations</TableCell>
                <TableCell className={classes.tableCellCenter}>Actions</TableCell>
              </TableRow>
            )}
            itemContent={(_, foodItem) => {
              return (
                <ErrorBoundary key={toId(foodItem)}>
                  <TableCell>
                    <NavLink to={`/food-items/${toId(foodItem)}`}>
                      <Box display="flex" flexDirection="row">
                        <Box ml={1}>
                          <Box className={classes.name}>{foodItem.name}</Box>
                        </Box>
                      </Box>
                    </NavLink>
                  </TableCell>
                  {!isInline && (
                    <TableCell>
                      <TruncateNames names={foodItem.locations?.map((group) => group.name)} max={3} />
                    </TableCell>
                  )}
                  <TableCell>
                    <RowBox justifyContent="flex-end">
                      <IconButton
                        component={NavLink}
                        to={`/food-items/${toId(foodItem)}`}
                        data-cy={`food-item-view-${toId(foodItem)}`}
                      >
                        <Icon name="view" />
                      </IconButton>
                      {hasEditPermission && (
                        <Hidden xsDown>
                          <IconButton
                            component={NavLink}
                            to={`/food-items/${toId(foodItem)}/edit`}
                            data-cy={`food-item-edit-${toId(foodItem)}`}
                            disabled={!hasEditPermission}
                          >
                            <EditOutlined />
                          </IconButton>
                          {hasDelete && (
                            <AuthorTools
                              item={foodItem}
                              admins={{ delete: true }}
                              subject="food item"
                              onDelete={() => handleDelete(foodItem)}
                              loading={deleteLoading === toId(foodItem)}
                              p={0.5}
                            />
                          )}
                        </Hidden>
                      )}
                    </RowBox>
                  </TableCell>
                </ErrorBoundary>
              )
            }}
          />

          {!loading && items && !items.length && <NoItemsMessage>No matching items</NoItemsMessage>}
        </div>
      )}
    </>
  )
}

export { FoodItemTable }
