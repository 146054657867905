import React from "react"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 28,
    fontWeight: "600",
    lineHeight: "36px",
    marginBottom: ({ mb }) => theme.spacing(mb),
    marginLeft: ({ ml }) => theme.spacing(ml),
  },
}))

const MainHeading = ({ children, ml = 1, mb = 1 }) => {
  const classes = useStyles({ ml, mb })

  return (
    <Typography variant="h1" className={classes.title}>
      {children}
    </Typography>
  )
}

export default MainHeading
