import React, { useState } from "react"
import { Hidden, Grid, Button, Box, DialogActions, makeStyles, ButtonGroup, Menu, MenuItem } from "@material-ui/core"
import { ArrowDropDown } from "@material-ui/icons"
import { Trans } from "@lingui/macro"
import { LinkButton } from "../LinkButton"
import { preventDefault } from "../../utils"
import { LoadingSpinner } from ".."

const useStyles = makeStyles((theme) => ({
  actions: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(1)}px`,
    fontSize: 14,
    marginRight: theme.spacing(-1),
    marginLeft: theme.spacing(-1),
  },
  buttonText: {
    fontWeight: "600",
    fontSize: 14,
  },
  menu: {
    marginTop: 50,
  },
}))

const CreatorActions = ({
  id,
  onClose,
  onSubmit,
  submitLabel,
  submitLoading,
  submitColor = "primary",
  extraSubmits,
  cancelLabel = <Trans>Cancel</Trans>,
  subject,
  customMobile,
  customDesktop,
  disableSubmit,
}) => {
  const classes = useStyles()

  const [showMenu, setShowMenu] = useState(false)
  const [showMenuEl, setShowMenuEl] = useState(null)

  const handleToggleMenu = ({ currentTarget }) => {
    setShowMenuEl(!showMenu ? currentTarget : null)
    setShowMenu(!showMenu)
  }

  return (
    <>
      <Hidden smUp>
        {customMobile}
        {!customMobile && (
          <Grid container direction="row" spacing={3}>
            <Grid item xs={6}>
              {onClose && (
                <Button
                  id={`${id}-cancel`}
                  variant="contained"
                  fullWidth
                  onClick={(event) => preventDefault(event, () => onClose(event, true))}
                  disabled={submitLoading}
                >
                  {cancelLabel}
                </Button>
              )}
            </Grid>
            <Grid item xs={6}>
              {!extraSubmits && onSubmit && (
                <Button
                  id={`${id}-submit`}
                  variant="contained"
                  color={submitColor}
                  fullWidth
                  onClick={onSubmit}
                  disabled={disableSubmit || submitLoading}
                >
                  {!submitLoading && (
                    <>{submitLabel || <Trans>Save {subject && String(subject).toLowerCase()}</Trans>}</>
                  )}
                  {submitLoading && <LoadingSpinner size="24px" delay={false} />}
                </Button>
              )}
              {extraSubmits && extraSubmits.length > 0 && (
                <>
                  <ButtonGroup variant="contained" fullWidth color={submitColor}>
                    <Button
                      id={`${id}-submit`}
                      onClick={onSubmit}
                      className={classes.buttonText}
                      disabled={disableSubmit || submitLoading}
                    >
                      {!submitLoading && (
                        <>{submitLabel || <Trans>Save {subject && String(subject).toLowerCase()}</Trans>}</>
                      )}
                      {submitLoading && <LoadingSpinner size="24px" delay={false} />}
                    </Button>
                    <Button
                      size="small"
                      onClick={handleToggleMenu}
                      className={classes.buttonText}
                      disabled={disableSubmit || submitLoading}
                    >
                      <ArrowDropDown />
                    </Button>
                  </ButtonGroup>
                  <Menu
                    anchorEl={showMenuEl}
                    keepMounted
                    open={showMenu}
                    onClose={handleToggleMenu}
                    className={classes.menu}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    {extraSubmits.map((submit, index) => (
                      <MenuItem key={index} onClick={submit.onSubmit}>
                        {submit.label}
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              )}
            </Grid>
          </Grid>
        )}
      </Hidden>

      <Hidden xsDown>
        {customDesktop}
        {!customDesktop && (
          <Box mr={-2}>
            <DialogActions className={classes.actions}>
              {onClose && (
                <Box mr={onSubmit ? 2 : 0}>
                  <LinkButton
                    id={`${id}-cancel`}
                    onClick={(event) => onClose(event, true)}
                    className={`${classes.button} ${classes.buttonText}`}
                    disabled={submitLoading}
                  >
                    {cancelLabel}
                  </LinkButton>
                </Box>
              )}
              {!extraSubmits && onSubmit && (
                <Button
                  id={`${id}-submit`}
                  variant="contained"
                  color={submitColor}
                  onClick={onSubmit}
                  className={classes.buttonText}
                  disabled={disableSubmit || submitLoading}
                >
                  {!submitLoading && <>{submitLabel || <>Save {subject && subject.toLowerCase()}</>}</>}
                  {submitLoading && <LoadingSpinner size="24px" delay={false} />}
                </Button>
              )}
              {extraSubmits && extraSubmits.length > 0 && (
                <>
                  <ButtonGroup variant="contained" color={submitColor}>
                    <Button
                      id={`${id}-submit`}
                      onClick={onSubmit}
                      className={classes.buttonText}
                      disabled={disableSubmit || submitLoading}
                    >
                      {!submitLoading && <>{submitLabel || <>Save {subject && subject.toLowerCase()}</>}</>}
                      {submitLoading && <LoadingSpinner size="24px" delay={false} />}
                    </Button>
                    <Button
                      size="small"
                      onClick={handleToggleMenu}
                      className={classes.buttonText}
                      disabled={disableSubmit || submitLoading}
                    >
                      <ArrowDropDown />
                    </Button>
                  </ButtonGroup>
                  <Menu
                    anchorEl={showMenuEl}
                    keepMounted
                    open={showMenu}
                    onClose={handleToggleMenu}
                    className={classes.menu}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    {extraSubmits.map((submit, index) => (
                      <MenuItem key={index} onClick={submit.onSubmit}>
                        {submit.label}
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              )}
            </DialogActions>
          </Box>
        )}
      </Hidden>
    </>
  )
}

export { CreatorActions }
