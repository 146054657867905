import { useMutation, gql } from "@apollo/client"
import { ADMINABLE_USERS_FIELDS } from "./fragments/adminableUserFieldsFragment"

const RESEND_INVITES_MUTATION = gql`
  mutation ResendInvites($ids: [ID!]!) {
    adminableUsers(ids: $ids) {
      resendInvites {
        ...AdminableUsersFields
      }
    }
  }
  ${ADMINABLE_USERS_FIELDS}
`

const useMutationResendInvites = (options) => useMutation(RESEND_INVITES_MUTATION, options)

export { useMutationResendInvites }
