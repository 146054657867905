import React from "react"

const sufixes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

const getBytes = ({ bytes, brackets, decimalPlaces = 1 }) => {
  if (!bytes) return ""
  let i = Math.floor(Math.log(bytes) / Math.log(1024))
  if (`${Math.round(bytes / 1024 ** i)}`.length > 3) i += 1
  return (
    <>
      {brackets && "["}
      {(bytes / 1024 ** i).toFixed(decimalPlaces)}
      {sufixes[i]}
      {brackets && "]"}
    </>
  )
}

const FormatBytes = ({ bytes, brackets, decimalPlaces = 1, className }) => (
  <span className={className}>{getBytes({ bytes, brackets, decimalPlaces })}</span>
)

export { FormatBytes }
