import { useQuery, useLazyQuery, gql } from "@apollo/client"

const REPORTJOB_FIELDS = `
  process {
    name
  }
  jobs {
    id
    location
    title
    availableFrom
    stepsUncompleted
    stepsCompleted
    stepsOverdue
    stepsSkipped
    status
  }
`

const REPORTJOB_QUERY = gql`
  query ReportJob($process: ID!, $locations: [ID!]!, $gt: ScheduleDateInput!, $lt: ScheduleDateInput) {
    reportJob(process: $process, locations: $locations, gt: $gt, lt: $lt)
    {
      ${REPORTJOB_FIELDS}
    } 
  }
`

const useQueryReportJob = (options) =>
  useQuery(REPORTJOB_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

const useLazyQueryReportJob = (options) =>
  useLazyQuery(REPORTJOB_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

export { useQueryReportJob, useLazyQueryReportJob }
