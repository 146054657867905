import React, { useEffect, useState } from "react"
import { produce } from "immer"
import { Box, Button, Chip, Collapse, IconButton, makeStyles, Tooltip } from "@material-ui/core"
import { t, Trans } from "@lingui/macro"
import { useAuth } from "../../services"
import { isScheduleValid, mapToIds, useMountEffect } from "../../utils"
import {
  GroupedAssignerDisplay,
  LocationsAssigner,
  ProcessScheduleAssigner,
  ProcessScheduleStatement,
  UsersGroupsAssigner,
} from "../Assigners"
import { FieldSectionHeading } from "../Headings"
import { FlexBox, RowBox } from "../Boxes"
import { AssignedLocations, Assignees, DeleteConfirmIconButton, Icon, usersGroupsEveryoneItem } from ".."

const useStyles = makeStyles((theme) => ({
  editDisplay: {
    opacity: ({ inactive, disabled }) => (inactive && !disabled ? 0.3 : 1),
  },
  display: {
    opacity: ({ inactive, disabled }) => (inactive || disabled ? 0.3 : 1),
  },
  name: {
    opacity: ({ inactive, disabled }) => (inactive && !disabled ? 0.3 : 1),
  },
  addScheduleButton: {
    marginRight: theme.spacing(0.5),
  },
  duplicateScheduleButton: {
    marginLeft: theme.spacing(0.5),
  },
  iconButtonIcon: {
    fontSize: 20,
  },
}))

const ProcessSchedule = ({
  schedule,
  subject,
  disabled,
  collapsible,
  expanded = true,
  onChange,
  onClick,
  onToggleExpanded,
  onAdd,
  onDuplicate,
  removable,
  onRemove,
}) => {
  const {
    settings: { locations: userLocations },
  } = useAuth()
  const [showAssigner] = useState(false)
  const [usersGroupsInitialPicked, setUsersGroupsInitialPicked] = useState(null)
  const [actionSettings, setActionSettings] = useState(null)
  const [item, setItem] = useState(schedule)
  const classes = useStyles({ inactive: item.inactive, disabled })

  useMountEffect(() => {
    if (schedule.hasEveryone) {
      setUsersGroupsInitialPicked([usersGroupsEveryoneItem])
    } else {
      const noUsersOrGroups = !schedule.users.length && !schedule.groups.length
      setUsersGroupsInitialPicked(noUsersOrGroups ? [] : [...schedule.users, ...schedule.groups])
    }

    const noActionSettings = !schedule.actionSettings?.users?.length && !schedule.actionSettings?.groups?.length
    setActionSettings(noActionSettings ? [] : [...schedule.actionSettings.users, ...schedule.actionSettings.groups])
  })

  useEffect(() => {
    if (item.name !== schedule.name) {
      setItem({ ...item, name: schedule.name })
    }
  }, [item, schedule.name])

  const handleLocationsPickedChanged = (picked) => {
    const newItem = produce(item, (draft) => {
      draft.locations = [...picked]
    })

    setItem(newItem)
    onChange && onChange(newItem)
  }

  const handleUsersGroupsPickedChanged = (picked) => {
    const newHasEveryone = picked.some((p) => p.__typename === "Everyone")

    const newItem = produce(item, (draft) => {
      draft.hasEveryone = newHasEveryone
      draft.users = newHasEveryone ? [] : [...picked.filter((p) => p.__typename === "User")]
      draft.groups = newHasEveryone ? [] : [...picked.filter((p) => p.__typename === "Group")]
    })

    setItem(newItem)
    onChange && onChange(newItem)
  }
  const handleActionSettingsChanged = (picked) => {
    const newHasEveryone = picked.some((p) => p.__typename === "Everyone")

    const newItem = produce(item, (draft) => {
      draft.actionSettings = {
        hasEveryone: newHasEveryone,
        users: newHasEveryone ? [] : [...picked.filter((p) => p.__typename === "User")],
        groups: newHasEveryone ? [] : [...picked.filter((p) => p.__typename === "Group")],
      }
    })

    setItem(newItem)
    onChange && onChange(newItem)
  }

  const handleScheduleChanged = (newValue) => {
    const newItem = produce(item, (draft) => {
      Object.assign(draft, newValue)
    })

    setItem(newItem)
    onChange && onChange(newItem)
  }

  const handleToggleExpanded = () => {
    onToggleExpanded && onToggleExpanded()
  }

  const handleClick = (event) => {
    onClick && onClick(event)
  }

  const handleAdd = () => {
    onAdd && onAdd()
  }

  const handleToggleInactive = () => {
    const newItem = produce(item, (draft) => {
      draft.inactive = !draft.inactive
    })

    setItem(newItem)
    onChange && onChange(newItem)
  }

  const handleDuplicate = () => {
    onDuplicate && onDuplicate()
  }

  const handleRemove = () => {
    onRemove && onRemove()
  }

  const { locations } = item
  const assignToDisabled = !locations?.length
  const multiUserLocation = userLocations.length > 1

  const countActionSettings = item.actionSettings?.users?.length + item.actionSettings?.groups?.length

  const isValid = isScheduleValid(item)

  const editDisplay = (
    <>
      <Box onClick={handleClick} mt={collapsible ? 1 : 0} className={classes.editDisplay}>
        <ProcessScheduleAssigner
          title={item.name ? t`Schedule - ${item.name}` : t`Schedule`}
          schedule={item}
          subject={subject}
          bottomBorder={false}
          onChange={handleScheduleChanged}
          disabled={disabled}
          cy="ProcessCreator-schedule"
        />
        {locations && multiUserLocation && (
          <LocationsAssigner
            initialPicked={locations}
            onPickedChanged={handleLocationsPickedChanged}
            topBorder={false}
            disabled={disabled}
            cy="ProcessCreator-locations"
          />
        )}
        {usersGroupsInitialPicked && (
          <UsersGroupsAssigner
            title={t`Assign to`}
            placeholder={assignToDisabled ? t`No locations selected` : t`Nobody selected`}
            allowSelf
            locations={mapToIds(locations)}
            topBorder={false}
            openPicker={showAssigner}
            initialPicked={usersGroupsInitialPicked}
            onPickedChanged={handleUsersGroupsPickedChanged}
            disabled={assignToDisabled || disabled}
            cy="ProcessCreator-to"
          />
        )}
        {actionSettings && (
          <UsersGroupsAssigner
            icon={<Icon name="action" />}
            title={t`Default action assignees`}
            placeholder={assignToDisabled ? t`No locations selected` : t`Nobody selected`}
            allowSelf
            allowAll={false}
            locations={mapToIds(locations)}
            topBorder={false}
            openPicker
            tooltip={t`Assign default staff for any actions raised when this job is run. These cannot be removed, but additional staff can still be assigned.`}
            initialPicked={actionSettings}
            onPickedChanged={handleActionSettingsChanged}
            disabled={assignToDisabled || disabled}
            cy="ProcessCreator-defaultActionAssignees"
          />
        )}
      </Box>
      {isValid && !collapsible && (
        <RowBox mt={3}>
          <Button
            variant="contained"
            fullWidth
            data-cy="Button-add-schedule"
            onClick={handleAdd}
            className={classes.addScheduleButton}
            disabled={disabled}
          >
            <Icon name="add" /> <Trans>Add additional schedule</Trans>
          </Button>
          {item.inactive && (
            <Button
              variant="contained"
              fullWidth
              data-cy="Button-add-schedule"
              onClick={handleToggleInactive}
              className={classes.enableScheduleButton}
              disabled={disabled}
            >
              <Icon name="unpause" />
              <Box ml={0.5}>Enable this schedule</Box>
            </Button>
          )}
          <Button
            variant="contained"
            fullWidth
            data-cy="Button-add-schedule"
            onClick={handleDuplicate}
            className={classes.duplicateScheduleButton}
            disabled={disabled}
          >
            <Icon name="copy" />
            <Box ml={0.5}>
              <Trans>Duplicate this schedule</Trans>
            </Box>
          </Button>
        </RowBox>
      )}
    </>
  )

  const collapsedDisplay = (
    <GroupedAssignerDisplay pl={2} py={1} onClick={handleToggleExpanded} className={classes.display}>
      <RowBox mb={1} title={t`Schedule`}>
        <Icon name="schedule" />
        <Box ml={1.5} title={t`Schedule`}>
          <ProcessScheduleStatement schedule={item} subject={subject} />
        </Box>
      </RowBox>
      {multiUserLocation && (
        <RowBox mb={1} title={t`Locations`}>
          <Icon name="location" />
          <Box ml={1.5} title={t`Locations`}>
            <AssignedLocations locations={item.locations} />
          </Box>
        </RowBox>
      )}
      <RowBox title={t`Assign to`}>
        <Icon name="people" />
        <Box ml={1.5} title={t`Assign to`}>
          <Assignees users={item.users} groups={item.groups} />
        </Box>
      </RowBox>
      <RowBox title={t`Default action assignees`} mt={1}>
        <Icon name="action" />
        <Box ml={1.5} title={t`Default action assignees`}>
          {countActionSettings > 0 ? (
            <Assignees users={item?.actionSettings?.users} groups={item?.actionSettings?.groups} />
          ) : (
            <span>
              <Trans>No default action assignees</Trans>
            </span>
          )}
        </Box>
      </RowBox>
    </GroupedAssignerDisplay>
  )

  return (
    <>
      {collapsible && (
        <RowBox>
          <FlexBox flexGrow={1} className={classes.name}>
            <FieldSectionHeading mt={1}>
              {item.inactive && "(Paused) "}
              {item.name}
            </FieldSectionHeading>
          </FlexBox>
          {!isValid && (
            <Box ml={2}>
              <Chip label="Incomplete" color="secondary" size="small" icon={<Icon name="edit" />} />
            </Box>
          )}
          {isValid && (
            <>
              <Box ml={1}>
                <Tooltip title={item.inactive ? t`Enable this schedule` : t`Pause this schedule`}>
                  <IconButton size="small" onClick={handleToggleInactive} disabled={disabled}>
                    <Icon name={item.inactive ? "unpause" : "pause"} className={classes.iconButtonIcon} />
                  </IconButton>
                </Tooltip>
              </Box>

              <Box ml={1}>
                <Tooltip title={t`Duplicate this schedule`}>
                  <IconButton size="small" onClick={handleDuplicate} disabled={disabled}>
                    <Icon name="copy" className={classes.iconButtonIcon} />
                  </IconButton>
                </Tooltip>
              </Box>
            </>
          )}
          {removable && (
            <Box ml={1}>
              <Tooltip title={isValid && !item.inactive ? t`Pause this schedule first` : t`Remove this schedule`}>
                <Box>
                  <DeleteConfirmIconButton onDelete={handleRemove} disabled={(disabled || !item.inactive) && isValid} />
                </Box>
              </Tooltip>
            </Box>
          )}
        </RowBox>
      )}

      <Collapse in={expanded}>{editDisplay}</Collapse>

      <Collapse in={!expanded}>{collapsedDisplay}</Collapse>
    </>
  )
}

export default ProcessSchedule
