import { useMutation, gql } from "@apollo/client"

const DELETE_USER_TRAINING_MUTATION = gql`
  mutation DeleteUserTraining($id: ID!) {
    deleteUserTraining(id: $id)
  }
`

const useMutationDeleteUserTraining = () => useMutation(DELETE_USER_TRAINING_MUTATION)

export { useMutationDeleteUserTraining }
