import { useQuery, gql } from "@apollo/client"
import { CATEGORY_FIELDS } from "../knowledge"

const TEMPLATE_CATEGORIES_QUERY = gql`
  query TemplateCategories {
    templateCategories {
      ...CategoryFields
    }
  }
  ${CATEGORY_FIELDS}
`

const useQueryTemplateCategories = () =>
  useQuery(TEMPLATE_CATEGORIES_QUERY, {
    fetchPolicy: "cache-and-network",
  })

export { useQueryTemplateCategories }
