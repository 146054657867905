import { useMutation, gql } from "@apollo/client"

const DELETE_ACCREDITATION_MUTATION = gql`
  mutation DeleteAccreditation($id: ID!) {
    deleteAccreditation(id: $id)
  }
`

const useMutationDeleteAccreditation = () => useMutation(DELETE_ACCREDITATION_MUTATION)

export { useMutationDeleteAccreditation }
