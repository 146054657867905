import React from "react"
import AvatarGroup from "@material-ui/lab/AvatarGroup"
import { makeStyles } from "@material-ui/core"
import { t } from "@lingui/macro"
import { Avatar } from ".."
import { Icon } from "../Icon"

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.avatar.plus.background,
    fontSize: 14,
    fontWeight: "600",
  },
}))

const AssigneeAvatars = ({ users: usersInitial, groups: groupsInitial, limit = 2 }) => {
  const classes = useStyles()
  const users = usersInitial || []
  const groups = groupsInitial || []
  const count = users.length + groups.length

  const title = [...groups, ...users].map((item) => item.name || item.fullName).join(", ")

  return (
    <>
      <AvatarGroup max={Math.max(limit, 2)} spacing="small" classes={classes} title={title || t`All staff`}>
        {groups && groups.map((group) => <Avatar key={group.id} icon={<Icon name="group" />} />)}
        {users && users.map((user) => <Avatar key={user.id} {...user} />)}
        {!count && <Avatar icon={<Icon name="everyone" />} />}
      </AvatarGroup>
    </>
  )
}

export { AssigneeAvatars }
