import { useMutation, gql } from "@apollo/client"

const DELETELABEL_TEMPLATE_MUTATION = gql`
  mutation DeleteLabelTemplate($id: ID!) {
    labelTemplate(id: $id) {
      delete
    }
  }
`

const useMutationDeleteLabelTemplate = () => useMutation(DELETELABEL_TEMPLATE_MUTATION)

export { useMutationDeleteLabelTemplate }
