import React from "react"
import { Checkbox, FormControlLabel } from "@material-ui/core"
import { ColumnBox } from "../Boxes"

const CheckboxList = ({ options, onChange }) => {
  const handleChange = (event, option) => {
    onChange && onChange(option, event.target.checked)
  }

  return (
    <ColumnBox>
      {options.map((option) => (
        <FormControlLabel
          key={option.value}
          control={
            <Checkbox
              defaultChecked={option.checked}
              onChange={(event) => handleChange(event, option)}
              color="default"
            />
          }
          label={option.label}
        />
      ))}
    </ColumnBox>
  )
}

export { CheckboxList }
