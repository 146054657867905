import React, { forwardRef, memo } from "react"
import { Box } from "@material-ui/core"

const FlexBox = forwardRef(function FlexBox({ alignItems = "center", children, ...rest }, ref) {
  return (
    <Box display="flex" alignItems={alignItems} {...rest} ref={ref}>
      {children}
    </Box>
  )
})

const FlexBoxMemo = memo(FlexBox)

export { FlexBoxMemo as FlexBox }
