import { PROCESS_STEP_LOGIC_OUTCOME, PROCESS_STEP_LOGIC_RANGE, PROCESS_STEP_LOGIC_RESPONSE_TYPES } from "../data"

function createSuccess() {
  return {
    hasRequirements: false,
    messages: [],
  }
}

function checkLogicOutcomesSatisfied(rule, step, newResponse) {
  if (!rule) {
    return createSuccess()
  }

  if (!PROCESS_STEP_LOGIC_RESPONSE_TYPES.includes(step.responseType)) {
    return createSuccess()
  }

  let ruleMatch = false

  const responseAsNumber = parseFloat(newResponse)

  if (Number.isNaN(responseAsNumber)) {
    return createSuccess()
  }

  switch (rule.range) {
    case PROCESS_STEP_LOGIC_RANGE.BETWEEN:
      if (rule.lowerValue > rule.upperValue) {
        ruleMatch = responseAsNumber >= rule.upperValue && responseAsNumber <= rule.lowerValue
      } else {
        ruleMatch = responseAsNumber >= rule.lowerValue && responseAsNumber <= rule.upperValue
      }
      break
    case PROCESS_STEP_LOGIC_RANGE.NOT_BETWEEN:
      if (rule.lowerValue > rule.upperValue) {
        ruleMatch = responseAsNumber < rule.upperValue || responseAsNumber > rule.lowerValue
      } else {
        ruleMatch = responseAsNumber < rule.lowerValue || responseAsNumber > rule.upperValue
      }
      break
    case PROCESS_STEP_LOGIC_RANGE.EQUAL:
      ruleMatch = responseAsNumber === rule.lowerValue
      break
    case PROCESS_STEP_LOGIC_RANGE.NOT_EQUAL:
      ruleMatch = responseAsNumber !== rule.lowerValue
      break
    case PROCESS_STEP_LOGIC_RANGE.GREATER_THAN:
      ruleMatch = responseAsNumber > rule.lowerValue
      break
    case PROCESS_STEP_LOGIC_RANGE.GREATER_OR_EQUAL:
      ruleMatch = responseAsNumber >= rule.lowerValue
      break
    case PROCESS_STEP_LOGIC_RANGE.LESS_THAN:
      ruleMatch = responseAsNumber < rule.lowerValue
      break
    case PROCESS_STEP_LOGIC_RANGE.LESS_OR_EQUAL:
      ruleMatch = responseAsNumber <= rule.lowerValue
      break
    default:
      throw new Error(`Unsupported logic range: ${rule.range}`)
  }

  if (!ruleMatch) {
    return createSuccess()
  }

  // return status of outcomes
  const outcomes = rule.outcomes.map((outcome) => {
    if (outcome.type === PROCESS_STEP_LOGIC_OUTCOME.ACTION) {
      if (step.unresolvedActions?.length > 0) {
        return { hasRequirement: false }
      }
      return {
        hasRequirement: true,
        message: "An action needs to be raised for this step",
        type: outcome.type,
      }
    }
    if (outcome.type === PROCESS_STEP_LOGIC_OUTCOME.NOTE) {
      if (step.notes?.length > 0) {
        return { hasRequirement: false }
      }
      return {
        hasRequirement: true,
        message: "A note needs to be added for this step",
        type: outcome.type,
      }
    }
    if (outcome.type === PROCESS_STEP_LOGIC_OUTCOME.NOTE_TEXT) {
      if (step.notes?.length > 0 && step.notes?.some((note) => note.text != null && note.text.length > 0)) {
        return { hasRequirement: false }
      }
      return {
        hasRequirement: true,
        message: "A text note needs to be added for this step",
        type: outcome.type,
      }
    }
    if (outcome.type === PROCESS_STEP_LOGIC_OUTCOME.NOTE_IMAGE) {
      if (
        step.notes?.length > 0 &&
        step.notes?.some(
          (note) => note.uploads.length > 0 && note.uploads.some((upload) => upload.fileGroup === "image")
        )
      ) {
        return { hasRequirement: false }
      }
      return {
        hasRequirement: true,
        message: "An image note needs to be added for this step",
        type: outcome.type,
      }
    }
    if (outcome.type === PROCESS_STEP_LOGIC_OUTCOME.NOTE_FILE) {
      if (step.notes?.length > 0 && step.notes?.some((note) => note.uploads.length > 0)) {
        return { hasRequirement: false }
      }
      return {
        hasRequirement: true,
        message: "An file note needs to be added for this step",
        type: outcome.type,
      }
    }
    throw new Error(`Unsupported logic outcome: ${outcome.type}`)
  })

  return {
    hasRequirements: outcomes.some((outcome) => outcome.hasRequirement),
    messages: outcomes.map((outcome) => outcome.message).filter((message) => message),
    types: outcomes.map((outcome) => outcome.type).filter((type) => type),
  }
}

function checkLogic(step, newResponse) {
  const result = {
    hasRequirements: false,
    messages: [],
    types: [],
  }

  if (!step.logic || step.logic.length === 0) {
    return result
  }

  if (!PROCESS_STEP_LOGIC_RESPONSE_TYPES.includes(step.responseType)) {
    return result
  }

  for (const rule of step.logic) {
    const { hasRequirements, messages, types } = checkLogicOutcomesSatisfied(rule, step, newResponse)

    if (hasRequirements) {
      result.hasRequirements = true
      result.messages.push(...messages)
      result.types.push(...types)
    }
  }

  return result
}

export { checkLogic }
