import React from "react"
import { Tab } from "@material-ui/core"
import { NavLink } from "react-router-dom"

const NavLinkTab = ({ value, base, filters = "", fullWidth = true, ...rest }) => (
  <Tab
    component={NavLink}
    to={`${base}${value}${filters}`}
    value={value}
    textColor="primary"
    fullWidth={fullWidth}
    style={{ minWidth: 130 }}
    {...rest}
  />
)

export { NavLinkTab }
