import React from "react"
import { ListItem } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 0,
    marginLeft: 0,
    marginBottom: theme.spacing(1),
    padding: 0,
    "&:last-child": {
      marginBottom: 0,
    },
  },
}))

const PickerListItem = ({ item, children, ...rest }) => {
  const classes = useStyles()

  return (
    <ListItem classes={classes} {...rest}>
      {children}
    </ListItem>
  )
}

export { PickerListItem }
