import { useMutation, gql } from "@apollo/client"
import { KNOWLEDGE_FIELDS } from "./useQueryKnowledges"

const CREATEKNOWLEDGE_MUTATION = gql`
  mutation CreateKnowledge($input: KnowledgeInput!) {
    createKnowledge: createKnowledgeV2(input: $input) {
      ...KnowledgeFields
    }
  }
  ${KNOWLEDGE_FIELDS}
`

const useMutationCreateKnowledge = () => useMutation(CREATEKNOWLEDGE_MUTATION)

export { useMutationCreateKnowledge }
