import React, { useState } from "react"
import { Button, Box } from "@material-ui/core"
import { Add as AddIcon } from "@material-ui/icons"
import { Trans } from "@lingui/macro"
import { RequirePermissions, FloatingActionButton } from ".."
import { useAuth } from "../../services"

const DesktopCreateProcessButton = ({ onCreateClick }) => {
  const { permissionGroups } = useAuth()

  const handleCreateClick = (type) => {
    onCreateClick(type)
  }

  return (
    <RequirePermissions requires={permissionGroups.createJobs}>
      <Box display="flex" flexDirection="row">
        <RequirePermissions requires={["process_create"]}>
          <Box ml={1}>
            <Button
              onClick={() => handleCreateClick("audit")}
              variant="contained"
              color="primary"
              data-cy="Button-create-audit"
            >
              <Trans>Create job</Trans>
            </Button>
          </Box>
        </RequirePermissions>
      </Box>
    </RequirePermissions>
  )
}

const MobileCreateProcessButton = ({ onCreateClick }) => {
  const { permissionGroups } = useAuth()
  const [openSpeedDial, setOpenSpeedDial] = useState(false)

  const handleCreateClick = (type) => {
    handleToggleSpeedDial()
    onCreateClick(type)
  }

  const handleToggleSpeedDial = () => {
    setOpenSpeedDial(!openSpeedDial)
  }

  return (
    <RequirePermissions requires={permissionGroups.createJobs}>
      <FloatingActionButton
        color="primary"
        aria-label="Create a new job template"
        onClick={() => handleCreateClick("audit")}
        data-cy="FloatingActionButton-create"
      >
        <AddIcon />
      </FloatingActionButton>
    </RequirePermissions>
  )
}

export { DesktopCreateProcessButton, MobileCreateProcessButton }
