import { useMutation, gql } from "@apollo/client"
import { LABEL_CATEGORIES_FIELDS } from "./useQueryLabelCategories"

const CREATELABEL_CATEGORY_MUTATION = gql`
  mutation CreateLabelCategory(
  $input: LabelCategoryInput!, 
  ) {
    createLabelCategory(
    input: $input
    ) {
      ${LABEL_CATEGORIES_FIELDS}
    } 
  }
`

const useMutationCreateLabelCategory = () => useMutation(CREATELABEL_CATEGORY_MUTATION)

export { useMutationCreateLabelCategory }
