import { useMutation, gql } from "@apollo/client"

const DELETE_DEVICE_MUTATION = gql`
  mutation DeleteDevice($id: ID!) {
    deleteDevice(id: $id)
  }
`

const useMutationDeleteDevice = () => useMutation(DELETE_DEVICE_MUTATION)

export { useMutationDeleteDevice }
