import React, { useState, useEffect } from "react"
import {
  Avatar,
  Box,
  Collapse,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  TextField,
} from "@material-ui/core"
import { Security } from "@material-ui/icons"
import { useLazyQueryCheckPasswordComplexity } from "../../data"
import { LoadingSpinner } from "../LoadingSpinner"

const useStyles = makeStyles((theme) => ({
  input: {
    width: 40,
    marginRight: theme.spacing(1),
  },
  errorText: {
    fontSize: 8,
    color: theme.palette.error.main,
  },
  errorAvatar: {
    backgroundColor: theme.palette.error.light,
  },
}))

const PasswordInput = ({ variant = "outlined", margin = "normal", value, onChange, ...rest }) => {
  const classes = useStyles()
  const [checkPasswordComplexity, { data, loading }] = useLazyQueryCheckPasswordComplexity()
  const [password, setPassword] = useState("")
  const [errors, setErrors] = useState(null)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (password) checkPasswordComplexity({ variables: { password } })
    }, 600)
    return () => clearTimeout(timer)
  }, [checkPasswordComplexity, password])

  useEffect(() => {
    if (data) onChange(data.checkPasswordComplexity.passed ? password : "")
  }, [data, onChange, password])

  useEffect(() => {
    if (data) setErrors(data.checkPasswordComplexity.passed ? null : data.checkPasswordComplexity.errors)
  }, [data])

  const handlePasswordChange = async ({ target: { value: newValue } }) => {
    setPassword(newValue)
  }

  return (
    <Box>
      <TextField
        variant={variant}
        margin={margin}
        type="password"
        value={password}
        onChange={handlePasswordChange}
        {...rest}
      />

      <Collapse in={loading}>
        <Box display="flex" justifyContent="center">
          <LoadingSpinner size={60} />
        </Box>
      </Collapse>
      <Collapse in={errors !== null} timeout={1000}>
        <List dense disablePadding>
          {errors &&
            errors.map((error, index) => (
              <ListItem key={index} className={classes.error} disableGutters>
                <ListItemAvatar>
                  <Avatar variant="rounded" className={classes.errorAvatar}>
                    <Security />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={error} className={classes.errorText} />
              </ListItem>
            ))}
        </List>
      </Collapse>
    </Box>
  )
}

export { PasswordInput }
