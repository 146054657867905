import React, { useState } from "react"
import { Tooltip, makeStyles } from "@material-ui/core"
import { Icon } from "../Icon"

const useStyles = makeStyles(() => ({
  inner: {
    height: 20,
    width: 20,
  },
}))

const HintTooltip = ({ hint, ...props }) => {
  const classes = useStyles()
  const [open, setTooltip] = useState(false)

  const handleOpenTooltip = () => {
    setTooltip(true)
  }

  return (
    <Tooltip open={open} onClose={() => setTooltip(false)} title={hint} {...props}>
      <div className={classes.inner}>
        <Icon name="information" fontSize="small" onClick={handleOpenTooltip} />
      </div>
    </Tooltip>
  )
}
export { HintTooltip }
