import { useMutation, gql } from "@apollo/client"

const REGISTER_DEVICE_MUTATION = gql`
  mutation RegisterDevice($name: String!, $os: String!, $year: Int, $type: Int!, $installationId: String!) {
    registerDevice(name: $name, os: $os, year: $year, type: $type, installationId: $installationId) {
      passcode
      expiresAt
    }
  }
`

const useMutationRegisterDevice = () =>
  useMutation(REGISTER_DEVICE_MUTATION, {
    errorPolicy: "all",
  })

export { useMutationRegisterDevice }
