import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Box, Collapse, Typography } from "@material-ui/core"
import { LoadingSpinner } from "../LoadingSpinner"
import { FlexBox } from "../Boxes"

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "24px",
    paddingTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  subNavigation: {
    padding: ({ noPadding }) => (noPadding ? "0 16px" : "0 16px 21px 16px"),
    backgroundColor: theme.palette.background.shaded,
    position: "sticky",
    borderRadius: 8,
  },
}))

const SubNavigation = ({
  title,
  children,
  className,
  titleClassName,
  mr = 6,
  mb = 2,
  loading = false,
  noPadding = false,
  ...rest
}) => {
  const classes = useStyles({ noPadding })

  return (
    <Box className={`${classes.subNavigation} ${className}`} mr={mr} mb={mb} {...rest}>
      {!!title && (
        <Typography variant="h1" className={`${classes.title} ${titleClassName}`}>
          {title}
        </Typography>
      )}
      {loading && (
        <FlexBox justifyContent="center" py={2}>
          <LoadingSpinner size={45} delay={false} />
        </FlexBox>
      )}
      <Collapse in={!loading}>{!loading && children}</Collapse>
    </Box>
  )
}

export { SubNavigation }
