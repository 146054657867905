import React from "react"
import { List } from "@material-ui/core"
import { SortableContainer, SortableElement } from "react-sortable-hoc"
import { ProcessStepCreator } from "./ProcessStepCreator"

const SortableProcessStepCreator = SortableElement((props) => <ProcessStepCreator {...props} />)

const SortableProcessStepListContainer = SortableContainer(
  ({
    items,
    onAddStep,
    onUpdateStep,
    onRemoveStep,
    onCloneStep,
    onUploadingStateChange,
    disabled,
    processType,
    locations,
  }) => (
    <List component="div">
      {items.map((item, index) => (
        <SortableProcessStepCreator
          key={index}
          index={index}
          stepIndex={index}
          step={item}
          locations={locations}
          onAdd={onAddStep}
          onUpdate={onUpdateStep}
          onRemove={onRemoveStep}
          onClone={onCloneStep}
          onUploadingStateChange={onUploadingStateChange}
          disabled={disabled}
          stepDisabled={disabled}
          processType={processType}
        />
      ))}
    </List>
  )
)

export { SortableProcessStepListContainer }
