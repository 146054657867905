/* eslint-disable */
import React, { Component, Children } from "react"
import PropTypes from "prop-types"
import { NavLink } from "react-router-dom"
import { iterateMentionsMarkup, readConfigFromChildren } from "./utils"
import { useJobUtils } from "../../utils"
import { Box, makeStyles, Tooltip } from "@material-ui/core"
import { Icon } from "../Icon"
import { ColumnBox, RowBox } from "../Boxes"
import KnowledgePeekMention from "./KnowledgePeekMention"

const _generateComponentKey = (usedKeys, id) => {
  if (!usedKeys.hasOwnProperty(id)) {
    usedKeys[id] = 0
  } else {
    usedKeys[id]++
  }
  return `${id}_${usedKeys[id]}`
}

const styles = {
  whiteSpace: "pre-wrap",
}

const useStyles = makeStyles((theme) => ({
  localLink: {
    color: theme.palette.primary.main,
  },
  externalLink: {
    display: "inline-block",
  },
  externalLinkIcon: {
    display: "inline-block",
    verticalAlign: "middle",
    width: 16,
    height: 16,
    marginLeft: 2,
    marginTop: -3,
  },
  externalLinkTooltipUrl: {
    color: theme.palette.text.faint,
    maxWidth: 250,
    whiteSpace: "pre-wrap",
  },
}))

const MentionComponentForMatch = ({ id, display, mentionChildIndex, ...props }) => {
  const classes = useStyles()
  const { canNavigateToJob, getNavigateToLinkForTypeLocationDayAndID } = useJobUtils()
  const idParts = id.split(":")
  const idValue = idParts[1]

  const handleExternalClick = (event) => {
    event.stopPropagation()
    return true
  }

  let link
  switch (idParts[0]) {
    case "Knowledge":
    case "KnowledgeV2":
      return <KnowledgePeekMention id={idValue}>{display}</KnowledgePeekMention>
    case "MentionableProcessJob":
      link =
        canNavigateToJob({ location: idParts[3] }) &&
        getNavigateToLinkForTypeLocationDayAndID("process", idParts[2], idParts[3], idValue)
      break
    case "MentionableTask":
      link =
        canNavigateToJob({ location: idParts[3] }) &&
        getNavigateToLinkForTypeLocationDayAndID("task", idParts[2], idParts[3], idValue)
      break
    default:
      if (id?.startsWith("https://")) {
        return (
          <Tooltip
            title={
              <RowBox>
                <ColumnBox>
                  <Box>Visit external site</Box>
                  <Box className={classes.externalLinkTooltipUrl}>{id}</Box>
                </ColumnBox>
              </RowBox>
            }
          >
            <a target="_blank" href={id} className={classes.externalLink} onClick={handleExternalClick}>
              <span>{display}</span>
              <Icon name="external-link" className={classes.externalLinkIcon} />
            </a>
          </Tooltip>
        )
      }
      if (id?.startsWith("mailto:")) {
        return (
          <Tooltip
            title={
              <RowBox>
                <ColumnBox>
                  <Box>Open mail client</Box>
                  <Box className={classes.externalLinkTooltipUrl}>{id}</Box>
                </ColumnBox>
              </RowBox>
            }
          >
            <a target="_blank" href={id} className={classes.externalLink} onClick={handleExternalClick}>
              <Icon name="email" className={classes.externalLinkIcon} />
              <span>{display}</span>
            </a>
          </Tooltip>
        )
      }
  }

  const newProps = { id, display, key: id }
  const child = Children.toArray(props.children)[mentionChildIndex]
  const clone = React.cloneElement(child, newProps)

  if (link)
    return (
      <NavLink key={id} to={link} className={classes.localLink}>
        {clone}
      </NavLink>
    )
  return clone
}

class MentionHighlighter extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  }

  static defaultProps = {
    value: "",
  }

  constructor() {
    super(...arguments)

    this.state = { lastPosition: {} }
  }

  render() {
    const { value, children } = this.props
    const config = readConfigFromChildren(children)

    const resultComponents = []
    const componentKeys = {}

    // start by appending directly to the resultComponents
    const components = resultComponents
    let substringComponentKey = 0

    const textIteratee = (substr, index, indexInPlainText) => {
      components.push(this.renderSubstring(substr, substringComponentKey))
      substringComponentKey++
    }

    const mentionIteratee = (markup, index, indexInPlainText, id, display, mentionChildIndex, lastMentionEndIndex) => {
      // generate a component key based on the id
      const key = _generateComponentKey(componentKeys, id)
      components.push(this.getMentionComponentForMatch(id, display, mentionChildIndex, key))
    }

    iterateMentionsMarkup(value, config, mentionIteratee, textIteratee)

    // append a span containing a space, to ensure the last text line has the correct height
    components.push(" ")

    if (components !== resultComponents) {
      // if a caret component is to be rendered, add all components that followed as its children
      resultComponents.push(this.renderHighlighterCaret(components))
    }

    return <div style={styles}>{resultComponents}</div>
  }

  renderSubstring(string, key) {
    // set substring span to hidden, so that Emojis are not shown double in Mobile Safari
    return <span key={key}>{string}</span>
  }

  // Returns a clone of the Mention child applicable for the specified type to be rendered inside the highlighter
  getMentionComponentForMatch(id, display, mentionChildIndex, key) {
    return (
      <MentionComponentForMatch
        id={id}
        display={display}
        mentionChildIndex={mentionChildIndex}
        key={key}
        {...this.props}
      />
    )
  }

  // Renders an component to be inserted in the highlighter at the current caret position
  renderHighlighterCaret(children) {
    return (
      <span
        {...this.props.style("caret")}
        ref={(el) => {
          this.caretRef = el
        }}
        key="caret"
      >
        {children}
      </span>
    )
  }
}

export default MentionHighlighter
