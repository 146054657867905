import { gql, useMutation } from "@apollo/client"
import { ASSET_FIELDS } from "./fragments/assetFieldsFragment"
import { toId } from "../../utils"
import { ASSETS_COUNT_QUERY } from "./useQueryAssets"

const CREATE_ASSET_MUTATION = gql`
  mutation CreateAsset($input: AssetMutationInput!) {
    createAsset(input: $input) {
      ...AssetFields
    }
  }
  ${ASSET_FIELDS}
`

const UPDATE_ASSET_MUTATION = gql`
  mutation UpdateAsset($id: ID!, $input: AssetMutationInput!) {
    asset(id: $id) {
      update(input: $input) {
        ...AssetFields
      }
    }
  }
  ${ASSET_FIELDS}
`
const DELETE_ASSET_MUTATION = gql`
  mutation DeleteAsset($id: ID!) {
    asset(id: $id) {
      delete
    }
  }
`

const ASSET_ADD_NOTE_MUTATION = gql`
  mutation AssetMutationAddNote($id: ID!, $input: AssetAddNoteInput!) {
    asset(id: $id) {
      addNote(input: $input) {
        ...AssetFields
      }
    }
  }
  ${ASSET_FIELDS}
`

const ASSET_DELETE_NOTE_MUTATION = gql`
  mutation AssetDeleteNote($id: ID!, $note: ID!) {
    asset(id: $id) {
      note(id: $note) {
        delete
      }
    }
  }
`

const useMutationCreateAsset = (options) =>
  useMutation(CREATE_ASSET_MUTATION, { ...options, refetchQueries: [ASSETS_COUNT_QUERY] })
const useMutationUpdateAsset = (options) => useMutation(UPDATE_ASSET_MUTATION, options)
const useMutationDeleteAsset = (options) =>
  useMutation(DELETE_ASSET_MUTATION, {
    ...options,
    refetchQueries: [ASSETS_COUNT_QUERY],
    update(cache, { errors }, { variables: { id } }) {
      if (errors) {
        return
      }
      const normalizedId = cache.identify({ id: toId(id), __typename: "Asset" })
      cache.evict({ id: normalizedId })
      cache.gc()
    },
  })
const useMutationAssetAddNote = (options) => useMutation(ASSET_ADD_NOTE_MUTATION, options)
const useMutationAssetDeleteNote = (options) => useMutation(ASSET_DELETE_NOTE_MUTATION, options)

export {
  useMutationCreateAsset,
  useMutationUpdateAsset,
  useMutationDeleteAsset,
  useMutationAssetAddNote,
  useMutationAssetDeleteNote,
}
