import React from "react"
import { useQueryGroups } from "../../data"
import { GroupOutlinedSelect } from "../OutlinedSelect"
import { useMutationGroupCreateAttribute, useMutationGroupDeleteAttribute } from "../../data/groups/useMutationGroup"
import { IntegrationMappingConfiguration } from "./IntegrationMappingConfiguration"

const IntegrationGroupConfiguration = ({ integration }) => {
  const { data } = useQueryGroups()
  const [addAttribute, { loading }] = useMutationGroupCreateAttribute()
  const [deleteAttribute] = useMutationGroupDeleteAttribute()

  return (
    <IntegrationMappingConfiguration
      integration={integration}
      data={data?.groups || []}
      source="group"
      label="Area of work"
      plural="areas of work"
      addAttribute={addAttribute}
      deleteAttribute={deleteAttribute}
      components={{
        add: {
          Destination: ({ onChange, value }) => (
            <GroupOutlinedSelect
              label="Area of work"
              value={value}
              hasAll={false}
              onChange={(event) => onChange(event.target.value)}
              fullWidth
              required
              disabled={loading}
            />
          ),
        },
      }}
    />
  )
}

export { IntegrationGroupConfiguration }
