import { useMutation, gql } from "@apollo/client"
import moment from "moment"
import { v4 as uuid } from "uuid"
import { toId } from "../../utils"
import { makeOptimisticUserFromPrincipal } from "../makeOptimistic"
import { ACTION_FIELDS } from "./fragments/actionFieldsFragment"

const CREATE_JOB_ACTION_MUTATION = gql`
  mutation CreateJobAction($input: JobActionInput!) {
    createJobAction(input: $input) {
      ...ActionFields
    }
  }
  ${ACTION_FIELDS}
`

const CREATE_LOCATION_ACTION_MUTATION = gql`
  mutation CreateLocationAction($input: LocationActionInput!) {
    createLocationAction(input: $input) {
      ...ActionFields
    }
  }
  ${ACTION_FIELDS}
`

const makeCreateActionOptimisticResponse = (dataPropName, { action: { location, users, ...action }, principal }) => ({
  __typename: "Mutation",
  [dataPropName]: {
    __typename: "Action",
    ...action,
    id: uuid(),
    author: makeOptimisticUserFromPrincipal(principal),
    location: {
      __typename: "Location",
      id: toId(location),
      name: "...",
    },
    users: users.map((user) => ({
      __typename: "User",
      id: toId(user),
      fullName: "...",
    })),
    hasSentConfirms: false,
    comments: [],
    confirmed: [],
    unconfirmed: [],
    createdAt: moment().format(),
    updatedAt: moment().format(),
  },
})

const useMutationCreateJobAction = () => useMutation(CREATE_JOB_ACTION_MUTATION)

const useMutationCreateLocationAction = () => useMutation(CREATE_LOCATION_ACTION_MUTATION)

export { useMutationCreateJobAction, useMutationCreateLocationAction, makeCreateActionOptimisticResponse }
