import React, { useState, useEffect } from "react"
import { Redirect, useHistory } from "react-router-dom"
import { Button, TextField, Typography, Container, Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import QueryString from "query-string"
import { useApolloClient } from "@apollo/client"
import { Trans, t } from "@lingui/macro"
import {
  LoadingSpinner,
  LogoFull,
  NavLinkItem,
  Icon,
  PaperBox,
  TextDivider,
  LinkButton,
  RowBox,
} from "../../components"
import { useAuth } from "../../services"
import { useFormUtils, useLocalStorage } from "../../utils"
import { PublicContainer } from "./PublicContainer"

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 300,
    padding: 0,
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    marginBottom: theme.spacing(5),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  external: {
    marginBottom: theme.spacing(2),
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.buttons.light.background,
    width: 300,
  },
  externalLabel: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  externalLogo: {
    width: 18,
    height: 18,
    marginRight: theme.spacing(1),
  },
  more: {
    color: theme.palette.text.primary,
    width: "100%",
    textAlign: "center",
    fontWeight: "normal",
  },
}))

const Login = () => {
  const classes = useStyles()
  const history = useHistory()
  const auth = useAuth(useApolloClient())
  const { isValid } = useFormUtils()
  const [storedIdp, , removeIdp] = useLocalStorage("idp")

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [loadingGoogle, setLoadingGoogle] = useState(false)
  const [loadingIdp, setLoadingIdp] = useState(false)
  const [more, setMore] = useState(false)

  // reason from qs
  const qs = QueryString.parse(window.location.search)

  useEffect(() => {
    if (!error) {
      if (["expired", "idle"].includes(qs.reason))
        setError(`Your session has expired${qs.reason === "idle" ? " due to inactivity" : ""}`)
      else if (qs.reason === "unauth") setError("Authentication required")
    }
  }, [qs, error, setError])

  const handleSubmit = async (evt) => {
    evt.preventDefault()
    setLoading(true)
    await auth.login({ type: "password", credentials: { username, password } }).catch((err) => {
      setError(err.message)
      setLoading(false)
    })
  }

  const handleLoginWithGoogle = async () => {
    try {
      setLoadingGoogle(true)
      await auth.loginWithGoogle()
    } catch (err) {
      setLoadingGoogle(false)
    }
  }

  const handleLoginWithStoredIdp = async () => {
    try {
      setLoadingIdp(true)
      history.push(`/login/${storedIdp.unique}`)
    } catch (err) {
      setLoadingIdp(false)
    }
  }

  const handleRemoveStoredIdp = () => {
    removeIdp()
  }

  const handleMore = () => {
    setMore(true)
  }

  const formValid = () => isValid(username) && isValid(password)

  const isFormValid = formValid()

  if (!auth.authed) {
    if (qs.to && !qs.to.startsWith("/")) {
      console.log("[Login]", "qs.to ! /", "Signing out")
      return <Redirect to="/login" />
    }
  }

  const anyLoading = loading || loadingGoogle || loadingIdp

  const showCommon = !storedIdp || more

  return (
    <PublicContainer>
      <PaperBox p={5}>
        <Container component="main" className={classes.root}>
          <div className={classes.wrapper}>
            <Box mb={2}>
              <LogoFull width="197" />
            </Box>
            {error && (
              <Typography component="p" style={{ textAlign: "center" }}>
                <br />
                <strong style={{ color: "Red" }}>{error}</strong>
                <br />
              </Typography>
            )}
            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              {storedIdp && (
                <>
                  <Box mt={error ? 2 : 0}>
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      className={classes.external}
                      size="large"
                      disabled={anyLoading}
                      onClick={handleLoginWithStoredIdp}
                      title={storedIdp.name}
                    >
                      {!loadingIdp && <Icon name="identity-provider" className={classes.externalLogo} />}
                      {loadingIdp && <LoadingSpinner size="24px" className={classes.externalLogo} delay={false} />}
                      <span className={classes.externalLabel}>
                        <Trans>Sign in with {storedIdp.name}</Trans>
                      </span>
                    </Button>
                  </Box>
                  {more && (
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      className={classes.external}
                      size="large"
                      disabled={anyLoading}
                      onClick={handleRemoveStoredIdp}
                    >
                      <Icon name="cancel" className={classes.externalLogo} />
                      <span className={classes.externalLabel}>
                        <Trans>Remove this option</Trans>
                      </span>
                    </Button>
                  )}
                  <TextDivider>
                    <Trans>OR</Trans>
                  </TextDivider>
                  {!more && (
                    <Box mt={2}>
                      <LinkButton onClick={handleMore} className={classes.more}>
                        <RowBox alignItems="center" justifyContent="center" flexGrow={1}>
                          <Icon name="down" />
                          <Box ml={0.5}>
                            <Trans>More sign in options</Trans>
                          </Box>
                        </RowBox>
                      </LinkButton>
                    </Box>
                  )}
                </>
              )}

              {showCommon && (
                <>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label={t`Email`}
                    name="username"
                    type="email"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    autoComplete="email"
                    disabled={anyLoading}
                    autoFocus
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    label={t`Password`}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    disabled={anyLoading}
                  />
                  {!loading && (
                    <Button
                      id="signin"
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      size="large"
                      disabled={anyLoading || !isFormValid}
                    >
                      <span>
                        <Trans>Sign in</Trans>
                      </span>
                    </Button>
                  )}
                  {loading && (
                    <Button
                      id="signin-loading"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      size="large"
                      disabled
                    >
                      <LoadingSpinner size="30px" delay={false} />
                    </Button>
                  )}

                  <TextDivider>
                    <Trans>OR</Trans>
                  </TextDivider>

                  <Box my={2}>
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      className={classes.external}
                      size="large"
                      disabled={anyLoading}
                      onClick={handleLoginWithGoogle}
                    >
                      {!loadingGoogle && <Icon name="google" className={classes.externalLogo} />}
                      {loadingGoogle && <LoadingSpinner size="24px" className={classes.externalLogo} delay={false} />}
                      <span>
                        <Trans>Sign in with Google</Trans>
                      </span>
                    </Button>

                    {/* 
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.external}
                    size="large"
                    disabled={loading}
                    onClick={() => alert("Service not currently available")}              
                  >
                    <Icon name="facebook" className={classes.externalLogo} />
                    <span>Sign in with Facebook</span>
                  </Button> 
                */}
                  </Box>

                  <Box display="flex" justifyContent="center">
                    <NavLinkItem to="/password" text={<Trans>Forgotten your password?</Trans>} disabled={anyLoading} />
                  </Box>
                </>
              )}
            </form>
          </div>
        </Container>
      </PaperBox>
      {/* {showCommon && (
        <Box display="flex" justifyContent="center" mt={3}>
          <Box mr={0.5}>No account?</Box>
          <NavLinkItem to="/signup" text="Start a free trial" disabled={anyLoading} />
        </Box>
      )} */}
    </PublicContainer>
  )
}

export default Login
