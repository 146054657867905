import { useMutation, gql } from "@apollo/client"
import moment from "moment"
import { v4 as uuid } from "uuid"
import { makeOptimisticUserFromPrincipal } from "../makeOptimistic"
import { ACTION_FIELDS } from "./fragments/actionFieldsFragment"

const CREATEACTIONCOMMENT_MUTATION = gql`
  mutation CreateActionComment($content: String!, $action: ID!, $uploads: [ID!]) {
    createActionComment(content: $content, action: $action, uploads: $uploads) {
      ...ActionFields
    }
  }
  ${ACTION_FIELDS}
`

const makeCreateActionCommentOptimisticResponse = ({ action, content, uploads, principal }) => ({
  __typename: "Mutaton",
  createActionComment: {
    ...action,
    comments: [
      ...action.comments,
      {
        __typename: "Comment",
        id: uuid(),
        author: makeOptimisticUserFromPrincipal(principal),
        content,
        uploads: uploads.map((upload) => ({ fileSize: 1, ...upload })),
        createdAt: moment().format(),
      },
    ],
  },
})

const useMutationCreateActionComment = (options) => useMutation(CREATEACTIONCOMMENT_MUTATION, options)

export { useMutationCreateActionComment, makeCreateActionCommentOptimisticResponse }
