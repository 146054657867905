import { gql } from "@apollo/client"

const GROUP_FIELDS = gql`
  fragment GroupFields on Group {
    id
    visibility
    name
    attributes {
      id
      location
      integration
      key
      value
    }
    hideFromKiosk
  }
`

export { GROUP_FIELDS }
