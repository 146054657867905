import { useMutation, gql } from "@apollo/client"
import { TRAINING_MODULE_FIELDS } from "./useQueryTrainingModules"

const UPDATE_TRAINING_MODULE_MUTATION = gql`
  mutation UpdateTrainingModule(
    $id: ID!
    $name: String!
    $description: String
    $knowledges: [ID!]!
    $users: [ID!]
    $groups: [ID!]
  ) {
    updateTrainingModule(
      id: $id
      name: $name
      description: $description
      knowledges: $knowledges
      users: $users
      groups: $groups
    ) {
      ${TRAINING_MODULE_FIELDS}
    }
  }
`

const useMutationUpdateTrainingModule = () => useMutation(UPDATE_TRAINING_MODULE_MUTATION)

export { useMutationUpdateTrainingModule }
