import { useQuery, gql } from "@apollo/client"
import { AREA_FIELDS } from "./useQueryAreas"

const AREA_QUERY = gql`
  query Area($id: ID!) {
    area(id: $id) {
      ...AreaFields
    }
  }
  ${AREA_FIELDS}
`

const useQueryArea = (options) => useQuery(AREA_QUERY, options)

export { useQueryArea }
