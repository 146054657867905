import React from "react"
import { Box } from "@material-ui/core"
import { Avatar } from ".."
import { RowBox } from "../Boxes"

const Author = ({ author, prefix, className }) => (
  <RowBox>
    <Avatar {...author} />
    <Box align="left" ml={1} className={className}>
      {prefix} {author.fullName}
    </Box>
  </RowBox>
)

export { Author }
