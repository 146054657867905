export default {
  httpBaseUri: "http://budgie:5020",
  httpServiceBaseUri: "http://budgie:5020/graphql",
  wsServiceBaseUri: "ws://budgie:5020/graphql",

  showConfigInTitle: true,
  configTitle: "[LOCALBUDGIE]",

  theme: {
    logoContainer: "images-dev",
  },

  errors: {
    showDetail: true,
  },
}
