import { useMutation, gql } from "@apollo/client"
import { USER_ACCREDITATION_FIELDS } from "./fragments/userAccreditationFieldsFragment"

const UPDATE_USER_ACCREDITATION_MUTATION = gql`
  mutation UpdateUserAccreditation($id: ID!, $uploads: [ID!], $expiresAt: Date) {
    updateUserAccreditation(id: $id, uploads: $uploads, expiresAt: $expiresAt) {
      ...UserAccreditationFields
    }
  }
  ${USER_ACCREDITATION_FIELDS}
`

const useMutationUpdateUserAccreditation = () => useMutation(UPDATE_USER_ACCREDITATION_MUTATION)

export { useMutationUpdateUserAccreditation }
