import React from "react"
import PopupState, { anchorRef, bindTrigger, bindMenu } from "material-ui-popup-state"
import { Button, Menu, MenuItem } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { Trans } from "@lingui/macro"

const useStyles = makeStyles(() => ({
  menu: {
    marginTop: 5,
  },
}))

const DesktopPeopleButton = ({ onCreate }) => {
  const classes = useStyles()

  const handleCreate = (type, popupState) => {
    onCreate && onCreate(type)
    if (popupState) popupState.close()
  }

  return (
    <PopupState variant="popover" popupId="desktop-add-people">
      {(popupState) => (
        <>
          <Button
            variant="contained"
            color="primary"
            {...bindTrigger(popupState)}
            ref={anchorRef(popupState)}
            data-cy="Button-create-person"
          >
            <Trans>Add people</Trans>
          </Button>
          <Menu
            {...bindMenu(popupState)}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            className={classes.menu}
          >
            <MenuItem onClick={() => handleCreate("single", popupState)} data-cy="MenuItem-create-single-person">
              <Trans>Add single person</Trans>
            </MenuItem>
            <MenuItem onClick={() => handleCreate("multiple", popupState)} data-cy="MenuItem-create-multiple-people">
              <Trans>Add multiple people</Trans>
            </MenuItem>
          </Menu>
        </>
      )}
    </PopupState>
  )
}

export { DesktopPeopleButton }
