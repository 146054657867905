import Validator from "@impelsys/validatorjs"
import pluralize from "pluralize"

const useFormUtils = () => {
  const isError = (val, isTouched) => isTouched && (!val || val.length === 0)

  const getErrorMessage = (val, isTouched, message) => isError(val, isTouched) && message

  const isValid = (...vals) => vals.every((val) => val != null && `${val}`.length > 0)

  Validator.register("decimals", function (value, requirement) {
    const decimalPlaces = parseInt(requirement)
    let regex

    // Calculate how many decimal places this input can have - or unlimited if not set
    if (decimalPlaces === 0) {
      regex = new RegExp(`^-?\\d+$`)
    } else {
      const decimalLimit = decimalPlaces > 0 ? `{0,${decimalPlaces}}` : "*"
      regex = new RegExp(`^-?\\d+(?:\\.\\d${decimalLimit})?$`)
    }
    return regex.test(value)
  })

  Validator.register("ip", function (value) {
    const regex =
      /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9a-f]{1,4}:){7}([0-9a-f]{1,4}|:))|(([0-9a-f]{1,4}:){6}(:[0-9a-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9a-f]{1,4}:){5}(((:[0-9a-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9a-f]{1,4}:){4}(((:[0-9a-f]{1,4}){1,3})|((:[0-9a-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9a-f]{1,4}:){3}(((:[0-9a-f]{1,4}){1,4})|((:[0-9a-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9a-f]{1,4}:){2}(((:[0-9a-f]{1,4}){1,5})|((:[0-9a-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9a-f]{1,4}:){1}(((:[0-9a-f]{1,4}){1,6})|((:[0-9a-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9a-f]{1,4}){1,7})|((:[0-9a-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/gi
    return regex.test(value)
  })

  const validate = {
    numeric: (value) => new Validator({ value }, { value: "numeric" }).passes(),
    required: (value) => new Validator({ value }, { value: "required" }).passes(),
    requiredNumeric: (value) => {
      const validation = new Validator(
        { value },
        { value: "required|numeric" },
        { required: "", numeric: "Please enter a valid number." },
      )
      if (validation.passes()) {
        return { validation: true, message: "" }
      }
      return { validation: false, message: validation.errors.first("value") }
    },
    requiredEmail: (value) => new Validator({ value }, { value: "required|email" }).passes(),
    requiredNumericDecimals: (value, decimals) => {
      const validation = new Validator(
        { value, decimals },
        { value: `required|numeric|decimals:${decimals}` },
        {
          numeric: "Please enter a valid number.",
          decimals:
            decimals > 0
              ? `Please limit your answer to :decimals decimal ${pluralize("place", decimals)}.`
              : `Please remove any decimal places from your answer.`,
        },
      )
      if (validation.passes()) {
        return { validation: true, message: "" }
      }
      return { validation: false, message: validation.errors.first("value") }
    },
    ip: (value) => new Validator({ value }, { value: "ip" }).passes(),
    requiredIP: (value) => new Validator({ value }, { value: "required|ip" }).passes(),
  }

  const touched = (val, set) => {
    if (val == null) {
      set("")
    }
  }

  const debounceTouched = (set) => setTimeout(() => set(true))

  return {
    getErrorMessage,

    isError,
    isValid,

    validate,

    touched,
    debounceTouched,
  }
}

export { useFormUtils }
