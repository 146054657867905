import React from "react"
import { Box, useTheme, useMediaQuery } from "@material-ui/core"
import { ModuleHeading } from "../Headings"
import { ErrorBoundary } from "../ErrorBoundary"

const DashboardModule = ({ title: { text, unconfirmed }, children, adminOnly = false, cy }) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <ErrorBoundary>
      <Box mb={xs ? 2 : 5} data-cy={cy}>
        {Boolean(text) && (
          <ModuleHeading unconfirmed={unconfirmed} adminOnly={adminOnly}>
            {text}
          </ModuleHeading>
        )}
        {children}
      </Box>
    </ErrorBoundary>
  )
}

export { DashboardModule }
