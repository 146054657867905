const truncateInMiddle = (value, at) =>
  at && value?.length > at ? `${value.substring(0, at / 2)}...${value.substring(value.length - at / 2)}` : value

const truncateAtStart = (value, at) => (at && value?.length > at ? `${value.substring(0, at)}...` : value)

const useStringUtils = () => {
  const trimEllip = (string, length) =>
    string && string.length > length ? `${string.substring(0, length)}...` : string

  return {
    trimEllip,
    truncateInMiddle,
  }
}

export { useStringUtils, truncateInMiddle, truncateAtStart }
