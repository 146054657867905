import React, { memo } from "react"
import { useAuth } from "../../services"

const RequirePermissions = ({ requires, features, operator = "or", children, lacks = null }) => {
  const { hasPermission, hasFeature } = useAuth()
  const show = (!requires || hasPermission(requires, operator)) && (!features || hasFeature(features))

  if (!show) return lacks

  return <>{children}</>
}

const RequirePermissionsMemo = memo(RequirePermissions)

export { RequirePermissionsMemo as RequirePermissions }
