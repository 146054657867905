import { useMutation, gql } from "@apollo/client"

const DELETECATEGORY_MUTATION = gql`
  mutation DeleteCategory($id: ID!) {
    deleteCategory(id: $id)
  }
`

const useMutationDeleteCategory = () => useMutation(DELETECATEGORY_MUTATION)

export { useMutationDeleteCategory }
