import { useQuery, gql } from "@apollo/client"
import { offsetLimitPagination } from "@apollo/client/utilities"

const INTEGRATION_EVENTS_LIMIT = 20

const INTEGRATION_EVENTS_FIELDS = `
  id
  name
  payload
  result
  status
  providerMessage
  createdAt
  updatedAt
`

const INTEGRATION_EVENTS_QUERY = gql`
  query IntegrationEvents($id: ID!, $offset: Int) {
    integration(id: $id) {
      events(offset: $offset, limit: ${INTEGRATION_EVENTS_LIMIT}) {
        ${INTEGRATION_EVENTS_FIELDS}
      }
    }
  }
`

const eventsIntegrationQueryTypePolicies = {
  IntegrationQuery: {
    fields: {
      events: offsetLimitPagination(),
    },
  },
}

const useQueryIntegrationEvents = (options) => useQuery(INTEGRATION_EVENTS_QUERY, options)

export { useQueryIntegrationEvents, eventsIntegrationQueryTypePolicies }
