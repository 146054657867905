import { useMutation, gql } from "@apollo/client"
import { PROCESS_FIELDS } from "./fragments/processFieldsFragment"
import { JOB_FIELDS } from "../jobs/fragments/jobFieldsFragment"
import { toId } from "../../utils"

const UPDATE_PROCESS_MUTATION = gql`
  mutation UpdateProcess($id: ID!, $input: ProcessInput!) {
    process(id: $id) {
      update(input: $input) {
        ...ProcessFields
      }
    }
  }
  ${PROCESS_FIELDS}
`

const INACTIVE_PROCESS_MUTATION = gql`
  mutation InactiveProcess($id: ID!, $value: Boolean!) {
    process(id: $id) {
      inactive(value: $value) {
        ...ProcessFields
      }
    }
  }
  ${PROCESS_FIELDS}
`

const RUN_PROCESS_MUTATION = gql`
  mutation RunProcess($id: ID!, $schedule: ID!, $title: String, $location: ID!) {
    process(id: $id) {
      run(schedule: $schedule, title: $title, location: $location) {
        ...JobFields
      }
    }
  }
  ${JOB_FIELDS}
`

const COPY_PROCESS_MUTATION = gql`
  mutation CopyProcess($id: ID!, $input: ProcessCopyInput) {
    process(id: $id) {
      copy(input: $input) {
        ...ProcessFields
      }
    }
  }
  ${PROCESS_FIELDS}
`

const DELETE_PROCESS_MUTATION = gql`
  mutation DeleteProcess($id: ID!) {
    process(id: $id) {
      delete
    }
  }
`

const useMutationUpdateProcess = () => useMutation(UPDATE_PROCESS_MUTATION)

const useMutationUpdateProcessInactive = () => useMutation(INACTIVE_PROCESS_MUTATION)

const useMutationRunProcess = () => useMutation(RUN_PROCESS_MUTATION)

const useMutationCopyProcess = () => useMutation(COPY_PROCESS_MUTATION)

const useMutationDeleteProcess = () =>
  useMutation(DELETE_PROCESS_MUTATION, {
    update(cache, { errors, ...rest }, { variables: { id } }) {
      if (errors) {
        return
      }
      const normalizedId = cache.identify({ id: toId(id), __typename: "Process" })
      cache.evict({ id: normalizedId })
      cache.gc()
    },
  })

export {
  useMutationUpdateProcess,
  useMutationUpdateProcessInactive,
  useMutationRunProcess,
  useMutationCopyProcess,
  useMutationDeleteProcess,
}
