import { useLazyQuery, gql } from "@apollo/client"
import { SEARCH_FIELDS } from "./fragments/searchFieldsFragment"

const SEARCH_LIMIT = 20

const SEARCH_QUERY = gql`
  query SearchAreas($limit: Int!, $searchText: String!, $location: ID!) {
    searchAreas(limit: $limit, searchText: $searchText, location: $location) {
      keywords
      jobs {
        total
        items {
          ...SearchFields
        }
      }
      knowledge {
        total
        items {
          ...SearchFields
        }
      }
      posts {
        total
        items {
          ...SearchFields
        }
      }
    }
  }
  ${SEARCH_FIELDS}
`

const useLazyQuerySearch = (options) => {
  const [load, result] = useLazyQuery(SEARCH_QUERY, {
    variables: {
      limit: SEARCH_LIMIT,
    },
    ...options,
  })

  return [load, result]
}

export { useLazyQuerySearch }
