import React from "react"
import { Box, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    fontSize: 14,
    fontWeight: 600,
    borderRadius: 4,
  },
}))

const AlertBox = ({ children, className = "", px = 1, py = 0.5, ...rest }) => {
  const classes = useStyles()

  return (
    <Box className={[classes.root, className].join(" ")} px={px} py={py} {...rest}>
      {children}
    </Box>
  )
}

export { AlertBox }
