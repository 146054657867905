import { useMutation, gql } from "@apollo/client"
import { PROCESS_FIELDS } from "./fragments/processFieldsFragment"

const CREATE_PROCESS_MUTATION = gql`
  mutation CreateProcess($input: ProcessInput!) {
    createProcess(input: $input) {
      ...ProcessFields
    }
  }
  ${PROCESS_FIELDS}
`

const useMutationCreateProcess = () => useMutation(CREATE_PROCESS_MUTATION)

export { useMutationCreateProcess }
