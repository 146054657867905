import { useMutation, gql } from "@apollo/client"
import { ROLE_ADMINABLE_FIELDS, ROLE_FIELDS } from "./fragments/roleFieldsFragment"
import { toId } from "../../utils"
import { ATTRIBUTE_FIELDS } from "../shared/fragments/attributeFieldsFragment"

const ROLE_CREATE_MUTATION = gql`
  mutation RoleCreate($input: RoleCreateMutationInput!) {
    createRole(input: $input) {
      ...RoleFields
      ...RoleAdminableFields
    }
  }
  ${ROLE_FIELDS}
  ${ROLE_ADMINABLE_FIELDS}
`

const ROLE_UPDATE_MUTATION = gql`
  mutation RoleUpdate($id: ID!, $input: RoleUpdateMutationInput!) {
    role(id: $id) {
      update(input: $input) {
        ...RoleFields
        ...RoleAdminableFields
      }
    }
  }
  ${ROLE_FIELDS}
  ${ROLE_ADMINABLE_FIELDS}
`

const ROLE_DELETE_MUTATION = gql`
  mutation RoleDelete($id: ID!) {
    role(id: $id) {
      delete
    }
  }
`

const ROLE_CREATE_ATTRIBUTE_MUTATION = gql`
  mutation RoleCreateAttribute($id: ID!, $input: AttributeCreateMutationInput!) {
    role(id: $id) {
      createAttribute(input: $input) {
        ...RoleFields
        ...RoleAdminableFields
      }
    }
  }
  ${ROLE_FIELDS}
  ${ROLE_ADMINABLE_FIELDS}
`

const ROLE_UPDATE_ATTRIBUTE_MUTATION = gql`
  mutation RoleUpdateAttribute($id: ID!, $attribute: ID!, $input: AttributeUpdateMutationInput!) {
    role(id: $id) {
      attribute(id: $attribute) {
        update(input: $input) {
          ...AttributeFields
        }
      }
    }
  }
  ${ATTRIBUTE_FIELDS}
`

const ROLE_DELETE_ATTRIBUTE_MUTATION = gql`
  mutation RoleDeleteAttribute($id: ID!, $attribute: ID!) {
    role(id: $id) {
      attribute(id: $attribute) {
        delete
      }
    }
  }
`

const useMutationCreateRole = () => useMutation(ROLE_CREATE_MUTATION)

const useMutationUpdateRole = () => useMutation(ROLE_UPDATE_MUTATION)

const useMutationDeleteRole = () => useMutation(ROLE_DELETE_MUTATION)

const useMutationRoleCreateAttribute = () => useMutation(ROLE_CREATE_ATTRIBUTE_MUTATION)

const useMutationRoleUpdateAttribute = () => useMutation(ROLE_UPDATE_ATTRIBUTE_MUTATION)

const useMutationRoleDeleteAttribute = () =>
  useMutation(ROLE_DELETE_ATTRIBUTE_MUTATION, {
    update(cache, { errors, ...rest }, { variables: { attribute } }) {
      if (errors) {
        return
      }
      const normalizedId = cache.identify({ id: toId(attribute), __typename: "Attribute" })
      cache.evict({ id: normalizedId })
      cache.gc()
    },
  })

export {
  useMutationCreateRole,
  useMutationUpdateRole,
  useMutationDeleteRole,
  useMutationRoleCreateAttribute,
  useMutationRoleUpdateAttribute,
  useMutationRoleDeleteAttribute,
}
