import React, { Suspense, memo, useEffect, useMemo, useState } from "react"
import { makeStyles } from "@material-ui/styles"
import moment from "moment"
import sortArray from "sort-array"
import { Box, Button } from "@material-ui/core"
import InfiniteScroll from "react-infinite-scroll-component"
import { Trans } from "@lingui/macro"
import { NoItemsMessage, LoadingSpinner } from ".."
import { useAuth } from "../../services"
import { IntervalRenderer } from "../IntervalRenderer"
import { PROCESS_SCHEDULE_UNTIL } from "../../data"

const JobListItems = React.lazy(() => import("./JobListItems"))

const useStyles = makeStyles((theme) => ({
  jobList: {
    marginBottom: theme.spacing(1),
  },
  loading: {
    display: "flex",
    justifyContent: "center",
  },
}))

const completeDelayMs = 3000

const defaultPageSize = 8

const jobSort = {
  common: {
    computed: {
      jobType: (job) => (job.process ? "process" : "task"),
      steps: (job) => job.status.steps.length,
      repeat: (job) => (job.process ? job.process.repeat.type : 0),
      completed: (job) => {
        if (job.hasSubmit && !job.status.submitted) {
          return false
        }
        return (
          (job.status.completed && moment().diff(job.status.completedAt) > completeDelayMs) ||
          (job.status.submitted && moment().diff(job.status.submittedAt) > completeDelayMs)
        )
      },
      overdue: (job) =>
        (!job.status.completed || moment().diff(job.status.completedAt) < completeDelayMs) &&
        job.dueAt !== null &&
        moment().isAfter(moment(job.dueAt))
          ? 0
          : 1,
    },
  },
  options: [
    {
      id: "jobStatus",
      name: "Job status",
      by: ["completed", "overdue", "availableFrom", "dueAt", "title"],
    },
    {
      id: "availableFromAsc",
      name: "Start time (Ascending)",
      by: ["availableFrom", "title", "jobType"],
      order: ["asc", "asc", "asc"],
    },
    {
      id: "availableFromDesc",
      name: "Start time (Descending)",
      by: ["availableFrom", "title", "jobType"],
      order: ["desc", "asc", "asc"],
    },
  ],
}

const JobListLoading = () => {
  const classes = useStyles()
  return (
    <div className={classes.loading}>
      <LoadingSpinner size={60} />
    </div>
  )
}

const isTimeBasedPinning = (job) =>
  [PROCESS_SCHEDULE_UNTIL.MINUTES, PROCESS_SCHEDULE_UNTIL.HOURS, PROCESS_SCHEDULE_UNTIL.DAYS].includes(
    job.availableUntil.type,
  )

const filterActiveTimeBasedPinning = (job, date) => {
  // not time based availableUntil?
  if (!isTimeBasedPinning(job)) {
    return true
  }

  // current date/time is before availableUntil at
  if (moment().isSameOrBefore(moment(job.availableUntil.at))) {
    return true
  }

  // availableUntil at is after request date
  if (moment(date).add(1, "day").startOf("day").isSameOrBefore(moment(job.availableUntil.at))) {
    return true
  }

  return false
}

const filterData = (data, location, date, filterKeywords, hideCompleted, hideFuture, hideHidden, isFuture) =>
  data.jobs.list.filter((job) => {
    const keywords = `${job.title} ${job.scheduleName} ${job.processName} ${job.process?.name || ""}`
      .trim()
      .toLowerCase()

    const isCompletedUnderDebounce =
      job.status.completed && moment().diff(job.status.completedAt, "milliseconds") < completeDelayMs

    return (
      (!job.status.completed || isCompletedUnderDebounce || !hideCompleted) &&
      job.location.id === location.id &&
      (moment().isSameOrAfter(job.availableFrom) || !hideFuture || isFuture) &&
      (!hideHidden || filterActiveTimeBasedPinning(job, date)) &&
      (filterKeywords.length === 0 || filterKeywords.every((keyword) => keywords.includes(keyword)))
    )
  })

const JobList = memo(function JobList({
  loading,
  data,
  networkStatus,
  onEdit,
  filters,
  date,
  compact,
  empty,
  showEndMessage = true,
  showShowMore = false,
  pageSize = defaultPageSize,
}) {
  const classes = useStyles()
  const { location } = useAuth()
  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(pageSize)

  useEffect(() => {
    setLimit(pageSize)
  }, [filters, pageSize])

  const handleLoadMore = (isShow) => {
    if (!isShow && showShowMore) {
      return
    }
    setLimit(Math.min(limit + pageSize, data?.jobs.list.length || 0))
  }

  const items = useMemo(() => {
    if (!data) {
      return []
    }

    let result
    if (filters) {
      const { filterText, hideCompleted, hideFuture, hideHidden, isFuture, sort } = filters

      const filterKeywords = filterText
        ? filterText
            .split(" ")
            .map((keyword) => keyword.toLowerCase().trim())
            .filter((k) => k)
        : []

      result = filterData(data, location, date, filterKeywords, hideCompleted, hideFuture, hideHidden, isFuture)

      if (sort) {
        const sortOption = jobSort.options.find((option) => option.id === sort)
        sortArray(result, { ...jobSort.common, ...sortOption })
      }
    } else {
      result = data.jobs.list
    }

    if (result.length !== total) {
      setTotal(result.length)
    }

    return result.slice(0, limit)
  }, [data, date, filters, limit, location, total])

  if (!data?.jobs.list.length > 0 && loading && networkStatus !== 6) {
    return <JobListLoading />
  }
  if (!data || (data && (!data.jobs || !data.jobs.list.length))) {
    return (
      <div className={classes.jobList}>
        {empty || (
          <NoItemsMessage>
            <Trans>No current jobs</Trans>
          </NoItemsMessage>
        )}
      </div>
    )
  }

  const hasMore = total > limit

  return (
    <IntervalRenderer delay={items.length ? 1000 : 0}>
      {() => (
        <Box mb={5}>
          <InfiniteScroll
            dataLength={items.length}
            next={handleLoadMore}
            hasMore={hasMore}
            loader={!showShowMore && <JobListLoading />}
            style={{ overflow: "visible" }}
          >
            <Box data-cy="Box-jobs">
              <Suspense fallback={<JobListLoading />}>
                <JobListItems
                  items={items}
                  date={date}
                  compact={compact}
                  onEdit={onEdit}
                  footer={
                    <>
                      {!loading && showEndMessage && (
                        <NoItemsMessage>
                          {items.length || "No"} matching job{items.length > 1 || !items.length ? "s" : ""}
                        </NoItemsMessage>
                      )}
                      {showShowMore && hasMore && (
                        <Box display="flex" justifyContent="center" mt={3}>
                          <Button variant="outlined" onClick={() => handleLoadMore(true)}>
                            <Trans>Show More</Trans>
                          </Button>
                        </Box>
                      )}
                    </>
                  }
                />
              </Suspense>
            </Box>
          </InfiniteScroll>
        </Box>
      )}
    </IntervalRenderer>
  )
})

export { JobList, jobSort }
