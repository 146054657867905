import { useLazyQuery, useQuery, gql } from "@apollo/client"

const USERS_QUERY = gql`
  query Users($filter: UsersFilterInput) {
    users(filter: $filter) {
      id
      firstName
      lastName
      fullName
      avatar {
        key
      }
      locations {
        id
        name
      }
    }
  }
`

const useQueryUsers = (options) => useQuery(USERS_QUERY, options)

const useLazyQueryUsers = () => useLazyQuery(USERS_QUERY)

export { useQueryUsers, useLazyQueryUsers }
