import { Box, Chip, Typography } from "@material-ui/core"
import React from "react"
import { formatEventLabel } from "../../utils/events"
import { ColumnBox, RowBox } from "../Boxes"
import { FieldSectionHeading } from "../Headings"
import { Icon } from "../Icon"

const InfoChip = ({ label, icon }) => (
  <Chip
    icon={
      <ColumnBox ml={1} alignItems="center">
        <Icon name={icon} />
      </ColumnBox>
    }
    label={label}
    variant="outlined"
  />
)

const IntegrationProviderInfo = ({
  integrationProvider: { name, description, events, remoteEvents, attributes },
  ...rest
}) => (
  <Box {...rest}>
    <FieldSectionHeading mb={0}>Description</FieldSectionHeading>
    <Box mb={2}>{description}</Box>
    {Boolean(events?.length) && (
      <Box mb={1}>
        <FieldSectionHeading mb={0}>Operandio Events</FieldSectionHeading>
        <Typography variant="body2">Notifies {name} of the following Operandio events.</Typography>
        <RowBox mt={1} flexWrap="wrap">
          {events.map((event) => (
            <Box key={event} mr={0.5} mb={0.5}>
              <InfoChip label={formatEventLabel(event)} icon="integration-event" />
            </Box>
          ))}
        </RowBox>
      </Box>
    )}
    {Boolean(remoteEvents?.length) && (
      <Box mb={1}>
        <FieldSectionHeading mb={0}>{name} Events</FieldSectionHeading>
        <Typography variant="body2">Configures {name} to notify Operandio of the following events.</Typography>
        <RowBox mt={1} flexWrap="wrap">
          {remoteEvents.map((event) => (
            <Box key={event.remoteName} mr={0.5} mb={0.5}>
              <InfoChip label={event.remoteName} icon="integration-event" />
            </Box>
          ))}
        </RowBox>
      </Box>
    )}
    {Boolean(attributes?.integration.length) && (
      <Box mb={1}>
        <FieldSectionHeading mb={0}>Settings</FieldSectionHeading>
        <Typography variant="body2">
          Stores the following to connect {name} with your Operandio organisation.
        </Typography>
        <RowBox mt={1} flexWrap="wrap">
          {attributes.integration.map((attribute) => (
            <Box key={attribute.key} mr={0.5} mb={0.5}>
              <InfoChip label={attribute.name} icon="integration" />
            </Box>
          ))}
        </RowBox>
      </Box>
    )}
    {Boolean(attributes?.location.length) && (
      <Box mb={1}>
        <FieldSectionHeading mb={0}>Locations</FieldSectionHeading>
        <Typography variant="body2">Stores the following to connect {name} with your Operandio locations.</Typography>
        <RowBox mt={1} flexWrap="wrap">
          {attributes.location.map((attribute) => (
            <Box key={attribute.key} mr={0.5} mb={0.5}>
              <InfoChip label={attribute.name} icon="integration-location" />
            </Box>
          ))}
        </RowBox>
      </Box>
    )}
    {Boolean(attributes?.locationGroup.length) && (
      <Box mb={1}>
        <FieldSectionHeading mb={0}>Areas of Work by Location</FieldSectionHeading>
        <Typography variant="body2">
          Stores the following to connect {name} with your Operandio Areas of Work by Location.
        </Typography>
        <RowBox mt={1} flexWrap="wrap">
          {attributes.locationGroup.map((attribute) => (
            <Box key={attribute.key} mr={0.5} mb={0.5}>
              <InfoChip label={attribute.name} icon="integration-group" />
            </Box>
          ))}
        </RowBox>
      </Box>
    )}
    {Boolean(attributes?.group.length) && (
      <Box mb={1}>
        <FieldSectionHeading mb={0}>Areas of Work</FieldSectionHeading>
        <Typography variant="body2">
          Stores the following to connect {name} with your Operandio Areas of Work.
        </Typography>
        <RowBox mt={1} flexWrap="wrap">
          {attributes.group.map((attribute) => (
            <Box key={attribute.key} mr={0.5} mb={0.5}>
              <InfoChip label={attribute.name} icon="integration-group" />
            </Box>
          ))}
        </RowBox>
      </Box>
    )}
    {Boolean(attributes?.role.length) && (
      <Box mb={1}>
        <FieldSectionHeading mb={0}>Permission Level</FieldSectionHeading>
        <Typography variant="body2">
          Stores the following to connect {name} with your Operandio Permission Levels.
        </Typography>
        <RowBox mt={1} flexWrap="wrap">
          {attributes.role.map((attribute) => (
            <Box key={attribute.key} mr={0.5} mb={0.5}>
              <InfoChip label={attribute.name} icon="integration-group" />
            </Box>
          ))}
        </RowBox>
      </Box>
    )}
    {Boolean(attributes?.user.length) && (
      <Box mb={1}>
        <FieldSectionHeading mb={0}>Users</FieldSectionHeading>
        <Typography variant="body2">Stores the following to connect {name} with your Operandio users.</Typography>
        <RowBox mt={1} flexWrap="wrap">
          {attributes.user.map((attribute) => (
            <Box key={attribute.key} mr={0.5} mb={0.5}>
              <InfoChip label={attribute.name} icon="integration-user" />
            </Box>
          ))}
        </RowBox>
      </Box>
    )}
  </Box>
)

export { IntegrationProviderInfo }
