import { useEffect, useState } from "react"
import { useDebounceCallback } from "@react-hook/debounce"

const useDebounceIntercept = (initialState, wait, leading, onSetState) => {
  const [state, setState] = useState(initialState)
  return [
    state,
    useDebounceCallback(
      (newState) => {
        setState(newState)
        onSetState(newState)
      },
      wait,
      leading
    ),
  ]
}

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)
    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])
  return debouncedValue
}

export { useDebounce, useDebounceIntercept }
