import React, { useState } from "react"
import { Box, TextField } from "@material-ui/core"
import { Trans } from "@lingui/macro"
import { RowBox } from "../../Boxes"
import { DeleteConfirmIconButton } from "../../Buttons"

const CreatorContentMultiChoiceAnswer = ({ answer, onChange, onDeleted }) => {
  const [touched, setTouched] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const handleChange = (event) => {
    setTouched(true)
    onChange(answer, event)
  }

  const handleDelete = () => {
    onDeleted(answer)
  }

  return (
    <RowBox mb={2} alignItems="flex-start">
      <TextField
        label={<Trans>Incorrect answer</Trans>}
        fullWidth
        variant="outlined"
        value={answer.text}
        onChange={handleChange}
        error={touched && answer.text.trim() === ""}
        helperText={touched && answer.text.trim() === "" ? <Trans>Empty answers will be removed</Trans> : ""}
      />
      <Box ml={deleting ? 1 : 0} mt={0.5}>
        <DeleteConfirmIconButton
          size="medium"
          onDeleting={() => setDeleting(true)}
          onCancelDeleting={() => setDeleting(false)}
          onDelete={handleDelete}
        />
      </Box>
    </RowBox>
  )
}

export { CreatorContentMultiChoiceAnswer }
