import React from "react"
import { Box, TextField, Switch, makeStyles, FormControlLabel, InputAdornment } from "@material-ui/core"
import { RowBox, ReadOnlyTextField, FlexBox, Icon } from ".."
import { joinStringsWithNaturalLanguage, toId } from "../../utils"

const ResponseSetCustomScoring = ({ responseSet, scoring, onChange }) => {
  const handleUpdate = (updatedItem, index) => {
    const updatedScoring = [...scoring]
    updatedScoring[index] = updatedItem
    onChange(updatedScoring)
  }

  const noScoreResponses = scoring
    .filter((item) => item.noScore)
    .map((item) => responseSet.responses.find((response) => toId(response) === toId(item.response)))
    .map((item) => item.text)

  return (
    <>
      <Box>
        {scoring.map((item, index) => {
          const response = responseSet.responses.find((r) => toId(r) === toId(item.response))

          if (!response) {
            return null
          }

          return (
            <Box key={toId(item.response)} mt={2} mb={1}>
              <ResponseCustomScoring
                response={response}
                item={item}
                onChange={(updatedItem) => handleUpdate(updatedItem, index)}
              />
            </Box>
          )
        })}
      </Box>
      <Box my={2}>
        {noScoreResponses.length > 0 && (
          <>
            This step will not count towards overall possible score if the selected response is{" "}
            {joinStringsWithNaturalLanguage(noScoreResponses, "or")}
          </>
        )}
      </Box>
    </>
  )
}

const useResponseStyles = makeStyles((theme) => ({
  scoreColumn: {
    width: 100,
  },
  noScoreColumn: {
    whiteSpace: "nowrap",
  },
  labelRoot: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },
  labelNoScore: {
    fontSize: 15,
  },
}))

const ensureNumeric = (value) => {
  const numericValue = parseFloat(value)
  if (Number.isNaN(numericValue)) {
    return 0
  }
  return !/^\d+(\.\d)?$/.test(value) ? Math.floor(numericValue * 10) / 10 : numericValue
}

const ResponseCustomScoring = ({ response, item, onChange }) => {
  const classes = useResponseStyles()

  const handleUpdate = (propName, value) => {
    onChange({ ...item, [propName]: value })
  }

  return (
    <RowBox alignItems="center">
      <FlexBox flexGrow={1}>
        <ReadOnlyTextField
          variant="outlined"
          label="Response"
          value={response.text}
          size="small"
          cy="TextField-scoring-response"
          fullWidth
        />
      </FlexBox>
      <Box ml={1} className={classes.scoreColumn}>
        <TextField
          variant="outlined"
          type={item.noScore ? "text" : "number"}
          label="Score"
          value={item.noScore ? "" : item.score}
          onChange={(event) => handleUpdate("score", ensureNumeric(event.target.value))}
          size="small"
          disabled={item.noScore}
          InputProps={{
            "data-cy": "TextField-scoring-score",
            min: 0,
            startAdornment: item.noScore ? (
              <InputAdornment position="start">
                <Icon name="none" />
              </InputAdornment>
            ) : null,
          }}
        />
      </Box>
      <Box ml={2} className={classes.noScoreColumn}>
        <FormControlLabel
          control={
            <Switch
              checked={item.noScore}
              size="small"
              color="primary"
              onChange={(event) => handleUpdate("noScore", event.target.checked)}
              data-cy="Switch-scoring-no-score"
            />
          }
          label={
            <Box ml={0.5} className={classes.labelNoScore}>
              No score
            </Box>
          }
          classes={{ root: classes.labelRoot }}
        />
      </Box>
    </RowBox>
  )
}

export { ResponseSetCustomScoring }
