import { useMutation, gql } from "@apollo/client"
import { ADMINABLE_USERS_FIELDS } from "./fragments/adminableUserFieldsFragment"

const RESEND_INVITE_MUTATION = gql`
  mutation ResendInvite($id: ID!) {
    adminableUser(id: $id) {
      resendInvite {
        ...AdminableUsersFields
      }
    }
  }
  ${ADMINABLE_USERS_FIELDS}
`

const useMutationResendInvite = (options) => useMutation(RESEND_INVITE_MUTATION, options)

export { useMutationResendInvite }
