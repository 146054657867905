import React from "react"
import { makeStyles, Box, Typography, Divider } from "@material-ui/core"
import { Avatar } from ".."
import { UserStatusChip } from "../Chips"
import TimeAgo from "../TimeAgo"
import { useAuth } from "../../services"

const useStyles = makeStyles((theme) => ({
  panel: {
    width: "100%",
    borderRadius: "8px",
    textAlign: "left",
    fontWeight: "normal",
  },
  avatar: {
    width: 80,
    height: 80,
    fontSize: 24,
  },
  nameTitle: {
    color: theme.palette.text.primary,
    fontSize: 24,
    fontWeight: "500",
    lineHeight: "30px",
  },
  groups: {
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: "16px",
  },
  locations: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    lineHeight: "16px",
  },
  lastSeen: {
    color: theme.palette.text.primary,
    fontSize: 13,
    lineHeight: "16px",
  },
}))

const MentionUserPanel = ({
  user: { id, firstName, lastName, avatar, groups, locations, status, invite, activeAt },
  ...rest
}) => {
  const classes = useStyles()
  const { isCurrentUser } = useAuth()

  return (
    <Box mr={5} className={classes.panel} {...rest}>
      <Box display="flex" flexDirection="row" alignItems="center" p={2}>
        <Avatar {...{ firstName, lastName, avatar }} className={classes.avatar} />
        <Box mt={0} ml={2} flexGrow={1}>
          <Box mb={0} display="flex" flexGrow={1} justifyContent="flex-start">
            <Typography component="h1" className={classes.nameTitle}>
              {firstName} {lastName}
            </Typography>
          </Box>
          <Box className={classes.groups} mb={0.5}>
            {groups && groups.map((group) => group.name).join(", ")}
          </Box>
          <Box className={classes.locations}>
            {locations && locations.length > 0 && locations.map((location) => location.name).join(", ")}
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box p={2} display="flex" flexDirection="column">
        <Box mb={0.5}>
          <UserStatusChip user={{ status, invite }} />
        </Box>
        <Box className={classes.lastSeen}>
          Last seen: {activeAt ? <TimeAgo date={isCurrentUser(id) ? new Date() : activeAt} /> : <>Never</>}
        </Box>
      </Box>
    </Box>
  )
}

export { MentionUserPanel }
