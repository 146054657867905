import { useMutation, gql } from "@apollo/client"

import { AREA_FIELDS } from "./fragments/areaFieldsFragment"
import { useAuth } from "../../services"
import { AREAS_QUERY } from "./useQueryAreas"
import { mapToIds, toId } from "../../utils"

const CREATE_AREA_MUTATION = gql`
  mutation CreateArea($input: AreaMutationInput!) {
    createArea(input: $input) {
      ...AreaFields
    }
  }
  ${AREA_FIELDS}
`

const useMutationCreateArea = () => {
  const {
    location,
    principal: { groups: usersGroups },
  } = useAuth()

  return useMutation(CREATE_AREA_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: AREAS_QUERY,
      },
      {
        query: AREAS_QUERY,
        variables: { filter: { locations: [toId(location)], groups: mapToIds(usersGroups) } },
      },
    ],
  })
}

export { useMutationCreateArea }
