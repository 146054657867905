import React, { useState } from "react"
import { Box, Button, Collapse, Grid, makeStyles, TextField, Typography } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import arrayMove from "array-move"
import { useHistory, useParams } from "react-router-dom"
import { Trans, t } from "@lingui/macro"
import { DraftBlockquote, Icon, TileButton } from ".."

import {
  PROCESS_STEP_LOGIC_OUTCOME,
  PROCESS_STEP_LOGIC_RANGE,
  PROCESS_STEP_RESPONSE_TYPE,
  PROCESS_TYPE,
  useMutationCreateProcess,
  useMutationUpdateProcess,
  useQueryLocations,
} from "../../data"
import {
  useFormUtils,
  useMountEffect,
  useDraft,
  hasIterator,
  useAnalytics,
  isScheduleValid,
  mapToIds,
  deepRemoveTypenames,
  removeTypename,
  isTemporaryId,
  toId,
  useProcessUtils,
  getResponseStepsCount,
} from "../../utils"
import { CreatorActions } from "../Creators"
import { ColumnBox } from "../Boxes"
import Checkbox from "../Checkbox"
import { TextDivider } from "../TextDivider"
import ProcessSchedules from "./ProcessSchedules"
import { MissingLocationAccess } from "./MissingLocationAccess"
import { HasActiveJobsChanged } from "./HasActiveJobsChanged"
import { SortableProcessStepListContainer } from "./SortableProcessStepListContainer"
import TagsField from "../TextField/TagsField"
import useMergeState from "../../utils/useMergeState"
import { useAuth } from "../../services"

const initialState = {
  name: "",
  hasScoring: true,
  steps: [],
  schedules: [],
  inactive: false,
}

const initialStepState = {
  name: "",
  responseType: PROCESS_STEP_RESPONSE_TYPE.CHECKBOX,
  responseMandatory: false,
  responseSet: null,
  canFail: false,
  hasScoring: true,
  description: null,
  format: {
    unit: null,
    decimals: null,
  },
  expanded: true,
  uploads: [],
  hasExclusions: false,
  exclusions: [],
  logic: [],
  selection: {
    selectFrom: "users",
    allowMultiple: false,
    customItems: [],
  },
}

const initialStepLogicOutcomeState = {
  type: PROCESS_STEP_LOGIC_OUTCOME.ACTION,
}

const initialStepLogicState = {
  range: PROCESS_STEP_LOGIC_RANGE.BETWEEN,
  lowerValue: 0,
  upperValue: 1,
  outcomes: [
    {
      ...initialStepLogicOutcomeState,
    },
  ],
}

const useStyles = makeStyles(() => ({
  sortableListHelper: {
    zIndex: 99999,
  },
}))

const ProcessCreator = ({ onClose, edit = null }) => {
  const classes = useStyles()
  const { set, id, action } = useParams()
  const { getNavigateToTemplateLink } = useProcessUtils()
  const history = useHistory()
  const { hasFeature } = useAuth()
  const [createProcess, { loading: createLoading }] = useMutationCreateProcess()
  const [updateProcess, { loading: updateLoading }] = useMutationUpdateProcess()
  const { data: locationsData } = useQueryLocations()
  const { isValid, isError, getErrorMessage, debounceTouched } = useFormUtils()
  const analytics = useAnalytics()
  const [isUploading, setIsUploading] = useState(false)
  const [init, setInit] = useState(false)
  const [scheduleKey, setScheduleKey] = useState(0)
  const [closing, setClosing] = useState(false)
  const [disabled, setDisabled] = useState(true)

  const [nameTouched, setNameTouched] = useState(false)
  const [nameValid, setNameValid] = useState(false)
  const [stepNameValid, setStepNameValid] = useState(true)

  const [type, setType] = useState(action === "audit" ? PROCESS_TYPE.AUDIT : PROCESS_TYPE.PROCESS)
  const [name, setName] = useState(initialState.name)
  const [hasScoring, setHasScoring] = useState(initialState.hasScoring)
  const [steps, setSteps] = useState(initialState.steps)
  const [schedules, setSchedules] = useState(initialState.schedules)
  const [inactive, setInactive] = useState(initialState.inactive)
  const [tags, setTags] = useMergeState({
    value: [],
    touched: false,
  })

  const subject = type === PROCESS_TYPE.AUDIT ? "Audit" : "Process"
  const subjectLowerCase = String(subject).toLowerCase()

  const [draft, setDraft, removeDraft, draftUntouched] = useDraft(`process_creator`, null)

  const shouldHaveEveryone = (schedule) => schedule.users.length === 0 && schedule.groups.length === 0

  useMountEffect(() => {
    if (edit) {
      setType(edit.type)
      setName(edit.name)
      setTags({
        value: edit.tags.map((tag) => ({
          id: tag.id,
          name: tag.name,
        })),
      })
      setHasScoring(edit.hasScoring)
      setSteps([...edit.steps.map((step) => ({ expanded: false, ...JSON.parse(JSON.stringify(step)) }))])
      setSchedules([
        ...edit.schedules.map((schedule) => ({
          expanded: false,
          hasEveryone: shouldHaveEveryone(schedule),
          ...schedule,
        })),
      ])
      setInactive(edit.inactive)

      setNameValid(isValid(edit.name))
    } else if (draft) {
      if (draft.name) {
        setNameTouched(true)
        setNameValid(true)
        setName(draft.name)
      }
      setTags(
        {
          value: draft?.tags?.map((tag) => ({
            id: tag.id,
            name: tag.name,
          })),
        } ?? [],
      )
      draft.steps && setSteps([...draft.steps])
      draft.schedules &&
        setSchedules([
          ...draft.schedules.map((schedule) => ({
            expanded: !isScheduleValid(schedule),
            ...schedule,
          })),
        ])
      setHasScoring(draft.hasScoring)
      draft.inactive && setInactive(draft.inactive)
    }

    setInit(true)
  })

  const handleClose = (event, isCancel) => {
    onClose(isCancel)
  }

  const setDraftProperty = (prop, value) => {
    if (edit) return
    setDraft((prev) => ({
      ...prev,
      [prop]: value,
    }))
  }

  const handleDiscardDraft = () => {
    removeDraft()
    handleResetState()
  }

  const handleResetState = () => {
    setNameTouched(false)
    setName(initialState.name)
    setSteps(initialState.steps)
    setSchedules(initialState.schedules)
    setHasScoring(initialState.hasScoring)
    setInactive(initialState.inactive)
    setScheduleKey(scheduleKey + 1)
    setTags({
      value: [],
      touched: false,
    })
  }

  const handleNameChange = (e) => {
    setName(e.target.value)
    setNameValid(isValid(e.target.value))
    setDraftProperty("name", e.target.value)
  }

  const handleTagsChange = (options) => {
    setTags({ value: options })
    setDraftProperty("tags", options)
  }

  const handleSubmit = async (e, toggleActive = null) => {
    e.preventDefault()
    if (formValid()) {
      setClosing(true)
      const inputSteps = steps
        .filter((step) => step.name)
        .map(
          ({
            id: stepId,
            name: stepName,
            responseType,
            responseMandatory,
            responseSet,
            scoring,
            process,
            canFail,
            hasScoring: stepHasScoring,
            description,
            uploads,
            hasExclusions,
            exclusions,
            format,
            logic,
            canEnterManually,
            canUseDevice,
            selection,
          }) => {
            // Be mindful adding new items here where they might
            // not be present in saved draft state
            return {
              id: stepId,
              name: stepName,
              responseType,
              responseMandatory,
              responseSet: toId(responseSet, true),
              scoring: scoring ? deepRemoveTypenames(scoring) : [],
              description,
              process: toId(process, true),
              canFail,
              hasScoring: stepHasScoring,
              uploads: mapToIds(uploads),
              hasExclusions: exclusions?.length > 0 ? hasExclusions : false,
              exclusions: !exclusions || exclusions.includes("all") ? [] : mapToIds(exclusions),
              format: deepRemoveTypenames(format),
              logic: deepRemoveTypenames(logic),
              canEnterManually,
              canUseDevice,
              selection: deepRemoveTypenames(selection),
            }
          },
        )
      const inputSchedules = schedules.map(
        ({
          id: scheduleId,
          name: scheduleName,
          after,
          due,
          until,
          repeat,
          locations,
          users,
          groups,
          inactive: scheduleInactive,
          actionSettings,
        }) => ({
          id: isTemporaryId(scheduleId) ? null : scheduleId,
          name: scheduleName,
          after: deepRemoveTypenames(after),
          due: removeTypename(due),
          until: removeTypename(until),
          repeat: deepRemoveTypenames(repeat),
          locations: mapToIds(locations),
          users: mapToIds(users),
          groups: mapToIds(groups),
          inactive: scheduleInactive,
          actionSettings: {
            users: mapToIds(actionSettings?.users),
            groups: mapToIds(actionSettings?.groups),
          },
        }),
      )
      const variables = {
        input: {
          type,
          name,
          tags: mapToIds(tags.value),
          steps: inputSteps,
          schedules: inputSchedules,
          inactive,
          hasScoring,
        },
      }
      if (toggleActive) {
        variables.input.inactive = toggleActive !== "active"
      }
      if (edit) {
        await updateProcess({
          variables: {
            id: edit.id,
            ...variables,
          },
        })
      } else {
        await createProcess({
          variables,
        })
        removeDraft()
        analytics.track("Created Process")
      }
      handleClose()
    }
  }

  const formValid = () =>
    isValid(name) &&
    steps.length > 0 &&
    !steps.some((step) => step.responseType === PROCESS_STEP_RESPONSE_TYPE.PROCESS && !step.process) &&
    getResponseStepsCount(steps) >= 1 &&
    !isUploading &&
    schedules.length > 0 &&
    schedules.every((schedule) => isScheduleValid(schedule))

  const handleUpdateStep = (index, propName, value) => {
    let result
    if (propName === "expanded") {
      result = [...getCollapsedSteps(value && index)]
      setSteps(result)
    } else if (typeof value === "function") {
      result = value(steps)
      setSteps(result)
    } else if (steps[index][propName] !== value) {
      result = [...steps]
      result[index][propName] = value
      setSteps(result)
    }
    if (hasIterator(result)) {
      setDraftProperty("steps", [...result])
    }
    if (propName === "name" && index === steps.length - 1) setStepNameValid(value)
  }

  const handleAddStep = () => {
    setStepNameValid(false)
    const collapsed = getCollapsedSteps()

    // carry over some settings from step above
    const priorStep = collapsed.length > 0 ? collapsed[collapsed.length - 1] : null
    const priorStepSettings = priorStep
      ? { responseType: priorStep.responseType, responseSet: priorStep.responseSet }
      : {}

    collapsed.push({ ...initialStepState, ...priorStepSettings })
    setSteps([...collapsed])
    setDraftProperty("steps", [...collapsed])
  }

  const getCollapsedSteps = (expandedIndex) =>
    [...steps].map((s, index) => {
      s.expanded = typeof expandedIndex !== "undefined" && expandedIndex === index
      return s
    })

  const handleUploadingStateChange = (state) => {
    setIsUploading(state)
  }

  const handleCloneStep = (index) => {
    const newStep = JSON.parse(JSON.stringify(steps[index]))
    delete newStep.id
    newStep.name = `${newStep.name} (copy)`
    newStep.expanded = true
    setSteps((state) => {
      state[index].expanded = false
      state.splice(index + 1, 0, newStep)
      setDraftProperty("steps", [...state])
      return [...state]
    })
  }

  const handleRemoveStep = (index) => {
    setSteps((state) => {
      state.splice(index, 1)
      setDraftProperty("steps", [...state])
      return [...state]
    })
    setStepNameValid(true)
  }

  const handleUpdateBeforeSortStart = ({ index }) => {
    if (steps[index].expanded) {
      setSteps((state) => {
        state[index].expanded = false
        setDraftProperty("steps", [...state])
        return state
      })
    }
  }

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    setSteps((state) => {
      const next = arrayMove(state, oldIndex, newIndex)
      setDraftProperty("steps", [...next])
      return [...next]
    })
  }

  const handleSchedulesChange = (newValues) => {
    setSchedules([...newValues])
    setDraftProperty("schedules", [...newValues])
  }

  const handleHasScoringChange = () => {
    const result = !hasScoring
    setHasScoring(result)
    setDraftProperty("hasScoring", result)
  }

  const handleInactiveChange = () => {
    const result = !inactive
    setInactive(result)
    setDraftProperty("inactive", result)
  }

  const handleLocationAccessChange = (value) => {
    setDisabled(value)
  }

  const handleTypeChange = (newType) => {
    setType(newType)
    if (edit) {
      history.push(getNavigateToTemplateLink(id, set, null, "edit"))
    } else {
      history.push(getNavigateToTemplateLink("new", set, null, newType))
    }
  }

  const isFormValid = formValid()

  const loading = createLoading || updateLoading || closing

  const showDraft = !edit && !!draft && draftUntouched

  const hasAreas = hasFeature("areas")

  return (
    <>
      <MissingLocationAccess edit={edit} onChange={handleLocationAccessChange} />

      <HasActiveJobsChanged subject={subjectLowerCase} edit={edit} hidden={disabled} />

      <DraftBlockquote show={showDraft} subject={subject} onDiscard={handleDiscardDraft} />

      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ColumnBox flexGrow={1}>
              <TileButton
                title={<Trans>Process</Trans>}
                description={
                  <Trans>
                    Simple, customizable checklists for staff to complete either on a schedule or as required.
                  </Trans>
                }
                active={type === PROCESS_TYPE.PROCESS}
                onClick={() => handleTypeChange(PROCESS_TYPE.PROCESS)}
                style={{ width: "100%" }}
                data-cy={`TileButton-${PROCESS_TYPE.PROCESS}`}
              />
            </ColumnBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <TileButton
              title={<Trans>Audit</Trans>}
              description={
                <Trans>
                  Conduct inspections and create scoring reports for safety, accountability and brand standards.
                </Trans>
              }
              active={type === PROCESS_TYPE.AUDIT}
              onClick={() => handleTypeChange(PROCESS_TYPE.AUDIT)}
              style={{ width: "100%" }}
              data-cy={`TileButton-${PROCESS_TYPE.AUDIT}`}
            />
          </Grid>
        </Grid>
      </Box>

      <TextDivider mb={2} />

      <Box mb={2}>
        <TextField
          label={`${subject} name`}
          variant="outlined"
          value={name}
          onChange={handleNameChange}
          fullWidth
          required
          onBlur={() => debounceTouched(setNameTouched)}
          error={isError(name, nameTouched)}
          helperText={getErrorMessage(name, nameTouched, t`Please enter a name for the ${subjectLowerCase}`)}
          disabled={disabled}
          inputProps={{ "data-cy": "TextField-title" }}
        />
      </Box>

      {hasAreas && (
        <Box mb={2}>
          <TagsField value={tags?.value ?? []} label={t`Tags`} onChange={(_, options) => handleTagsChange(options)} />
        </Box>
      )}

      {steps.length > 0 && (
        <SortableProcessStepListContainer
          processType={type}
          items={steps}
          locations={locationsData}
          onAddStep={handleAddStep}
          onUpdateStep={handleUpdateStep}
          onRemoveStep={handleRemoveStep}
          onCloneStep={handleCloneStep}
          onUploadingStateChange={handleUploadingStateChange}
          useDragHandle
          updateBeforeSortStart={handleUpdateBeforeSortStart}
          onSortEnd={handleSortEnd}
          helperClass={classes.sortableListHelper}
          disabled={disabled}
        />
      )}

      <Box mb={3}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleAddStep}
          disabled={!nameValid || !stepNameValid || isUploading || disabled}
          data-cy="Button-add-step"
        >
          <Icon name="add" />
          <Trans>Add a step</Trans>
        </Button>
      </Box>
      <Box mb={2}>
        {init && (
          <ProcessSchedules
            key={scheduleKey}
            subject={subjectLowerCase}
            initialName={name}
            schedules={schedules}
            onChange={handleSchedulesChange}
            disabled={disabled}
          />
        )}

        {schedules.length > 1 && (
          <Box mt={2}>
            <TextDivider />
          </Box>
        )}

        <Collapse in={type === PROCESS_TYPE.AUDIT}>
          <ColumnBox pt={1} pb={2} px={2}>
            <Checkbox
              type="label"
              color="primary"
              label={t`Hide scoring from reports`}
              checked={!hasScoring}
              onChange={handleHasScoringChange}
              disabled={disabled}
            />
            <Typography variant="caption">
              <Trans>
                Scores are calculated from Button response types. Check this option to not include scoring in your audit
                results.
              </Trans>
            </Typography>
          </ColumnBox>
          <TextDivider />
        </Collapse>

        {(!edit || (edit && !edit.inactive)) && (
          <>
            <ColumnBox pt={1} pb={2} px={2}>
              <Checkbox
                type="label"
                color="primary"
                label={t`Make ${subjectLowerCase} inactive`}
                checked={inactive}
                onChange={handleInactiveChange}
                disabled={disabled}
              />
              <Typography variant="caption">
                <Trans>
                  Stop the {subjectLowerCase} running as scheduled. It will save as an inactive {subjectLowerCase} where
                  you can edit and publish it later.
                </Trans>
              </Typography>
            </ColumnBox>
            <TextDivider />
          </>
        )}
      </Box>

      {edit?.inactive && (
        <Box mb={1}>
          <Alert severity="warning" icon={false}>
            <AlertTitle>This {subjectLowerCase} is currently inactive</AlertTitle>
            If you would like to make it active, select <strong>Save {subjectLowerCase} and make active</strong> when
            saving.
          </Alert>
        </Box>
      )}

      <CreatorActions
        id="ProcessCreator-CreatorActions"
        subject={subject}
        onClose={handleClose}
        onSubmit={handleSubmit}
        disableSubmit={!isFormValid || isUploading || loading || disabled}
        extraSubmits={
          edit?.inactive
            ? [
                {
                  label: t`Save ${subjectLowerCase} and make active`,
                  onSubmit: (event) => handleSubmit(event, "active"),
                },
              ]
            : null
        }
        submitLoading={loading}
      />
    </>
  )
}
export { initialStepState, initialStepLogicState, initialStepLogicOutcomeState }
export default ProcessCreator
