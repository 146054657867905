import { useMutation, gql } from "@apollo/client"
import { LOCATION_FIELDS } from "./useQueryLocations"

const CREATE_LOCATION_MUTATION = gql`
  mutation CreateLocation($input: LocationCreateMutationInput!) {
    createLocation(input: $input) {
      ...LocationFields
    }
  }
  ${LOCATION_FIELDS}
`

const useMutationCreateLocation = () => useMutation(CREATE_LOCATION_MUTATION)

export { useMutationCreateLocation }
