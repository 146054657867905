import { useMutation, gql } from "@apollo/client"

const REGISTER_UPLOAD_MUTATION = gql`
  mutation RegisterUpload($fileName: String!, $fileSize: Int!) {
    registerUpload(fileName: $fileName, fileSize: $fileSize) {
      signedUploadUrl
      upload {
        id
        key
        fileName
        fileGroup
        contentType
      }
    }
  }
`

const useMutationRegisterUpload = () => useMutation(REGISTER_UPLOAD_MUTATION)

export { useMutationRegisterUpload }
