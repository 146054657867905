import React, { useState, Fragment, useEffect } from "react"
import { Box, Button, Divider, makeStyles, TextField } from "@material-ui/core"
import Config from "react-global-configuration"
import {
  Avatar,
  Caption,
  DeleteConfirmIconButton,
  FormatDateTimeCompact,
  Icon,
  InfoSection,
  Uploader,
  UploadingImagesList,
  UploadingList,
  ImagesViewer,
  DownloadList,
} from ".."
import { PaperBox, RowBox } from "../Boxes"
import { useMutationAssetAddNote, useMutationAssetDeleteNote } from "../../data/assets/useMutationAsset"

import { toId, mapToIds } from "../../utils"
import { useAuth } from "../../services"

const useStyles = makeStyles(() => ({
  divider: {
    width: "100%",
  },
  author: {
    width: 30,
    height: 30,
  },
  text: {
    textAlign: "left",
    fontSize: 12,
    wordBreak: "break-word",
    wordWrap: "break-word",
  },
  formUploading: {
    width: "100%",
  },
}))

const limitIncrement = 5

export const AssetNotes = ({ asset }) => {
  const classes = useStyles()
  const [text, setText] = useState("")
  const [limit, setLimit] = useState(0)
  const {
    principal: { firstName, lastName, avatar },
    location,
  } = useAuth()
  const [notes, setNotes] = useState([])

  const [uploads, setUploads] = useState([])
  const [uploading, setUploading] = useState([])

  // mutations
  const [addNote] = useMutationAssetAddNote()
  const [deleteNote, { loading: deleteLoading }] = useMutationAssetDeleteNote()

  const { clientKiosk, clientDevice } = Config.get()

  useEffect(() => {
    setNotes(asset?.notes?.length ? [...asset.notes].reverse().slice(0, limit) : [])
  }, [limit, asset])

  const handleAdd = async () => {
    const variables = {
      id: toId(asset),
      input: {
        text,
        uploads: mapToIds(uploads),
      },
    }
    setText("")
    setUploads([])
    setUploading([])

    if (!limit) {
      setLimit(limitIncrement)
    }
    await addNote({ variables })
  }

  const handleEnter = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      handleAdd()
    }
  }

  const isValid = Boolean(text?.trim())
  const moreCount = asset?.notes?.length - limit
  const hasNativeCamera = clientDevice || clientKiosk

  const handleDelete = async (note) => {
    await deleteNote({
      variables: {
        id: toId(asset),
        note: toId(note),
      },
    })

    setNotes(notes.filter((n) => n.id !== note.id))
  }

  const handleItemUploaded = (item) => {
    setUploads((state) => [...state, item])
    setUploading([...uploading.filter((upload) => toId(upload) !== toId(item))])
  }

  const handleDeviceUploaded = (items) => {
    setUploads((prev) => [...prev, ...items])
  }

  const handleRemoveUpload = (id) => {
    const newUploads = uploads.filter((upload) => toId(upload) !== id)
    setUploads([...newUploads])
    setUploading([...uploading.filter((upload) => toId(upload) !== id)])
  }

  return (
    <>
      <PaperBox>
        <InfoSection>
          <Box flexGrow={1}>
            <RowBox flexGrow={1}>
              <Box mr={1}>
                <Avatar {...{ firstName, lastName, avatar: { key: avatar } }} className={classes.author} />
              </Box>
              <TextField
                size="small"
                placeholder="Add an internal note..."
                variant="outlined"
                value={text}
                onChange={(event) => setText(event.target.value)}
                onKeyPress={handleEnter}
                data-cy="AssetNotes-text"
                fullWidth
              />
              <RowBox ml={1}>
                {hasNativeCamera && (
                  <Uploader
                    icon={<Icon name="camera" />}
                    cameraOnly
                    onDeviceUploaded={handleDeviceUploaded}
                    size="small"
                  />
                )}
                <Uploader onItemUploaded={handleItemUploaded} size="small" />

                <Button
                  onClick={handleAdd}
                  className={classes.submit}
                  data-cy="AssetNotes-add"
                  disabled={!isValid}
                  variant="contained"
                >
                  Add
                </Button>
              </RowBox>
            </RowBox>
            {uploads.length > 0 && (
              <Box pt={1} pb={1} px={1} className={classes.formUploading}>
                <UploadingImagesList
                  uploaded={uploads}
                  uploading={uploading}
                  height={80}
                  onRemoveUpload={handleRemoveUpload}
                />
                <UploadingList
                  uploaded={uploads}
                  uploading={uploading}
                  onRemoveUpload={handleRemoveUpload}
                  images={false}
                />
              </Box>
            )}
          </Box>
        </InfoSection>
        <Box data-cy="AssetNotes-notes">
          {notes.map((note, index) => (
            <Box key={toId(note)} data-cy={`AssetNote-${index}`}>
              <Divider className={classes.divider} />
              <InfoSection className={classes.note}>
                <Box flexGrow={1}>
                  <RowBox flexGrow={1}>
                    <Box mr={1}>
                      <Avatar {...note.author} className={classes.author} />
                    </Box>
                    <RowBox flexGrow={1} className={classes.text} mr={1}>
                      {note.text}
                    </RowBox>
                    <Caption mb={0} mr={1}>
                      <FormatDateTimeCompact value={note.createdAt} compactTodayTimeZone={location?.timeZone || null} />
                    </Caption>
                    <DeleteConfirmIconButton
                      onDelete={() => handleDelete(note)}
                      disabled={deleteLoading}
                      cy="AssetNote-delete"
                    />
                  </RowBox>
                  {note.uploads.length > 0 ? (
                    <Box pt={1}>
                      <ImagesViewer uploads={note.uploads} height={80} width={100} />
                      <DownloadList uploads={note.uploads} variant="chips" />
                    </Box>
                  ) : null}
                </Box>
              </InfoSection>
            </Box>
          ))}
        </Box>
        {moreCount > 0 && (
          <>
            <Divider />
            <InfoSection>
              <RowBox justifyContent="center" flexGrow={1}>
                <Button onClick={() => setLimit(limit + limitIncrement)} data-cy="AssetNotes-loadMore">
                  {limit === 0 && <>Show notes ({moreCount})</>}
                  {limit > 0 && <>Show more ({moreCount})</>}
                </Button>
              </RowBox>
            </InfoSection>
          </>
        )}
      </PaperBox>
    </>
  )
}
