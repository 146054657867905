import { offlineVar, offlineReconnectingVar } from "../../data/offlineVar"
import { useMountAwareReactiveVar } from "../../utils"

const useOffline = () => {
  const offline = useMountAwareReactiveVar(offlineVar)
  const reconnecting = useMountAwareReactiveVar(offlineReconnectingVar)

  return {
    offline,
    reconnecting,
  }
}

export { useOffline }
