import { gql } from "@apollo/client"

const USER_PREFERENCES_FIELDS = gql`
  fragment UserPreferencesFields on UserPreferences {
    hideCompletedSteps
    language
  }
`

export { USER_PREFERENCES_FIELDS }
