import { useQuery, gql } from "@apollo/client"
import { GROUP_FIELDS } from "./fragments/groupFieldsFragment"

const GROUPS_QUERY = gql`
  query Groups {
    groups {
      ...GroupFields
    }
  }
  ${GROUP_FIELDS}
`

const useQueryGroups = (options) => useQuery(GROUPS_QUERY, options)

export { useQueryGroups }
