import React, { useState } from "react"
import { makeStyles } from "@material-ui/styles"
import { Box } from "@material-ui/core"
import { Trans } from "@lingui/macro"

const useStyles = makeStyles(() => ({
  toggle: {
    cursor: "pointer",
    whiteSpace: "nowrap",
  },
}))

const mapNames = (names, separator) => {
  return names.map((name, index) => (
    <Box key={index} component="span">
      {index > 0 && separator}
      {name}
    </Box>
  ))
}

const TruncateNames = ({ names, max = 3, separator = ", ", emptyText = null }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  if (!names?.length > 0) {
    return emptyText
  }

  if (names.length <= max) {
    return mapNames(names, separator)
  }

  const truncatedNames = open ? names : names.slice(0, max)

  return (
    <>
      {mapNames(truncatedNames, separator)}
      <Box
        component="span"
        color="primary.main"
        ml={0.5}
        onClick={(e) => {
          e.stopPropagation()
          setOpen(!open)
        }}
        className={classes.toggle}
      >
        {open ? (
          <Trans>show less</Trans>
        ) : (
          <>
            +{names.length - max} <Trans>more</Trans>
          </>
        )}
      </Box>
    </>
  )
}

export { TruncateNames }
