import { useMutation, gql } from "@apollo/client"
import { CATEGORY_FIELDS } from "./useQueryCategories"

const UPDATECATEGORY_MUTATION = gql`
  mutation UpdateCategory(
    $id: ID!
    $name: String!
    $description: String
    $icon: String!
    $tags: [ID!]
    $groups: [ID!]
    $locations: [ID!]
  ) {
    updateCategory(
      id: $id
      name: $name
      description: $description
      icon: $icon
      tags: $tags
      groups: $groups
      locations: $locations
    ) {
      ...CategoryFields
    }
  }
  ${CATEGORY_FIELDS}
`

const useMutationUpdateCategory = () => useMutation(UPDATECATEGORY_MUTATION)

export { useMutationUpdateCategory }
