import { useMutation, gql } from "@apollo/client"

import { PREP_LIST_FIELDS } from "./fragments/prepListFieldsFragment"

const SAVE_AS_TEMPLATE_PREP_LIST_MUTATION = gql`
  mutation SaveAsTemplatePrepList($id: ID!, $input: SaveAsTemplateMutationInput!) {
    prepList(id: $id) {
      saveAsTemplate(input: $input) {
        ...PrepListFields
      }
    }
  }
  ${PREP_LIST_FIELDS}
`

const useMutationSaveAsTemplatePrepList = () => {
  return useMutation(SAVE_AS_TEMPLATE_PREP_LIST_MUTATION)
}

export { useMutationSaveAsTemplatePrepList }
