import React, { useState } from "react"
import { Dialog, DialogTitle, DialogContent, Box, Divider, DialogActions, Tabs, Tab, Button } from "@material-ui/core"
import { NavLink, useParams } from "react-router-dom"
import { Trans } from "@lingui/macro"
import { DialogTitleCloser } from "../Creators/DialogTitleCloser"
import { InlineTitleCloser } from "../Creators"
import { ErrorBoundary, InlineTitle, LoadingSpinner, RowBox } from ".."
import { UserPanel } from "../UserPanel"
import { useQueryAdminableUser, useMutationResendInvite } from "../../data"
import { toId } from "../../utils"
import { PersonTraining } from "./PersonTraining"
import { useAuth } from "../../services"
import { PersonAccreditation } from "./PersonAccreditation"
import { PersonTrainingAssessment } from "./PersonTrainingAssessment"
import { PersonDetails } from "./PersonDetails"

const PersonViewer = ({ id, onClose, isInline, active = "details", disabledDialogActions }) => {
  const { action } = useParams()
  const [resendInvite, { loading: sendInviteLoading }] = useMutationResendInvite()
  const {
    data: userData,
    loading: userLoading,
    refetch: refetchUser,
  } = useQueryAdminableUser({ variables: { id: toId(id) }, skip: !id })
  const [tab, setTab] = useState(action || active)
  const { hasFeature, canCreateRoles } = useAuth()

  const handleResendInvite = async () => {
    await resendInvite({ variables: { id } })
    await refetchUser()
  }

  const handleOnClose = (_, isCancel) => {
    onClose(isCancel)
  }

  const user = userData?.adminableUser || null
  const isCurrentUser = toId(user) === toId(id)
  const hasCreateRoles = canCreateRoles(user?.roles)
  const hasTraining = hasFeature("training") && hasCreateRoles
  const hasAccreditation = hasFeature("accreditation") && hasCreateRoles
  const hasTrainingAssessment = hasTraining && hasFeature("training_assessment") && hasCreateRoles
  const hasTabs = hasTraining || hasTrainingAssessment || hasAccreditation

  const hasEditDetails = tab === "details" && hasCreateRoles

  const display = (
    <>
      <Box px={isInline ? 2 : 0} py={isInline ? 2 : 0} data-cy="PersonViewer-display">
        <UserPanel user={user} layout="landscape" mb={2} />
        {hasTabs && (
          <Tabs
            variant="standard"
            indicatorColor="primary"
            textColor="primary"
            value={tab}
            onChange={(event, newTab) => setTab(newTab)}
            aria-label="Person details tabs"
            data-cy="PersonViewer-tabs"
          >
            <Tab label={<Trans>Details</Trans>} value="details" data-cy="Tab-details" />
            {hasTraining && <Tab label={<Trans>Training courses</Trans>} value="training" data-cy="Tab-training" />}
            {hasTrainingAssessment && (
              <Tab
                label={<Trans>Training assessment</Trans>}
                value="training-assessment"
                data-cy="Tab-training-assessment"
              />
            )}
            {hasAccreditation && (
              <Tab label={<Trans>Accreditations</Trans>} value="accreditation" data-cy="Tab-accreditation" />
            )}
          </Tabs>
        )}
        {hasTabs && <Divider />}

        <ErrorBoundary>
          <Box pt={2} data-cy="PersonViewer-page">
            {tab === "details" && (
              <PersonDetails user={user} onResendInvite={handleResendInvite} sendInviteLoading={sendInviteLoading} />
            )}
            {tab === "training" && <PersonTraining user={user} />}
            {tab === "training-assessment" && <PersonTrainingAssessment user={user} />}
            {tab === "accreditation" && <PersonAccreditation user={user} />}
          </Box>
        </ErrorBoundary>
      </Box>
    </>
  )

  const loadingDisplay = (
    <Box display="flex" justifyContent="center">
      <LoadingSpinner size={60} delay={false} />
    </Box>
  )

  if (isInline) {
    return (
      <>
        <InlineTitle>
          Staff details
          <InlineTitleCloser onClose={handleOnClose} />
        </InlineTitle>
        {userLoading && !isCurrentUser && loadingDisplay}
        {isCurrentUser && display}
        {hasEditDetails && (
          <RowBox pr={2} pb={2} justifyContent="flex-end">
            <Button component={NavLink} variant="contained" color="primary" to={`/people/${id}/edit`}>
              Edit details
            </Button>
          </RowBox>
        )}
      </>
    )
  }

  return (
    <Dialog open={id !== null} onClose={handleOnClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
      <DialogTitle id="form-dialog-title">
        Staff details
        <DialogTitleCloser onClose={handleOnClose} />
      </DialogTitle>
      <DialogContent>
        {userLoading && !isCurrentUser && loadingDisplay}
        {isCurrentUser && display}
      </DialogContent>
      {hasEditDetails && !disabledDialogActions && (
        <DialogActions>
          <Box pt={1} pr={2} pb={2}>
            <Button component={NavLink} variant="contained" color="primary" to={`/people/${id}/edit`}>
              Edit details
            </Button>
          </Box>
        </DialogActions>
      )}
    </Dialog>
  )
}

export { PersonViewer }
