import { useQuery, useLazyQuery, gql } from "@apollo/client"

const REPORTUSERSDAYSOVERVIEW_FIELDS = `
  id
  days {
    day { year month day }
    stepsCompleted
    stepsOverdue
    stepsSkipped    
    stepsUncompleted    
  }
`

const REPORTUSERSDAYSOVERVIEW_QUERY = gql`
  query ReportUsersDaysOverview($users: [ID!]!, $locations: [ID!]!, $groups: [ID!], $gt: ScheduleDateInput!, $lt: ScheduleDateInput) {
    reportUsersDaysOverview(users: $users, locations: $locations, groups: $groups, gt: $gt, lt: $lt)
    {
      ${REPORTUSERSDAYSOVERVIEW_FIELDS}
    } 
  }
`

const useQueryReportUsersDaysOverview = (options) =>
  useQuery(REPORTUSERSDAYSOVERVIEW_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

const useLazyQueryReportUsersDaysOverview = (options) =>
  useLazyQuery(REPORTUSERSDAYSOVERVIEW_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

export { useQueryReportUsersDaysOverview, useLazyQueryReportUsersDaysOverview }
