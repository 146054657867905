import React, { useState, useEffect, memo, useMemo } from "react"
import {
  FormControlLabel,
  Grid,
  Chip,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItemAvatar,
  ListItemText,
  Divider,
  Box,
  DialogActions,
  ListItemSecondaryAction,
  Tooltip,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { Trans, Plural } from "@lingui/macro"
import { useMutationConfirmNotification } from "../../data"
import { Checkbox, Avatar, FormatDateTime, ColumnBox, SearchInput } from ".."
import { toId, usePostUtils } from "../../utils"
import { LinkButton } from "../LinkButton"
import { PickerListItem } from "../PickerListItems"
import { Caption, FieldSectionHeading } from "../Headings"
import { DialogTitleCloser } from "../Creators"
import TimeAgo from "../TimeAgo"

const useCheckboxLabelStyles = makeStyles((theme) => ({
  label: {
    fontSize: 16,
    color: theme.palette.text.primary,
    fontWeight: "600",
  },
}))

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  confirmed: {
    fontSize: 13,
    fontWeight: "400",
    color: theme.palette.text.secondary,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  content: {
    marginBottom: theme.spacing(4),
  },
  sendingChip: {
    fontWeight: "600",
    color: "white",
    backgroundColor: theme.palette.primary.main,
  },
  unconfirmedChip: {
    color: "white",
    backgroundColor: theme.palette.error.main,
    cursor: "pointer",
  },
  confirmedChip: {
    color: "white",
    backgroundColor: theme.palette.success.main,
    cursor: "pointer",
  },
}))

const useDialogStyles = makeStyles((theme) => ({
  dialog: {
    width: theme.dimensions.dialogs.pickers.width,
    marginLeft: ({ xs }) => (xs ? 0 : null),
    marginRight: ({ xs }) => (xs ? 0 : null),
    height: 600,
  },
  confirmed: {
    color: theme.palette.success.main,
  },
  unconfirmed: {
    color: theme.palette.error.main,
  },
  name: {
    maxWidth: 150,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  date: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginLeft: theme.spacing(1),
    right: 0,
    cursor: "default",
  },
  mention: {
    textDecoration: "underline",
    textDecorationStyle: "dashed",
    textUnderlineOffset: "2px",
    cursor: "default",
  },
}))

const PostConfirmedDialog = ({ post, open, onClose }) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const classes = useDialogStyles({ xs })

  const [searchText, setSearchText] = useState("")

  const confirmedItems = useMemo(
    () =>
      [...post.confirmed]
        .filter(({ user }) => user.fullName.toLowerCase().includes(searchText.toLowerCase()))
        .sort(({ user: a }, { user: b }) => a.fullName.localeCompare(b.fullName)),
    [post, searchText]
  )

  const unconfirmedItems = useMemo(
    () =>
      [...post.unconfirmed]
        .filter(({ user }) => user.fullName.toLowerCase().includes(searchText.toLowerCase()))
        .sort(({ user: a }, { user: b }) => a.fullName.localeCompare(b.fullName)),
    [post, searchText]
  )

  const handleClose = () => onClose()

  const handleSearchChange = (text) => {
    if (text === searchText) return
    setSearchText(text)
  }

  const titleConfirmed = post.confirmed.length
  const titleuUnconfirmed = post.unconfirmed.length

  const confirmed = confirmedItems.length
  const unconfirmed = unconfirmedItems.length
  const total = titleConfirmed + titleuUnconfirmed

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle>
        <Trans>
          {confirmed} / {total} confirmed
        </Trans>
        <DialogTitleCloser onClose={handleClose} />
      </DialogTitle>
      <DialogContent>
        <SearchInput
          initialValue={searchText}
          placeholder="Search staff"
          onChange={handleSearchChange}
          autoFocus
          boxProps={{ pl: 0, pr: 0, mb: 2 }}
        />
        {confirmed > 0 && (
          <Box mb={1}>
            <FieldSectionHeading className={classes.confirmed}>
              <Trans>Confirmed</Trans>
            </FieldSectionHeading>
            <List>
              {confirmedItems.map((item) => (
                <UserListItemMemo key={item.id} user={item.user} date={item.confirmed} />
              ))}
            </List>
          </Box>
        )}
        {confirmed > 0 && unconfirmed > 0 && <Divider />}
        {unconfirmed > 0 && (
          <Box mt={2}>
            <FieldSectionHeading className={classes.unconfirmed}>
              <Trans>Unconfirmed</Trans>
            </FieldSectionHeading>
            <List>
              {unconfirmedItems.map((item) => (
                <UserListItemMemo key={item.id} user={item.user} />
              ))}
            </List>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box py={1} px={3}>
          <LinkButton onClick={handleClose}>
            <Trans>Close</Trans>
          </LinkButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

const UserListItem = ({ user, date }) => {
  const classes = useDialogStyles()

  return (
    <PickerListItem>
      <ListItemAvatar>
        <Avatar {...user} />
      </ListItemAvatar>
      <ColumnBox>
        <ListItemText classes={{ primary: classes.name }}>{user.fullName}</ListItemText>
        {user?.locations?.length === 1 && <Caption mb={0}>{user.locations[0].name}</Caption>}
        {user?.locations?.length > 1 && (
          <Tooltip title={user.locations.map((location) => location.name).join(", ")}>
            <div>
              <Caption className={classes.mention} mb={0}>{`${user.locations.length} locations`}</Caption>
            </div>
          </Tooltip>
        )}
      </ColumnBox>

      {date && (
        <Tooltip title={<FormatDateTime value={date} />}>
          <ListItemSecondaryAction className={classes.date}>
            {/* hide title because we have tooltip */}
            <TimeAgo date={date} compact hideTitle />
          </ListItemSecondaryAction>
        </Tooltip>
      )}
    </PickerListItem>
  )
}

const UserListItemMemo = memo(UserListItem)

const PostConfirm = ({ post }) => {
  const classes = useStyles()
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down("sm"))
  const {
    hasCurrentUserConfirmedPost,
    hasCurrentUserReceivedPostNotification,
    getCurrentUserUnconfirmedNotification,
    isCurrentUserAuthorOf,
  } = usePostUtils()
  const checkboxLabelClasses = useCheckboxLabelStyles()
  const [userHasConfirmed, setUserHasConfirmed] = useState(
    hasCurrentUserConfirmedPost(post) && !getCurrentUserUnconfirmedNotification(post),
  )
  const [postUpdatedAt, setPostUpdatedAt] = useState(post.updatedAt)
  const [userNotNotified, setUserNotNotified] = useState(true)
  const [confirmNotification] = useMutationConfirmNotification()
  const [isAuthor] = useState(isCurrentUserAuthorOf(post))
  const [openConfirmed, setOpenConfirmed] = useState(false)

  useEffect(() => {
    if (hasCurrentUserConfirmedPost(post) && !getCurrentUserUnconfirmedNotification(post) && !userHasConfirmed) {
      setUserHasConfirmed(true)
    }
    if (userHasConfirmed && postUpdatedAt !== post.updatedAt) {
      setUserHasConfirmed(false)
    }
  }, [getCurrentUserUnconfirmedNotification, hasCurrentUserConfirmedPost, post, postUpdatedAt, userHasConfirmed])

  useEffect(() => {
    setUserNotNotified(!hasCurrentUserReceivedPostNotification(post))
  }, [hasCurrentUserReceivedPostNotification, post])

  useEffect(() => {
    if (postUpdatedAt !== post.updatedAt) {
      setPostUpdatedAt(post.updatedAt)
    }
  }, [post.updatedAt, postUpdatedAt])

  if (!post.hasConfirm) return null

  const handleConfirm = () => {
    setUserHasConfirmed(true)
    const notification = getCurrentUserUnconfirmedNotification(post)
    if (notification) {
      confirmNotification({
        variables: { id: toId(notification) },
      })
    }
  }

  const handleShowConfirmedClick = () => {
    setOpenConfirmed(true)
  }
  const handleShowConfirmedClose = () => {
    setOpenConfirmed(false)
  }

  const confirmed = post.confirmed.length
  const unconfirmed = post.unconfirmed.length
  const total = confirmed + unconfirmed
  const allConfirmed = post.hasSentConfirms && confirmed === total

  return (
    <>
      <PostConfirmedDialog post={post} open={openConfirmed} onClose={handleShowConfirmedClose} />

      <Grid container direction="row" alignItems="center" justifyContent="space-between" className={classes.root}>
        <Grid item sm={12} md={7}>
          {isAuthor && (
            <div className={checkboxLabelClasses.label}>
              <Plural
                value={total}
                one="You requested read confirmation for this post"
                other="You requested read confirmations for this post"
              />
            </div>
          )}
          {!isAuthor && !userNotNotified && (
            <FormControlLabel
              classes={checkboxLabelClasses}
              label={<Trans>I have read and confirmed</Trans>}
              control={<Checkbox type="confirm" checked={userHasConfirmed} onChange={handleConfirm} />}
            />
          )}
        </Grid>
        <Grid
          item
          sm={12}
          md={5}
          container
          direction="row"
          alignItems="center"
          justifyContent={sm ? "space-between" : "flex-end"}
          spacing={1}
        >
          {!post.hasSentConfirms && (
            <>
              <Grid item>
                <Chip label={<Trans>Sending requests</Trans>} className={classes.sendingChip} />
              </Grid>
            </>
          )}
          {post.hasSentConfirms && (
            <>
              <Grid item>
                {!allConfirmed && (
                  <LinkButton onClick={handleShowConfirmedClick} className={classes.confirmed}>
                    <Trans>
                      {confirmed} / {total} staff have confirmed
                    </Trans>
                  </LinkButton>
                )}
              </Grid>
              <Grid item>
                <LinkButton onClick={handleShowConfirmedClick}>
                  {!allConfirmed && !userHasConfirmed && (
                    <Chip label={<Trans>Unconfirmed</Trans>} className={classes.unconfirmedChip} />
                  )}
                  {userHasConfirmed && !allConfirmed && (
                    <Chip label={<Trans>You confirmed</Trans>} className={classes.confirmedChip} />
                  )}
                  {allConfirmed && (
                    <Chip label={<Trans>All staff confirmed</Trans>} className={classes.confirmedChip} />
                  )}
                </LinkButton>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default PostConfirm
