import Base from "./Base"

export default {
  httpBaseUri: "https://api.operandio.com",
  httpServiceBaseUri: "https://api.operandio.com/graphql",
  wsServiceBaseUri: "wss://api.operandio.com/graphql",

  showConfigInTitle: true,
  configTitle: "[LOCAL LIVE]",

  theme: {
    ...Base.theme,

    logoContainer: "images-dev",
  },

  uploader: {
    ...Base.uploader,

    imageViewerBaseUri: "https://dydc66wtc7q9n.cloudfront.net",
    imageViewerBucketName: "operandio-content-ap-southeast-2",
  },

  stripe: {
    publishableKey:
      "pk_live_51GxNIsCmvyAu99J00nBfNgcypgin24GX4q97w3Dxa0Whoq5OsOtZSsGzpZaVbTwh1PGZ71WcrKGma5YJQ2BktMtn00bimwwkVm",
  },

  errors: {
    ...Base.errors,

    showDetail: true,
  },
}
