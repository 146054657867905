import { gql } from "@apollo/client"

const SENSOR_MOBILE_LOCATION_FIELDS = gql`
  fragment SensorMobileLocationFields on SensorMobileLocation {
    mobileCountryCode
    mobileNetworkCode
    locationAreaCode
    cellId
  }
`

const SENSOR_FIELDS = gql`
  fragment SensorFields on Sensor {
    id
    serial
    name
    type
    product
    location {
      id
    }
    sample {
      id
      value
      battery
      signal
      at
      type
      lbs {
        ...SensorMobileLocationFields
      }
      displayUnit
    }
    samples {
      id
      value
      battery
      signal
      at
      type
      lbs {
        ...SensorMobileLocationFields
      }
      displayUnit
    }
  }
  ${SENSOR_MOBILE_LOCATION_FIELDS}
`

const SENSOR_DETAIL_FIELDS = gql`
  fragment SensorDetailFields on SensorDetail {
    id
    serial
    name
    type
    product
    location {
      id
    }
    sample {
      id
      value
      battery
      signal
      at
      type
      lbs {
        ...SensorMobileLocationFields
      }
      displayUnit
    }
    samples {
      id
      value
      battery
      signal
      at
      type
      lbs {
        ...SensorMobileLocationFields
      }
      displayUnit
    }
  }
  ${SENSOR_MOBILE_LOCATION_FIELDS}
`

export { SENSOR_FIELDS, SENSOR_MOBILE_LOCATION_FIELDS, SENSOR_DETAIL_FIELDS }
