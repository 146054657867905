import { Box, Button } from "@material-ui/core"
import { RowBox } from "../Boxes"
import { Icon } from "../Icon"

const TablePagination = ({ children, count, rowsPerPage, page, boxProps = {}, onPageChange, disabled }) => {
  const handlePageChange = (event, newPage) => {
    onPageChange && onPageChange(event, newPage)
  }

  const hasPrev = page > 0
  const hasNext = page < Math.floor(count / rowsPerPage)

  return (
    <RowBox justifyContent="space-between" {...boxProps}>
      <Box>
        {hasPrev && (
          <Button
            color="primary"
            onClick={(event) => handlePageChange(event, page - 1)}
            disabled={disabled || page < 1}
            size="large"
          >
            <RowBox>
              <Icon name="chevron-left" />
              <Box ml={1}>Prev</Box>
            </RowBox>
          </Button>
        )}
      </Box>
      <Box>{children}</Box>
      <Box>
        {hasNext && (
          <Button
            color="primary"
            onClick={(event) => handlePageChange(event, page + 1)}
            disabled={disabled || page >= Math.ceil(count / rowsPerPage)}
            size="large"
          >
            <RowBox>
              <Box mr={1}>Next</Box>
              <Icon name="chevron-right" />
            </RowBox>
          </Button>
        )}
      </Box>
    </RowBox>
  )
}

export { TablePagination }
