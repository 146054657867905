import React from "react"
import { Radio } from "@material-ui/core"
import { PickerListItem } from "."

const RadioPickerListItem = ({ item, checked, onChange, children }) => (
  <PickerListItem>
    <Radio checked={checked} color="primary" onChange={(e) => onChange(e, item)} />
    {children}
  </PickerListItem>
)

export { RadioPickerListItem }
