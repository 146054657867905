import { useLazyQuery, useQuery, gql } from "@apollo/client"
import { NOTIFICATION_FIELDS } from "./useQueryNotifications"

const NOTIFICATION_QUERY = gql`query Notification($id: ID!) {
  ${NOTIFICATION_FIELDS}
  notification(id: $id) {
    ...NotificationFields
  } 
}`

const useQueryNotification = (options) => useQuery(NOTIFICATION_QUERY, options)

const useLazyQueryNotification = (options) => useLazyQuery(NOTIFICATION_QUERY, options)

export { useQueryNotification, useLazyQueryNotification, NOTIFICATION_QUERY }
