import { useLazyQuery, gql } from "@apollo/client"

const POTENTIAL_NOTIFICATIONS_COUNT_QUERY = gql`
  query PotentialNotificationsCount($filter: PotentialNotificationsCountInput!) {
    potentialNotificationsCount(filter: $filter)
  }
`

const useLazyQueryPotentialNotifications = () => useLazyQuery(POTENTIAL_NOTIFICATIONS_COUNT_QUERY)

export { useLazyQueryPotentialNotifications }
