const host = `${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}`

export default {
  host,

  httpBaseUri: "unset",
  httpServiceBaseUri: "unset",
  wsServiceBaseUri: "unset",

  clientBaseUri: "unset",
  clientId: "OperandioWebClient",
  clientKiosk: false,
  clientDevice: false,

  deviceBaseUri: "com.operandio.app://",

  showConfigInConsole: false,
  showConfigInTitle: false,

  dataClient: {
    retry: {
      enabled: false,
    },
    throwIfNoOperationName: false,
    connectToDevTools: false,
    webClientHeader: "x-op-webclient",
    deviceClientHeader: "x-op-deviceclient",
  },

  linkDownloadApp: {
    ios: "https://apps.apple.com/au/app/operandio/id1515263737",
    android: "https://play.google.com/store/apps/details?id=com.operandio.app&hl=en_US",
  },

  auth: {
    idleTimeoutMs: false,
    // idleTimeoutMs: (1000 * 60) * 15, //  15 minutes
    refreshOffset: 1000 * 60 * 3, // 3 minutes
    refreshMinimumMs: 1000 * 60 * 5, // 5 minutes
    pinLength: 4,
    device: {
      register: {
        passcodeLength: 10,
      },
    },
    password: {
      reset: {
        passcodeLength: 6,
      },
    },
  },

  authProviders: {
    external: {
      redirect_uri: `https://${host}/login-result`,
      response_type: "code",
      scope: "openid profile email",
      filterProtocolClaims: true,
      loadUserInfo: true,
    },
    google: {
      authority: "https://accounts.google.com",
      client_id: "829642099593-gpo9pioqi5g1o9k0f01ddsoteilqiamo.apps.googleusercontent.com",
      login_redirect_uri: `https://${host}/google`,
      signup_redirect_uri: `https://${host}/signup/google`,
      post_logout_redirect_uri: `https://${host}/`,
      response_type: "id_token",
      scope: "openid profile email",
      filterProtocolClaims: true,
      loadUserInfo: true,
      prompt: "select_account",
    },
    microsoft: {
      authority: "https://login.microsoftonline.com/234873ad-da2e-4910-9e38-d068b4caba3b/v2.0",
      client_id: "557608e7-1534-45cc-b9ba-d368f7ab49fc",
      login_redirect_uri: `https://${host}/microsoft`,
      signup_redirect_uri: `https://${host}/signup/microsoft`,
      post_logout_redirect_uri: `https://${host}/`,
      response_type: "code",
      scope: "openid profile email",
      filterProtocolClaims: true,
      loadUserInfo: true,
      prompt: "select_account",
    },
  },

  version: {
    check: {
      enabled: true,
      intervalMs: 1000 * 30, // 30 seconds
      // intervalMs: (2000), // 2 seconds, for testing
    },
  },

  polling: {
    jobsMs: 15000,
  },

  theme: {
    logoContainer: "unset",
  },

  uploader: {
    acceptImages: [".png", ".jpg", ".jpeg"],
    acceptDocuments: [
      ".pdf",
      ".doc",
      ".docx",
      ".odt",
      ".pages",
      ".xls",
      ".xlsx",
      ".xlt",
      ".xltx",
      ".ods",
      ".ots",
      ".zip",
      ".gz",
      ".xz",
    ],

    imageViewerBaseUri: "unset",
    imageViewerBucketName: "unset",
  },

  templates: {
    featuredCategory: "5f7d4d9c5d6817e2fb4438ba",
  },

  links: {
    terms: "https://operandio.com/terms",
    privacy: "https://operandio.com/privacy-policy",
    help: "https://help.operandio.com",
    emailSupport: "support@operandio.com",
    emailSensorSupport: "temperature@operandio.com",
    phoneSensorSupport: "+61 3 9071 3051",
    kiosk: {
      login: "operandio://kiosk/login-kiosk",
      logout: "operandio://kiosk/login-kiosk",
    },
    device: {
      login: "operandio://device/login-device",
      logout: "operandio://device/logout-device",
      reauth: "operandio://device/reauth-device",
    },
  },

  stripe: {
    publishableKey:
      "pk_test_51GxNIsCmvyAu99J0zSm3s4WkoLqlUVQivMB7NC2mNLHStEivCR56Zw6E64KmUIKY3WgHbxctV3mCdPIgYpdosUpJ00cIRCMU4A",
  },

  announceKit: {
    widget: "https://announcekit.co/widgets/v2/3PpfUI",
  },

  services: {
    localisationUrl: "https://services.operandio.com/localisation",
    export: {
      jobUrl: "https://nig1o9pqw8.execute-api.ap-southeast-2.amazonaws.com/prod/job/", // LIVE account
      auditUrl: "https://nig1o9pqw8.execute-api.ap-southeast-2.amazonaws.com/prod/auditscores/", // LIVE account
    },
  },

  google: {
    mapsApiKey: "AIzaSyBI3STyewxhrfNJcP1hfJbyol59Y5oynTk",
    translateApiKey: "AIzaSyBr0DC179X1gkWNTPvBa_2oaH5R0dPvSoc",
  },

  analytics: {
    mixpanel: {
      enabled: true,
      token: "cea0afc88d07146d8ee72a4e97a984e5",
      settings: { debug: true, track_pageview: true, persistence: "localStorage" },
    },
  },

  sensors: {
    battery: {
      warnLowPercent: 10,
    },
    at: {
      warnAgoMaxMin: 45,
    },
  },

  printing: {
    labels: {
      minimum: 1,
      maximum: 50,
      demonstrationMaximum: 2,
    },
  },

  errors: {
    showDetail: false,
    showGraphQLErrorNotification: false,
  },
}
