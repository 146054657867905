import React, { memo } from "react"
import Config from "react-global-configuration"
import MuiAvatar from "@material-ui/core/Avatar"
import { AccountCircle } from "@material-ui/icons"
import { makeStyles } from "@material-ui/core"
import { Tooltip } from "../Tooltip"
import { getImageSource } from ".."

const useStyles = makeStyles((theme) => ({
  colorDefault: {
    backgroundColor: theme.palette.grey[400],
    color: theme.palette.text.primary,
  },
  img: {
    opacity: 1,
    animationName: ({ kiosk, device }) => (kiosk || device ? null : "fadeInOpacity"),
    animationIterationCount: 1,
    animationTimingFunction: "ease-in",
    animationDuration: "0.35s",
  },
}))

const Avatar = memo(function Avatar({
  className,
  avatar,
  firstName,
  lastName,
  fullName,
  identityProvider,
  usernameChange,
  activeAt,
  icon,
  number,
  inactive,
  external,
  ...rest
}) {
  const { clientKiosk: kiosk, clientDevice: device } = Config.get()
  const { imageViewerBaseUri, imageViewerBucketName } = Config.get("uploader")
  const classes = useStyles({ kiosk, device })

  const externalTitle = external ? " (External)" : ""

  if (avatar && avatar.key) {
    return (
      <Tooltip title={fullName ? `${fullName}${externalTitle}` : `${firstName} ${lastName}${externalTitle}`}>
        <MuiAvatar
          classes={classes}
          className={className}
          alt={fullName || `${firstName} ${lastName}`}
          src={getImageSource(imageViewerBaseUri, imageViewerBucketName, avatar, "cover", 80, 80)}
          {...rest}
        />
      </Tooltip>
    )
  }
  if (firstName && lastName) {
    return (
      <Tooltip title={`${firstName} ${lastName}${externalTitle}`}>
        <MuiAvatar classes={classes} className={className} {...rest}>
          {firstName.charAt(0).toUpperCase()}
          {lastName.charAt(0).toUpperCase()}
        </MuiAvatar>
      </Tooltip>
    )
  }
  if (fullName) {
    return (
      <Tooltip title={`${fullName}${externalTitle}`}>
        <MuiAvatar classes={classes} className={className} {...rest}>
          {fullName.charAt(0).toUpperCase()}
        </MuiAvatar>
      </Tooltip>
    )
  }
  if (icon) {
    return (
      <MuiAvatar classes={classes} className={className} {...rest}>
        {icon}
      </MuiAvatar>
    )
  }
  if (number) {
    return (
      <MuiAvatar className={className} {...rest}>
        {number}
      </MuiAvatar>
    )
  }
  return (
    <MuiAvatar classes={classes} className={className} {...rest}>
      <AccountCircle />
    </MuiAvatar>
  )
})

export { Avatar }
