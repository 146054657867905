import React from "react"
import { GroupsDisplay } from "."
import { useQueryGroups } from "../../data"
import { useAuth } from "../../services"
import { toId } from "../../utils"

const DeviceGroupsDisplay = () => {
  const {
    location,
    settings: { deviceGroups },
  } = useAuth()
  const { data } = useQueryGroups()

  if (!deviceGroups || !data) {
    return location?.name || null
  }

  return (
    <GroupsDisplay
      groups={deviceGroups.map((deviceGroup) => data.groups.find((group) => toId(deviceGroup) === toId(group)))}
    />
  )
}

export { DeviceGroupsDisplay }
