// fileInfo = { uri, type, name }
export const uploadToS3PresignedUrl = async (signedUploadUrl, fileInfo) =>
  new Promise((resolve, reject) => {
    const request = new XMLHttpRequest()
    request.onreadystatechange = function () {
      if (request.readyState === 4) {
        if (request.status === 200) {
          resolve(request.response)
        } else {
          reject(request.response)
        }
      }
    }
    request.open("PUT", signedUploadUrl)
    request.setRequestHeader("Content-Type", fileInfo.type)
    request.send(fileInfo)
  })
