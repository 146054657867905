import React from "react"
import Config from "react-global-configuration"
import * as Sentry from "@sentry/browser"
import { Button, Box } from "@material-ui/core"
import { ErrorContent } from "."

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
    const { showDetail } = Config.get("errors")
    this.showDetail = showDetail
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error, info })

    console.log("[ERRORBOUNDARY]", error, info)

    Sentry.withScope((scope) => {
      scope.setExtras(info)
      Sentry.captureException(error)
    })
  }

  render() {
    const { hasError, error, info } = this.state
    const { showDetail } = this

    if (hasError) {
      return (
        <ErrorContent>
          <Box mb={6}>
            <p>An unhandled exception has occurred in the client application.</p>
            <p>
              Our technicians have been automatically notified of the problem, and will work to resolve it as soon as
              possible.
            </p>
            <p>Please try reloading the page using the button below to continue.</p>
            <Box mt={3}>
              <Button onClick={() => window.location.reload(true)} variant="contained">
                Reload Page
              </Button>
            </Box>
          </Box>
          {showDetail && error && info && (
            <div className="error-detail format-text">
              <h2 className="h4">Exception Detail</h2>
              {error.toString()}
              <br />
              {info.componentStack}
            </div>
          )}
        </ErrorContent>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
