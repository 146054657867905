import React from "react"
import { Box, Collapse } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"

const MissingLocationAccessBlockquote = ({ show = false, subject = "process", mb = 3 }) => (
  <Collapse in={show}>
    <Box mb={mb}>
      <Alert severity="error">
        <AlertTitle>You cannot edit this {subject}</AlertTitle>
        You cannot edit this {subject} as it is assigned to one or more locations that you don't have access to. Please
        contact your system administrator.
      </Alert>
    </Box>
  </Collapse>
)

export { MissingLocationAccessBlockquote }
