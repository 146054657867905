import Config from "react-global-configuration"
import * as Sentry from "@sentry/browser"
import { WEB_POSTMESSAGE_TYPE } from "../data/enums"
import { useMountEffect } from "./useMountEffect"

function useDeviceUtils() {
  const { clientKiosk, clientDevice } = Config.get()

  useMountEffect(() => {
    if (!window.receiveDeviceMessage) {
      window.receiveDeviceMessage = function handleDeviceMessage(data) {
        if (!data) {
          Sentry.captureMessage("useDeviceUtils handleMessage did not include any data")
          return
        }

        let parsedData
        try {
          parsedData = JSON.parse(data)
        } catch (error) {
          console.log(error)
          Sentry.captureException(error)
          return
        }

        const messageEvent = new CustomEvent("deviceMessage", {
          detail: parsedData,
        })

        window.dispatchEvent(messageEvent)
      }
    }
  })

  const isOS = (os) => {
    if (!window.deviceClientInfo?.platform?.os) {
      return false
    }

    if (Array.isArray(os)) {
      return os.includes(window.deviceClientInfo?.platform?.os)
    }

    return window.deviceClientInfo?.platform?.os === os
  }

  const parseVersion = () => {
    if (!window.deviceClientInfo?.app?.version) {
      return [0, 0, 0]
    }

    const versionParts = window.deviceClientInfo?.app?.version.split(".").map((v) => parseInt(v, 10))

    if (versionParts.length < 3) {
      return [0, 0, 0]
    }

    return versionParts
  }

  const isVersion = (major, minor, patch) => {
    const [majorVersion, minorVersion, patchVersion] = parseVersion()

    return majorVersion === major && minorVersion === minor && patchVersion === patch
  }

  const isLessThanVersion = ({ major, minor, patch }) => {
    const [majorVersion, minorVersion, patchVersion] = parseVersion()

    if (majorVersion < major) {
      return true
    }

    if (majorVersion === major && minorVersion < minor) {
      return true
    }

    if (majorVersion === major && minorVersion === minor && patchVersion < patch) {
      return true
    }

    return false
  }

  const canPostMessage = () => !!window.ReactNativeWebView?.postMessage

  const postMessage = (data) => {
    if (!canPostMessage()) return

    window.ReactNativeWebView.postMessage(JSON.stringify(data))
  }

  const postImagesMessage = (uploads, imageIndex = 0) => {
    if ((!clientKiosk && !clientDevice) || !canPostMessage() || !uploads?.length > 0) {
      return
    }

    postMessage({
      type: WEB_POSTMESSAGE_TYPE.IMAGES,
      payload: {
        images: [...uploads],
        imageIndex,
      },
    })
  }

  const postDownloadMessage = (payload) => {
    if ((!clientKiosk && !clientDevice) || !canPostMessage() || !payload) return

    postMessage({
      type: WEB_POSTMESSAGE_TYPE.DOWNLOAD,
      payload,
    })
  }

  // payload = { multiple: bool, maximum: number, message: string, camera: user|environment }
  const postTakePhotosMessage = (payload) => {
    if ((!clientKiosk && !clientDevice) || !canPostMessage()) return

    postMessage({
      type: WEB_POSTMESSAGE_TYPE.TAKE_PHOTOS,
      payload,
    })
  }

  const postPrintLabelsMessage = ({ printer, job }) => {
    if ((!clientKiosk && !clientDevice) || !canPostMessage() || !printer || !job) {
      return
    }

    postMessage({
      type: WEB_POSTMESSAGE_TYPE.PRINT_LABELS,
      payload: {
        printer,
        job,
      },
    })
  }

  const postRouteChangedMessage = (path) => {
    if ((!clientKiosk && !clientDevice) || !canPostMessage()) return

    postMessage({
      type: WEB_POSTMESSAGE_TYPE.ROUTE_CHANGED,
      payload: {
        path,
      },
    })
  }

  const postFindPrintersMessage = () => {
    if ((!clientKiosk && !clientDevice) || !canPostMessage()) return

    let type = WEB_POSTMESSAGE_TYPE.FIND_PRINTERS

    // patch for undefined FIND_PRINTERS with earlier app versions
    try {
      if (isLessThanVersion({ major: 1, minor: 1, patch: 5 })) {
        type = WEB_POSTMESSAGE_TYPE.FIND_PRINTERS_UNDEFINED
      }
    } catch (error) {
      console.error(error)
      Sentry.captureException(error)
    }

    postMessage({
      type,
    })
  }

  const postSelectPrinterMessage = (printer) => {
    if ((!clientKiosk && !clientDevice) || !canPostMessage()) return

    postMessage({
      type: WEB_POSTMESSAGE_TYPE.SELECT_PRINTER,
      payload: {
        printer,
      },
    })
  }

  const postFindProbesMessage = () => {
    if ((!clientKiosk && !clientDevice) || !canPostMessage()) return

    postMessage({
      type: WEB_POSTMESSAGE_TYPE.FIND_PROBES,
    })
  }

  const postStartReadingProbeValueMessage = (payload) => {
    if ((!clientKiosk && !clientDevice) || !canPostMessage()) return

    postMessage({
      type: WEB_POSTMESSAGE_TYPE.START_READING_PROBE_VALUE,
      payload,
    })
  }

  const postStopReadingProbeValueMessage = (payload) => {
    if ((!clientKiosk && !clientDevice) || !canPostMessage()) return

    postMessage({
      type: WEB_POSTMESSAGE_TYPE.STOP_READING_PROBE_VALUE,
      payload,
    })
  }

  const postPongMessage = () => {
    if ((!clientKiosk && !clientDevice) || !canPostMessage()) return

    postMessage({
      type: WEB_POSTMESSAGE_TYPE.PONG,
    })
  }

  return {
    canPostMessage,
    postImagesMessage,
    postDownloadMessage,
    postTakePhotosMessage,
    postPrintLabelsMessage,
    postRouteChangedMessage,
    postFindPrintersMessage,
    postSelectPrinterMessage,
    postFindProbesMessage,
    postStartReadingProbeValueMessage,
    postStopReadingProbeValueMessage,
    postPongMessage,

    deviceClientInfo: window.deviceClientInfo,
    isVersion,
    isLessThanVersion,
    isOS,
  }
}

export { useDeviceUtils }
