import React from "react"
import { makeStyles } from "@material-ui/styles"
import { useTheme, Badge } from "@material-ui/core"
import { Avatar, Icon } from ".."

const useBadgeStyles = makeStyles(() => ({
  badge: {
    right: 6,
    top: 36,
  },
}))

const UserAvatar = ({ external, ...user }) => {
  const classes = useBadgeStyles()
  const theme = useTheme()

  return external ? (
    <Badge
      badgeContent={
        <Icon
          name="identity-provider"
          style={{
            width: 18,
            height: 18,
            backgroundColor: "white",
            color: theme.palette.text.secondary,
            borderRadius: 3,
          }}
        />
      }
      classes={classes}
      overlap="circular"
    >
      <Avatar {...user} external />
    </Badge>
  ) : (
    <Avatar {...user} />
  )
}

export { UserAvatar }
