import { offlineVar, offlineReconnectingVar } from "../../data/offlineVar"
import { useAuth } from "../../services"
import { useMountEffect } from "../../utils"

const OfflineModeProvider = ({ dataClient, children }) => {
  const { refresh } = useAuth()

  useMountEffect(() => {
    window.addEventListener("offline", offlineListener)
    window.addEventListener("online", onlineListener)

    return () => {
      window.removeEventListener("offline", offlineListener)
      window.removeEventListener("online", onlineListener)
    }
  })

  const onlineListener = async () => {
    offlineReconnectingVar(true)
    setTimeout(async () => {
      try {
        await refresh(dataClient.offlineClient, false)
        offlineVar(false)
        offlineReconnectingVar(false)
        dataClient.openQueue()
      } catch (error) {
        offlineReconnectingVar(false)
        offlineVar(false)
        console.log(error)
      }
    }, 1000)
  }

  const offlineListener = () => {
    dataClient.closeQueue()
    offlineReconnectingVar(false)
    offlineVar(true)
  }

  return <>{children}</>
}

export { OfflineModeProvider }
