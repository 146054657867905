import Base from "./Base"
import Local from "./Local"

const config = {
  ...Local,
  clientDevice: true,

  version: {
    ...Base.version,
    check: {
      ...Base.version.check,
      mockDeviceClientInfo: {
        app: {
          version: "1.0.0",
          build: "1",
          channel: "production",
        },
        platform: {
          os: "ios",
          version: "17",
        },
      },
    },
  },

  auth: Local.auth ? { ...Local.auth } : { ...Base.auth },
}

export default config
