import React from "react"
import { useAuth } from "../../services"
import { Avatar } from ".."

const OrganisationLogo = () => {
  const {
    settings: {
      organisation: { name, logo },
    },
  } = useAuth()

  if (!logo) return null

  return <Avatar avatar={{ key: logo }} fullName={name} />
}

export { OrganisationLogo }
