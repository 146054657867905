import React from "react"
import { Button, makeStyles } from "@material-ui/core"
import { PaperBox, RowBox } from "../Boxes"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: ({ active }) => (active ? theme.palette.primary.main : "inherit"),
    "&&:hover": {
      backgroundColor: ({ active }) => (active ? theme.palette.primary.main : theme.palette.shaded.main),
    },
  },
  text: {
    color: ({ active }) => theme.palette.text[active ? "white" : "primary"],
  },
}))

const StaticButton = ({ text, active, onClick, ...rest }) => {
  const classes = useStyles({ active })

  return (
    <PaperBox component={Button} className={classes.root} color="primary" onClick={onClick} fullWidth {...rest}>
      <RowBox className={classes.text}>{text}</RowBox>
    </PaperBox>
  )
}

export { StaticButton }
