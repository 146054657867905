import React, { memo } from "react"
import { Badge, Box, Tooltip, makeStyles, useTheme, useMediaQuery } from "@material-ui/core"
import { t } from "@lingui/macro"
import { Icon, LinkButton } from ".."
import { RowBox } from "../Boxes"

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.text.primary,
    fontSize: ({ xs }) => (xs ? 12 : 13),
    fontWeight: "500",
    minHeight: theme.spacing(4),
    borderRadius: theme.spacing(0.5),
    padding: ({ xs }) => (xs ? theme.spacing(0.25) : theme.spacing(0.5)),
    "&:disabled": {
      color: theme.palette.text.disabled,
      cursor: "default",
    },
    textAlign: "left",
  },
  badgeDot: {
    cursor: "pointer",
    height: 12,
    minWidth: 12,
    borderRadius: 6,
    marginTop: 12,
    marginLeft: -6,
    "&:before": {
      content: "''",
      position: "absolute",
      height: 24,
      width: 24,
      borderRadius: 12,
      backgroundColor: ({ badgeColor }) => badgeColor || theme.palette.raised.main,
      opacity: 0,
      animation: "beacon 1.2s infinite",
    },
  },
}))

const DisabledTooltip = ({ children, disabled }) => (
  <Tooltip title={disabled ? t`Preview only` : ""}>
    <Box>{children}</Box>
  </Tooltip>
)

const ActionCardFooterButton = memo(function ActionCardFooterButton({
  icon,
  label,
  onClick,
  iconClassName = "",
  loading,
  badgeProps,
  badgeColor,
  disabled,
  preview,
  ...rest
}) {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const classes = useStyles({ badgeColor, xs })

  const handleClick = (event) => {
    if (disabled) {
      return
    }

    onClick && onClick(event)
  }

  const previewDisabled = preview && disabled

  const button = (
    <LinkButton className={classes.button} onClick={handleClick} disabled={!preview && disabled} {...rest}>
      <RowBox>
        <Icon name={icon} className={iconClassName} />
        <Box ml={xs ? 0.25 : 0.5}>{label}</Box>
      </RowBox>
    </LinkButton>
  )

  if (!badgeProps) {
    return <DisabledTooltip disabled={previewDisabled}>{button}</DisabledTooltip>
  }

  return (
    <DisabledTooltip disabled={previewDisabled}>
      <Badge
        overlap="circular"
        variant="dot"
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={{
          dot: classes.badgeDot,
        }}
        onClick={handleClick}
        {...badgeProps}
      >
        {button}
      </Badge>
    </DisabledTooltip>
  )
})

export default ActionCardFooterButton
