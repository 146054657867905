import { useQuery, gql } from "@apollo/client"
import { produce } from "immer"
import { toId } from "../../utils"
import { JOB_FIELDS } from "../jobs/fragments/jobFieldsFragment"

const NOTIFICATIONS_LIMIT = 5

const NOTIFICATION_FIELDS = gql`
  fragment NotificationFields on Notification {
    id
    read
    type: newType # use newType (String) to replace deprecated type (Number)
    eventName
    message
    from {
      id
      firstName
      lastName
      fullName
      avatar {
        key
      }
    }
    post {
      id
      title
      hasConfirm
      confirmed {
        id
        confirmed
      }
      unconfirmed {
        id
        confirmed
      }
    }
    action {
      id
      title
      location
      priority
      status
      statusAt
      dueAt
    }
    process {
      id
    }
    job {
      ...JobFields
    }
    userTraining {
      id
      name
    }
    userAccreditations {
      id
      accreditation {
        name
      }
    }
    hasConfirm
    confirmed
    createdAt
    updatedAt
  }
  ${JOB_FIELDS}
`

const NOTIFICATIONS_FIELDS = gql`
  ${NOTIFICATION_FIELDS}
  fragment NotificationsFields on NotificationList {
    unread
    unconfirmed
    items {
      ...NotificationFields
    }
  }
`

const NOTIFICATIONS_QUERY = gql`
  query Notifications($limit: Int!, $offset: Int!) {
    notifications(limit: $limit, offset: $offset) {
      ...NotificationsFields
    }
  }
  ${NOTIFICATIONS_FIELDS}
`

const notificationPostTypePolicies = {
  NotificationPost: {
    fields: {
      confirmed: {
        merge(prev, next) {
          return [...next]
        },
      },
      unconfirmed: {
        merge(prev, next) {
          return [...next]
        },
      },
    },
  },
}

const NOTIFICATION_CREATED_SUBSCRIPTION = gql`
  subscription notificationCreated {
    notificationCreated {
      unread
      unconfirmed
      notification {
        ...NotificationFields
      }
    }
  }
  ${NOTIFICATION_FIELDS}
`

const NOTIFICATION_UPDATED_SUBSCRIPTION = gql`
  subscription notificationUpdated {
    notificationUpdated {
      unread
      unconfirmed
      notification {
        ...NotificationFields
      }
    }
  }
  ${NOTIFICATION_FIELDS}
`

const NOTIFICATION_DELETED_SUBSCRIPTION = gql`
  subscription notificationDeleted {
    notificationDeleted {
      unread
      unconfirmed
      notification {
        ...NotificationFields
      }
    }
  }
  ${NOTIFICATION_FIELDS}
`

const useQueryNotifications = () => {
  const result = useQuery(NOTIFICATIONS_QUERY, {
    variables: {
      limit: NOTIFICATIONS_LIMIT,
      offset: 0,
    },
  })

  return {
    ...result,
    subscribe: () => [
      result.subscribeToMore({
        document: NOTIFICATION_CREATED_SUBSCRIPTION,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev
          const { unread, unconfirmed, notification: newItem } = subscriptionData.data.notificationCreated

          const items = [...prev.notifications.items]

          const notification = items.find((p) => toId(p) === toId(newItem))
          if (!notification) {
            items.unshift(newItem)
          }

          return {
            ...prev,
            notifications: {
              unread,
              unconfirmed,
              items,
              __typename: prev.notifications.__typename,
            },
          }
        },
        onError: (err) => console.log("[useQueryNotifications][NOTIFICATION_CREATED_SUBSCRIPTION]", err),
      }),
      result.subscribeToMore({
        document: NOTIFICATION_UPDATED_SUBSCRIPTION,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev
          const { unread, unconfirmed, notification: updatedItem } = subscriptionData.data.notificationUpdated

          const next = JSON.parse(JSON.stringify(prev))

          let notification = next.notifications.items.find((n) => toId(n) === toId(updatedItem))
          if (notification) {
            notification = { ...updatedItem }
          }

          next.notifications.unread = unread
          next.notifications.unconfirmed = unconfirmed

          return next
        },
        onError: (err) => console.log("[useQueryNotifications][NOTIFICATION_CREATED_SUBSCRIPTION]", err),
      }),
      result.subscribeToMore({
        document: NOTIFICATION_DELETED_SUBSCRIPTION,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev
          const deletedItem = subscriptionData.data.notificationDeleted

          return produce(prev, (data) => {
            data.notifications.unread = deletedItem.unread
            data.notifications.unconfirmed = deletedItem.unconfirmed
            data.notifications.items = [
              ...prev.notifications.items.filter((notification) => toId(notification) !== toId(deletedItem)),
            ]
          })
        },
        onError: (err) => console.log("[useQueryNotifications][NOTIFICATION_DELETED_SUBSCRIPTION]", err),
      }),
    ],
  }
}

export {
  useQueryNotifications,
  NOTIFICATIONS_QUERY,
  NOTIFICATION_FIELDS,
  NOTIFICATIONS_LIMIT,
  notificationPostTypePolicies,
}
