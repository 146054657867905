import React from "react"
import ReactTimeAgoComponent from "react-timeago"
import { Hidden, makeStyles } from "@material-ui/core"
import moment from "moment-timezone"
import { FormatDateCompact } from ".."

const useStyles = makeStyles((theme) => ({
  overdue: {
    color: theme.palette.error.main,
  },
}))

const getSecondsAgo = (lowercase) => `${lowercase ? "j" : "J"}ust now`

const formatter = ({ lowercase, compact, value, unit, suffix, epochSeconds, nextFormatter }) => {
  if (suffix === "from now") {
    if (unit === "second") return "A few seconds"
    if (unit === "minute") return `${value}min`
    if (unit === "hour" && value === 1) return `${value}hr`
    if (unit === "hour" && value > 1) return `${value}hrs`
    if (compact && unit === "day") return `${value}d`
    if (compact && unit === "week" && value === 1) return `${value}wk`
    if (compact && unit === "week" && value > 1) return `${value}wks`
    if (compact && unit === "month") return `${value}mth`
    if (compact && unit === "year") return `${value}y`
    return nextFormatter(value, unit, suffix, epochSeconds)
  }

  if (unit === "second" || suffix !== "ago") return getSecondsAgo(lowercase, compact)
  if (unit === "minute") return `${value}min ago`
  if (unit === "hour" && value === 1) return `${value}hr ago`
  if (unit === "hour" && value > 1) return `${value}hrs ago`
  if (compact && unit === "day") return `${value}d ago`
  if (compact && unit === "week" && value === 1) return `${value}wk ago`
  if (compact && unit === "week") return `${value}wks ago`
  if (compact && unit === "month") return `${value}mth ago`
  if (compact && unit === "year") return `${value}y ago`
  return nextFormatter(value, unit, suffix, epochSeconds)
}

const TimeAgoWithProps = ({ compact, lowercase, accurate = false, date, prefix, hideTitle = false, ...props }) => {
  if (!date) {
    return null
  }

  if (accurate && date && moment(date).diff(moment().startOf("day"), "day") < 0) {
    return <FormatDateCompact value={date} />
  }

  const title = hideTitle ? null : moment(date).local().format()

  return (
    <ReactTimeAgoComponent
      date={date}
      title={title}
      {...props}
      minPeriod={60}
      formatter={(value, unit, suffix, epochSeconds, nextFormatter) =>
        formatter({ lowercase, compact, accurate, value, unit, prefix, suffix, epochSeconds, nextFormatter })
      }
    />
  )
}

const TimeAgo = ({ overdue = false, xsCompact = true, ...props }) => {
  const classes = useStyles()

  return (
    <span className={overdue ? classes.overdue : ""}>
      <Hidden xsDown>
        <TimeAgoWithProps {...props} />
      </Hidden>
      <Hidden smUp>
        <TimeAgoWithProps {...props} compact={xsCompact} />
      </Hidden>
    </span>
  )
}

export default TimeAgo
