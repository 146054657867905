import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Chip } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  processingRoot: {
    color: theme.palette.text.white,
    backgroundColor: theme.palette.primary.main,
  },
  completedRoot: {
    color: theme.palette.text.white,
    backgroundColor: theme.palette.success.main,
  },
}))

const StepChip = ({ awaiting, processing, completed, ...rest }) => {
  const classes = useStyles()

  let root = null
  if (processing) {
    root = classes.processingRoot
  } else if (completed) {
    root = classes.completedRoot
  }

  return <Chip classes={{ root }} {...rest} />
}

export { StepChip }
