import { useQuery, gql } from "@apollo/client"
import { ROLE_FIELDS } from "./fragments/roleFieldsFragment"

const ROLES_QUERY = gql`
  query Roles {
    roles {
      ...RoleFields
    }
  }
  ${ROLE_FIELDS}
`

const useQueryRoles = () => useQuery(ROLES_QUERY)

export { useQueryRoles }
