import { useQuery, gql, useLazyQuery } from "@apollo/client"
import { SUPPLIER_FIELDS } from "./fragments/supplierFieldsFragment"

const SUPPLIER_QUERY = gql`
  query Supplier($id: ID!) {
    supplier(id: $id) {
      ...SupplierFields
    }
  }
  ${SUPPLIER_FIELDS}
`

const useQuerySupplier = (options) => useQuery(SUPPLIER_QUERY, options)

const useLazyQuerySupplier = (options) => useLazyQuery(SUPPLIER_QUERY, options)

export { useQuerySupplier, useLazyQuerySupplier }
