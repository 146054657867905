import React, { useState, Fragment } from "react"
import { Box, Button, Divider, makeStyles, TextField } from "@material-ui/core"
import { Avatar, Caption, DeleteConfirmIconButton, FormatDateTimeCompact, InfoSection } from ".."
import { PaperBox, RowBox } from "../Boxes"
import {
  useMutationAdminableUserAddNote,
  useMutationAdminableUserDeleteNote,
  useMakeAdminableUserOptimistic,
} from "../../data/users/useMutationAdminableUser.js"

import { toId } from "../../utils"
import { useAuth } from "../../services"

const useStyles = makeStyles(() => ({
  divider: {
    width: "100%",
  },
  author: {
    width: 30,
    height: 30,
  },
  text: {
    textAlign: "left",
    fontSize: 12,
    wordBreak: "break-word",
    wordWrap: "break-word",
  },
}))

const limitIncrement = 5

export const PersonNotes = ({ user }) => {
  const classes = useStyles()
  const [text, setText] = useState("")
  const [limit, setLimit] = useState(0)
  const {
    principal: { firstName, lastName, avatar },
    location,
  } = useAuth()

  // mutations
  const [addNote] = useMutationAdminableUserAddNote(user)
  const [deleteNote, { loading: deleteLoading }] = useMutationAdminableUserDeleteNote(user)
  const { makeAdminableUserAddNoteOptimisticResponse } = useMakeAdminableUserOptimistic()

  const handleAdd = async () => {
    const variables = {
      user: toId(user),
      text,
    }
    const optimisticResponse = makeAdminableUserAddNoteOptimisticResponse({
      ...variables,
      user,
    })
    setText("")
    if (!limit) {
      setLimit(limitIncrement)
    }
    await addNote({ variables, optimisticResponse })
  }

  const handleDelete = async (note) => {
    await deleteNote({
      variables: {
        user: toId(user),
        note: toId(note),
      },
    })
  }

  const handleEnter = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      handleAdd()
    }
  }

  const isValid = Boolean(text?.trim())
  const notes = user?.notes?.length ? [...user.notes].reverse().slice(0, limit) : []
  const moreCount = user?.notes?.length - limit

  return (
    <>
      <PaperBox>
        <InfoSection>
          <Box mr={1}>
            <Avatar {...{ firstName, lastName, avatar: { key: avatar } }} className={classes.author} />
          </Box>
          <TextField
            size="small"
            placeholder="Add an internal note..."
            variant="outlined"
            value={text}
            onChange={(event) => setText(event.target.value)}
            onKeyPress={handleEnter}
            data-cy="PersonNotes-text"
            fullWidth
          />
          <Box ml={1}>
            <Button
              onClick={handleAdd}
              className={classes.submit}
              data-cy="PersonNotes-add"
              disabled={!isValid}
              variant="contained"
            >
              Add
            </Button>
          </Box>
        </InfoSection>
        <Box data-cy="PersonNotes-notes">
          {notes.map((note, index) => (
            <Box key={toId(note)} data-cy={`PersonNote-${index}`}>
              <Divider className={classes.divider} />
              <InfoSection className={classes.note}>
                <Box mr={1}>
                  <Avatar {...note.author} className={classes.author} />
                </Box>
                <RowBox flexGrow={1} className={classes.text} mr={1}>
                  {note.text}
                </RowBox>
                <Caption mb={0} mr={1}>
                  <FormatDateTimeCompact value={note.createdAt} compactTodayTimeZone={location?.timeZone || null} />
                </Caption>
                <DeleteConfirmIconButton
                  onDelete={() => handleDelete(note)}
                  disabled={deleteLoading}
                  cy="PersonNote-delete"
                />
              </InfoSection>
            </Box>
          ))}
        </Box>
        {moreCount > 0 && (
          <>
            <Divider />
            <InfoSection>
              <RowBox justifyContent="center" flexGrow={1}>
                <Button onClick={() => setLimit(limit + limitIncrement)} data-cy="PersonNotes-loadMore">
                  {limit === 0 && <>Show notes ({moreCount})</>}
                  {limit > 0 && <>Show more ({moreCount})</>}
                </Button>
              </RowBox>
            </InfoSection>
          </>
        )}
      </PaperBox>
    </>
  )
}
