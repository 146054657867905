import React, { useState } from "react"
import { makeStyles, Box, Typography, Collapse } from "@material-ui/core"
import { Avatar, FormatDateTimeCompact } from ".."

const useStyles = makeStyles((theme) => ({
  panel: {
    width: "100%",
    borderRadius: "8px",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: 12,
    lineHeight: "14px",
  },
  avatar: {
    width: 40,
    height: 40,
    fontSize: 16,
  },
  nameTitle: {
    color: theme.palette.text.primary,
    fontSize: 16,
    fontWeight: "500",
    lineHeight: "20px",
  },
}))

const TimestampUserPanel = ({ user: { firstName, lastName, avatar }, timestamp, hoverText, ...rest }) => {
  const classes = useStyles()
  const [over, setOver] = useState(false)

  return (
    <Box
      mr={5}
      className={classes.panel}
      onMouseEnter={() => setOver(true)}
      onMouseLeave={() => setOver(false)}
      {...rest}
    >
      <Box display="flex" flexDirection="row" px={2} p={2}>
        <Avatar {...{ firstName, lastName, avatar }} className={classes.avatar} />
        <Box mt={0} ml={1} flexGrow={1}>
          <Box mb={0} display="flex" flexGrow={1} justifyContent="flex-start">
            <Typography component="h1" className={classes.nameTitle}>
              {firstName} {lastName}
            </Typography>
          </Box>
          <Box className={classes.timestamp}>
            <FormatDateTimeCompact value={timestamp} />
          </Box>
          {!!hoverText && <Collapse in={over}>{hoverText}</Collapse>}
        </Box>
      </Box>
    </Box>
  )
}

export { TimestampUserPanel }
