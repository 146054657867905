import React, { useRef, useState } from "react"
import { Box, makeStyles, useTheme, useMediaQuery, TextField, InputAdornment } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import SearchIcon from "@material-ui/icons/Search"
import Highlighter from "react-highlight-words"
import { t } from "@lingui/macro"
import { useLazyQuerySearch } from "../../data"
import { useAuth } from "../../services"
import { toId, useDebounceIntercept } from "../../utils"
import TimeAgo from "../TimeAgo"
import { PaperBox } from "../Boxes"

const useStyles = makeStyles((theme) => ({
  searchInput: {
    height: 56,
  },
  highlight: {
    fontWeight: "600",
    marginLeft: 0,
  },
  date: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
  },
}))

const useInputStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    fontSize: 16,
    color: theme.palette.text.primary,
    marginBottom: ({ mb }) => (mb ? theme.spacing(mb) : 0),
  },
  notchedOutline: {
    borderColor: "#d2d2d2",
  },
}))

const KnowledgeSearch = ({ placeholder = t`Search knowledge base articles...`, onItemSelected }) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const classes = useStyles()
  const inputClasses = useInputStyles()
  const { location } = useAuth()
  const [load, { loading, data }] = useLazyQuerySearch()
  const [open, setOpen] = useState(false)
  const clearRef = useRef(false)

  const handleLoad = (newSearchText) => {
    if (newSearchText) load({ variables: { searchText: newSearchText, location: toId(location), limit: 9 } })
  }
  const [searchText, setSearchText] = useDebounceIntercept("", 300, 0, handleLoad)

  const handleInputChange = (event, newValue) => {
    setSearchText(newValue)
  }

  const handleChange = (event, option) => {
    if (option?.id) {
      clearRef.current = !clearRef.current
      onItemSelected && onItemSelected(option)
    }
  }

  const knowledge = (data && data.searchAreas.knowledge.items) || []

  return (
    <Autocomplete
      key={clearRef.current}
      freeSolo
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      onInputChange={handleInputChange}
      onChange={handleChange}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={({ title }) => title}
      options={knowledge}
      loading={loading}
      PaperComponent={PaperBox}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          margin="none"
          variant="outlined"
          className={xs ? null : classes.searchInput}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            classes: inputClasses,
            type: "text",
          }}
          data-cy="KnowledgeSearch-input"
        />
      )}
      renderOption={(option) => (
        <Box display="flex" flexDirection="row" alignItems="center" flexGrow={1} data-cy="KnowledgeSearch-option">
          <Box>
            <Highlighter
              highlightClassName={classes.highlight}
              searchWords={searchText.split(" ")}
              autoEscape
              textToHighlight={option.title}
            />
          </Box>
          {option.date && (
            <Box ml="auto" pl={1} display="flex" className={classes.date}>
              <TimeAgo date={option.date} />
            </Box>
          )}
        </Box>
      )}
    />
  )
}

export { KnowledgeSearch }
