import React from "react"
import { ListItemAvatar, ListItemText } from "@material-ui/core"
import { CheckboxPickerListItem } from ".."
import OperandioFallbackAvatar from "../Avatar/OperandioFallbackAvatar"

const AllUsersPickerListItem = ({ primaryText = "All people", ...props }) => (
  <CheckboxPickerListItem {...props}>
    <ListItemAvatar>
      <OperandioFallbackAvatar />
    </ListItemAvatar>
    <ListItemText primary={primaryText} />
  </CheckboxPickerListItem>
)

export default AllUsersPickerListItem
