import React from "react"
import { Collapse } from "@material-ui/core"

const enabled = true

const ProcessCollapse = ({ in: show, children, ...rest }) => {
  if (!enabled) {
    if (show) {
      return children
    }

    return null
  }

  return (
    <Collapse in={show} {...rest}>
      {children}
    </Collapse>
  )
}

export { ProcessCollapse }
