import React, { useState } from "react"
import { makeStyles } from "@material-ui/core"
import { ConfirmAlert } from "../Alerts"
import { TooltipIconButton } from "./TooltipIconButton"

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.error.main,
  },
}))

const RemoveIconButton = ({ onConfirmedClick, onCancelledClick, subject, children, classes, ...props }) => {
  const defaultClasses = useStyles()
  const [confirming, setConfirming] = useState(false)

  const handleClick = () => setConfirming(true)

  const handleResponse = (confirmed) => {
    setConfirming(false)
    if (confirmed && onConfirmedClick) onConfirmedClick()
    else if (!confirmed && onCancelledClick) onCancelledClick()
  }

  return (
    <>
      <ConfirmAlert open={confirming} title={`Remove ${subject}`} onClose={handleResponse} />

      <TooltipIconButton
        tooltip={`Remove ${subject}`}
        onClick={handleClick}
        classes={classes || defaultClasses}
        {...props}
      >
        {children}
      </TooltipIconButton>
    </>
  )
}

export { RemoveIconButton }
