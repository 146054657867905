import React, { memo } from "react"
import { makeStyles } from "@material-ui/core"
import moment from "moment-timezone"
import { t } from "@lingui/macro"
import { FormatTime } from "./FormatTime"
import { useAuth } from "../../services"

const useStyles = makeStyles((theme) => ({
  overdue: {
    color: theme.palette.error.main,
  },
}))

const FormatHumanTime = ({ value, showOverdue = false, overdueSuffix = "" }) => {
  const { location } = useAuth()
  const timeZone = location?.timeZone || moment.tz.guess()
  const classes = useStyles()
  const isMidday = moment(value).tz(timeZone).isSame(moment(value).tz(timeZone).startOf("day").add(12, "hours"))
  const isStartOfDay = moment(value).tz(timeZone).isSame(moment(value).tz(timeZone).startOf("day"))
  const isOverdue = showOverdue && value && moment(value).tz(timeZone).isSameOrBefore(moment())

  return (
    <span className={isOverdue ? classes.overdue : null}>
      {value && isStartOfDay && t`All day`}
      {value && isMidday && t`Midday`}
      {value && !isStartOfDay && !isMidday && (
        <FormatTime value={value} showOverdue={showOverdue} overdueSuffix={overdueSuffix} />
      )}
      {isOverdue && overdueSuffix && <> {overdueSuffix}</>}
    </span>
  )
}

const FormatHumanTimeMemo = memo(FormatHumanTime)

export { FormatHumanTimeMemo as FormatHumanTime }
